import { SelectProps } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'
import DownArrow from '../../assets/icons/down.svg?react'
import { IsModalContext } from '../Dialog'
import { StyledSelect, StyledTreeSelect } from './components'

const SelectWrapper = styled.div`
  position: relative;
`

export const StyledDownArrow = styled(DownArrow)`
  fill: ${({ theme }) => theme.colors.main};
`
// eslint-disable-next-line react/display-name
export const Select = React.forwardRef<any, React.PropsWithChildren<SelectProps>>(
  ({ className, loading, value, ...props }, ref) => {
    const isModal = React.useContext(IsModalContext)

    return (
      <SelectWrapper className={className}>
        <StyledSelect
          loading={loading}
          suffixIcon={null}
          value={value}
          data-value={value}
          // TODO: check typescript error with props
          {...(props as any)}
          popupClassName={`${props.popupClassName ? props.popupClassName : ''} ${
            isModal ? ' dialog-dropdown-select' : ''
          }`}
          ref={ref}
        />
        {!loading && (
          <div className="down-arrow">
            <StyledDownArrow />
          </div>
        )}
      </SelectWrapper>
    )
  }
)

export const TreeSelect = StyledTreeSelect
