import React, { ReactNode } from 'react'
import styled, { DefaultTheme } from 'styled-components/macro'
import _ from 'lodash'
import { NotificationEventWrapper } from './NotificationEventWrapper'
import { Text } from '../../Text'

const Wrapper = styled.div`
  display: flex;
  margin: 16px;
  align-items: flex-start;
`

const LeftInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  fill: #fff;
`

const RightInnerWrapper = styled.div`
  min-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 100%;
  margin-left: 8px;
`

const TextStyled = styled(Text)`
  line-height: 1;
  height: 24px;
  font-size: 24px;
  margin-left: 8px;
`

type Props = {
  message: ReactNode
  button?: ReactNode
  count?: ReactNode
  icon?: ReactNode
  fallbackTheme: DefaultTheme
  notificationPopupKey: string
}

export const NotificationEventDefaultLayout = ({
  message,
  button,
  count,
  icon,
  fallbackTheme,
  notificationPopupKey
}: Props) => {
  return (
    <NotificationEventWrapper fallbackTheme={fallbackTheme} notificationPopupKey={notificationPopupKey}>
      <Wrapper>
        <LeftInnerWrapper>
          {icon}
          <TextStyled fallbackTheme={fallbackTheme} type="ghost">
            {_.isNumber(count) ? `${count}` : count}
          </TextStyled>
        </LeftInnerWrapper>
        <RightInnerWrapper>
          {message}
          {button}
        </RightInnerWrapper>
      </Wrapper>
    </NotificationEventWrapper>
  )
}
