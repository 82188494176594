import styled, { css } from 'styled-components/macro'
import { formatStyleSize } from '../../utils/helpers'
import { FOOTER_HEIGHT, HEADER_HEIGHT, PAGE_PADDING_BOTTOM, PAGE_PADDING_TOP } from '../../utils/theme'

export const centerChildrenMixin = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

type WithMarginTop = {
  marginTop?: number | string
}

export const CenterChildren = styled.div.withConfig<WithMarginTop>({ shouldForwardProp: p => p !== 'marginTop' })`
  ${centerChildrenMixin}
  ${({ marginTop }) => (marginTop ? `margin-top: ${formatStyleSize(marginTop)};` : '')}
`

type WithPage = {
  inPage?: boolean
}

export const WindowCenter = styled.div.withConfig<WithPage>({ shouldForwardProp: p => p !== 'inPage' })`
  width: 100vw;
  height: ${({ inPage }) =>
    inPage ? `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT + PAGE_PADDING_TOP + PAGE_PADDING_BOTTOM}px)` : '100vh'};
  ${centerChildrenMixin}
`

export const CenterText = styled.div`
  text-align: center;
`
