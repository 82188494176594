/* eslint-disable import/no-default-export */
export default {
  BackToLogin: 'Zurück zur Anmeldung',
  SimpleUsefulPowerful: 'Einfach, nützlich und leistungsstark.',
  MargoWasSurprise: 'margò war eine wundervolle Bereicherung für mein Team und mich.',
  ContactCrif: 'Zur Beantragung eines Zugangs oder für weitere Informationen kontaktieren Sie bitte {{companyName}}.',
  ContactUsText: 'Zur Beantragung eines Zugangs oder für weitere Informationen kontaktieren Sie bitte uns.',
  Name: 'Name',
  InsertName: 'Geben Sie Ihren Namen ein',
  Surname: 'Nachname',
  InsertSurname: 'Geben Sie Ihren Nachnamen ein',
  Email: 'E-Mail',
  InsertEmail: 'Geben Sie Ihre E-Mail-Adresse ein',
  Message: 'Nachricht',
  InsertMessage: 'Schreiben Sie hier Ihre Nachricht',
  IAgreeWith: 'Ich stimme den',
  PrivacyPolicy: 'Datenschutzbestimmungen zu',
  Send: 'Senden',
  Success: 'Ihre Nachricht wurde korrekt an {{companyName}} gesendet.',
  ContactCRIF: 'Kontaktieren Sie {{companyName}}',
  ContactUs: 'Kontaktieren Sie uns',
  Company: 'Unternehmen',
  InsertCompany: 'Bitte Firmenname eingeben',
  Country: 'Land',
  InsertCountry: 'Wählen Sie Ihr Land',
  JobTitle: 'Berufsbezeichnung',
  InsertJobTitle: 'Bitte Berufsbezeichnung eingeben',
  Telephone: 'Telefonnummer',
  InsertTelephone: 'Bitte Telefonnummer eingeben',
  CompanyName: 'Firmenname',
  ContactCustomer: 'Kontakt zum Kunden',
  EnterContactDetails: 'Geben Sie die Kontaktdaten ein',
  ContactName: 'Firmenname',
  ContactSurname: 'Kontaktname',
  ContactEmail: 'Kontakt E-mail',
  ContactTelephone: 'Kontakt Telefonnummer',
  ConfirmOperation: 'Bestätigen Sie den Vorgang',
  ConfirmOperationDescription: 'Die Operation kann einige Minuten dauern. Willst du fortfahren?',
  CreateOffer: 'Angebot erstellen',
  ContactCustomerOnline: 'Kontaktieren Sie den Kunden online'
}
