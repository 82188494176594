// eslint-disable-next-line import/no-default-export
export default {
  title: 'Operations',
  CompaniesInTheList: 'Imprese nella lista',
  Editor: {
    AddRule: 'Aggiungi regola',
    AddGroup: 'Aggiungi gruppo',
    DeleteGroup: 'Elimina gruppo',
    StartNewSearch: 'Inizia una nuova ricerca'
  },
  ShowQuery: 'Mostra Query',
  NoMobileDesc: 'La pagina alla quale stai cercando di accedere è disponibile per tablet e desktop.',
  Search: 'Cerca',
  Actions: {
    LoadQuery: 'Carica Ricerca',
    LoadQueryTooltip: 'Apri una ricerca salvata',
    SaveQuery: 'Salva Ricerca',
    SaveQueryTooltip: 'Salva questa ricerca nel modulo Operations',
    Download: 'Download Excel',
    DownloadTooltip: 'Scarica il portafoglio in formato Excel sul tuo dispositivo',
    SaveList: 'Salva lista',
    SaveListTooltip: 'Salva questa lista di imprese in un portafoglio'
  },
  SaveQuery: {
    DialogTitle: 'Salva ricerca',
    Description: 'Inserisci un nome da dare alla tua analisi o seleziona una ricerca salvata per sovrascriverla.',
    InputLabel: 'Nome ricerca',
    InputPlaceholder: 'Nome analisi',
    UpdateExisting: 'Aggiorna esistente',
    CreateNew: 'Crea nuova',
    SavedQuery: 'salvata correttamente.',
    SavedQueryDescription: 'Troverai la ricerca salvata nel repository della relativa sezione.'
  },
  filters: {
    selectGroup: 'Seleziona gruppo filtri',
    selectFilter: 'Seleziona filtro',
    booleanTrue: 'Vero',
    booleanFalse: 'Falso',
    booleanEmpty: 'Non disponibile',
    searchText: 'Inserisci testo',
    searchForSynonyms: 'Cerca sinonimi',
    operatorsEquals: 'Uguale a',
    operatorsNotEquals: 'Diverso da',
    operatorsGreater: 'Maggiore di',
    operatorsLower: 'Minore di',
    operatorsGreaterOrEqual: 'Maggiore o uguale di',
    operatorsLowerOrEqual: 'Minore o uguale di',
    operatorsNotExists: 'Nullo',
    operatorsExists: 'Non nullo',
    operatorsBetween: 'Compreso tra',
    operatorsIsOneOf: 'Fa parte di',
    operatorsIsNotOneOf: 'Non fa parte di',
    operatorsIn: 'Fa parte di',
    operatorsNotIn: 'Non fa parte di',
    operatorsContains: 'Contiene',
    operatorsNotContains: 'Non contiene',
    selectSynonyms: 'Seleziona sinonimi',
    fromDate: 'Data minimo',
    toDate: 'Data massimo',
    minValue: 'Valore minimo',
    maxValue: 'Valore massimo'
  },
  CustomizeQuery: {
    Title: 'Customizza la tua query.',
    Message:
      'Per iniziare la tua ricerca clicca su "Aggiungi regola". Seleziona gli operatori, scegli i filtri di interesse e conferma.'
  },
  WarningRespectingLocalUnits: {
    Title: 'Arricchimento',
    Text: 'La query conterrà solo imprese con Tipo di sede "Sede amministrativa", "Sede amministrativa e Sede legale" e "Sede legale" (se selezionate). Per includere le aziende con Tipo di sede "Unità locale", attiva l\'add-on Unità locali contattando il customer service.'
  }
}
