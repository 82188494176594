import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isDenied, isForUpsell } from '@utils/constraints'
import { LockedFeatureTooltipContent } from '@components/Tooltip/LockedFeatureTooltipContent'
import { useBranding } from '../../hooks/useBranding'
import NotificationBurgerIcon from '../../assets/icons/notification.svg?react'
import ClearIcon from '../../assets/icons/close.svg?react'
import BurgerIcon from '../../assets/icons/burger-menu.svg?react'
import UserIcon from '../../assets/icons/single.svg?react'
import UserSecret from '../../assets/icons/fa-user-secret.svg?react'
import CalendarIcon from '../../assets/icons/icon-s-calendar.svg?react'
import { LanguageSwitch } from '../../containers/LanguageSwitch'
import { CurrencySwitch } from '../../containers/CurrencySwitch'
import { BasicTooltip } from '../Tooltip'
import { VisibleDrawer } from './types'
import { IconButton, BellIcon } from './StyledComponents'
import { hasOriginator, isLoggedIn, moduleAppointmentConstraint } from '../../features/user/selectors'

const getMobileIcon = (drawer: VisibleDrawer, hasNotification: boolean) => {
  if (drawer === VisibleDrawer.MobileMenu) return <ClearIcon />
  if (hasNotification) return <NotificationBurgerIcon />
  return <BurgerIcon />
}

type Props = {
  hasNewNotifications: boolean
  setHasNewNotifications: (hasNewNotifications: boolean) => void
  setDrawer: (drawer: VisibleDrawer) => void
  drawer: VisibleDrawer
}

export const IconBar = ({ setHasNewNotifications, hasNewNotifications, drawer, setDrawer }: Props) => {
  const { t } = useTranslation('header')
  const { allowedModules } = useBranding()
  const isImpersonited = useSelector(hasOriginator)
  const isUserLoggedIn = useSelector(isLoggedIn)
  const appointmentConstraint = useSelector(moduleAppointmentConstraint)

  const isAppointmentForUpsell = isForUpsell(appointmentConstraint)

  const toggleDrawer = React.useCallback(
    (drawerType: VisibleDrawer) => {
      setDrawer(drawer === drawerType ? VisibleDrawer.None : drawerType)
    },
    [drawer, setDrawer]
  )

  const overlayStyle = { maxWidth: '350px' }

  return (
    <>
      {isUserLoggedIn && (
        <>
          {allowedModules.Appointment && !isDenied(appointmentConstraint) && (
            <BasicTooltip
              placement="bottom"
              title={isAppointmentForUpsell ? <LockedFeatureTooltipContent /> : t('Appointments')}
              overlayClassName={isAppointmentForUpsell ? 'info-tooltip' : undefined}
            >
              <IconButton
                className="pc tablet"
                onClick={() => toggleDrawer(VisibleDrawer.Appointment)}
                active={drawer === VisibleDrawer.Appointment}
                role="button"
                aria-label="appointment menu"
                disabled={isAppointmentForUpsell}
              >
                <CalendarIcon />
              </IconButton>
            </BasicTooltip>
          )}
          <BasicTooltip placement="bottom" title={t('FeedbackArea')} autoAdjustOverflow overlayStyle={overlayStyle}>
            <IconButton
              className="pc tablet"
              data-testid="bell-icon"
              onClick={() => {
                toggleDrawer(VisibleDrawer.Notification)
                setHasNewNotifications(false)
              }}
              active={drawer === VisibleDrawer.Notification}
            >
              <BellIcon hasNewNotifications={hasNewNotifications} />
            </IconButton>
          </BasicTooltip>
          <BasicTooltip placement="bottom" title={t('Profile')}>
            <IconButton
              className="pc tablet"
              data-testid="profile-icon"
              onClick={() => toggleDrawer(VisibleDrawer.Account)}
              active={drawer === VisibleDrawer.Account}
            >
              {isImpersonited ? <UserSecret /> : <UserIcon />}
            </IconButton>
          </BasicTooltip>
        </>
      )}
      <LanguageSwitch className="pc" primary />
      <CurrencySwitch className="pc" primary />
      <IconButton
        className="tablet"
        onClick={() => toggleDrawer(VisibleDrawer.MobileMenu)}
        active={drawer === VisibleDrawer.MobileMenu}
      >
        {getMobileIcon(drawer, false)}
      </IconButton>
      <IconButton
        className="mobile"
        onClick={() => toggleDrawer(VisibleDrawer.MobileMenu)}
        active={drawer === VisibleDrawer.MobileMenu}
        aria-label="show mobile menu"
        role="button"
      >
        {getMobileIcon(drawer, hasNewNotifications)}
      </IconButton>
    </>
  )
}
