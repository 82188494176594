import { QueryType } from '../../types'
import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'
import { Query } from './types'

export const FETCH_QUERIES = 'query/FETCH_QUERIES'
export const SET_QUERIES = 'query/SET_QUERIES'
export const REMOVE_QUERIES_BY_TYPE = 'query/REMOVE_QUERIES_BY_TYPE'
export const SET_QUERY = 'query/SET_QUERY'

export const QueryActions = {
  fetchQueries: (type: QueryType) => createAction(FETCH_QUERIES, { type }),
  setQueries: (type: QueryType, queries: Query[]) => createAction(SET_QUERIES, { type, queries }),
  removeQueriesByType: (type: QueryType) => createAction(REMOVE_QUERIES_BY_TYPE, { type }),
  setQuery: (query: Query) => createAction(SET_QUERY, { query })
}

export type QueryActions = ActionsUnion<typeof QueryActions>
