import { ActionsUnion } from '../../../utils/types'
import { createAction } from '../../../utils/redux'
import { SalestoolAssignmentsViewType } from '../../salestoolAssignmentsPage/types'
import { AssignmentType, SalesToolTableContent } from './types'

export const SET_SELECTED_ROWS = 'view/salestoolTable/SET_SELECTED_ROWS'
export const TOGGLE_SELECT_ALL_ROWS = 'view/salestoolTable/TOGGLE_SELECT_ALL_ROWS'
export const SET_TABLE_CONTENT = 'view/salestoolTable/SET_TABLE_CONTENT'
export const RESET_CONTENT = 'view/salestoolTabl/RESET_CONTENT'
export const ASSIGNMENT_LOADING = 'view/salestoolTable/ASSIGNMENT_LOADING'

export const ASSIGN_ITEM_TO_COMPANIES = 'view/salestoolTable/ASSIGN_ITEM_TO_COMPANIES'
export const ASSIGN_ITEM_TO_COMPANY = 'view/salestoolTable/ASSIGN_ITEM_TO_COMPANY'
export const MOVE_ASSIGNMENTS_PENDING = 'view/salestoolTable/MOVE_ASSIGNMENTS_PENDING'
export const CLEAR_PENDING_ASSIGNMENTS = 'view/salestoolTable/CLEAR_PENDING_ASSIGNMENTS'

export const SalestoolTableActions = {
  setSelectedRows: (selectedRows: string[]) => createAction(SET_SELECTED_ROWS, selectedRows),
  toggleSelectAllRows: () => createAction(TOGGLE_SELECT_ALL_ROWS),
  setTableContent: (data: SalesToolTableContent, isSale: boolean) => createAction(SET_TABLE_CONTENT, { data, isSale }),
  assignItemToCompanies: (type: AssignmentType, value: string, currentTab?: SalestoolAssignmentsViewType) =>
    createAction(ASSIGN_ITEM_TO_COMPANIES, { type, value, currentTab }),
  assignItemToCompany: (
    type: AssignmentType,
    value: string,
    entityId: string,
    currentTab?: SalestoolAssignmentsViewType
  ) => createAction(ASSIGN_ITEM_TO_COMPANY, { type, value, entityId, currentTab }),
  moveAssignmentsToPending: () => createAction(MOVE_ASSIGNMENTS_PENDING),
  clearPendingAssignments: () => createAction(CLEAR_PENDING_ASSIGNMENTS),
  setLoadingAssignment: (id: string, loading: boolean) => createAction(ASSIGNMENT_LOADING, { id, loading }),
  resetContent: () => createAction(RESET_CONTENT)
}

export type SalestoolTableActions = ActionsUnion<typeof SalestoolTableActions>
