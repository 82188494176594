import React from 'react'
import _ from 'lodash'
import styled, { css } from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import { gridTheme } from '../../utils/theme'
import { Dimension, parseDimensionOptional } from '../../utils/dimension'

type Breakpoint = keyof typeof gridTheme.breakpoints

type DirectionalDimensions = {
  top?: Dimension
  left?: Dimension
  bottom?: Dimension
  right?: Dimension
}

type ImgWrapperProps = {
  width?: Dimension
  height?: Dimension
  minWidth?: Dimension
  minHeight?: Dimension
  maxWidth?: Dimension
  maxHeight?: Dimension
  absolutePosition?: boolean | DirectionalDimensions
}

type BreakpointProps = {
  [P in Breakpoint]?: ImgWrapperProps
}

type ImageWrapperProps = {
  src: string
  alt: string
  className?: string
} & BreakpointProps &
  ImgWrapperProps

const imageWrapperSingleStyle = ({
  absolutePosition,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight
}: ImgWrapperProps) => {
  let dims: DirectionalDimensions = {}
  if (!_.isUndefined(absolutePosition) && !_.isBoolean(absolutePosition)) {
    dims = absolutePosition
  }
  return css`
    position: ${absolutePosition ? 'absolute' : undefined};
    top: ${parseDimensionOptional(dims.top)};
    left: ${parseDimensionOptional(dims.left)};
    bottom: ${parseDimensionOptional(dims.bottom)};
    right: ${parseDimensionOptional(dims.right)};

    & > img {
      width: ${parseDimensionOptional(width)};
      height: ${parseDimensionOptional(height)};
      min-width: ${parseDimensionOptional(minWidth)};
      min-height: ${parseDimensionOptional(minHeight)};
      max-width: ${parseDimensionOptional(maxWidth)};
      max-height: ${parseDimensionOptional(maxHeight)};
    }
  `
}

const imageWrapperStyles = ({
  absolutePosition,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  ...bp
}: ImgWrapperProps & BreakpointProps) => css`
  ${imageWrapperSingleStyle({ absolutePosition, width, height, minWidth, minHeight, maxWidth, maxHeight })}
  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    ${bp.xs ? imageWrapperSingleStyle(bp.xs) : undefined}
  }
  ${media.sm`
    ${bp.sm ? imageWrapperSingleStyle(bp.sm) : undefined}
  `}
  ${media.md`
    ${bp.md ? imageWrapperSingleStyle(bp.md) : undefined}
  `}
  ${media.lg`
    ${bp.lg ? imageWrapperSingleStyle(bp.lg) : undefined}
  `}
  ${media.xl`
    ${bp.xl ? imageWrapperSingleStyle(bp.xl) : undefined}
  `}
`

const ImgWrapper = styled.div`
  ${imageWrapperStyles}
`

export const Image = ({ src, alt, ...rest }: ImageWrapperProps) => (
  <ImgWrapper {...rest}>
    <img src={src} alt={alt} />
  </ImgWrapper>
)
