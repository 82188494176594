import _ from 'lodash'
import { LoadingOption, LoadingParams } from './types'

export const buildLoadingKey = (type: LoadingOption, param?: LoadingParams) => {
  if (!param) return type
  if (_.isArray(param)) {
    return `${type}.${_.join(param, '.')}`
  }

  return `${type}.${param}`
}
