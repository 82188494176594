import { useCallback, useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { FormatNumberFunction } from '../utils/types'
import { useDeepCompareMemoize } from './useDeepCompareMemoize'

const defaultOptions = { notation: 'standard' } as Intl.NumberFormatOptions

export const useLocaleNumberFormatter = (options: Intl.NumberFormatOptions = {}) => {
  const numberFormat = useMemo(
    () =>
      new Intl.NumberFormat(navigator.language, {
        ...defaultOptions,
        ...options
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useDeepCompareMemoize([navigator.language, options])
  )

  const formatNumber = useCallback<FormatNumberFunction>(
    n => {
      if (_.isUndefined(n)) return ''
      if (_.isNumber(n) && !_.isFinite(n)) return '0'
      return numberFormat.format(Number(n))
    },
    [numberFormat]
  )

  const [decimalSeparator, setDecimalSeparator] = useState(() => _.replace(numberFormat.format(1.1), /1/g, ''))
  useEffect(() => {
    setDecimalSeparator(_.replace(numberFormat.format(1.1), /1/g, ''))
  }, [numberFormat, setDecimalSeparator])
  const [thousandSeparator, setThousandSeparator] = useState(() => _.replace(numberFormat.format(1.1), /1/g, ''))
  useEffect(() => {
    setThousandSeparator(_.replace(numberFormat.format(1111), /1/g, ''))
  }, [numberFormat, setThousandSeparator])

  const parseNumber = useCallback(
    (stringNumber: string) => {
      let normalizedStringNumber = stringNumber
      normalizedStringNumber = _.replace(stringNumber, new RegExp(`\\${thousandSeparator}`, 'g'), '')
      normalizedStringNumber = _.replace(normalizedStringNumber, new RegExp(`\\${decimalSeparator}`), '.')
      return parseFloat(normalizedStringNumber)
    },
    [decimalSeparator, thousandSeparator]
  )

  return { formatNumber, parseNumber, decimalSeparator, thousandSeparator }
}
