import 'dayjs/locale/cs'
import 'dayjs/locale/de'
import 'dayjs/locale/sk'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

// eslint-disable-next-line import/no-default-export
export default dayjs
