import _ from 'lodash'

export type Dimension = string | number

export const parseDimension = (dim: Dimension) => {
  if (dim === 0) return '0'
  if (_.isNumber(dim)) return `${dim}px`
  return dim
}

export const parseDimensionOptional = (dim: Dimension | undefined) => {
  if (_.isUndefined(dim)) return undefined
  return parseDimension(dim)
}
