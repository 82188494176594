/* eslint-disable import/no-default-export */
export default {
  Username: 'Uživatelské jméno',
  Password: 'Heslo',
  Login: 'Přihlásit',
  LoginErrorMessage: 'Zadaný uživatel nebo heslo není správné',
  SignInMessage: 'Přihlásit se do Margo',
  EnterDetails: 'Níže zadejte své údaje',
  InsertUserName: 'Vložte uživatelské jméno',
  InsertUserNamaInBehalf: 'Jménem',
  InsertPassword: 'Vložte heslo',
  ForgotPassword: 'Zapomněli jste heslo?',
  ResetPasswordDialog: {
    Title: 'Zapomenuté heslo',
    Description:
      'Kontaktujte naše zákaznické centrum a požádejte o obnovení hesla,  e-mail <0> {{email}} </0> nebo telefon {{phone}}.',
    Email: 'helpdesk.cz@crif.com',
    Phone: '+420 737 152 074'
  },
  ResetPasswordForm: {
    Description:
      'Zadejte uživatelské jméno a e-mailovou adresu přiřazenou k vašemu účtu. Bude vám zaslán e-mail s odkazem na obnovení hesla.',
    SuccessDesription: 'Byl vám zaslán e-mail s odkazem na obnovení hesla. Přejděte na přihlašovací stránku.',
    Email: 'E-mail',
    ResetPassword: 'Obnovit heslo',
    ReturnToLoginpage: 'Zpět na přihlašovací stránku',
    InsertUserName: 'Vložte uživatelské jméno',
    InsertEmail: 'Vložte e-mail',
    InvalidUsernameOrPassword:
      'Heslo nebylo obnoveno. Uživatelské jméno / e-mail není platný. Zkuste to znovu nebo kontaktujte zákaznické centrum.',
    ResetFailed: 'Obnovení hesla nebylo provedeno.'
  },
  RenewPasswordForm: {
    Title: 'Obnovit heslo',
    Description: 'Platnost vašeho hesla vypršela. Pro přístup ke službám Margo prosím změňte své heslo.',
    OldPassword: 'Staré heslo',
    NewPassword: 'Nové heslo',
    ConfirmPassword: 'Potvrďte nové heslo',
    ChangePassword: 'Změnit heslo',
    PasswordChangeSuccessTitle: 'Heslo změněno',
    PasswordChangeSuccessText: 'Heslo bylo úspěšně změněno',
    RenewPasswordFailed: 'Staré heslo je nesprávne.'
  },
  PasswordExpiration90Days: 'Heslo bude platné po dobu 90 dní',
  PasswordRules_czsk: `Heslo musí mít délku alespoň 8 znaků
  Heslo musí obsahovat maximálně 3 stejné znaky po sobě
  Heslo musí obsahovat velká a malá písmena
  Heslo musí obsahovat alespoň jednu číslici a jeden speciální znak
  Heslo nesmí obsahovat jméno uživatele.
  Heslo nesmí být stejné jako posledních šest použitých hesel`,
  Cs: {
    Title: 'Uživatel je již přihlášen',
    Info: 'Váš uživatel je již přihlášen, aktivní relace se tím ukončí. Všechny operace na pozadí se zachovají.',
    Question: 'Chcete ukončit existující relaci a začít novou?',
    NewSession: 'Začít novou relaci'
  },
  Sso: {
    error: 'Došlo k problému s přihlášením podle metody "Single Sign On".',
    back: 'Přihlaste se pomocí uživatelského jména a hesla.'
  }
}
