// eslint-disable-next-line import/no-default-export
export default {
  LeadSection: {
    Title: 'Gli strumenti per il successo nelle vendite',
    Text: "Le API di Margò offrono un set unico di dati aziendali registrati per supportare la crescita del tuo business e l'automazione del CRM. Utilizza l'ampia gamma di data packet disponibili per accedere a informazioni ufficiali quali dati sui dipendenti e sul fatturato, indirizzi aziendali, contatti e analytics avanzati. Potenzia il tuo CRM e i database sui clienti con le informazioni più recenti per analizzare i mercati, trovare nuovi potenziali clienti e opportunità di business."
  },
  MiddleSection: {
    Title: 'API Margò nel complesso',
    ItemCompanies: 'Dati di 6 milioni di imprese italiane aggiornati quotidianamente',
    ItemAnalytics: 'Oltre 40 indicatori analytics avanzati',
    ItemFilters: 'Oltre 300 filtri per selezionare e raggruppare le imprese',
    ItemPackets: '130+ data packet tematici per accedere ai dati aziendali'
  },
  BottomSection: {
    Title:
      "Libera il potenziale del tuo business, semplifica l'organizzazione della rete di vendita e automatizza i processi manuali",
    AutomationTitle: 'Automazione CRM',
    AutomationText:
      'Integra i dati Margò nel tuo CRM per mantenere il tuo database clienti aggiornato con i dati ufficiali e fornire informazioni rilevanti al tuo team di vendita.',
    MarketAnalysisTitle: 'Analisi di Mercato e AI',
    MarketAnalysisText:
      'Collega i tuoi sistemi di business intelligence a Margò e arricchisci i dati della tua base clienti. Potenzia i tuoi modelli statistici con nuovi dati.',
    EnrichmentTitle: 'Analizza Mercato e arricchimento di dati',
    EnrichmentText:
      'Crea cluster di imprese arricchendo i dati della tua base di clienti con le ultime informazioni disponibili.',
    BetterDataTitle: 'Dati migliori sui tuoi lead',
    BetterDataText:
      'Aumenta la conversione delle tue campagne di vendita con dati che aiutano il tuo team commerciale a valutare le imprese.',
    ProspectingTitle: 'Trova Clienti',
    ProspectingText:
      'Scopri nuove nicchie di mercato, trova i migliori prospect per i tuoi prodotti, importali ed inizia subito a vendere.',
    MarketingTitle: 'Marketing Analytics',
    MarketingText:
      'Trova imprese attive nel commercio internazionale, che innovano e sono digitali. Visualizza il fatturato stimato delle PMI.',
    FinanceTitle: 'Finance Analytics',
    FinanceText:
      "Seleziona le imprese che hanno un'elevata propensione a sottoscrivere prodotti finanziari e scopri le loro esigenze di finanziamento.",
    OnboardingTitle: 'Onboarding e fatturazione elettronica',
    OnboardingText:
      'Crea moduli di iscrizione autocompilanti per la tua attività B2B e sfrutta i dati di Margo per semplificare le attività amministrative.'
  },
  LearnMore: 'Per saperne di più'
}
