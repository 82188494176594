import { PortfolioCompaniesMapReq, PortfolioCompaniesMapReqData } from '../../api/portfolio/types'

export const createPortfolioCompaniesMapReq = ({
  pos,
  query,
  semantic,
  maxZoom,
  isEnrichedQuery,
  companyPortfolioStatus,
  excludedPortfoliosIds
}: PortfolioCompaniesMapReqData): PortfolioCompaniesMapReq => ({
  area: {
    northWest: {
      latitude: pos.bounds?.northWest.lat ?? 0,
      longitude: pos.bounds?.northWest.lng ?? 0
    },
    southEast: {
      latitude: pos.bounds?.southEast.lat ?? 0,
      longitude: pos.bounds?.southEast.lng ?? 0
    }
  },
  suppressClusters: pos.zoom >= maxZoom,
  zoom: pos.zoom,
  query,
  semantic,
  isEnrichedQuery,
  companyPortfolioStatus,
  excludedPortfoliosIds
})
