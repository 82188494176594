/* eslint-disable import/no-default-export */
export default {
  Welcome: {
    Name: 'Welcome',
    Title: 'Welcome on Margò',
    Description:
      "Margò is the new {{companyName}} smart marketing platform, designed for providing support to your business growth with a wide range of data. It's responsive to your needs and smart to anticipate your demands. It's the perfect tool to manage the commercial and marketing processes from the beginning to end."
  },
  PortfolioManagement: {
    Name: 'Portfolio Management',
    Title: "Easy way to manage your client's information",
    Description:
      "Create company lists, update the latest data, enrich your database with client's new information and look your business market from a new angle."
  },
  Targeting: {
    Name: 'Analyze Market',
    Title: 'A new way to look at your clients',
    Description:
      'View your clients on the map, analyze the structure of your company portfolios on multiple levels, avoid the risk. Customize and expand the analysis according to your needs. Save the analysis and share them to support new business strategies.'
  },
  Prospecting: {
    Name: 'Prospecting',
    Title: 'New clients in one click',
    Description:
      "Seek new clients for your business, receive a notification for a new company that matches your criteria. Find new prospects similar to your best clients, filter on partner companies' portfolio and take advantage of semantic search to find you next client."
  },
  SalesTool: {
    Name: 'Sales Tool',
    Title: 'All your clients at hand',
    Description:
      'Select the target, identify the actions and start the commercial campaigns. Empower the commercial network providing data in order to close new deals in the office or through mobile.'
  },
  Api: {
    Name: 'API',
    Title: 'Business Information As-a-service',
    Description:
      'Integrate Margò data with your company tools in order to have always the latest data of your clients with you. Automate the enrichment of your customer portfolios and answer the questions of your business by connecting Margò data to your Reporting, CRM and Business Master data systems.'
  }
}
