import { SendToOtherModulesDialogData as SendToOtherModulesDialogDataProspecting } from '@pages/Prospecting/SendToOtherModulesDialog/types'
import { SendToOtherModulesDialogData as SendToOtherModulesDialogDataTargeting } from '@pages/Targeting/SendToOtherModulesDialog/types'
import { SendToOtherModulesDialogData as SendToOtherModulesDialogDataPortfolio } from '@pages/PortfolioManagement/Portfolio/SendToOtherModulesDialog/types'
import { CancelPortfolioMonitoringDialogData } from '@pages/PortfolioManagement/CancelPortfolioMonitoringDialog/types'
import { UnlockPortfolioEsgDataDialogData } from '@pages/PortfolioManagement/Portfolio/UnlockPortfolioEsgDataDialog/types'
import { UnlockCompanyEsgDataDialogData } from '@pages/CompanyReport/Shared/UnlockCompanyEsgDataDialog/types'
import { DeleteContactDialogData, EditContactNoteDialogData } from '@pages/Account/Contacts/types'
import { AssignCompaniesDialogData } from '../../containers/Dialog/AssignCompanies/types'
import { ConfirmDialogData } from '../../containers/Dialog/ConfirmDialog/types'
import { CreatePortfolioDialogData } from '../../containers/Dialog/CreatePortfolio/types'
import { DeleteCompaniesDialogData } from '../../containers/Dialog/DeleteCompanies/types'
import { DeleteDocumentDialogData } from '../../containers/Dialog/DeleteDocument/types'
import { DeleteGoalDialogData } from '../../containers/Dialog/DeleteGoal/types'
import { DeleteProductDialogData } from '../../containers/Dialog/DeleteProduct/types'
import { EditGoalDialogData } from '../../containers/Dialog/EditGoal/types'
import { EditProductDialogData } from '../../containers/Dialog/EditProduct/types'
import { DeleteTaskDialogData } from '../../containers/Dialog/DeleteTask/types'
import { EditTaskDialogData } from '../../containers/Dialog/EditTask/types'
import { EnrichCompaniesDialogData } from '../../containers/Dialog/EnrichCompanies/types'
import { EnrichPersonDialogView } from '../../containers/Dialog/EnrichPerson/types'
import { InfoDialogData } from '../../containers/Dialog/InfoDialog/types'
import { MoveCompaniesDialogData } from '../../containers/Dialog/MoveCompanies/types'
import { SemanticClusterContactFormDialogData } from '../../containers/Dialog/SemanticClusterContactFormDialog/types'
import { LoadQueryDialogData as LoadPortfolioQueryDialogData } from '../../pages/PortfolioManagement/LoadQueryDialog/types'
import { SaveQueryDialogData as SavePortfolioQueryDialogData } from '../../pages/PortfolioManagement/SaveQueryDialog/types'
import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'
import { CompanyPopupDialogData, PersonPopupDialogData } from './dataTypes'
import { DialogType } from './types'
import { CampaignReceivedData } from '../../pages/SalesTool/Campaign/Dialog/CampaignReceivedDialog/types'
import { DeleteCompanyDialogData } from '../../pages/SalesTool/AssignCampaign/Dialog/DeleteCompany/types'
import { MoveCompanyDialogData } from '../../pages/SalesTool/AssignCampaign/Dialog/MoveCompany/types'
import { DeleteCustomTrackDialogData } from '../../containers/Dialog/DeleteCustomTrack/types'
import { RenameCustomTrackDialogData } from '../../containers/Dialog/RenameCustomTrack/types'
import { DeleteConfigurationDialogData } from '../../containers/Dialog/DeleteConfiguration/types'
import { DeleteAppointmentDialogData } from '../../containers/Dialog/DeleteAppointment/types'
import { EnrichPortfolioDialogData } from '../../containers/Dialog/EnrichPortfolio/types'

export const SHOW_DIALOG = 'dialog/SHOW_DIALOG'
export const CLOSE_DIALOG = 'dialog/CLOSE_DIALOG'
export const SET_DIALOG_DATA = 'dialog/SET_DIALOG_DATA'
export const UPDATE_DIALOG_DATA = 'dialog/UPDATE_DIALOG_DATA'
export const SHOW_DIALOG_WITH_DATA = 'dialog/SHOW_DIALOG_WITH_DATA'

const showDialog = (dialog: DialogType) => createAction(SHOW_DIALOG, { dialog })
const showDialogWithData = <T = any>(dialog: DialogType, data: T) =>
  createAction(SHOW_DIALOG_WITH_DATA, { dialog, data })

export const DialogActions = {
  showDialog,
  showDialogWithData,
  setDialogData: (dialog: DialogType, data: any) => createAction(SET_DIALOG_DATA, { dialog, data }),
  updateDialogData: (dialog: DialogType, data: any) => createAction(UPDATE_DIALOG_DATA, { dialog, data }),
  closeDialog: () => createAction(CLOSE_DIALOG),

  openCreatePortfolioDialog: (data: CreatePortfolioDialogData) => showDialogWithData('CreatePortfolio', data),
  openLoadPortfolioQueryDialog: (data: LoadPortfolioQueryDialogData) => showDialogWithData('LoadPortfolioQuery', data),
  openSavePortfolioQueryDialog: (data: SavePortfolioQueryDialogData) => showDialogWithData('SavePortfolioQuery', data),
  openCancelPortfolioMonitoringDialog: (data: CancelPortfolioMonitoringDialogData) =>
    showDialogWithData('CancelPortfolioMonitoring', data),
  openEnrichCompaniesDialog: (data: EnrichCompaniesDialogData) => showDialogWithData('EnrichCompanies', data),
  openEnrichPortfolioDialog: (data: EnrichPortfolioDialogData) => showDialogWithData('EnrichPortfolio', data),
  openInfoDialog: (data: InfoDialogData) => showDialogWithData('InfoDialog', data),
  openConfirmationDialog: (data: ConfirmDialogData) => showDialogWithData('ConfirmDialog', data),
  openDeleteDocumentDialog: (data: DeleteDocumentDialogData) => showDialogWithData('DeleteDocument', data),
  openDeleteCompaniesDialog: (data: DeleteCompaniesDialogData) => showDialogWithData('DeleteCompanies', data),
  openMoveCompaniesDialog: (data: MoveCompaniesDialogData) => showDialogWithData('MoveCompanies', data),
  openAssignCompaniesDialog: (data: AssignCompaniesDialogData) => showDialogWithData('AssignCompanies', data),
  openEnrichPersonDialog: (personId: string, redirectTo?: string) =>
    showDialogWithData('EnrichPerson', { view: EnrichPersonDialogView.Init, personId, redirectTo }),
  openCompanyPopupDialog: (data: CompanyPopupDialogData) => showDialogWithData(data.type, data),
  openPersonPopupDialog: (data: PersonPopupDialogData) => showDialogWithData(data.type, data),
  openEditGoalDialog: (data: EditGoalDialogData) => showDialogWithData('EditGoal', data),
  openDeleteGoalDialog: (data: DeleteGoalDialogData) => showDialogWithData('DeleteGoal', data),
  openDeleteContactDialog: (data: DeleteContactDialogData) => showDialogWithData('DeleteContact', data),
  openEditContactNoteDialog: (data: EditContactNoteDialogData) => showDialogWithData('EditContactNote', data),
  openEditProductDialog: (data: EditProductDialogData) => showDialogWithData('EditProduct', data),
  openDeleteProductDialog: (data: DeleteProductDialogData) => showDialogWithData('DeleteProduct', data),
  openEditTaskDialog: (data: EditTaskDialogData) => showDialogWithData('EditTask', data),
  openDeleteTaskDialog: (data: DeleteTaskDialogData) => showDialogWithData('DeleteTask', data),
  openCampaignReceivedDialog: (data: CampaignReceivedData) => showDialogWithData('CampaignReceived', data),
  openDeleteCampaignCompanyDialog: (data: DeleteCompanyDialogData) => showDialogWithData('DeleteCampaignCompany', data),
  openMoveCampaignCompanyDialog: (data: MoveCompanyDialogData) => showDialogWithData('MoveCampaignCompany', data),
  openSemanticClusterContactDialog: (data: SemanticClusterContactFormDialogData) =>
    showDialogWithData('SemanticClusterContactForm', data),
  openDeleteCustomTrackDialog: (data: DeleteCustomTrackDialogData) => showDialogWithData('DeleteCustomTrack', data),
  openRenameCustomTrackDialog: (data: RenameCustomTrackDialogData) => showDialogWithData('RenameCustomTrack', data),
  openDeleteConfigurationDialog: (data: DeleteConfigurationDialogData) =>
    showDialogWithData('DeleteConfiguration', data),
  openCreateConfigurationDialog: (data: InfoDialogData) => showDialogWithData('CreateConfiguration', data),
  openDeleteAppointmentDialog: (data: DeleteAppointmentDialogData) => showDialogWithData('DeleteAppointment', data),
  openSearchInformationDialog: () => showDialog('SearchInformation'),
  openSendToOtherModulesDialogProspecting: (data: SendToOtherModulesDialogDataProspecting) =>
    showDialogWithData('SendToOtherModulesDialogProspecting', data),
  openSendToOtherModulesDialogTargeting: (data: SendToOtherModulesDialogDataTargeting) =>
    showDialogWithData('SendToOtherModulesDialogTargeting', data),
  openSendToOtherModulesDialogPortfolio: (data: SendToOtherModulesDialogDataPortfolio) =>
    showDialogWithData('SendToOtherModulesDialogPortfolio', data),
  openUnlockPortfolioEsgDataDialog: (data: UnlockPortfolioEsgDataDialogData) =>
    showDialogWithData('UnlockPortfolioEsgDataDialog', data),
  openUnlockCompanyEsgDataDialog: (data: UnlockCompanyEsgDataDialogData) =>
    showDialogWithData('UnlockCompanyEsgDataDialog', data)
}

export type DialogActions = ActionsUnion<typeof DialogActions>
