/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  ChooseNameForCompaniesList: 'Scegli il nome del portafoglio di imprese',
  EnterARepresentativeName: 'Inserisci un nome che rappresenti il portafoglio che stai caricando.',
  EnterARepresentativeNameCompaniesList: 'Inserisci un nome che rappresenti il portafoglio che stai salvando.',
  ItWillIdentifyCompaniesList: 'Lo distinguerà dai portafogli che caricherai in seguito!',
  PortfolioName: 'Nome del portafoglio',
  InsertNameForPortfolio: 'Inserisci un nome per il tuo portafoglio',
  UploadPortfolio: {
    StandardTitle: 'Carica portafoglio',
    UploadPortfolioOrConnect: 'Carica un portafoglio o collega il CRM della tua impresa per iniziare a lavorare',
    UploadOrConnect: 'Carica un portafoglio o collega il CRM',
    UploadFromPlatform: 'Carica dalla piattaforma',
    UploadAPortfolio: 'Carica un portafoglio',
    SelectFromPc: 'Seleziona da PC',
    DownloadTemplateDescription1: 'Scarica il tracciato Base del portafoglio',
    DownloadTemplateDescription2:
      'Inserisci i dati delle tue imprese clienti come indicato nel foglio di esempio mantenendo inalterata la struttura delle tabelle',
    DownloadTemplateDescription3: 'Carica il tracciato nel passo successivo',
    DownloadTemplateDescription4:
      'Non conosci il Business identification number/CRIF Id? Contatta il servizio clienti al <0>support.ch@crif.com</0> per procedere col matching manuale',
    DownloadTemplate: 'Scarica il file',
    ChooseHowToUploadPortfolio: 'Scegli come caricare il portafoglio aziendale',
    SelectPortfolioFromPc: 'Seleziona il portafoglio dal tuo computer',
    YouCanLoad: 'Puoi caricare altri portafogli quando vuoi, tutti i portafogli saranno caricati separatamente!',
    ToEnsureTheCorrectLoading:
      'Per garantire il corretto caricamento delle imprese, assicurarsi di aver utilizzato il modello fornito',
    ChooseHowToUploadForUpdate: 'Scegli come aggiornare il tuo portafoglio',
    SelectCompanyListFromPC: 'Seleziona il portafoglio dal tuo PC',
    ToEnsureTheCorrectLoadingForUpdate:
      'Per garantire il corretto caricamento delle imprese, assicurati di aver utilizzato il template fornito da Margò. Scarica l\'elenco di base dalla funzione "Download portafoglio", copia e incolla i valori nel template, aggiornalo e carica il template compilato in questa sezione.',
    SelectFile: 'Seleziona il file',
    SelectFileFromComputer: 'Seleziona il file desiderato',
    ChoosePortfolioName: 'Scegli un nome di portafoglio',
    ItWillDistinguish: 'Lo distinguerà dalla lista che caricherai in seguito!',
    WrapperPage: {
      TypeOfUpload: 'Tipo di caricamento',
      TypeOfUploadDescription: 'Scegli il tipo di caricamento che vuoi utilizzare: standard o semplificato',
      StandardUpload: 'Caricamento standard',
      StandardUploadTooltip: 'Carica imprese su Margò attraverso il template dedicato',
      SimplifiedUpload: 'Caricamento semplificato',
      SimplifiedUploadTooltip: 'Carica un elenco di codici identificativi direttamente sul web, in modo semplificato'
    },
    Simplified: {
      Title: 'Caricamento portafoglio semplificato',
      FirstStepTitle: 'Incolla o digita i codici identificativi',
      AvailableCharacters: 'Caratteri disponibili: {{numberOfCharacters}}/{{maximumCharacters}}',
      UploadWarning:
        'ATTENZIONE: Chiudendo la pagina o tornando indietro senza salvare, tutte le modifiche andranno perse',
      UploadPlaceholder: 'Incolla o digita {{descriptionFields}} validi, separati da uno spazio',
      DescriptionFields: {
        ita: 'Codice Fiscale/Partita IVA/Numero CRIF',
        deu: 'ONR',
        czsk: 'Codice Fiscale/Partita IVA/Numero CRIF',
        che: 'Identificativo Azienda/CRIF Id /Partita Iva/Company book number'
      },
      Country: {
        Select: 'Seleziona il tuo paese',
        SK: 'Slovacchia',
        CZ: 'Repubblica Ceca',
        CHE: 'Svizzera',
        AUT: 'Austria'
      },
      Description1: 'Incolla o digita {{descriptionFields}} validi, separati da uno spazio',
      Description2: 'Clicca su "Continua" per i prossimi passaggi',
      Description3:
        'Eventuali errori verranno segnalati nella "Tabella Errori", accessibile cliccando su "Gestisci Errore" nella relativa notifica',
      ChooseStatus: {
        Title: 'Scegli lo stato che vuoi assegnare',
        Description1: 'Seleziona lo stato iniziale da assegnare alle imprese.',
        Description2: 'Verrà assegnato un unico stato a tutte le imprese del portafoglio.'
      }
    },
    ManageErrors: {
      Title: 'Visualizza e correggi gli errori',
      Description1: 'Clicca sul {{descriptionFields}} per modificarlo, o clicca sul cestino per eliminare la riga',
      Description2: 'Clicca su "Continua" per caricare il file',
      Description3: 'Trova le imprese corrette nel portafoglio',
      CompanyMatchStatusTitle: 'Status del caricamento',
      StatusDescriptionTitle: 'Descrizione status',
      DownloadList: 'Scarica lista',
      CompanyMatchStatus: {
        Rejected: 'Rifiutato',
        RejectedDescription: 'Il {{descriptionFields}} non è valido',
        Duplicate: 'Duplicata',
        DuplicateDescription: "Il {{descriptionFields}} inserito è già presente all'interno del portafoglio",
        NotMatched: 'Non matchata',
        NotMatchedDescription:
          'Il {{descriptionFields}} è valido, ma non è stata trovata alcuna corrispondenza nel database',
        Pending: 'In attesa di convalida',
        PendingDescription: 'Il {{descriptionFields}} è stato modificato. Fare clic su Continua per convalidarlo',
        Ceased: 'Cessata',
        CeasedDescription: "Il {{descriptionFields}} inserito fa riferimento ad un'impresa cessata"
      }
    }
  },
  ExcludeCustomVariables: 'Escludi Variabili Custom',
  ExcludeCustomVariablesConfirm:
    'Spostare le imprese senza Variabili Custom? In tal caso, le Variabili Custom del portafoglio di origine saranno eliminate.',
  Management: {
    Header: 'Portafoglio imprese',
    Upload: 'Carica Portafoglio',
    UploadTooltip: 'Carica imprese su Margò attraverso il template dedicato',
    UploadTooltipWithSimplified:
      'Carica un elenco di codici identificativi, utilizzando il metodo standard o semplificato',
    ShowCorporate: 'Portafoglio corporate',
    SearchPortfolioTooltip: 'Cerca portafoglio',
    SearchPortfolioPlaceholder: 'Cerca per nome del portafoglio',
    SearchHeader: 'Gestione Portafoglio - Risultati ricerca portafoglio',
    PortfolioTabs: {
      AllPortfolios: 'Tutti i portafogli',
      MyPortfolios: 'Miei portafogli',
      SharedPortfolios: 'Portafogli condivisi',
      ReceivedPortfolios: 'Portafogli ricevuti'
    },
    PortfolioName: {
      ChangePortfolioName: 'Cambia nome al portafoglio',
      EnterARepresentativeName: 'Inserire un nome per il portfolio',
      InsertPortfolioName: 'Inserisci il nome del portafoglio',
      NameChangedCorrectly: 'Nome modificato correttamente nel'
    },
    PortfolioNote: {
      AddNote: 'Aggiungi note',
      EditNote: 'Modifica note',
      AddANoteToDescribe: 'Aggiungi una note per descrivere il portafoglio',
      AddNotePlaceholder: 'Aggiungi note',
      AddNoteFieldLabel: 'Note'
    },
    DeletePortfolio: {
      DeletePortfolio: 'Elimina portafoglio',
      AreYouSure: 'Sei sicuro di voler eliminare {{name}}?',
      PortfolioDeletedCorrectly: '{{name}} eliminato correttamente.',
      DataWillRemain: 'Tutti le informazioni sulle imprese che hai scaricato, rimarranno nel tuo repository'
    },
    Table: {
      NumberOfCompanies: 'Numero di imprese',
      EnrichedCompanies: 'Imprese arricchite',
      CreationDate: 'Data di creazione',
      LastUpdateDate: 'Ultima data di aggiornamento ',
      CampaignsWithThisPortfolio: 'Campagne con questo portafoglio'
    },
    Menu: {
      ChangeName: 'Cambia nome',
      DuplicatePortfolio: 'Duplica portafoglio',
      DeletePortfolio: 'Elimina portafoglio',
      AddNote: 'Aggiungi note',
      EditNote: 'Modifica note'
    },
    Message: {
      Header: 'Clicca su "Carica portafoglio" per caricare il tuo primo portafoglio!',
      Description:
        "Qui puoi vedere una lista già salvata con il prospetto suggerito. Clicca sul suo nome e inizia a esplorarlo e gestire le imprese all'interno dell'elenco."
    },
    UploadComplete: 'Caricamento completato!',
    CompaniesUploadedCorrectly: 'Imprese caricate correttamente',
    CeasedCompaniesNotMatched: 'Imprese cessate o non matchate non caricate',
    ErrorsDueWrongData: 'Dati errati caricati',
    Download: 'Scarica',
    ListOfCeasedCompanies: 'Lista delle imprese',
    ListOfErrors: 'Lista degli errori',
    Stats: {
      Companies: 'Impresa',
      Companies_plural: 'Imprese',
      AllCompanies: 'Tutte le imprese',
      EnrichedCompanies: 'Imprese arricchite',
      Enriched: 'Arricchite',
      NotEnriched: 'Non arricchite',
      Prospect: 'Prospect',
      Lead: 'Lead',
      Client: 'Cliente',
      Client_plural: 'Clienti',
      Supplier: 'Fornitore',
      Supplier_plural: 'Fornitori',
      Competitor: 'Competitor',
      LastUpdate: 'Data di aggiornamento'
    },
    Enrich: 'Arricchito',
    EnrichTooltip: 'Ottieni dati aggiuntivi su queste imprese',
    Analyze: 'Analizza portafoglio',
    AnalyzeTooltip: 'Visualizza i dati del portafoglio rispetto alle principali variabili',
    Similarity: 'Genera similarity',
    SimilarityTooltip: "Applica l'algoritmo di similarità per trovare nuovi prospect",
    SelectSource: 'Caricamento imprese da file template',
    UpdatePortfolio: 'Portafoglio di aggiornamento'
  },
  Table: {
    Empty: 'Non abbiamo trovato imprese che corrispondano alla tua ricerca'
  },
  SelectColumns: 'Seleziona colonne',
  LoadMore: 'Carica altro',
  MapDescription:
    'La visualizzazione su mappa mostra le singole imprese indipendentemente dallo stato della stessa nel portafoglio',
  CompanyGroupActions: {
    Title: 'Azioni di gruppo',
    Actions: 'Azioni',
    AreaManager: 'Area manager',
    Sales: 'Sales',
    Products: 'Prodotti',
    Select: 'Seleziona',
    NotAssigned: 'Non assegnato',
    SelectAreaManager: 'Seleziona area manager',
    SelectSales: 'Seleziona sales',
    SelectProducts: 'Seleziona prodotto',
    ActionItems: {
      DuplicateCompany: "Duplica l'impresa",
      DeleteCompany: "Cancella l'impresa",
      MoveCompany: 'Sposta impresa in altro portafoglio',
      Enrich: 'Arricchisci',
      CreatePortfolio: 'Crea Portafoglio',
      DownloadExcel: 'Download Portafoglio',
      AddLocalUnits: 'Aggiunta Unità Locali',
      ShareCompanies: 'Condividi imprese',
      UnshareCompanies: 'Annulla condivisione companies'
    },
    CreatePortfolio: {
      Title: 'Crea Portafoglio',
      InputDescription: 'Inserisci un nome rappresentativo del portafoglio che stai creando',
      InsertPortfolioName: 'Inserisci il nome del portafoglio'
    },
    HiddenCompanyMessage: "L'operazione non sarà eseguita per le imprese appartenenti a portafogli nascosti."
  },
  Actions: {
    ViewCampaigns: 'Visualizza tutte le campagne relative',
    LoadQuery: 'Carica Ricerca',
    LoadQueryTooltip: 'Apri una ricerca salvata',
    SaveQuery: 'Salva Ricerca',
    SaveQueryTooltip: 'Salva questa ricerca nel modulo Portafoglio Imprese',
    Download: 'Download portafoglio',
    DownloadTooltip: 'Scarica il portafoglio in formato Excel sul tuo dispositivo',
    Analyze: 'Analizza portafoglio',
    AnalyzeTooltip: 'Visualizza i dati del portafoglio rispetto alle principali variabili',
    Similarity: 'Genera Similarity',
    SimilarityTooltip: "Applica l'algoritmo di similarità per trovare nuovi prospect",
    Campaign: 'Crea campagna',
    CampaignTooltip: 'Assegna le imprese alla forza vendita e pianifica una campagna commerciale',
    Update: 'Aggiorna Portafoglio',
    UpdateTooltip: 'Aggiungi nuove imprese a questo portafoglio o aggiorna le variabili custom',
    LocalUnits: 'Aggiunta unità locali',
    LocalUnitsTooltip: 'Aggiungi le unità locali connesse alle sedi in questo portafoglio',
    SendToOtherModules: 'Invia ad Altri Moduli',
    SendToOtherModulesTooltip: 'Invia le imprese al modulo Gestione Vendite o Analizza Mercato',
    UnlockEsg: 'Sblocca Score ESG',
    UnlockEsgTooltip: 'Sblocca lo Score ESG per le imprese nel portafoglio',
    MaxEsgUnlockExceededTooltip:
      'Puoi sbloccare il punteggio ESG per un massimo di {{count}} aziende alla volta. Seleziona meno aziende.'
  },
  Enriched: 'Arricchito',
  Enrich: 'Arricchire',
  Monitored: 'Seguito',
  Close: 'Chiudi',
  Filters: 'Filtri',
  UserFilters: 'Filtri utente',
  Download: {
    DownloadPortfolio: 'Download portafoglio',
    SelectTypeOfList: 'Seleziona il tipo di lista',
    SelectTypeOfListDescription: 'Seleziona il tipo di lista che vuoi scaricare.',
    DownloadCost: 'Il costo del download sarà lo stesso per ogni tipo di elenco.',
    Base: 'Base',
    BaseDescription: "L'elenco conterrà la serie base di dati relativi alle società.",
    Complete: 'Completo',
    CompleteDescription: "L'elenco conterrà il set completo di dati relativi alle società",
    ExceededMaximumCompanies: 'Superato numero massimo di imprese scaricabili',
    Marketing: 'Marketing',
    Standard: 'Marketing',
    MarketingDescription: "L'elenco conterrà l'insieme standard di dati relativi alle società",
    Custom: 'Custom',
    CustomDescription: "Puoi personalizzare l'elenco per scaricare e/o salvare solo il set di dati di cui hai bisogno",
    CustomTracks: 'Tracciati salvati',
    CustomTracksDefaultSelection: 'Nessun tracciato custom selezionato',
    SaveCustomTrack: 'Salva Tracciato Custom',
    SaveCustomTrackQuestion: 'Vuoi salvare i data packet da te scelti per questo tracciato custom?',
    SaveCustomTrackTitle: 'Salva tracciato custom',
    SaveCustomTrackDescription:
      'Inserisci un nome da dare al tuo tracciato custom o seleziona un tracciato salvato per sovrascriverlo.',
    SaveCustomTrackName: 'Nome tracciato custom',
    SaveCustomTrackUpdateExisting: 'Aggiorna esistente',
    SaveCustomTrackCreateNew: 'Crea nuova',
    DownloadExample: 'Scarica Esempio',
    Download: 'Download',
    ForEveryCompanyInList: "per ogni impresa nell'elenco",
    ToDownloadYouNeedToPurchase:
      'Per effettuare il download della lista, è necessario arricchire le imprese con dati {{companyName}}',
    YouAreDownloading:
      'Stai per scaricare un file Excel contenente tutti i dati {{companyName}} richiesti. Verrà salvato nella sezione "Utilizzo" del tuo account.',
    CustomExport: {
      StepTitle: 'Seleziona informazioni',
      Description1: 'Seleziona le informazioni che vuoi includere nel tuo download',
      Description2: 'Il costo del download sarà lo stesso per ogni tipo di lista.',
      DataPacket: 'Data packet',
      DataPacketSearchPlaceholder: 'Cerca datapacket o campi specifici'
    },
    Dialog1: 'Il file richiesto è in elaborazione e potrebbe richiedere alcuni minuti.',
    Dialog2: "Puoi seguire l'avanzamento dal menu notifiche.",
    SaveSuccess: 'salvato come Tracciato Custom',
    SaveSuccessDescription: 'Troverai il tracciato salvato nel repository della relativa sezione.'
  },
  Enrichment: {
    PortfolioEnrichment:
      "Arricchimento Imprese. L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?",
    Enrichment: 'Arricchimento',
    EnrichmentComplete: 'Arricchimento completato',
    EnrichmentDescription:
      "Arricchendo l'impresa con dati {{companyName}}, queste informazioni saranno scaricate e aggiornate periodicamente",
    CompaniesInPortfolio: 'Imprese in questo portafoglio',
    CompaniesEnriched: 'Imprese già arricchite',
    CompaniesNotEnriched: 'Imprese non arricchite',
    CreditsWillBeUsed: 'Rings usati',
    CreditsRemaining: 'Rings residui',
    CompaniesSuccesfullyEnriched: 'Imprese arricchite'
  },
  CompanyDeletion: {
    ConfirmDelete: "Sei sicuro che vuoi cancellare l'impresa?",
    DeleteSuccess: "Impresa cancellata correttamente. L'operazione sarà eseguita in pochi second."
  },
  CompaniesMove: {
    ConfirmMove:
      "L'impresa che hai selezionato verrà rimossa dal portafoglio attuale e aggiunta in un altro portafoglio. Seleziona un portafoglio dalla lista.",
    ConfirmMove_plural:
      'Le imprese che hai selezionato verranno rimosse dal portafoglio attuale e aggiunte in un altro portafoglio. Seleziona un portafoglio dalla lista.',
    MoveSuccess: "Lo spostamento è in corso, l'operazione potrebbe richiedere alcuni minuti.",
    SelectPortfolio: 'Seleziona portafoglio'
  },
  CompanyAssignment: {
    ConfirmOperation: 'Conferma Operazione',
    ProductAssignment:
      "Assegnazione del prodotto {{product}} alle imprese selezionate. L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?",
    SalesAssignment:
      "Assegnazione del venditore {{sales}} alle imprese selezionate. L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?",
    AreaManagerAssignment:
      "Assegnazione del venditore {{manager}} alle imprese selezionate. L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?",
    ProductUnassignment:
      "Rimozione assegnazione per le imprese selezionate.  L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?",
    SalesUnassignment:
      "Rimozione assegnazione per le imprese selezionate.  L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?",
    AreaManagerUnassignment:
      "Rimozione assegnazione per le imprese selezionate.  L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?"
  },
  DialogError: "Si è verificato un errore durante l'esecuzione della richiesta.",
  Credits: 'Rings',
  LoadQuery: {
    DialogTitle: 'Carica una ricerca salvata',
    SaveBefore: {
      Question: 'Vuoi salvare la ricerca di prospect prima di caricare una ricerca?',
      Description:
        'Salva la tua ricerca di prospect prima di caricare la tua ricerca, per evitare di perdere informazioni.',
      OkBtn: 'Salva ricerca',
      CancelBtn: 'Salta il salvataggio'
    }
  },
  DomainFilter: {
    SaveAndClose: 'Salva filtri e chiudi'
  },
  ManageDuplicates: {
    Title: 'Gestisci duplicati',
    Description: "Per duplicare un'impresa, è necessario cambiare il suo stato o il prodotto",
    GroupActions: 'Azioni di gruppo',
    Duplicates: '{{count}} duplicato',
    Duplicates_plural: '{{count}} duplicati',
    ShowOK: 'Spettacolo OK',
    HideOK: 'Nascondere OK',
    SelectColumns: 'Seleziona colonne'
  },
  GenerateSimilarity: {
    Title: 'Genera similarity: seleziona uno stato rappresentativo',
    TitleClusterAllowed: 'Genera Similarity',
    Description: "Per iniziare l'analisi, è necessario selezionare il tipo di stato che intendi processare",
    DropdownPlaceholder: 'Seleziona stato',
    GotoActionButton: 'Seleziona',
    IncludeSemanticClusters: 'Includi Classi Settoriali Margò'
  },
  Campaign: {
    YouNeedToEnrich: 'Per creare una campagna, tutte le imprese nel portafoglio devono essere arrichite.'
  },
  CreatingPortfolio: 'Creare portafoglio',
  UpdatingPortfolio: 'Aggiornamento',
  Share: {
    SharePortfolioTitle: 'Condividi portafoglio',
    Subtitle: 'Seleziona gli utenti',
    Description: 'seleziona gli utenti con cui vuoi condividere le imprese',
    Success: 'Aggiornamento condivisione avvenuto con successo.',
    ShareCompany: 'Condividi impresa',
    DeleteSharing: 'Elimina condivisione',
    SharedCompanies: 'Aziende condivise',
    SharedPortfolio: 'Portafoglio condiviso',
    SharePortfolio: 'Condivisione portafoglio',
    SharedByOwner: 'Condiviso da {{ownerName}}',
    NotActiveUser: 'utente non attivo',
    ShareCompanies: {
      Title: 'Condividi imprese',
      Subtitle: 'Seleziona gli utenti',
      Description: 'Seleziona gli utenti con cui vuoi condividere le imprese',
      WithAreaManager: 'Condividi le imprese con area manager',
      WithSales: 'Condividi le imprese con sales',
      ConfirmTitle: 'Conferma Operazione',
      ConfirmSubtitle: 'Aggiornamento condivisione con utente selezionato.',
      ConfirmDescription: "L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?",
      Check: 'Verifica se tutte le società sono valide per la condivisione.',
      CheckFail: 'Per proseguire è necessario assegnare le imprese selezionate.'
    },
    UnshareCompanies: {
      Title: 'Annulla condivisione companies',
      Subtitle: 'Seleziona gli utenti',
      Description: 'Seleziona gli utenti con cui vuoi annullare la condivisione',
      WithAreaManager: 'Annulla condivisione companies con area manager',
      WithSales: 'Annulla condivisione companies con sales',
      ConfirmTitle: 'Conferma Operazione',
      ConfirmSubtitle: 'Updating unsharing from the selected user',
      ConfirmDescription: "L'operazione potrebbe richiedere alcuni minuti. Procedere con l'operazione?",
      Check: "Verifica se tutte le società sono valide per l'annullamento della condivisione.",
      CheckFail: 'Per proseguire è necessario assegnare le imprese selezionate.'
    }
  },
  Exclude: {
    Title: 'Escludi portafoglio',
    ExcludedPortfolio: 'Portafoglio Escluso',
    Description: 'Seleziona gli utenti ai quali escludere il portafoglio selezionato',
    Success: 'Condivisione in esclusione eseguita correttamente.',
    Error: 'Si è verificato un errore. Non è stato possibile condividere in esclusione questo portafoglio.'
  },
  AddLocalUnits: {
    Title: 'Aggiunta unità locali',
    NoCompanySelected: 'Nessuna impresa selezionata. Aggiungere almeno una Sede Amministrativa.',
    NoHeadquarter: 'Il portafoglio selezionato contiene già Unità Locali. Aggiungere almeno una Sede Amministrativa.',
    NoHeadquarterSelected: 'Le imprese selezionate sono già Unità Locali. Aggiungere almeno una Sede Amministrativa.',
    Enrich: {
      Title:
        "Per effettuare l'aggiunta delle unità locali, è necessario arricchire le imprese con dati {{companyName}}",
      Description:
        'Stai per aggiungere unità locali in un portafoglio contenente tutti i dati {{companyName}} richiesti. Verrà salvato nella sezione "Utilizzo" del tuo account.'
    },
    ChooseBranch: {
      Title: 'Scegli le unità locali da aggiungere.',
      Description: 'Seleziona il tipo di unità locale che vuoi aggiungere.',
      All: 'Tutte le tipologie di Unità Locali',
      DeleteAll: 'Elimina tutti'
    },
    ChooseStatus: {
      Title: 'Scegli lo status da aggiungere.',
      Description: 'Seleziona lo status iniziale per le imprese che vuoi aggiungere.'
    },
    CreateTag: {
      Title: 'Crea tag.',
      Description: 'Ogni impresa verrà definita da un tag creato da te.',
      Label: 'Crea tag (opzionale)',
      Placeholder: 'Inserisci tag'
    }
  },
  EditPortfolioCompany: {
    Title: 'Modifica valori azienda',
    Subtitle: 'Cambio valori',
    Description:
      'Scegli quali campi vuoi modificare e inserisci il nuovo valore bel box. Nel caso in cui ci sia più volte la stessa impresa in un portafoglio, non sarà possibile modificare i campi Stato in portafoglio e Prodotto.',
    CompanyStatus: 'Stato in portafoglio',
    Amount: 'Importo ricevuto',
    AreaManager: 'Area Manager',
    Sales: 'Sales',
    Product: 'Prodotto',
    Tags: 'Tags',
    Save: 'Salva',
    Error: 'Errore',
    ErrorDescription: 'Impossibile caricare i dati. Riprovare più tardi.',
    SaveSuccessTitle: 'Modifiche salvate',
    SaveSuccessText: 'Le modifiche sono state salvate correttamente.',
    SaveErrorTitle: 'Errore'
  },
  SendToOtherModulesDialog: {
    Title: 'Seleziona modulo',
    SubTitle: 'Seleziona il modulo a cui vuoi inviare la tua analisi o lista',
    Targeting: 'Analizza mercato',
    TargetingDescription:
      'Clicca qui per inviare la lista di imprese individuate al modulo Analizza Mercato per consultare grafici aggiornati che forniscono una rappresentazione della distribuzione delle aziende rispetto alle principali variabili.',
    SalesTool: 'Gestione vendite',
    SalesToolDescription:
      'Clicca qui per inviare la lista di imprese individuate al modulo Gestione Vendite. La lista di imprese verrà salvata in un portafoglio e i dati delle imprese verranno arricchiti. All’interno del modulo Gestione Vendite, potrai avviare una campagna di vendite sulle imprese selezionate ed assegnarla alla tua forza vendita.',
    OkButton: 'Invia',
    DisabledTargetingTooltip: 'Questa azione è possibile solo per portafogli con più di {{count}} società'
  },
  MonitoringRemoval: {
    Title: 'Smetti di seguire',
    ButtonTooltip: 'Stai seguendo questo portafoglio. Clicca qui per smettere di seguire.',
    UnfollowSuccess: 'Rimozione portafoglio seguito eseguita con successo.',
    UnfollowAndSaveSuccess: 'Rimozione portafoglio seguito eseguita con successo e query {{queryName}} salvata.',
    UnfollowAndUpdateSuccess: 'Rimozione portafoglio seguito eseguita con successo e query {{queryName}} salvata.',
    UnfollowFailure:
      'Rimozione portafoglio seguito è fallita. Prego riprovare più tardi o contatti il nostro servizio clienti.',
    WarningMessage:
      'Cliccando salva il portafoglio non sarà più monitorato. Nota che per questo portafoglio non sarà più possibile ripristinare lo stato monitorato. Vuoi salvare la query che è stata usata per la creazione di questo portafoglio?'
  },
  UnlockPortfolioEsgDataDialog: {
    Title: 'Sblocca Score ESG',
    SubTitle: 'Sei sicuro di voler sbloccare gli Score ESG?',
    UnlockText:
      "Controlleremo e sbloccheremo gli Score Specifici ESG per tutte le imprese che li posseggono e i dati saranno visibili per sei mesi dalla data di sblocco, o fino alla data di fine sottoscrizione se antecedente. Ogni sblocco costerà un credito ESG. Alla data di scadenza dei dati ESG per le imprese sbloccate, sarà necessario sbloccarli nuovamente. Invieremo una notifica al termine dell'operazione. Potrebbe richiedere alcuni minuti.",
    UnlockRequestedText:
      "La richiesta per sbloccare lo score ESG dell'azienda è stata effettuata con successo. L'operazione potrebbe richiedere alcuni minuti. Ora puoi chiudere questa finestra di dialogo.",
    EnrichmentRequiredText:
      "Siamo spiacenti, l'operazione non può essere completata in quanto sono presenti imprese non arricchite nella lista. Per continuare, arricchisci le imprese o seleziona imprese già arricchite.",
    OkUnlock: 'Conferma',
    OkEnrich: 'Arricchito',
    ErrorUnlockingEsgData:
      "Errore imprevisto durante lo sblocco dello score ESG dell'azienda. Si prega di riprovare più tardi."
  }
}
