/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  PM001: 'Firma korrekt hochgeladen. Upload des Portfolios {{portfolioName}} ist vollständig!',
  PM001_plural: 'Firmen erfolgreich hochgeladen. Upload des Portfolios {{portfolioName}} ist vollständig!',
  PM002: 'Details zum Upload-Fehler des Portfolios {{portfolioName}} aufrufen',
  PM003: '{{ceasedUnmatched}} Firma nicht übernommen, da erloschen oder keine Übereinstimmung',
  PM003_plural: '{{ceasedUnmatched}} Firmen nicht übernommen, da erloschen oder keine Übereinstimmung',
  PM004: 'Fehler aufgrund falscher Daten',
  PM004_plural: 'Fehler aufgrund falscher Daten',
  PM005: 'Download-Liste {{exportType}} des Portfolios {{portfolioName}} abgeschlossen',
  PM006: 'Download PDF der Firma {{companyName}} abgeschlossen',
  PM007: 'Duplizierung von {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} korrekt ausgeführt',
  PM008: 'Das Löschen von {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} wurde korrekt ausgeführt',
  PM009:
    '{{count}} Unternehmen aus dem Portfolio {{sourcePortfolioName}} wurde erfolgreich in das Portfolio {{targetPortfolioName}} verschoben.',
  PM009_plural:
    '{{count}} Unternehmen von Portfolio {{sourcePortfolioName}} wurden erfolgreich in das Portfolio {{targetPortfolioName}} verschoben.',
  PM010: 'Anreicherung von {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} korrekt ausgeführt',
  PM011:
    'Freigabe von {{companyNumber}} Unternehmen aus Portfolio {{portfolioName}} für {{userName}} korrekt ausgeführt',
  PM012: 'Anwender hat {{companyNumber}} Unternehmen zugewiesen',
  PM013: 'Entfernen der Freigabe von {{portfolioName}} wurde korrekt ausgeführt',
  PM014Part1:
    'Die Zuordnung von {{productName}} zu {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} wurde korrekt durchgeführt.',
  PM014Part1_plural:
    'Die Zuordnung von {{productName}} zu {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} wurde korrekt durchgeführt.',
  PM014Part2:
    ' Die Produktzuweisung wurde für das Unternehmen {{numberOfDuplicates}} aufgrund einer Duplizierung der Zeile nicht abgeschlossen.',
  PM014Part2_plural:
    ' Die Produktzuweisung wurde für {{numberOfDuplicates}} Unternehmen aufgrund einer Duplizierung der Zeile nicht abgeschlossen.',
  PM015: '{{salesName}} {{numberOfCompanies}} Unternehmen in {{portfolioName}} korrekt zugewiesen',
  PM017: '{{managerName}} {{numberOfCompanies}} Unternehmen in {{portfolioName}} korrekt zugewiesen',
  PM014_0: '{{numberOfCompanies}} Unternehmen in {{portfolioName}} korrekt zugewiesen',
  PM015_0: '{{numberOfCompanies}} Unternehmen in {{portfolioName}} korrekt zugewiesen',
  PM017_0: '{{numberOfCompanies}} Unternehmen in {{portfolioName}} korrekt zugewiesen',
  PM019: 'Kampagne {{campaignName}} mit {{numberOfCompanies}} Unternehmen erstellt',
  PM019_plural: 'Kampagne {{campaignName}} mit {{numberOfCompanies}} Unternehmen erstellt',
  PM020: '{{numberOfCompanies}} korrekt angereichert',
  PM021: '{{initiatorUserName}} Unternehmen mit {{numberOfCompanies}} erfolgreich geteilt.',
  PM022: '{{initiatorUserName}} Unternehmen werden von {{numberOfCompanies}} nicht mehr geteilt',
  PM023: 'Teilen wurde erfolgreich ausgeführt.',
  PM024: 'Teilen wurde erfolgreich ausgeführt.',
  PM025: '{{initiatorUserName}} hat {{numberOfCompanies}} Unternehmen mit {{numberOfSalesUsers}} Benutzern geteilt',
  PM026:
    '{{initiatorUserName}} beendet das Teilen von {{numberOfCompanies}} Unternehmen mit {{numberOfSalesUsers}} Benutzern',
  PM027: 'Fehler aufgrund falscher Daten',
  PM027_plural: 'Fehler aufgrund falscher Daten',
  PM028: 'Download der Update-Vorlage abgeschlossen',
  PM029: 'Portfolio {{portfolioName}} wird nicht mehr verfolgt',
  LL001:
    'Hinzufügen von lokalen Einheiten. Das Portfolio wurde mit {{numberOfCompanies}} lokale Einheiten aktualisiert.',
  ST001: 'Kampagne {{campaignName}} mit {{numberOfCompanies}} Unternehmen erstellt.',
  ST001_plural: 'Kampagne {{campaignName}} mit {{numberOfCompanies}} Unternehmen erstellt.',
  ST001WithDuplicates: '{{numberOfDuplicates}} Duplikat wurde weggelassen.',
  ST001WithDuplicates_plural: '{{numberOfDuplicates}} Duplikate wurden weggelassen.',
  ST002: '{{userName}} hat {{numberOfCompanies}} Unternehmen aus der Kampagne {{campaignName}} gelöscht',
  ST002_plural: '{{userName}} hat {{numberOfCompanies}} Unternehmen aus der Kampagne {{campaignName}} gelöscht',
  ST003: '{{userName}} hat {{numberOfCompanies}} Unternehmen der Kampagne {{campaignName}} zugewiesen.',
  ST003_plural: '{{userName}} hat {{numberOfCompanies}} Unternehmen der Kampagne {{campaignName}} zugewiesen.',
  ST003WithDuplicates: '{{numberOfDuplicates}}  Duplikat wurde weggelassen.',
  ST003WithDuplicates_plural: '{{numberOfDuplicates}} Duplikate wurden weggelassen.',
  ST004: 'Die Kampagne {{campaignName}} startet am {{startDate}}',
  ST005: 'Die Kampagne {{campaignName}} wird enden am {{endDate}}',
  ST006: 'Das {{portfolioName}} Portfolio wurde erfolgreich zur {{campaignName}} Kampagne hinzugefügt.',
  ST007: 'Download Excel der Kampagne {{campaignName}} abgeschlossen',
  ST009: 'Neue Zuordnungen von {{numberOfCompanies}} Unternehmen für die {{campaignName}} Kampagne ist abgeschlossen.',
  ST010: '{{numberOfCompanies}} Unternehmen wurde erfolgreich zur Kampagne {{campaignName}} hinzugefügt.',
  ST010_plural: '{{numberOfCompanies}} Unternehmen wurden erfolgreich zur Kampagne {{campaignName}} hinzugefügt.',
  ST011:
    'Zuweisung der Aufgabe {{taskName}} an {{numberOfCompanies}} Unternehmen für die Kampagne {{campaignName}} abgeschlossen.',
  ST012: '{{userName}} hat {{salesName}} {{numberOfCompanies}} Unternehmen der Kampagne {{campaignName}} zugewiesen.',
  ST012_plural:
    '{{userName}} hat {{salesName}} {{numberOfCompanies}} Unternehmen der Kampagne {{campaignName}} zugewiesen.',
  ST012WithDuplicates: '{{numberOfDuplicates}}  Duplikat wurde weggelassen.',
  ST012WithDuplicates_plural: '{{numberOfDuplicates}} Duplikate wurden weggelassen.',
  ST013: 'Zuweisung zum Unternehmen {{numberOfCompanies}} für die Kampagne {{campaignName}} entfernt.',
  ST013_plural: 'Zuweisung zu {{numberOfCompanies}} Unternehmen für die {{campaignName}} Kampagne entfernt.',
  ST014: '{{userName}} hat {{salesName}} {{numberOfCompanies}} Unternehmen der Kampagne {{campaignName}} entfernt.',
  ST014_plural:
    '{{userName}} hat {{salesName}} {{numberOfCompanies}} Unternehmen der Kampagne {{campaignName}} entfernt.',
  PR001: '{{numberOfCompanies}} neue Unternehmen wurden in das überwachte Portfolio {{portfolioName}} aufgenommen(DE)',
  PR002:
    'Einfügen und Anreicherung von {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} korrekt ausgeführt',
  PR003:
    'Erstellung des Portfolios {{portfolioName}} und das Hinzufügen von {{numberOfCompanies}} Unternehmen war erfolgreich',
  PR004:
    'Das Einfügen und Anreichern von {{numberOfCompanies}} Unternehmen im verfolgten Portfolio {{portfolioName}} wurde korrekt ausgeführt',
  PR005:
    'Erstellung des verfolgten Portfolios {{portfolioName}} und Einfügen der mit {{numberOfCompanies}} angereicherten Unternehmen korrekt ausgeführt',
  PR006: 'Die Anreicherung zum Portfolio <0> {{portfolioName}} </0> wurde erfolgreich ausgeführt. {{exclusionWarning}}',
  PR007:
    'Einfügen und Anreicherung von {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} korrekt ausgeführt',
  TR001:
    'Erstellung des Portfolios {{portfolioName}} und das Hinzufügen von {{numberOfCompanies}} Unternehmen war erfolgreich',
  TR002:
    'Einfügen und Anreicherung von {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} korrekt ausgeführt',
  TA001: 'Download Excel von Analyze Market abgeschlossen',
  TA002: 'Download PDF von Analyze Market abgeschlossen',
  DO002: 'Die Datei wurde erstellt und kann nun heruntergeladen werden.',
  DO003: 'Die {{module}} Suche {{queryName}} wurde von {{grantorUserName}} mit Ihnen geteilt.',
  PM002Event1: 'Erloschene Unternehmen nicht hochgeladen',
  PM002Event2:
    'Laden fehlgeschlagen wegen falscher Daten. Sie können die Vorlage später im Bereich "Portfolio Management" hochladen',
  OP001:
    'Erstellen des Portfolios {{portfolioName}} und Einfügung von {{numberOfCompanies}} Unternehmen korrekt ausgeführt',
  OP001_plural:
    'Erstellen des Portfolios {{portfolioName}} und Einfügung von {{numberOfCompanies}} Unternehmen korrekt ausgeführt',
  OP002:
    'Einfügen und Anreicherung von {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} korrekt ausgeführt',
  OP003: 'Ihr angereichertes Portfolio {{portfolioName}} wurde korrekt erstellt. {{exclusionWarning}}',
  OP004: 'Anreicherung von {{numberOfCompanies}} Unternehmen im Portfolio {{portfolioName}} korrekt ausgeführt',
  MissingMessage: 'Hinweistext für Typ {{type}} fehlt.',
  ExcludedCompaniesWarning: 'Unternehmen, die zu verdeckten Portfolios gehören, wurden nicht verarbeitet.',
  SalesTool: 'Sales Tool',
  Portfolio: 'Unternehmensportfolio',
  Prospecting: 'Prospecting',
  Operations: 'Operatione',
  Targeting: 'Analyze Market',
  DownloadPDF: 'PDF herunterladen',
  ShareQuery: {
    Title: 'Abfrage teilen'
  },
  EsgUnlockComplete:
    'ESG-Unternehmensbewertungen wurden für {{numberOfUnlockedCompanies}}-Unternehmen mit allen verfügbaren Daten im Portfolio {{portfolioName}} freigeschaltet. {{numberOfNotUnlockedCompanies}} wurden aufgrund bereits entsperrter oder fehlender Daten nicht entsperrt.',
  NotificationArchive: {
    Title: 'Benachrichtigung',
    Description: 'Hier finden Sie Ihre Benachrichtigung (Listenansicht)',
    Notifications: '{{notificationsNumber}} Benachrichtigung',
    Welcome: 'Willkommen in margò:',
    NoNotifications: 'In diesem Bereich erscheinen nach erstmaliger Aktionsausführung Hinweise'
  },
  Table: {
    Header: {
      Date: 'Datum',
      Section: 'Bereich',
      Message: 'Nachricht'
    }
  },
  NotificationPopup: {
    Open: 'Öffnen',
    ManageErrors: 'Fehler verwalten',
    DownloadList: 'Liste herunterladen',
    DownloadFile: 'Datei heruntergeladen',
    ShareQueryAction: 'Gemeinsame Suche speichern'
  },
  PushEvent: {
    UploadProgress: 'Ihr Portfolio wird hochgeladen',
    UploadProgressFinished: 'Upload abgeschlossen',
    DeleteCompaniesProgress: 'Das Löschen ausgewählter Unternehmen wird durchgeführt',
    DeleteCompaniesProgressFinished: 'Das Löschen ausgewählter Unternehmen ist abgeschlossen',
    EnrichCompaniesProgress: 'Das Anreichern ausgewählter Unternehmen wird durchgeführt',
    EnrichCompaniesProgressFinished: 'Das Anreichern ausgewählter Unternehmen ist abgeschlossen',
    CreatePortfolioFromSelectionProgress: 'Erstellung eines Portfolios ausgewählter Unternehmen ist in Arbeit',
    CreatePortfolioFromSelectionProgressFinished:
      'Erstellen eines Portfolios ausgewählter Unternehmen ist abgeschlossen',
    DuplicateProgress: 'Ihr Portfolio wird dupliziert',
    DuplicateProgressFinished: 'Duplizieren abgeschlossen',
    ShareCompaniesProgress: 'Die Unternehmen werden geteilt',
    ShareCompaniesProgressFinished: 'Das Teilen ist abgeschlossen',
    UnshareCompaniesProgress: 'Das Teilen wurde beendet',
    UnshareCompaniesProgressFinished: 'Companies were stopped shared',
    AssignSalesProgress: 'Zuweisung an Sales wird durchgeführt',
    AssignSalesProgressFinished: 'Zuweisung an Sales ist abgeschlossen',
    AssignAreaManagerProgress: 'Zuweisung an Area Manager wird durchgeführt',
    AssignAreaManagerProgressFinished: 'Zuweisung an Area Manager ist abgeschlossen',
    AssignProductProgress: 'Assign to Product in progress',
    AssignProductProgressFinished: 'Assign to Product completed',
    ExportPortfolioProgress: 'Ihr Portfolio {{portfolioName}} wird zum Download vorbereitet',
    ExportPortfolioProgressFinished: 'Ihr Portfolio-Download {{portfolioName}} ist fertig',
    AddLocalUnitsToPortfolioProgress: 'Lokale Einheit hinzufügen. Portfolio-Aktualisierung in Bearbeitung',
    ProspectingSaveListProgress: 'Portfolio wird erstellt.',
    ProspectingSaveListProgressFinished: 'Portfolioerstellung abgeschlossen.',
    ProspectingUpdateListProgress: 'Aktualisierung des Portfolios ist in Arbeit.',
    ProspectingUpdateListProgressFinished: 'Aktualisierung des Portfolios ist abgeschlossen.',
    TargetingSaveListProgress: 'Portfolioerstellung aus Analyze Market läuft',
    TargetingSaveListProgressFinished: 'Portfolioerstellung abgeschlossen.',
    TargetingUpdateListProgress: 'Aktualisierung des Portfolios von Analyze Market läuft',
    TargetingUpdateListProgressFinished: 'Aktualisierung des Portfolios ist abgeschlossen.',
    UpdateCampaignTaskProgress: 'Ihre Kampagne wird aktualisiert',
    UpdateCampaignTaskProgressFinished: 'Ihre Kampagne wurde aktualisiert',
    OperationsSaveListProgress: 'Portfolio wird erstellt.',
    OperationsSaveListProgressFinished: 'Portfolioerstellung abgeschlossen.',
    OperationsUpdateListProgress: 'Aktualisierung des Portfolios ist in Arbeit.',
    OperationsUpdateListProgressFinished: 'Aktualisierung des Portfolios ist abgeschlossen.',
    UnlockEsgScoresProgress: 'Entsperrvorgang läuft',
    UnlockEsgScoresProgressFinished: 'Der Entsperrvorgang ist abgeschlossen',
    PdfGenerateProgress: 'PDF-Erstellung für die Firma {{companyName}} in Arbeit'
  },
  Banner: {
    RingsWarningMessage:
      'Vorsicht! Ihre verbleibenden Rings sind weniger als 10%. Bitte überprüfen Sie <1>Ihr Konto</1> oder kontaktieren Sie unser Verkaufsteam.'
  },
  ContactsDownloadStart: 'Ihre Kontakte werden zum Download vorbereitet',
  ContactsDownloadEnd: 'Der Download der Kontakte ist abgeschlossen',
  Contacts: 'Kontakte'
}
