/* eslint-disable import/no-default-export */
export default {
  BackToLogin: 'Zpět na přihlášení',
  SimpleUsefulPowerful: 'Jednoduché, užitečné a výkonné.',
  MargoWasSurprise: 'Margo bylo pro mě a můj tým úžasným překvapením.',
  ContactCrif: 'Kontaktujte {{companyName}} a založte si účet nebo požádejte o informace.',
  ContactUsText: 'Kontaktujte nás a založte si účet nebo požádejte o informace.',
  Name: 'Jméno',
  InsertName: 'Zadejte své jméno',
  Surname: 'Příjmení',
  InsertSurname: 'Zadejte své příjmení',
  Email: 'E-mail',
  InsertEmail: 'Zadejte svůj e-mail',
  Message: 'Zpráva',
  InsertMessage: 'Sem napište svůj text',
  IAgreeWith: 'Souhlasím s',
  PrivacyPolicy: 'politikou ochrany osobních údajů',
  Send: 'Poslat',
  Success: 'Vaše zpráva byla odeslána společnosti {{companyName}}.',
  ContactCRIF: 'Kontaktujte {{companyName}}',
  ContactUs: 'Kontaktujte nás',
  Company: 'Společnost',
  InsertCompany: 'Zadejte svou společnost',
  Country: 'Země',
  InsertCountry: 'Vyberte svoji zemi',
  JobTitle: 'Název pracovní pozice',
  InsertJobTitle: 'Zadejte název své pracovní pozice',
  Telephone: 'Telefonní číslo',
  InsertTelephone: 'Zadejte své telefonní číslo',
  CompanyName: 'Název společnosti',
  ContactCustomer: 'Kontaktujte zákazníka',
  EnterContactDetails: 'Zadejte kontaktní údaje',
  ContactName: 'Jméno kontaktní osoby',
  ContactSurname: 'Příjmení kontaktní osoby',
  ContactEmail: 'E-mail kontaktní osoby',
  ContactTelephone: 'Telefonní číslo kontaktní osoby',
  ConfirmOperation: 'Potvrďte operaci',
  ConfirmOperationDescription: 'Operace může trvat několik minut. Chcete pokračovat?',
  CreateOffer: 'Vytvořte nabídku',
  ContactCustomerOnline: 'Kontaktujte zákazníka online'
}
