import { notification as notificationAntd } from 'antd'
import { ReactNode } from 'react'

type Props = {
  content: ReactNode
  key: string
}

export const wrapper = ({ content, key, ...rest }: Props) => {
  notificationAntd.open({
    ...rest,
    key,
    duration: 5,
    message: content,
    className: 'notification-wrapper'
  })
}
