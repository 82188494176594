import { Reducer } from 'redux'
import _ from 'lodash'
import * as actions from './actions'
import { Notification, PushEvent } from './types'

export type NotificationState = {
  notifications: Notification[]
  lastNotificationId: string | null
  hasNewNotifications: boolean
  numberOfNotifications: number | null
  numberOfNotificationsToDisplay: number
  pushEvents: PushEvent[]
}

export const initState: NotificationState = {
  notifications: [],
  lastNotificationId: null,
  hasNewNotifications: false,
  numberOfNotifications: null,
  numberOfNotificationsToDisplay: 0,
  pushEvents: []
}

export const notificationsReducer: Reducer<NotificationState, actions.NotificationActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case actions.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications
      }
    case actions.ADD_OLD_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload.notifications]
      }
    case actions.ADD_NEW_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...action.payload.notifications, ...state.notifications]
      }
    case actions.SET_LAST_NOTIFICATION_ID:
      return {
        ...state,
        lastNotificationId: action.payload.notificationId
      }
    case actions.SET_HAS_NEW_NOTIFICATIONS:
      return {
        ...state,
        hasNewNotifications: action.payload.hasNewNotifications
      }
    case actions.SET_NUMBER_OF_NOTIFICATIONS:
      return {
        ...state,
        numberOfNotifications: action.payload.number
      }
    case actions.SET_NUMBER_OF_NOTIFICATIONS_TO_DISPLAY:
      return {
        ...state,
        numberOfNotificationsToDisplay: action.payload.number
      }
    case actions.SET_NOTIFICATION_READ:
      return {
        ...state,
        notifications: _.map(state.notifications, notification => {
          if (notification.id === action.payload.notificationId) {
            return {
              ...notification,
              read: action.payload.isRead
            }
          }
          return notification
        })
      }
    case actions.UPDATE_PUSH_EVENTS: {
      const newState = _.reduce(
        action.payload.pushEvents,
        ({ hasNewNotifications, pushEvents }, newEvent) => {
          const idx = _.findIndex(pushEvents, ['id', newEvent.id])
          if (idx === -1) {
            pushEvents.push(newEvent)
            return { pushEvents, hasNewNotifications: true }
          }
          // eslint-disable-next-line no-param-reassign
          pushEvents[idx] = newEvent
          return { pushEvents, hasNewNotifications }
        },
        { pushEvents: [...state.pushEvents], hasNewNotifications: state.hasNewNotifications }
      )
      return {
        ...state,
        ...newState
      }
    }
    case actions.REMOVE_PUSH_EVENTS:
      return {
        ...state,
        pushEvents: _.reject(state.pushEvents, pushEvent =>
          _.includes(_.map(action.payload.pushEvents, 'id'), pushEvent.id)
        )
      }
    case actions.RESET_NOTIFICATIONS:
      return {
        ...initState
      }
    default:
      return state
  }
}
