/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: "Per avviare l'analisi è necessario selezionare il tipo di analisi che si desidera elaborare",
    TypeOfAnalysis: 'Tipo di analisi',
    FromTargeting: 'Carica analisi di mercato',
    FromTargetingTooltip: 'Apri una ricerca salvata nel modulo Analizza mercato',
    FromProspecting: 'Carica ricerca da trova clienti',
    FromProspectingTooltip: 'Apri una ricerca salvata nel modulo Trova clienti',
    NewResearch: 'Inizia nuova ricerca',
    NewResearchTooltip: "Trova clienti a partire dall'intero database aziende di Margò",
    PortfolioQuery: 'Ricerca da Portafoglio imprese',
    TargetingQuery: 'Ricerca da Analizza mercato',
    ProspectingQuery: 'Ricerca da Trova clienti',
    LoadQuery: 'Carica ricerca',
    LoadQueryTooltip: 'Apri una ricerca salvata',
    SelectOne: 'Seleziona',
    SavedQuery: 'Ricerca salvata',
    ChooseQuery: 'Scegli quale ricerca vuoi caricare',
    ChooseQueryTargeting: 'Scegli quale ricerca di Targeting vuoi caricare',
    YouCanResumeResearch: 'Puoi recuperare una ricerca già iniziata.'
  },
  Prospecting: 'Trova clienti',
  Actions: {
    LoadQuery: 'Carica Ricerca',
    LoadQueryTooltip: 'Apri una ricerca salvata',
    SaveQuery: 'Salva Ricerca',
    SaveQueryTooltip: 'Salva questa ricerca nel modulo Trova clienti',
    Download: 'Download Excel',
    DownloadTooltip: 'Scarica la lista di imprese arricchite sul tuo dispositivo',
    SaveList: 'Salva lista',
    SaveListTooltip: 'Salva questa lista di imprese in un portafoglio',
    SaveAndMonitor: 'Salva e Segui Lista',
    SaveAndMonitorTooltip:
      "Salva l'elenco di queste società in un portafoglio che si aggiorna dinamicamente quando nuove società rientrano in questi parametri di ricerca. Le aziende individuate che saranno aggiunte al portafoglio verranno arricchite automaticamente.",
    SendToSales: 'Invia lista al Gestione Vendite',
    SendToSalesTooltip: 'Assegna queste imprese alla forza vendita e pianifica una campagna commerciale',
    SendToOtherModules: 'Invia ad altri moduli',
    SendToOtherModulesTooltip: 'Invia le imprese al modulo Gestione Vendite o Analizza Mercato'
  },
  Indexes: {
    Penetration: 'Indice di Penetrazione',
    PenetrationTooltip:
      'Misura la % di imprese in un portafoglio rispetto a quelle generate dal Similarity. Un valore maggiore indica una migliore penetrazione sul mercato potenziale.',
    Opportunity: 'Indice di Opportunità',
    OpportunityTooltip:
      'Misura la % di imprese generate dal Similarity non presenti nel portafoglio. Un valore maggiore indica un maggior numero di potenziali clienti.'
  },
  CompaniesInList: 'Imprese nella lista',
  CompaniesInList_plural: 'Imprese nella lista',
  Search: {
    CompanySearch: 'Ricerca Imprese',
    CompanySearchPlaceholder: 'Trova aziende tramite',
    CompanySearchFields: {
      TaxNumber: 'codice fiscale',
      VatNumber: 'partita IVA',
      ONRNumber: 'ONR',
      CompanyName: 'ragione sociale',
      CompanyBookNumber: 'company book number',
      CrifNumber: 'numero CRIF',
      CompanyIdentification: "codice identificativo dell'azienda",
      LegacyChNumber: 'CH Key',
      WebSite: 'sito web'
    },
    SemanticSearch: 'Ricerca semantica',
    SearchPlaceholder: 'Cerca imprese per parole chiave di attività o prodotto (es. pannelli solari)',
    All: 'Tutti',
    OfficialData: 'Dati ufficiali',
    Website: 'Web & Social',
    Synonyms: 'Sinonimi',
    Advanced: 'Ricerca semantica avanzata',
    AdvancedSwitch: 'Avanzata',
    AdvancedTooltip:
      'Con la Ricerca semantica avanzata puoi utilizzare più parole collegate da operatori logici (AND, OR, NOT). Per abilitarla attiva lo switch su ON. Scarica il documento con le specifiche per maggiori informazioni. ',
    DownloadSsGuide: 'Scarica la guida per la Ricerca Semantica'
  },
  EmptySearch: {
    History: {
      Title: 'Nessun filtro',
      Subtitle: 'Qui puoi vedere quanto ciascun filtro influenza la tua ricerca'
    },
    Banner: {
      Title: 'Inizia a cercare ultizzando i tuoi filtri preferiti',
      Subtitle:
        'Prima di iniziare la ricerca, assicurati di aver selezionato tutti i filtri desiderati. Il sistema genererà una lista di prospect ideali, sulla base dei filtri selezionati.'
    }
  },
  SaveBeforeContinue: {
    DialogTitle: 'Salva ricerca',
    Question: ' Vuoi salvare la ricerca di prospect prima di cambiare pagina?',
    Description: 'Salva la tua ricerca di prospect prima di cambiare pagina, per evitare di perdere informazioni.',
    OkBtn: 'Salva ricerca',
    CancelBtn: 'Salta il salvataggio'
  },
  DomainFilter: {
    SaveAndClose: 'Salva filtri e chiudi'
  },
  LoadMore: 'Carica altro',
  LocalUnits: {
    NoLocalUnits: {
      Title: 'Aggiungi Unità Locali',
      Text: 'Contattare il team vendite di Margò per attivare la funzionalità Unità Locali.'
    }
  },
  UnavailableForMobile: 'La pagina alla quale stai cercando di accedere è disponibile per tablet e desktop.',
  WarningRespectingLocalUnits: {
    Title: 'Arricchimento',
    Text: 'Sono state arricchite le imprese con Tipo di sede "Sede amministrativa", "Sede amministrativa e Sede legale" e "Sede legale" (se selezionate). Per arricchire le aziende con Tipo di sede "Unità locale", attiva l\'add-on Unità locali contattando il customer service.'
  },
  SendToOtherModulesDialog: {
    Title: 'Seleziona modulo',
    SubTitle: 'Seleziona il modulo a cui vuoi inviare la tua analisi o lista',
    Targeting: 'Analizza mercato',
    TargetingDescription:
      'Clicca qui per inviare la lista di imprese individuate al modulo Analizza Mercato per consultare grafici aggiornati che forniscono una rappresentazione della distribuzione delle aziende rispetto alle principali variabili.',
    SalesTool: 'Gestione vendite',
    SalesToolDescription:
      'Clicca qui per inviare la lista di imprese individuate al modulo Gestione Vendite. La lista di imprese verrà salvata in un portafoglio e i dati delle imprese verranno arricchiti. All’interno del modulo Gestione Vendite, potrai avviare una campagna di vendite sulle imprese selezionate ed assegnarla alla tua forza vendita.',
    OkButton: 'Invia'
  }
}
