import React from 'react'
import { WithT } from 'i18next'
import styled from 'styled-components/macro'
import { Text } from '../../Text'
import { Notification } from '../../../features/notifications/types'
import { notificationText } from '../../../utils/notifications'
import { Type } from '../../../utils/theme'
import { FormatNumberFunction } from '../../../utils/types'

const TextStyled = styled(Text)<{ inline?: boolean }>`
  margin-top: 4px;
  display: ${({ inline }) => (inline ? 'inline' : 'flex')};
  align-items: center;
  white-space: pre-line;

  /* IE fix: force flex to wrap text */
  width: 100%;
`

type Props = {
  notification: Notification
  type?: Type
  block?: boolean
  fallbackTheme?: any
  text?: string
  children?: React.ReactNode
  inline?: boolean
  notificationTextSuffix?: string
  formatNumber: FormatNumberFunction
} & WithT

export const NotificationEventText = ({
  notification,
  type = 'ghost',
  block = true,
  fallbackTheme,
  text,
  t,
  formatNumber,
  children,
  inline,
  notificationTextSuffix
}: Props) => {
  let textToShow = text ?? notificationText(t, notification, formatNumber)

  if (notificationTextSuffix !== undefined) {
    textToShow = `${JSON.stringify(textToShow)}\n${notificationTextSuffix}`
  }

  return (
    <TextStyled inline={inline} fallbackTheme={fallbackTheme} type={type} size="S" block={block}>
      {children || textToShow}
    </TextStyled>
  )
}
