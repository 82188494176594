/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: "Pour démarrer l'analyse, sélectionner le type d'analyse à traiter",
    TypeOfAnalysis: "Type d'analyse",
    LoadQuery: 'Charger requête',
    LoadQueryTooltip: 'Ouvrir une requête enregistrée',
    NewResearch: 'Démarrer nouvelle recherche',
    NewResearchTooltip:
      'Rechercher des prospects à travers toutes les sociétés présentes dans la base de données Margo',
    SelectOne: 'Sélectionner requête',
    SavedQuery: 'Requête enregistrée',
    PortfolioQuery: 'Requête de gestion des portefeuilles',
    TargetingQuery: 'Requête Analyser marché',
    ProspectingQuery: 'Requête de prospection',
    ChooseQuery: 'Choisir quelle requête charger',
    YouCanResumeResearch: 'Une recherche déjà lancée peut être reprise.'
  },
  Prospecting: 'Prospection',
  Actions: {
    LoadQuery: 'Charger Requête',
    LoadQueryTooltip: 'Ouvrir une requête enregistrée',
    SaveQuery: 'Enregistrer Requête',
    SaveQueryTooltip: 'Enregistrer cette requête dans le module Prospection',
    Download: 'Télécharger Excel',
    DownloadTooltip: 'Télécharger la liste enrichie de sociétés sur votre dispositif',
    SaveList: 'Enregistrer liste',
    SaveListTooltip: 'Enregistrer cette liste de sociétés dans le portefeuille',
    SaveAndMonitor: 'Enregistrer et Suivre la Liste',
    SaveAndMonitorTooltip:
      'Enregistrez la liste de ces entreprises dans un portefeuille qui se met à jour dynamiquement à mesure que de nouvelles entreprises entrent dans les paramètres de recherche. Les entreprises ajoutées seront automatiquement enrichies.',
    SendToSales: 'Envoyer la liste à Outil de vente',
    SendToSalesTooltip: 'Affecter les sociétés aux équipes commerciales et planifier une campagne publicitaire',
    SendToOtherModules: 'Envoyer vers autres modules',
    SendToOtherModulesTooltip: 'Envoyer les sociétés à Outil de vente ou Analyser marché'
  },
  Indexes: {
    Penetration: 'Indice de pénétration',
    PenetrationTooltip:
      "Il mesure le % de sociétés du portefeuille comparé à celles générées par analogie. Une valeur plus élevée indique une meilleure pénétration d'un potentiel marché.",
    Opportunity: "Indice d'opportunité",
    OpportunityTooltip:
      'Il mesure le % de sociétés générées par analogie non incluses dans le portefeuille. Une valeur plus élevée indique un plus grand nombre de clients potentiels.'
  },
  CompaniesInList: 'Société dans la liste',
  CompaniesInList_plural: 'Sociétés dans la liste',
  Search: {
    CompanySearch: "Recherche d'entreprise",
    CompanySearchPlaceholder: 'Trouvez des entreprises par ',
    CompanySearchFields: {
      TaxNumber: 'code fiscal',
      VatNumber: 'N° de TVA',
      ONRNumber: 'ONR',
      CompanyName: 'nom de la société',
      CompanyBookNumber: 'company book number',
      CrifNumber: 'numéro CRIF',
      CompanyIdentification: "numéro d'identification de l'entreprise",
      LegacyChNumber: 'Clé CH',
      WebSite: 'site web'
    },
    SemanticSearch: 'Recherche sémantique',
    SearchPlaceholder:
      "Trouvez des entreprises en utilisant des mots-clés relatifs à l'activité commerciale ou au produit",
    All: 'Tous',
    OfficialData: 'Données officielles',
    Website: 'Web & Réseaux sociaux',
    Synonyms: 'Synonymes',
    Advanced: 'Recherche sémantique avancée',
    AdvancedSwitch: 'Avancée',
    AdvancedTooltip:
      "La Recherche sémantique avancée permet d'utiliser plusieurs mots liés par des opérateurs logiques (AND, OR, NOT). Pour l'activer, placer le curseur sur ON. Télécharger le document pour plus d'informations.",
    DownloadSsGuide: 'Télécharger le guide de recherche sémantique'
  },
  EmptySearch: {
    History: {
      Title: 'Aucun filtre',
      Subtitle: 'La façon dont chaque filtre affecte la recherche est visible ici'
    },
    Banner: {
      Title: 'Démarrer la recherche en utilisation vos filtres préférés',
      Subtitle:
        "Avant de démarrer la recherche, s'assurer d'avoir sélectionné les filtres les plus utiles. La plateforme génère une liste de prospects idéaux sur la base des filtres sélectionnés."
    }
  },
  SaveBeforeContinue: {
    DialogTitle: 'Enregistrer recherche',
    Question: 'Vous souhaitez enregistrer la recherche de prospects avant de changer de page?',
    Description: 'Sauvegardez les recherches de vos clients potentiels pour éviter de perdre des données',
    OkBtn: 'Enregistrer recherche',
    CancelBtn: 'Omettre enregistrement'
  },
  DomainFilter: {
    SaveAndClose: 'Enregistrer filtres & fermer'
  },
  LoadMore: 'Charger plus',
  LocalUnits: {
    NoLocalUnits: {
      Title: 'Ajouter unités locales',
      Text: "Contacter l'équipe commerciale de Margò afin d'activer la fonctionnalité Unité locale."
    }
  },
  UnavailableForMobile:
    "La page à laquelle vous tenez d'accéder est disponible uniquement sur tablettes et ordinateurs portables.",
  WarningRespectingLocalUnits: {
    Title: 'Enrichissement',
    Text: "Les sociétés à bureau de type « Siège administratif », « Siège administratif et siège social » et « Siège social » ont été enrichies (si sélectionnées). Pour enrichir des sociétés à bureau de type « Unité locale », activer l'extension Unités locales en contactant le service client."
  },
  SendToOtherModulesDialog: {
    Title: 'Sélectionnez le module',
    SubTitle: 'Sélectionnez le module auquel vous souhaitez envoyer votre analyse ou votre liste',
    Targeting: 'Analyser marché',
    TargetingDescription:
      'Cliquez ici pour envoyer la liste des entreprises identifiées au module Analyser marché, pour consulter des graphiques mis à jour qui fournissent une représentation de la distribution des entreprises par rapport aux principales variables.',
    SalesTool: 'Outil de vente',
    SalesToolDescription:
      "Cliquez ici pour envoyer la liste des entreprises identifiées au module Outil de vente. La liste sera enregistrée dans un portfolio et les données des entreprises seront enrichies. Dans le module Outil de vente, vous pouvez lancer une campagne de vente sur les entreprises sélectionnées et l'attribuer à votre force de vente.",
    OkButton: 'Envoyer'
  }
}
