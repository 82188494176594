import _ from 'lodash'
import { Reducer } from 'redux'
import { RESET_COMPANIES } from '../actions'
import * as actions from './actions'

export type TableDataState = {
  companyIds: string[]
  allSelected: boolean
  selectedRowKeys: string[]
}

export type WithTableDataState = {
  rowKey: string
} & TableDataState

export type WithIdTableState = Readonly<{
  portfolioCompany: Record<string, TableDataState>
  rowKey: string
}>

export const initState = (rowKey: string): WithIdTableState => ({
  portfolioCompany: {},
  rowKey
})

export const initTableData = (rowKey: string): WithTableDataState => ({
  companyIds: [],
  selectedRowKeys: [],
  allSelected: false,
  rowKey
})

const updateCompanyGenerator = (rowKey: string) => (state: TableDataState, newState: Partial<WithTableDataState>) => ({
  ...initTableData(rowKey),
  ...state,
  ...newState
})

const updateGenerator =
  (rowKey: string) => (state: WithIdTableState, portfolioId: string, newState: Partial<TableDataState>) => ({
    ...state,
    portfolioCompany: {
      ...state.portfolioCompany,
      [portfolioId]: updateCompanyGenerator(rowKey)(state.portfolioCompany[portfolioId], newState)
    }
  })

export type ReducerWithIdTableState<T = {}> = Reducer<T & WithIdTableState, actions.WithPortfolioCompaniesActions>

export const withCompaniesWithIdTable =
  <T>(
    reducer: Reducer<T, any>,
    reducerName: actions.ConcreteReducer,
    rowKey: string,
    reducerInitState: T
  ): ReducerWithIdTableState<T> =>
  // @ts-ignore
  (state = { ...initState(rowKey), ...reducerInitState }, action) => {
    if (_.get(action, 'payload.reducer') !== reducerName) {
      if (action.type === RESET_COMPANIES) {
        return {
          ...state,
          ...initState(rowKey)
        }
      }
      return reducer(state, action)
    }

    const update = updateGenerator(rowKey)

    switch (action.type) {
      case actions.SET_COMPANY_IDS: {
        const { ids, reload } = action.payload
        const portfolioId = action.payload.portfolioId!
        const { selectedRowKeys, allSelected, companyIds } = state.portfolioCompany[portfolioId]
          ? state.portfolioCompany[portfolioId]
          : { selectedRowKeys: [], allSelected: false, companyIds: [] }
        const newSelectedRows = allSelected ? ids.filter(entityId => !companyIds.includes(entityId)) : []

        return update(state, portfolioId, {
          companyIds: reload ? ids : [...state.portfolioCompany[portfolioId].companyIds, ...ids],
          selectedRowKeys: allSelected ? [...selectedRowKeys, ...newSelectedRows] : selectedRowKeys
        })
      }
      case actions.SET_SELECTED_ROWS:
        return update(state, action.payload.portfolioId!, { selectedRowKeys: action.payload.selectedRowKeys })
      case actions.SELECT_ALL_ROWS:
        if (action.payload.selected)
          return update(state, action.payload.portfolioId!, {
            allSelected: true,
            selectedRowKeys: action.payload.selectedRowKeys
          })
        return update(state, action.payload.portfolioId!, { allSelected: false, selectedRowKeys: [] })
      default:
        return reducer(state, action)
    }
  }

export type ReducerWithTableDataState<T> = Reducer<T & WithTableDataState, actions.WithPortfolioCompaniesActions>

export const withCompaniesTable =
  <T>(
    reducer: Reducer<T, any>,
    reducerName: actions.ConcreteReducer,
    rowKey: string,
    reducerInitState: T
  ): ReducerWithTableDataState<T> =>
  // @ts-ignore
  (state = { ...initTableData(rowKey), ...reducerInitState }, action) => {
    if (_.get(action, 'payload.reducer') !== reducerName) {
      if (action.type === RESET_COMPANIES) {
        return {
          ...state,
          ...initTableData(rowKey)
        }
      }
      return reducer(state, action)
    }

    const updateCompany = updateCompanyGenerator(rowKey)

    switch (action.type) {
      case actions.SET_COMPANY_IDS: {
        const { ids, reload } = action.payload
        const companyIds = reload ? ids : [...state.companyIds, ...ids]
        return updateCompany(state, {
          companyIds,
          selectedRowKeys: state.allSelected ? [...companyIds] : state.selectedRowKeys
        })
      }
      case actions.SET_SELECTED_ROWS:
        return updateCompany(state, { selectedRowKeys: action.payload.selectedRowKeys })
      case actions.SELECT_ALL_ROWS:
        if (action.payload.selected)
          return updateCompany(state, {
            allSelected: true,
            selectedRowKeys: state.companyIds
          })
        return updateCompany(state, { allSelected: false, selectedRowKeys: [] })
      default:
        return reducer(state, action)
    }
  }
