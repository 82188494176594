import { ActionsUnion } from '../../utils/types'
import { createAction } from '../../utils/redux'
import { PortfolioCompany } from './types'

export const UPDATE_PORTFOLIO_COMPANIES = 'portfolio-companies/UPDATE_PORTFOLIO_COMPANIES'
export const ENRICH_COMPANIES = 'portfolio-companies/ENRICH_COMPANIES'
export const RESET_COMPANIES = 'portfolio-companies/RESET'

export const PortfolioCompaniesActions = {
  updatePortfolioCompanies: (portfolioCompanies: PortfolioCompany[]) =>
    createAction(UPDATE_PORTFOLIO_COMPANIES, { portfolioCompanies }),
  enrichCompanies: (companyIds: string[]) => createAction(ENRICH_COMPANIES, { companyIds }),
  reset: () => createAction(RESET_COMPANIES)
}

export type PortfolioCompaniesActions = ActionsUnion<typeof PortfolioCompaniesActions>
