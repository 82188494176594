/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: 'To start the analysis you need to select the type of analysis you want to process',
    TypeOfAnalysis: 'Type of analysis',
    LoadQuery: 'Load Query',
    LoadQueryTooltip: 'Open a saved query',
    NewResearch: 'Start new search',
    NewResearchTooltip: "Search for prospects through all the companies in Margo's database",
    SelectOne: 'Select query',
    SavedQuery: 'Saved query',
    PortfolioQuery: 'Portfolio Management query',
    TargetingQuery: 'Analyze Market query',
    ProspectingQuery: 'Prospecting Query',
    ChooseQuery: 'Choose which query you want to load',
    YouCanResumeResearch: 'You can resume a research that you have already started.'
  },
  Prospecting: 'Prospecting',
  Actions: {
    LoadQuery: 'Load Query',
    LoadQueryTooltip: 'Open a saved query',
    SaveQuery: 'Save Query',
    SaveQueryTooltip: 'Save this query in the Prospecting module',
    Download: 'Download Excel',
    DownloadTooltip: 'Download the enriched list of companies on your device',
    SaveList: 'Save List',
    SaveListTooltip: 'Save this companies list into a portfolio',
    SaveAndMonitor: 'Save & Follow List',
    SaveAndMonitorTooltip:
      'Save these companies list into a portfolio that updates dynamically as new companies enter in search parameters. Companies added will be automatically enriched.',
    SendToOtherModules: 'Send to other modules',
    SendToOtherModulesTooltip: 'Send the companies to Sales tool or to Analyze market',
    SendToSales: 'Send list to Sales Tool'
  },
  Indexes: {
    Penetration: 'Penetration Index',
    PenetrationTooltip:
      'It measures the % of portfolio companies compared to the ones generated by Similarity. An higher value indicates a better penetration on the potential market.',
    Opportunity: 'Opportunity Index',
    OpportunityTooltip:
      'It measures the % of companies generated by Similarity not included in the portfolio. An higher value indicates a greater number of potential clients.'
  },
  CompaniesInList: 'Company in the list',
  CompaniesInList_plural: 'Companies in the list',
  Search: {
    CompanySearch: 'Company search',
    CompanySearchPlaceholder: 'Find companies by',
    CompanySearchFields: {
      TaxNumber: 'tax code',
      VatNumber: 'vat code',
      ONRNumber: 'ONR',
      CompanyName: 'company name',
      CompanyBookNumber: 'company book number',
      CrifNumber: 'crif number',
      CompanyIdentification: 'business identification number',
      LegacyChNumber: 'legacy CH number',
      WebSite: 'web site'
    },
    SemanticSearch: 'Semantic search',
    SearchPlaceholder: 'Find companies by keyword about business activity or product (e.g. solar panels)',
    All: 'All',
    OfficialData: 'Official data',
    Website: 'Web & Social',
    Synonyms: 'Synonyms',
    Advanced: 'Advanced semantic search',
    AdvancedSwitch: 'Advanced',
    AdvancedTooltip:
      'With the Advanced semantic search you can use multiple words linked by logical operators (AND, OR, NOT). To enable it, turn the switch to ON. Download the document for more information.',
    DownloadSsGuide: 'Download semantic search guide'
  },
  EmptySearch: {
    History: {
      Title: 'No filters',
      Subtitle: 'Here you can see how each filter affects your research'
    },
    Banner: {
      Title: 'Start research using your favorite filters',
      Subtitle:
        'Before starting the research, make sure you have selected the filters that are most useful for you. The platform will generate a list of ideal prospects based on the selected filters.'
    }
  },
  SaveBeforeContinue: {
    DialogTitle: 'Save search',
    Question: 'Do you want to save the search of prospects before changing the page?',
    Description: "Save your potential customers' searches to avoid losing data",
    OkBtn: 'Save search',
    CancelBtn: 'Skip save'
  },
  DomainFilter: {
    SaveAndClose: 'Save filters & close'
  },
  LoadMore: 'Load more',
  LocalUnits: {
    NoLocalUnits: {
      Title: 'Add Local Units',
      Text: 'Please, contact Margò Sales team in order to activate the Local Unit functionality.'
    }
  },
  UnavailableForMobile: 'The page you are trying to access is only available for tablets and desktops.',
  WarningRespectingLocalUnits: {
    Title: 'Enrichment',
    Text: 'Companies with Office type "Administrative Headquarter", "Administrative Headquarter and Registered office" and "Registered office" have been enriched (if selected). To enrich companies with Office type "Local unit", please activate the Local Units addon by contacting the customer service.'
  },
  SendToOtherModulesDialog: {
    Title: 'Select module',
    SubTitle: 'Select the module where you want to send your analysis or list to',
    Targeting: 'Analyze market',
    TargetingDescription:
      'Click here to send the list of identified companies to the Analyze Market module, to consult updated graphs that provide a representation of the companies’ distribution with respect to the main variables.',
    SalesTool: 'Sales tool',
    SalesToolDescription:
      'Click here to send the list of identified companies to the Sales Tool module. The list will be saved in a portfolio and the companies’ data will be enriched. Within the Sales Tool module, you can start a sales campaign on the selected companies and assign it to your sales force.',
    OkButton: 'Send'
  }
}
