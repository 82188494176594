import { useInfiniteQuery, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useCallback } from 'react'
import _ from 'lodash'
import { QueryType } from '../../types'
import { getQueryAsExpression, getFilterQueriesAsDocument } from './api'
import { cacheAllTheTime } from '../../features/queryclient'
import { GetQueriesRequest } from './types'
import { FilterQueryDto, FilterStructureModuleRequest } from '../filters/types'
import { getPreloadedQuery } from '../filters'

export const getQueriesKeys = {
  All: () => [{ level1: 'getQueries' }] as const,
  WithQueryTypes: (queryTypes: QueryType[]) => [{ ...getQueriesKeys.All()[0], queryTypes }] as const,
  GetFilterQueriesAsDocument: (params: GetQueriesRequest) =>
    [{ ...getQueriesKeys.All()[0], level2: 'getFilterQueriesAsDocument', params }] as const,
  GetQueryAsExpression: () => [{ ...getQueriesKeys.All()[0], level2: 'getQueryAsExpression' }] as const,
  GetQueryAsExpressionForId: (queryId: string | undefined) =>
    [{ ...getQueriesKeys.GetQueryAsExpression()[0], queryId }] as const,
  PreloadQuery: (module: FilterStructureModuleRequest, enabled?: boolean) =>
    [{ ...getQueriesKeys.All()[0], level2: 'preloadQuery', module, enabled }] as const
}

export const useGetFilterQueriesAsDocument = (params: GetQueriesRequest, enabled?: boolean) => {
  const {
    isLoading,
    isFetchingNextPage,
    data,
    hasNextPage,
    fetchNextPage: fetchNext
  } = useInfiniteQuery({
    queryKey: getQueriesKeys.GetFilterQueriesAsDocument(params),
    queryFn: ({ pageParam, queryKey: [queryKeys] }) =>
      getFilterQueriesAsDocument({
        documentTypes: queryKeys.params.documentTypes,
        sortOrder: queryKeys.params.sortOrder,
        sortProperty: queryKeys.params.sortProperty,
        skip: queryKeys.params.top ? pageParam * queryKeys.params.top : undefined,
        top: queryKeys.params.top
      }),
    initialPageParam: 0,
    getNextPageParam: (lastResp, allPages) => {
      const count = _.sum(_.map(allPages, 'items.length'))
      return lastResp?.totalCount <= count ? undefined : allPages.length
    },
    enabled
  })
  const fetchNextPage = useCallback(() => {
    fetchNext()
  }, [fetchNext])

  return {
    isLoading,
    data: _.flatMap(data?.pages, ({ items }) => items),
    pageParams: data?.pageParams,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  }
}

export const useGetQueryAsExpression = (
  queryId?: string,
  options?: Omit<
    UseQueryOptions<
      FilterQueryDto | null,
      unknown,
      FilterQueryDto | null,
      ReturnType<typeof getQueriesKeys.GetQueryAsExpressionForId>
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery({
    queryKey: getQueriesKeys.GetQueryAsExpressionForId(queryId),
    queryFn: ({ queryKey: [queryKeys] }) => {
      if (queryKeys.queryId) {
        return getQueryAsExpression(queryKeys.queryId)
      }
      return null
    },
    ...cacheAllTheTime,
    ...options
  })

export const useGetPreloadedQuery = (
  module: FilterStructureModuleRequest,
  options?: Omit<
    UseQueryOptions<
      FilterQueryDto | null,
      unknown,
      FilterQueryDto | null,
      ReturnType<typeof getQueriesKeys.PreloadQuery>
    >,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    queryKey: getQueriesKeys.PreloadQuery(module, options?.enabled),
    queryFn: ({ queryKey: [queryKeys] }) => {
      if (options?.enabled !== false) {
        return getPreloadedQuery(queryKeys.module).then(response => response.data)
      }
      return null
    },
    ...cacheAllTheTime,
    ...options
  })
}
