import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'
import * as t from './types'

export const SET_LOADING = 'loading/SET_LOADING'

export const setLoading = (type: t.LoadingOption, isLoading: boolean, param?: t.LoadingParams) =>
  createAction(SET_LOADING, { type, isLoading, param })

export type LoadingActionSimple = (isLoading: boolean) => ReturnType<typeof setLoading>

export type LoadingActionParametrized<TParam extends object> = (
  isLoading: boolean,
  param: TParam
) => ReturnType<typeof setLoading>

const setSingleLoadingFactory =
  (type: t.LoadingOption): LoadingActionSimple =>
  isLoading =>
    setLoading(type, isLoading)

const setPropLoadingFactory =
  <TParam extends Record<string, t.LoadingParams>>(
    type: t.LoadingOption,
    prop: keyof TParam
  ): LoadingActionParametrized<TParam> =>
  (isLoading, param) =>
    setLoading(type, isLoading, param[prop])

export const LoadingActions = {
  setNotificationsLoading: setSingleLoadingFactory('notifications'),
  setDialogLoading: setSingleLoadingFactory('dialog'),
  setCurrenciesLoading: setSingleLoadingFactory('currencies'),

  // parametrized
  setPortfolioCompanyDuplicatesLoading: setPropLoadingFactory<t.WithPortfolioId>(
    'portfolioCompanyDuplicates',
    'portfolioId'
  )
}

export type LoadingActions = ActionsUnion<typeof LoadingActions>
