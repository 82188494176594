import React, { useEffect } from 'react'
import { useNavigate, useLocation, matchPath } from 'react-router-dom'
import { REFRESH_ROUTE } from '../../../routes'
import { Loading } from '../../Loading'

export const RefreshWrapper = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const match = matchPath({ path: REFRESH_ROUTE, end: false }, location.pathname)

  useEffect(() => {
    if (match)
      navigate(
        {
          ...location,
          pathname: location.pathname.substring(match?.pathname.length)
        },
        { replace: true, state: location.state }
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Loading />
}
