import _ from 'lodash'
import { keepPreviousData, useInfiniteQuery, UseInfiniteQueryOptions, useQuery } from '@tanstack/react-query'
import { WithKeepPreviousData } from '@api/types'
import { AxiosError } from 'axios'
import { convertCompoundExpressionForBackend } from '../../features/filters/utils'
import { ApplicationModule } from '../../types'
import { ProspectingCompaniesRes } from '../prospecting/types'
import {
  getLocalUnitActionsAllowed,
  getCompaniesByQueryFilter,
  getCompaniesByFreeText,
  getMapLocationsData,
  getMatchingTarget
} from './api'
import {
  QueryFilterCompaniesRequest,
  FreeTextCompaniesRequest,
  QueryLocationsRequest,
  QueryLocationsResponse,
  QueryAllowLocalUnitsActionRequest,
  MatchingTargetRequest,
  GetMatchingTargetResponse
} from './types'

export const getCompanyKeys = {
  All: (module: string) => [{ level1: 'companies', module }] as const,
  CompaniesByQueryFilter: (moduleName: ApplicationModule) =>
    [{ ...getCompanyKeys.All(moduleName)[0], level2: 'companiesByQueryFilter', moduleName }] as const,
  CompaniesByQueryFilterWithData: (data: QueryFilterCompaniesRequest) =>
    [{ ...getCompanyKeys.CompaniesByQueryFilter(data.moduleName)[0], ...data }] as const,

  GetMatchingTarget: ({ matchingTerm, companyUnitId, countryCode }: MatchingTargetRequest) =>
    [
      {
        ...getCompanyKeys.All(ApplicationModule.Prospecting)[0],
        level2: 'getMatchingTarget',
        matchingTerm,
        companyUnitId,
        countryCode
      }
    ] as const,

  CompaniesByFreeText: (moduleName: ApplicationModule) =>
    [{ ...getCompanyKeys.All(moduleName)[0], level2: 'companiesByFreeText', moduleName }] as const,
  CompaniesByFreeTextWithData: (data: FreeTextCompaniesRequest) =>
    [{ ...getCompanyKeys.CompaniesByQueryFilter(data.moduleName)[0], ...data }] as const,

  MapLocationsData: (moduleName: ApplicationModule) =>
    [{ ...getCompanyKeys.All(moduleName)[0], level2: 'getMapLocationsData' }] as const,
  MapLocationsDataWithRequest: (moduleName: ApplicationModule, request: QueryLocationsRequest) =>
    [{ ...getCompanyKeys.MapLocationsData(moduleName)[0], request }] as const,

  GetLocalUnitActionsAllowedWithData: (data: QueryAllowLocalUnitsActionRequest) =>
    [{ ...getCompanyKeys.All(data.moduleName)[0], level2: 'get-localunitactions-allowed', ...data }] as const
}

export const useGetCompaniesByQueryFilter = (
  data: QueryFilterCompaniesRequest,
  queryOptions: Partial<UseInfiniteQueryOptions<ProspectingCompaniesRes> & WithKeepPreviousData> = {}
) => {
  const convertedData = { ...data, query: convertCompoundExpressionForBackend(data.query) }

  const {
    data: prospectingCompanies,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching
  } = useInfiniteQuery({
    queryKey: getCompanyKeys.CompaniesByQueryFilterWithData(convertedData),
    queryFn: ({ queryKey: [queryKeys], pageParam }) => {
      if (!queryKeys.query) return Promise.reject()

      return getCompaniesByQueryFilter({
        pageType: queryKeys.pageType,
        moduleName: queryKeys.moduleName,
        pagination: { ...queryKeys.pagination, position: pageParam },
        query: queryKeys.query,
        excludedPortfoliosIds: queryKeys.excludedPortfoliosIds,
        selectedPortfoliosIds: queryKeys.selectedPortfoliosIds,
        orderBy: queryKeys.orderBy ?? undefined,
        semanticSearch: queryKeys.semanticSearch
      })
    },
    initialPageParam: 0,
    getNextPageParam: (lastResp, allPages) => {
      const count = _.sum(_.map(allPages, 'data.items.length'))
      return lastResp?.data?.total <= count ? undefined : allPages.length
    },
    enabled: queryOptions.enabled ?? true,
    placeholderData: queryOptions.keepPreviousData ? keepPreviousData : undefined
  })

  const lastPage = _.last(prospectingCompanies?.pages)

  return {
    prospectingCompanies,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
    totalCount: lastPage?.data?.total
  }
}

export const useGetCompaniesByFreeText = (
  data: FreeTextCompaniesRequest,
  queryOptions: Partial<UseInfiniteQueryOptions<ProspectingCompaniesRes> & WithKeepPreviousData> = {}
) => {
  const {
    data: prospectingCompanies,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching
  } = useInfiniteQuery({
    queryKey: getCompanyKeys.CompaniesByFreeTextWithData(data),
    queryFn: ({ queryKey: [queryKeys], pageParam }) => {
      return getCompaniesByFreeText({
        moduleName: queryKeys.moduleName,
        pagination: { ...queryKeys.pagination, position: pageParam },
        orderBy: queryKeys.orderBy ?? undefined,
        freeText: queryKeys.freeText,
        freeTextExcludeLocalUnits: queryKeys.freeTextExcludeLocalUnits
      })
    },
    initialPageParam: 0,
    getNextPageParam: (lastResp, allPages) => {
      const count = _.sum(_.map(allPages, 'data.items.length'))
      return lastResp?.data?.total <= count ? undefined : allPages.length
    },
    enabled: queryOptions.enabled ?? true,
    placeholderData: queryOptions.keepPreviousData ? keepPreviousData : undefined
  })
  const lastPage = _.last(prospectingCompanies?.pages)

  return {
    prospectingCompanies,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
    totalCount: lastPage?.data?.total
  }
}

export const useGetMapLocationsData = <TData>(
  moduleName: ApplicationModule,
  request: QueryLocationsRequest,
  select?: (data: QueryLocationsResponse) => TData,
  enabled = true
) => {
  const convertedRequest = { ...request, query: convertCompoundExpressionForBackend(request.query) }

  return useQuery({
    queryKey: getCompanyKeys.MapLocationsDataWithRequest(moduleName, convertedRequest),
    queryFn: ({ queryKey: [queryKeys] }) => getMapLocationsData(queryKeys.request),
    enabled,
    select
  })
}

export const useGetLocalUnitActionsAllowed = (data: QueryAllowLocalUnitsActionRequest) => {
  const convertedData = { ...data }

  return useQuery({
    queryKey: getCompanyKeys.GetLocalUnitActionsAllowedWithData(convertedData),
    queryFn: ({ queryKey: [queryKeys] }) => {
      if (!queryKeys.query) return Promise.reject()

      return getLocalUnitActionsAllowed({
        query: queryKeys.query,
        moduleName: queryKeys.moduleName,
        semanticSearch: queryKeys.semanticSearch
      })
    }
  })
}

export const useGetMatchingTarget = (
  { matchingTerm, companyUnitId, countryCode }: MatchingTargetRequest,
  disabled: boolean | undefined
) =>
  useQuery<
    GetMatchingTargetResponse,
    AxiosError,
    GetMatchingTargetResponse,
    ReturnType<typeof getCompanyKeys.GetMatchingTarget>
  >({
    queryKey: getCompanyKeys.GetMatchingTarget({
      matchingTerm: companyUnitId ? undefined : matchingTerm,
      companyUnitId,
      countryCode
    }),
    queryFn: ({ queryKey: [queryKeys] }) =>
      getMatchingTarget(queryKeys.matchingTerm, queryKeys.companyUnitId, queryKeys.countryCode),
    enabled: !disabled && !!((matchingTerm && countryCode) || companyUnitId)
  })
