import styled from 'styled-components/macro'

export const NotificationTitle = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 22px;
`

export const NotificationTextStyled = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
`

export const ProgressMessageBaseWrapper = styled.div`
  display: flex;
  fill: ${({ theme }) => theme.colors.white};
`
