import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getFreeSearchSuggestions } from './api'
import { SuggestionsRequest, SuggestionsResponse } from './types'

export const getSuggestionsKeys = {
  All: () => [{ level1: 'suggestions' }] as const,
  FreeSearchSuggestions: () => [{ ...getSuggestionsKeys.All()[0], level2: 'freeSearch' }] as const,
  FreeSearchSuggestionsWithData: (request: SuggestionsRequest) =>
    [{ ...getSuggestionsKeys.FreeSearchSuggestions()[0], request }] as const
}

export const useGetSuggestions = <T = SuggestionsResponse>(
  requestData: SuggestionsRequest,
  options?: Omit<
    UseQueryOptions<T, AxiosError, T, ReturnType<typeof getSuggestionsKeys.FreeSearchSuggestionsWithData>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery({
    queryKey: getSuggestionsKeys.FreeSearchSuggestionsWithData(requestData),
    queryFn: ({ queryKey: [queryKeys] }) => getFreeSearchSuggestions<T>(queryKeys.request),
    ...options
  })
