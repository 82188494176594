import _ from 'lodash'
import { CompoundExpression, Expression, FieldOperator } from '@api/expressionTypes'
import { MapFilterData } from '../../features/filters/types/mapFilter'
import {
  isDateRangeExpression,
  isFieldExpression,
  isGeoDistanceExpression,
  isGeoPolygonExpression,
  isNumericRangeExpression,
  isHierarchyExpression,
  isCompoundExpression,
  isCustomVarExpression,
  isCustomVarFieldExpression,
  isCustomVarNumericRangeExpression,
  isCustomVarDateRangeExpression,
  isSemanticExpression
} from '../../features/operations/guards'
import { NumericRange } from '../../types'
import { TreeKeySelection } from '../../utils/tree'

export const getFieldIdFromExpression = (expression: Expression) => {
  if (
    isFieldExpression(expression) ||
    isNumericRangeExpression(expression) ||
    isDateRangeExpression(expression) ||
    isGeoDistanceExpression(expression) ||
    isGeoPolygonExpression(expression)
  ) {
    return expression.fieldId
  }

  if (isCustomVarExpression(expression) && expression.position !== undefined) {
    return expression.position.toString()
  }

  if (isHierarchyExpression(expression)) return expression.additionalData.fieldId

  // CompoundExpression and SemanticExpression are only used in Operations and dont have fieldId
  if (isSemanticExpression(expression)) return expression.type
  if (isCompoundExpression(expression)) return expression.groupId

  return undefined
}

export const getExpressionIndexBasedOnFieldId = ({ childExpressions }: CompoundExpression, id: string) => {
  return _.findIndex(childExpressions, childExpression => getFieldIdFromExpression(childExpression) === id)
}

const getChildExpressionHierarchical = ({ childExpressions }: CompoundExpression, id: string) => {
  return _.find(childExpressions, e => {
    if (isCompoundExpression(e)) {
      return _.find(e.childExpressions, { fieldId: id })
    }
    return getFieldIdFromExpression(e) === id
  }) as Expression
}

export const getSimpleValueFromCompoundExpression = (expression: CompoundExpression, id: string) => {
  const childExpression = getChildExpressionHierarchical(expression, id)
  if (isFieldExpression(childExpression) || isCustomVarFieldExpression(childExpression)) return childExpression.value

  return undefined
}

export const getDateRangeFromExpression = (expression: Expression) => {
  if (isDateRangeExpression(expression) || isCustomVarDateRangeExpression(expression)) {
    return {
      min: expression.minValue,
      max: expression.maxValue
    }
  }
  if ((isFieldExpression(expression) || isCustomVarFieldExpression(expression)) && expression.value) {
    return {
      min: expression.operator === FieldOperator.Ge ? (expression.value as string) : null,
      max: expression.operator === FieldOperator.Le ? (expression.value as string) : null
    }
  }
  return undefined
}
export const getDateRangeFromCompoundExpression = (expression: CompoundExpression, id: string) => {
  const childExpression = getChildExpressionHierarchical(expression, id)
  return childExpression ? getDateRangeFromExpression(childExpression) : undefined
}

export const getNumericRangeFromExpression = (expression: Expression, range: NumericRange) => {
  if (isNumericRangeExpression(expression) || isCustomVarNumericRangeExpression(expression)) {
    return {
      min: expression.minValue,
      max: expression.maxValue
    }
  }
  if ((isFieldExpression(expression) || isCustomVarFieldExpression(expression)) && !_.isNil(expression.value)) {
    return {
      min: expression.operator === FieldOperator.Ge ? expression.value : range.min,
      max: expression.operator === FieldOperator.Le ? expression.value : range.max
    }
  }
  return undefined
}

export const getNumericRangeFromCompoundExpression = (
  expression: CompoundExpression,
  id: string,
  range: NumericRange
) => {
  const childExpression = getChildExpressionHierarchical(expression, id)
  return childExpression ? getNumericRangeFromExpression(childExpression, range) : undefined
}

export const getGeoDataFromExpression = (expression: Expression) => {
  if (isGeoDistanceExpression(expression)) {
    return {
      distance: {
        distanceKm: expression.distanceKm!,
        point: expression.point!
      },
      data: {
        address: expression.additionalData.address,
        searchType: expression.additionalData.searchType,
        companyName: expression.additionalData.companyName
      }
    }
  }
  if (isGeoPolygonExpression(expression)) {
    return {
      polygon: {
        points: _.map(expression.points, point => _.omit(point, '$type'))
      },
      data: {
        address: expression.additionalData.address,
        searchType: expression.additionalData.searchType,
        companyName: expression.additionalData.companyName
      }
    }
  }
  return undefined
}

export const getGeoDataFromCompoundExpression = (
  expression: CompoundExpression,
  id: string
): MapFilterData | undefined => {
  const childExpression = getChildExpressionHierarchical(expression, id)
  return getGeoDataFromExpression(childExpression)
}

export const getHierarchyExpressionIndexBasedOnFieldId = ({ childExpressions }: CompoundExpression, id: string) => {
  return _.findIndex(
    childExpressions,
    childExpression => isHierarchyExpression(childExpression) && childExpression.additionalData.fieldId === id
  )
}

export const removeMostPopularFiltersFromExpression = (
  expression: CompoundExpression,
  mostPopularFiltersArr: TreeKeySelection[]
) => {
  const mostPopularFiltersKeys = _.map(mostPopularFiltersArr, 'key')
  return {
    ...expression,
    childExpressions: _.reject(expression.childExpressions, childExpression =>
      _.includes(mostPopularFiltersKeys, getFieldIdFromExpression(childExpression))
    )
  }
}

export const getMostPopularFiltersForKeySelection = (
  otherFilters: TreeKeySelection[],
  mostPopularFiltersArr: TreeKeySelection[],
  areSelected: boolean
) => {
  const withMostPopularFilters = _.uniqBy(_.concat(otherFilters ?? [], mostPopularFiltersArr), 'key')
  const withoutMostPopularFilters = _.filter(
    otherFilters,
    filter => !_.some(mostPopularFiltersArr, { key: filter.key })
  )
  if (areSelected === false) {
    return withMostPopularFilters
  }
  if (areSelected === true) {
    return withoutMostPopularFilters
  }
  return otherFilters
}
