import axios, { CancellablePromise, CancelToken } from 'axios'
import { FeatureCollection, GeoJsonProperties } from 'geojson'
import _ from 'lodash'
import React from 'react'
import { media } from 'styled-bootstrap-grid'
import { DefaultTheme } from 'styled-components/macro'
import { Claim, ClaimKey, claimKeys } from '../authorization'
import { REFRESH_ROUTE } from '../routes'
import { FieldType } from '../types'
import { FormatNumberFunction, Hideable } from './types'
import { toLocalizedDateFormat, toLocalizedTimeFormat } from './formatters'

type GeoJsonPropertiesMapper = (properties: GeoJsonProperties) => GeoJsonProperties

export function dateFormat<T>(value: T) {
  if (_.isDate(value) || _.isString(value)) {
    return toLocalizedDateFormat(value)
  }
  return value
}

export function timeFormat<T>(value: T) {
  if (_.isDate(value) || _.isString(value)) {
    return toLocalizedTimeFormat(value)
  }
  return value
}

export const formatStyleSize = (size: number | string): string => {
  if (_.isNumber(size)) {
    return `${size}px`
  }
  return size
}

export const getThemeColorByName = (theme: DefaultTheme, colorName?: string) => {
  if (colorName && _.has(theme.colors, colorName)) {
    return _.get(theme.colors, colorName)
  }
  return null
}

export const multiply = (x: number, y: number) => {
  // due to float overflow
  if (y < 1) {
    return x / y ** -1
  }
  return x * y
}

export const toInt = (b?: boolean) => (b ? 1 : 0)

export const formatField = (type: FieldType, value: any, formatNumber?: FormatNumberFunction): any => {
  switch (type) {
    case FieldType.Date:
      return dateFormat(value)
    case FieldType.Time:
      return timeFormat(value)
    case FieldType.DateTime:
      return (
        <>
          <div>{dateFormat(value)}</div>
          <div>{timeFormat(value)}</div>
        </>
      )
    case FieldType.Array:
    case FieldType.ArrayKeywords:
      return _.join(value, ', ')
    case FieldType.ArrayDomains: {
      const values: string[] = value
      const fields = _.reduce(
        values,
        (acc: JSX.Element[], x, index) => {
          acc.push(<span key={index}>{x}</span>)
          if (index < values.length - 1) {
            acc.push(<br key={`br-${index}`} />)
          }
          return acc
        },
        []
      )
      return fields
    }
    case FieldType.Decimal:
    case FieldType.Long:
      return formatNumber ? formatNumber(value) : value
    default:
      return value
  }
}

export const md = (a: any, ...args: any[]): any => {
  return media.md(a, ...args)
}

export const lg = (a: any, ...args: any[]): any => {
  return media.lg(a, ...args)
}

export const xl = (a: any, ...args: any[]): any => {
  return media.xl(a, ...args)
}

export const parseQueryParam = (query: string) => {
  const pairs = _.split(_.replace(query, '?', ''), '&')
  return _.reduce(
    pairs,
    (params: Record<string, string>, pair) => {
      const [key, value] = _.split(pair, '=')
      return {
        ...params,
        [key]: decodeURIComponent(value)
      }
    },
    {}
  )
}

export const delayWithPromise = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const areNotUndefined = (...vals: any[]) => !_.some(vals, val => val === undefined)

export const mapGeoJsonFeatureCollectionProps =
  (propertiesMapper: GeoJsonPropertiesMapper) => (geoJson: FeatureCollection) => {
    const features = _.map(geoJson.features, feature => {
      const properties = propertiesMapper(feature.properties)
      return { ...feature, properties }
    })
    return { ...geoJson, features }
  }

export const generateRefreshUrl = (url: string) => `${REFRESH_ROUTE}${_.startsWith(url, '/') ? url : `/${url}`}`

export function parseClaims(claims: Array<ClaimKey>): number {
  return _.reduce(
    _.map(claims, c => Claim[c]),
    (acc, v) => acc | v,
    0
  )
}

export function flagsToClaimKeys(flags: number): ClaimKey[] {
  return _.reduce(claimKeys, (acc: ClaimKey[], k: ClaimKey) => (flags & Claim[k] ? [k, ...acc] : acc), [])
}

type KeyofForRecord<T, K extends keyof any = keyof any> = {
  [P in keyof T]: T[P] extends K ? P : never
}[keyof T]

type Mapper<P, R> = (__: P) => R

export const isMultipleOf = (n1: number) => (n2: number) => n2 % n1 === 0

export function updateRecord<K extends keyof any, T>(
  destination: Record<K, T>,
  source: T[],
  propName: KeyofForRecord<T, K>
): Record<K, T>

export function updateRecord<K extends keyof any, T>(
  destination: Record<K, T>,
  source: T[],
  mapper: Mapper<T, K>
): Record<K, T>

export function updateRecord<K extends keyof any, T>(destination: Record<K, T>, source: Record<K, T>): Record<K, T>

export function updateRecord<K extends keyof any, T>(
  destination: Record<K, T>,
  source: T[] | Record<K, T>,
  param?: KeyofForRecord<T, K> | Mapper<T, K>
): Record<K, T> {
  if (_.isArray(source)) {
    const getKey: Mapper<T, K> = _.isFunction(param) ? param : (p: T) => _.get(p, param!)
    return {
      ...destination,
      ..._.reduce(
        source,
        (acc: Record<K, T>, item: T) => {
          const key = getKey(item)
          return _.isUndefined(key)
            ? acc
            : {
                ...acc,
                [key]: _.merge(_.get(destination, key), {}, item)
              }
        },
        {} as Record<K, T>
      )
    }
  }
  return {
    ...destination,
    ...source
  }
}

export function parseFileExtensionFromName(fileName: string) {
  return _.includes(fileName, '.') ? _.last(_.split(fileName, '.')) : ''
}

export function filterVisible<T extends object>(items: Hideable<T>[], showHidden?: boolean): T[]

export function filterVisible<T extends object>(items: Hideable<T>[] | undefined, showHidden?: boolean): T[] | undefined

export function filterVisible<T extends object>(
  items: Hideable<T>[] | undefined,
  showHidden?: boolean
): T[] | undefined {
  if (_.isNil(items)) return items
  return showHidden ? items : _.reject(items, i => !!i.hideFeature)
}

export const convertSorting = (sortingOrder: string) => (sortingOrder === 'ascend' ? 'Asc' : 'Desc')

/**
 * This helper function sets up cancel() method on the Promise returned by an async function call.
 * In `react-query`, when a query is cancelled, it also calls `cancel()` on the Promise returned by the query function.
 * A query can be cancelled automatically (when unmounting the component that uses it) or, if needed, manually,
 * by calling `queryClient.cancelQueries(queryKey)`.
 *
 * For more info on query cancellation, see [React Query documentation](https://react-query.tanstack.com/guides/query-cancellation).
 *
 * @param getPromise `Promise` returned by the async function call or a function that takes the `CancelToken` as first parameter,
 * and returns a `Promise`.
 * @param cancelMessage optional message passed to `CancelToken`'s `cancel()` function.
 * @returns The promise passed as the first parameter, but with added `cancel()` function.
 */
export function apiCallWithCancel<TResult>(
  getPromise: Promise<TResult> | ((token: CancelToken) => Promise<TResult>),
  cancelMessage?: string
): Promise<TResult> {
  const source = axios.CancelToken.source()
  const promise: CancellablePromise<TResult> = _.isFunction(getPromise) ? getPromise(source.token) : getPromise
  promise.cancel = () => {
    source.cancel(cancelMessage)
  }
  return promise
}

export const withLabelRequired = (label: string) => `${label}*`
