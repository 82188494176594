import axios, { CancelTokenSource, InternalAxiosRequestConfig } from 'axios'
import _ from 'lodash'
import { Store } from 'redux'
import { LOGIN_REQUEST } from '../../api/login'
import { UserActions } from '../user/actions'

const cancelTokens: Record<string, CancelTokenSource> = {}

export function applyAxiosInterceptors(store: Store) {
  axios.interceptors.response.use(undefined, (error: any) => {
    if (axios.isCancel(error)) return Promise.reject(error)
    if (error.response) {
      if (
        error.response.config &&
        (error.config.allowedErrorStatuses === true ||
          _.includes(error.config.allowedErrorStatuses, error.response.status))
      ) {
        return Promise.reject(error)
      }

      switch (error.response.status) {
        case 401: {
          if (
            _.includes(_.get(error, 'request.responseURL', ''), LOGIN_REQUEST.url) ||
            error.response?.data?.response?.reason === 'InvalidUsernameOldPassword'
          )
            break
          store.dispatch(UserActions.logout(true))
          break
        }
        default:
          break
      }

      return Promise.reject(error)
    }

    return Promise.reject(error)
  })

  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (config.isCancelToken === true) {
      const hashKey = config.url
      if (hashKey) {
        let cancelToken = cancelTokens[hashKey]

        if (cancelToken) {
          cancelToken.cancel(config.url)
        }

        // eslint-disable-next-line no-multi-assign
        cancelTokens[hashKey] = cancelToken = axios.CancelToken.source()

        return { ...config, cancelToken: cancelToken.token }
      }
    }
    return config
  })
}
