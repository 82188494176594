import _ from 'lodash'
import { useCallback, useReducer } from 'react'
import { StorageType, StorageArea, PersisterOptions, PersisterOptionsWithArea } from './types'

const getStorage = (storageType?: StorageType) => {
  let storage: Storage = window.sessionStorage
  if (storageType === StorageType.Local) {
    storage = window.localStorage
  }
  return storage
}

const getStorageKey = (area: StorageArea, key: string) => {
  return `${area}:${key}`
}

const suffix = ':usePersistedState'

function getReducer<T>(storageType: StorageType | undefined, key: string) {
  return (state: T, setStateAction: T | ((prevState: T) => T)) => {
    const isAction = (p: any): p is (prevState: T) => T => _.isFunction(p)

    let a: T
    if (isAction(setStateAction)) {
      a = setStateAction(state)
      const storage = getStorage(storageType)
      try {
        storage.setItem(key + suffix, JSON.stringify(a))
      } catch (e2) {
        // eslint-disable-next-line no-console
        console.log(`could not store the ${a} on ${key}`)
      }

      return a
    }

    a = setStateAction
    const storage = getStorage(storageType)
    try {
      storage.setItem(key + suffix, JSON.stringify(a))
    } catch (e1) {
      // eslint-disable-next-line no-console
      console.log(`could not store the ${a} on ${key}`)
    }
    return a
  }
}

export function usePersistedState<T>(
  initialValue: T,
  { key, storageType }: PersisterOptions
): [T, (value: T | ((prevState: T) => T)) => void] {
  const getValue = useCallback(() => {
    const storage = getStorage(storageType)
    try {
      const storedValue = storage.getItem(key + suffix)
      if (!_.isNil(storedValue)) {
        const parsedValue: T = JSON.parse(storedValue)
        return parsedValue
      }
      return undefined
      // eslint-disable-next-line no-empty
    } catch (e) {
      return undefined
    }
  }, [key, storageType])

  const reducer = getReducer<T>(storageType, key)
  const [state, setStateAction] = useReducer(reducer, null, () => {
    return getValue() ?? initialValue
  })

  return [state, setStateAction]
}

export function usePersistedStateForArea<T>(
  initialValue: T,
  { area, key, storageType }: PersisterOptionsWithArea
): [T, (value: T | ((prevState: T) => T)) => void] {
  const storageKey = getStorageKey(area, key)

  return usePersistedState<T>(initialValue, { key: storageKey, storageType })
}
