import React from 'react'
import styled from 'styled-components/macro'
import { Overlay } from '../Overlay'
import { lg } from '../../utils/helpers'
import { hiddenScrollMixin } from '../../utils/cssMixins'
import { AnimationOverflow, containerWidthMixin, WIDE_WIDTH, NARROW_WIDTH, OFFSET } from './common'

const DrawerComponent = styled.div<{ open: boolean }>`
  position: fixed;
  top: ${OFFSET}px;
  right: 40px;
  flex: 0 0 auto;
  margin-right: 20px;
  z-index: 44010;
  transition: width ${({ theme }) => theme.animation.duration};
  width: ${({ open }) => (open ? 'auto' : '0 !important')};
  ${containerWidthMixin}
`

const RightDrawerContentWrapper = styled.div<{ open: boolean; height: number }>`
  && {
    position: relative;
    && {
      right: ${({ open }) => (open ? 0 : `-${NARROW_WIDTH}`)};
      left: auto !important;
    }
    ${({ open }) => lg`
      && {
        right: ${open ? 0 : `-${WIDE_WIDTH}`};
        left: auto !important;
      }
    `}
    ${containerWidthMixin}
    height: ${({ height }) => height}px;
    max-height: ${({ height }) => height}px;
    overflow-x: visible;
    overflow: scroll;
    ${hiddenScrollMixin}
    transition: all ${({ theme }) => theme.animation.duration};
  }
`

type Props = {
  height: number
  open?: boolean
  drawerContent?: React.ReactNode
  onRightOverlayClick?: () => void
}

export const RightDrawer = ({ height, open, drawerContent, onRightOverlayClick }: Props) => (
  <>
    <DrawerComponent open={!!open} data-testid="right-filter-drawer">
      <AnimationOverflow open={!!open}>
        <RightDrawerContentWrapper open={!!open} height={height}>
          {!!open && drawerContent}
        </RightDrawerContentWrapper>
      </AnimationOverflow>
    </DrawerComponent>
    <Overlay scrollDisabled zIndex={10009} visible={!!open} onClick={onRightOverlayClick} />
  </>
)
