import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { LockedFeatureTooltipContent } from '@components/Tooltip/LockedFeatureTooltipContent'
import { isDenied, isForUpsell } from '@utils/constraints'
import { UserActions } from '@features/user/actions'
import ManagementIcon from '../../assets/icons/case.svg?react'
import ProspectingIcon from '../../assets/icons/ordered.svg?react'
import ApiIcon from '../../assets/icons/settings.svg?react'
import TargetingIcon from '../../assets/icons/target-1.svg?react'
import SalesIcon from '../../assets/icons/up.svg?react'
import OperationsIcon from '../../assets/icons/icon-s-activity.svg?react'
import PnrrIcon from '../../assets/icons/pnrr.svg?react'
import { Claim } from '../../authorization'
import { getFreemiumReturnUrl, getVisibleClaims } from '../../features/config/selectors'
import { isLoggedIn, moduleSalesToolConstraint } from '../../features/user/selectors'
import { useBranding } from '../../hooks/useBranding'
import * as routes from '../../routes'
import { MenuItemDef } from './types'

export function useMainMenuItems() {
  const { t } = useTranslation('header')

  const { pathname } = useLocation()
  const visibleClaims = useSelector(getVisibleClaims)
  const isUserLogged = useSelector(isLoggedIn)
  const salesToolConstraint = useSelector(moduleSalesToolConstraint)
  const { allowedModules } = useBranding()

  const isSalesToolForUpsell = isForUpsell(salesToolConstraint)

  const items = React.useMemo(() => {
    const ret: MenuItemDef[] = []
    if (!isUserLogged) return ret

    const isPortfolioManagementVisible = allowedModules.PortfolioManagement && visibleClaims & Claim.PortfolioManagement
    const isTargetingVisible = allowedModules.Targeting && visibleClaims & Claim.Targeting
    const isProspectingVisible = allowedModules.Prospecting && visibleClaims & Claim.Prospecting
    const isSalesToolVisible = allowedModules.SalesTool && visibleClaims & Claim.SalesTool
    const isApiVisible = allowedModules.API
    const isOperationsVisible = allowedModules.Operations && visibleClaims & Claim.Operations
    const isPnrrVisible = allowedModules.PNRR && visibleClaims & Claim.Pnrr

    if (isPortfolioManagementVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.PORTFOLIO_MANAGEMENT_MODULE),
        icon: ManagementIcon,
        text: t('PortfolioManagement'),
        link: routes.PORTFOLIO_MANAGEMENT
      })
    }
    if (isTargetingVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.TARGETING_MODULE),
        icon: TargetingIcon,
        text: t('Targeting'),
        link: routes.TARGETING
      })
    }
    if (isProspectingVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.PROSPECTING_MODULE),
        icon: ProspectingIcon,
        text: t('Prospecting'),
        link: routes.PROSPECTING
      })
    }
    if (isOperationsVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.OPERATIONS),
        icon: OperationsIcon,
        text: t('Operations'),
        link: routes.OPERATIONS
      })
    }
    if (isSalesToolVisible && !isDenied(salesToolConstraint)) {
      ret.push({
        active: _.startsWith(pathname, routes.SALESTOOL_MODULE),
        icon: SalesIcon,
        text: t('SalesTool'),
        link: routes.SALESTOOL,
        disabled: isSalesToolForUpsell,
        tooltip: isSalesToolForUpsell ? <LockedFeatureTooltipContent /> : undefined,
        tooltipClickable: isSalesToolForUpsell
      })
    }
    if (isPnrrVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.PNRR),
        icon: PnrrIcon,
        text: t('Pnrr'),
        link: routes.PNRR
      })
    }
    if (isApiVisible) {
      ret.push({
        active: _.startsWith(pathname, routes.API_MODULE),
        icon: ApiIcon,
        text: t('API'),
        link: routes.API
      })
    }
    return ret
  }, [
    isUserLogged,
    allowedModules.PortfolioManagement,
    allowedModules.Targeting,
    allowedModules.Prospecting,
    allowedModules.SalesTool,
    allowedModules.API,
    allowedModules.Operations,
    allowedModules.PNRR,
    visibleClaims,
    salesToolConstraint,
    pathname,
    t,
    isSalesToolForUpsell
  ])

  return items
}

export const useChangeCountry = () => {
  const dispatch = useDispatch()
  const freemiumReturnUrl = useSelector(getFreemiumReturnUrl)

  const changeCountry = React.useCallback(() => {
    dispatch(UserActions.logout(false))

    if (freemiumReturnUrl) {
      window.location.replace(freemiumReturnUrl)
    }
  }, [dispatch, freemiumReturnUrl])

  return { changeCountry }
}
