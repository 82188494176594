import _ from 'lodash'
import * as T from '../../api/expressionTypes'

export const isExpression = (expression: any): expression is T.Expression => {
  if (!_.isObject(expression) || !_.has(expression, '$type')) return false
  return (
    (expression as T.Expression).$type === 'CompoundExpression' ||
    (expression as T.Expression).$type === 'FieldExpression' ||
    (expression as T.HierarchyExpression).$type === 'HierarchyExpression' ||
    (expression as T.NumericRangeExpression).$type === 'NumericRangeExpression' ||
    (expression as T.DateRangeExpression).$type === 'DateRangeExpression' ||
    (expression as T.SemanticExpression).$type === 'SemanticExpression' ||
    (expression as T.GeoDistanceExpression).$type === 'GeoDistanceExpression' ||
    (expression as T.GeoPolygonExpression).$type === 'GeoPolygonExpression' ||
    (expression as T.CustomVarFieldExpression).$type === 'CustomVariableExpression' ||
    (expression as T.CustomVarNumericRangeExpression).$type === 'CustomVariableNumericRangeExpression' ||
    (expression as T.CustomVarDateRangeExpression).$type === 'CustomVariableDateRangeExpression'
  )
}

export const isCompoundExpression = (expression: any): expression is T.CompoundExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'CompoundExpression'
}

export const isFieldExpression = (expression: any): expression is T.FieldExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'FieldExpression'
}

export const isCustomVarFieldExpression = (expression: any): expression is T.CustomVarFieldExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'CustomVariableExpression'
}

export const isCustomVarNumericRangeExpression = (expression: any): expression is T.CustomVarNumericRangeExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'CustomVariableNumericRangeExpression'
}

export const isCustomVarDateRangeExpression = (expression: any): expression is T.CustomVarDateRangeExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'CustomVariableDateRangeExpression'
}

export const isCustomVarExpression = (expression: any): expression is T.CustomVarExpression => {
  if (!isExpression(expression)) return false
  return (
    (expression as T.CustomVarFieldExpression).$type === 'CustomVariableExpression' ||
    (expression as T.CustomVarNumericRangeExpression).$type === 'CustomVariableNumericRangeExpression' ||
    (expression as T.CustomVarDateRangeExpression).$type === 'CustomVariableDateRangeExpression'
  )
}

export const isHierarchyExpression = (expression: any): expression is T.HierarchyExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'HierarchyExpression'
}

export const isNumericRangeExpression = (expression: any): expression is T.NumericRangeExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'NumericRangeExpression'
}

export const isDateRangeExpression = (expression: any): expression is T.DateRangeExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'DateRangeExpression'
}

export const isSemanticExpression = (expression: any): expression is T.SemanticExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'SemanticExpression'
}

export const isGeoDistanceExpression = (expression: any): expression is T.GeoDistanceExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'GeoDistanceExpression'
}

export const isGeoPolygonExpression = (expression: any): expression is T.GeoPolygonExpression => {
  if (!isExpression(expression)) return false
  return expression.$type === 'GeoPolygonExpression'
}
