import { jwtDecode } from 'jwt-decode'
import _ from 'lodash'
import dayjs from '@dayjs'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { Dispatch } from 'redux'
import { LoadStatus } from '@features/config/types'
import { getAutomaticLogoutTime, getUserConfigStatus } from '../../../features/config/selectors'
import { DialogActions } from '../../../features/dialog/actions'
import { UserActions } from '../../../features/user/actions'
import { getImpersonationAllowed, getToken, getUserClaims, hasPortfolioActions } from '../../../features/user/selectors'
import { useInterval } from '../../../hooks/useInterval'
import * as route from '../../../routes'
import { flagsToClaimKeys } from '../../../utils/helpers'
import { ConfigGuard } from '../../ConfigGuard'
import { Idle } from '../../Idle'

type Token = {
  exp: number
}

function decodeToken(token: string | null): Token | null {
  try {
    return token ? jwtDecode(token) : null
  } catch {
    return null
  }
}

function useTokenChecking(dispatch: Dispatch) {
  const token = useSelector(getToken)

  const decodedToken = useRef<Token | null>(decodeToken(token))

  useEffect(() => {
    decodedToken.current = decodeToken(token)
  }, [token])

  const checkToken = () => {
    if (!decodedToken.current) return
    const expiration = dayjs.unix(decodedToken.current.exp)
    const diff = expiration.diff(dayjs(), 's')

    if (diff < 0) {
      dispatch(UserActions.logout(false))
    }
    if (diff < 180) {
      dispatch(UserActions.refreshToken())
    }
  }

  useEffect(() => {
    checkToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useInterval(checkToken, 15000)
}

type AuthWrapperProps = {
  component: React.ElementType
  requiredPortfolioActions?: boolean
  requiredImpersonization?: boolean
  requiredClaims?: number
  loggedIn: boolean
}

export const AuthWrapper = ({
  component: Component,
  requiredPortfolioActions = false,
  requiredClaims = 0,
  requiredImpersonization = false,
  loggedIn
}: AuthWrapperProps) => {
  const { t } = useTranslation('error')
  const dispatch = useDispatch()
  const location = useLocation()

  const userClaims = useSelector(getUserClaims)
  const logoutTime = useSelector(getAutomaticLogoutTime)
  const portfolioActionsAllowed = useSelector(hasPortfolioActions)
  const isImpersonationAllowed = useSelector(getImpersonationAllowed)
  const loadConfigStatus = useSelector(getUserConfigStatus)

  useTokenChecking(dispatch)

  if (!loggedIn) {
    const query = location.pathname === route.DASHBOARD ? '' : `?returnUrl=${encodeURIComponent(location.pathname)}`
    return <Navigate to={`${route.LOGIN}${query}`} state={{ from: location }} replace />
  }

  const missingClaims = requiredClaims & ~(userClaims & requiredClaims)
  if (missingClaims) {
    return (
      <Navigate
        to={{
          pathname: route.FORBIDDEN
        }}
        state={{
          text: t('MissingClaims', {
            claims: flagsToClaimKeys(missingClaims).join(', ')
          })
        }}
        replace
      />
    )
  }

  const missingPortfolioActions = requiredPortfolioActions && !portfolioActionsAllowed
  if (loadConfigStatus === LoadStatus.LOADED && missingPortfolioActions) {
    return <Navigate to={{ pathname: route.FORBIDDEN }} replace />
  }

  if (requiredImpersonization && isImpersonationAllowed === false) {
    return <Navigate to={{ pathname: route.FORBIDDEN }} replace />
  }

  return (
    <ConfigGuard>
      {_.gt(logoutTime, 0) ? (
        <Idle
          timeout={logoutTime}
          onChange={(inactive: boolean) => {
            if (!inactive) return
            dispatch(DialogActions.showDialog('LogoutCancel'))
          }}
          render={() => <Component />}
        />
      ) : (
        <Component />
      )}
    </ConfigGuard>
  )
}
