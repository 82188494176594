import { Reducer } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { getCurrencies } from '../../api/currencies'
import { createAction } from '../../utils/redux'
import { Currency, CurrenciesState } from './types'
import { GetAvailableCurrenciesDto } from '../../api/currencies/types'
import * as mappers from './mappers'
import { withLoading } from '../loading/sagas'
import { LoadingActions } from '../loading/actions'

// ACTIONS
const FETCH_CURRENCIES = 'currencies/FETCH_CURRENCIES'
const SET_CURRENCIES = 'currencies/SET_CURRENCIES'

export const CurrencyActions = {
  fetchCurrencies: () => createAction(FETCH_CURRENCIES),
  setCurrencies: (currencies: Currency[]) => createAction(SET_CURRENCIES, { currencies })
}

// REDUCER

const initState: CurrenciesState = {
  items: []
}

export type CurrencyActionsType = ReturnType<typeof CurrencyActions.setCurrencies>

export const currenciesReducer: Reducer<CurrenciesState, CurrencyActionsType> = (state = initState, action) => {
  switch (action.type) {
    case SET_CURRENCIES:
      return {
        ...state,
        items: action.payload.currencies
      }
    default:
      return state
  }
}

// SAGAS

export function* fetchCurrencies() {
  try {
    const resp: AxiosResponse<GetAvailableCurrenciesDto> = yield call(getCurrencies)
    const currencies = mappers.toCurrencies(resp.data)
    yield put(CurrencyActions.setCurrencies(currencies))
  } catch (e) {
    // since withLoading decorator is rethrowing error we need to catch it here or it will crash
    // and prevent handleUserConfig saga to do retries
  }
}

export const handleCurrenciesSaga = takeLatest(
  FETCH_CURRENCIES,
  withLoading(LoadingActions.setCurrenciesLoading, fetchCurrencies)
)
