/* eslint-disable import/no-default-export */
export default {
  UserName: 'Nome utente',
  InsertUserName: 'Inserisci il tuo nome utente',
  Email: 'Email',
  InsertEmail: 'Inserisci la tua email',
  ResetPassword: 'Resetta la password',
  Success:
    'La tua password è stata reimpostata correttamente. Ti abbiamo inviato uno nuovo nella tua casella di posta.',
  Propmt:
    "Inserisci il nome utente e l'indirizzo e-mail a cui è associato il tuo account. Ti verrà inviata la password per accedere al servizio nella casella di posta che hai indicato."
}
