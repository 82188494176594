import { ActionsUnion } from '../../utils/types'
import { createAction } from '../../utils/redux'
import { PortfolioCompanyDuplicate } from './types'

export const FETCH_DUPLICATES = 'duplicates/FETCH_DUPLICATES'
export const SET_DUPLICATES = 'duplicates/SET_DUPLICATES'
export const TOGGLE_SHOW_RESOLVED = 'duplicates/TOGGLE_SHOW_RESOLVED'
export const TOGGLE_GROUP_ACTIONS = 'duplicates/TOGGLE_GROUP_ACTIONS'
export const SET_CHECKED_ROWS = 'duplicates/SET_CHECKED_ROWS'
export const SET_ALL_ROWS_CHECKED = 'duplicates/SET_ALL_ROWS_CHECKED'
export const SET_DUPLICATE_STATUS = 'duplicates/SET_DUPLICATE_STATUS'
export const SET_DUPLICATE_PRODUCT = 'duplicates/SET_DUPLICATE_PRODUCT'
export const SET_LOOKUP = 'duplicates/SET_LOOKUP'
export const SET_VALIDATING = 'duplicates/SET_VALIDATING'
export const SET_RESOLVED = 'duplicates/SET_RESOLVED'
export const SUBMIT = 'duplicates/SUBMIT'
export const CLOSE = 'duplicates/CLOSE'

export const PortfolioCompanyDuplicatesActions = {
  fetchDuplicates: (portfolioId: string) => createAction(FETCH_DUPLICATES, { portfolioId }),
  setDuplicates: (portfolioId: string, duplicates: PortfolioCompanyDuplicate[]) =>
    createAction(SET_DUPLICATES, { portfolioId, duplicates }),
  toggleShowResolved: (portfolioId: string) => createAction(TOGGLE_SHOW_RESOLVED, { portfolioId }),
  toggleGroupActions: (portfolioId: string) => createAction(TOGGLE_GROUP_ACTIONS, { portfolioId }),
  setCheckedRows: (portfolioId: string, rowIds: string[]) => createAction(SET_CHECKED_ROWS, { portfolioId, rowIds }),
  setAllRowsChecked: (portfolioId: string, checked: boolean) =>
    createAction(SET_ALL_ROWS_CHECKED, { portfolioId, checked }),
  setStatus: (portfolioId: string, rowId: string, status?: string) =>
    createAction(SET_DUPLICATE_STATUS, { portfolioId, rowId, status }),
  setProduct: (portfolioId: string, rowId: string, product?: string) =>
    createAction(SET_DUPLICATE_PRODUCT, { portfolioId, rowId, product }),
  setLookup: (portfolioId: string, identificationCode: string, lookup: PortfolioCompanyDuplicate[]) =>
    createAction(SET_LOOKUP, { portfolioId, identificationCode, lookup }),
  setValidating: (portfolioId: string, rowId: string, isValidating: boolean) =>
    createAction(SET_VALIDATING, { portfolioId, rowId, isValidating }),
  setResolved: (portfolioId: string, rowId: string, isResolved: boolean) =>
    createAction(SET_RESOLVED, { portfolioId, rowId, isResolved }),
  submit: (portfolioId: string) => createAction(SUBMIT, { portfolioId }),
  close: (portfolioId: string) => createAction(CLOSE, { portfolioId })
}

export type PortfolioCompanyDuplicatesActions = ActionsUnion<typeof PortfolioCompanyDuplicatesActions>

export type FetchDuplicatesAction = ReturnType<typeof PortfolioCompanyDuplicatesActions.fetchDuplicates>

export type SubmitAction = ReturnType<typeof PortfolioCompanyDuplicatesActions.submit>

export type SetAction =
  | ReturnType<typeof PortfolioCompanyDuplicatesActions.setStatus>
  | ReturnType<typeof PortfolioCompanyDuplicatesActions.setProduct>
