import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import { Dialog } from '../../components/Dialog'
import { gridTheme } from '../../utils/theme'

export const StyledDialog = styled(Dialog)`
  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    width: 90vw !important;
  }

  ${media.sm`
    width: 90vw !important;
  `}

  ${media.md`
    width: 60vw !important;
  `}
`

export const StyledDialogBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  align-items: center;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    flex-direction: column;
  }
`

export const ContainerItem = styled.div`
  flex: 1 1 auto;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    &:not(:first-child) {
      padding-top: 40px;
    }
  }
`

export const BoldText = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main};
`

export const StyledText = styled.p`
  white-space: pre-wrap;
  margin: 0;
`

export const StackCustomVariables = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '80%' : '50%')};
`
export const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: horizontal;
  align-content: ${({ isMobile }) => (isMobile ? 'center' : 'right')};
`
export const Content = styled.div`
  display: flex;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
