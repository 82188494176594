import React, { RefObject } from 'react'
import { StickyContext, StickyHandlerProps } from './Container'

export type StickyChildArgs = {
  style: React.CSSProperties
  isSticky: boolean
  distanceFromBottom?: number
  ref?: RefObject<HTMLElement>
}

type StickyProps = {
  topOffset?: number
  bottomOffset?: number
  children: (props: StickyChildArgs) => JSX.Element
}

export const Sticky = ({ topOffset = 0, bottomOffset = 0, children }: StickyProps) => {
  const [isSticky, setIsSticky] = React.useState(false)
  const [style, setStyle] = React.useState<any>({})
  const [distanceFromBottom, setDistanceFromBottom] = React.useState<number>()

  const context = React.useContext(StickyContext)
  const placeholderRef = React.useRef<HTMLDivElement>(null)
  const contentRef = React.useRef<HTMLElement>(null)

  const handleContainerEvent = React.useCallback(
    ({ distanceFromTop: distanceFromTopProp, distanceFromBottom: distanceFromBottomProp }: StickyHandlerProps) => {
      const parent = context.getParent()

      if (!parent || !placeholderRef.current) return

      const placeholderClientRect = placeholderRef.current?.getBoundingClientRect()

      const bottomDifference = distanceFromBottomProp - bottomOffset

      const isStickyLocal = distanceFromTopProp <= -topOffset && distanceFromBottomProp > -bottomOffset

      const styleLocal: React.CSSProperties = !isStickyLocal
        ? {}
        : {
            position: 'fixed',
            top: bottomDifference > 0 ? 0 : bottomDifference,
            left: placeholderClientRect.left,
            width: placeholderClientRect.width
          }
      styleLocal.transform = 'translateZ(0)'

      setIsSticky(isStickyLocal)
      setDistanceFromBottom(distanceFromBottomProp)
      setStyle(styleLocal)
    },
    [bottomOffset, context, topOffset]
  )

  React.useEffect(() => {
    if (!context.subscribe) throw new TypeError('Expected Sticky to be mounted within StickyContainer')
    context.subscribe(handleContainerEvent)

    return () => {
      context.unsubscribe(handleContainerEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const element = React.useMemo(
    () =>
      React.cloneElement<StickyChildArgs>(
        children({
          isSticky,
          distanceFromBottom,
          style
        }),
        {
          ref: contentRef
        }
      ),
    [children, distanceFromBottom, isSticky, style]
  )

  return (
    <div>
      <div ref={placeholderRef} />
      {element}
    </div>
  )
}
