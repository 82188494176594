import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, Navigate } from 'react-router-dom'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FloatAssistant } from '@containers/FloatAssistant/FloatAssistant'
import { Claim } from '../../authorization'
import {
  isLoggedIn,
  moduleDashboardConstraint,
  moduleSalesToolConstraint,
  freeSearchPermissionConstraint
} from '../../features/user/selectors'
import {
  getBrandingConfig
  // showReactQueryDevTool as showReactQueryDevToolSelector
} from '../../features/config/selectors'
import { useUsercentricsScript } from '../../features/usercentrics/useUsercentricsScript'
import * as route from '../../routes'
import { importLazy } from '../../utils/import'
import { DialogRenderer } from './DialogRenderer'
import { GlobalStyle } from './GlobalStyle'
import { LoadingFallback } from './LoadingFallback'
import { AuthWrapper, OnlyUnauthWrapper, RefreshWrapper } from './Route'
import { useSingleSignOnParams } from '../../hooks/useSingleSignOnParams'
import { ForcedLogout } from './ForcedLogout'

const Dashboard = importLazy(() => import('../../pages/Dashboard'))
const PortfolioManagementWrapper = importLazy(() => import('../../pages/PortfolioManagement'))
const Targeting = importLazy(() => import('../../pages/Targeting'))
const ProspectingPageWrapper = importLazy(() => import('../../pages/Prospecting'))
const Search = importLazy(() => import('../../pages/Search'))
const NotificationsArchive = importLazy(() => import('../../pages/Notifications/NotificationsArchive'))
const Account = importLazy(() => import('../../pages/Account'))
const Contact = importLazy(() => import('../../pages/Contact'))
const ResetPassword = importLazy(() => import('../../pages/ResetPassword'))
const CompanyReport = importLazy(() => import('../../pages/CompanyReport'))
const PersonReport = importLazy(() => import('../../pages/PersonReport'))
const ImprintPolicy = importLazy(() => import('../../pages/Policy/ImprintPolicy'))
const PrivacyPolicy = importLazy(() => import('../../pages/Policy/PrivacyPolicy'))
const CookiesPolicy = importLazy(() => import('../../pages/Policy/CookiesPolicy'))
const TermsOfUse = importLazy(() => import('../../pages/Policy/TermsOfUse'))
const Operations = importLazy(() => import('../../pages/Operations'))
const ApiPage = importLazy(() => import('../../pages/Api'))
const NotFound = importLazy(() => import('../../pages/PageNotFound'))
const Login = importLazy(() => import('../../pages/Login'))
const Forbidden = importLazy(() => import('../../pages/Forbidden'))
const Error = importLazy(() => import('../../pages/Error'))
const SalesTool = importLazy(() => import('../../pages/SalesTool'))
const Pnrr = importLazy(() => import('../../pages/Pnrr'))
const Appointment = importLazy(() => import('../../pages/Appointment'))
const Contacts = importLazy(() => import('../../pages/Contacts'))

export const App = () => {
  const loggedIn = useSelector(isLoggedIn)
  // const showReactQueryDevTool = useSelector(showReactQueryDevToolSelector)
  const brandingConfig = useSelector(getBrandingConfig)
  const dashboardConstraint = useSelector(moduleDashboardConstraint)
  const salesToolConstraint = useSelector(moduleSalesToolConstraint)
  const freeSearchConstraint = useSelector(freeSearchPermissionConstraint)

  const { isSingleSignOn } = useSingleSignOnParams()

  useUsercentricsScript()

  return (
    <>
      <LoadingFallback>
        <Routes>
          {/* Dashboard */}
          <Route
            path={route.DASHBOARD}
            element={
              dashboardConstraint !== undefined ? (
                <Navigate to={{ pathname: route.PROSPECTING }} replace />
              ) : (
                <AuthWrapper loggedIn={loggedIn} component={Dashboard} />
              )
            }
          />
          {/* Portfolio management */}
          <Route
            path={route.getParentRoute(route.PORTFOLIO_MANAGEMENT)}
            element={
              <AuthWrapper
                loggedIn={loggedIn}
                requiredClaims={Claim.PortfolioManagement}
                component={PortfolioManagementWrapper}
              />
            }
          />
          {/* Targeting */}
          <Route
            path={route.getParentRoute(route.TARGETING)}
            element={<AuthWrapper loggedIn={loggedIn} requiredClaims={Claim.Targeting} component={Targeting} />}
          />
          {/* Prospecting */}
          <Route
            path={route.getParentRoute(route.PROSPECTING)}
            element={
              <AuthWrapper loggedIn={loggedIn} requiredClaims={Claim.Prospecting} component={ProspectingPageWrapper} />
            }
          />
          {/* Company Report */}
          <Route
            path={route.getParentRoute(route.COMPANY_REPORT)}
            element={<AuthWrapper loggedIn={loggedIn} component={CompanyReport} />}
          />
          {/* Person Report */}
          <Route path={route.PERSON_REPORT} element={<AuthWrapper loggedIn={loggedIn} component={PersonReport} />} />
          {/* Sales tools */}
          <Route
            path={route.getParentRoute(route.SALESTOOL)}
            element={
              salesToolConstraint !== undefined ? (
                <Navigate to={{ pathname: route.FORBIDDEN }} replace />
              ) : (
                <AuthWrapper loggedIn={loggedIn} requiredClaims={Claim.SalesTool} component={SalesTool} />
              )
            }
          />
          {/* Operations */}
          <Route
            path={route.OPERATIONS_MODULE}
            element={<AuthWrapper loggedIn={loggedIn} requiredClaims={Claim.Operations} component={Operations} />}
          />
          {/* PNRR */}
          <Route
            path={route.getParentRoute(route.PNRR_MODULE)}
            element={<AuthWrapper loggedIn={loggedIn} requiredClaims={Claim.Pnrr} component={Pnrr} />}
          />
          {/* Account */}
          <Route
            path={route.getParentRoute(route.ACCOUNT)}
            element={<AuthWrapper loggedIn={loggedIn} component={Account} />}
          />
          {/* Appointment */}
          {brandingConfig.allowedModules.Appointment && (
            <Route
              path={route.getParentRoute(route.APPOINTMENT_MODULE)}
              element={<AuthWrapper loggedIn={loggedIn} component={Appointment} />}
            />
          )}
          {/* Contacts */}
          <Route
            path={route.getParentRoute(route.CONTACTS)}
            element={<AuthWrapper loggedIn={loggedIn} component={Contacts} />}
          />
          {/* API */}
          <Route path={route.API} element={<AuthWrapper loggedIn={loggedIn} component={ApiPage} />} />
          {/* Other */}
          <Route
            path={route.LOGIN}
            element={
              <OnlyUnauthWrapper loggedIn={loggedIn && !isSingleSignOn} redirect={route.DASHBOARD} component={Login} />
            }
          />
          <Route
            path={route.CONTACT}
            element={<OnlyUnauthWrapper loggedIn={loggedIn} redirect={route.DASHBOARD} component={Contact} />}
          />
          <Route
            path={route.RESET_PASS}
            element={<OnlyUnauthWrapper loggedIn={loggedIn} redirect={route.DASHBOARD} component={ResetPassword} />}
          />
          <Route
            path={route.NOTIFICATIONS_ARCHIVE}
            element={<AuthWrapper loggedIn={loggedIn} component={NotificationsArchive} />}
          />
          <Route
            path={route.SEARCH}
            element={
              freeSearchConstraint !== undefined ? (
                <Navigate to={{ pathname: route.FORBIDDEN }} replace />
              ) : (
                <AuthWrapper loggedIn={loggedIn} requiredClaims={Claim.Prospecting} component={Search} />
              )
            }
          />
          <Route path={route.IMPRINT_POLICY} element={<ImprintPolicy />} />
          <Route path={route.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={route.COOKIES_POLICY} element={<CookiesPolicy />} />
          <Route path={route.TERMS_OF_USE} element={<AuthWrapper loggedIn={loggedIn} component={TermsOfUse} />} />
          <Route path={route.getParentRoute(route.REFRESH_ROUTE)} element={<RefreshWrapper />} />
          <Route path={route.ERROR} element={<Error />} />
          <Route path={route.FORBIDDEN} element={<Forbidden />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </LoadingFallback>
      <ForcedLogout />
      <DialogRenderer />
      <GlobalStyle />
      {/* TODO react devtools causing problems so commented for now */}
      {/* {showReactQueryDevTool && <ReactQueryDevtools initialIsOpen={false} />} */}
      {loggedIn && <FloatAssistant />}
    </>
  )
}

// eslint-disable-next-line import/no-default-export
export default App
