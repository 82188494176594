/* eslint-disable import/no-default-export */
export default {
  Welcome: {
    Name: 'Willkommen an Bord',
    Title: 'Willkommen bei margò',
    Description:
      'margò ist die neue {{companyName}} Marketing Intelligence-Plattform, mit der Sie Ihr Unternehmenswachstum mit einer Vielzahl von Daten aktiv unterstützen können. Es passt sich Ihren Anforderungen und Bedürfnissen an. margò ist das perfekte Tool zur Steuerung umfassender Geschäfts- und Marketingprozesse.'
  },
  PortfolioManagement: {
    Name: 'Portfolio Management',
    Title: 'Gewinnen Sie Einblicke in Ihre Kundenbasis und optimieren Sie Ihre Wachstumsstrategien',
    Description:
      'margò hilft Ihnen dabei, Ihre Kundendaten mit offiziellen Quellen und den Daten der {{companyName}} auf dem neuesten Stand zu halten. Reichern Sie Ihre Kundendatenbank mit neuen wertvollen Informationen an und schöpfen Sie so die Möglichkeiten Ihres Kundenportfolios voll aus.'
  },
  Targeting: {
    Name: 'Analyze Market',
    Title: 'Analysieren Sie Märkte und Branchen und identifizieren so neue Chancen.',
    Description:
      'Visualisieren Sie Ihre Kunden auf einer Landkarte, analysieren Sie die Struktur Ihrer Unternehmensportfolios auf mehreren Ebenen und vermeiden Sie Risiken. Passen Sie die Analysen ganz Ihren Bedürfnissen an. Speichern Sie Ihre Berichte und verteilen Sie diese intern - für eine optimale Unterstützung Ihrer neuen Business-Strategien.'
  },
  Prospecting: {
    Name: 'Prospecting',
    Title: 'Neue Kunden mit einem Klick',
    Description:
      'Finden Sie neue Kunden für Ihr Unternehmen und lassen Sie sich benachrichtigen, sobald ein neues Unternehmen Ihren Kriterien entspricht. Identifizieren Sie potentielle Neukunden, die Ihrer Kundenstruktur optimal entsprechen. Nutzen Sie die Filtermöglichkeiten und schöpfen Sie aus den vielfältigen Möglichkeiten der semantischen Suche, um den optimalen Weg zu Ihrem nächsten Kunden zu finden.'
  },
  SalesTool: {
    Name: 'Sales Tool',
    Title: 'Sales Management in Ihren Händen',
    Description:
      'Wählen Sie Ihre Targets aus, identifizieren Sie die nächsten Aktionen und starten Sie Ihre Kampagnen. Unterstützen Sie Ihr Sales Team mit nützlichen Daten und optimieren Sie so Ihre Vertriebsaktivitäten, egal ob im Office oder beim Kunden vor Ort. Überwachen Sie den Fortschritt Ihrer Verkaufschancen und steigern Sie Ihre Umsätze.'
  },
  Api: {
    Name: 'API',
    Title: 'Business Information as-a-service',
    Description:
      'Verbinden Sie Ihr Master Data System und Ihr CRM mit dem gesamten Datenuniversum von margò, um Ihre Kundendaten in Echtzeit auf dem neuesten Stand zu halten. margò automatisiert die Anreicherung Ihres Kundenportfolios und unterstützt Sie optimal bei Ihren Herausforderungen und Zielen.'
  }
}
