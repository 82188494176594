/* eslint-disable import/no-default-export */
export default {
  Title: 'Výsledky vyhľadávania',
  SaletoolTitle: 'Nástroj predaja - Výsledky vyhľadávania kampaní',
  Search: 'Hľadať',
  SearchPlaceholder: 'Hľadať',
  SearchCompaniesAdditionalInfo: 'Upozorňujeme, že pre spoločnosti môžete použiť',
  NoResults: 'Ľutujeme, nenašli sme žiadne zhody pre',
  TryDifferent:
    'Skúste použiť iné kľúčové slovo alebo použite <0> sémantické vyhľadávanie </0> na vyhľadanie súvisiacich výrazov',
  TryDifferentWithoutProsp: 'Skúsiť s iným kľúčovým slovom.',
  Banner: {
    title: 'Aktivovať "Výber klientov" sekciu!',
    subtitle: 'Sémantické vyhľadávanie je k dispozícii iba pre užívateľov, ktorí si zakúpili sekciu "Výber klientov".',
    gotoAction: 'Kontaktujte {{companyName}}'
  },
  Tabs: {
    Companies: 'Spoločnosti',
    Portfolios: 'Portfólia',
    Campaigns: 'Kampane'
  },
  SearchInformationDialog: {
    Title: 'Čo sa dá vyhľadať?',
    SubTitleWithCampaigns: 'Môžete vyhľadávať spoločnosti, portfóliá alebo kampane',
    SubTitle: 'Môžete vyhľadávať spoločnosti alebo portfóliá',
    CompaniesBy: '<strong>Spoločnosti</strong> podľa:',
    PortfoliosCampaignsHelpText: '<strong>Portfóliá</strong> a <strong>kampane</strong> podľa názvu',
    PortfoliosHelpText: '<strong>Portfóliá</strong> podľa názvu',
    Companies: {
      CommercialDisplay: 'Komerčné zobrazenie'
    }
  },
  HiddenPortfolioCompany:
    'Firma, ktorú hľadáte, nie je prístupná, pretože môže patriť do portfólia skrytého iným používateľom vo vašej organizácii.',
  Company: 'Spoločnosť',
  Portfolio: 'Portfólio',
  Campaign: 'Kampaň',
  GoTo: {
    Company: 'Report spoločnosti',
    Portfolio: 'Správa portfólia',
    Campaign: 'Obchodné aktivity'
  }
}
