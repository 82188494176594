/* eslint-disable import/no-default-export */
export default {
  LogoutPrimaryText: 'Enter your credentials to access the service',
  LogoutSecondaryText: 'Add information about yourself and your role in the company you are part of',
  PortfolioManagement: 'Portfolio management',
  Targeting: 'Analyze market',
  Prospecting: 'Prospecting',
  SalesTool: 'Sales tool',
  API: 'API',
  Operations: 'Operations',
  Pnrr: 'PNRR',
  SemanticSearch: 'Go to semantic search',
  FreeSearchPlaceholderWithCampaigns: 'Find company, portfolio or campaign by name',
  FreeSearchPlaceholder: 'Find company or portfolio by name',
  SearchErrorTooLong: 'Maximum number of characters: {{maxLimit}}',
  SearchErrorTooShort: 'Please enter at least {{minLimit}} characters',
  FeedbackArea:
    'Notification area: inside you can find your latest completed actions or check the progress of those in progress',
  Profile: 'Profile',
  Notification: {
    Title: 'Notification',
    ViewAll: 'View all'
  },
  Appointments: 'Appointments'
}
