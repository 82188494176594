/* eslint-disable import/no-default-export */
export default {
  LogoutPrimaryText: 'Inserisci le tue credenziali per accedere al servizio',
  LogoutSecondaryText: "Aggiungi le informazioni su di te e sul tuo ruolo nell'impresa di cui fai parte",
  PortfolioManagement: 'Portafoglio imprese',
  Targeting: 'Analizza mercato',
  Prospecting: 'Trova clienti',
  SalesTool: 'Gestione vendite',
  API: 'API',
  Operations: 'Operations',
  Pnrr: 'PNRR',
  SemanticSearch: 'Vai alla ricerca semantica',
  FreeSearchPlaceholderWithCampaigns: "Trova per nome un'azienda, un portafoglio o una campagna",
  FreeSearchPlaceholder: "Trova per nome un'azienda o un portafoglio",
  SearchErrorTooLong: 'Numero massimo di caratteri: {{maxLimit}}',
  SearchErrorTooShort: 'Per favore inserisci almeno {{minLimit}} caratteri',
  FeedbackArea:
    'Area notifiche: al suo interno puoi trovare le tue ultime azioni completate o verificare la progressione di quelle in corso',
  Profile: 'Profilo',
  Notification: {
    Title: 'Notifica',
    ViewAll: 'Vedi tutte'
  },
  Appointments: 'Appuntamenti'
}
