import { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import _ from 'lodash'
import { FieldErrors } from 'react-hook-form'
import { Dictionary } from '../../utils/types'
import { FormError } from '../../utils/validators'

export type ErrorResponseItem = {
  propertyName: string
  errorCode: string
  errorMessage: string
  attemptedValue?: string
  customState?: Record<string, any>
}

export type ErrorResponse = {
  response: Record<string, ErrorResponseItem[]>
}

export const parseSingleError = (error: ErrorResponseItem): FormError => ({
  error: error.errorCode,
  errorMessage: error.errorMessage,
  data: { ..._.mapKeys(error.customState, (__, k) => _.camelCase(k)), label: error.propertyName } || {}
})

export const parseErrors = (e: AxiosError<ErrorResponse>): Dictionary<FormError | FormError[]> => {
  const response = e.response?.data?.response
  if (!response) return {}

  return _.reduce(
    response,
    (result, value, key) => {
      try {
        return {
          ...result,
          [key]: _.map(value, parseSingleError)
        }
      } catch (parseError) {
        return {
          ...result,
          [key]: {
            error: value,
            translated: true
          }
        }
      }
    },
    {}
  )
}

export const parseError = (error: FormError, label: string, t: TFunction): string => {
  if (!_.isObject(error)) return ''

  const type = _.get(error, 'error', '')
  const data = _.get(error, 'data', {})
  const translated = _.get(error, 'translated', false)
  if (translated) {
    return type
  }
  return t(`error:${type}`, { ...data, label } as Record<string, string>)
}

export const getFieldError = (error: FormError | FormError[], label: string, t: TFunction) => {
  if (!error) {
    return undefined
  }
  if (_.isArray(error)) {
    return parseError(_.head(error), label, t)
  }
  if (_.isObject(error)) {
    return parseError(error, label, t)
  }
  return t(`error:${error}`, { label })
}

export const getErrorMessage =
  (
    validator: (args: any, values?: any) => Promise<FormError | FormError[]> | FormError | FormError[] | undefined,
    label: string,
    t: TFunction
  ) =>
  async (value: any, values?: any) => {
    const result = await validator(value, values)
    return getFieldError(result, label, t)
  }

export const getError = (errors: FieldErrors<any>, name: string) => {
  // eslint-disable-next-line lodash/path-style
  return _.get(errors, [name, 'message']) ?? _.get(errors, `${name}.message`)
}
