import React from 'react'
import { NotificationEventContainer } from './NotificationEventContainer'
import { PushEventContainer } from './PushEventContainer'

export const NotificationPopupContainer = () => {
  return (
    <>
      <NotificationEventContainer />
      <PushEventContainer />
    </>
  )
}
