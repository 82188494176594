import { Reducer } from 'redux'
import { DialogType } from './types'
import * as actions from './actions'
import { Dictionary } from '../../utils/types'

export type DialogState = Readonly<{
  visibleDialog?: DialogType
  dialogData: Dictionary<any>
}>

export const initState: DialogState = {
  visibleDialog: undefined,
  dialogData: {}
}

export const dialogReducer: Reducer<DialogState, actions.DialogActions> = (state = initState, action) => {
  switch (action.type) {
    case actions.SHOW_DIALOG:
      return {
        ...state,
        visibleDialog: action.payload.dialog
      }
    case actions.SHOW_DIALOG_WITH_DATA:
      return {
        ...state,
        visibleDialog: action.payload.dialog,
        dialogData: {
          ...state.dialogData,
          [action.payload.dialog]: action.payload.data
        }
      }
    case actions.SET_DIALOG_DATA:
      return {
        ...state,
        dialogData: {
          ...state.dialogData,
          [action.payload.dialog]: action.payload.data
        }
      }
    case actions.UPDATE_DIALOG_DATA:
      return {
        ...state,
        dialogData: {
          ...state.dialogData,
          [action.payload.dialog]: {
            ...state.dialogData[action.payload.dialog],
            ...action.payload.data
          }
        }
      }
    case actions.CLOSE_DIALOG:
      return {
        ...state,
        visibleDialog: undefined
      }
    default:
      return state
  }
}
