import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { DefaultTheme } from 'styled-components/macro'
import ChevronRight from '../../../assets/icons/chevron-right.svg?react'
import { Button } from '../../Button'

export const OpenButtonStyled = styled(Button)`
  align-self: flex-end;
  margin-top: 16px;
`

type Props = {
  fallbackTheme: DefaultTheme
  navigationCallback: () => void
  text?: string
  icon?: React.ReactNode
}

export const OpenButton = ({ fallbackTheme, navigationCallback, text, icon }: Props) => {
  const { t } = useTranslation(['notification'])

  return (
    <OpenButtonStyled
      fallbackTheme={fallbackTheme}
      onClick={navigationCallback}
      ghost
      iconRight={icon || <ChevronRight />}
    >
      {text || t('notification:NotificationPopup.Open')}
    </OpenButtonStyled>
  )
}
