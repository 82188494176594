import { CommonModuleContextType } from '../context'
import { SimilarityType } from '../filters/types'

export type ProspectingCompanyMapDto = {
  position: {
    lat: number
    lng: number
  }
  entityId?: string
  count?: number
}

export enum FunnelItemTypeEnum {
  Filter = 'Filter',
  Expression = 'Expression',
  Semantic = 'Semantic',
  ExcludedPortfolios = 'ExcludedPortfolios'
}

export type FunnelDataItemBase =
  | {
      funnelItemType: FunnelItemTypeEnum.Semantic
    }
  | {
      funnelItemType: FunnelItemTypeEnum.ExcludedPortfolios
    }
  | {
      funnelItemType: FunnelItemTypeEnum.Expression
      fieldId: string
    }

export type FunnelDataItem = FunnelDataItemBase & { count: number }

export type FunnelItemLabel =
  | {
      text: string
      tkey?: undefined
    }
  | {
      text?: undefined
      tkey: string
    }

export type FunnelItem = FunnelDataItem & { label: FunnelItemLabel }

export type ProspectingContextTypePure = {
  similarity?: SimilarityType
  setSimilarity: (similarity: SimilarityType | undefined) => void
}

export type ProspectingContextType = ProspectingContextTypePure & CommonModuleContextType
