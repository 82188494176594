import _ from 'lodash'
import { createSelector } from 'reselect'
import { RootState } from '../types'
import { DuplicateTableRow, PortfolioCompanyDuplicate } from './types'
import { paramSelector } from '../../utils/selectors'

type WithPortfolioId = { portfolioId: string }
type WithIdentificationCode = { identificationCode: string }

const selectState = (state: RootState) => state.portfolioCompanyDuplicates

const getPortfolioState = createSelector(selectState, paramSelector<WithPortfolioId>(), (state, { portfolioId }) =>
  _.get(state.duplicates, portfolioId, undefined)
)

export const getTableData = createSelector(getPortfolioState, (portfolio): DuplicateTableRow[] => {
  if (portfolio) {
    const { resolved, validating, companies, statuses, products } = portfolio
    return _.map(companies, x => ({
      ...x,
      selectedStatus: statuses[x.id],
      selectedProduct: products[x.id],
      isResolved: _.includes(resolved, x.id),
      isValidating: _.includes(validating, x.id)
    }))
  }
  return []
})

export const hasUnresolvedDuplicates = createSelector(getPortfolioState, (portfolio): boolean => {
  if (portfolio) {
    return !portfolio.closed && !_.isEmpty(portfolio.companies)
  }
  return false
})

export const showResolvedDuplicates = createSelector(getPortfolioState, (portfolio): boolean =>
  portfolio ? portfolio.showResolved : false
)

export const groupActionsEnabled = createSelector(getPortfolioState, (portfolio): boolean =>
  portfolio ? portfolio.groupActions : false
)

export const getCheckedRowIds = createSelector(
  getPortfolioState,
  (portfolio): string[] => portfolio?.checkedRowIds || []
)

export const allRowsChecked = createSelector(getPortfolioState, (portfolio): boolean => {
  if (!portfolio || _.isEmpty(portfolio.companies)) return false
  return _.size(portfolio.companies) === _.size(portfolio.checkedRowIds)
})

export const getDuplicatesTableData = createSelector(
  getTableData,
  showResolvedDuplicates,
  (tableData, showResolved): DuplicateTableRow[] => (showResolved ? tableData : _.reject(tableData, 'isResolved'))
)

export const getPortfolioCompaniesByIdentificationCode = createSelector(
  getPortfolioState,
  paramSelector<WithIdentificationCode>(),
  (portfolio, { identificationCode }): PortfolioCompanyDuplicate[] =>
    _.get(portfolio, ['lookup', identificationCode], [])
)

export const allDuplicatesResolved = createSelector(getPortfolioState, (portfolio): boolean =>
  portfolio ? _.size(portfolio.companies) === _.size(portfolio.resolved) : false
)
