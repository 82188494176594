import { AxiosInstance } from 'axios'

export const setAuthorizationHeader = (axiosInstance: AxiosInstance, token?: string) => {
  // eslint-disable-next-line no-param-reassign
  axiosInstance.defaults.headers.Authorization = token ?? null
}

export const ACCEPT_CURRENCY_HEADER = 'X-Accept-Currency'

export const setCurrency = (axiosInstance: AxiosInstance, currency = '') => {
  if (currency === '') {
    // eslint-disable-next-line no-param-reassign
    delete axiosInstance.defaults.headers[ACCEPT_CURRENCY_HEADER]
    return
  }
  // eslint-disable-next-line no-param-reassign
  axiosInstance.defaults.headers[ACCEPT_CURRENCY_HEADER] = currency
}
