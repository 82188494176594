import _ from 'lodash'
import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects'
import { Dispatch } from 'redux'
import {
  FETCH_NOTIFICATIONS,
  FETCH_NUMBER_OF_NOTIFICATIONS,
  FETCH_SET_NOTIFICATION_READ,
  NotificationActions
} from './actions'
import {
  getNumberOfNotificationsToDisplay,
  getLoadedNotificationsCount,
  getLastNotificationId,
  getNotification
} from './selectors'
import {
  NotificationsResponse,
  NumberOfNotificationsResponse,
  NotificationResponse,
  PushEventResponse
} from '../../api/notification/types'
import { getNotifications, getNumberOfNotifications, putNotificationRead } from '../../api/notification'
import { LoadingActions } from '../loading/actions'
import { withLoading } from '../loading/sagas'
import { SIGNALR_STARTED } from '../config/actions'
import { mapNotificationResponse, mapPushEventResponse } from '../mappers'
import { SignalR } from '../../utils/signalR'

export function* fetchNumberOfNotifications() {
  const { data }: { data: NumberOfNotificationsResponse } = yield call(getNumberOfNotifications)
  const { count: numberOfNotifications } = data
  yield put(NotificationActions.setNumberOfNotifications(numberOfNotifications))
}

export function* fetchSetNotificationRead(action: ReturnType<typeof NotificationActions.fetchSetNotificationRead>) {
  const { notificationId, isRead, updateStore } = action.payload
  const notification: ReturnType<typeof getNotification> = yield select(getNotification, { notificationId })
  if (notification?.read === isRead) return
  if (updateStore) yield put(NotificationActions.setNotificationRead(notificationId, isRead))

  yield call(putNotificationRead, notificationId, isRead)
}

export function* fetchNotifications(action: ReturnType<typeof NotificationActions.fetchNotifications>) {
  const prevNumberOfNotificationToDisplay: ReturnType<typeof getNumberOfNotificationsToDisplay> = yield select(
    getNumberOfNotificationsToDisplay
  )
  const lastNotificationId: ReturnType<typeof getLastNotificationId> = yield select(getLastNotificationId)
  const notificationsCount: ReturnType<typeof getLoadedNotificationsCount> = yield select(getLoadedNotificationsCount)
  const { numberOfNotifications } = action.payload
  const newNumberOfNotificationToDisplay =
    numberOfNotifications && numberOfNotifications > prevNumberOfNotificationToDisplay
      ? numberOfNotifications
      : prevNumberOfNotificationToDisplay
  if (newNumberOfNotificationToDisplay > notificationsCount) {
    const { data }: { data: NotificationsResponse } = yield call(getNotifications, {
      top: newNumberOfNotificationToDisplay - notificationsCount,
      skip: notificationsCount
    })
    const notifications = _.map(data.items, mapNotificationResponse)
    if (!lastNotificationId && !_.isEmpty(notifications)) {
      yield put(NotificationActions.setLastNotificationId(notifications[0].id))
    }
    yield put(NotificationActions.addOldNotifications(notifications))
  }
  yield put(NotificationActions.setNumberOfNotificationsToDisplay(newNumberOfNotificationToDisplay))
}

export const createNotificationHandler = (dispatch: Dispatch) => (notificationResponse: NotificationResponse) => {
  const notification = mapNotificationResponse(notificationResponse)
  dispatch(NotificationActions.setHasNewNotifications(true))
  dispatch(NotificationActions.addNewNotifications([notification]))
}

export const createPushEventHandler = (dispatch: Dispatch) => (pushEventResponse: PushEventResponse) => {
  const pushEvent = mapPushEventResponse(pushEventResponse)
  dispatch(NotificationActions.updatePushEvents([pushEvent]))
}

export function* subscribe() {
  yield SignalR.addSubscriptionHandler('Notification', createNotificationHandler)
  yield SignalR.addSubscriptionHandler('PushEvent', createPushEventHandler)
}

export function* rootSaga() {
  yield all([
    takeEvery(FETCH_NOTIFICATIONS, withLoading(LoadingActions.setNotificationsLoading, fetchNotifications)),
    takeLatest(FETCH_NUMBER_OF_NOTIFICATIONS, fetchNumberOfNotifications),
    takeEvery(FETCH_SET_NOTIFICATION_READ, fetchSetNotificationRead),
    takeEvery(SIGNALR_STARTED, subscribe)
  ])
}
