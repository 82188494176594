import { css } from 'styled-components/macro'
import { Text } from '../Text'

export const commonStyle = () => css`
  height: 40px;
  border: 0;
  opacity: 1;
  fill: currentColor;

  ${Text.Style} {
    color: inherit;
  }

  :disabled {
    opacity: 0.4;
  }

  &,
  :disabled,
  :focus,
  :hover,
  :disabled:hover {
    color: ${({ theme }) => theme.colors.main};
    background-color: ${({ theme }) => theme.colors.secondary};
    &.ant-btn-primary {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.main};
    }
    &.ant-btn-dangerous {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.pumpkinOrange};
    }
    &.ant-btn-background-ghost {
      color: ${({ theme }) => theme.colors.white};
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  :active {
    color: ${({ theme }) => theme.colors.mainPressed};
    background-color: ${({ theme }) => theme.colors.secondaryPressed};
    &.ant-btn-primary {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.mainPressed};
    }
    &.ant-btn-dangerous {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.brownyOrange};
    }
    &.ant-btn-background-ghost {
      color: ${({ theme }) => theme.colors.white};
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
`
