import React from 'react'
import _ from 'lodash'
import { Scrollbar, ScrollbarProps } from 'react-scrollbars-custom'
import styled, { css, DefaultTheme } from 'styled-components/macro'
import { gridTheme } from '../../utils/theme'

type ColorFunc = (theme: DefaultTheme) => string

type MandatoryCustomScrollProps = {
  trackWidth: number
  trackSpacing: number
  trackMarginStart: number
  trackMarginEnd: number
  hideForDesktop: boolean
  maxThumbSizePercentage: number
  thumbColor: string | ColorFunc
  trackColor: string | ColorFunc
}

const defaultCustomProps: MandatoryCustomScrollProps = {
  trackWidth: 10,
  trackSpacing: 0,
  trackMarginStart: 10,
  trackMarginEnd: 10,
  hideForDesktop: false,
  maxThumbSizePercentage: 0,
  thumbColor: theme => theme.colors.main,
  trackColor: 'rgba(0, 0, 0, 0.1)'
}

export type CustomScrollProps = Partial<MandatoryCustomScrollProps>

const getDefaultScrollProps = (props?: CustomScrollProps): MandatoryCustomScrollProps => ({
  ...defaultCustomProps,
  ...props
})

const getBackgroundColor = (theme: DefaultTheme, color: string | ColorFunc, hidden: boolean): string => {
  if (!hidden) {
    return _.isString(color) ? color : color(theme)
  }
  return 'transparent'
}

type WithCustomScrollProps = {
  customTrackXProps?: CustomScrollProps
  customTrackYProps?: CustomScrollProps
  useFlex?: boolean
  maxWidth?: string
}

type WithSingleCustomScrollProps = {
  customTrackProps?: CustomScrollProps
  useFlex?: boolean
  maxWidth?: string
}

type BaseProps = Omit<ScrollbarProps, 'ref' | 'as' | 'noDefaultStyles'>

type ScrollWrapperProps = BaseProps & WithCustomScrollProps

type ScrollWrapperState = {
  thumbXWidth?: number
  thumbYHeight?: number
}

class BaseScrollbar extends React.PureComponent<ScrollWrapperProps, ScrollWrapperState> {
  state: ScrollWrapperState = {}

  handleUpdate: ScrollWrapperProps['onUpdate'] = (values, prevValues) => {
    const { onUpdate, customTrackXProps, customTrackYProps } = this.props
    const thumbXPercent = customTrackXProps?.maxThumbSizePercentage
    const thumbYPercent = customTrackYProps?.maxThumbSizePercentage
    if (onUpdate) onUpdate(values, prevValues)
    this.setState({
      thumbXWidth: thumbXPercent !== undefined ? thumbXPercent * values.clientWidth : undefined,
      thumbYHeight: thumbYPercent !== undefined ? thumbYPercent * values.clientHeight : undefined
    })
  }

  render() {
    const { thumbXWidth, thumbYHeight } = this.state

    return (
      <Scrollbar
        noDefaultStyles
        maximalThumbXSize={thumbXWidth}
        maximalThumbYSize={thumbYHeight}
        onUpdate={this.handleUpdate}
        {..._.omit(this.props, 'customTrackXProps', 'customTrackYProps', 'useFlex', 'onUpdate', 'maxWidth')}
      />
    )
  }
}

export const defaultScrollbarStyle = css<WithCustomScrollProps>`
  ${({ useFlex = false }) =>
    useFlex
      ? `
      flex: 1 1 auto;
      display: flex;
      `
      : `
      width: 100%;
      height: 100%;
      `}
  ${({ maxWidth }) =>
    maxWidth
      ? `
      max-width: ${maxWidth};
      display: flex;
      `
      : ''}

  .ScrollbarsCustom-Wrapper {
    position: relative !important;
    top: 0;
    left: 0;
    right: auto !important;
    bottom: auto !important;
    margin-bottom: 15px;

    ${({ useFlex = false }) =>
      useFlex
        ? `
      flex: 1 1 auto;
      display: flex;
      `
        : ''}

    .ScrollbarsCustom-Scroller {
      position: relative !important;
      top: 0;
      left: 0;
      right: auto !important;
      bottom: auto !important;
      ${({ useFlex = false }) =>
        useFlex
          ? `
        flex: 1 1 auto;
        `
          : ''}

      .ScrollbarsCustom-Content {
        min-width: 100%;
        min-height: 100%;
        ${({ useFlex = false }) =>
          useFlex
            ? `
          flex: 1 1 auto;
          `
            : ''}
      }
    }
  }

  .ScrollbarsCustom-Track {
    position: absolute;
    overflow: hidden;
    border-radius: 4px;
    user-select: none;
    z-index: 3;
  }

  .ScrollbarsCustom-Thumb {
    cursor: pointer;
    border-radius: 4px;
  }

  ${({ customTrackXProps, customTrackYProps, theme }) => {
    const trackXProps = getDefaultScrollProps(customTrackXProps)
    const trackYProps = getDefaultScrollProps(customTrackYProps)
    return `
      .ScrollbarsCustom-TrackY {
        width: ${trackYProps.trackWidth}px;
        height: calc(100% - ${trackYProps.trackMarginStart + trackYProps.trackMarginEnd}px);
        top: ${trackYProps.trackMarginStart}px;
        right: ${trackYProps.trackSpacing}px;
        background: ${getBackgroundColor(theme, trackYProps.trackColor, false)};
      }

      .ScrollbarsCustom-TrackX {
        height: ${trackXProps.trackWidth}px;
        width: calc(100% - ${trackXProps.trackMarginStart + trackXProps.trackMarginEnd}px);
        left: ${trackXProps.trackMarginStart}px;
        bottom: ${trackXProps.trackSpacing}px;
        background: ${getBackgroundColor(theme, trackXProps.trackColor, false)};
      }

      .ScrollbarsCustom-ThumbY {
        width: 100%;
        background: ${getBackgroundColor(theme, trackYProps.thumbColor, false)};
      }

      .ScrollbarsCustom-ThumbX {
        height: 100%;
        background: ${getBackgroundColor(theme, trackXProps.thumbColor, false)};
      }

      @media (min-width: ${gridTheme.breakpoints.md}px) {
        .ScrollbarsCustom-TrackY {
          background: ${getBackgroundColor(theme, trackYProps.trackColor, trackYProps.hideForDesktop)};
          ${trackYProps.hideForDesktop ? 'display: none;' : ''}
        }

        .ScrollbarsCustom-TrackX {
          background: ${getBackgroundColor(theme, trackXProps.trackColor, trackXProps.hideForDesktop)};
          ${trackXProps.hideForDesktop ? 'display: none;' : ''}
        }

        .ScrollbarsCustom-ThumbY {
          background: ${getBackgroundColor(theme, trackYProps.thumbColor, trackYProps.hideForDesktop)};
          ${trackYProps.hideForDesktop ? 'display: none;' : ''}
        }

        .ScrollbarsCustom-ThumbX {
          background: ${getBackgroundColor(theme, trackXProps.thumbColor, trackXProps.hideForDesktop)};
          ${trackXProps.hideForDesktop ? 'display: none;' : ''}
        }
      }
    `
  }}
`

export const StyledScrollWrapper = styled(BaseScrollbar)`
  ${defaultScrollbarStyle}
`

type HorizontalProps = Omit<BaseProps, 'noScrollY'> & WithSingleCustomScrollProps

export const StyledHorizontalScrollWrapper = ({ customTrackProps, ...props }: HorizontalProps) => (
  <StyledScrollWrapper noScrollY customTrackXProps={customTrackProps} {...props} />
)

type VerticalProps = Omit<BaseProps, 'noScrollX'> & WithSingleCustomScrollProps

export const StyledVerticalScrollWrapper = ({ customTrackProps, ...props }: VerticalProps) => (
  <StyledScrollWrapper noScrollX customTrackYProps={customTrackProps} {...props} />
)
