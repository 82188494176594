import { LocalStorageKeys } from './types'

export const getParsedValueFromLocalStorage = <T>(key: LocalStorageKeys, defaultValue: T) => {
  const localStorageValue = window.localStorage.getItem(key)

  if (localStorageValue) {
    return JSON.parse(localStorageValue) as T
  }

  return defaultValue
}

export const saveStringifiedToLocalStorage = (key: LocalStorageKeys, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value))

export const removeFromLocalStorage = (key: LocalStorageKeys) => window.localStorage.removeItem(key)
