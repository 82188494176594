/* eslint-disable import/no-default-export */
export default {
  ExcelFileType: 'Il file selezionato non è valido. Carica un file excel (estensione .xls o .xlsx).',
  FileNotEmpty: 'Il file non può essere vuoto.',
  Forbidden: 'Proibito',
  ExcludeCustomVariablesError:
    'La struttura delle Variabili Custom di questo portafoglio non è compatibile con quella del portafoglio di origine.',
  Length: 'Il "{{label}}" deve essere compreso tra {{min}} e {{max}} caratteri.',
  LengthLong: 'La lunghezza massima è di {{max}} caratteri',
  LengthShort: 'Il numero minimo di caractteri è {{min}}',
  MissingClaims: 'You are missing claims: {{claims}}.',
  NoSpecialCharacters: 'Il "{{label}}" non può contenere caratteri speciali',
  NoSpecialCharactersWoLabel: 'Il campo non può contenere caratteri speciali',
  MaxTagsCount: 'Numero massimo di tag forniti: 20',
  NotEmpty: 'Il campo non può essere vuoto.',
  CannotStartWithSpace: 'Il nome non può iniziare con uno spazio',
  PortfolioCreationError:
    'Attenzione! errore di creazione del portafoglio. Controlla il file selezionato e prova a caricare di nuovo.',
  PortfolioCreationFromListError:
    'Attenzione! Errore durante la creazione del portafoglio. Inserire la lista di identificativi e riprovare il caricamento.',
  PortfolioImportCompaniesLessThen:
    'Il template supera il numero massimo di {{maxCompaniesCount}} righe consentite, controlla prova a caricare di nuovo.',
  PortfolioImportCompaniesNotEmpty: "Il file caricato non è valido. Assicurati che ci sia almeno un'impresa.",
  PortfolioImportHasCustomVariableSheet: 'Foglio per le variabili personalizzate mancante nel file caricato.',
  PortfolioImportCustomVariableNames:
    'Le variabili personalizzate del file caricato devono contenere nomi delle etichette unici.',
  PortfolioImportValidStructure:
    'Il file caricato non è valido. Assicurarsi che la struttura del modello utilizzata sia la stessa di quella proposta.',
  Required: 'Il campo "{{label}}" è obbligatorio',
  RequiredWithoutFieldName: 'Il campo è obbligatorio',
  ServerError: 'Server error',
  MinimumLength8Characters: 'La password deve essere di almeno 8 caratteri',
  MinimumLength10Characters: 'La password deve essere di almeno 10 caratteri',
  MustContainLetter: 'La password deve contenere almeno una lettera e un numero',
  MustContainNumber: 'La password deve contenere almeno una lettera e un numero',
  CannotContainUsername: "La password non può contenere l'User Id",
  CannotBeSameAsOldPassword: "La nuova password deve essere diversa dall'ultima utilizzata",
  MustContainCapitalLetter: 'La password deve contenere almeno una maiuscola',
  CannotContainMoreThan2EqualCharactersInRow: 'La password non può contenere più di tre caratteri consecutivi identici',
  CannotContainMoreThan3EqualCharactersInRow: 'La password non può contenere più di tre caratteri consecutivi identici',
  MaximumLength25Characters: 'La password deve essere lunga almeno 8 caratteri e massimo 25 caratteri',
  UniqueName: 'Nome già esistente. Si prega di specificare un nome differente.',
  Warning: 'Attenzione',
  ForbiddenText: 'Sorry, you are not authorized to access this page.',
  PortfolioDownloadError: 'Warning! Portfolio download error. Please try again.',
  PortfolioLockErrorTitle: 'Conferma Operazione',
  PortfolioLockError: "Non è possibile completare l'operazione perchè è in esecuzione un'altra azione sul portafoglio.",
  PdfReportLockErrorTitle: 'Conferma Operazione',
  PdfReportLockError:
    "Creazione del pdf in corso per l'impresa {{companyName}}, attendere il completamento dell’operazione.",
  CouldNotValidatePortfolioName: 'Errore del server. Impossibile convalidare il nome del portafoglio.',
  MinMax: 'Il valore deve essere compreso tra {{min}} e {{max}}',
  OnlyIntegers: 'Sono consentiti solo numeri interi.',
  ProspectingSaveListError: 'Warning! portfolio creation error. Check the selected file and try uploading again.',
  NotEmail: 'Il valore non ha un formato email valido.',
  MustAgreeWithPrivacyPolicy: 'Devi accettare la privacy policy.',
  ContactError: 'Siamo spiacenti ma non siamo riusciti a elaborare il tuo messaggio.',
  ResetPasswordError: 'Siamo spiacenti ma non è stato possibile reimpostare la password.',
  DuplicatesNotAllowed: 'I duplicati non sono ammessi.',
  CreateGoal: 'Siamo spiacenti ma non siamo riusciti a creare il tuo obiettivo.',
  SharePortfolio: 'We are sorry but we could not share portfolio.',
  OnlyNumbersOrSpace: 'Il "{{label}}" può contenere solo numeri o spazi',
  CampaignNameAlreadyExist: 'Nome della campagna già esistente. Scegli un nome diverso',
  CampaignNameTooShort: 'Il nome della campagna deve essere compreso tra 5 e 35 lettere',
  CampaignNameTooLong: 'Il nome della campagna deve essere compreso tra 5 e 35 lettere',
  CampaignEmpty: 'Il nome della campagna è obbligatorio',
  CampaignInvalidName: 'Il nome della campagna non può contenere caratteri speciali',
  UniqueGoalName: 'Nome del goal già esistente. Si prega di specificare un nome differente.',
  UniqueTaskName: 'Nome già esistente. Si prega di specificare un nome differente.',
  StartCampaignError: 'Si è verificato un errore e non è stato possibili avviare la campagna. Riprova più tardi.',
  StopCampaignError: 'Si è verificato un errore e non è stato possibili terminare la campagna. Riprova più tardi.',
  DuplicateCompanyError: "Impossibile duplicare l'impresa: stato e prodotto scelti risultano già assegnati.",
  DeleteCampaign: 'Si è verificato un errore e non è stato possibile eliminare la campagna. Riprova più tardi.',
  ChangeCampaignName:
    'Si è verificato un errore e non è stato possibile cambiare il nome della campagna. Riprova più tardi.',
  ChangeEndDate: 'Si è verificato un errore e non è stato possibile cambiare la data. Riprova più tardi.',
  ChangeStartDate: 'Si è verificato un errore e non è stato possibile cambiare la data. Riprova più tardi.',
  ChangeCampaignNote: 'Si è verificato un errore e non è stato possibile cambiare la nota. Riprova più tardi.',
  AddCompanyNote: 'Si è verificato un errore e non è stato possibile aggiungere la nota. Riprova più tardi.',
  DeleteCompanyNote: 'Si è verificato un errore e non è stato possibile eliminare la nota. Riprova più tardi.',
  FetchCompanyNotes: 'Si è verificato un errore nel caricamento delle note. Riprova più tardi.',
  AssignTask: 'Si è verificato un errore e non è stato possibile assegnare il task selezionato. Riprova più tardi.',
  CampaignDownloadError:
    'Si è verificato un errore e non è stato possibile scaricare il file excel. Riprova più tardi.',
  RenewCampaign: 'Impossibile rinnovare la campagna in quanto contiene un portafoglio non valido.',
  AssignCampaignError: 'Impossibile salvare i prodotti e venditori assegnati. Riprova più tardi.',
  AssignCampaignErrorCompaniesDuplicated:
    "Si è verifato un errore durante l'assegnazione di una o più società. Alcune società risultano duplicate.",
  FetchCampaigns: 'Si è verificato un errore nel caricamento delle campagne. Riprova più tardi.',
  SaveCampaign: 'Si è verificato un errore durante il salvataggio della campagna. Riprova più tardi.',
  DuringCampaignCreation:
    'Si è verificato un errore e non è possibile completare la procedura di creazione della campagna. Riprova più tardi.',
  FetchCompanies: 'Si è verificato un errore nel caricamento delle imprese.',
  EnrichCompany: "Siamo spiacenti ma l'arricchimento delle imprese è fallito.",
  NotFound: {
    Title: 'Non trovato',
    Text: 'Spiacenti, la pagina che hai visitato non esiste più.',
    BackHome: 'Indietro',
    DocumentNotFound: 'Il documento non è più disponibile.',
    PortfolioNotFound: 'Il portafoglio non è più disponibile.',
    CampaignNotFound: 'La campagna non è più disponibile.',
    GoBack: 'Indietro',
    QueryNotFound: 'La query non è disponibile.',
    ReportLoadFailed: 'Il report di questa impresa non è al momento disponibile.',
    ReportLoadFailedBody: 'Spiacenti, la pagina che hai visitato non è disponibile.',
    Section: 'La sezione non è disponibile',
    SectionForCountry: 'Questa sezione non è disponibile per il tuo paese ({{country}})',
    EnrichmentNotPossible: 'Non è possibile arricchire questa impresa.',
    EnrichmentNotPossibleBody:
      "L'impresa ricercata non è arricchibile perché potrebbe appartenere ad un portafoglio nascosto da un altro utente della tua organizzazione.",
    ReportNotAvailable: '	Report non disponibile.',
    ReportNotAvailableBody:
      "L'impresa ricercata non è accessibile perché potrebbe appartenere ad un portafoglio nascosto da un altro utente della tua organizzazione."
  },
  ErrorPage: {
    Text: 'An unexpected error occured.'
  },
  ConfigGuardErrorPage: {
    Title: 'We are sorry',
    Text: 'Application config could not be loaded.',
    Reload: 'Reload configuration',
    Logout: 'Logout'
  },
  CreateProduct: 'Siamo spiacenti ma non siamo riusciti a creare il tuo prodotto.',
  UniqueProductName: 'Nome del prodotto esistente. Si prega di specificare un nome diverso.',
  PasswordSameAsOld: "La nuova password deve essere diversa dall'ultima utilizzata",
  PasswordCouldNotBeChanged: 'Impossibile modificare la password. Controllare che la password rispetti i requisiti.',
  InvalidOldPassword: 'La password inserita non è corretta.',
  UsernameDoesMatch: "L'Username dell'utente impersonato è lo stesso della tua utenza.",
  PasswordContainsUsername: "La password non può contenere l'Utente",
  PasswordCharacterAndDigit: 'La password deve contenere almeno una lettera e un numero',
  PasswordMultipleCharacters: 'La password non può contenere più di tre caratteri consecutivi identici',
  PasswordDoesNotMatch: 'La password non corrisponde',
  DisallowedPassword: 'La password non è permessa',
  SelectAtLeastOneCompany: "Per proseguire è necessario selezionare almeno un'impresa.",
  TargetingLoadPortfolio: 'Errore durante il caricamento del portafoglio selezionato',
  TargetingLoadPortfolioDescription:
    'Puoi riprovare più tardi, oppure il portafoglio scelto potrebbe non essere valido',
  TargetingLoadQuery: "Errore durante il caricamento dell'analisi selezionata",
  TargetingLoadQueryDescription: "Puoi riprovare più tardi, oppure l'analisi scelta potrebbe non essere valida",
  TargetingDownloadAnalysisError: "Si è verificato un errore e non è stato possibile scaricare l'analisi.",
  GenericMessage: "Si è verificato un errore durante l'elaborazione della tua richiesta.",
  LoginSE: 'Si è verificato un problema con il login, riprova più tardi.',
  TooManyWordsInFilter:
    "E' stato superato il numero massimo di 1.000 parole inseribili contemporaneamente ({{wordCount}}/1000)",
  NoEmptyValues: 'La query non può contenere valori vuoti.',
  ProspectingFunnelErrorTitle: 'Errore nel caricamento del funnel',
  ProspectingFunnelErrorText: 'Errore nel caricamento del funnel. Riprova a contatta il servizio clienti.',
  MinValueIsRequired: 'Il valore "Min" è obbligatorio',
  MaxValueIsRequired: 'Il valore "Max" è obbligatorio',
  MatchingTermDoesNotExist: '{{matchingTerm}} non esiste',
  CountryRequired: 'Si prega di selezionare una nazione',
  R_24: 'L\'aggiornamento di alcuni campi per questa impresa causerebbe una duplicazione. Esiste già un\'impresa nel portafoglio con gli stessi valori per "Stato in portafoglio" e "Prodotto".',
  R_5: 'Il numero massimo di tag supera il limite consentito di 20.',
  DataLoadError: 'Impossibile caricare i dati. Riprovare più tardi. Per favore riprova più tardi.',
  SaveChangesFailed: 'Si è verificato un errore durante il salvataggio delle modifiche. Per favore riprova più tardi.',
  PortfolioImportCustomVariableMaxLabelLimit:
    'Le etichette delle Variabili Custom non possono contenere più di 256 caratteri.',
  FailedToLoadTermsOfUse: 'Impossibile caricare le condizioni di utilizzo, riprovare più tardi.',
  InvalidPhone: 'Formato del numero di telefono non valido'
}
