// eslint-disable-next-line import/no-default-export
export default {
  Close: 'Close',
  Popup: {
    TaxCode: 'Tax code',
    BirthDate: 'Date of birth',
    BirthTown: 'Birth town',
    Enriched: 'Enriched',
    Enrich: 'Enrich'
  },
  NotEnriched: {
    Title: 'Purchase {{companyName}} data to unlock this section',
    Message: 'You can unlock this section by purchasing {{companyName}} data',
    Action: 'Unlock {{companyName}} data'
  },
  IdentificationData: {
    Title: 'Identification data',
    TaxCode: 'Tax code',
    BirthDate: 'Date of birth',
    BirthPlace: 'Birthplace',
    BirthCountry: 'Birth country',
    Sex: 'Sex',
    Address: 'Address'
  },
  Positions: {
    Title: 'Active and former positions',
    ActivePositions: {
      Title: 'Active positions'
    },
    PastPositions: {
      Title: 'Past positions'
    },
    From: 'From',
    To: 'To'
  },
  PartnersList: {
    Title: 'Partner list',
    Amount: 'Amount'
  },
  Enrich: {
    Title: 'Enrich person',
    Desc: 'By enriching person with the {{companyName}} data, these information will be downloaded and will be updated periodically',
    Success: 'Enrichment was successful',
    CreditsWillBeUsed: 'Rings used',
    CreditsRemaining: 'Remaining rings',
    ProposalFailed: 'There was problem with checking available credits for this operation.'
  }
}
