import React from 'react'
import { Result as AntResult } from 'antd'
import { Image } from '../Image'

type Props = {
  title: string
  subTitle: string
  imageSrc: string
  extra: React.ReactNode
  alt?: string
}

export const Result = ({ title, subTitle, imageSrc, extra, alt = '' }: Props) => {
  return (
    <AntResult
      icon={<Image src={imageSrc} alt={alt} width="100%" height="100%" maxWidth={500} />}
      title={title}
      subTitle={subTitle}
      extra={extra}
    />
  )
}
