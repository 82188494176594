/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Appuntamento',
  CreateAppointmentButton: 'Crea nuovo appuntamento',
  ManageAppointmentsButton: 'Gestisci appuntamento',
  EditAppointment: 'Modifica appuntamento',
  SendEmailLabel: 'Invia dettagli al mio calendario e notifica i destinatari',
  ForHeadquarterButton: 'Per sede legale',
  ForLocalBranchButton: 'Per questa unità locale',
  AppointmentDetails: {
    Title: 'Inserisci nome, data e luogo relativi al tuo nuovo appuntamento',
    EditTitle: 'Modifica nome, data e luogo relativi al tuo appuntamento',
    MatchingTermIta: 'Codice Fiscale/Partita IVA',
    MatchingTermDeu: 'Codice Fiscale/Partita IVA',
    MatchingTermChe: 'Identificativo Azienda / CRIF Id',
    MatchingTermAut: 'Partita Iva / Company book number',
    MatchingTermCheAut: 'Identificativo Azienda / CRIF Id / Partita Iva / Company book number',
    MatchingTermCzsk: 'Codice Fiscale/Partita IVA',
    MatchingTermFareast: 'Codice Fiscale/Partita IVA',
    Name: 'Nome appuntamento',
    Description: 'Descrizione appuntamento',
    Place: 'Luogo',
    StartDate: 'Data di inizio',
    EndDate: 'Data di fine',
    SelectColor: 'Imposta un colore'
  },
  CreateAppointment: {
    Title: 'Crea appuntamento',
    ShareAppointmentStep: {
      Title: 'Seleziona destinatari',
      Text: 'Seleziona gli utenti a cui vuoi inoltrare il promemoria o inserisci l’indirizzo email dei destinatari, separati da virgola',
      Email: 'Indirizzi email, separati da virgola'
    },
    ConfirmationStep: {
      Title: 'Conferma operazione',
      Text: "Vuoi inviare l'appuntamento nel tuo calendario? Imposta la selezione su ON: Margò ti invierà una mail contenente tutti i dettagli appena inseriti"
    }
  },
  DeleteAppointment: {
    Title: 'Cancella appuntamento',
    SubTitle: "Sei sicuro di voler cancellare l'appuntamento?"
  },
  AppointmentsList: {
    EmptyText: 'Qui troverai tutti gli appuntamenti salvati sul tuo calendario.',
    NameColumn: 'Nome',
    StartColumn: 'Inizio',
    EndColumn: 'Fine',
    ShowExpired: 'Mostra scaduti',
    All: 'Tutti'
  },
  Colors: {
    Green: 'Verde',
    Yellow: 'Giallo',
    Orange: 'Arancione',
    Red: 'Rosso',
    Violet: 'Viola',
    Blue: 'Blu',
    LightBlue: 'Azzurro',
    Turquoise: 'Turchese',
    Pink: 'Fucsia',
    Black: 'Nero'
  }
}
