import { usePrevious } from '@hooks/usePrevious'
import React, { createContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

type RouterContextType = {
  to?: string
  from?: string
}

export const RouterContext = createContext<RouterContextType>({})

export const RouterProvider = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useLocation()
  const prevPathname = usePrevious(pathname, true, true)
  const providerValue = useMemo(
    () => ({
      to: pathname,
      from: prevPathname
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  )

  return <RouterContext.Provider value={providerValue}>{children}</RouterContext.Provider>
}
