// eslint-disable-next-line import/no-default-export
export default {
  LeadSection: {
    Title: 'The toolbox for sales success',
    Text: 'Margo API offers a unique set of registered companies data to support your business growth and CRM automation. Leverage the wide range of data packets available to access official information like employees and turnover data, company addresses, contacts and advanced analytics. Enhance your CRM and customer databases with the latest information to analyze markets, find new prospects and business opportunities.'
  },
  MiddleSection: {
    Title: 'Margò API at a glance',
    ItemCompanies: 'Data of 6M Italian companies updated daily',
    ItemAnalytics: '40+ Advanced Analytics and Indicators',
    ItemFilters: 'Over 300 filters to select and clusterize companies',
    ItemPackets: '130+ data packets to access themed company data'
  },
  BottomSection: {
    Title: 'Unleash the potential of your business, streamline the sales organization and automate manual processes',
    AutomationTitle: 'CRM automation',
    AutomationText:
      'Integrate Margo data in your CRM to keep your database up-to-date with official registries and deliver relevant information to your sales team.',
    MarketAnalysisTitle: 'Market analysis and AI',
    MarketAnalysisText:
      'Connect your business intelligence systems to Margo and enrich your customer base data. Supercharge your AI models with new data.',
    EnrichmentTitle: 'Data enrichment and targeting',
    EnrichmentText:
      'Create targeted company clusters by enriching your customer base data with the latest company information available.',
    BetterDataTitle: 'Better data on your leads',
    BetterDataText:
      'Enhance your sales campaigns with data that helps your commercial team evaluate companies and close deals faster.',
    ProspectingTitle: 'Prospecting',
    ProspectingText:
      'Discover new market niches and find the best prospects for your products, import them on your systems and get the sales started.',
    MarketingTitle: 'Marketing analytics',
    MarketingText:
      'Find companies that do international trade, innovate or have a high digital attitude. View turnover estimates of SME companies.',
    FinanceTitle: 'Finance analytics',
    FinanceText:
      'Select companies that have a high propensity to subscribe to different financial products and discover the seasonality of their financing needs.',
    OnboardingTitle: 'Onboarding and e-invoicing',
    OnboardingText:
      'Create frictionless sign-up forms for your B2B business and take advantage of Margo data to streamline administrative tasks.'
  },
  LearnMore: 'Learn more'
}
