import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ConfigProvider } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { Result } from '../Result'
import { Button } from '../Button'
import { Loading } from '../Loading'
import { ConfigActions } from '../../features/config/actions'
import { CurrencyActions } from '../../features/currencies'
import { canLoadUserConfig, getUserConfigStatus } from '../../features/config/selectors'
import { UserActions } from '../../features/user/actions'
import ErrorImage from '../../assets/images/illustration-error.svg'
import { LoadStatus } from '../../features/config/types'
import { useAntdLocale } from './useAntdLocale'
import { theme } from '../../utils/theme'

type Props = {
  children: JSX.Element
  /**
   * Just for testing purposes
   */
  loadingComponent?: JSX.Element
}

export const ConfigGuard = ({ children, loadingComponent }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()
  const location = useLocation()

  const canStartLoading = useSelector(canLoadUserConfig)
  const configLoadStatus = useSelector(getUserConfigStatus)
  const antdLocale = useAntdLocale()

  const fetchConfig = useCallback(() => {
    dispatch(ConfigActions.handleUserConfig(location, navigate))
    dispatch(CurrencyActions.fetchCurrencies())
  }, [dispatch, location, navigate])

  useEffect(() => {
    if (canStartLoading && configLoadStatus !== LoadStatus.FAILED) fetchConfig()
  }, [canStartLoading, configLoadStatus, fetchConfig])

  if (configLoadStatus === LoadStatus.FAILED)
    return (
      <Result
        imageSrc={ErrorImage}
        title={t('ConfigGuardErrorPage.Title')}
        subTitle={t('ConfigGuardErrorPage.Subtitle')}
        extra={
          <>
            <Button type="primary" onClick={fetchConfig}>
              {t('ConfigGuardErrorPage.Reload')}
            </Button>

            <Button type="primary" onClick={() => dispatch(UserActions.logout(false))}>
              {t('Logout')}
            </Button>
          </>
        }
      />
    )
  if (configLoadStatus === LoadStatus.LOADING || configLoadStatus === LoadStatus.NOT_LOADED) {
    return loadingComponent || <Loading />
  }
  return (
    <ConfigProvider
      locale={antdLocale}
      theme={{
        token: {
          fontFamily: theme.fontFamily
        }
      }}
    >
      {children}
    </ConfigProvider>
  )
}
