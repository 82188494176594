import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'

export const SET_HIDDEN_TASK_POPUP_EXPIRATION_DATE = 'view/saleDetail/SET_HIDDEN_TASK_POPUP_EXPIRATION_DATE'

export const SaleDetailActions = {
  setHiddenTaskPopupExpirationDate: (date: string) => createAction(SET_HIDDEN_TASK_POPUP_EXPIRATION_DATE, date)
}

export type SaleDetailActions = ActionsUnion<typeof SaleDetailActions>
