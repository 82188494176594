import _ from 'lodash'
import { Reducer } from 'redux'
import * as actions from './actions'
import { PortfolioCompanyDuplicate } from './types'

type PortfolioState = {
  companies: PortfolioCompanyDuplicate[]
  lookup: Record<string, PortfolioCompanyDuplicate[]>
  statuses: Record<string, string | undefined>
  products: Record<string, string | undefined>
  resolved: string[]
  showResolved: boolean
  groupActions: boolean
  checkedRowIds: string[]
  validating: string[]
  closed: boolean
}

export const portfolioInitState: PortfolioState = {
  companies: [],
  lookup: {},
  statuses: {},
  products: {},
  resolved: [],
  showResolved: true,
  groupActions: false,
  checkedRowIds: [],
  validating: [],
  closed: false
}

export type PortfolioCompanyDuplicatesState = Readonly<{
  duplicates: Record<string, PortfolioState>
}>

export const initState: PortfolioCompanyDuplicatesState = {
  duplicates: {}
}

const update = (
  state: PortfolioCompanyDuplicatesState,
  portfolioId: string,
  portfolioStateUpdate: Partial<PortfolioState>
): PortfolioCompanyDuplicatesState => {
  return {
    ...state,
    duplicates: {
      ...state.duplicates,
      [portfolioId]: {
        ...portfolioInitState,
        ...state.duplicates[portfolioId],
        ...portfolioStateUpdate
      }
    }
  }
}

export const portfolioCompanyDuplicatesReducer: Reducer<
  PortfolioCompanyDuplicatesState,
  actions.PortfolioCompanyDuplicatesActions
> = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_DUPLICATES:
      return update(state, action.payload.portfolioId, { companies: action.payload.duplicates })
    case actions.TOGGLE_SHOW_RESOLVED:
      return update(state, action.payload.portfolioId, {
        showResolved: !state.duplicates[action.payload.portfolioId].showResolved
      })
    case actions.TOGGLE_GROUP_ACTIONS: {
      const groupActions = !state.duplicates[action.payload.portfolioId].groupActions
      const updated = { groupActions }
      if (!groupActions) {
        // @ts-ignore
        updated.checkedRowIds = []
      }
      return update(state, action.payload.portfolioId, updated)
    }
    case actions.SET_CHECKED_ROWS:
      return update(state, action.payload.portfolioId, { checkedRowIds: action.payload.rowIds })
    case actions.SET_ALL_ROWS_CHECKED:
      return update(state, action.payload.portfolioId, {
        checkedRowIds: action.payload.checked ? _.map(state.duplicates[action.payload.portfolioId].companies, 'id') : []
      })
    case actions.SET_DUPLICATE_STATUS: {
      const { groupActions, checkedRowIds } = state.duplicates[action.payload.portfolioId]
      if (groupActions && _.includes(checkedRowIds, action.payload.rowId)) {
        return update(state, action.payload.portfolioId, {
          statuses: {
            ...state.duplicates[action.payload.portfolioId].statuses,
            ..._.reduce(checkedRowIds, (acc, rowId) => _.set(acc, rowId, action.payload.status), {})
          }
        })
      }
      return update(state, action.payload.portfolioId, {
        statuses: {
          ...state.duplicates[action.payload.portfolioId].statuses,
          [action.payload.rowId]: action.payload.status
        }
      })
    }
    case actions.SET_DUPLICATE_PRODUCT: {
      const { groupActions, checkedRowIds } = state.duplicates[action.payload.portfolioId]
      if (groupActions && _.includes(checkedRowIds, action.payload.rowId)) {
        return update(state, action.payload.portfolioId, {
          products: {
            ...state.duplicates[action.payload.portfolioId].products,
            ..._.reduce(checkedRowIds, (acc, rowId) => _.set(acc, rowId, action.payload.product), {})
          }
        })
      }
      return update(state, action.payload.portfolioId, {
        products: {
          ...state.duplicates[action.payload.portfolioId].products,
          [action.payload.rowId]: action.payload.product
        }
      })
    }
    case actions.SET_LOOKUP:
      return update(state, action.payload.portfolioId, {
        lookup: {
          ...state.duplicates[action.payload.portfolioId].lookup,
          [action.payload.identificationCode]: action.payload.lookup
        }
      })
    case actions.SET_VALIDATING: {
      const { portfolioId, rowId, isValidating } = action.payload
      const { validating } = state.duplicates[portfolioId]
      return update(state, portfolioId, {
        validating: isValidating ? [...validating, rowId] : _.without(validating, rowId)
      })
    }
    case actions.SET_RESOLVED: {
      const { portfolioId, rowId, isResolved } = action.payload
      const { resolved } = state.duplicates[portfolioId]
      return update(state, portfolioId, {
        resolved: isResolved ? [...resolved, rowId] : _.without(resolved, rowId)
      })
    }
    case actions.CLOSE:
      return update(state, action.payload.portfolioId, { closed: true })
    default:
      return state
  }
}
