/* eslint-disable import/no-default-export */
export default {
  BackToLogin: 'Späť na prihlásenie',
  SimpleUsefulPowerful: 'Jednoduché, užitočné a výkonné.',
  MargoWasSurprise: 'Margo bolo pre mňa a môj tím úžasným prekvapením.',
  ContactCrif: 'Kontaktujte {{companyName}} a otvorte si účet alebo požiadajte o informácie.',
  ContactUsText: 'Kontaktujte nás a otvorte si účet alebo požiadajte o informácie.',
  Name: 'Meno',
  InsertName: 'Zadajte svoje meno',
  Surname: 'Priezvisko',
  InsertSurname: 'Zadajte svoje priezvisko',
  Email: 'E-mail',
  InsertEmail: 'Zadajte svoj e-mail',
  Message: 'Správa',
  InsertMessage: 'Sem napíšte svoj text',
  IAgreeWith: 'Súhlasím s',
  PrivacyPolicy: 'politikou ochrany osobných údajov',
  Send: 'Poslať',
  Success: 'Vaša správa bola odoslaná spoločnosti {{companyName}}.',
  ContactCRIF: 'Kontaktujte {{companyName}}',
  ContactUs: 'Kontaktujte nás',
  Company: 'Spoločnosť',
  InsertCompany: 'Zadajte svoju spoločnosť',
  Country: 'Krajina',
  InsertCountry: 'Vyberte svoju krajinu',
  JobTitle: 'Názov pracovnej pozície',
  InsertJobTitle: 'Zadajte názov svojej pracovnej pozície',
  Telephone: 'Telefónne čislo',
  InsertTelephone: 'Zadajte svoje telefónne číslo',
  CompanyName: 'Názov spoločnosti',
  ContactCustomer: 'Kontaktujte zákazníka',
  EnterContactDetails: 'Zadajte kontaktné údaje',
  ContactName: 'Meno kontaktnej osoby',
  ContactSurname: 'Priezvisko kontaktnej osoby',
  ContactEmail: 'E-mail kontaktnej osoby',
  ContactTelephone: 'Telefónne číslo kontaktnej osoby',
  ConfirmOperation: 'Potvrďte operáciu',
  ConfirmOperationDescription: 'Operácia môže trvať niekoľko minút. Chcete pokračovať?',
  CreateOffer: 'Vytvorte ponuku',
  ContactCustomerOnline: 'Kontaktujte zákazníka online'
}
