import { put } from 'redux-saga/effects'
import { NOT_FOUND } from '../../../routes'
import { PortfolioCompanyDuplicatesActions } from '../../portfolioCompanyDuplicates/actions'
import { LOAD_PORTFOLIO_DETAIL_WITHOUT_PORTFOLIO, PortfolioDetailViewActionType } from '../actions'

// it is only copy of handleLoadPortfolioDetail without portfolio fetching
export function* handleLoadPortfolioDetailWithoutPortfolio(
  action: PortfolioDetailViewActionType<'loadPortfolioDetailWithoutPortfolio'>
) {
  if (action.type !== LOAD_PORTFOLIO_DETAIL_WITHOUT_PORTFOLIO) return

  const { portfolioId, navigate } = action.payload

  try {
    yield put(PortfolioCompanyDuplicatesActions.fetchDuplicates(portfolioId))
  } catch (e) {
    navigate(
      { pathname: NOT_FOUND },
      {
        replace: true,
        state: {
          textKey: '',
          titleKey: 'PortfolioNotFound',
          showMenu: true,
          image: 'koImage'
        }
      }
    )
  }
}
