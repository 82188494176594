import _ from 'lodash'
import { css } from 'styled-components/macro'
import { formatStyleSize } from '../../utils/helpers'
import { theme } from '../../utils/theme'

export type Offset =
  | {
      top?: string | number
      left?: string | number
      right?: string | number
      bottom?: string | number
    }
  | string
  | number

const offsets = (cssProp: string, offset: Offset) => {
  if (_.isNumber(offset) || _.isString(offset)) {
    return `${cssProp}: ${formatStyleSize(offset)};`
  }
  const { top, left, right, bottom } = offset
  return `${cssProp}: ${formatStyleSize(top || 0)} ${formatStyleSize(right || 0)} ${formatStyleSize(
    bottom || 0
  )} ${formatStyleSize(left || 0)};`
}

export const ellipsisMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export type TextMixinProps = {
  size: string
  lineHeight: number
  bold: boolean
  center: boolean
  ellipsis: boolean
  margin?: Offset
  padding?: Offset
  onClick?: () => void
}

export const textMixin = ({
  size,
  lineHeight,
  bold,
  center,
  ellipsis,
  margin,
  padding,
  onClick
}: TextMixinProps) => css`
  fill: currentColor;
  font-size: ${size};
  line-height: ${lineHeight};
  font-weight: ${bold ? 'bold' : 'normal'};
  ${ellipsis &&
  css`
    && {
      ${ellipsisMixin}
    }
  `};
  ${center && 'text-align: center;'}
  ${!_.isNil(margin) ? offsets('margin', margin) : ''}
  ${!_.isNil(padding) ? offsets('padding', padding) : ''}
  ${onClick &&
  css`
    :hover {
      cursor: pointer;
    }
  `}
`

export type TitleLevel = 1 | 2 | 3 | 4

export const convertNumberToSupportingHeadingLevel = (headLevel: number | undefined): TitleLevel | undefined => {
  if (headLevel === 1 || headLevel === 2 || headLevel === 3 || headLevel === 4) return headLevel
  return undefined
}

type TitleLevelProps = {
  as: 'h1' | 'h2' | 'h3' | 'h4'
  size: string
  bold: boolean
}

const TITLE_LEVELS: Record<TitleLevel, TitleLevelProps> = {
  1: { as: 'h1', size: theme.fontSizes.extraLarge, bold: true },
  2: { as: 'h2', size: theme.fontSizes.large, bold: true },
  3: { as: 'h3', size: theme.fontSizes.medium, bold: true },
  4: { as: 'h4', size: theme.fontSizes.medium, bold: false }
}

export const titleLevelProps = (level?: TitleLevel) => TITLE_LEVELS[level || 2]

export type TextSize = 'S' | 'M' | 'L' | 'XL' | number

type TextSizeProps = {
  size: string
  lineHeight: number
}

const TEXT_SIZES: Record<TextSize, TextSizeProps> = {
  S: { size: theme.fontSizes.extraSmall, lineHeight: 1.5 },
  M: { size: theme.fontSizes.small, lineHeight: 1.71 },
  L: { size: theme.fontSizes.medium, lineHeight: 1.33 },
  XL: { size: theme.fontSizes.large, lineHeight: 1.5 }
}

export const textSizeProps = (size?: TextSize) => {
  if (_.isNumber(size)) {
    return {
      size: `${size}px`,
      lineHeight: 1.5
    }
  }
  return TEXT_SIZES[size || 'M']
}
