import React from 'react'
import { StyledText } from '@components/Text'
import { useTranslation } from 'react-i18next'
import { ConfirmationBarWrapper, StyledButton } from './styled'

type ConfirmationBarProps = {
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmationBar: React.FC<ConfirmationBarProps> = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation()

  return (
    <ConfirmationBarWrapper>
      <StyledText lineHeight={1.6}>{t('common:AssistantAi.DeletionConfirmation')}</StyledText>
      <StyledButton onClick={onCancel}>{t('common:Cancel')}</StyledButton>
      <StyledButton type="primary" onClick={onConfirm}>
        {t('common:Ok')}
      </StyledButton>
    </ConfirmationBarWrapper>
  )
}
