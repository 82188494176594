import debounce from 'debounce-promise'
import { validateName } from './api'
import { parseErrors } from '../../features/form/utils'
import { NAME_VALIDATION_DEBOUNCE_TIME } from '../../features/constants'
import { takeLatestPromise } from '../../utils/promise'

export const validateNewPortfolioName = debounce(
  takeLatestPromise(async (newPortfolioName: string) => {
    try {
      await validateName(newPortfolioName)
    } catch (e: any) {
      const errors = parseErrors(e)
      return errors.name
    }
    return undefined
  }),
  NAME_VALIDATION_DEBOUNCE_TIME
)
