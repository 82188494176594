/* eslint-disable import/no-default-export */
export default {
  Username: 'Benutzername',
  Password: 'Passwort',
  Login: 'Login',
  LoginErrorMessage: 'Benutzername oder Passwort ist falsch.',
  SignInMessage: 'Melden Sie sich bei margò an',
  EnterDetails: 'Geben Sie bitte unten Ihre Daten ein.',
  InsertUserName: 'Geben Sie Ihren Benutzernamen ein',
  InsertUserNamaInBehalf: 'Im Auftrag von',
  InsertPassword: 'Geben Sie Ihr Passwort ein',
  ForgotPassword: 'Haben Sie Ihr Passwort vergessen?',
  ResetPasswordDialog: {
    Title: 'Passwort vergessen',
    Description:
      'Um Ihr Passwort zurück zu setzen, kontaktieren Sie bitte unseren Customer Service. E-Mail: <0> {{email}} </0> Telefon: {{phone}}',
    Email: 'customerserviceitaly@cribis.com',
    Phone: '800 821058'
  },
  ResetPasswordForm: {
    Description:
      'Geben Sie Ihren Benutzernamen und die mit Ihrem Konto verknüpfte E-Mail-Adresse ein. Sie erhalten eine E-Mail mit dem Link zum Vervollständigen des Zurücksetzens des Passworts.',
    SuccessDesription:
      'Eine E-Mail mit dem Link zum Zurücksetzen des Passworts wurde Ihnen zugesandt. Gehen Sie zur Anmeldeseite.',
    Email: 'E-Mail',
    ResetPassword: 'Passwort zurücksetzen',
    ReturnToLoginpage: 'Zurück zur Anmeldeseite',
    InsertUserName: 'Geben Sie Ihren Benutzernamen ein',
    InsertEmail: 'Geben Sie Ihre E-Mail ein',
    InvalidUsernameOrPassword:
      'Das Passwort wurde nicht zurückgesetzt. Benutzername/E-Mail ungültig. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundendienst.',
    ResetFailed: 'Das Zurücksetzen des Passworts ist fehlgeschlagen.'
  },
  RenewPasswordForm: {
    Title: 'Passwort zurücksetzen',
    Description:
      'Ihr Passwort ist abgelaufen. Um Zugang zu den Margo-Diensten zu erhalten, ändern Sie bitte Ihr Passwort.',
    OldPassword: 'Aktuelles Passwort',
    NewPassword: 'Neues Passwort',
    ConfirmPassword: 'Neues Passwort wiederholen',
    ChangePassword: 'Passwort ändern',
    RenewPasswordFailed: 'Das alte Passwort ist falsch.',
    PasswordChangeSuccessTitle: 'Passwort geändert',
    PasswordChangeSuccessText: 'Passwort wurde erfolgreich geändert'
  },
  PasswordExpiration90Days: 'Das Passwort bleibt 90 Tage gültig',
  PasswordRules_deu: `Das Passwort muss mindestens 8 Zeichen lang sein
  Das Passwort muss mindestens einen Buchstaben und eine Zahl enthalten
  Das Passwort darf nicht mehr als 3 aufeinanderfolgende identische Zeichen enthalten
  Das Passwort darf nicht die Benutzer-ID enthalten
  Das Passwort darf nicht mit den letzten sechs verwendeten Passwörtern identisch sein`,
  PasswordRules_che: `Die Mindestlänge des Passworts beträgt 8 Zeichen und die maximale Länge 25 Zeichen
  Das Passwort muss mindestens einen Buchstaben und eine Ziffer enthalten
  Das Passwort darf nicht mit dem alten Passwort identisch sein und darf nicht den Benutzernamen enthalten
  Das Passwort muss mindestens einen Großbuchstaben enthalten
  Das Passwort darf nicht 3 aufeinanderfolgende gleiche Zeichen enthalten`,
  Cs: {
    Title: 'Der Benutzer ist bereits angemeldet',
    Info: 'Ihr Benutzer ist bereits angemeldet. Anschließend beenden Sie die anderen aktiven Sitzungen. Alle Hintergrundoperationen werden beibehalten.',
    Question: 'Möchten Sie eine vorhandene Sitzung schließen und eine neue starten?',
    NewSession: 'Neue Sitzung starten'
  },
  Sso: {
    error: 'Es gab ein Problem mit der Anmeldung über die "Single-Sign-On-Methode".',
    back: 'Melden Sie sich mit Benutzername und Passwort an.'
  }
}
