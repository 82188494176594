import axios, { CancelTokenSource } from 'axios'
import { ProspectingCompaniesRes } from '../prospecting/types'
import {
  CompaniesEnrichmentProposalRequest,
  CompaniesEnrichmentProposalResponse,
  QueryCompaniesResponse,
  QueryCompaniesRequest,
  CreateFromCompoundExpressionRequest,
  AddCompaniesByCompoundExpressionRequest,
  EnrichCompaniesResponse,
  QueryFilterCompaniesRequest,
  FreeTextCompaniesRequest,
  QueryLocationsRequest,
  QueryLocationsResponse,
  QueryAllowLocalUnitsActionRequest,
  QueryAllowLocalUnitsActionResponse,
  GetMatchingTargetResponse
} from './types'

export const getEnrichmentProposalQuery = (
  requestData: CompaniesEnrichmentProposalRequest,
  cancelTokenSource?: CancelTokenSource
) => {
  const promise = axios
    .post<CompaniesEnrichmentProposalResponse>(
      '/api/PortfolioEnrichment/companies-enrichment-proposal-query',
      {
        ...requestData,
        orderBy: requestData.orderByFieldId ? 'desc' : undefined
      },
      { cancelToken: cancelTokenSource?.token }
    )
    .then(res => res.data)
  // @ts-ignore
  promise.cancel = () => {
    cancelTokenSource?.cancel('Company popup request cancelled')
  }
  return promise
}

export const createEnrichCompaniesRequest = (requestData: CreateFromCompoundExpressionRequest) => {
  return axios
    .post<EnrichCompaniesResponse>('/api/portfolio/create-from-compound-expression', {
      ...requestData,
      orderBy: requestData.orderByFieldId ? 'desc' : undefined
    })
    .then(res => res.data)
}

export const addEnrichCompaniesRequest = ({
  portfolioId,
  requestData
}: {
  portfolioId: string
  requestData: AddCompaniesByCompoundExpressionRequest
}) =>
  axios
    .post<EnrichCompaniesResponse>(`/api/portfolio/${portfolioId}/add-companies-by-compound-expression`, {
      ...requestData,
      orderBy: requestData.orderByFieldId ? 'desc' : undefined
    })
    .then(res => res.data)

export const getCompaniesByQuery = (query: QueryCompaniesRequest) => {
  return axios.post<QueryCompaniesResponse>('/api/companies/companies-by-query', query).then(res => res.data)
}

export const getCompaniesByQueryFilter = (request: QueryFilterCompaniesRequest) => {
  return axios.post<ProspectingCompaniesRes>('/api/companies/companies-by-query-filter', request).then(res => res.data)
}

export const getCompaniesByFreeText = (request: FreeTextCompaniesRequest) => {
  return axios.post<ProspectingCompaniesRes>('/api/companies/companies-by-free-text', request).then(res => res.data)
}

export const getMapLocationsData = (requestData: QueryLocationsRequest) =>
  axios.post<QueryLocationsResponse>('/api/prospecting/locations-query', requestData).then(res => res.data)

export const getLocalUnitActionsAllowed = (request: QueryAllowLocalUnitsActionRequest) => {
  return axios
    .post<QueryAllowLocalUnitsActionResponse>('/api/prospecting/get-localunitactions-allowed', request)
    .then(res => res)
}

export const getMatchingTarget = (matchingTerm?: string, companyUnitId?: string, countryCode?: string) =>
  axios
    .get<GetMatchingTargetResponse>('/api/companies/target', {
      params: { matchingTerm, companyUnitId, countryCode }
    })
    .then(res => res.data)
