import _ from 'lodash'
import React from 'react'
import { ProductFruits } from 'react-product-fruits'
import { useSelector } from 'react-redux'
import {
  getSelectedLanguage,
  getUserAccountInfo,
  getUserFirstName,
  getUserLastName,
  getVisibilityCountries,
  hasFreemiumRole
} from '../../features/user/selectors'
import { UserType } from '../../authorization'

type Props = {
  productFruitsKey: string
}

enum UserSubType {
  Free = 'Free',
  Full = 'Full'
}

export const ProductFruitsTool = ({ productFruitsKey }: Props) => {
  const accountInfo = useSelector(getUserAccountInfo)
  const userFirstName = useSelector(getUserFirstName)
  const userLastName = useSelector(getUserLastName)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const countries = useSelector(getVisibilityCountries)
  const hasFreemium = useSelector(hasFreemiumRole)
  const userType = hasFreemium ? UserSubType.Free : UserSubType.Full

  if (window.productFruitsIsReady) {
    return null
  }

  return (
    <>
      {!_.isEmpty(accountInfo.userCode) && selectedLanguage && (
        <ProductFruits
          workspaceCode={productFruitsKey}
          language={_.toLower(selectedLanguage?.substring(0, 2))}
          user={{
            username: accountInfo.userCode,
            role: UserType[accountInfo.userType],
            firstname: userFirstName,
            lastname: userLastName,
            props: { countries, userType }
          }}
        />
      )}
    </>
  )
}
