import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useLocation } from 'react-router-dom'
import { TrackingActions } from '../../features/analytics/actions'

export const RouteChangeTracker = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(TrackingActions.trackPageView(pathname))
  }, [dispatch, pathname])

  return <>{children}</>
}
