// eslint-disable-next-line import/no-default-export
export default {
  Title: 'PNRR - National Recovery and Resilience Plan',
  UnavailableForMobile: 'The page you are trying to access is only available for tablets and desktops.',
  Description:
    'With the PNRR module you will visualize and segment a set of companies according to investments previewed and build your target',
  Box: {
    Title: 'General Context',
    Text: 'In December 2019, the President of the European Commission, Ursula von der Leyen, presented the European Green Deal that aims to make Europe the first continent with zero climate impact by 2050.\nThe pandemic, and the ensuing economic crisis, have prompted the EU to formulate a coordinated response at both cyclical level, with the suspension of the Stability Pact and large economic support packages adopted by individual Member States, is structural, in particular with the launch in July 2020 of the New Generation EU (NGEU) programme.\nIn September 2020, the Interministerial Committee for European Affairs (CIAE) approved a proposal for guidelines for the drafting of the PNRR, which was submitted to the Italian Parliament. On 13 and 14 October 2020, the Chambers issued an address inviting the Government to prepare the Plan ensuring a broad involvement of the private sector, local authorities and productive forces in the country.\nIn the following months, an in-depth informal dialogue took place with the European Commission’s Task Force. On 12 January 2021, the Council of Ministers approved a proposal for PNRR on which Parliament carried out a thorough examination, approving its conclusions on 31 March 2021.\nThe Government has rewritten the Plan, also in the light of Parliament’s comments. In April 2021, the plan was discussed with local and regional authorities, political forces and the social partners.'
  },
  SelectTheTarget: 'Select the target PNRR Investment',
  SelectTheTargetTooltip: 'Screening and identification of target companies for specific investments',
  InvestmentsWizard: {
    Title: 'PNRR Analysis',
    SelectAll: 'Select all',
    StepOne: {
      Title: 'Select the portfolio you want to analyze',
      Description:
        'You can process the analysis from one or more of your portfolios or view the aggregated analysis of all the companies in the {{companyName}} system.',
      PortfolioOption: 'Select portfolio',
      AggregateOption: 'All companies in the {{companyName}} System',
      UploadedPortfolio: 'Uploaded portfolio'
    },
    StepTwo: {
      Title: 'Choose the status you want to analyze',
      DescriptionLine1: 'Select the initial status of the companies to be analyzed.',
      DescriptionLine2: 'During the analysis you can change the status using filters.',
      Status: 'Status'
    },
    StepThree: {
      Title: 'Select the mission related to the PNRR Investment',
      DescriptionLine1:
        'The RRF requires Member States to submit an investment and reform package: the National Recovery and Resilience Plan (PNRR). This Plan, which is divided into six Missions and 16 Components, benefits from the close interlocution that has taken place in recent months with Parliament and the European Commission, on the basis of the RRF Regulation.',
      DescriptionLine2:
        'The six Plan Missions are: digitisation, innovation, competitiveness, culture and tourism; green revolution and ecological transition; infrastructure for sustainable mobility; education and research.',
      Mission: 'Mission'
    },
    StepFour: {
      Title: 'Select the component related to the PNRR Investment',
      Description:
        'The Plan is divided into sixteen Components, grouped into six Missions. The latter are articulated in line with the six Pillars mentioned in the RRF Regulation and illustrated in the previous paragraph, although the wording follows a slightly different sequence and aggregation.',
      Component: 'Component'
    },
    StepFive: {
      Title: 'Select the scope related to the PNRR Investment',
      Description:
        'Based on the foregoing, the Justice Reform is articulated in some priority areas of intervention as interventions on the organization: Office of the process and Administration Strengthening, Civil Process Reform and Alternative Dispute Resolution (ADR), Tax Justice Reform, Criminal Process Reform, Reform of the judiciary.',
      Scope: 'Scope'
    },
    StepSix: {
      Title: 'Select the investment related to the PNRR',
      Description:
        'The Italian PNRR provides 151 Investments within the different components of the six missions. The objective of Investments is to boost the productivity of the country and the growth of the Italian economy, to make it more digital, dynamic, sustainable and inclusive.',
      Investment: 'Investment'
    }
  },
  Switch: {
    Pnrr: 'PNRR',
    SubsidizedFinancing: 'Subsidized financing'
  },
  SubsidizedFinancing: {
    Title: 'Subsidized financing',
    Description:
      'With the Subsidized financing functionality you will visualize all the investments and the public tenders your interest company is eligible for. Also, you can upload a portfolio in order to discover all information about a set of companies.',
    CheckForSubsidizedFinancing: 'Check for Subsidized financing',
    LatestDocuments: {
      Title: 'Latest documents',
      DocumentName: 'Document Name',
      PortfolioName: 'Portfolio Name',
      Type: 'Type',
      CreationDate: 'Creation Date',
      Status: 'Status',
      LoadMore: 'Load more',
      OpenDocument: 'Open document',
      Delete: 'Delete',
      DownloadDocument: 'Download document'
    }
  }
}
