import React from 'react'
import styled from 'styled-components/macro'
import { To } from 'react-router-dom'
import { Text, TextProps } from './Text'
import { Link, LinkWithoutStack } from './Link'
import { textSizeProps } from './common'

export const StyledText = styled(Text)`
  /* IE Fix */
  flex-basis: 100%;
`

type Props = {
  to?: To
  state?: any
  stretch?: boolean
  hideIcon?: boolean
  withoutStack?: boolean
} & TextProps

export const LinkText = ({ to, state, stretch, size, hideIcon, onClick, withoutStack, ...textProps }: Props) => {
  const iconSize = textSizeProps(size).size

  const LinkComponent = withoutStack ? LinkWithoutStack : Link
  return (
    <LinkComponent to={to} state={state} stretch={stretch} iconSize={iconSize} hideIcon={hideIcon} onClick={onClick}>
      <StyledText {...textProps} primary size={size} />
    </LinkComponent>
  )
}
