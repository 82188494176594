import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'
import { AutoComplete as AntAutoComplete } from 'antd'
import SearchIcon from '../../assets/icons/search.svg?react'
import CloseIcon from '../../assets/icons/close.svg?react'
import ExpandIcon from '../../assets/icons/icon-s-player-fullscreen-2.svg?react'
import CollapseIcon from '../../assets/icons/icon-s-player-smallscreen-1.svg?react'
import { IconButton } from '../Button'
import { BasicTooltip } from '../Tooltip'
import { IconPosition, SearchType, TextAreaVerticalExpansion } from './types'

export { SearchIcon, CloseIcon, ExpandIcon, CollapseIcon }

export type TextboxProps = { open: boolean; iconPosition: IconPosition }

const ExpandableHeightMixin = css<{ expansion?: TextAreaVerticalExpansion }>`
  height: ${({ expansion }) => {
    switch (expansion) {
      case TextAreaVerticalExpansion.OneLine:
        return '40px'
      case TextAreaVerticalExpansion.TwoLines:
        return '85px'
      case TextAreaVerticalExpansion.Full:
        return 'auto'
      default:
        return 'initial'
    }
  }};
  &.is-checking-height {
    transition: none;
  }
`

const textboxMixin = css<TextboxProps>`
  position: relative;
  right: ${({ open }) => (open ? 0 : '-100%')};
  flex: 1 1 auto;
  padding: 8px 0 8px 16px;
  font-size: 14px;
  line-height: 1.71;
  border: none;
  border-radius: ${({ iconPosition }) => (iconPosition === IconPosition.Right ? '4px 0 0 4px' : '0 4px 4px 0')};
  color: inherit;
  transition: all ${({ theme }) => theme.animation.duration};

  &&&:focus {
    outline: none;
  }

  &&&::placeholder {
    text-overflow: ellipsis;
    opacity: 0.8;
  }
`

const ICON_SIZE = 40

export const StyledAutoComplete = styled(AntAutoComplete)`
  position: absolute;
  left: 0px;
  width: 100%;
  .ant-select-selector.ant-select-selector {
    font-size: 14px;
    line-height: 1.71;
    background-color: #1a508a;
    border-color: #1a508a !important;
    border-radius: 0px;
    .ant-select-selection-placeholder.ant-select-selection-placeholder {
      color: ${({ theme }) => theme.colors.white};
      margin-left: ${ICON_SIZE}px;
    }
    .ant-select-selection-search-input.ant-select-selection-search-input {
      color: ${({ theme }) => theme.colors.white};
      border: 0;
      :hover {
        border: 0 !important;
      }
    }
    .ant-select-selection-search {
      margin-left: ${ICON_SIZE}px;
    }
  }
`

export const SearchInput = styled.input`
  ${textboxMixin}
`

export const SearchTextAreaStyled = styled.textarea`
  ${textboxMixin}
  height: auto;
  resize: none;
`

export const SearchButton = styled.div<{ open: boolean; position: IconPosition }>`
  cursor: pointer;
  position: ${({ open }) => (open ? 'relative' : 'absolute')};
  right: 0;
  display: block;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  padding: 8px;
  border-radius: ${({ position }) => (position === IconPosition.Right ? '0 4px 4px 0' : '4px 0 0 4px')};
  z-index: 1;
  transition: all ${({ theme }) => theme.animation.duration};

  :hover {
    fill: ${({ theme }) => theme.colors.pumpkinOrange};
  }
`

export const ExpandButton = styled(IconButton)<{ position: IconPosition }>`
  position: absolute;
  ${({ position }) => (position === IconPosition.Left ? 'left: 0' : 'right: 0')};
  top: 40px;
  &&&:active {
    background-color: transparent;
  }

  :hover {
    fill: ${({ theme }) => theme.colors.pumpkinOrange};
  }
`

export const SearchWrapper = styled.div<{ type: SearchType; open: boolean }>`
  display: flex;
  overflow: hidden;
  position: relative;
  height: 40px;
  width: 100%;

  @media only screen and (max-width: 320px) {
    display: flex;
  }

  ${({ theme, type }) => {
    switch (type) {
      case 'primary':
        return `color: ${theme.colors.white}; fill: ${theme.colors.white};`
      case 'secondary':
        return `color: ${theme.colors.main}; fill: ${theme.colors.main};`
      default:
        return ''
    }
  }}

  ${SearchInput},
  ${SearchTextAreaStyled} {
    background-color: ${({ theme, type }) => {
      switch (type) {
        case 'primary':
          return '#1a508a'
        case 'secondary':
          return theme.colors.paleGrey
        default:
          return 'initial'
      }
    }};
  }

  ${SearchInput}::placeholder,
  ${SearchTextAreaStyled}::placeholder {
    color: ${({ theme, type }) => {
      switch (type) {
        case 'primary':
          return theme.colors.white
        case 'secondary':
          return theme.colors.brownGray
        default:
          return 'initial'
      }
    }};
  }

  ${SearchButton} {
    background-color: ${({ theme, type, open }) => {
      switch (type) {
        case 'primary':
          return open ? '#1a508a' : theme.colors.main
        case 'secondary':
          return theme.colors.paleGrey
        default:
          return 'initial'
      }
    }};
  }
`

export const SearchTextareaWrapper = styled(SearchWrapper)`
  ${ExpandableHeightMixin}
  flex-grow: 1;
  background-color: ${({ theme, type }) => {
    switch (type) {
      case 'primary':
        return '#1a508a'
      case 'secondary':
        return theme.colors.paleGrey
      default:
        return 'initial'
    }
  }};
`

export const ErrorLabel = styled.div`
  color: #f5222d;
  margin-bottom: -10px;
`

export const SearchArea = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  flex-grow: 1;
`

type SearchInputIconProps = {
  disabled: boolean
  onlySearchIcon: boolean
  isSearching: boolean
  isOpen: boolean
  tooltipSearchText: string | undefined
  position: IconPosition
  onSearch: () => void
  onCancel: () => void
}

export const SearchInputIcon: React.FC<SearchInputIconProps> = ({
  disabled,
  onlySearchIcon,
  isSearching,
  isOpen,
  tooltipSearchText,
  position,
  onSearch,
  onCancel
}) => {
  const { t } = useTranslation('common')

  if (disabled) return null
  if (!onlySearchIcon && isSearching) {
    return (
      <BasicTooltip placement="top" title={t('Cancel')}>
        <SearchButton onClick={onCancel} position={position} open={isOpen} role="button" aria-label="search">
          <CloseIcon />
        </SearchButton>
      </BasicTooltip>
    )
  }
  return (
    <BasicTooltip placement="top" title={tooltipSearchText ?? t('Search')}>
      <SearchButton onClick={onSearch} position={position} open={isOpen} role="button" aria-label="search">
        <SearchIcon />
      </SearchButton>
    </BasicTooltip>
  )
}
