import { TFunction } from 'i18next'
import { MAX_QUERY_NAME_LENGTH } from '../features/query/constants'
import { cannotStartWithSpace, noSpecialCharactersSubset, requiredWithoutFieldName } from './validators'

export const checkQueryName = (t: TFunction, name: string) => {
  if (requiredWithoutFieldName(name)) {
    return t('error:RequiredWithoutFieldName')
  }

  if (name.length > MAX_QUERY_NAME_LENGTH) {
    return t('error:LengthLong', { max: MAX_QUERY_NAME_LENGTH })
  }

  if (cannotStartWithSpace(name)) {
    return t('error:CannotStartWithSpace')
  }

  if (noSpecialCharactersSubset(name)) {
    return t('error:NoSpecialCharactersWoLabel')
  }

  return undefined
}
