import { useState, useEffect } from 'react'

export const useEllipsis = (isLoading: boolean) => {
  const [ellipsis, setEllipsis] = useState('')

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setEllipsis(prev => (prev.length < 3 ? `${prev}.` : ''))
      }, 500)

      return () => clearInterval(interval)
    }
  }, [isLoading])

  return ellipsis
}
