import _ from 'lodash'
import axios from 'axios'
import { useQuery, useInfiniteQuery, UseInfiniteQueryOptions, keepPreviousData } from '@tanstack/react-query'
import { CompoundExpression } from '@api/expressionTypes'
import { WithKeepPreviousData } from '@api/types'
import { convertCompoundExpressionForBackend } from '../../features/filters/utils'
import { mapOrderByToRequestOptions } from '../../features/mappers'
import { OrderByOption } from '../../features/filters/types'
import {
  CompaniesEnrichmentProposalRequest,
  CompaniesEnrichmentProposalResponse,
  QueryCompaniesResponse
} from '../companies/types'
import { getCompaniesByQuery, getEnrichmentProposalQuery } from '../companies'
import { CompanyDetailsType } from '../../types'

// TODO: generalize for all modules
export const getOperationsKeys = {
  All: () => [{ level1: 'operations' }] as const,

  EnrichmentProposal: () => [{ ...getOperationsKeys.All()[0], level2: 'enrichmentProposal' }] as const,
  EnrichmentProposalWithRequest: (request: CompaniesEnrichmentProposalRequest) =>
    [{ ...getOperationsKeys.EnrichmentProposal()[0], request }] as const,

  OperationsCompanies: () => [{ ...getOperationsKeys.All()[0], level2: 'getOperationsCompaniesByQuery' }] as const,
  OperationsCompaniesByQuery: (size: number, query?: CompoundExpression, orderBy?: OrderByOption) =>
    [{ ...getOperationsKeys.OperationsCompanies()[0], size, query, orderBy }] as const
}

export const useEnrichmentProposalQuery = <T>(
  request: CompaniesEnrichmentProposalRequest,
  select?: (data: CompaniesEnrichmentProposalResponse) => T
) => {
  const cancelSource = axios.CancelToken.source()
  return useQuery({
    // Disabled because cancelSource
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getOperationsKeys.EnrichmentProposalWithRequest(request),
    queryFn: ({ queryKey: [queryKeys] }) => getEnrichmentProposalQuery(queryKeys.request, cancelSource),
    select
  })
}

export const useGetOperationsCompanies = (
  size: number,
  query?: CompoundExpression,
  orderBy?: OrderByOption,
  queryOptions: Partial<UseInfiniteQueryOptions<QueryCompaniesResponse>> & WithKeepPreviousData = {}
) => {
  const convertedExpression = query ? convertCompoundExpressionForBackend(query) : query

  return useInfiniteQuery({
    queryKey: getOperationsKeys.OperationsCompaniesByQuery(size, convertedExpression, orderBy),
    queryFn: ({ queryKey: [queryKeys], pageParam }) => {
      if (!queryKeys.query) return Promise.reject()

      return getCompaniesByQuery({
        query: queryKeys.query,
        pagination: { position: pageParam, size: queryKeys.size },
        sortBy: queryKeys.orderBy ? mapOrderByToRequestOptions([queryKeys.orderBy]) : undefined,
        moduleName: 'Operations',
        pageType: CompanyDetailsType.FullDetails
      })
    },
    initialPageParam: 0,
    getNextPageParam: (lastResp, allPages) => {
      const count = _.sum(_.map(allPages, 'data.items.length'))
      return lastResp?.data?.total <= count ? undefined : allPages.length
    },
    enabled: queryOptions.enabled ?? true,
    placeholderData: queryOptions.keepPreviousData ? keepPreviousData : undefined
  })
}
