import { Key } from 'antd/es/table/interface'
import { CompoundExpression } from '@api/expressionTypes'
import { SemanticSearch } from '../../features/filters/types'
import { QueryType, SortItem } from '../../types'
import { QueryOrderByOption } from '../filters/types'

export type GetQueriesParams = {
  top?: number
  skip?: number
  sort?: [SortItem]
}

export type QueryResponseItem = {
  id: string
  userId: string
  type: QueryType
  name: string
  createdAt: string
  lastUseDate: string
  isMonitored: boolean
  associatedEntityId?: string
  sharedBy?: string
}

export type GetQueriesResponse = {
  items: QueryResponseItem[]
  totalCount: number
}

export type GetQueriesRequest = {
  documentTypes?: QueryType[]
  sortProperty?: Key | readonly Key[]
  sortOrder?: string
  skip?: number
  top?: number
}

export type UpdateFilterQueryRequest = {
  query: CompoundExpression
  selectedPortfoliosIds?: string[]
  excludedPortfoliosIds?: string[]
  semanticSearch?: SemanticSearch
  orderBy?: QueryOrderByOption[]
  visibleFilters: string[]
}

export type CreateFilterQueryRequest = {
  addToDashboard: boolean
  type: QueryType
  associatedEntityId?: string
  queryName: string
} & UpdateFilterQueryRequest

export enum SubmitMethod {
  None,
  CreateNew,
  Update
}

export type ShareFilterQueryConfirmation = {
  name: string
  type: QueryType
  grantorUserId: string
}

export enum ShareFilterQueryStatus {
  SharingAccepted = 'SharingAccepted',
  AlreadyAccepted = 'AlreadyAccepted'
}

export type ShareFilterQueryConfirmationResponse = {
  status: ShareFilterQueryStatus
}
