/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  SalesTool: {
    Header: 'Outil de vente',
    Management: {
      Assignee: 'Cessionnaire',
      AssigneeSgCzsk: 'Cessionnaire',
      Campaigns: 'Campagnes',
      SearchCampaign: 'Rechercher une campagne',
      SearchCampaignLong: 'Rechercher nom de campagne',
      NewCampaign: 'Créer campagne',
      NewCampaignTooltip: "Planifier une campagne commerciale en partant d'un portefeuille",
      CampaignArchive: 'Archive de campagne',
      CampaignArchiveTooltip: "Accéder à l'archive pour terminer les campagnes et les renouveler",
      CampaignsToAssign: 'À affecter',
      CampaignsToStart: 'Pas encore démarré',
      CampaignsActive: 'Démarré',
      CampaignsArchived: 'Archivé',
      Stats: {
        Companies: 'Société',
        Companies_plural: 'Sociétés',
        StartDate: 'Date de début',
        EndDate: 'Date de fin',
        EnrichedCompanies: 'Sociétés enrichies',
        Clients: 'Clients',
        Suppliers: 'Fournisseurs',
        Prospects: 'Prospects',
        LastUpdate: 'Dernière actualisation',
        Competitors: 'Concurrents',
        Lead: 'Principal'
      },
      Table: {
        NumberOfCompanies: 'Nombre de sociétés',
        GoalName: 'Nom objectif',
        Creator: 'Créateur',
        Tasks: 'Tâche',
        Status: 'Statut',
        CampaignPortfolios: 'Portefeuilles de cette campagne'
      },
      PorfolioBox: 'Portefeuille de cette campagne',
      ConversionRate: '{{percentage}} % de taux de conversion',
      CompletedTasks: '{{percentage}} % de tâches terminées',
      Banner: {
        Title: 'Cliquer sur le nom de la campagne pour affecter la liste',
        Subtitle: 'Avant de démarrer la campagne, affecter les Commerciaux et Produits à chaque société de la liste.'
      },
      CreatedAtAscending: 'Date de création (Ascendante)',
      CreatedAtDescending: 'Date de création (Descendante)',
      StartDateAscending: 'Date de début (Ascendante)',
      StartDateDescending: 'Date de début (Descendante)',
      EndDateAscending: 'Date de fin (Ascendante)',
      EndDateDescending: 'Date de fin (Descendante)'
    },
    AddCampaign: {
      EnterName: 'Saisir un nom représentatif de la campagne',
      EnterNameDescription: 'Un appel est utilisé pour la campagne créée',
      EnterDates: 'Sélectionner la date de début et la date de fin',
      EnterDatesDescription:
        "Les dates de début et de fin sont toujours affichées associées à la campagne ; cela vous aide, vous même et les commerciaux à atteindre les objectifs d'activité",
      withCustomVariables: 'avec des variables personnalisables',
      withoutCustomVariables: 'sans variables personnalisables',
      EnterPortfolio: 'Choisir le portefeuille à associer à la campagne',
      EnterPortfolioDescription: "Associer une ou plusieurs portefeuilles à la campagne qui vient d'être créée.",
      EnterGoal: 'Choisir un objectif pour la campagne',
      EnterGoalDescription: 'Sélectionner un objectif, affecté à des tâches définies.',
      CreateGoalDescription: 'Cliquez ici pour créer un nouvel <0>Objectif</0>.',
      InvalidGoal:
        "L'objectif sélectionné ne comporte aucune tâche. Veuillez sélectionner un autre objectif ou cliquez ici pour créer un <0>Objectif</0>",
      EnterConversionRate: 'Saisir une description et définir un taux de conversion',
      EnterConversionRateDescription:
        "Saisir une description de la campagne en cours de création, elle fournit davantage d'informations sur l'objectif à atteindre.",
      CampaignCreationPendingTitle: 'Création de campagne',
      CampaignCreationPendingText:
        "Votre campagne a été créée. Vous serez dirigez vers l'Outil de vente et recevrez une notification une fois l'opération terminée.",
      SalesPerformanceChartTitle:
        'Voulez-vous afficher le graphique « Performances des commerciaux » pour les utilisateurs Commerciaux ?',
      SalesPerformanceChartText: 'Activer le graphique',
      RestoreDataQuestion: 'Voulez-vous restaurer toutes les données associées à cette campagne avant son expiration ?',
      RestoreDataEnable: 'Activer toutes les données précédentes',
      RestoreDataEnableDisabledTooltip:
        "L'objectif initial de cette campagne ne peut pas être sélectionné car il a été effacé. Sélectionnez-en un nouveau parmi les objectifs disponibles",
      AssignedInformation:
        'Notez que dans le cas où le responsable et le vendeur sont affectés à la même entreprise, dans la colonne responsable de la campagne sera indiqué le nom du vendeur.'
    }
  },
  ManageCampaign: {
    Header: 'Gérer les commerciaux et produits',
    SalesHeader: 'Gérer campagne'
  },
  Management: {
    Message: {
      Header: "Créer une campagne, l'affecter à vos commerciaux et la démarrer",
      SubHeader: 'Pour créer une campagne, utiliser le bouton « Créer campagne ».',
      Description:
        "Une fois la campagne créée, il est possible d'affecter des sociétés aux commerciaux puis de la démarrer. Toutes vos campagnes figurent sur cette page."
    },
    Campaign: {
      ChangeCampaignName: 'Changer le nom de la campagne',
      ChangeCampaignNameShort: 'Changer nom',
      ChangeCampaignNote: 'Changer note de campagne',
      AddCampaignNote: 'Ajouter note',
      ChangeCampaignNoteDescription: 'Changer la note pour décrire le taux de conversion.',
      AddCampaignNoteDescription: 'Saisir une note pour décrire le taux de conversion.',
      EnterARepresentativeName: 'Saisir un nom représentatif de la campagne',
      InsertCampaignName: 'Saisir un nom de campagne',
      InsertCampaignNote: 'Saisir une note',
      NameChangedCorrectly: 'Nom changé correctement en',
      NoteChangedCorrectly: 'Note changée correctement',
      NoteRemovedCorrectly: 'Note retirée correctement',
      NoteAddedCorrectly: 'Note ajoutée correctement',
      ChangeEndDate: 'Changer date de fin',
      ChangeEndDateDescription: 'Changer la date de fin courante.',
      ChangeEndDateSuccess: 'termine le',
      ChangeStartDate: 'Changer date de début',
      ChangeStartDateDescription: 'Changer la date de début courante.',
      ChangeStartDateSuccess: 'démarre le',
      StartCampaignDialogText1: 'Voulez-vous démarrer cette campagne ?',
      StartCampaignDialogText2:
        "L'avancement de l'ensemble de la campagne est visible dans le tableau de bord de gestion Outil de vente.",
      StartCampaignDialogText3:
        "En cliquant clic sur « Continuer », chaque commercial reçoit une notification à propos de l'« Affectation de société ».",
      StartCampaignDoneDialogText1: '{{campaignName}} démarre le {{startDate}}.',
      StartCampaignDoneDialogText2:
        "Chaque commercial reçoit une notification à propos de l'« Affectation de société »",
      StopCampaignDialogText1: 'Voulez-vous arrêter cette campagne ?',
      StopCampaignDialogText2:
        'Elle sera déplacée dans la section « Archive de campagne » et peut être renouvelée si souhaité.',
      StopCampaignDialogText3: "Pour voir l'« Archive de campagne », revenir au tableau de bord.",
      StopCampaignDoneDialogText1:
        '{{campaignName}} est interrompue correctement, elle est disponible dans la section « Archive de campagne ».',
      DeleteCampaignDialogText1: 'Voulez-vous vraiment supprimer {{campaignName}} ?',
      DeleteCampaignDialogText2: 'Tous les résultats de la campagne seront supprimés.',
      DeleteCampaignDoneDialogText1: '{{campaignName}} supprimé correctement.'
    }
  },
  AssignCampaign: {
    Header: 'Affecter des commerciaux et un produit',
    CreatedBy: 'Créé par {{ownerName}}',
    StartDate: 'Date de début :',
    EndDate: 'Date de fin :',
    AddPortfolio: 'Ajouter sociétés/portefeuille',
    AddPortfolioTooltip: 'Ajouter de nouvelles sociétés à cette campagne',
    SelectColumns: 'Sélectionner des colonnes',
    GroupActions: 'Actions de groupe',
    SalestoolAssignmentsSelectPlaceholder: 'Sélectionner',
    AssignSales: 'Affecter des commerciaux',
    AssignProduct: 'Affecter produit',
    NotAssigned: 'Non affecté',
    Sales: 'Commercial',
    Product: 'Produit',
    Note: 'Notes',
    EditNoteIconTitle: 'Modifier note',
    CompaniesCount: 'Société',
    CompaniesCount_plural: 'Sociétés',
    AssignedCount: 'Affecté',
    NotAssignedCount: 'Non affecté',
    AppAround: {
      NotAlreadyDone: "Aucune offre n'a encore été envoyée",
      InProgress: 'Une offre a déjà été envoyée le {{dateTime}}',
      Ko: "Erreur d'offre le {{dateTime}}",
      Ok: 'Offre envoyée le {{dateTime}}'
    },
    Inventia: {
      NotAlreadyDone: "Aucun appel n'a démarré",
      InProgress: "L'appel a été démarré le {{dateTime}}",
      Ko: "Erreur d'appel, le {{dateTime}}",
      Ok: 'Appel passé le {{dateTime}}'
    },
    Banner: {
      Title: 'Cliquer sur « Affecter campagne » pour affecter des commerciaux et produits',
      Subtitle:
        "Avant de démarrer la campagne, affecter les Commerciaux et Produits à chacune des sociétés de la liste. À la fin, cliquer sur « Enregistrer » pour terminer l'opération, la campagne ne démarre pas avant d'avoir cliqué sur « Démarrer campagne »."
    },
    WriteYourNote: 'Écrire vos notes ici',
    Dialog: {
      SelectAtLeastOneCompany: 'Sélectionner au moins une société.',
      AssignSaleTitle: 'Affecter commercial',
      AssignProductTitle: 'Affecter produit',
      AssignSaleOrProductMultiple: '{{item}} affecté à {{count}} société.',
      AssignSaleOrProductMultiple_plural: '{{item}} affecté à {{count}} sociétés.',
      DuplicateCompanyTitle: 'Dupliquer une société',
      DuplicateCompanyConfirm: 'Voulez-vous vraiment dupliquer {{companyName}} ?',
      DuplicateCompanySelection: 'Pour dupliquer une société, il est nécessaire de modifier son statut ou son produit.',
      DuplicateCompanyDone: '{{companyName}} dupliqué correctement dans {{campaignName}}.',
      DuplicateCompanySelectProduct: 'Sélectionner produit',
      DuplicateCompanySelectStatus: 'Sélectionner statut',
      DuplicateCompanyProduct: 'Produit',
      DuplicateCompanyStatus: 'Statut',
      SaveCampaignTitle: 'Enregistrer campagne',
      SaveCampaignPending:
        "L'opération enregistrée est en cours et peut prendre quelques instants. Une notification est envoyée au terme de l'opération.",
      AddCompaniesTitle: 'Ajout de sociétés en cours',
      AddPortfolioTitle: 'Ajouter sociétés/portefeuille',
      AddPortfolio: 'Ajouter portefeuille',
      SelectThePortfolioYouWantToAdd: 'Sélectionner le portefeuille à ajouter',
      AddNewCompaniesFromStartingPortfolio: 'Ajouter de nouvelles sociétés à partir du portefeuille de départ',
      AddedCompanies: 'Sociétés ajoutées',
      AddedPortfolio: 'Portefeuille ajouté',
      AddPortfolioSelect: 'Sélectionner',
      AddCompaniesPending:
        "Ajout de sociétés à {{campaignName}} en cours. Certaines Variables personnalisables ne sont pas intégrées en raison d'incohérences de la structure des variables. Au terme de l'opération, une notification est envoyée.",
      AddCompaniesWithCustomVariablesStructureChangedPending:
        "Ajout d'entreprises à {{campaignName}} en cours. Certaines variables personnalisées ne seront pas insérées en raison de l'inadéquation de la structure des variables. A la fin de l'opération, vous recevrez une notification.",
      AddPortfolioPending:
        "{{selectedPortfolioName}} est ajouter à {{campaignName}}. Vous serez notifié au terme de l'opération.",
      AddCompanyNoteTitle: 'Notes',
      AddCompanyNote: "Il est possible d'ajouter jusqu'à cinq notes à cette société",
      AddCompanyNoteTextAreaLabel: 'Insérer une nouvelle note (250 caractères maxi)',
      AddCompanyNoteTextAreaPlaceholder: 'Votre notes...',
      DeleteNoteConfirmation: 'Voulez-vous vraiment supprimer cette note ?',
      DeleteCompany: {
        Title: "Supprimer l'entreprise",
        Text: "Voulez-vous vraiment supprimer l'entreprise de la campagne ?",
        Success: 'La société a été supprimée avec succès de la campagne.',
        Error: "Une erreur s'est produite lors de la suppression de la société de la campagne."
      },
      MoveCompany: {
        Title: "Déplacer l'entreprise vers une autre campagne",
        Text: "L'entreprise que vous avez sélectionnée sera supprimée de la campagne en cours et ajoutée à une autre campagne. Veuillez sélectionner une campagne dans la liste ci-dessous.",
        SelectLabel: 'Sélectionner une campagne',
        Footer: "Les ventes et le produit attribués à l'entreprise seront supprimés",
        Success: "L'entreprise a été déplacée avec succès vers la campagne.",
        Error: "Une erreur s'est produite lors du déplacement de l'entreprise vers la campagne.",
        ErrorIncompatibleDestEntity:
          "La structure des variables personnalisées de cette campagne n'est pas compatible avec celle d'origine."
      }
    },
    Errors: {
      DuplicateCampaign: 'Une erreur s’est produite durant la duplication de la société.',
      DuplicateCampaignMissingPortfolio:
        "Il n'est pas possible de dupliquer la société car le portefeuille à laquelle elle appartient n'existe plus.",
      AddPortfolio: "Une erreur s’est produite durant l'ajout du portefeuille sélectionné.",
      AddCompanies:
        "Une erreur s’est produite durant l'ajout de nouvelles sociétés depuis le portefeuille de démarrage."
    }
  },
  SalesPerformance: {
    Banner: {
      Title: 'Les performances de vos commerciaux',
      Subtitle: 'pour chaque tâche sont visibles dans cette case'
    },
    Tooltip: 'Cette case affiche les 5 meilleurs Cessionnaires de la tâche sélectionnée',
    Actions: 'Actions',
    TotalProgressOrder: 'Progression totale',
    LastUpdateOrder: 'Dernière actualisation',
    NumberCompaniesOrder: ' Nombre de sociétés affectées'
  },
  CampaignDetail: {
    MapBoxTitle: 'Répartition géographique',
    CompaniesBoxTitle: 'Sociétés sur tâche',
    AssigneeBoxTitle: 'Performances des cessionnaire',
    NoteLabel: 'Note',
    Task: 'Tâche',
    TasksStatisticsLabel: 'société',
    TasksStatisticsLabel_plural: 'sociétés',
    AssignTask: 'Affecter tâche',
    SalesPerformanceNCompanies: 'N° de sociétés',
    WaterfallChartTasksCompleted: 'terminé',
    WaterfallChartBoxTitle: '{{count}} société {{percentage}} % terminé',
    WaterfallChartBoxTitle_plural: '{{count}} sociétés {{percentage}} % terminé',
    AllCompaniesLabel: 'Tous',
    AssignedCompaniesLabel: 'Affecté',
    UnassignedCompaniesLabel: 'Non affecté',
    Dialog: {
      AssignTaskTitle: 'Affecter tâche',
      CampaignReceived: {
        Title: 'Campagne reçue',
        Text: 'Veuillez noter que vous avez reçu cette campagne de {{ownerName}}.',
        SubText: 'Vous ne pourrez modifier les tâches que pour les entreprises qui vous ont été attribuées.'
      }
    },
    ShowAssigneeDetail: 'Afficher détails Cessionnaires'
  },
  CompaniesOnTask: {
    Void: 'Néant',
    Called: 'Appelé',
    Visited: 'Visité',
    Negotiation: 'Négociation',
    Active: 'Actif',
    Negative: 'Négatif'
  },
  CompanyDetail: {
    Actions: {
      DownloadPDF: 'Télécharger PDF',
      AddToCampaign: 'Ajouter à campagne'
    }
  },
  CampaignArchive: {
    Subtitle:
      'Contient des campagnes interrompues et terminées. Cliquer sur « Renouveler campagne » pour redémarrer la campagne en maintenant la même affectation ',
    SubtitleSale: 'Contient des campagnes interrompues et terminées.',
    RenewCampaign: 'Renouveler campagne',
    DeleteCampaign: 'Supprimer'
  },
  EnrichBeforeRenew: 'Avant de démarrer la campagne, enrichir les portefeuilles suivants :',
  SaleDetail: {
    Area: 'Zone',
    CampaignsView: 'Vue campagnes',
    Summary: 'Synthèse',
    TotalCompanies: 'Sociétés totales',
    TotalCampaigns: 'Campagnes totales',
    ActiveCampaigns: 'Campagnes actives',
    ArchivedCampaigns: 'Campagnes archivées',
    CompletedCampaigns: 'Campagnes terminées',
    AssignTaskDialogText1: 'Voulez-vous changer la tâche ?',
    AssignTaskDialogText1_noPrevTask: 'Voulez-vous affecter la tâche ?',
    AssignTaskDialogText2: 'La tâche est-elle modifiée de {{originalTask}} à {{newTask}}.',
    AssignTaskDialogText2_noPrevTask: 'Vous affectez {{newTask}}.',
    AssignTaskDoneDialogText1: 'La tâche a été modifiée correctement !',
    AssignTaskDoneDialogText1_noPrevTask: 'La tâche a été affectée correctement !',
    AssignTaskDoneDialogText2: 'Votre responsable recevra une notification.',
    AssignTaskMultipleDoneDialogText1: "Les tâches sélectionnées sont en cours d'affectation.",
    AssignTaskMultipleDoneDialogText2: 'Une fois cela fait, votre responsable recevra une notification.',
    AssignTasksDialogText: 'Vous affectez {{newTask}} à {{fCount}} société.',
    AssignTasksDialogText_plural: 'Vous affectez {{newTask}} à {{fCount}} sociétés.',
    AssignTasksNotOwnerInfo: 'Seules les entreprises qui vous ont été assignées seront modifiées.'
  },
  ItemNotAssignedYet: 'Non encore affecté',
  LoadMore: 'Charger plus',
  DownloadBtn: 'Télécharger PDF/Excel',
  DownloadBtnTooltip: 'Télécharger un rapport de cette campagne sur votre dispositif',
  AssignCampaignBtn: 'Affecter campagne',
  AssignCampaignBtnTooltip: 'Affecter des commerciaux et produits aux sociétés de cette campagne',
  ManageCampaignBtn: 'Gérer campagne',
  ManageCampaignBtnTooltip: 'Affecter des commerciaux et produits aux sociétés de cette campagne',
  StartCampaignBtn: 'Démarrer campagne',
  StartCampaignBtnTooltip: 'Habiliter la visibilité de la campagne aux commerciaux et surveiller les performances',
  StopCampaignBtn: 'Arrêter campagne',
  StopCampaignBtnTooltip: "Interrompre cette campagne et l'archiver",
  DeleteCampaign: 'Supprimer campagne',
  CampaignName: 'Nom campagne',
  StartDate: 'Date de début',
  EndDate: 'Date de fin',
  Name: 'Nom',
  PortfolioLabel: 'Portefeuille',
  GoalLabel: 'Choisir objectif',
  ConversionRateLabel: 'Définir pourcentage de taux de conversion',
  Close: 'Fermer',
  Save: 'Enregistrer',
  SaveTooltip: 'Enregistrer les modifications apportées à cette campagne',
  CreateCampaign: 'Créer campagne',
  RenewCampaign: 'Renouveler campagne',
  DomainFilter: {
    SaveAndClose: 'Enregistrer et fermer'
  },
  Table: {
    Empty: "Nous n'avons pas trouvé de sociétés correspondant à votre recherche"
  },
  Errors: {
    CampaignSave: 'Une erreur est survenue lors de la création de la campagne.'
  },
  GenericErrorTitle: "Une erreur s'est produite",
  Download: {
    PDFHeaderTitle: 'Rapport de campagne',
    DownloadTitle: 'Télécharger PDF/Excel',
    DownloadPDF: 'Télécharger PDF',
    DownloadExcel: 'Télécharger Excel',
    SelectTypeOfList: 'Télécharger PDF/Excel',
    SelectTypeOfListDescription:
      'Télécharger le rapport complet sur la campagne en deux formats différents, sélectionner le type de liste à télécharger.',
    CompaniesInCampaign: 'Sociétés dans cette campagne',
    AssignedCompanies: 'Sociétés affectées',
    ActiveCompanies: 'Sociétés devenues « Actives »',
    Pdf: 'PDF',
    PdfDescription: 'Télécharger au format .pdf',
    Excel: 'Excel',
    ExcelDescription: 'Télécharger au format .XLS',
    DownloadExcelTitle:
      'Un fichier contenant toutes les données de {{companyName}} et le résultat de cette campagne sont téléchargés sous forme de liste.',
    DownloadExcelDescription:
      "Une notification est envoyée dès la création du fichier Excel afin de pouvoir le télécharger et de l'enregistrer.",
    DownloadPDFPleaseWait: 'Attendre la génération du fichier PDF',
    DownloadExcelPendingMessage:
      "Le fichier requis est en cours de traitement et peut prendre plusieurs minutes.\nIl est possible de suivre l'avancement depuis le menu des notifications."
  },
  Actions: {
    GoToCampaign: 'Aller à campagne'
  },
  CampaignStatus: {
    ToAssign: 'À affecter',
    ToStart: 'Pas encore démarré',
    Active: 'Démarré',
    Stopped: 'Arrêté',
    Expired: 'Expiré'
  },
  EmptyCampaigns: 'Aucune campagne trouvée'
}
