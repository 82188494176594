import axios from 'axios'
import { UrlConfig } from '../types'
import { LoginAsRequest, LoginRequest, LoginResponse } from './types'

export const LOGIN_REQUEST: UrlConfig = {
  url: '/api/session/login',
  method: 'post'
}

export const logout = () => axios.post('/api/session/logout')

export const getTokenBasedOnIdToken = (idToken: string) => {
  return axios.post<LoginResponse>('api/sso/saml-sp-initiated', { idToken }).then(resp => resp.data)
}

export const login = (request: LoginRequest) => axios.post<LoginResponse>('/api/session/login', request)

export const loginAs = (request: LoginAsRequest) => axios.post<LoginResponse>('/api/session/login-as', request)
