import { AggregationField, TargetingCompanyStatusFilter } from '../../api/targeting/types'
import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'
import { DownloadType, TargetingFilter, TargetingFilterId } from './types'

export const FETCH_PORTFOLIO_CONFIG = 'targeting/FETCH_PORTFOLIO_CONFIG'

export const SET_ACTIVE_FILTERS = 'targeting/SET_ACTIVE_FILTERS'
export const UNSET_ACTIVE_FILTER = 'targeting/UNSET_ACTIVE_FILTER'
export const SET_AGGREGATION_FIELD = 'targeting/SET_AGGREGATION_FIELD'

export const SET_LOADED_QUERY = 'targeting/SET_LOADED_QUERY'
export const SET_DOWNLOAD = 'targeting/SET_DOWNLOAD'

export const SET_TARGETING_TABULAR_FILTER = 'targeting/SET_TARGETING_TABULAR_FILTER'

export const TargetingActions = {
  fetchPortfolioConfig: () => createAction(FETCH_PORTFOLIO_CONFIG), // TEMP: to prevent reducer error
  setLoadedQuery: (params: { queryId: string; queryName: string; hasValidPortfolio: boolean } | undefined) =>
    createAction(SET_LOADED_QUERY, params),
  setActiveFilters: (filter: TargetingFilter) => createAction(SET_ACTIVE_FILTERS, filter),
  unsetActiveFilter: (filterName: TargetingFilterId) => createAction(UNSET_ACTIVE_FILTER, filterName),
  setAggregationField: (field: AggregationField) => createAction(SET_AGGREGATION_FIELD, field),
  setDownload: (type: DownloadType) => createAction(SET_DOWNLOAD, type),
  setTargetingCompanyStatusFilter: (filter = TargetingCompanyStatusFilter.totalCompanies) =>
    createAction(SET_TARGETING_TABULAR_FILTER, { filter })
}

export type TargetingActions = ActionsUnion<typeof TargetingActions>
