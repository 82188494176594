import React from 'react'
import styled from 'styled-components/macro'
import { textMixin, TextMixinProps, Offset, TitleLevel, titleLevelProps } from './common'
import { useViewportSize } from '../../hooks/useViewportSize'

const Style = styled.div<TextMixinProps>`
  ${textMixin}
  font-family: 'Comfortaa', cursive;
  color: ${props => props.theme.colors.main};
`

export type TitleProps = {
  className?: string
  style?: React.CSSProperties
  level?: TitleLevel
  center?: boolean
  ellipsis?: boolean
  margin?: Offset
  padding?: Offset
  text?: string
  children?: React.ReactNode
  onClick?: () => void
}

const defaultProps = {
  center: false,
  ellipsis: false,
  lineHeight: 1.5,
  margin: 0
}

export const Title = ({ level, text, children, ...props }: TitleProps) => {
  const { isMobile } = useViewportSize()

  return (
    <Style {...defaultProps} {...props} {...titleLevelProps(isMobile && level === 1 ? 2 : level)}>
      {children || text}
    </Style>
  )
}

Title.Style = Style
