/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { askAi, rateAnswer } from '@api/assistantAi'
import { useSelector, useDispatch } from 'react-redux'
import { useEllipsis } from '@hooks/useEllipsis'
import { LocalStorageKeys } from '@features/storage/types'
import { getParsedValueFromLocalStorage, saveStringifiedToLocalStorage } from '@features/storage/utils'
import { DialogActions } from '@features/dialog/actions'
import { ContactViewType } from '@pages/Contact/ContactDialog/types'
import { getUserFirstName, getUserId } from '../../features/user/selectors'
import HeadsetIcon from '../../assets/icons/headset.svg?react'
import {
  FloatingButton,
  FloatButtonGroup,
  StyledCard,
  CloseIconWrapper,
  SenderLabel,
  Close,
  StyledTitle,
  StyledScroll
} from './styled'
import { MessageData, MessagesRecord } from './types'
import { ChatMessage } from './Chat/ChatMessage'
import { ConfirmationBar } from './Chat/ConfirmationBar'
import { ChatInputBar } from './Chat/ChatInput'
import { ChatContainer, AssistantMessageContainer, AssistantMessageBubble, Disclaimer, StyledText } from './Chat/styled'

type Props = {
  floatPosition?: string
  cardPosition?: string
}

export const AssistantAi = ({ floatPosition, cardPosition }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userFirstName = useSelector(getUserFirstName)
  const userId = useSelector(getUserId)

  const initialMessage: MessageData = {
    sender: 'Assistant',
    text: t('common:AssistantAi.Hello', { userFirstName }),
    isInitial: true
  }

  const [messages, setMessages] = useState<MessageData[]>(
    () => getParsedValueFromLocalStorage<MessagesRecord>(LocalStorageKeys.ASSISTANT_AI_DATA, {})[userId] || []
  )
  const [currentMessage, setCurrentMessage] = useState<string | undefined>('')
  const [isChatVisible, setIsChatVisible] = useState<boolean>(false)
  const [conversationId, setConversationId] = useState<string | undefined>(undefined)
  const [questionAndAnswerId, setQuestionAndAnswerId] = useState<string>()
  const [feedbackGiven, setFeedbackGiven] = useState(false)
  const [thankYouMessageVisible, setThankYouMessageVisible] = useState(false)
  const [isFirstOpen, setIsFirstOpen] = useState(true)
  const [showConfirmation, setShowConfirmation] = useState(false)

  const { mutate: mutateAskAi, isPending } = useMutation({
    mutationFn: (userMessage?: string) => {
      return askAi({ question: userMessage, conversationId })
    },
    onSuccess: data => {
      setConversationId(data.data.conversationId)
      setQuestionAndAnswerId(data.data.questionAndAnswerId)
      const assistantMessage: MessageData = { sender: 'Assistant', text: data.data.answer }
      setMessages(prev => [...prev, assistantMessage])
    },
    onError: () => {
      setCurrentMessage('')
    }
  })

  const { mutate: mutateRateAnswer } = useMutation({
    mutationFn: (value: number) => {
      return rateAnswer({ rating: value }, questionAndAnswerId)
    }
  })

  const ellipsis = useEllipsis(isPending)

  const handleRating = (value: number, messageIndex: number) => {
    const updatedMessages = [...messages]
    updatedMessages[messageIndex].isRated = true
    setMessages(updatedMessages)

    mutateRateAnswer(value)

    setFeedbackGiven(true)
    setThankYouMessageVisible(true)

    setTimeout(() => {
      setThankYouMessageVisible(false)
    }, 1000)
  }

  const handleSend = () => {
    if (currentMessage?.trim() !== '') {
      const trimmedMessage = currentMessage?.trim() ?? ''
      const userMessage: MessageData = { sender: 'Customer', text: trimmedMessage }
      setMessages([...messages, userMessage])
      setCurrentMessage('')
      mutateAskAi(trimmedMessage)
    }
  }

  const openChat = () => {
    setIsChatVisible(!isChatVisible)

    if (isFirstOpen) {
      const storedMessages =
        getParsedValueFromLocalStorage<MessagesRecord>(LocalStorageKeys.ASSISTANT_AI_DATA, {})[userId] || []
      if (storedMessages.length === 0) {
        setMessages([initialMessage])
      } else {
        setMessages(storedMessages)
      }
      setConversationId(undefined)
      setIsFirstOpen(false)
    }
  }

  const handleResetConfirm = () => {
    setMessages([initialMessage])
    setConversationId(undefined)
    setQuestionAndAnswerId(undefined)
    setShowConfirmation(false)
  }

  const handleResetCancel = () => {
    setShowConfirmation(false)
  }

  const resetChat = () => {
    setShowConfirmation(true)
  }

  const chatRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (chatRef.current && isChatVisible) {
      chatRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
  }, [messages, isChatVisible])

  useEffect(() => {
    if (!isFirstOpen && messages.length > 0) {
      const messagesRecord = getParsedValueFromLocalStorage<MessagesRecord>(LocalStorageKeys.ASSISTANT_AI_DATA, {})
      messagesRecord[userId] = messages
      saveStringifiedToLocalStorage(LocalStorageKeys.ASSISTANT_AI_DATA, messagesRecord)
    }
  }, [messages, userId, isFirstOpen])

  return (
    <>
      <FloatingButton bottom={floatPosition} onClick={openChat}>
        <HeadsetIcon />
      </FloatingButton>
      <FloatButtonGroup>
        {isChatVisible && (
          <StyledCard
            bottom={cardPosition}
            title={
              <>
                <StyledTitle>
                  <HeadsetIcon />
                  {t('common:AssistantAi.ChatTitle')}
                  <CloseIconWrapper>
                    <Close onClick={openChat} />
                  </CloseIconWrapper>
                </StyledTitle>
              </>
            }
          >
            <StyledScroll>
              <ChatContainer>
                {messages.map((message, messageIndex) => (
                  <ChatMessage
                    key={messageIndex}
                    messageIndex={messageIndex}
                    message={message}
                    onRate={(value: number) => handleRating(value, messageIndex)}
                    feedbackGiven={feedbackGiven}
                    thankYouMessageVisible={thankYouMessageVisible}
                  />
                ))}
                {isPending && (
                  <AssistantMessageContainer>
                    <AssistantMessageBubble>
                      {t('common:AssistantAi.Writing')}
                      {ellipsis}
                    </AssistantMessageBubble>
                    <SenderLabel sender="Assistant">{t('common:AssistantAi.Assistant')}</SenderLabel>
                  </AssistantMessageContainer>
                )}
                <div ref={chatRef} />
              </ChatContainer>
            </StyledScroll>
            {showConfirmation ? (
              <ConfirmationBar onConfirm={handleResetConfirm} onCancel={handleResetCancel} />
            ) : (
              <ChatInputBar
                placeholder={t('common:AssistantAi.Placeholder')}
                currentMessage={currentMessage}
                onMessageChange={e => setCurrentMessage(e.target.value)}
                onSend={handleSend}
                onReset={resetChat}
              />
            )}
            <Disclaimer>
              <Trans
                i18nKey="common:AssistantAi.Disclaimer"
                components={{
                  a: (
                    <StyledText
                      aria-label="Email Support"
                      onClick={() =>
                        dispatch(DialogActions.showDialogWithData('Contact', { state: ContactViewType.INIT }))
                      }
                    />
                  )
                }}
              />
            </Disclaimer>
          </StyledCard>
        )}
      </FloatButtonGroup>
    </>
  )
}
