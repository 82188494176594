import _ from 'lodash'
import { Reducer } from 'redux'
import * as actions from './actions'
import { PortfolioCompany } from './types'

export type PortfolioCompanyState = Readonly<{
  portfolioCompanies: Record<string, PortfolioCompany>
  order: string[]
}>

export const initState: PortfolioCompanyState = {
  portfolioCompanies: {},
  order: []
}

export const portfolioCompanyReducer: Reducer<PortfolioCompanyState, actions.PortfolioCompaniesActions> = (
  state = initState,
  action
) => {
  switch (action.type) {
    case actions.UPDATE_PORTFOLIO_COMPANIES:
      return {
        portfolioCompanies: {
          ...state.portfolioCompanies,
          ..._.keyBy(action.payload.portfolioCompanies, 'entityId')
        },
        order: _.union(state.order, _.map(action.payload.portfolioCompanies, 'entityId'))
      }
    case actions.ENRICH_COMPANIES: {
      const companiesWithEntityId = _.filter(state.portfolioCompanies, company =>
        _.includes(action.payload.companyIds, company.companyUnitId)
      )

      const enriched = _.map(companiesWithEntityId, company => ({ ...company, isEnriched: true }))
      return {
        ...state,
        portfolioCompanies: {
          ...state.portfolioCompanies,
          ..._.keyBy(enriched, 'entityId')
        }
      }
    }
    case actions.RESET_COMPANIES:
      return {
        ...initState
      }
    default:
      return state
  }
}
