/* eslint-disable import/no-default-export */
export default {
  Welcome: {
    Name: 'Bienvenue',
    Title: 'Bienvenue sur Margò',
    Description:
      "Margò est la nouvelle plateforme marketing intelligente de {{companyName}}, conçue pour fournir une aide à la croissance de votre activité à travers une vaste gamme de données. Elle répond à vos besoins et anticipe intelligemment vos exigences. Elle est donc l'outil parfait pour gérer les processus commerciaux et marketing de bout en bout."
  },
  PortfolioManagement: {
    Name: 'Gestion des portefeuilles',
    Title: 'Mode de gestion aisé des informations client',
    Description:
      "Créer des listes de sociétés, mettre à jour les dernières données, enrichir votre base de données avec les nouvelles informations du client et voir votre secteur d'activité d'un nouvel angle."
  },
  Targeting: {
    Name: 'Analyser marché',
    Title: 'Une nouvelle façon de voir ses clients',
    Description:
      "Observer les clients sur une carte, analyser la structure des portefeuilles de société sur plusieurs niveaux éviter les risques. Personnaliser et étendre les analyses selon vos besoins. Enregistrer les analyses et les partager pour prendre en charge de nouvelles stratégies d'activité."
  },
  Prospecting: {
    Name: 'Prospection',
    Title: 'Nouveaux clients en un clic',
    Description:
      "Rechercher de nouveaux clients pour votre activité, recevoir des notifications lorsqu'une nouvelle société répond à vos critères. Trouver de nouveaux prospects similaires à vos meilleurs clients, filtrer sur le portefeuille de sociétés partenaires et exploiter la recherche sémantique pour trouver votre prochain client."
  },
  SalesTool: {
    Name: 'Outil de vente',
    Title: 'Tout vos clients à portée de main',
    Description:
      "Sélectionner la cible, identifier les actions et démarrer des campagnes commerciales. S'emparer du réseau commercial fournissant les données pour conclure de nouveaux accords au bureau et à travers le mobile."
  },
  Api: {
    Name: 'API',
    Title: 'Informations commerciales en tant que service',
    Description:
      "Intégrer les données de Margò aux outils de la société afin de toujours disposer des dernières données clients. Automatiser l'enrichissement de vos portefeuilles clients et répondre aux questions de votre activité en connectant les données de Margò à vos systèmes de données en matière de Rapport, CRM et Business Master."
  }
}
