import { DefaultTheme } from 'styled-components/macro'

export const HEADER_HEIGHT = 80
export const HEADER_LOGO_WIDTH = 188
export const FOOTER_HEIGHT = 80
export const BREAKPOINT_MOBILE = 480
export const PAGE_PADDING_TOP = 40
export const PAGE_PADDING_BOTTOM = 40

export const theme = {
  colors: {
    main: '#003c7d',
    mainPressed: '#003064',
    secondary: '#f1f5f8',
    secondaryPressed: '#e4ebf2',
    gray: '#f2f2f2',
    veryLightPink: '#dfdfdf',
    paleGrey: '#f1f5f8',
    buttonBorderSecondary: '#dfdfdf',
    inputBorder: '#dfdfdf',
    brownGray: '#a1a1a1',
    veryLightBlue: '#e4ebf2',
    pumpkinOrange: '#ff7500',
    brownyOrange: '#cc5e00',
    lightOrange: '#ff9033',
    white: '#ffffff',
    powderBlue: '#c9d9e5',
    dustyBlue: '#5c8ab3',
    marineBlue: '#003064',
    grayishBrown: '#505050',
    whiteGray: '#f4f4f4',
    darkMint: '#35d57b',
    navy: '#001832',
    almostWhite: '#f9f9f9',
    watermelon: '#ff3c65',
    watermelonLight: '#ffebef',
    plum: '#661828',
    darkGrey: '#797979',
    yellow: '#FFCC00',
    botticelli: '#c9d9e6',
    link: '#003c7d',
    error: '#f5222d',
    antdError: '#ff4d4f',
    disabled: '#cecece',
    chromeAutofillInput: '#e8f0fe'
  },
  fontFamily: "'Lato', sans-serif",
  fontSizes: {
    extraSmall: '12px',
    small: '14px',
    medium: '16px',
    big: '19px',
    large: '24px',
    extraLarge: '32px'
  },
  animation: {
    duration: '0.3s'
  },
  icon: {
    size: 24
  }
}

export const gridTheme = {
  breakpoints: {
    xl: 1920,
    lg: 1366,
    md: 1024,
    sm: 768,
    xs: 768 // this should be smaller, didn't touch it not to break the style in other parts of the app
  },
  row: {
    padding: 20
  },
  col: {
    padding: 20
  },
  container: {
    padding: 0
  }
}

export type Type = 'primary' | 'normal' | 'secondary' | 'ghost' | 'dusty' | 'danger' | 'inherit'

export const colorByType = (t: DefaultTheme, type?: Type) => {
  switch (type) {
    case 'primary':
      return t.colors.main
    case 'secondary':
      return t.colors.brownGray
    case 'ghost':
      return t.colors.white
    case 'dusty':
      return t.colors.dustyBlue
    case 'danger':
      return t.colors.watermelon
    case 'inherit':
      return undefined
    default:
      return t.colors.grayishBrown
  }
}

export const chartThemeColors = {
  yellow: ['#996f0c', '#cc9410', '#ffba14', '#fdd470', '#faeccb', '#fdf3dd'],
  purple: ['#602269', '#802d8c', '#a139af', '#c586cd'],
  blue: ['#326296', '#5c8ab3', '#95adc7', '#c9d9e5', '#e4ebf2'],
  turquoise: ['#097b85', '#0a8f9a', '#0db9c7', '#57dce7', '#9febf1', '#cff5f8']
}

export type ChartColorTheme = keyof typeof chartThemeColors

export type ChartColors<T extends ChartColorTheme> = (typeof chartThemeColors)[T]

export const DEFAULT_MAP_HIGHLIGHT_COLOR = '#b4b4b4'
export const getMapHighlightColors = (percentage: number) => {
  if (percentage >= 0.8) {
    return '#002a58'
  }
  if (percentage >= 0.6) {
    return '#003c7d'
  }
  if (percentage >= 0.4) {
    return '#336397'
  }
  if (percentage >= 0.2) {
    return '#668ab1'
  }
  if (percentage > 0) {
    return '#99b1cb'
  }

  // 0 and NaN
  return DEFAULT_MAP_HIGHLIGHT_COLOR
}
