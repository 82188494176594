import { Location, NavigateFunction } from 'react-router-dom'
import { ActionsUnion } from '../../utils/types'
import { createAction } from '../../utils/redux'
import { ApplicationConfigDto, UserConfigStates } from './types'

export const ON_APP_CONFIG = 'config/ON_APP_CONFIG'
export const SET_APP_CONFIG = 'config/SET_APP_CONFIG'
export const SET_FAIL_APP_CONFIG = 'config/SET_FAIL_APP_CONFIG'
export const HANDLE_USER_CONFIG = 'config/HANDLE_USER_CONFIG'
export const SET_USER_CONFIG = 'config/SET_USER_CONFIG'

export const SIGNALR_STARTED = 'config/SIGNALR_STARTED'
export const SET_SIGNALR_STARTED = 'config/SET_SIGNALR_STARTED'
export const CONFIG_AFTER_LOGOUT = 'config/AFTER_LOGOUT'
export const SET_APP_LANGUAGE = 'config/SET_APP_LANGUAGE'

export const ConfigActions = {
  // appConfig
  onAppConfig: (config: ApplicationConfigDto) => createAction(ON_APP_CONFIG, { config }),
  setAppConfig: (config: ApplicationConfigDto) => createAction(SET_APP_CONFIG, { config }),
  setFailAppConfig: () => createAction(SET_FAIL_APP_CONFIG),
  // userConfig
  handleUserConfig: (location: Location, navigate: NavigateFunction) =>
    createAction(HANDLE_USER_CONFIG, { location, navigate }),
  setUserConfig: (userConfig: UserConfigStates) => createAction(SET_USER_CONFIG, { userConfig }),
  // other
  signalRStarted: () => createAction(SIGNALR_STARTED),
  setSignalRStarted: (started: boolean) => createAction(SET_SIGNALR_STARTED, { started }),
  setAppLanguage: (language: string) => createAction(SET_APP_LANGUAGE, { language }),
  resetAfterLogout: () => createAction(CONFIG_AFTER_LOGOUT)
}

export type ConfigActions = ActionsUnion<typeof ConfigActions>
