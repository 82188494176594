import { QueryClient, QueryClientConfig } from '@tanstack/react-query'

export const retryLogic = (failureCount: number, error: any) =>
  // In case request failed first time we are doing retry -> failureCount means number of retry failures (first call error comes with failureCount equals to 0)
  // In case of statusCode 4xx we are not doing retry
  failureCount < 1 && error?.response?.status >= 500

export const defaultQueryClientOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retry: retryLogic,
      notifyOnChangeProps: 'all'
    }
  }
}

export const queryClient = new QueryClient(defaultQueryClientOptions)

export const cacheAllTheTime = {
  staleTime: Infinity
}

export const cacheOneMinute = {
  staleTime: 60 * 1000
}
