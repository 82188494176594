/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: 'Pro zahájení analýzy musíte zvolit typ analýzy, kterou chcete zpracovat',
    TypeOfAnalysis: 'Typ analýzy',
    FromTargeting: 'Načíst dotaz z Analýzy trhu',
    FromTargetingTooltip: 'Otevřete dotaz uložený z Analýzy trhu',
    FromProspecting: 'Načíst dotaz z Výběru klientů',
    FromProspectingTooltip: 'Otevřete dotaz uložený z Výběru klientů',
    NewResearch: 'Spustit nové vyhledávání',
    NewResearchTooltip: 'Hledejte potenciální zákazníky prostřednictvím všech společností v databázi Margo',
    PortfolioQuery: 'Načtěte dotaz ze správy portfolia',
    TargetingQuery: 'Načíst dotaz z Analýzy trhu',
    ProspectingQuery: 'Načíst dotaz z Výběru klientů',
    LoadQuery: 'Načíst dotaz',
    LoadQueryTooltip: 'Otevřete uložený dotaz',
    SelectOne: 'Vybrat dotaz',
    SavedQuery: 'Uložený dotaz',
    ChooseQuery: 'Vyberte, který dotaz chcete načíst',
    YouCanResumeResearch: 'Můžete pokračovat ve vyhledávání, které jste už začali.'
  },
  Prospecting: 'Výběr klientů',
  Actions: {
    LoadQuery: 'Načíst Dotaz',
    LoadQueryTooltip: 'Otevřete uložený dotaz',
    SaveQuery: 'Uložit Dotaz',
    SaveQueryTooltip: 'Uložte tento dotaz do Výběru klientů',
    Download: 'Stáhnout Excel',
    DownloadTooltip: 'Stáhněte si obohacený seznam společností do svého zařízení',
    SaveList: 'Uložit Seznam',
    SaveListTooltip: 'Uložte seznam těchto společností do portfolia',
    SaveAndMonitor: 'Uložit a Sledovat Seznam',
    SaveAndMonitorTooltip:
      'Uložte seznam těchto společností do portfolia, které se dynamicky aktualizuje, když nové společnosti odpovídají zvoleným parametrům. Přidané společnosti budou automaticky obohaceny.',
    SendToSales: 'Poslat seznam do Obchodních aktivit',
    SendToSalesTooltip: 'Přiřaďte společnosti k prodejnímu týmu a naplánujte komerční kampaň',
    SendToOtherModules: 'Poslat do jiných modulů',
    SendToOtherModulesTooltip: 'Pošlete společnosti do modulu Obchodní aktivity nebo Analýza trhu'
  },
  Indexes: {
    Penetration: 'Index penetrace',
    PenetrationTooltip:
      'Měří % společností v portfoliu ve srovnání s těmi, které generuje Podobnost. Vyšší hodnota znamená lepší penetraci na potenciálním trhu.',
    Opportunity: 'Index příležitostí',
    OpportunityTooltip:
      'Měří % společností vytvořených podobností, které nejsou zahrnuty v portfoliu. Vyšší hodnota označuje větší počet potenciálních klientů.'
  },
  CompaniesInList_0: 'Společnost na seznamu',
  CompaniesInList_1: 'Společnosti na seznamu',
  CompaniesInList_2: 'Společností na seznamu',
  Search: {
    CompanySearch: 'Vyhledávání společnosti',
    CompanySearchPlaceholder: 'Najděte společnosti podle',
    CompanySearchFields: {
      TaxNumber: 'IČO',
      VatNumber: 'DIČ',
      ONRNumber: 'ONR',
      CompanyName: 'Názvu společnosti',
      CrifNumber: 'Crif čísla',
      CompanyIdentification: 'IČO'
    },
    SemanticSearch: 'Sémantické vyhledávání',
    SearchPlaceholder: 'Vyhledávejte podle klíčových slov',
    All: 'Vše',
    OfficialData: 'Oficiální data',
    Website: 'Web a sociální sítě',
    Synonyms: 'Synonyma',
    Advanced: 'Rozšířené sémantické vyhledávání',
    AdvancedSwitch: 'Rozšířené',
    AdvancedTooltip:
      'S pokročilým sémantickým vyhledáváním můžete používat více slov odděleny pomocí (AND, OR, NOT). Pro zapnutí funkce přepněte tlačítko na Zap. Pro více informací o sémantickém vyhledávání stáhněte dokument.',
    DownloadSsGuide: 'Stáhnout průvodce sémantickým vyhledáváním'
  },
  EmptySearch: {
    History: {
      Title: 'Bez filtrů',
      Subtitle: 'Zde se zobrazí, jak změna filtru ovlivní vyhledávání'
    },
    Banner: {
      Title: 'Začněte vyhledávání pomocí svých oblíbených filtrů',
      Subtitle:
        'Před začátkem vyhledávání se ujistěte, že jste vybrali nejvhodnější filtry. Platforma vygeneruje seznam ideálních prospektů na základě zvolených filtrů.'
    }
  },
  SaveBeforeContinue: {
    DialogTitle: 'Uložit vyhledávání',
    Question: 'Chcete před pokračováním uložit svoje vyhledávání?',
    Description: 'Před pokračováním uložte svoje vyhledávání, aby nedošlo ke ztrátě dat.',
    OkBtn: 'Uložit vyhledávání',
    CancelBtn: 'Přeskočit uložení'
  },
  DomainFilter: {
    SaveAndClose: 'Uložit filtry a zavřít'
  },
  UnavailableForMobile: 'Stránka, na kterou se pokoušíte dostat, je k dispozici pouze pro tablety a počítače.',
  SendToOtherModulesDialog: {
    Title: 'Vyberte modul',
    SubTitle: 'Vyberte modul, kam chcete odeslat analýzu nebo seznam',
    Targeting: 'Analýza trhu',
    TargetingDescription:
      'Kliknutím sem odešlete seznam identifikovaných společností do modulu Analýza trhu, kde si můžete prohlédnout aktualizované grafy, které představují rozložení společností s ohledem na hlavní proměnné.',
    SalesTool: 'Obchodní aktivity',
    SalesToolDescription:
      'Kliknutím sem odešlete seznam identifikovaných společností do modulu Obchodní aktivity. Seznam bude uložen do portfolia a data společností budou obohacena. V rámci modulu Obchodní aktivity můžete zahájit prodejní kampaň na vybraných společnostech a přiřadit ji k vaší prodejní síle.',
    OkButton: 'Odeslat'
  }
}
