import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldPolyfillPlurales } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillNumberformat } from '@formatjs/intl-numberformat/should-polyfill'

export async function initIntlNumberFormatter() {
  let isPolyfilled = false
  if (shouldPolyfillCanonicalLocales()) {
    isPolyfilled = true
    await import('@formatjs/intl-getcanonicallocales/polyfill')
    await import('@formatjs/intl-locale/polyfill')
  }

  if (shouldPolyfillPlurales()) {
    isPolyfilled = true
    await import('@formatjs/intl-pluralrules/polyfill')
  }

  if (shouldPolyfillNumberformat()) {
    isPolyfilled = true
    await import('@formatjs/intl-numberformat/polyfill')
  }

  if (isPolyfilled) {
    await Promise.all([
      import('@formatjs/intl-pluralrules/locale-data/en'),
      import('@formatjs/intl-pluralrules/locale-data/it'),
      import('@formatjs/intl-pluralrules/locale-data/de'),
      import('@formatjs/intl-pluralrules/locale-data/sk'),
      import('@formatjs/intl-pluralrules/locale-data/cs'),
      import('@formatjs/intl-pluralrules/locale-data/fr'),
      import('@formatjs/intl-numberformat/locale-data/en'),
      import('@formatjs/intl-numberformat/locale-data/it'),
      import('@formatjs/intl-numberformat/locale-data/de'),
      import('@formatjs/intl-numberformat/locale-data/sk'),
      import('@formatjs/intl-numberformat/locale-data/cs'),
      import('@formatjs/intl-numberformat/locale-data/fr')
    ])
  }
}
