import { Radio } from '@components/Radio'
import React from 'react'
import { Text } from '@components/Text'
import { useTranslation } from 'react-i18next'
import { SubmitMethod } from '@api/query/types'

type Props = {
  currentSaveMethod: SubmitMethod
  setCurrentSaveMethod: (option: SubmitMethod) => void
  disabled: boolean
  allowEmptySelection?: boolean
}

export const SelectionRadioGroup = ({
  disabled,
  currentSaveMethod,
  setCurrentSaveMethod,
  allowEmptySelection = false
}: Props) => {
  const { t } = useTranslation('common')

  return (
    <Radio.Group disabled={disabled} value={currentSaveMethod} onChange={e => setCurrentSaveMethod(e.target.value)}>
      {allowEmptySelection && (
        <Radio value={SubmitMethod.None}>
          <Text size="S" type="primary" bold>
            {t('SaveQuery.DoNotCreate')}
          </Text>
        </Radio>
      )}

      <Radio value={SubmitMethod.Update}>
        <Text size="S" type="primary" bold>
          {t('SaveQuery.UpdateExisting')}
        </Text>
      </Radio>

      <Radio value={SubmitMethod.CreateNew}>
        <Text size="S" type="primary" bold>
          {t('SaveQuery.CreateNew')}
        </Text>
      </Radio>
    </Radio.Group>
  )
}
