import ItIcon from '../../assets/icons/it.svg?react'
import GbIcon from '../../assets/icons/gb.svg?react'
import DeIcon from '../../assets/icons/de.svg?react'
import SkIcon from '../../assets/icons/sk.svg?react'
import CzIcon from '../../assets/icons/cz.svg?react'
import FrIcon from '../../assets/icons/fr.svg?react'

type LanguageIconProps = {
  langKey: string
}

export const getLanguageIconCmp = ({ langKey }: LanguageIconProps) => {
  switch (langKey) {
    case 'en-GB':
      return GbIcon
    case 'it-IT':
      return ItIcon
    case 'de-DE':
      return DeIcon
    case 'sk-SK':
      return SkIcon
    case 'cs-CZ':
      return CzIcon
    case 'fr-FR':
      return FrIcon
    default:
      return GbIcon
  }
}
