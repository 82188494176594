import axios, { AxiosRequestConfig } from 'axios'
import { SessionResponse, AvailableCountry } from './types'
import { ApplicationConfigDto, InstanceConfigDto } from '../../features/config/types'

const configURL = '/config.json'

export const fetchApplicationConfig = () => axios.get<ApplicationConfigDto>(configURL, { params: { t: Date.now() } })

export const fetchInstanceConfig = (instanceCode: string) =>
  axios.get<InstanceConfigDto>(`/config-${instanceCode}.json`, {
    baseURL: window.location.origin,
    params: { t: Date.now() }
  })

export const fetchSession = () => axios.get<SessionResponse>('/api/session')

export const fetchAvailableCountries = (instanceCode: string, config?: AxiosRequestConfig) =>
  axios
    .get<AvailableCountry[]>(`/api/Common/countries?instanceCode=${instanceCode}`, config)
    .then(response => response.data)
