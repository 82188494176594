/* eslint-disable import/no-default-export */
export default {
  Welcome: {
    Name: 'Vítejte',
    Title: 'Vítejte v Margo',
    Description:
      'Margo je nová inteligentní marketingová platforma {{companyName}}, která je navržena pro poskytování podpory růstu vašeho podnikání pomocí široké škály dat. Reaguje na vaše potřeby a dokáže předvídat vaše požadavky. Je to dokonalý nástroj pro správu obchodních a marketingových procesů od začátku do konce.'
  },
  PortfolioManagement: {
    Name: 'Správa portfolia',
    Title: 'Snadný způsob správy klientských údajů',
    Description:
      'Vytvářejte seznamy společností, aktualizujte nejnovější údaje, obohacujte svou databázi o nové informace o klientech a podívejte se na svůj obchodní trh z nového úhlu pohledu.'
  },
  Targeting: {
    Name: 'Analýza trhu',
    Title: 'Nový způsob pohledu na vaše klienty',
    Description:
      'Zobrazte své klienty na mapě, analyzujte na více úrovních strukturu klientských portfolií, vyhněte se riziku. Přizpůsobte a rozšiřte si analýzu podle svých potřeb. Uložte si analýzy a sdílejte je s cílem podpořit nové obchodní strategie.'
  },
  Prospecting: {
    Name: 'Výběr klientů',
    Title: 'Noví klienti jedním kliknutím',
    Description:
      'Vyhledejte pro svou firmu nové klienty, dostanete upozornění na novou společnost, která odpovídá vašim kritériím. Najděte nové obchodní šance podobné vašim nejlepším klientům, filtrujte portfolio partnerských společností a využijte sémantické vyhledávání k vyhledání dalšího klienta.'
  },
  SalesTool: {
    Name: 'Obchodní aktivity',
    Title: 'Všichni vaši klienti po ruce',
    Description:
      'Vyberte si cíl, identifikujte akce a začněte obchodní kampaně. Posilte postavení obchodní sítě, poskytněte jim informace s cílem uzavřít nové obchody v kanceláři nebo prostřednictvím mobilních telefonů.'
  },
  Api: {
    Name: 'API',
    Title: 'Obchodní informace jako služba',
    Description:
      'Integrujte Margo údaje do svých firemních nástrojů, abyste měli u sebe vždy nejnovější údaje o svých klientech. Automatizujte obohacení svých klientských portfolií o nové údaje a získejte odpovědi na otázky, které souvisí s podnikáním připojením údajů z Margo k vašim systémům Reportingu, CRM a Business Master Data.'
  }
}
