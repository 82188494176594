import React from 'react'
import { Navigate } from 'react-router-dom'

type Props = {
  loggedIn: boolean
  redirect: string
  component: React.ElementType
}

export const OnlyUnauthWrapper = ({ loggedIn, redirect, component: Component }: Props) =>
  loggedIn ? <Navigate to={redirect} replace /> : <Component />
