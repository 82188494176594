import React, { PureComponent, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { toInt } from '../../utils/helpers'

const OverlayComponent = styled.div<{ visible: number; z: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.colors.main};
  z-index: ${({ z }) => z};
`

type Props = {
  visible: boolean
  zIndex: number
  scrollDisabled: boolean
  onClick?: () => void
  children?: ReactNode
}

export class Overlay extends PureComponent<Props> {
  bodyRef?: HTMLElement

  componentDidMount() {
    this.bodyRef = document.body
    this.setBodyStyle()
  }

  componentDidUpdate() {
    this.setBodyStyle()
  }

  componentWillUnmount() {
    this.bodyRef!.style.overflow = 'auto'
  }

  setBodyStyle = () => {
    const { visible, scrollDisabled } = this.props
    this.bodyRef!.style.overflow = visible && scrollDisabled ? 'hidden' : 'auto'
  }

  render() {
    const { visible, zIndex, onClick, children } = this.props
    return (
      <>
        {children}
        <OverlayComponent visible={toInt(visible)} z={zIndex} onClick={onClick} />
      </>
    )
  }
}
