import React from 'react'
import { WindowCenter } from '../Center'
import { ExtraLargeSpin } from './Spin'

export const Loading = () => (
  <WindowCenter data-testid="loading-screen-fallback">
    <ExtraLargeSpin delay={150} />
  </WindowCenter>
)

export const LoadingInPage = () => (
  <WindowCenter data-testid="loading-screen-fallback" inPage>
    <ExtraLargeSpin delay={150} />
  </WindowCenter>
)
