import axios, { CancelToken } from 'axios'
import { QueryType } from '../../types'
import {
  CreateFilterQueryRequest,
  GetQueriesParams,
  GetQueriesRequest,
  GetQueriesResponse,
  QueryResponseItem,
  ShareFilterQueryConfirmation,
  ShareFilterQueryConfirmationResponse,
  UpdateFilterQueryRequest
} from './types'
import { FilterQueryDto } from '../filters/types'

export const getQueries = (type: QueryType | QueryType[], params: GetQueriesParams = {}, cancelToken?: CancelToken) => {
  const withTypeParams = {
    'Filter.Types': type,
    ...params
  }

  // prettier-ignore
  const result = axios
    .get<GetQueriesResponse>('api/documents', { params: withTypeParams, cancelToken })
    .then(response => response.data)
  return result
}

export const getFilterQueriesAsDocument = (params: GetQueriesRequest) => {
  const result = axios
    .get<GetQueriesResponse>('api/documents/filter-queries-as-document', { params })
    .then(response => response.data)
  return result
}

export const getQueryAsExpression = (queryId: string) =>
  axios.get<FilterQueryDto>(`api/documents/${queryId}/get-filter-query`).then(response => response.data)

export const addQueryAsExpression = (data: CreateFilterQueryRequest) =>
  axios
    .post<QueryResponseItem>('api/documents/create-filter-query', data, { allowedErrorStatuses: [400, 409] })
    .then(response => response.data)

export const updateQueryAsExpression = (queryId: string, data: UpdateFilterQueryRequest) =>
  axios
    .put<QueryResponseItem>(
      `api/documents/${queryId}/update-filter-query`,
      { ...data },
      { allowedErrorStatuses: [400, 404, 409] }
    )
    .then(response => response.data)

export const updateQuery = (queryId: string, name: string, date: Date) =>
  axios.put(`api/Documents/${queryId}`, {
    name,
    lastUseDate: date.toISOString()
  })

export const shareFilterQueryOffer = (queryId: string, users: { userId: string }[]) =>
  axios.put(`/api/documents/${queryId}/share-filter-query-offer`, { users }).then(response => response.data)

export const shareFilterQueryConfirmation = (queryId: string, shareInfo: ShareFilterQueryConfirmation) =>
  axios
    .put<ShareFilterQueryConfirmationResponse>(`/api/documents/${queryId}/share-filter-query-confirmation`, shareInfo)
    .then(response => response.data)
