import React, { useCallback, memo, useEffect, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isDialogLoading } from '../../../features/loading/selectors'
import { getVisibleDialog, getVisibleDialogData } from '../../../features/dialog/selectors'
import { DialogActions } from '../../../features/dialog/actions'
import DialogRendererComponent from './DialogRendererComponent'

export const DialogRendererComponentWrapper = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(isDialogLoading)
  const dialogType = useSelector(getVisibleDialog)
  const dialogData = useSelector(getVisibleDialogData)
  const location = useLocation()

  const closeDialog = useCallback(() => dispatch(DialogActions.closeDialog()), [dispatch])

  useEffect(() => {
    if (dialogType) {
      closeDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, closeDialog])

  return (
    <>
      {dialogType && (
        <Suspense>
          <DialogRendererComponent
            dialogType={dialogType}
            dialogData={dialogData}
            isLoading={isLoading}
            close={closeDialog}
          />
        </Suspense>
      )}
    </>
  )
}

export const DialogRenderer = memo(DialogRendererComponentWrapper)
