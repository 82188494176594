import { Reducer } from 'redux'
import * as actions from './actions'
import { AnalyticsState } from './types'

export const analyticsReducer: Reducer<AnalyticsState, actions.TrackingActions> = (_state, action) => {
  switch (action.type) {
    case actions.SET_VISITOR_ID: {
      return {
        visitorId: action.payload?.visitorId?.slice(0, 16)
      }
    }
    default:
      return {}
  }
}
