import { Method as RequestMethod } from 'axios'
import { CompoundExpression } from './expressionTypes'
import { SemanticSearchType } from './semanticSearch/types'

export type ArrayResponse<T> = {
  items: T[]
  totalCount: number
}

export type UserResponse = {
  id: string // <- User Id
  organization: OrganizationResponse
  userCode: string
  firstName: string
  lastName: string
  email: string
  authUserDistinguishName: string
  impersonationAllowed?: boolean
  organizationUserDistinguishName: string
  isReleaseNotesEditor?: boolean
  userType: string // <- UserType / Level
  language: string // <- Language code
  currency: string
  isActive: boolean
  channel: Channel
  phoneNumber?: string
  jobTitle?: string
}

export type OrganizationResponse = {
  id: string // <- Organization Id
  code: string
  name: string // <- Organization Name
  countryCode: string
  authUserDistinguishName: string
  organizationUserDistinguishName: string
  identificationCode: string
  addressCountryCode?: string // alpha-2 country code
}

export type UrlConfig = {
  url: string
  method: RequestMethod
}

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderByResponseDto = {
  jsonPath: string
  text: string
  direction: OrderByDirection
}

export type OrderByResponse = {
  available: OrderByResponseDto[]
  default: OrderByResponseDto[]
}

export type OrderByRequest = {
  fieldName: string
  sortOrder: OrderByDirection
}

export enum ResultCode {
  IncompatibleDestEntity = 'INDESTEN'
}

export enum Channel {
  None = 'None',
  Web = 'Web',
  Api = 'Api',
  Integrations = 'Integrations'
}

export enum SortDirectionEnum {
  Unknown = 'unknown',
  Asc = 'asc',
  Desc = 'desc'
}

export enum CustomVariableType {
  String = 'String',
  Numeric = 'Numeric',
  Date = 'Date'
}

export type CustomVariable = {
  name: string
  position: number
  type: CustomVariableType
}

export type CustomVariablesResponse = {
  customVars: CustomVariable[]
}

export type RedirectUrlResponse = {
  externalUrl: string
}

export type OperationResult = {
  status: 'success' | 'error' | 'pending'
  actionContainerId: string
  destinationId: string
  errorCode: {
    code: string
  }
  internalErrorMessage: string
  data: any
}

export type GroupActionRequest = {
  selectedItems?: ExternalItemIdentification[]
  itemsToIgnore?: ExternalItemIdentification[]
  sourceContainerItemId?: string
  destinationContainerItemId?: string
}

export type ExternalItemIdentification = {
  entityId?: string
  companyUnitId: string
  identificationCode: string
  isEnriched: boolean
  sqlVersion?: number
}

export type GridQueryFiltersRequest = {
  semantic?: SemanticSearchRequest
  ignoredPortfolios?: string[]
  orderBy?: string
  numberOfCompanies?: number
  query: CompoundExpression | undefined
}

export type SemanticSearchRequest = {
  search: string
  synonyms: SynonymItem[]
  type: SemanticSearchType
  advanced: boolean
  not: boolean
}

export type SynonymItem = {
  key: string
  text: string
}

export type WithKeepPreviousData = {
  keepPreviousData?: boolean
}
