import React from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultTheme } from 'styled-components/macro'
import Download from '../../../assets/icons/download.svg?react'
import { OpenButtonStyled } from './OpenButton'

type Props = {
  fallbackTheme: DefaultTheme
  downloadFile: () => void
  text?: string
}

export const DownloadButton = ({ fallbackTheme, downloadFile, text }: Props) => {
  const { t } = useTranslation(['notification'])

  return (
    <OpenButtonStyled fallbackTheme={fallbackTheme} onClick={downloadFile} ghost iconRight={<Download />}>
      {text || t('notification:NotificationPopup.DownloadList')}
    </OpenButtonStyled>
  )
}
