import { getServicesBaseInfo } from '@s-group/react-usercentrics'

const MATOMO_SERVICE_ID = 'u6fxocwTs'
export const getMatomoConsentValue = (usercentricsSettingsId: string | undefined) => {
  if (usercentricsSettingsId === undefined) return true

  const services = getServicesBaseInfo()

  const matomoService = services.find(service => service.id === MATOMO_SERVICE_ID)

  if (matomoService) return matomoService.consent.status

  return false
}
