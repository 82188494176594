// @ts-ignore
window.$buoop = {
  required: { e: 12, f: 1, o: 1, s: 1, c: 1 },
  // Specifies required browser versions
  // Browsers older than this will be notified.
  // f:22 ---> Firefox < 22 gets notified
  // Negative numbers specify how much versions behind current version.
  // c:-5 ---> Chrome < 35  gets notified if latest Chrome version is 40.

  reminder: 0,
  // after how many hours should the message reappear
  // 0 = show all the time

  reminderClosed: 24,
  // if the user explicitly closes message it reappears after x hours

  insecure: true,
  api: 2022.12
}

function handleDomContentLoaded() {
  const e = document.createElement('script')
  e.src = 'https://browser-update.org/update.min.js'
  document.body.appendChild(e)
}

document.addEventListener('DOMContentLoaded', handleDomContentLoaded, false)
