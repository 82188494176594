import _ from 'lodash'
import { CompoundExpression, Expression } from '@api/expressionTypes'
import { GetUserDashboardResponse } from '../api/dashboard/types'
import { DocumentsResponse } from '../api/documents/types'
import {
  DomainNodeDataResponse,
  DomainNodesDataResponse,
  FilterDefinitionRequest,
  FilterDefinitionRequestType,
  FilterStructureRequestDef,
  KeyTreeResponse,
  QueryOrderByOption
} from '../api/filters/types'
import { NotificationResponse, PushEventResponse } from '../api/notification/types'
import { DataItem, QueryCompaniesResponse } from '../api/companies/types'
import {
  GetPortfolioCompanyDuplicatesResponse,
  PortfolioCompaniesResponse,
  PortfolioCompany as PortfolioCompanyApi,
  PortfolioCompanyMapDto,
  PortfolioCompanyMapResponseDto
} from '../api/portfolio/types'
import {
  FunnelCountersFilterRequest,
  FunnelCountersRequest,
  ProspectingCompaniesRes,
  ProspectingCompany,
  ProspectingCompanyMapResponseDto,
  CompanyCell
} from '../api/prospecting/types'
import { QueryResponseItem } from '../api/query/types'
import {
  CampaignCompaniesRes,
  CampaignCompanyDto,
  CampaignCompanyMapDto,
  CampaignCompanyMapResponseDto
} from '../api/salestool/types'
import { OrderByRequest, OrderByResponseDto, SemanticSearchRequest } from '../api/types'
import { OperationsCompany } from '../pages/Operations/ListView/types'
import { FieldConfigurationDto, QueryType, SortItem, TableColumn } from '../types'
import { DataTree, KeyTree } from '../utils/tree'
import { DashboardWithWidgets } from './dashboard/types'
import { Document, DocumentType } from './documents/types'
import { FilterDefinition, FilterOptionType, OrderByOptions, SemanticSearch } from './filters/types'
import { Notification, PushEvent } from './notifications/types'
import { PortfolioCompany } from './portfolioCompany/types'
import { PortfolioCompanyDuplicate } from './portfolioCompanyDuplicates/types'
import { FunnelDataItemBase, FunnelItemTypeEnum, ProspectingCompanyMapDto } from './prospecting/types'
import { Query } from './query/types'
import { CampaignCompany } from './salestool/types'
import { TableColumnSorting } from './tables/types'
import { getChildExpressionById, getDefaultRootExpression } from './operations/utils'
import { isCompoundExpression, isCustomVarExpression, isHierarchyExpression } from './operations/guards'

const getCompanyCellValue = (cell: CompanyCell | undefined): string | undefined => {
  return cell?.text ?? cell?.value
}

export const mapCompaniesItems = <
  T extends OperationsCompany | ProspectingCompany | PortfolioCompany | CampaignCompany
>(
  columns: string[] | undefined,
  item: DataItem
): T =>
  _.reduce(
    columns,
    (allData, col) => ({
      ...allData,
      [col]: getCompanyCellValue(_.find(item.cells, { fieldId: col })),
      cells: item.cells,
      entityId: item.entityId,
      companyUnitId: item.companyUnitId
    }),
    {} as T
  )

export const mapCampaignCompanyItems = (columns: string[] | undefined, item: CampaignCompanyDto): CampaignCompany =>
  _.reduce(
    columns,
    (allData, col) => ({
      ...allData,
      [col]: getCompanyCellValue(_.find(item.cells, { fieldId: col })),
      cells: item.cells,
      entityId: item.entityId ?? '',
      companyUnitId: item.companyUnitId
    }),
    {} as CampaignCompany
  )

const getFilterDefinitionId = (filter: FilterDefinition): string => {
  if (filter.isCustomVar) {
    return _.isNil(filter.position) ? filter.id : `${filter.position}`
  }
  return filter.id
}

const getFilterDefinitionRequestType = (filter: FilterDefinition): FilterDefinitionRequestType => {
  if (filter.isCustomVar) {
    return FilterDefinitionRequestType.CustomVar
  }
  return filter.type === FilterOptionType.Hierarchy
    ? FilterDefinitionRequestType.Hierarchy
    : FilterDefinitionRequestType.Simple
}

export const getFilterDefinitionRequestTypeFromExpression = (expression: Expression): FilterDefinitionRequestType => {
  if (isCustomVarExpression(expression)) {
    return FilterDefinitionRequestType.CustomVar
  }
  return isHierarchyExpression(expression) ? FilterDefinitionRequestType.Hierarchy : FilterDefinitionRequestType.Simple
}

export const formatFilterDefinitionRequest = (
  filterDefinition: FilterDefinition,
  groupId: string
): FilterDefinitionRequest => ({
  id: getFilterDefinitionId(filterDefinition),
  definitionType: getFilterDefinitionRequestType(filterDefinition),
  groupId
})

export const formatFilterDefinitionToRequestStructure = (
  filterDefinition: FilterDefinition
): FilterStructureRequestDef => {
  return {
    id: getFilterDefinitionId(filterDefinition),
    definitionType: getFilterDefinitionRequestType(filterDefinition)
  }
}

export const mapNotificationResponse = (notification: NotificationResponse): Notification => {
  const { timestamp, ...rest } = notification
  return {
    ...rest,
    dateTime: timestamp
  }
}

export const mapQueries = (res?: QueryResponseItem[]) =>
  _.map(
    res,
    (x): Query => ({
      id: x.id,
      type: x.type,
      name: x.name,
      createdAt: new Date(x.createdAt),
      lastUseDate: new Date(x.lastUseDate),
      isMonitored: x.type === QueryType.MonitoredQueries,
      associatedEntityId: x.associatedEntityId,
      query: {}
    })
  )

export const mapDocuments = (res?: DocumentsResponse) =>
  _.map(
    res?.items,
    (x): Document => ({
      ...x,
      isMonitored: x.type === DocumentType.MonitoredQueries
    })
  )

export const mapPortfolioCompany = (portfolioCompany: PortfolioCompanyApi, portfolioId: string): PortfolioCompany => ({
  portfolioId,
  ...portfolioCompany,
  name: portfolioCompany.companyName,
  fields: _.reduce(
    portfolioCompany.cells,
    (cells, { columnId, value, text }) => {
      // eslint-disable-next-line no-param-reassign
      cells[columnId] = text ?? value
      return cells
    },
    {} as Record<string, any>
  ),
  createdAt: new Date(portfolioCompany.createdAt)
})

export const mapPortfolioCompaniesResponse = ({
  columns,
  companies,
  portfolioId
}: {
  columns?: string[]
  companies: PortfolioCompaniesResponse
  portfolioId: string
}) =>
  _.map(companies.data.items, item => ({
    ...mapCompaniesItems<PortfolioCompany>(columns, item),
    portfolioId,
    entityId: item.entityId,
    identificationCode: item.identificationCode,
    margoId: item.margoId,
    name: item.companyName,
    createdAt: new Date(item.createdAt),
    isHeadquarter: item.isHeadquarter,
    isSharedWithAreaManager: item.isSharedWithAreaManager,
    isSharedWithSales: item.isSharedWithSales,
    isEnriched: item.isEnriched
  }))

export const mapProspectingCompaniesResponse = ({
  columns,
  companies
}: {
  columns?: string[]
  companies: ProspectingCompaniesRes
}) =>
  _.map(companies.data.items, item => ({
    ...item,
    ...mapCompaniesItems<ProspectingCompany>(columns, item)
  }))

export const mapSalestoolCompaniesResponseNew = ({
  columns,
  companies
}: {
  columns?: string[]
  companies: CampaignCompaniesRes
}) =>
  _.map(companies.data, item => ({
    ...item,
    ...mapCampaignCompanyItems(columns, item)
  }))

const collLeft = (fields: FieldConfigurationDto[], fieldIndex: number) => {
  const prevStickyFields = fields.filter((f, i) => f.sticky && i < fieldIndex)
  const left = prevStickyFields.reduce((sum, f) => sum + (f.width ?? 0), 0)
  return left
}

export const mapTableColumnConfiguration = (fields: FieldConfigurationDto[]): TableColumn[] =>
  _.map(fields, (x, i) => ({
    dataIndex: x.jsonPath,
    title: x.text,
    type: x.fieldType,
    visibilityType: x.visibilityType,
    className: x.sticky ? 'sticky-column' : undefined,
    onCell: () => ({
      style: {
        minWidth: x.width,
        left: x.sticky ? `${collLeft(fields, i)}px` : undefined
      }
    }),
    onHeaderCell: () => ({
      style: {
        minWidth: x.width,
        left: x.sticky ? `${collLeft(fields, i)}px` : undefined
      }
    })
  }))

export const mapPortfolioCompanyMapDataResponse = (
  company: PortfolioCompanyMapResponseDto
): PortfolioCompanyMapDto => ({
  entityId: company.entityId,
  companyStatus: company.companyStatus,
  position: {
    lat: company.geoPosition.lat,
    lng: company.geoPosition.lon
  },
  count: company.count
})

export const mapProspectingCompanyMapDataResponse = (
  company: ProspectingCompanyMapResponseDto
): ProspectingCompanyMapDto => ({
  entityId: company.entityId,
  position: {
    lat: company.geoPosition.lat,
    lng: company.geoPosition.lon
  },
  count: company.count
})

export const mapCampaignCompanyMapDataResponse = (company: CampaignCompanyMapResponseDto): CampaignCompanyMapDto => ({
  entityId: company.entityId,
  position: {
    lat: company.geoPosition.lat,
    lng: company.geoPosition.lon
  },
  count: company.count
})

export const mapDomainNodeDataResponse = (node: DomainNodeDataResponse): DataTree => ({
  key: node.code,
  label: node.displayName,
  hasChildren: node.hasChildren,
  parentPath: node.parentPath,
  children: undefined,
  childrenOrder: undefined,
  count: node.count
})

export const mapDomainNodesDataResponse = (nodes: DomainNodesDataResponse | null | undefined): DataTree[] =>
  _.map(nodes, node => mapDomainNodeDataResponse(node))

export const mapSearchDomainNodesDataResponse = (nodes: DomainNodesDataResponse | null | undefined): DataTree[] =>
  _.map(mapDomainNodesDataResponse(nodes), node => _.set(node, 'hasChildren', false))

export const mapKeyTree = (keyTreeResponse: KeyTreeResponse): KeyTree => JSON.parse(keyTreeResponse.serializedTree)

export const mapUserDashboardResponse = (data: GetUserDashboardResponse): DashboardWithWidgets => ({
  id: data.item.id,
  userId: data.item.userId,
  widgets: _.keyBy(data.item.items, 'id')
})

export const mapPushEventResponse = (pushEvent: PushEventResponse): PushEvent => {
  const { timestamp, eventId, ...rest } = pushEvent
  return {
    ...rest,
    id: eventId,
    dateTime: timestamp
  }
}

export function mapFunnelRequest(
  expression: CompoundExpression | undefined,
  excludedPortfolios: string[],
  semanticSearch: SemanticSearchRequest | undefined,
  funnelData: FunnelDataItemBase[]
): FunnelCountersRequest {
  const funnelCountersFilters: FunnelCountersFilterRequest[] = _.compact(
    _.map(funnelData, funnelDataItem => {
      if (funnelDataItem.funnelItemType === FunnelItemTypeEnum.Semantic) {
        return {
          type: 'Semantic',
          semantic: semanticSearch
        }
      }
      if (funnelDataItem.funnelItemType === FunnelItemTypeEnum.ExcludedPortfolios) {
        return {
          type: 'IgnoredPortfolios',
          ignoredPortfolios: excludedPortfolios
        }
      }
      const childExpression = expression ? getChildExpressionById(expression, funnelDataItem.fieldId) : undefined
      if (!childExpression) return undefined

      if (isCompoundExpression(childExpression)) {
        return {
          type: 'Filter',
          query: childExpression
        }
      }

      return {
        type: 'Filter',
        query: {
          ...getDefaultRootExpression(),
          childExpressions: [childExpression]
        }
      }
    })
  )

  return {
    filters: funnelCountersFilters
  }
}

export const mapPortfolioCompanyDuplicatesResponse = (
  data: GetPortfolioCompanyDuplicatesResponse
): PortfolioCompanyDuplicate[] =>
  _.map(data.records, x => ({
    id: x.margoId, // TODO: refactor margoId to entityId
    name: x.companyName,
    // TODO: API doesn't send amount and sales
    amount: '1000',
    sales: `Sales ${x.margoId}`,
    status: '',
    product: '',
    ..._.reduce(x.cells, (cells, c) => _.set(cells, c.columnId, c.text ?? c.value), {})
  }))

export const formatSemanticSearch = (semanticSearch: SemanticSearch): SemanticSearchRequest => ({
  search: semanticSearch.searchText,
  synonyms: semanticSearch.appliedSynonyms,
  type: semanticSearch.type,
  advanced: semanticSearch.advanced,
  not: semanticSearch.not
})

export const formatSemanticSearchForRequest = (
  semanticSearch: SemanticSearch | undefined
): SemanticSearchRequest | undefined => {
  if (!semanticSearch) return undefined
  return semanticSearch.appliedSynonyms.length || semanticSearch.searchText || semanticSearch.not
    ? formatSemanticSearch(semanticSearch)
    : undefined
}

export const mapOrderByOptions = (optionsResposne: OrderByResponseDto[]): OrderByOptions =>
  _.map(optionsResposne, x => ({
    key: `${x.jsonPath}-${x.direction}`,
    text: x.text,
    jsonPath: x.jsonPath,
    direction: x.direction
  }))

export const mapOrderByToRequestOptions = (options: OrderByOptions): OrderByRequest[] =>
  _.map(options, o => ({
    fieldName: o.jsonPath,
    sortOrder: o.direction
  }))

export const mapSavedQueryOrderByToOrderByKey = (options: QueryOrderByOption[]) =>
  _.map(options, o => `${o.jsonPath}-${o.direction}`)

export const mapOrderByOptionsToQueryOrderByOptions = (options: OrderByOptions): QueryOrderByOption[] =>
  _.map(options, x => ({ jsonPath: x.jsonPath, direction: x.direction }))

export const mapOperationsCompaniesResponse = ({
  columns,
  companies
}: {
  columns?: string[]
  companies: QueryCompaniesResponse
}) =>
  _.map(companies.data.items, item => ({
    ...item,
    ...mapCompaniesItems<OperationsCompany>(columns, item),
    id: item.entityId ?? ''
  }))

export const mapSortingRequest = (sorting?: TableColumnSorting): SortItem | undefined => {
  if (!sorting) return undefined
  return {
    property: sorting.field,
    order: sorting.order === 'ascend' ? 'Asc' : 'Desc'
  }
}
