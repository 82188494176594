import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Dialog } from '../../../components/Dialog'
import { InfoDialogData } from './types'
import { StyledText, Content } from '../components'

type Props = {
  close: () => void
  data: InfoDialogData
}

export const InfoDialogComponent = ({ close, data }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'portfolio', 'salestool'])
  const {
    title,
    text,
    i18nTitle,
    i18nText,
    i18nTitleData,
    i18nTextData,
    textElement,
    image,
    onCloseRedirectTo,
    onClose,
    showCancelButton = true,
    width = 520
  } = data

  const infoTitle = i18nTitle ? t(i18nTitle, i18nTitleData ?? {}) : title
  const infoText = i18nText ? t(i18nText, i18nTextData ?? {}) : text

  const onDialogClose = onCloseRedirectTo
    ? () => {
        close()
        navigate({ pathname: onCloseRedirectTo })
      }
    : () => {
        if (onClose) onClose()
        close()
      }

  return (
    <Dialog
      loading={false}
      open
      width={width}
      title={infoTitle}
      okText={t('common:Ok')}
      onOk={onDialogClose}
      onCancel={showCancelButton ? onDialogClose : undefined}
      footer
    >
      <Content>
        <div className="text">
          <StyledText>{textElement ?? infoText}</StyledText>
        </div>
        {image && <img src={image} alt="" />}
      </Content>
    </Dialog>
  )
}

export const InfoDialog = React.memo(InfoDialogComponent)
