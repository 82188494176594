/* eslint-disable import/no-default-export */
export default {
  Welcome: {
    Name: 'Vitajte',
    Title: 'Vitajte v Margo',
    Description:
      'Margo je nová {{companyName}} inteligentná marketingová platforma, ktorá je navrhnutá na poskytovanie podpory rastu vášho podnikania pomocou širokej škály údajov. Reaguje na vaše potreby a inteligentne dokáže predvídať vaše požiadavky. Je to dokonalý nástroj na správu obchodných a marketingových procesov od začiatku do konca.'
  },
  PortfolioManagement: {
    Name: 'Správa portfólia',
    Title: 'Ľahký spôsob správy klientských údajov',
    Description:
      'Vytvárajte zoznamy spoločností, aktualizujte najnovšie údaje, obohacujte svoju databázu o nové informácie o klientoch a pozrite sa na svoj obchodný trh z nového uhla pohľadu.'
  },
  Targeting: {
    Name: 'Analýza trhu',
    Title: 'Nový spôsob pohľadu na vašich klientov',
    Description:
      'Zobrazte svojich klientov na mape, analyzujte štruktúru portfólií svojej spoločnosti na viacerých úrovniach, vyhnite sa riziku. Prispôsobte a rozšírte si analýzu podľa svojich potrieb. Uložte si analýzy a zdieľajte ich s cieľom podporiť nové obchodné stratégie.'
  },
  Prospecting: {
    Name: 'Výber klientov',
    Title: 'Noví klienti jedným kliknutím',
    Description:
      'Vyhľadajte pre svoju firmu nových klientov, dostanete upozornenie na novú spoločnosť, ktorá zodpovedá vašim kritériám. Nájdite nové prospekty podobné vašim najlepším klientom, filtrujte portfólio partnerských spoločností a využite sémantické vyhľadávanie na vyhľadanie ďalšieho klienta.'
  },
  SalesTool: {
    Name: 'Obchodné aktivity',
    Title: 'Všetci vaši klienti po ruke',
    Description:
      'Vyberte si cieľ, identifikujte akcie a začnite obchodné kampane. Posilnite postavenie obchodnej siete poskytujúcej informácie s cieľom uzavrieť nové obchody v kancelárii alebo prostredníctvom mobilných telefónov.'
  },
  Api: {
    Name: 'API',
    Title: 'Obchodné informácie ako služba',
    Description:
      'Integrujte Margo údaje do svojich firemných nástrojov, aby ste u seba mali vždy najnovšie údaje svojich klientov. Automatizujte obohatenie svojich klientských portfólií o nové údaje a zodpovedzte otázky svojho podnikania pripojením údajov z Margo k vašim systémom Reportingu, CRM a Business Master Data. '
  }
}
