import { MiddlewareAPI, Dispatch } from 'redux'
import { RootState } from '../types'
import { ActionWithPayload } from '../../utils/types'
import { LoadStatus } from '../config/types'
import { getAppConfigStatus } from '../config/selectors'
import { getMatomoConsentValue } from '../usercentrics/utils'
import { Matomo } from './helpers'
import { ActionTrackingMap } from './tracking'

export const analyticsMiddleware = (tracking: ActionTrackingMap) => {
  // buffer used to cumulated all actions until app config is loaded
  let actionBuffer: ActionWithPayload<string, any>[] = []

  return (store: MiddlewareAPI<Dispatch, RootState>) =>
    (next: Dispatch) =>
    (action: ActionWithPayload<string, any>) => {
      next(action)

      const state = store.getState()
      const isConfigLoaded = getAppConfigStatus(state) === LoadStatus.LOADED

      const trackMatomo =
        !!(state.config.matomo?.siteId && state.config.matomo?.urlBase) &&
        getMatomoConsentValue(state.config.usercentricsSettingsId)

      // If Matomo tracker info is not provided in config or user deny consent then no tracking will occur
      if (isConfigLoaded && !trackMatomo) {
        actionBuffer = []
        return
      }

      actionBuffer.push(action)

      if (!isConfigLoaded) return

      // once app config is loaded, pass all buffered actions trough tracking
      while (actionBuffer.length) {
        const bufferedAction = actionBuffer.shift()!
        const trackingFunc = tracking[bufferedAction.type]
        if (trackingFunc) {
          if (trackMatomo) {
            Matomo.tracker?.pushInstruction('rememberConsentGiven')
          }
          try {
            trackingFunc(state, bufferedAction, !!trackMatomo)
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(`Tracking function [${bufferedAction.type}] crashed:`, e)
          }
        }
      }
    }
}
