/* eslint-disable import/no-default-export */
import account from './account'
import common from './common'
import dashboard from './dashboard'
import error from './error'
import footer from './footer'
import header from './header'
import login from './login'
import portfolio from './portfolio'
import salestool from './salestool'
import prospecting from './prospecting'
import targeting from './targeting'
import notification from './notification'
import contact from './contact'
import resetPassword from './resetPassword'
import onboarding from './onboarding'
import companyReport from './companyReport'
import personReport from './personReport'
import search from './search'
import api from './api'
import operations from './operations'
import appointment from './appointment'
import companyContacts from './companyContacts'

export default {
  account,
  common,
  dashboard,
  error,
  footer,
  header,
  login,
  portfolio,
  salestool,
  prospecting,
  targeting,
  notification,
  companyReport,
  personReport,
  contact,
  resetPassword,
  onboarding,
  search,
  api,
  operations,
  appointment,
  companyContacts
}
