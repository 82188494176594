import { LocalStorageKeys } from '@features/storage/types'
import { getParsedValueFromLocalStorage, saveStringifiedToLocalStorage } from '@features/storage/utils'
import { useEffect, useRef } from 'react'

export function usePrevious<T>(value: T, shouldUpdate = true, saveIntoLocalStorage = false) {
  const ref = useRef<T>()

  useEffect(() => {
    if (shouldUpdate) {
      ref.current = value
      if (saveIntoLocalStorage) saveStringifiedToLocalStorage(LocalStorageKeys.PREVIOUS_PATH, value)
    }
  }, [saveIntoLocalStorage, shouldUpdate, value])

  // Return previous value (happens before update in useEffect above)
  if (saveIntoLocalStorage) {
    return ref.current ?? getParsedValueFromLocalStorage(LocalStorageKeys.PREVIOUS_PATH, value)
  }
  return ref.current
}
