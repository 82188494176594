import { Key, SortOrder } from 'antd/es/table/interface'

export enum TableKey {
  CampaignArchive = 'CampaignArchive',
  Products = 'Products',
  TargetingChartDetail = 'TargetingChartDetail'
}

export enum PersistedStateKeys {
  OperationsListViewColumns = 'operationsListViewColumns',
  PortfolioListViewColumns = 'portfolioListViewColumns',
  ProspectingListViewColumns = 'prospectingListViewColumns',
  ProspectingSearchColumns = 'prospectingSearchColumns',
  SalestoolAssignCampaignColumns = 'salestoolAssignCampaignColumns',
  SalestoolTaskTableColumns = 'salestoolTaskTableColumns'
}

export type TableColumnSorting = { field?: Key | readonly Key[]; order?: SortOrder }
