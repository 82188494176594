/* eslint-disable import/no-default-export */
export default {
  Title: 'Résultats de la recherche',
  SaletoolTitle: 'Outil de vente - Résultats de la recherche Campagne',
  Search: 'Chercher',
  SearchPlaceholder: 'Rechercher',
  SearchCompaniesAdditionalInfo: 'Veuillez noter que pour les entreprises, vous pouvez également utiliser le',
  SearchCompaniesAdditionalInfoAutChe:
    'Veuillez noter que pour les entreprises autrichiennes, vous pouvez également utiliser le, Nom de la société, N° de TVA, Site web URL, Company Book number. Pour les suisses entreprises, vous pouvez également utiliser le N° de TVA, Numéro d’identification de l’entreprise, Nom de la société, Site web URL, Clé CH',
  NoResults: "Désolé, la recherche n'a donné aucun résultat pour",
  TryDifferent:
    'Essayer avec un autre mot clé ou utiliser la <0>Recherche sémantique</0> pour rechercher les termes connexes',
  TryDifferentWithoutProsp: 'Essayer avec un autre mot clé.',
  Banner: {
    title: 'Activer la section « Prospection » !',
    subtitle:
      'La recherche sémantique est uniquement disponible pour les utilisateurs qui ont acheté la section « Prospection ».',
    gotoAction: 'Contacter {{companyName}}'
  },
  Tabs: {
    Companies: 'Sociétés',
    Portfolios: 'Portefeuilles',
    Campaigns: 'Campagnes'
  },
  SearchInformationDialog: {
    Title: 'Que peut-on rechercher?',
    SubTitleWithCampaigns: 'Vous pouvez rechercher des entreprises, des portefeuilles ou des campagnes',
    SubTitle: 'Vous pouvez rechercher des entreprises ou des portefeuilles',
    CompaniesBy: '<strong>Entreprises</strong> par:',
    PortfoliosCampaignsHelpText: '<strong>Portefeuilles</strong> et <strong>campagnes</strong> par nom',
    PortfoliosHelpText: '<strong>Portefeuilles</strong> par nom',
    Companies: {
      CommercialDisplay: 'Affichage commercial',
      Swiss: {
        VatCode: 'N° de TVA',
        IdentificationNumber: 'Numéro d’identification de l’entreprise',
        CompanyName: 'Nom de la société',
        Website: 'Site web URL',
        LegacyCHNumber: 'Clé CH',
        CompanyBookNumber: 'Company Book number'
      }
    },
    Countries: {
      ch: 'SUISSE',
      at: "L'AUTRICHE"
    }
  },
  ShowLocalUnits: 'Afficher les unités locales',
  Company: 'Société',
  Portfolio: 'Portefeuille',
  Campaign: 'Campagne',
  GoTo: {
    Company: "Rapport d'enterprise",
    Portfolio: 'Gestion des portefeuilles',
    Campaign: 'Outil de vente'
  }
}
