/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  SalesTool: {
    Header: 'Obchodné aktivity',
    Management: {
      Assignee: 'Pridelené osoby',
      AssigneeSgCzsk: 'Pridelená osoba',
      Campaigns: 'Kampane',
      SearchCampaign: 'Vyhľadať kampaň',
      SearchCampaignLong: 'Vyhľadať názov kampane',
      NewCampaign: 'Vytvoriť kampaň',
      NewCampaignTooltip: 'Naplánujte si komerčnú kampaň vychádzajúcu z portfólia',
      CampaignArchive: 'Archív kampaní',
      CampaignArchiveTooltip: 'Otvorte archív ukončených kampaní a obnovte ich',
      CampaignsToAssign: 'Priradiť',
      CampaignsToStart: 'Zatiaľ nezačaté',
      CampaignsActive: 'Začaté',
      CampaignsArchived: 'Archivované',
      Stats: {
        Companies_0: 'Spoločnosť',
        Companies_1: 'Spoločnosti',
        Companies_2: 'Spoločností',
        StartDate: 'Dátum začiatku',
        EndDate: 'Dátum konca',
        EnrichedCompanies: 'Obohatené spoločnosti',
        Clients: 'Klienti',
        Suppliers: 'Dodávatelia',
        Prospects: 'Záujemcovia',
        LastUpdate: 'Posledná aktualizácia',
        Competitors: 'Konkurenti',
        Lead: 'Lead'
      },
      Table: {
        NumberOfCompanies: 'Počet spoločností',
        GoalName: 'Názov cieľa',
        Creator: 'Tvorca',
        Tasks: 'Úlohy',
        Status: 'Status',
        CampaignPortfolios: 'Portfólia v tejto kampani'
      },
      PorfolioBox: 'Portfólio v tejto kampani',
      ConversionRate: '{{percentage}}% konverzný pomer',
      CompletedTasks: '{{percentage}}% ukončených úloh',
      Banner: {
        Title: 'Pre priradenie k zoznamu musíte "kliknúť" na Názov kampane',
        Subtitle: 'Pred začatím kampane priraďte obchodníkov a produkty každej spoločnosti v zozname.'
      },
      CreatedAtAscending: 'Dátum vytvorenia (Vzostupne)',
      CreatedAtDescending: 'Dátum vytvorenia (Zostupne)',
      StartDateAscending: 'Dátum začiatku (Vzostupne)',
      StartDateDescending: 'Dátum začiatku (Zostupne)',
      EndDateAscending: 'Dátum konca (Vzostupne)',
      EndDateDescending: 'Dátum konca (Zostupne)'
    },
    AddCampaign: {
      EnterName: 'Vložte charakteristické meno kampane',
      EnterNameDescription: 'Použijete jeden kruh na jednu kampaň, ktorú vytvoríte',
      EnterDates: 'Vyberte dátum začiatku a konca',
      EnterDatesDescription:
        'Začiatočný a konečný dátum bude vždy zobrazený s kampaňou a pomôže vám dosiahnuť obchodné ciele',
      withoutCustomVariables: 'bez vlastných premenných',
      withCustomVariables: 's vlastnými premennými',
      EnterPortfolio: 'Vyberte portfólio, ktoré chcete pripojiť ku kampani',
      EnterPortfolioDescription: 'Vyberte jedno alebo viac portfólií ku kampani, ktorú ste práve vytvorili.',
      EnterGoal: 'Vyberte cieľ kampane',
      EnterGoalDescription: 'Vyberte cieľ pridelený k popísaným úlohám.',
      CreateGoalDescription: 'Kliknutím sem vytvoríte nový <0>Cieľ</0>.',
      InvalidGoal:
        'Zvolený cieľ nemá priradené žiadne úlohy. Prosím vyberte ďalší cieľ alebo kliknutím sem vytvoríte nový <0>Cieľ</0>.',
      EnterConversionRate: 'Vložte popis a nastavte konverzný pomer',
      EnterConversionRateDescription:
        'Vložte popis kampane, ktorú vytvárate. Dá vám viacej informácií o cieli, ktorý chcete dosiahnuť.',
      CampaignCreationPendingTitle: 'Vytvorenie kampane',
      CampaignCreationPendingText:
        'Vaša kampaň sa vytvára. Budete presmerovaný do Obchodných aktivít a po dokončení operácie dostanete notifikáciu.',
      SalesPerformanceChartTitle: 'Chcete, aby bol graf „Výkonnosť predaja“ viditeľný pre používateľov predaja?',
      SalesPerformanceChartText: 'Povoliť graf',
      RestoreDataQuestion: 'Chcete obnoviť všetky údaje súvisiace s touto kampaňou pred uplynutím jej platnosti?',
      RestoreDataEnable: 'Povoliť všetky predchádzajúce údaje',
      RestoreDataEnableDisabledTooltip:
        'Pôvodný cieľ pre túto kampaň nie je možné vybrať z dôvodu jeho odstránenia. Vyberte si nový z dostupných cieľov.',
      AssignedInformation:
        'Upozorňujeme, že v prípade, že manažér aj obchodník sú priradení k tej istej spoločnosti, v stĺpci príjemca kampane bude uvedený názov obchodníka'
    }
  },
  ManageCampaign: {
    Header: 'Management predaja a produktu',
    SalesHeader: 'Management kampane'
  },
  Management: {
    Message: {
      Header: 'Vytvorte kampaň, priraďte ju vašim obchodníkom a spustite ju',
      SubHeader: 'Pre vytvorenie kampane stlačte tlačidlo "Vytvoriť kampaň"',
      Description:
        'Po vytvorení kampane môžete priradiť spoločnosti obchodníkom a začať. Na tejto stránke nájdete zoznam všetkých vašich kampaní.'
    },
    Campaign: {
      ChangeCampaignName: 'Zmeniť názov kampane',
      ChangeCampaignNameShort: 'Zmeniť meno',
      ChangeCampaignNote: 'Zmeniť poznámku kampane',
      AddCampaignNote: 'Pridať poznámku',
      ChangeCampaignNoteDescription: 'Zmeniť poznámku k popisu konverzného pomeru.',
      AddCampaignNoteDescription: 'Vložiť poznámku k popisu konverzného pomeru.',
      EnterARepresentativeName: 'Vložiť zodpovedajúce meno pre kampaň',
      InsertCampaignName: 'Vložiť meno kampane',
      InsertCampaignNote: 'Vložiť poznámku',
      NameChangedCorrectly: 'Meno správne zmenené',
      NoteChangedCorrectly: 'Poznámka správne zmenená',
      NoteRemovedCorrectly: 'Poznámka bola odstránená správne',
      NoteAddedCorrectly: 'Poznámka pridaná správne',
      ChangeEndDate: 'Zmeniť dátum konca',
      ChangeEndDateDescription: 'Zmeniť aktuálny dátum ukončenia.',
      ChangeEndDateSuccess: 'bude ukončené dňa',
      ChangeStartDate: 'Zmeniť dátum začiatku',
      ChangeStartDateDescription: 'Zmeniť aktuálny dátum začiatku.',
      ChangeStartDateSuccess: 'sa začne',
      StartCampaignDialogText1: 'Chcete začať s touto kampaňou?',
      StartCampaignDialogText2: 'Vývoj celej kampane uvidíte v prehľade Obchodných aktivít.',
      StartCampaignDialogText3:
        'Po kliknutí na "pokračovať", dostane každý obchodník notifikáciu o "priradení spoločnosti".',
      StartCampaignDoneDialogText1: '{{campaignName}} začne {{startDate}}.',
      StartCampaignDoneDialogText2: 'Každý obchodník dostane notifikáciu o "priradení spoločnosti"',
      StopCampaignDialogText1: 'Chcete ukončiť túto kampaň?',
      StopCampaignDialogText2: 'Bude umiestená do časti "Archív kampane" a môžete ju kedykoľvek "Obnoviť".',
      StopCampaignDialogText3: 'Pre zobrazenie "Archív kampane" sa musíte vrátiť späť na dashboard.',
      StopCampaignDoneDialogText1: '{{campaignName}} sa ukončila korektne, nájdete ju v časti "Archív kampane".',
      DeleteCampaignDialogText1: 'Naozaj chcete zmazať {{campaignName}}?',
      DeleteCampaignDialogText2: 'Všetky výsledky kampane budú teraz zmazané.',
      DeleteCampaignDoneDialogText1: '{{campaignName}} vymazaná korektne.'
    }
  },
  AssignCampaign: {
    Header: 'Priradiť predaj a produkt',
    CreatedBy: 'Vytvoril {{ownerName}}',
    StartDate: 'Dátum začiatku:',
    EndDate: 'Dátum konca:',
    AddPortfolio: 'Pridajte spoločnosti/portfólio',
    AddPortfolioTooltip: 'Pridajte do tejto kampane nové spoločnosti',
    SelectColumns: 'Vybrať stĺpce',
    GroupActions: 'Skupinová akcia',
    SalestoolAssignmentsSelectPlaceholder: 'Vybrať',
    AssignSales: 'Priradiť predaj',
    AssignProduct: 'Priradiť produkt',
    NotAssigned: 'Nepriradený',
    Sales: 'Predaj',
    Product: 'Produkt',
    Note: 'Poznámky',
    EditNoteIconTitle: 'Edituj poznámku',
    CompaniesCount_0: 'Spoločnosť',
    CompaniesCount_1: 'Spoločnosti',
    CompaniesCount_2: 'Spoločností',
    AssignedCount_0: 'Priradená',
    AssignedCount_1: 'Priradené',
    AssignedCount_2: 'Priradených',
    NotAssignedCount_0: 'Nepriradená',
    NotAssignedCount_1: 'Nepriradené',
    NotAssignedCount_2: 'Nepriradených',
    AppAround: {
      NotAlreadyDone: 'Zatiaľ nebola odoslaná žiadna ponuka',
      InProgress: 'Ponuka bola predložená {{dateTime}}',
      Ko: 'Chyba v ponuke {{dateTime}}',
      Ok: 'Ponuka odoslaná {{dateTime}}'
    },
    Inventia: {
      NotAlreadyDone: 'Neboli iniciované žiadne hovory',
      InProgress: 'Hovor sa začal dňa {{dateTime}}',
      Ko: 'Chyba pri hovore, zapnuté {{dateTime}}',
      Ok: 'Hovor sa uskutočnil {{dateTime}}'
    },
    Banner: {
      Title: 'Kliknite na "Priradiť kampaň" na priradenie obchodníkov a produktov',
      Subtitle:
        'Pred začatím kampane priraďte obchodníkov a produkty ku všetkým spoločnostiam na zozname. Na konci kliknite na "Uložiť" pre dokončenie operácie, kampaň sa začne po stlačení "Začať kampaň".'
    },
    WriteYourNote: 'Tu píšte svoje poznámky',
    Dialog: {
      SelectAtLeastOneCompany: 'Musíte vybrať aspoň jednu spoločnosť.',
      AssignSaleTitle: 'Priradiť predaj',
      AssignProductTitle: 'Priradiť produkt',
      AssignSaleOrProductMultiple_0: '{{item}} priradený k {{count}} spoločnosti.',
      AssignSaleOrProductMultiple_1: '{{item}} príradený {{count}} spoločnostiam.',
      AssignSaleOrProductMultiple_2: '{{item}} príradený {{count}} spoločnostiam.',
      DuplicateCompanyTitle: 'Duplicitná spoločnosť',
      DuplicateCompanyConfirm: 'Naozaj chcete duplikovať {{companyName}}?',
      DuplicateCompanySelection: 'Pre duplikovanie spoločnosti je potrebné zmeniť jej status a produkt.',
      DuplicateCompanyDone: '{{companyName}} správne duplikovaná vo vnútri  {{campaignName}}.',
      DuplicateCompanySelectProduct: 'Vybrať produkt',
      DuplicateCompanySelectStatus: 'Vybrať status',
      DuplicateCompanyProduct: 'Produkt',
      DuplicateCompanyStatus: 'Status',
      SaveCampaignTitle: 'Uložiť kampaň',
      SaveCampaignPending: 'Ukladanie operácie pokračuje a potrvá niekoľko minút. Po dokončení dostanete notifikáciu.',
      AddCompaniesTitle: 'Pridávanie spoločností',
      AddPortfolioTitle: 'Pridávanie spoločností',
      AddPortfolio: 'Vybrať portfólio, ktoré chcete pridať.',
      AddPortfolioSelectPortfolio: 'Vybrať portfólio',
      AddCompaniesPending:
        'Prebieha pridávanie spoločností do kampane {{campaignName}}. Na konci operácie dostanete upozornenie.',
      AddCompaniesWithCustomVariablesStructureChangedPending:
        'Prebieha pridávanie spoločností do {{campaignName}}. Niektoré vlastné premenné nebudú vložené z dôvodu nesúladu štruktúry premenných. Na konci operácie dostanete upozornenie.',
      AddPortfolioPending:
        '{{selectedPortfolioName}} sa pridáva k {{campaignName}}. Po skončení operácie dostanete notifikáciu.',
      AddCompanyNoteTitle: 'Poznámky',
      AddCompanyNote: 'K tejto spoločnosti môžete pridať 5 poznámok.',
      AddCompanyNoteTextAreaLabel: 'Vložiť novú poznámku (max. 250 znakov)',
      AddCompanyNoteTextAreaPlaceholder: 'Vaša poznámka...',
      AddNewCompaniesFromStartingPortfolio: 'Pridajte nové spoločnosti z počiatočného portfólia',
      AddedCompanies: 'Pridané spoločnosti',
      AddedPortfolio: 'Pridané portfóliá',
      AddPortfolioSelect: 'Vybrať',
      DeleteNoteConfirmation: 'Naozaj chcete zmazať túto poznámku?',
      DeleteCompany: {
        Title: 'Vymazať spoločnosť',
        Text: 'Naozaj chcete odstrániť spoločnosť z kampane?',
        Success: 'Společnost byla úspěšně odstraněna z kampaně.',
        Error: 'Při odstraňování společnosti z kampaně došlo k chybě.'
      },
      MoveCompany: {
        Title: 'Presunúť spoločnosť do inej kampane',
        Text: 'Spoločnosť, ktorú ste vybrali, bude odstránená z aktuálnej kampane a pridaná do inej kampane. Vyberte jednu kampaň zo zoznamu nižšie.',
        SelectLabel: 'Vyberte kampaň',
        Footer: 'Obchodník a produkt priradený k spoločnosti budú vymazaní',
        Success: 'Spoločnosť bola úspešne presunutá do kampane.',
        Error: 'Pri presúvaní spoločnosti do kampane sa vyskytla chyba.',
        ErrorIncompatibleDestEntity: 'Štruktúra vlastných premenných v tejto kampani nie je kompatibilná s pôvodnou.'
      }
    },
    Errors: {
      DuplicateCampaign: 'Pri duplikácii spoločnosti sa vyskytla chyba.',
      DuplicateCampaignMissingPortfolio:
        'Spoločnosť nie je možné duplikovať, pretože portfólio, do ktorého patrí, už neexistuje.',
      AddPortfolio: 'Pri pridávaní vybratého portfólia sa vyskytla chyba.',
      AddCompanies: 'Pri pridávaní nových spoločností z počiatočného portfólia sa vyskytla chyba.'
    }
  },
  SalesPerformance: {
    Banner: {
      Title: 'Vaše predajné výsledky',
      Subtitle: 'budú viditeľné v tomto poli pre každú úlohu'
    },
    Tooltip: 'Toto pole ukazuje top 5 Pridelených osôb pre vybranú úlohu',
    Actions: 'Akcie',
    TotalProgressOrder: 'Celkový pokrok',
    LastUpdateOrder: 'Posledný update',
    NumberCompaniesOrder: ' Počer pripísaných spoločností'
  },
  CampaignDetail: {
    MapBoxTitle: 'Geografická distribúcia',
    CompaniesBoxTitle: 'Spoločnosti na úlohe',
    AssigneeBoxTitle: 'Výkonnosť pridelených osôb',
    NoteLabel: 'Poznámka',
    Task: 'Úloha',
    TasksStatisticsLabel_0: 'spoločnosť',
    TasksStatisticsLabel_1: 'spoločnosti',
    TasksStatisticsLabel_2: 'spoločností',
    AssignTask: 'Priradiť úlohu',
    SalesPerformanceNCompanies: 'N° spoločností',
    WaterfallChartTasksCompleted: 'dokončené',
    WaterfallChartBoxTitle_0: '{{count}} spoločnosť {{percentage}}% dokončená',
    WaterfallChartBoxTitle_1: '{{count}} spoločnosti {{percentage}}% dokončené',
    WaterfallChartBoxTitle_2: '{{count}} spoločností {{percentage}}% dokončených',
    AllCompaniesLabel: 'Všetko',
    AssignedCompaniesLabel: 'Priradené',
    UnassignedCompaniesLabel: 'Nepriradené',
    Dialog: {
      AssignTaskTitle: 'Priradiť úlohu',
      CampaignReceived: {
        Title: 'Prijatá kampaň',
        Text: 'Upozorňujeme, že túto kampaň ste dostali od {{ownerName}}.',
        SubText: 'Úlohy budete môcť upravovať len pre spoločnosti, ktoré vám boli pridelené.'
      }
    },
    ShowAssigneeDetail: 'Zobraziť detail Pridelené osoby'
  },
  CompaniesOnTask: {
    Void: 'Neplatná',
    Called: 'Kontaktovaná',
    Visited: 'Navštívená',
    Negotiation: 'Vyjednávanie',
    Active: 'Aktívna',
    Negative: 'Negatívna'
  },
  CompanyDetail: {
    Actions: {
      DownloadPDF: 'Stiahnuť PDF',
      AddToCampaign: 'Pridať ku kampani'
    }
  },
  CampaignArchive: {
    Subtitle:
      'Obsahuje zastavené a dokončené kampane. Po kliku na "Obnoviť kampaň" sa kampaň reštartuje s nastavením rovnakého pripísania.',
    SubtitleSale: 'Obsahuje zastavené a dokončené kampane.',
    RenewCampaign: 'Obnoviť kampaň',
    DeleteCampaign: 'Zmazať'
  },
  EnrichBeforeRenew: 'Before starting the campaign, please enrich the following portfolios:',
  SaleDetail: {
    Area: 'Oblasť',
    CampaignsView: 'Zobrazenie kampaní',
    Summary: 'Sumár',
    TotalCompanies: 'Spolu spoločností',
    TotalCampaigns: 'Spolu kampaní',
    ActiveCampaigns: 'Aktívne kampane',
    ArchivedCampaigns: 'Archivované kampane',
    CompletedCampaigns: 'Dokončené kampane',
    AssignTaskDialogText1: 'Chcete zmeniť úlohu?',
    AssignTaskDialogText1_noPrevTask: 'Chcete priradiť úlohu?',
    AssignTaskDialogText2: 'Meníte úlohu z "{{originalTask}}" na "{{newTask}}".',
    AssignTaskDialogText2_noPrevTask: 'Priraďujete "{{newTask}}".',
    AssignTaskDoneDialogText1: 'Úloha bola správne zmenená!',
    AssignTaskDoneDialogText1_noPrevTask: 'Úloha bola správne priradená!',
    AssignTaskDoneDialogText2: 'Váš nadriadený dostane notifikáciu.',
    AssignTaskMultipleDoneDialogText1: 'Vybrané úlohy sa priraďujú.',
    AssignTaskMultipleDoneDialogText2: 'Po dokončení dostane Váš nadriadený notifikáciu.',
    AssignTasksDialogText_0: 'Priraďujete "{{newTask}}" k {{fCount}} spoločnosti.',
    AssignTasksDialogText_1: 'Priraďujete "{{newTask}}" k {{fCount}} spoločnostiam.',
    AssignTasksDialogText_2: 'Priraďujete "{{newTask}}" k {{fCount}} spoločnostiam.',
    AssignTasksNotOwnerInfo: 'Zmenia sa iba spoločnosti, ktoré vám boli pridelené.'
  },
  ItemNotAssignedYet: 'Ešte nepriradený',
  LoadMore: 'Načítať viacej',
  DownloadBtn: 'Stiahnuť PDF/Excel',
  DownloadBtnTooltip: 'Stiahnite si prehľad tejto kampane do svojho zariadenia',
  AssignCampaignBtn: 'Priradiť kampaň',
  AssignCampaignBtnTooltip: 'Priraďte predajcov a produkty spoločnostiam v tejto kampani',
  ManageCampaignBtn: 'Riadiť kampaň',
  ManageCampaignBtnTooltip: 'Priraďte obchodníkov a produkty spoločnostiam v tejto kampani',
  StartCampaignBtn: 'Začať kampaň',
  StartCampaignBtnTooltip: 'Povoľte predajcom viditeľnosť kampane a monitorujte výkonnosť',
  StopCampaignBtn: 'Zastaviť kampaň',
  StopCampaignBtnTooltip: 'Ukončite túto kampaň a archivujte ju',
  DeleteCampaign: 'Zmazať kampaň',
  CampaignName: 'Meno kampane',
  StartDate: 'Začiatok kampane',
  EndDate: 'Koniec kampane',
  Name: 'Meno',
  PortfolioLabel: 'Portfólio',
  GoalLabel: 'Vybrať cieľ',
  ConversionRateLabel: 'Nastaviť percento konverzného pomeru',
  Close: 'Zatvoriť',
  Save: 'Uložiť',
  SaveTooltip: 'Uložte zmeny vykonané v tejto kampani',
  CreateCampaign: 'Vytvoriť kampaň',
  RenewCampaign: 'Obnoviť kampaň',
  DomainFilter: {
    SaveAndClose: 'Uložiť a zavrieť'
  },
  Table: {
    Empty: 'Nenašli sme žiadne spoločnosti, ktoré zodpovedajú Vášmu vyhľadávaniu'
  },
  Errors: {
    CampaignSave: 'Pri vytváraní kampane sa vyskytla chyba.'
  },
  GenericErrorTitle: 'Vyskytla sa chyba',
  Download: {
    PDFHeaderTitle: 'Report kampane',
    DownloadTitle: 'Stiahnuť PDF/Excel',
    DownloadPDF: 'Stiahnuť PDF',
    DownloadExcel: 'Stiahnuť Excel',
    SelectTypeOfList: 'Stiahnuť PDF/Excel',
    SelectTypeOfListDescription:
      'Môžete si stiahnuť kompletnú správu o kampani v dvoch rôznych formách, vyberte typ zoznamu, ktorý chcete stiahnuť.',
    CompaniesInCampaign: 'Spoločnosti v tejto kampani',
    AssignedCompanies: 'Priradené spoločnosti',
    ActiveCompanies: 'Spoločnosti sa stali "Aktívne"',
    Pdf: 'PDF',
    PdfDescription: 'Stiahnuť v .PDF formáte',
    Excel: 'Excel',
    ExcelDescription: 'Stiahnuť v .XlS formáte',
    DownloadExcelTitle:
      'Sťahujete súbor, ktorý obsahuje všetky {{companyName}} dáta a výsledky tejto kampane ako zoznam.',
    DownloadExcelDescription: 'Bude uložený v "Užívateľskej" časti vášho účtu.',
    DownloadPDFPleaseWait: 'Počkajte prosím. Váš pdf súbor sa generuje.',
    DownloadExcelPendingMessage:
      'Požadovaný súbor sa spracováva a môže to trvať niekoľko minút.\nProgres môžete sledovať v menu notifikácie.'
  },
  Actions: {
    GoToCampaign: 'Na kampaň'
  },
  CampaignStatus: {
    ToAssign: 'Priradiť',
    ToStart: 'Zatiaľ nezačaté',
    Active: 'Začaté',
    Stopped: 'Zastavené',
    Expired: 'Expirované'
  },
  EmptyCampaigns: 'Žiadna kampaň nenájdená'
}
