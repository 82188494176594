/* eslint-disable import/no-default-export */
export default {
  MainTitle: 'Firmenname',
  Actions: 'Aktionen',
  DownloadPDF: 'PDF herunterladen',
  AddToCampaign: ' Der Kampagne hinzufügen',
  CompanyData: {
    CompanyName: 'Firmenname',
    Title: 'Vorschau',
    Town: 'Stadt',
    OfficeClassification: 'Branchentyp',
    Ateco2007: 'Hauptbranche WZ2008',
    LegalForm: 'Rechtsform',
    LastUpdate: 'Letztes Update',
    TaxNumber: 'Steuernummer',
    VatNumber: 'Umsatzsteuer-Identifikationsnummer',
    ZipCity: 'Postleitzahl Stadt',
    OnrNumber: 'ONR Number',
    MainIndustrialSectors: 'Hauptbranchen'
  },
  CompanySummary: {
    Evaluation: 'Bewertung',
    Profile: 'Profil',
    ratingScore: 'Bonitätsindex',
    CompanyGroup: 'Unternehmensgruppe',
    PublicContracts: 'Public tenders',
    LocalUnits: 'Filialen',
    CompanyStatus: 'Firmenstatus',
    Title: 'Firmenübersicht',
    Address: 'Adresse',
    Town: 'Stadt',
    State: 'Bundesland',
    TaxNumber: 'Steuernummer',
    VatNumber: 'Umsatzsteuer-Identifikationsnummer',
    CrifNumber: 'CB Nummer',
    OnrNumber: 'ONR Number',
    CCIAAREA: 'CCIAA / REA',
    TotalNumberBranches: 'Anzahl Niederlassungen',
    TotalNumberLocalUnit: 'Anzahl der Filialen',
    DetailedLegalForm: 'Detaillierte Rechtsform',
    Ateco2007: 'Hauptbranche WZ2008',
    SIC: 'SIC',
    NACE: 'Branchen NACE Code',
    StartDate: 'Startdatum',
    IncorporationDate: 'Gründungsdatum',
    RegistrationCamComDate: 'Registrierungsdatum bei der Handelskammer',
    HoldingCompanyName: 'Name der Holdinggesellschaft',
    NationalParentCompany: 'Nationale Muttergesellschaft',
    TenderWon3y: 'Public tenders won (Last 3Y)',
    TenderParticipated3y: 'Public tenders participated (Last 3Y)',
    TenderValueWon3y: 'Public tenders value won  (Last 3Y)',
    TypeOffice: 'Niederlassungtyp',
    CountOffice: 'Anzahl Niederlassungen nach Typ',
    Turnover: 'Umsatz',
    GroupTurnover: 'Group Turnover',
    Estimated: 'Geschätzt',
    Profit: 'Ertrag',
    Employees: 'Mitarbeiter',
    Mol: 'Bruttobetriebsmarge',
    DetectionDate: 'Jahresabschlussdatum',
    Ebitda: 'EBITDA',
    Ebit: 'EBIT',
    GroupVatCode: 'Gruppen-Mehrwertsteuercode',
    MainIndustrialSectors: 'Hauptbranchen',
    SecondaryIndustrialSectors: 'Nebenbranche',
    StartOfBusiness: 'Geschäftsbeginn',
    DateOfFoundation: 'Gründungsdatum',
    TotalNumberOfVehicles: 'Anzahl Fuhrpark',
    NumberOfPassengerCars: 'Anzahl PKW',
    CarpoolNumberOfLorries: 'Anzahl LKW',
    BankInformation: 'Bank Informationen',
    ImportExport: 'Import/Export',
    Entrepreneurship: 'Unternehmertum',
    SoaCertification: 'SOA-Zertifizierung'
  },
  FinancialStatement: {
    Title: 'Finanzbericht',
    Subtitle: 'Hauptbudgetindikatoren für die letzten 3 Jahre',
    Text: 'Vollständige Details, Jahresabschlusspositionen stehen mit Download der vollständigen Liste zur Verfügung.',
    EconomicData: 'Wirtschaftsdaten',
    FinancialData: 'Finanzdaten',
    FinancialRatios: 'Budgetindikatoren',
    ProductionValue: 'Produktionswert',
    ProductionCosts: 'Produktionskosten',
    BalanceOfRevenues: 'Saldo aus Einnahmen und finanziellen Belastungen',
    ProfitLoss: 'Gewinn / Verlust',
    CashFlow: 'Cashflow',
    Assets: 'Vermögenswerte',
    TotalAssets: 'Bilanzsumme',
    FixedAssets: 'Anlagevermögen',
    TangAssets: 'Sachanlagen',
    CurrentAssets: 'Umlaufvermögen',
    TotalLiabilities: 'Verbindlichkeiten gesamt',
    Liabilities: 'Verbindlichkeiten',
    LiabilitiesAccruals: 'Passivabgrenzung',
    TotalLiabilitiesAndEquity: 'Summe der Passiva',
    ShareholdersEquity: 'Eigenkapital der Aktionäre',
    Provisions: 'Rückstellungen',
    DebtRatio: 'Verschuldungsquote',
    PFN: 'Nettofinanzanlage',
    TotalCredits: 'HABEN insgesamt',
    TotalDebts: 'Verbindlichkeiten insgesamt',
    NetWorth: 'Eigenkapital',
    EBITDA: 'EBITDA',
    ROE: 'ROE',
    ROI: 'ROI',
    ROS: 'ROS',
    TotalEmployees: 'Anzahl Mitarbeiter',
    Turnover: 'Umsatz',
    Profit: 'Erlös',
    RegisterCapital: 'Grundkapital',
    BalanceSheetRatios: 'Bilanzgrad',
    Sales: 'Verkaufserlöse',
    Cost: 'Kosten',
    FinancialAssets: 'Vermögenswerte',
    BalanceSheetProfitLost: 'Gewinn / Verlust (+/-)',
    Equity: 'Eigenkapital',
    LiquidityRatio: 'Liquiditätsgrad',
    ReturnOnTotalCapital: 'Kapitalrendite',
    BalanceSheetRatio: 'Bilanzrelationen',
    TurnoverAndTurnoverTrend: 'Umsatz und Umsatztrend',
    EmployeeEmployeetrend: 'Mitarbeiter & Mitarbeiter Trend',
    InvestmentAssets: 'Anlagevermögen',
    ImmaterialAssets: 'Immaterielle Vermögenswerte',
    PropertyEquipment: 'Immobilienausstattung',
    Inventories: 'Vorräte',
    ReceivablesAndOtherAssets: 'Forderungen und sonstige Vermögensgegenstände',
    CashInhandBalancesWithCreditInstitutions: 'Kassenbestand bei Kreditinstituten',
    FinancialInvestments: 'Finanzielle Investitionen',
    Securities: 'Wertpapiere',
    Accruals: 'Rückstellungen für Verbindlichkeiten',
    CapitalReserves: 'Kapitalrücklagen',
    ProfitReserves: 'Gewinnrücklagen',
    AccumulatedProfitLoss: 'Kumulierter Gewinnverlust',
    UntaxedReserves: 'Unversteuerte Reserven'
  },
  People: {
    Title: 'Personen',
    From: 'Ab',
    Amount: 'Menge',
    Exponents: 'Exponenten',
    Shareholders: 'Manager und Anteilseigner',
    Partners: 'Partnerunternehmen',
    Years: 'Jahre',
    OfficialSourceData: 'Daten aus offizieller Quelle',
    UnofficialSourceData: 'Daten aus inoffiziellen Quellen',
    DateOfBirth: 'Geburtsdatum',
    TypeOfConnection: 'Funktion',
    GoToLinkedin: 'Gehen Sie zu LinkedIn',
    Visualize: {
      PersonData: 'Personendaten visualisieren',
      CompanyData: 'Unternehmensdaten visualisieren'
    }
  },
  DecisionMakersShareholders: {
    Title: 'Entscheider und Anteilseigner',
    DecisionMakers: 'Entscheider',
    ShareholderCompanies: 'Anteilseigner (Unternehmen)',
    ShareholderPersons: 'Anteilseigner (Person)'
  },
  ContactsAndSocial: {
    Title: 'Contacts & Social',
    Social: 'Social',
    RepresentativeCompany: 'Repräsentative Firma',
    CertifiedMail: 'Zertifizierte E-Mail',
    PublicEmail: 'öffentliche E-Mail-Adresse',
    Website: 'Website URL',
    Fax: 'Fax',
    Telephone: 'Telefon',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Youtube: 'Youtube',
    Linkedin: 'LinkedIn',
    Pinterest: 'Pinterest',
    Vimeo: 'Vimeo',
    LoadMore: 'Mehr laden'
  },
  MyContacts: {
    Title: 'Meine Kontakte'
  },
  Analytics: {
    Title: 'Analytics',
    Seasonalit: 'Seasonality Score',
    Propensity: 'Neigungswert',
    AnalyticsMarketing: 'Analytics Marketing',
    AnalyticsFinance: 'Analytics Finance',
    AnalyticsMarketingMobile: 'Marketing ',
    AnalyticsFinanceMobile: 'Finance',
    SeasonalitWinter: 'Winter',
    SeasonalitSpring: 'Frühling',
    SeasonalitSummer: 'Sommer',
    SeasonalitAutumn: 'Herbst',
    PropensityShortTerm: 'kurzfristig',
    PropensityMiddTerm: 'mittelfristig',
    PropensityCarLeas: 'Autoleasing',
    PropensityInstrLeas: 'Geräteleasing',
    PropensityRealEstateLeas: 'Immobilienleasing',
    PropensityFactoring: 'Factoring',
    Internazionalization: 'Internationalization Score',
    Social: 'Social Score',
    DigitalAttitude: 'Digital attitude Score',
    Innovation: 'Innovation Score',
    Supplier: 'Supplier Score',
    EstimatedTurnover: 'voraussichtlicher Umsatz',
    AnalyticsMarketingTooltip: 'Analytics Marketing',
    AnalyticsFinanceTooltip: 'Analytics Finance',
    AnalyticsMarketingMobileTooltip: 'Marketing ',
    AnalyticsFinanceMobileTooltip: 'Finance',
    SeasonalitTooltip:
      'Identifiziert Unternehmen mit einer starken Saisonalität und kurzfristigen Kreditlinien. Nützlich zum Starten von Initiativen zur Unterstützung dieser Unternehmen, die von Natur aus einen volatilen Einsatz von Betriebskapital haben',
    PropensityTooltip:
      'Zeigt die Neigung eines Unternehmens an, ein Finanzprodukt zu kaufen: kurzfristiger Kredit, langfristiger Kredit, Factoring, Leasing (Auto, Ausstattung und Maschinen, Immobilien). Der Neigungswert jedes Produkts ist in 3 Stufen eingeteilt. (1 = hoch, 2 = mittel, 3 = niedrig)',
    InternazionalizationTooltip: 'Zeigt die Neigung von Unternehmen, Geschäftsbeziehungen im Ausland zu betreiben',
    SocialTooltip: 'Score social',
    DigitalAttitudeTooltip:
      'Bei mittelgroßen und kleinen Unternehmen zeigt der Score die Unternehmen, welche die größte Affinität zur Digitalisierung haben',
    InnovationTooltip:
      'Zeigt die Innovationsbereitschaft des Unternehmens. Es basiert auf der Analyse von Patenten, Marken, Zertifizierungen und Beteiligungen von Business Accelerators',
    SupplierTooltip: 'Lieferanten Score',
    EstimatedTurnoverTooltip:
      'Für Unternehmen ohne Jahresabschluss wurde eine Schätzung entwickelt, mit dem der jüngste Umsatz konstruiert werden kann.'
  },
  Mastercard: {
    Title: 'Handel',
    Business: 'Business',
    BusinessTooltip:
      'Zeigt den Unternehmensumsatz basierend auf einem 12-Monats-Trend. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    Competitiveness: 'Wettbewerbsfähigkeit',
    CompetitivenessTooltip:
      'Zeigt das Potenzial eines Unternehmens innerhalb des Wettbewerbumfeldes, in dem es tätig ist. "Hoch" bedeutet es, dass es zu den besten in seiner Branche und seinem geografischen Gebiet gehört. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    Digitalization: 'Digitalisierung',
    DigitalizationTooltip:
      'Zeigt den Entwicklungsstand der digitalen Kanäle eines Unternehmens an. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    Premium: 'Premium',
    PremiumTooltip:
      'Zeigt den Einfluss des Unternehmens auf konsumfreudige Kunden, im Vergleich zum Branchendurchschnitt. "Hoch" bedeutet, dass dieses Unternehmen eine höhere Anziehungskraft auf diese Kunden auswirkt, als vergleichbare Unternehmen. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    CommercialPotential: 'Kommerzielles Potenzial',
    CommercialPotentialTooltip:
      'Zeigt das kommerzielle Potenzial des geografischen Gebiets im Vergleich zum Branchendurchschnitt. Der Score reicht von 1 (hoch) bis 3 (niedrig).',
    CompetitionLevel: 'Wettbewerbsniveau',
    CompetitionLevelTooltip:
      'Zeigt die Dichte an Unternehmen derselben Branche im geografischen Gebiet, in dem das Unternehmen tätig ist. Der Score reicht von 1 (hoch) bis 3 (niedrig).'
  },
  Portfolio: {
    Title: 'Portfolio',
    NumberOfCompanies: 'Anzahl Unternehmen',
    CreationDate: 'Erstellungsdatum',
    LastUpdateDate: 'Letztes Update',
    AddToPortfolio: 'Zum Portfolio hinzufügen',
    SaveCompany: 'Firmen speichern',
    SaveOrCreate: 'Möchten Sie die Firmen einem vorhandenen Portfolio hinzufügen oder möchten Sie eine neue erstellen?',
    SaveCompanyDesc:
      'Die Firmen wird automatisch mit dem Status "Prospect" im Bereich "Portfolio Management" gespeichert'
  },
  SalesTransaction: {
    Title: 'Feedback von Zessionar',
    Name: 'Name',
    Product: 'Produkt',
    Assignee: 'Zessionar',
    Task: 'Aufgabe',
    startDate: 'Startdatum',
    EndDate: 'Enddatum',
    Notes: 'Anmerkungen'
  },
  UnlockView: {
    Title: 'Um diesen Abschnitt freizuschalten ordern Sie bitte die erforderlichen {{companyName}}-Daten zu',
    MissingPortfoliosTitle: 'Hier finden Sie alle Portfolios, in denen das Unternehmen präsent ist',
    MissingSalesTransactionsTitle: 'Hier finden Sie alle Kampagnen, in denen das Unternehmen vertreten ist',
    Subtitle: 'Sie können diesen Abschnitt entsperren, indem Sie {{companyName}}-Daten erwerben',
    Button: 'Entsperren Sie {{companyName}}-Daten',
    Tooltip: 'Bitte Nachricht eingeben...'
  },
  ModalWindow: {
    Notes: 'Anmerkungen',
    Title: 'Sie können bis zu fünf Anmerkungen dieser Firma hinzufügen',
    InsertNote: '  Neue Anmerkung einfügen (max. 250 Zeichen)',
    NewNote: 'Neue Anmerkung hier erfassen',
    Add: 'Hinzufügen',
    AreYouSure: 'Möchten Sie diese Anmerkung wirklich löschen?',
    Yes: 'Ja',
    No: 'Nein'
  },
  CompanyProfile: 'Unternehmensprofil',
  CompanyProfileMobile: 'Unternehmen',
  FinancialStatementSummary: 'Zusammenfassung des Jahresabschlusses',
  FinancialStatementMobile: 'Finanziell',
  Contacts: {
    Title: 'Kontakte'
  },
  News: 'News',
  Close: 'Schließen',
  LocalUnits: {
    Title: 'Filialen',
    StartDate: 'Anfangsdatum'
  },
  Branches: {
    Title: 'Niederlassung',
    CbNumber: 'ONR Nummer',
    Address: 'Adresse',
    Telephone: 'Telefon'
  },
  Score: {
    Title: 'Ergebnis benutzerdefiniert',
    Tabs: {
      Index: 'Bewertungsrisiko',
      Relevant: 'Relevante Ereignisse',
      Telco: 'Analytik Telco',
      Analytics: 'Analytics Utilities'
    }
  },
  Pnrr: {
    Title: 'PNRR',
    Headers: {
      Score: 'PNRR Score Investment',
      Mission: 'Mission',
      Component: 'Component',
      Scope: 'Scope',
      Investment: 'Investment'
    }
  },
  CompanyPopup: {
    Title: 'Systemnachricht',
    CompanyNotFound: 'Für das ausgewählte Unternehmen konnten keine Daten wiederhergestellt werden'
  },
  PersonPopup: {
    Title: 'Systemnachricht',
    CompanyNotFound: 'Für das ausgewählte Person konnten keine Daten wiederhergestellt werden'
  },
  PdfPopup: {
    Title: 'Vorgang bestätigen',
    TextEnriched:
      'Sobald die PDF-Datei erstellt ist, erhalten Sie eine Benachrichtigung und können diese herunterladen und speichern. Mit der Operation fortfahren?',
    TextNotEnriched:
      'Um das PDF herunterzuladen, muss zuerst das ausgewählte Unternehmen angereichert werden. Der Vorgang kann einige Minuten dauern. Bestätigen Sie, um fortzufahren.'
  },
  Appointments: {
    Title: 'Termine'
  },
  Esg: {
    Title: 'ESG',
    UnlockDate: 'ESG Entsperrdatum',
    Sector: {
      Title: 'ESG-Sektorbewertung'
    },
    Unlock: {
      Title: 'Schalten Sie ESG-Daten frei, um diesen Abschnitt anzuzeigen',
      Subtitle: 'Sie können diesen Abschnitt durch den Kauf von ESG-Daten freischalten',
      TitleNotAllowed: 'Leider ist dieser Abschnitt derzeit nicht verfügbar',
      SubtitleNotAllowed: 'Die zugehörigen Felder werden sichtbar, wenn ein Vorgesetzter sie entsperrt.',
      Button: 'Schalten Sie ESG-Daten frei'
    },
    Company: {
      Title: 'ESG Unternehmensbewertung',
      Environment: 'Umwelt',
      Social: 'Sozial',
      Governance: 'Governance',
      ESGScoreFactors: 'ESG-Score-Faktoren',
      ESGTransitionRisks: 'ESG Übergangsrisiken',
      ESGPhysicalRisks: 'Physische ESG-Risiken'
    },
    PhysicalRisk: {
      acuteRisks: 'Physische ESG-Risiken - Akute',
      chronicRisks: 'Physische ESG-Risiken - Chronische',
      combinedRisks: 'Physische ESG-Risiken - Kombinierte',
      earthquakeRisks: 'Physische ESG-Risiken - Erdbeben'
    },
    Tooltips: {
      emissions:
        'Es gibt die Umweltauswirkungen der Treibhausgasemissionen in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      energyEfficiency:
        'Es gibt den Grad der Energieeffizienz für Betriebsaktivitäten in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      waterUsage:
        'Es gibt den Grad der Nutzung von fließendem Wasser in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      wasteProduction:
        'Es gibt die Menge des erzeugten generischen oder gefährlichen Abfalls in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      biodiversity:
        'Es gibt den Grad der Maßnahmen in Naturgebieten in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      environmentalHazards:
        'Es gibt das physische Risiko für Naturkatastrophen in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      communitySociety:
        'Es gibt die sozialen Auswirkungen auf lokale Gemeinschaften in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      employeeRelationship:
        'Es gibt die Beziehungsqualität zu den Mitarbeitern in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      customerRelationship:
        'Es gibt die Beziehungsqualität mit Kunden in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      povertyFamine:
        'Es zeigt die Verpflichtung, wirtschaftlich benachteiligten Gruppen Chancen zu garantieren, in einer synthetischen Bewertung, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      humanRights:
        'Es zeigt die Verpflichtung zur Achtung der Menschenrechte in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      transparency:
        'Es gibt die Transparenz von ESG-Themen für Unternehmenspraktiken in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      ethicalConsiderations:
        'Es gibt die Transparenz von ESG-Themen für Unternehmenspraktiken auf einer synthetischen Skala an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      strategyRiskManagement:
        'Es gibt die Strategie zum Risikomanagement in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      inclusiveness:
        'Es gibt die Inklusivität für Frauen und junge Menschen in einer synthetischen Bewertung an, die von 1 (am besten) bis 3 (am schlechtesten) reicht.',
      revenueTransitionScore:
        'Es bietet eine Bewertung der potenziellen Auswirkungen der ESG-Umstellung auf den Umsatz. Sie reicht von 1 (positive Auswirkung) bis 5 (negative Auswirkung).',
      investmentsTransitionScore:
        'Es bietet eine Bewertung des zusätzlichen Investitionsbetrags, der für den ESG-Umstieg erforderlich ist. Der Wert reicht von 1 (begrenzter Anstieg) bis 5 (deutlicher Anstieg).',
      ebitdaTransitionScore:
        'Es bietet eine Bewertung der Auswirkungen des ESG-Umstiegs auf das EBITDA. Sie reicht von 1 (sehr positive Auswirkung) bis 5 (negative Auswirkung).',
      overallTransitionScore:
        'Es bietet eine Bewertung der Auswirkungen des ESG-Umstiegs aus finanzieller und wirtschaftlicher Sicht. Der Wert reicht von 1 (vernachlässigbare Auswirkung) bis 5 (große Auswirkung).',
      landslide:
        'Es gibt die Gefährdung durch Erdrutsche und Bodensenkungen an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      coldWave:
        'Es zeigt die Belastung durch die Gefahr von Kältewellen an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      drought:
        'Es gibt die Gefährdung durch Dürre an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      heatWaves:
        'Es gibt die Gefährdung durch Hitzewellen an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      heavyPrecipitation:
        'Sie gibt die Gefährdung durch Starkniederschläge an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      wildfire:
        'Es zeigt die Gefährdung durch Waldbrände an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      extremeWind:
        'Es zeigt die Gefährdung durch extremen Wind an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      flood:
        'Es gibt die Gefährdung durch Hochwasser an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      changingPrecipitation:
        'Sie gibt die Gefährdung durch wechselnde Niederschläge an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      changingTemperature:
        'Es zeigt die Exposition gegenüber dem Risiko von Temperaturschwankungen an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      changingWindPatterns:
        'Es zeigt die Gefährdung durch wechselnde Windverhältnisse an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      soilCoastalErosion:
        'Es gibt die Gefährdung durch Boden- und Küstenerosion an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      heatStress:
        'Es gibt die Gefährdung durch Hitzestress an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      permafrostThawing:
        'Es zeigt die Gefährdung durch das Auftauen des Permafrosts an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      seaLevelRise:
        'Es gibt die Gefährdung durch das Risiko eines Meeresspiegelanstiegs an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      soilDegradation:
        'Es gibt die Gefährdung durch Bodendegradation an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      waterStress:
        'Es gibt die Gefährdung durch Wasserstress an. Der Wert liegt zwischen 1 (geringes Risiko) und 10 (maximales Risiko).',
      earthquake:
        'Es gibt einen Gefährdungsgrad für Erdbebenrisiken an, wobei auch die örtlichen Einheiten (in EU27) und der Unternehmenssektor berücksichtigt werden. Sie reicht von 1 (geringstes Risikoniveau) bis 10 (höchstes Risikoniveau).',
      combinedChronic:
        'Es fasst alle chronischen Phänomene zusammen und drückt die geografische Gefahr und den erwarteten Schaden für das Unternehmen aus, wobei auch seine lokalen Einheiten (in EU27) und der Unternehmenssektor berücksichtigt werden. Sie reicht von 1 (geringstes Risikoniveau) bis 5 (höchstes Risikoniveau).',
      combinedAcute:
        'Es fasst alle akuten Phänomene zusammen und drückt die geografische Gefahr und den erwarteten Schaden für das Unternehmen aus, wobei auch seine lokalen Einheiten (in EU27) und der Unternehmenssektor berücksichtigt werden. Sie reicht von 1 (geringstes Risikoniveau) bis 5 (höchstes Risikoniveau).',
      combinedFull:
        'Es fasst alle Naturphänomene (chronisch, akut und Erdbeben) zusammen und drückt die geografische Gefahr und den erwarteten Schaden für das Unternehmen aus, wobei auch seine lokalen Einheiten (in EU27) und der Unternehmenssektor berücksichtigt werden. Sie reicht von 1 (geringstes Risikoniveau) bis 5 (höchstes Risikoniveau).',
      flagChronicAcute:
        'Es berücksichtigt die Exposition gegenüber mindestens einem signifikanten Risiko - chronisch und akut. Er kann den Wert 0 (keine Aufnahme) oder 1 (mindestens eine Aufnahme) annehmen.',
      scoreE:
        'Synthetischer Indikator des Unternehmens zur Komponente „Environment“. Die Bewertung reicht von 1 (am besten) bis 5 (am schlechtesten).',
      scoreS:
        'Synthetischer Indikator des Unternehmens über die Komponente „Social“. Die Bewertung reicht von 1 (am besten) bis 5 (am schlechtesten).',
      scoreG:
        'Synthetischer Indikator des Unternehmens über die Komponente „Governance“. Die Bewertung reicht von 1 (am besten) bis 5 (am schlechtesten).',
      scoreEsg:
        'Gesamtbewertung des Unternehmens hinsichtlich ESG-Indikatoren. Die Bewertung reicht von 1 (am besten) bis 5 (am schlechtesten).'
    },
    Table: {
      ScoreFactor: 'Score Faktor',
      Description: 'Beschreibung',
      Score: 'Score'
    }
  },
  UnlockCompanyEsgDataDialog: {
    Title: 'Schalten Sie den ESG-Score frei',
    SubTitle: 'Möchten Sie den ESG-Score für dieses Unternehmen freischalten?',
    UnlockText:
      'Die Daten werden entsperrt und wir halten sie ab dem Entsperrdatum sechs Monate lang oder bis zum Enddatum des Abonnements, falls früher, auf dem neuesten Stand. Wenn die Zeit für ESG-Daten dieses Unternehmens abläuft, müssen Sie diese erneut entsperren.',
    UnlockingText: 'Die Freischaltoperation läuft gerade, es könnte ein paar Sekunden dauern.',
    CompanyUnlockedText:
      'Die ESG-Bewertung wurde erfolgreich für dieses Unternehmen freigeschaltet. Sie können dieses Dialogfeld jetzt schließen.',
    CompanyNotUnlockedText:
      'Die ESG-Bewertung konnte aufgrund fehlender ESG-Daten für dieses Unternehmen nicht freigeschaltet werden.',
    ErrorUnlockingEsgData:
      'Beim Entsperren der ESG-Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
    InsufficientCredits: 'Sie haben nicht genügend Credits, um die ESG-Daten für dieses Unternehmen freizuschalten.'
  }
}
