import React from 'react'
import _ from 'lodash'
import { Card, CardProps } from 'antd'
import styled from 'styled-components/macro'
import { Title } from '../Text'
import { formatStyleSize, toInt } from '../../utils/helpers'

type StyleProps = {
  primary: number
  padding?: number | string
  progress?: number
}

export const StyledBox = styled(Card)<StyleProps>`
  &.ant-card {
    border: 1px solid ${({ theme }) => theme.colors.veryLightPink};
    border-top: 4px solid ${({ theme: { colors }, primary }) => (primary ? colors.main : colors.powderBlue)};
    border-radius: 4px;
    z-index: 1;

    ${({ progress, theme: { colors } }) =>
      progress &&
      `
        &:after {
          content: '';
          position: absolute;
          top: -4px;
          height: 4px;
          width: 100%;
          background: linear-gradient(60deg, ${colors.darkMint}, ${colors.pumpkinOrange}, ${colors.darkMint});
          border-radius: 4px 4px 0 0;
          z-index: -1;
          animation: gradientanimation 1s ease-in infinite;
          background-size: 300% 300%;
        }

        @keyframes gradientanimation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
        `}

    .ant-card-body {
      overflow: hidden;
    }

    .ant-card-head-title {
      line-height: 1;
      ${Title.Style} {
        color: ${({ theme: { colors }, primary }) => (primary ? colors.main : colors.dustyBlue)};
      }
    }

    ${({ padding }) => !_.isNil(padding) && `.ant-card-body { padding: ${formatStyleSize(padding)}; }`}
  }
`

export type BoxProps = {
  primary?: boolean
  centerTitle?: boolean
  padding?: number | string
  loadingBorder?: boolean
} & CardProps

export const Box = ({ primary, centerTitle, title, loadingBorder, ...props }: BoxProps) => (
  <StyledBox
    {...props}
    title={
      _.isString(title) ? (
        <Title level={3} center={centerTitle} ellipsis>
          {title}
        </Title>
      ) : (
        title
      )
    }
    primary={toInt(primary)}
    progress={toInt(loadingBorder)}
  />
)

Box.defaultProps = {
  primary: true
}
