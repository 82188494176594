import { Reducer } from 'redux'
import * as actions from './actions'
import { buildLoadingKey } from './utils'

export type LoadingState = Record<string, boolean>

export const initState: LoadingState = {}

export const loadingReducer: Reducer<LoadingState, actions.LoadingActions> = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_LOADING: {
      const { param, type, isLoading } = action.payload
      const key = buildLoadingKey(type, param)

      return {
        ...state,
        [key]: isLoading
      }
    }
    default:
      return state
  }
}
