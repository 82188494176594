import { Reducer } from 'redux'
import * as actions from './actions'

export type SaleDetailState = Readonly<{
  hiddenTaskPopupExpirationDate?: string
}>

export const saleDetailReducer: Reducer<SaleDetailState, actions.SaleDetailActions> = (state = {}, action) => {
  switch (action.type) {
    case actions.SET_HIDDEN_TASK_POPUP_EXPIRATION_DATE:
      return {
        ...state,
        hiddenTaskPopupExpirationDate: action.payload
      }
    default:
      return state
  }
}
