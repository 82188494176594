import axios from 'axios'
import {
  NotificationsResponse,
  PortfolioNotification,
  GetPortfolioNotificationsRequest,
  NotificationsRequest
} from './types'

export const getNotifications = ({ top, skip, read, type, sort }: NotificationsRequest) =>
  axios.get<NotificationsResponse>('/api/notifications', {
    params: {
      'Filter.Read': read,
      'Filter.Type': type,
      Top: top,
      Skip: skip,
      Sort: sort
    }
  })

export const getNumberOfNotifications = () => axios.get<{ number: number }>('/api/notifications/count')

export const putNotificationRead = (notificationId: string, isRead: boolean) =>
  axios.put<{ number: number }>(`/api/notifications/${notificationId}/read`, {
    read: isRead
  })

export const getPortfolioNotifications = (request: GetPortfolioNotificationsRequest) =>
  axios.post<PortfolioNotification[]>('/api/PortfolioNotifications/search', request).then(r => r.data)
