import axios from 'axios'
import i18nInstance, { i18n } from 'i18next'
import _ from 'lodash'
import { all, call, delay, put, select, takeEvery, takeLeading } from 'redux-saga/effects'
import { FREEMIUM_ROLE_CODE } from '@features/constants'
import { getVisitorId } from '@features/analytics/selectors'
import { queryClient } from '../queryclient'
import { fetchInstanceConfig, fetchSession } from '../../api/config'
import { UserResponse } from '../../api/types'
import { UserType, UserTypeKey } from '../../authorization'
import { PromisedType } from '../../types'
import { SignalR } from '../../utils/signalR'
import { Dictionary } from '../../utils/types'
import { CurrencyActions } from '../currencies'
import { NotificationActions } from '../notifications/actions'
import { User } from '../sharedTypes'
import { CHANGE_LANGUAGE, UserActions } from '../user/actions'
import { getSelectedCurrency, getSelectedLanguage, hasPortfolioManagementClaim, isLoggedIn } from '../user/selectors'
import { ConfigActions, HANDLE_USER_CONFIG, ON_APP_CONFIG } from './actions'
import { getBaseUrl, getLanguageMap, getMatomoConfig } from './selectors'
import { AppConfig, InstanceConfigDto, LoadStatus, LocalStorageConfigState } from './types'
import { generateRefreshUrl } from '../../utils/helpers'
import { Matomo } from '../analytics/helpers'

export const mapLanguage = (language: string, LanguageMap: Dictionary<string>) => LanguageMap[language] || language

export function* startSignalR() {
  const baseUrl: ReturnType<typeof getBaseUrl> = yield select(getBaseUrl)
  if (!baseUrl) return
  yield call(SignalR.start, baseUrl)
  yield put(ConfigActions.signalRStarted())
}

export const setBaseURL = (baseURL: string) => {
  axios.defaults.baseURL = baseURL
}

export const setLanguage = (i18nInst: i18n, lng: string) => {
  i18nInst.changeLanguage(lng)
  axios.defaults.headers['Accept-Language'] = lng
}

export function* onAppConfig(action: ReturnType<typeof ConfigActions.setAppConfig>) {
  const { url, default: def } = action.payload.config

  yield call(setBaseURL, url ?? '')
  const lang: ReturnType<typeof getSelectedLanguage> = yield select(getSelectedLanguage)
  const language = lang ?? def?.defaultLanguage ?? 'en-GB'
  yield call(setLanguage, i18nInstance, language)
  yield put(ConfigActions.setAppLanguage(language))
  yield put(ConfigActions.setAppConfig(action.payload.config))
}

const MAX_RELOAD_ATTEMPTS = 3

export function* handleLanguageChange(action: ReturnType<typeof UserActions.changeLanguage>) {
  const { lang, reload, location, navigate } = action.payload
  const isUserLogged: ReturnType<typeof isLoggedIn> = yield select(isLoggedIn)
  yield call(setLanguage, i18nInstance, lang)
  yield put(ConfigActions.setAppLanguage(lang))
  yield put(UserActions.setLanguage(lang))
  if (isUserLogged) yield put(CurrencyActions.fetchCurrencies())

  setTimeout(() => queryClient.resetQueries({ queryKey: [] }), 1)

  if (reload) {
    const { search, state, pathname } = location
    navigate(
      {
        search,
        pathname: generateRefreshUrl(pathname)
      },
      { replace: true, state }
    )
  }
}

const mapUser = (user: UserResponse): User => {
  return {
    ..._.omit(user, 'organization', 'userType'),
    userType: UserType[user.userType as UserTypeKey]
  }
}

export function* shouldRedirectToWizard(config: Record<string, LocalStorageConfigState>, userId: string) {
  const hasPortfolioManagement: boolean = yield select(hasPortfolioManagementClaim)
  const showPortfolioWizard = _.get(config, [userId, 'showPortfolioWizard'])
  return hasPortfolioManagement && showPortfolioWizard === undefined
}

export function* handleUserConfig(action: ReturnType<typeof ConfigActions.handleUserConfig>) {
  const { location, navigate } = action.payload
  for (let i = 0; i < MAX_RELOAD_ATTEMPTS; i += 1) {
    try {
      yield put(ConfigActions.setUserConfig({ userConfigLoadStatus: LoadStatus.LOADING }))
      let res: PromisedType<typeof fetchSession>
      try {
        res = yield call(fetchSession)
      } catch (error: any) {
        if (error.response?.status === 404) {
          yield put(UserActions.logout(false))
          return
        }
        throw error
      }

      const { language, hasUnreadNotification, instanceCode, user } = res.data
      const { currency } = user

      const selectedLanguage: ReturnType<typeof getSelectedLanguage> = yield select(getSelectedLanguage)
      const languageMap: ReturnType<typeof getLanguageMap> = yield select(getLanguageMap)
      yield call(
        handleLanguageChange,
        UserActions.changeLanguage(mapLanguage(selectedLanguage || language, languageMap), false, location, navigate)
      )

      const selectedCurrency: ReturnType<typeof getSelectedCurrency> = yield select(getSelectedCurrency)
      yield put(UserActions.changeCurrency(selectedCurrency ?? currency, false, location, navigate))
      yield put(
        UserActions.setUserData({
          hasPortfolio: res.data.hasPortfolio,
          user: mapUser(res.data.user),
          organization: res.data.user.organization,
          branch: res.data.branch,
          instanceCode,
          originatorUser: _.isUndefined(res.data.originatorUser) ? undefined : mapUser(res.data.originatorUser),
          subscription: {
            ..._.omit(res.data.subscription, 'subscriptionDetails'),
            details: res.data.subscription.subscriptionDetails[0]
          },
          extensionsManager: res.data.extensionsManager
        })
      )
      let instance: InstanceConfigDto = {}
      try {
        const userConfigResp: PromisedType<typeof fetchInstanceConfig> = yield call(fetchInstanceConfig, instanceCode)
        instance = userConfigResp.data

        // Notify other sagas
      } catch (error) {
        // we do not have to fail, because we have default configuration loaded already
        // eslint-disable-next-line no-console
        console.log(`Application config for instance ${res.data.user.organization.countryCode} does not exists`)
      } finally {
        yield put(
          ConfigActions.setUserConfig({
            userConfigLoadStatus: LoadStatus.LOADED,
            instance
          })
        )
      }

      const matomoConfig: AppConfig['matomo'] = yield select(getMatomoConfig)
      const isFreemiumUser =
        _.find(res.data.subscription.subscriptionRoles, { role: { code: FREEMIUM_ROLE_CODE } }) !== undefined

      const siteId = isFreemiumUser ? matomoConfig?.freemiumSiteId : matomoConfig?.siteId
      yield call(Matomo.initialize, {
        urlBase: matomoConfig?.urlBase,
        siteId
      })
      const visitorId: string = yield select(getVisitorId)

      if (isFreemiumUser) {
        Matomo.setCrossDomainLinking(matomoConfig?.trackedUrls)
        Matomo.setVisitorId(visitorId)
      } else {
        Matomo.setUserId(res.data.user.userCode)
      }

      yield put(UserActions.setAvailableRedirects(res.data.availableRedirects))
      yield put(UserActions.setConstraints(res.data.constraints))
      yield put(UserActions.setHasPortfolioExclusions(res.data.hasPortfolioExclusions))
      yield put(UserActions.setCustCompVisibility(res.data.customerCompanyVisibilityAccountEnabled))
      yield put(UserActions.setCustCompConfigurationApplied(res.data.customerCompanyConfigurationApplied))
      yield put(NotificationActions.setHasNewNotifications(hasUnreadNotification))

      yield call(startSignalR)
      yield put(UserActions.displayNewReleaseNotes({ filter: { notSeenOnly: true } }))
      return
    } catch (e: any) {
      if (i < MAX_RELOAD_ATTEMPTS - 1) {
        yield delay((i + 1) * 2000)
      } else {
        yield put(
          ConfigActions.setUserConfig({
            userConfigLoadStatus: e?.response?.status === 401 ? LoadStatus.NOT_LOADED : LoadStatus.FAILED
          })
        )
        throw e
      }
    }
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(ON_APP_CONFIG, onAppConfig),
    // takeLeading, because when handleUserConfig is using SignalR, and this need to be properly finished.
    takeLeading(HANDLE_USER_CONFIG, handleUserConfig),
    takeEvery(CHANGE_LANGUAGE, handleLanguageChange)
  ])
}
