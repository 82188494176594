import React, { useState } from 'react'
import { ProductFruitsTool } from '@components/ProductFruits'
import { AssistantAi } from '@components/Assistant'
import { useSelector } from 'react-redux'
import {
  getChatBotTestOrganizationCodes,
  getProductFruitsKey,
  getProvisionalProductFruitsKey
} from '@features/config/selectors'
import { getOrganizationId } from '@features/user/selectors'
import { useProductFruitsApi } from 'react-product-fruits'
import QuestionMark from '../../assets/icons/question-mark.svg?react'
import { AssistantButton, Close } from './styled'

export const FloatAssistant = () => {
  const productFruitsKey = useSelector(getProductFruitsKey)
  const provisionalProductFruitsKey = useSelector(getProvisionalProductFruitsKey)
  const enabledOrganizations = useSelector(getChatBotTestOrganizationCodes)
  const organizationId = useSelector(getOrganizationId)
  const isAssistantAiEnabled = enabledOrganizations?.includes(organizationId) || enabledOrganizations?.includes('*')

  const [isChatVisible, setIsChatVisible] = useState<boolean>(false)

  useProductFruitsApi(
    api => {
      if (isChatVisible && !api.button.isOpened() && isAssistantAiEnabled) {
        api.button.show()
        api.button.close()
      }
      if (!isChatVisible && isAssistantAiEnabled) {
        api.button.hide()
      }
      return () => {
        if (isAssistantAiEnabled && isChatVisible) {
          api.button.close()
        }
      }
    },
    [isChatVisible, isAssistantAiEnabled]
  )

  const openChat = () => {
    if (productFruitsKey || provisionalProductFruitsKey) setIsChatVisible(!isChatVisible)
  }

  const floatPosition = productFruitsKey || provisionalProductFruitsKey ? '90px' : '40px'
  const cardPosition = productFruitsKey || provisionalProductFruitsKey ? '100px' : '50px'

  return (
    <>
      {isAssistantAiEnabled && (
        <>
          <AssistantButton onClick={openChat}>{isChatVisible ? <Close /> : <QuestionMark />}</AssistantButton>
          {isChatVisible && <AssistantAi cardPosition={cardPosition} floatPosition={floatPosition} />}
          {isChatVisible && provisionalProductFruitsKey && (
            <ProductFruitsTool productFruitsKey={provisionalProductFruitsKey} />
          )}
        </>
      )}
      {!isAssistantAiEnabled && productFruitsKey && <ProductFruitsTool productFruitsKey={productFruitsKey} />}
    </>
  )
}
