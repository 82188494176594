export const CUSTOM_FILTERS_GROUP = 'customFilters'

export const MAP_LOCATION_FILTER = 'location'
export const SEMANTIC_CLUSTERS_FILTER = 'semanticClusters'

export const PORTFOLIO_IDS_SQL_FILTER = 'portfolioIds:sql-domain:portfolioIds'
export const PORTFOLIO_IDS_FILTER = 'portfolioIds'
export const CAMPAIGN_IDS_FILTER = 'campaignIds:sql-domain:campaignIds'
export const ENRICHED_BY_SUBSCRIPTIONS = 'enrichedBySubscriptions'
export const CONTACT_BY_ORGANIZATIONS = 'contactByOrganizations'

export const NOT_ASSIGNED_CODE = '_EMPTY_'

export const TEXT_FIELDS_DEBOUNCE_WAIT = 500

export const GROUP_USER_FILTERS = 'userFilters'

export const COMPANY_SEARCH_GROUP_ID = 'COMPANY_SEARCH'
