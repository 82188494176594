import _ from 'lodash'

export const getCompaniesCount = (
  numberOfCompaniesByFilters: number,
  groupAction: boolean,
  isSelectAll: boolean,
  selectedItems?: any[],
  deselectedItems?: any[]
) => {
  if (!groupAction) return numberOfCompaniesByFilters
  if (isSelectAll) return numberOfCompaniesByFilters - _.size(deselectedItems)

  return _.size(selectedItems)
}
