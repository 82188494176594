import { FilterStructureRequestDef, QueryOrderByOption } from '../../api/filters/types'
import { QueryType } from '../../types'
import { TreeKeySelection } from '../../utils/tree'
import { Dictionary } from '../../utils/types'
import { SemanticSearch } from '../filters/types'

export type MultiSelectionSetting = {
  key: string
  isMultiSelectionEnabled: boolean
}

// obsolete, do not use anymore
export type QueryDetail = {
  activeFilters?: Dictionary<any>
  activeFilterParams?: Dictionary<any>
  filterGroups?: Record<string, TreeKeySelection[]>
  excludedPortfolios?: string[]
  semanticSearch?: SemanticSearch
  selectedPortfolios?: string[]
  orderBy?: QueryOrderByOption[]
  multiSelectionSettings?: MultiSelectionSetting[]
}

export type Query = {
  id: string
  type: QueryType
  name: string
  createdAt?: Date
  lastUseDate?: Date
  isMonitored?: boolean
  associatedEntityId?: string
  query: QueryDetail
}

export type FilterRequestWithName = {
  filterRequest: FilterStructureRequestDef
  filterName: string
  portfolioIds?: string[]
}

export const queryTypes: QueryType[] = [
  QueryType.ProspectingQueries,
  QueryType.TargetingQueries,
  QueryType.PortfolioQueries,
  QueryType.MonitoredQueries
]
