export enum EnrichPersonDialogView {
  Init,
  Recap,
  Fail
}

export type EnrichPersonDialogData = {
  view: EnrichPersonDialogView
  personId: string
  redirectTo?: string
}
