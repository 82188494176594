import _ from 'lodash'
import styled from 'styled-components/macro'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../components/Button'
import Triangle from '../../assets/icons/triangle-down.svg?react'
import { UserActions } from '../../features/user/actions'
import { getAvailableLanguages } from '../../features/config/selectors'

export const StyledTriangleIcon = styled(Triangle).withConfig<{ primary?: boolean }>({
  shouldForwardProp: p => p !== 'primary'
})`
  width: 8px;
  height: 9px;
  display: inline-block;
  margin-left: 8px;
  fill: ${({ primary, theme }) => (primary ? theme.colors.white : theme.colors.main)};
`

export const StyledLanguageButton = styled(Button).withConfig<{ primary?: boolean }>({
  shouldForwardProp: p => p !== 'primary'
})`
  flex: 0 0 auto;
  align-items: center;
  &&.ant-btn {
    background-color: transparent;
    color: ${({ primary, theme }) => (primary ? theme.colors.white : theme.colors.main)};
    :hover,
    :focus {
      background-color: transparent;
      color: ${({ primary, theme }) => (primary ? theme.colors.white : theme.colors.main)};
    }
  }
`

type Props = {
  primary?: boolean
  className?: string
}

export const LanguageSwitch = ({ primary, className }: Props) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const availableLanguages = useSelector(getAvailableLanguages)

  const currentLang = availableLanguages[i18n.language]?.short
  const menuItems = _.map(availableLanguages, (value, key) => ({ key, label: value.short }))

  return (
    <Dropdown
      overlayClassName="language-switch"
      className={className}
      menu={{
        items: menuItems,
        onClick: ({ key }) => dispatch(UserActions.changeLanguage(key, true, location, navigate))
      }}
      trigger={['click', 'hover']}
    >
      <StyledLanguageButton
        ghost={primary}
        primary={primary}
        type={primary ? 'primary' : 'link'}
        textSize="S"
        iconRight={<StyledTriangleIcon primary={primary} />}
      >
        {currentLang}
      </StyledLanguageButton>
    </Dropdown>
  )
}
