import { useQuery } from '@tanstack/react-query'
import { cacheOneMinute } from '@features/queryclient'
import { ApplicationModule } from '../../../types'
import { apiCallWithCancel } from '../../../utils/helpers'
import { getInfoBoxData, getReportCampaigns, getReportPortfolios } from './api'

export const companyReportKeys = {
  all: () => [{ level1: 'companyReport' }] as const,

  portfoliosAll: () => [{ ...companyReportKeys.all()[0], level2: 'report-portfolios' }] as const,
  portfoliosForCompany: (identificationCode: string, companyUnitId: string) =>
    [{ ...companyReportKeys.portfoliosAll()[0], identificationCode, companyUnitId }] as const,

  campaignsAll: () => [{ ...companyReportKeys.all()[0], level2: 'report-campaigns' }] as const,
  campaignsForCompany: (identificationCode: string, companyUnitId: string) =>
    [{ ...companyReportKeys.campaignsAll()[0], identificationCode, companyUnitId }] as const,

  companyUnitIdAll: () => [{ ...companyReportKeys.all()[0], level2: 'loadCompanyUnitId' }] as const,
  companyUnitIdForCompany: (identificationCode: string, companyUnitId: string | undefined) =>
    [{ ...companyReportKeys.companyUnitIdAll()[0], identificationCode, companyUnitId }] as const,

  reportDataAll: () => [{ ...companyReportKeys.all()[0], level2: 'getCompanyReportData' }] as const,
  reportDataForCompany: (identificationCode: string, companyUnitId: string) =>
    [{ ...companyReportKeys.reportDataAll()[0], identificationCode, companyUnitId }] as const,
  reportDataForCompanyIdentificationCode: (identificationCode: string) =>
    [{ ...companyReportKeys.reportDataAll()[0], identificationCode }] as const,
  reportDataForCompanyWithModule: (
    identificationCode: string,
    companyUnitId: string,
    entityItemId: string | undefined,
    module: ApplicationModule | undefined
  ) =>
    [
      {
        ...companyReportKeys.reportDataForCompany(identificationCode, companyUnitId)[0],
        entityItemId,
        module
      }
    ] as const,

  localUnitsAll: () => [{ ...companyReportKeys.all()[0], level2: 'getLocalUnits' }] as const,
  localUnitsForCompany: (identificationCode: string, companyUnitId: string) =>
    [{ ...companyReportKeys.localUnitsAll()[0], identificationCode, companyUnitId }] as const,
  localUnitsForCompanyWithPageSize: (
    identificationCode: string,
    companyUnitId: string,
    pageSize: number,
    entityItemId: string | undefined,
    module: ApplicationModule | undefined
  ) =>
    [
      {
        ...companyReportKeys.localUnitsForCompany(identificationCode, companyUnitId)[0],
        pageSize,
        entityItemId,
        module
      }
    ] as const,
  reportPeople: (taxCode: string) => [{ ...companyReportKeys.all()[0], taxCode, level2: 'getPeopleData' }] as const
}

export const useReportPortfolios = (identificationCode: string, companyUnitId: string) =>
  useQuery({
    queryKey: companyReportKeys.portfoliosForCompany(identificationCode, companyUnitId),
    queryFn: ({ queryKey: [queryKeys] }) =>
      apiCallWithCancel(token =>
        getReportPortfolios(queryKeys.identificationCode, queryKeys.companyUnitId, token).then(res => res.data)
      ),
    ...cacheOneMinute
  })

export const useReportCampaigns = (identificationCode: string, companyUnitId: string) =>
  useQuery({
    queryKey: companyReportKeys.campaignsForCompany(identificationCode, companyUnitId),
    queryFn: ({ queryKey: [queryKeys] }) =>
      apiCallWithCancel(token =>
        getReportCampaigns(queryKeys.identificationCode, queryKeys.companyUnitId, token).then(res => res.data)
      ),
    ...cacheOneMinute
  })

export const useLoadCompanyUnitId = (identificationCode: string, currentCompanyUnitId: string | undefined) =>
  useQuery({
    queryKey: companyReportKeys.companyUnitIdForCompany(identificationCode, currentCompanyUnitId),
    queryFn: ({ queryKey: [queryKeys] }) => {
      if (queryKeys.companyUnitId !== undefined) {
        return queryKeys.companyUnitId
      }
      return apiCallWithCancel(token =>
        getInfoBoxData(queryKeys.identificationCode, token).then(res => res.data.companyUnitId?.value)
      )
    }
  })
