import { Component } from 'react'
import _ from 'lodash'

type Event = 'mousemove' | 'mousedown' | 'keydown' | 'touchstart' | 'scroll'

type Props = {
  render: (inactive: boolean) => JSX.Element
  onChange?: (inactive: boolean) => void
  timeout?: number
  events?: Event[]
}

type State = {
  inactive: boolean
}

export class Idle extends Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    timeout: 5000,
    events: ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll']
  }

  state = {
    inactive: true
  }

  time: number | undefined = undefined

  componentDidMount() {
    const { events } = this.props
    _.forEach(events, event => {
      window.addEventListener(event, this.handleEvent)
    })
  }

  componentWillUnmount() {
    const { events } = this.props
    _.forEach(events, event => {
      window.removeEventListener(event, this.handleEvent)
    })
  }

  handleChange = (inactive: boolean) => {
    const { onChange } = this.props
    if (onChange) onChange(inactive)

    this.setState({ inactive })
  }

  handleEvent = () => {
    const { timeout } = this.props
    const { inactive } = this.state
    if (inactive) {
      this.handleChange(false)
    }
    clearTimeout(this.time)
    this.time = window.setTimeout(() => {
      this.handleChange(true)
    }, timeout)
  }

  render() {
    const { render } = this.props
    const { inactive } = this.state
    return render(inactive)
  }
}
