import { Button } from 'antd'
import styled from 'styled-components/macro'

export const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`

export const RateButton = styled(Button)<{ color: string }>`
  padding-right: 5px;
  padding-left: 5px;
  font-size: 10px;
  height: 25%;
  border-right: none;
  border-left: none;
  background-color: ${props => props.color};

  &:last-child {
    border-right: 1px solid #ddd;
  }

  &:first-child {
    border-left: 1px solid #ddd;
  }

  margin: 0;
  border-radius: 0;
`

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  top: -40px;
  position: relative;
  left: 115px;
  visibility: hidden;
  z-index: 46000;
  width: 160px;
`
