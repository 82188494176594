import { useSelector } from 'react-redux'
import React from 'react'
import { RootState } from '../features/types'
import { getBrandingConfig } from '../features/config/selectors'

export function useBranding() {
  const configSelector = React.useCallback((state: RootState) => getBrandingConfig(state), [])
  const config = useSelector(configSelector)
  return config
}
