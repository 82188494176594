/* eslint-disable import/no-default-export */
export default {
  Welcome: {
    Name: 'Benvenuto',
    Title: 'Margò ti da il benvenuto',
    Description:
      'Margò è la nuova piattaforma di marketing intelligence di {{companyName}}, studiata per dare supporto attivo alla crescita del tuo business, ricca di una vasta gamma di dati, certi, completi e di qualità. Reattiva per rispondere ai tuoi bisogni e Intelligente per prevedere le tue esigenze. Lo strumento per gestire i processi commerciali e di marketing dall’inizio alla fine.'
  },
  PortfolioManagement: {
    Name: 'Portafoglio Imprese',
    Title: 'Semplifica la gestione delle informazioni sui tuoi clienti',
    Description:
      'Crea liste di imprese, aggiorna le anagrafiche con dati ufficiali, arricchisci il tuo database clienti con nuove informazioni e guarda al tuo mercato da un punto di vista inedito.'
  },
  Targeting: {
    Name: 'Analizza Mercato',
    Title: 'Un nuovo modo di guardare ai tuoi clienti',
    Description:
      "Visualizza i tuoi clienti su mappa, analizza la composizione dei tuoi portafogli di imprese su più dimensioni, schiva il rischio. Personalizza e amplia l'analisi in base alle tue esigenze. Salva le tue analisi e condividile a supporto di nuove strategie di business."
  },
  Prospecting: {
    Name: 'Trova Clienti',
    Title: 'Nuovi clienti in un click',
    Description:
      'Ricerca nuovi clienti per il tuo business, ricevi una notifica quando una nuova impresa risponde ai tuoi criteri. Scopri nuovi prospect simili ai tuoi migliori clienti, filtra portafogli di imprese partner e sfrutta l’ampiezza della ricerca semantica per trovare la strada verso il tuo prossimo cliente.'
  },
  SalesTool: {
    Name: 'Gestione vendite',
    Title: 'I tuoi clienti a portata di mano',
    Description:
      'Seleziona l’obiettivo, identifica le azioni e avvia campagne commerciali. Potenzia la rete commerciale fornendo dati chiave per la chiusura di nuovi contratti in ufficio o in mobilità. Gestisci lo stato di avanzamento delle negoziazioni e ottimizza le performance.'
  },
  Api: {
    Name: 'API',
    Title: 'Business Information As-a-service',
    Description:
      'Unisci il patrimonio informativo di Margò alla familiarità dei tuoi sistemi aziendali per avere dati aggiornati in tempo reale sui tuoi clienti. Automatizza l’arricchimento dei tuoi portafogli clienti e rispondi alle domande del tuo business connettendo i dati di Margò ai tuoi sistemi di Reporting, CRM e Anagrafica aziendale.'
  }
}
