// eslint-disable-next-line import/no-default-export
export default {
  LeadSection: {
    Title: 'Súbor nástrojov pre úspech v predaji',
    Text: 'Margo API ponúka jedinečný súbor údajov o registrovaných spoločnostiach na podporu vášho obchodného rastu a automatizácie CRM. Využíva širokú škálu dátových paketov dostupných na prístup k oficiálnym informáciám, ako sú údaje o zamestnancoch a obrate, adresy spoločností, kontakty a pokročilé analýzy. Vylepšite svoje CRM a klientské databázy najnovšími informáciami o analýze trhov, hľadaní nových perspektív a obchodných príležitostí.'
  },
  MiddleSection: {
    Title: 'Margo API v skratke',
    ItemCompanies: 'Údaje 6,5 milióna českých a slovenských spoločností aktualizované denne',
    ItemAnalytics: '40+ pokročilých analýz a ukazovateľov',
    ItemFilters: '300+ filtrov na výber a zhlukovanie spoločností',
    ItemPackets: '130+ dátových balíkov na prístup k tematickým údajom spoločností'
  },
  BottomSection: {
    Title: 'Uvoľnite potenciál svojho podnikania, zefektívnite organizáciu predaja a automatizujte manuálne procesy',
    AutomationTitle: 'CRM automatizácia',
    AutomationText:
      'Integrujte údaje Margo do svojho CRM, aby bola vaša databáza aktuálna s oficiálnymi registrami a aby ste poskytovali príslušné informácie svojmu predajnému tímu.',
    MarketAnalysisTitle: 'Analýza trhu a AI',
    MarketAnalysisText:
      'Pripojte svoje business intelligence systémy k Margo a obohaťte svoje údaje o zákazníckej základni. Vylepšite svoje AI modely novými dátami.',
    EnrichmentTitle: 'Obohatenie údajov a targeting',
    EnrichmentText:
      'Vytvorte cielené podnikové klastre obohatením údajov o zákazníckej základni o najnovšie dostupné informácie o spoločnosti.',
    BetterDataTitle: 'Lepšie údaje o vašich potenciálnych zákazníkoch',
    BetterDataText:
      'Vylepšite svoje predajné kampane o údaje, ktoré pomôžu vášmu obchodnému tímu rýchlejšie vyhodnotiť spoločnosti a uzatvárať obchody.',
    ProspectingTitle: 'Výber klientov',
    ProspectingText:
      'Objavte nové medzery na trhu a nájdite najlepšie vyhliadky pre svoje výrobky, importujte ich do svojich systémov a začnite predaj.',
    MarketingTitle: 'Marketingová analytika',
    MarketingText:
      'Nájdite spoločnosti, ktoré podnikajú v medzinárodnom obchode, inovujú alebo majú vysokú digitalizáciu. Zobrazte odhady obratu MSP spoločností.',
    FinanceTitle: 'Finančná analytika',
    FinanceText:
      'Vyberte spoločnosti, ktoré majú vysoký sklon predplatiť si rôzne finančné produkty a zistite sezónnosť ich finančných potrieb.',
    OnboardingTitle: 'Onboarding and e-fakturácia',
    OnboardingText:
      'Vytvárajte bezstarostne registračné formuláre pre svoje B2B podnikanie a využite údaje z Marga na zefektívnenie administratívnych úloh.'
  },
  LearnMore: 'Dozvedieť sa viac'
}
