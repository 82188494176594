// eslint-disable-next-line import/no-default-export
export default {
  Close: 'Chiudi',
  Popup: {
    TaxCode: 'Codice fiscale',
    BirthDate: 'Data di nascita',
    BirthTown: 'Comune di nascita',
    Enriched: 'Arricchito',
    Enrich: 'Arricchire'
  },
  NotEnriched: {
    Title: 'Acquista dati {{companyName}} per sbloccare questa sezione',
    Message: 'Puoi sbloccare questa sezione, acquistando dati {{companyName}}',
    Action: 'Sblocca dati {{companyName}}'
  },
  IdentificationData: {
    Title: 'Sintesi',
    TaxCode: 'Codice fiscale',
    BirthDate: 'Data di nascita',
    BirthPlace: 'Comune di nascita',
    BirthCountry: 'Stato di nascita',
    Sex: 'Sesso',
    Address: 'Residenza'
  },
  Positions: {
    Title: 'Cariche/Qualifiche',
    ActivePositions: {
      Title: 'Cariche attive'
    },
    PastPositions: {
      Title: 'Cariche cessate'
    },
    From: 'Dal',
    To: 'A'
  },
  PartnersList: {
    Title: 'Partecipazioni',
    Amount: 'Valore'
  },
  Enrich: {
    Title: 'Arricchisci la persona',
    Desc: 'Arricchendo la persona con i dati {{companyName}}, queste informazioni verranno scaricate e aggiornate periodicamente',
    Success: 'Arricchimento completato',
    CreditsWillBeUsed: 'Rings usati',
    CreditsRemaining: 'Rings residui',
    ProposalFailed: 'Si è verificato un problema durante la verifica dei crediti disponibili per questa operazione.'
  }
}
