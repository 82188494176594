import { ActionsUnion } from '../../utils/types'
import { createAction } from '../../utils/redux'
import { Notification, PushEvent } from './types'

export const FETCH_NOTIFICATIONS = 'notification/FETCH_NOTIFICATIONS'
export const SET_NOTIFICATIONS = 'notification/SET_NOTIFICATIONS'
export const SET_LAST_NOTIFICATION_ID = 'notification/SET_LAST_NOTIFICATION_ID'
export const ADD_OLD_NOTIFICATIONS = 'notification/ADD_OLD_NOTIFICATIONS'
export const ADD_NEW_NOTIFICATIONS = 'notification/ADD_NEW_NOTIFICATIONS'
export const SET_HAS_NEW_NOTIFICATIONS = 'notification/SET_HAS_NEW_NOTIFICATIONS'
export const FETCH_NUMBER_OF_NOTIFICATIONS = 'notification/FETCH_NUMBER_OF_NOTIFICATIONS'
export const SET_NUMBER_OF_NOTIFICATIONS = 'notification/SET_NUMBER_OF_NOTIFICATIONS'
export const SET_NUMBER_OF_NOTIFICATIONS_TO_DISPLAY = 'notification/SET_NUMBER_OF_NOTIFICATIONS_TO_DISPLAY'
export const FETCH_SET_NOTIFICATION_READ = 'notification/FETCH_SET_NOTIFICATION_READ'
export const SET_NOTIFICATION_READ = 'notification/SET_NOTIFICATION_READ'
export const UPDATE_PUSH_EVENTS = 'notification/UPDATE_PUSH_EVENTS'
export const REMOVE_PUSH_EVENTS = 'notification/REMOVE_PUSH_EVENTS'
export const RESET_NOTIFICATIONS = 'notification/RESET_NOTIFICATIONS'

export const NotificationActions = {
  fetchNotifications: (numberOfNotifications: number) => createAction(FETCH_NOTIFICATIONS, { numberOfNotifications }),
  setNotifications: (notifications: Notification[]) => createAction(SET_NOTIFICATIONS, { notifications }),
  setLastNotificationId: (notificationId: string) => createAction(SET_LAST_NOTIFICATION_ID, { notificationId }),
  addOldNotifications: (notifications: Notification[]) => createAction(ADD_OLD_NOTIFICATIONS, { notifications }),
  addNewNotifications: (notifications: Notification[]) => createAction(ADD_NEW_NOTIFICATIONS, { notifications }),
  setHasNewNotifications: (hasNewNotifications: boolean) =>
    createAction(SET_HAS_NEW_NOTIFICATIONS, { hasNewNotifications }),
  fetchNumberOfNotifications: () => createAction(FETCH_NUMBER_OF_NOTIFICATIONS),
  setNumberOfNotifications: (number: number) => createAction(SET_NUMBER_OF_NOTIFICATIONS, { number }),
  setNumberOfNotificationsToDisplay: (number: number) =>
    createAction(SET_NUMBER_OF_NOTIFICATIONS_TO_DISPLAY, { number }),
  fetchSetNotificationRead: (notificationId: string, isRead: boolean, updateStore: boolean) =>
    createAction(FETCH_SET_NOTIFICATION_READ, { notificationId, isRead, updateStore }),
  setNotificationRead: (notificationId: string, isRead: boolean) =>
    createAction(SET_NOTIFICATION_READ, { notificationId, isRead }),
  updatePushEvents: (pushEvents: PushEvent[]) => createAction(UPDATE_PUSH_EVENTS, { pushEvents }),
  removePushEvents: (pushEvents: PushEvent[]) => createAction(REMOVE_PUSH_EVENTS, { pushEvents }),
  reset: () => createAction(RESET_NOTIFICATIONS)
}

export type NotificationActions = ActionsUnion<typeof NotificationActions>
