/* eslint-disable no-continue */
import _ from 'lodash'
import { FieldExpression } from '@api/expressionTypes'
import { SelectionByFieldId } from '@features/operations/types'
import {
  FilterStructure,
  FilterStructureResponse,
  FilterGroupRequestDto,
  FilterStructureRequestDef,
  FilterDefinitionRequestType,
  FilterGroup
} from '../../../api/filters/types'
import { TreeKeySelectionWithLength } from '../../../utils/tree'
import { DomainFilterSelector } from '../../../features/filters/types/domainFilter'

export const flatFilterGroup = (filterStructure: FilterStructure[]) => {
  const flatFilters: FilterStructureRequestDef[] = []
  let filters = [...filterStructure]
  while (filters.length > 0) {
    const currentStructure = filters.pop()
    if (currentStructure?.children !== undefined) {
      filters = filters.concat(currentStructure.children)
      continue
    }
    if (currentStructure?.filterHierarchy) {
      flatFilters.push({
        id: currentStructure?.filterHierarchy,
        definitionType: FilterDefinitionRequestType.Hierarchy
      })
      continue
    }
    if (currentStructure?.fieldId) {
      flatFilters.push({
        id: currentStructure?.fieldId,
        definitionType: FilterDefinitionRequestType.Simple
      })
    }
  }
  return flatFilters
}

export const toFilterGroupRequestDto = (
  filtersStructureData?: FilterStructureResponse
): Record<string, FilterGroupRequestDto> => {
  return _.keyBy(
    _.map(
      filtersStructureData?.filtersGroups,
      g =>
        ({
          groupId: g.id,
          filterDefinitions: _.values(flatFilterGroup(g.filters))
        }) as FilterGroupRequestDto
    ),
    'groupId'
  )
}

export const selectFilterGroup = (filterId?: string, filterGroups?: FilterGroup[]) => {
  return _.find(filterGroups, group => {
    let filters = [...group.filters]
    let isInGroup = false
    while (filters.length > 0) {
      const filter = filters.pop()
      // eslint-disable-next-line no-continue
      if (filter?.children) {
        filters = filters.concat(filter.children)
      }
      if (
        filter?.fieldId === filterId ||
        filter?.filterHierarchy === filterId ||
        filter?.semanticSearchType === filterId
      ) {
        isInGroup = true
        break
      }
    }
    return isInGroup
  })
}

export const getGroupIdFromFilterStructure = (
  filterStructure: FilterStructureResponse | undefined,
  fieldId: string
) => {
  const filterGroupRequest = toFilterGroupRequestDto(filterStructure)
  const groupIds = Object.keys(filterGroupRequest)
  return groupIds.find(groupId => filterGroupRequest[groupId].filterDefinitions.find(filter => filter.id === fieldId))
}

export const findFilterDefinitionTypeFromFilterStructure = (
  filtersStructureData: FilterStructureResponse | undefined,
  fieldId: string
): FilterDefinitionRequestType | undefined => {
  const filterGroupRequest = toFilterGroupRequestDto(filtersStructureData)
  const groupRequestDto = _.filter(
    _.map(filterGroupRequest, g => {
      return {
        ...g,
        filterDefinitions: _.filter(g.filterDefinitions, { id: fieldId })
      }
    }),
    group => group.filterDefinitions.length > 0
  )

  if (groupRequestDto.length > 0) {
    return groupRequestDto[0].filterDefinitions[0].definitionType
  }

  return undefined
}

export const getChildExpressionsValues = (childExpressions: FieldExpression[] | undefined): string[] => {
  if (childExpressions) {
    const selectedValues = _.map(childExpressions, childExpression => childExpression.value as string[])
    return _.flatten(selectedValues)
  }
  return []
}

export const reduceToSelectionByFieldId = (
  affectedNodes: TreeKeySelectionWithLength[],
  filterDefinitionSelectors: DomainFilterSelector[] | undefined
) => {
  return _.reduce<TreeKeySelectionWithLength, SelectionByFieldId[]>(
    affectedNodes,
    (result, node) => {
      const fieldId = _.find(filterDefinitionSelectors, { hierarchyLevel: node.parentPathLength + 1 })
        ?.fieldId as string

      const selection = _.find(result, { fieldId })

      if (selection) {
        return _.map(result, resultItem => {
          if (resultItem.fieldId !== fieldId) return resultItem
          return { ...resultItem, value: [...resultItem.value, node.key] }
        })
      }
      return [...result, { fieldId, value: [node.key] }]
    },
    []
  )
}
