import React from 'react'
import _ from 'lodash'
import { To } from 'react-router-dom'
import styled from 'styled-components/macro'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'
import { theme } from '../../utils/theme'
import { filterVisible } from '../../utils/helpers'
import { Hideable } from '../../utils/types'
import { SvgComponent } from '../../types'
import { MenuButton } from '../Button'
import { Link } from '../Text/Link'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  && > * + * {
    padding-left: 1em;
  }
`

const TitleWrapper = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`

const TitleWithSubtitle = styled.div`
  font-family: 'Comfortaa', cursive;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')}

  & > .title {
    color: ${theme.colors.main};
    font-size: ${theme.fontSizes.medium};
    line-height: 1.5;
    font-weight: bold;
  }

  & > .subtitle {
    color: ${theme.colors.main};
    font-size: ${theme.fontSizes.extraSmall};
    line-height: 1.5;
    font-weight: bold;
  }
`

type LinkHeaderTitleProps = {
  title: string
  subTitle?: string
  link?: To
  state?: any
  onClick?: () => void
}

export const LinkHeaderTitle: React.FC<LinkHeaderTitleProps> = ({ title, subTitle, link, state, onClick }) => {
  if (_.isUndefined(link)) {
    return (
      <TitleWithSubtitle>
        <div className="title">{title}</div>
        {!_.isUndefined(subTitle) && <div className="subtitle">{subTitle}</div>}
      </TitleWithSubtitle>
    )
  }
  return (
    <Link to={link} state={state} iconSize={theme.fontSizes.medium} ellipsis onClick={onClick}>
      <TitleWithSubtitle>
        <div className="title">{title}</div>
        {!_.isUndefined(subTitle) && <div className="subtitle">{subTitle}</div>}
      </TitleWithSubtitle>
    </Link>
  )
}

type Props = {
  title: string | React.ReactElement
  icon?: SvgComponent
  menuItems?: Hideable<MenuItemType>[]
}

export const LinkIconHeader: React.FC<Props> = ({ title, icon: Icon, menuItems }) => {
  const visibleItems = filterVisible(menuItems)

  return (
    <Wrapper>
      {Icon && <Icon fill={theme.colors.main} />}
      <TitleWrapper>{_.isString(title) ? <LinkHeaderTitle title={title} /> : title}</TitleWrapper>
      {!_.isEmpty(visibleItems) && (
        <MenuButton
          type="transparent"
          items={visibleItems?.map(x => ({
            key: x.key,
            label: x.label,
            onClick: x.onClick
          }))}
        />
      )}
    </Wrapper>
  )
}
