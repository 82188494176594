import React, { useCallback, useState } from 'react'
import { Dialog } from '@components/Dialog'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { cancelMonitoring, getPortfolioKeys } from '@api/portfolio'
import { Form } from 'antd'
import { Stack } from '@components/Stack'
import { checkQueryName } from '@utils/query'
import _ from 'lodash'
import { Input } from '@components/Input'
import { ErrorResponse, getFieldError, parseErrors } from '@features/form/utils'
import { AxiosError } from 'axios'
import { getQueriesKeys } from '@api/query'
import { QueryType } from '@types'
import { InfoDialog } from '@containers/Dialog/InfoDialog'
import { SubmitMethod } from '@api/query/types'
import { SelectionRadioGroup } from '@containers/SaveQuery/SelectionRadioGroup'
import { Text } from '../../../components/Text'
import { CancelPortfolioMonitoringDialogData } from './types'
import { UpdateQuerySelect } from '../../../containers/SaveQuery/UpdateQuerySelect'

type Props = {
  close: () => void
  data: CancelPortfolioMonitoringDialogData
}

export const CancelMonitoringDialog = ({ close, data: { portfolioId } }: Props) => {
  const { t } = useTranslation(['common', 'portfolio', 'error'])

  const [currentSaveMethod, setCurrentSaveMethod] = useState<SubmitMethod>(SubmitMethod.None)
  const [existingQueryId, setExistingQueryId] = useState<string>()
  const [queryName, setQueryName] = useState('')
  const [nameError, setNameError] = useState<string>()

  const onInputChangeHandler = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setNameError(checkQueryName(t, target.value))
      setQueryName(target.value)
    },
    [setNameError, setQueryName, t]
  )

  const isCreateNew = currentSaveMethod === SubmitMethod.CreateNew
  const isUpdateExisting = currentSaveMethod === SubmitMethod.Update

  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: () =>
      cancelMonitoring(portfolioId, {
        existingQueryId: isUpdateExisting ? existingQueryId : undefined,
        newQueryName: isCreateNew ? queryName : undefined
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getPortfolioKeys.PortfolioById(portfolioId) })
      if (currentSaveMethod !== SubmitMethod.None) {
        queryClient.invalidateQueries({
          queryKey: getQueriesKeys.GetFilterQueriesAsDocument({ documentTypes: [QueryType.PortfolioQueries] })
        })
      }
    },
    onError: (e: AxiosError<ErrorResponse>) => {
      if (isCreateNew) {
        const queryNameErr = parseErrors(e).queryName
        const label = t('common:SaveQuery.InputLabel')
        const error = getFieldError(queryNameErr, label, t)
        setNameError(error ?? t('error:MonitoringRemoval.UnfollowFailure'))
      } else {
        setNameError(t('error:MonitoringRemoval.UnfollowFailure'))
      }
    }
  })

  if (mutation.isSuccess) {
    let text = t('portfolio:MonitoringRemoval.UnfollowSuccess')
    if (currentSaveMethod === SubmitMethod.CreateNew)
      text = t('portfolio:MonitoringRemoval.UnfollowAndSaveSuccess', { queryName })
    if (currentSaveMethod === SubmitMethod.Update)
      text = t('portfolio:MonitoringRemoval.UnfollowAndUpdateSuccess', { queryName })
    return <InfoDialog close={close} data={{ title: t('portfolio:MonitoringRemoval.Title'), text }} />
  }

  return (
    <Dialog
      title={t('portfolio:MonitoringRemoval.Title')}
      width={900}
      open
      loading={mutation.isPending}
      footer=""
      cancelText={t('common:Cancel')}
      onCancel={close}
      okText={t('common:Save')}
      okButtonProps={{
        disabled:
          (isCreateNew && (nameError !== undefined || _.isEmpty(queryName))) ||
          (isUpdateExisting && existingQueryId === undefined)
      }}
      onOk={() => mutation.mutate()}
    >
      <Stack orientation="vertical" gap={40}>
        <Text>{t('portfolio:MonitoringRemoval.WarningMessage')}</Text>
        <SelectionRadioGroup
          disabled={mutation.isPending}
          currentSaveMethod={currentSaveMethod}
          setCurrentSaveMethod={(method: SubmitMethod) => {
            setNameError(undefined)
            setCurrentSaveMethod(method)
          }}
          allowEmptySelection
        />
        <Form.Item validateStatus={nameError ? 'error' : 'success'} help={nameError}>
          <Text size="S" type="primary" bold>
            {t('common:SaveQuery.InputLabel')}
          </Text>
          {isUpdateExisting && (
            <UpdateQuerySelect
              setQueryName={setQueryName}
              setQueryId={setExistingQueryId}
              queryTypes={[QueryType.ProspectingQueries]}
            />
          )}
          {(isCreateNew || currentSaveMethod === SubmitMethod.None) && (
            <Input
              disabled={mutation.isPending || currentSaveMethod === SubmitMethod.None}
              placeholder={t('common:SaveQuery.InputPlaceholder')}
              value={queryName}
              onChange={onInputChangeHandler}
            />
          )}
        </Form.Item>
      </Stack>
    </Dialog>
  )
}
