import React from 'react'
import styled from 'styled-components/macro'
import { DrawerProps, Drawer } from 'antd'
import { media } from 'styled-bootstrap-grid'
import { gridTheme, HEADER_HEIGHT, HEADER_LOGO_WIDTH } from '../../utils/theme'

export const DrawerStyledWrapper = styled.div<{ mobileMenu?: boolean }>`
  &&& .ant-drawer {
    position: fixed;
    pointer-events: unset;
  }

  &&& .ant-drawer-mask {
    opacity: 0;
    animation: none;
  }

  && .ant-drawer-content-wrapper {
    top: ${HEADER_HEIGHT}px;
    bottom: initial;
    height: auto;
    max-height: calc(100vh - ${HEADER_HEIGHT}px);
    overflow-y: auto;
    width: 320px !important;
    overscroll-behavior: contain;
  }

  @media (min-width: ${gridTheme.breakpoints.sm}px) and (max-width: ${gridTheme.breakpoints.md}px) {
    && .ant-drawer-content-wrapper {
      ${({ mobileMenu }) =>
        mobileMenu &&
        `
        width: calc(100vw - ${HEADER_LOGO_WIDTH}px) !important;
        height: calc(100vh - ${HEADER_HEIGHT}px);
      `}
    }
  }

  ${({ mobileMenu }) => media.lg`
    && .ant-drawer-content-wrapper {
      ${mobileMenu && 'display: none'}
    }
  `}
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DrawerStyled = styled(Drawer)`
  &&.ant-drawer-content {
    background-image: linear-gradient(0deg, ${({ theme }) => theme.colors.navy}, ${({ theme }) => theme.colors.main});
    border-radius: 0px 0 3px 3px;
    height: 100%;
  }

  && .ant-drawer-body {
    padding: 0;
    height: auto;
  }
`

type Props = DrawerProps & {
  children: React.ReactNode
  mobileMenu?: boolean
}

export const DrawerHeaderContainer = ({ children, mobileMenu, open, ...rest }: Props) => {
  return (
    <DrawerStyledWrapper mobileMenu={mobileMenu}>
      <DrawerStyled destroyOnClose getContainer={false} closable={false} open={open} {...rest}>
        {open && children}
      </DrawerStyled>
    </DrawerStyledWrapper>
  )
}
