import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { LocalStorageKeys } from '../features/storage/types'

function check(tabsKey: string, tabKey: string) {
  const ls = localStorage
  const ss = sessionStorage
  const tabId =
    ss.getItem(tabKey) ||
    (newId => {
      ss.setItem(tabKey, newId)
      return newId
    })(uuid())

  const update = (setTabValue: number) => {
    let currentValue = JSON.parse(ls.getItem(tabsKey) || '{}')

    const sum = _.sum(_.values(currentValue))

    if (_.isNil(currentValue[tabId]) && !sum) {
      ls.removeItem(tabsKey)
      ls.removeItem(LocalStorageKeys.USER)
      currentValue = {}
    }
    currentValue[tabId] = setTabValue
    ls.setItem(tabsKey, JSON.stringify(currentValue))
  }
  update(1)
  window.onbeforeunload = () => {
    update(0)
  }
}

export const checkValidSession = () => check('tabs', 'tabid')
