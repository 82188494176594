import React from 'react'
import { WithT } from 'i18next'
import { DefaultTheme } from 'styled-components/macro'
import { generatePath, Location } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { Dispatch } from 'redux'
import { DialogActions } from '@features/dialog/actions'
import {
  NotificationEventDefault,
  NotificationEventDefaultLayout,
  NotificationEventText,
  OpenButton,
  DownloadButton
} from '../NotificationEvents'
import Check from '../../../assets/icons/check-circle.svg?react'
import Warning from '../../../assets/icons/warning-circle.svg?react'
import Error from '../../../assets/icons/error-circle.svg?react'
import EditPen from '../../../assets/icons/pen-solid.svg?react'
import { Notification } from '../../../features/notifications/types'
import { CanUseAppModules } from '../../../features/config/types'
import { notificationActionUrl } from '../../../utils/notifications'
import { wrapper } from './Wrapper'
import { ADD_CAMPAIGN, PORTFOLIO, MANAGE_UPLOAD_ERRORS, PROSPECTING_MODULE } from '../../../routes'
import { Text } from '../../Text'
import { UserType } from '../../../authorization'
import { FormatNumberFunction } from '../../../utils/types'
import { NotificationSection } from '../NotificationSection'
import { TEXT_COLOR } from '../DrawerStyledComponents'

type Props = {
  notification: Notification
  navigationCallback: (to: Partial<Location>) => void
  downloadFile: (documentId: string) => void
  fallbackTheme: DefaultTheme
  formatNumber: FormatNumberFunction
  userType: UserType
  hasAccessSalesTool: boolean
  allowedModules: CanUseAppModules
  currentPath: string
  dispatch: Dispatch
} & WithT

export const notificationEvent = ({
  t,
  notification,
  navigationCallback,
  fallbackTheme,
  downloadFile,
  userType,
  hasAccessSalesTool,
  allowedModules,
  formatNumber,
  currentPath,
  dispatch,
  ...rest
}: Props) => {
  const notificationPopupKey = `notificationPopup${notification.id}`
  const actionUrl = notificationActionUrl(notification, currentPath)
  const canSeeCampaigns = hasAccessSalesTool && allowedModules.SalesTool

  switch (notification.type) {
    case 'PM001':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            count={notification.data.numberOfUploadedCompanies}
            message={
              <NotificationEventText
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
                notificationTextSuffix={
                  notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : undefined
                }
              />
            }
            button={
              actionUrl && (
                <OpenButton fallbackTheme={fallbackTheme} navigationCallback={() => navigationCallback(actionUrl)} />
              )
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'PM003':
    case 'PM004':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={notification.type === 'PM003' ? <Warning /> : <Error />}
            count={notification.type === 'PM003' ? notification.data.ceasedUnmatched : notification.data.rejected}
            message={
              <NotificationEventText
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
              />
            }
            button={
              actionUrl && (
                <DownloadButton
                  fallbackTheme={fallbackTheme}
                  downloadFile={() => downloadFile(notification.data.documentId)}
                />
              )
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'PM027':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Error />}
            count={notification.data.invalidCompanies.length}
            message={
              <NotificationEventText
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
              />
            }
            button={
              <OpenButton
                fallbackTheme={fallbackTheme}
                text={t('notification:NotificationPopup.ManageErrors')}
                icon={<EditPen />}
                navigationCallback={() =>
                  navigationCallback({
                    pathname: generatePath(MANAGE_UPLOAD_ERRORS),
                    state: {
                      documentId: notification.data.documentId,
                      portfolioId: notification.data.portfolioId,
                      invalidCompanies: notification.data.invalidCompanies,
                      country: notification.data.country
                    }
                  })
                }
              />
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'PM028':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={`${notificationPopupKey}`}
            icon={<Warning />}
            message={
              <NotificationEventText
                t={t}
                fallbackTheme={fallbackTheme}
                notification={notification}
                formatNumber={formatNumber}
                text={t('notification:PM028')}
              />
            }
            button={
              notification.data.documentId && (
                <DownloadButton
                  fallbackTheme={fallbackTheme}
                  downloadFile={() => downloadFile(notification.data.documentId)}
                />
              )
            }
          />
        ),
        key: `${notificationPopupKey}`
      })
      break
    case 'PM005':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={`${notificationPopupKey}1`}
            icon={<Warning />}
            message={
              <NotificationEventText
                t={t}
                fallbackTheme={fallbackTheme}
                notification={notification}
                formatNumber={formatNumber}
                text={t('notification:PM005', {
                  exportType: t(`portfolio:Download.${notification.data.exportType}`),
                  portfolioName: notification.data.portfolioName
                })}
                notificationTextSuffix={
                  notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : undefined
                }
              />
            }
            button={
              notification.data.documentId && (
                <DownloadButton
                  fallbackTheme={fallbackTheme}
                  downloadFile={() => downloadFile(notification.data.documentId)}
                />
              )
            }
          />
        ),
        key: `${notificationPopupKey}1`
      })
      break
    case 'PR006':
    case 'PR007': {
      const children =
        notification.type === 'PR006' ? (
          <Trans
            i18nKey="notification:PR006"
            components={[
              <Text
                key="link"
                bold
                color="white"
                size="S"
                onClick={() => actionUrl && navigationCallback(actionUrl)}
                fallbackTheme={fallbackTheme}
              />
            ]}
            values={{
              portfolioName: notification.data.portfolioName,
              exclusionWarning:
                notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : ''
            }}
          />
        ) : undefined
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            message={
              <NotificationEventText
                inline
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
                notificationTextSuffix={
                  notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : undefined
                }
              >
                {children}
              </NotificationEventText>
            }
            button={
              <>
                <OpenButton
                  fallbackTheme={fallbackTheme}
                  navigationCallback={() =>
                    navigationCallback({
                      pathname: generatePath(PORTFOLIO, { portfolioId: notification.data.portfolioId }),
                      state: {
                        openDownload: true,
                        resetFilters: true
                      }
                    })
                  }
                  text={t('prospecting:Actions.Download')}
                />
                {userType !== UserType.Sales && canSeeCampaigns && (
                  <OpenButton
                    fallbackTheme={fallbackTheme}
                    navigationCallback={() => {
                      if (currentPath.startsWith(PROSPECTING_MODULE)) {
                        navigationCallback({
                          pathname: currentPath,
                          state: {
                            portfolioId: notification.data.portfolioId,
                            saveBeforeContinueFlag: {
                              displayDialog: true,
                              actionPath: ADD_CAMPAIGN
                            }
                          }
                        })
                      } else {
                        navigationCallback({
                          pathname: ADD_CAMPAIGN,
                          state: { portfolioId: notification.data.portfolioId }
                        })
                      }
                    }}
                    text={t('prospecting:Actions.SendToSales')}
                  />
                )}
              </>
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    }
    case 'PM010':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            message={
              <NotificationEventText
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
                notificationTextSuffix={
                  notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : undefined
                }
              />
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'PM012':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            message={
              <NotificationEventText
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
                text={t('notification:PM012', {
                  grantorName: notification.data.grantorName,
                  portfolioName: notification.data.portfolioName
                })}
              />
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'PM013':
      // this is because we do not want to show this notification
      break
    case 'PM020':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            message={
              <NotificationEventText
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
                notificationTextSuffix={
                  notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : undefined
                }
              />
            }
            button={
              canSeeCampaigns ? (
                <OpenButton
                  fallbackTheme={fallbackTheme}
                  navigationCallback={() => {
                    navigationCallback({
                      pathname: ADD_CAMPAIGN,
                      state: { portfolioId: notification.data.portfolioId }
                    })
                  }}
                  text={t('prospecting:Actions.SendToSales')}
                />
              ) : undefined
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'PM019':
    case 'ST001':
    case 'ST003':
    case 'ST004':
    case 'ST005':
    case 'ST006':
    case 'ST009':
    case 'ST010':
    case 'ST011':
    case 'ST012':
    case 'ST013':
    case 'ST014':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            message={
              <NotificationEventText
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
              />
            }
            button={
              actionUrl && (
                <OpenButton
                  fallbackTheme={fallbackTheme}
                  navigationCallback={() => navigationCallback(actionUrl)}
                  text={t('salestool:Actions.GoToCampaign')}
                />
              )
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'ST007':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            message={
              <NotificationEventText
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
              />
            }
            button={
              actionUrl && (
                <DownloadButton
                  fallbackTheme={fallbackTheme}
                  downloadFile={() => downloadFile(notification.data.documentId)}
                />
              )
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'DO002':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            message={
              <>
                <NotificationSection
                  t={t}
                  fallbackTheme={fallbackTheme}
                  notification={notification}
                  type={TEXT_COLOR}
                  bold
                  block
                />
                <NotificationEventText
                  t={t}
                  formatNumber={formatNumber}
                  fallbackTheme={fallbackTheme}
                  notification={notification}
                />
              </>
            }
            button={
              <DownloadButton
                text={t('notification:NotificationPopup.DownloadFile')}
                fallbackTheme={fallbackTheme}
                downloadFile={() => downloadFile(notification.data.documentId)}
              />
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'DO003':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            message={
              <NotificationEventText
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
              />
            }
            button={
              <OpenButton
                fallbackTheme={fallbackTheme}
                navigationCallback={() =>
                  dispatch(DialogActions.showDialogWithData('ShareQueryConfirmationDialog', { ...notification.data }))
                }
                text={t('notification:NotificationPopup.ShareQueryAction')}
              />
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'OP003':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={notificationPopupKey}
            icon={<Check />}
            message={
              <NotificationEventText
                inline
                t={t}
                formatNumber={formatNumber}
                fallbackTheme={fallbackTheme}
                notification={notification}
              >
                <Trans
                  i18nKey="notification:OP003"
                  components={[
                    <Text
                      key="link"
                      bold
                      color="white"
                      size="S"
                      onClick={() => {
                        navigationCallback({
                          pathname: generatePath(PORTFOLIO, { portfolioId: notification.data.portfolioId }),
                          state: {
                            resetFilters: true
                          }
                        })
                      }}
                      fallbackTheme={fallbackTheme}
                    />
                  ]}
                  values={{
                    portfolioName: notification.data.portfolioName,
                    exclusionWarning:
                      notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : ''
                  }}
                />
              </NotificationEventText>
            }
            button={
              <>
                <OpenButton
                  fallbackTheme={fallbackTheme}
                  navigationCallback={() => {
                    navigationCallback({
                      pathname: generatePath(PORTFOLIO, { portfolioId: notification.data.portfolioId }),
                      state: {
                        openDownload: true,
                        resetFilters: true
                      }
                    })
                  }}
                  text={t('prospecting:Actions.Download')}
                />
                {userType !== UserType.Sales && canSeeCampaigns && (
                  <OpenButton
                    fallbackTheme={fallbackTheme}
                    navigationCallback={() => {
                      navigationCallback({
                        pathname: ADD_CAMPAIGN,
                        state: { portfolioId: notification.data.portfolioId }
                      })
                    }}
                    text={t('prospecting:Actions.SendToSales')}
                  />
                )}
              </>
            }
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'PM007':
    case 'PM014':
    case 'PM014_0':
    case 'PM015':
    case 'PM015_0':
    case 'PM017':
    case 'PM017_0':
    case 'PM021':
    case 'PM023':
    case 'PM025':
    case 'PM026':
    case 'LL001':
    case 'PR002':
    case 'PR003':
    case 'PR004':
    case 'PR005':
    case 'TR001':
    case 'TR002':
    case 'OP001':
    case 'OP002':
    case 'OP004':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefault
            t={t}
            notification={notification}
            notificationPopupKey={notificationPopupKey}
            navigationCallback={navigationCallback}
            formatNumber={formatNumber}
            fallbackTheme={fallbackTheme}
            notificationTextSuffix={
              notification.data.excludedCompaniesCount > 0 ? t('notification:ExcludedCompaniesWarning') : undefined
            }
            currentPath={currentPath}
          />
        ),
        key: notificationPopupKey
      })
      break
    case 'ContactsDownloadEnd':
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefaultLayout
            fallbackTheme={fallbackTheme}
            notificationPopupKey={`${notificationPopupKey}1`}
            icon={<Warning />}
            message={
              <NotificationEventText
                t={t}
                fallbackTheme={fallbackTheme}
                notification={notification}
                formatNumber={formatNumber}
                text={t('notification:ContactsDownloadEnd')}
              />
            }
            button={
              notification.data.documentId && (
                <DownloadButton
                  fallbackTheme={fallbackTheme}
                  downloadFile={() => downloadFile(notification.data.documentId)}
                />
              )
            }
          />
        ),
        key: `${notificationPopupKey}1`
      })
      break
    default:
      wrapper({
        ...rest,
        content: (
          <NotificationEventDefault
            t={t}
            notification={notification}
            notificationPopupKey={notificationPopupKey}
            navigationCallback={navigationCallback}
            formatNumber={formatNumber}
            fallbackTheme={fallbackTheme}
            currentPath={currentPath}
          />
        ),
        key: notificationPopupKey
      })
  }
}
