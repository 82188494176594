import { Reducer } from 'redux'
import _ from 'lodash'
import * as actions from './actions'
import { TableKey, TableColumnSorting } from './types'

type TableStatePartial = {
  sorting?: TableColumnSorting
}

export type TableState = Record<TableKey, TableStatePartial>

const initTableState: TableStatePartial = {
  sorting: undefined
}

export const initState: TableState = {
  ..._.reduce(
    _.values(TableKey),
    (acc, key) => {
      acc[key] = initTableState
      return acc
    },
    {} as TableState
  )
}

const updateState = (
  tableKey: TableKey,
  state: TableState,
  newState: Partial<Partial<TableStatePartial>>
): TableState => ({
  ...state,
  [tableKey]: { ...state[tableKey], ...newState }
})

export const tableReducer: Reducer<TableState, actions.TableActions> = (state = initState, action) => {
  switch (action.type) {
    case actions.SORTING_CHANGED: {
      const { tableKey, sorterResult } = action.payload
      return updateState(tableKey, state, {
        sorting: sorterResult
          ? {
              field: sorterResult.field,
              order: sorterResult.order
            }
          : undefined
      })
    }
    default:
      return state
  }
}
