import axios, { CancelToken } from 'axios'
import {
  LocalUnitsRequest,
  ReportCampaignsResponse,
  ReportDataRequest,
  ReportInfoBoxResponse,
  ReportPortfoliosResponse
} from './types'

export const getReportData = <TResponse>(params: ReportDataRequest, cancelToken?: CancelToken) =>
  axios.post<TResponse>('/api/Report/get-data', { ...params, reportDataType: 'Report' }, { cancelToken })

export const getReportPortfolios = (identificationCode: string, companyUnitId: string, cancelToken?: CancelToken) =>
  axios.get<ReportPortfoliosResponse>(`/api/Report/portfolios/${identificationCode}/${companyUnitId}`, { cancelToken })

export const getReportCampaigns = (identificationCode: string, companyUnitId: string, cancelToken?: CancelToken) =>
  axios.get<ReportCampaignsResponse>(`/api/Report/campaigns/${identificationCode}/${companyUnitId}`, { cancelToken })

export const getLocalUnits = <TResponse>(params: LocalUnitsRequest, cancelToken?: CancelToken) =>
  axios.post<TResponse>(
    '/api/Report/get-data',
    {
      ...params,
      reportDataType: 'LocalUnits'
    },
    { cancelToken }
  )

export const getInfoBoxData = (identificationCode: string, cancelToken?: CancelToken) =>
  axios.post<ReportInfoBoxResponse>(
    '/api/Report/get-data',
    { reportDataType: 'InfoBox', identificationCode },
    { cancelToken }
  )
