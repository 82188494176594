/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  ChooseNameForCompaniesList: 'Wählen Sie den Namen der Firmenliste',
  EnterARepresentativeName: 'Vergeben Sie einen repräsentativen Namen für das Portfolio das Sie hochladen.',
  EnterARepresentativeNameCompaniesList: 'Geben Sie einen Namen für das zu erstellende Portfolio ein',
  ItWillIdentifyCompaniesList: 'Der Name hilft Ihnen zur späteren Unterscheidung.',
  PortfolioName: 'Portfolioname',
  InsertNameForPortfolio: 'Geben Sie einen Namen für das Portfolio ein',
  UploadPortfolio: {
    StandardTitle: 'Portfolio hochladen',
    UploadPortfolioOrConnect: 'Portfolio hochladen oder Unternehmens-CRM verbinden, um zu beginnen',
    UploadOrConnect: 'Portfolio hochladen oder Unternehmens-CRM verbinden',
    UploadFromPlatform: 'Upload eines b.ONE-Portfolios',
    UploadAPortfolio: 'Portfolio hochladen',
    SelectFromPc: 'Lokales Verzeichnis auswählen',
    DownloadTemplateDescription1: 'Laden Sie den Basis-Track des Portfolios herunter',
    DownloadTemplateDescription2:
      'Geben Sie die Daten Ihrer Kundenunternehmen wie im Beispielblatt angegeben ein und lassen Sie die Struktur der Tabellen unverändert',
    DownloadTemplateDescription3: 'Laden Sie den Titel im nächsten Schritt hoch',
    DownloadTemplateDescription4:
      'Kennen Sie die Geschäftsidentifikationsnummer/CRIF-ID nicht? Bitte kontaktieren Sie den Kundendienst unter <0>support.ch@crif.com</0>, um mit dem manuellen Abgleich fortzufahren',
    DownloadTemplate: 'Datei herunterladen',
    ChooseHowToUploadPortfolio: 'Wählen Sie die gewünschte Datei aus',
    SelectPortfolioFromPc: 'Portfolio von Ihrem Laufwerk auswählen',
    YouCanLoad: 'Sie können jederzeit weitere Firmenlisten hochladen. Diese werden dann eigenständig geladen.',
    ToEnsureTheCorrectLoading:
      'Bitte stellen Sie für eine einwandfreie Verarbeitung der Daten sicher, dass das bereitgestellte margò Template verwendet wurde.',
    ChooseHowToUploadForUpdate: 'Wählen Sie, wie Sie Ihre Unternehmensliste aktualisieren möchten',
    SelectCompanyListFromPC: 'Wählen Sie die Firmenliste von Ihrem PC aus',
    ToEnsureTheCorrectLoadingForUpdate:
      'Um sicherzustellen, dass die Unternehmensliste korrekt geladen wird, stellen Sie sicher, dass Sie die von Margo bereitgestellte Vorlage verwendet haben. Diese finden Sie unter dem Button rechts, "Template herunterladen".',
    SelectFile: 'Bitte Datei auswählen',
    SelectFileFromComputer: 'Portfolioname vergeben',
    ChoosePortfolioName: 'Portfolioname vergeben',
    ItWillDistinguish: 'Der Name sollte eindeutig sein, damit Sie ihre Portfolios besser unterscheiden können.',
    WrapperPage: {
      TypeOfUpload: 'Art des Uploads',
      TypeOfUploadDescription:
        'Wählen Sie die Art des Uploads aus, den Sie durchführen möchten: Standard oder vereinfacht',
      StandardUpload: 'Standard Laden',
      StandardUploadTooltip: 'Laden Sie Unternehmen auf margò über die spezielle Vorlage',
      SimplifiedUpload: 'Vereinfacht Laden',
      SimplifiedUploadTooltip:
        'Laden Sie auf vereinfachte Weise eine Liste mit Identifikationscodes direkt ins Internet hoch'
    },
    Simplified: {
      Title: 'Vereinfachter Portfolio-Upload',
      FirstStepTitle: 'Fügen Sie Identifikationscodes ein oder geben Sie sie ein',
      AvailableCharacters: 'Verfügbare Charaktere: {{numberOfCharacters}}/{{maximumCharacters}}',
      UploadWarning:
        'WARNUNG: Wenn Sie diese Seite schließen oder ohne Speichern zurückgehen, gehen alle Änderungen verloren',
      UploadPlaceholder: 'Gültige {{descriptionFields}} einfügen oder eintippen, durch Leerzeichen getrennt',
      DescriptionFields: {
        ita: 'Steuernummer/Umsatzsteuer-/CRIF-Nummer',
        deu: 'ONR-Nummer',
        czsk: 'Steuernummer/Umsatzsteuer-/CRIF-Nummer',
        che: 'Unternehmens-Identifikationsnummer/Crif ID/UID-Nummer/MWST-Nr./Firmenbuchnummer'
      },
      Country: {
        Select: 'Wähle dein Land',
        SK: 'Slowakei',
        CZ: 'Tschechien',
        CHE: 'Schweizerisch',
        AUT: 'Österreich'
      },
      Description1: 'Gültige {{descriptionFields}} einfügen oder eintippen, durch Leerzeichen getrennt',
      Description2: 'Klicken Sie für die nächsten Schritte auf die Schaltfläche "Weiter"',
      Description3:
        'Mögliche Fehler werden in der „Fehlertabelle“ gemeldet, die durch Klicken auf „Fehler verwalten“ in der zugehörigen Benachrichtigung zugänglich ist',
      ChooseStatus: {
        Title: 'Wählen Sie den Status aus, den Sie anwenden möchten',
        Description1: 'Wählen Sie den Anfangsstatus der hochzuladenden Firmen aus.',
        Description2: 'Derselbe Unternehmensstatus wird auf das gesamte Portfolio angewendet.'
      }
    },
    ManageErrors: {
      Title: 'Fehler anzeigen und beheben',
      Description1:
        'Klicken Sie auf den {{descriptionFields}}, um ihn zu ändern, oder klicken Sie auf das Papierkorbsymbol, um die Zeile zu lösche',
      Description2: 'Klicken Sie auf die Schaltfläche „Weiter“, um die Datei hochzuladen',
      Description3: 'Finden Sie feste Unternehmen im Portfolio',
      CompanyMatchStatusTitle: 'Upload-Status',
      StatusDescriptionTitle: 'Status Beschreibung',
      DownloadList: 'Liste herunterladen',
      CompanyMatchStatus: {
        Rejected: 'Abgelehnt',
        RejectedDescription: 'Die {{descriptionFields}} ist ungültig',
        Duplicate: 'Dupliziert',
        DuplicateDescription: 'Die eingegebene {{descriptionFields}} ist bereits im Portfolio vorhanden',
        NotMatched: 'Nicht gefunden',
        NotMatchedDescription:
          'Die {{descriptionFields}} ist gültig, aber in der Datenbank wurde keine Übereinstimmung gefunden',
        Pending: 'Bis zur Validierung',
        PendingDescription: 'Die {{descriptionFields}} wurde geändert. Klicken Sie auf Weiter, um es zu bestätigen.',
        Ceased: 'Aufgehört',
        CeasedDescription: 'Die eingegebene {{descriptionFields}} bezieht sich auf ein aufgegebenes Unternehmen'
      }
    }
  },
  ExcludeCustomVariables: 'Benutzerdefinierte Variablen ausschließen',
  ExcludeCustomVariablesConfirm:
    'Möchten Sie Unternehmen ohne benutzerdefinierte Variablen verschieben? Wenn ja, werden die benutzerdefinierten Variablen aus dem Ursprungsportfolio gelöscht.',
  Management: {
    Header: 'Portfolio Management',
    Upload: 'Portfolio Hochladen',
    UploadTooltip: 'Laden Sie Unternehmen auf margò über die spezielle Vorlage',
    UploadTooltipWithSimplified:
      'Laden Sie eine Liste mit Identifikationscodes hoch, indem Sie die Standard- oder vereinfachte Methode verwenden',
    ShowCorporate: 'Gemeinsames Portfolio',
    SearchPortfolioTooltip: 'Portfolio durchsuchen',
    SearchPortfolioPlaceholder: 'Suche nach dem Namen des Portfolios',
    SearchHeader: 'Portfolio Management - Portfolio-Suchergebnisse',
    PortfolioTabs: {
      AllPortfolios: 'Alle Portfolios',
      MyPortfolios: 'Meine Portfolios',
      SharedPortfolios: 'Gemeinsame Portfolios',
      ReceivedPortfolios: 'Erhaltene Portfolios'
    },
    PortfolioName: {
      ChangePortfolioName: 'Portfolioname ändern',
      EnterARepresentativeName: 'Portfolioname eingeben',
      InsertPortfolioName: 'Portfolioname eingeben',
      NameChangedCorrectly: 'Name korrekt geändert in'
    },
    PortfolioNote: {
      AddNote: 'Notiz hinzufügen',
      EditNote: 'Notiz bearbeiten',
      AddANoteToDescribe: 'Fügen Sie eine Notiz hinzu, um das Portfolio zu beschreiben',
      AddNotePlaceholder: 'Notiz hinzufügen',
      AddNoteFieldLabel: 'Notiz'
    },
    DeletePortfolio: {
      DeletePortfolio: 'Portfolio löschen',
      AreYouSure: 'Möchten Sie {{name}} wirklich löschen?',
      PortfolioDeletedCorrectly: '{{name}} korrekt gelöscht.',
      DataWillRemain: 'Alle Unternehmensdaten, die Sie heruntergeladen haben, sind in Ihrem Speicher verfügbar.'
    },
    Table: {
      NumberOfCompanies: 'Anzahl Unternehmen',
      EnrichedCompanies: 'Angereicherte Unternehmen',
      CreationDate: 'Erstellungsdatum',
      LastUpdateDate: 'Letztes Update',
      CampaignsWithThisPortfolio: 'Kampagnen mit diesem Portfolio'
    },
    Menu: {
      ChangeName: 'Name wechseln',
      DuplicatePortfolio: 'Portfolio duplizieren',
      DeletePortfolio: 'Portfolio löschen',
      AddNote: 'Notiz hinzufügen',
      EditNote: 'Notiz bearbeiten'
    },
    Message: {
      Header: 'Klicken Sie auf "Portfolio hochladen", um Ihr erstes Portfolio hochzuladen.',
      Description:
        'Hier sehen Sie eine Liste mit bereits gespeicherten potenziellen Prospects. Klicken Sie für mehr Details auf den Namen und um die Unternehmen in der Liste zu verwalten.'
    },
    UploadComplete: 'Upload abgeschlossen!',
    CompaniesUploadedCorrectly: 'Firmen wurden korrekt hochgeladen',
    CeasedCompaniesNotMatched: 'Aufgegebene Unternehmen, nicht passend oder nicht hochgeladen',
    ErrorsDueWrongData: 'Fehler aufgrund falscher Daten',
    Download: 'Herunterladen',
    ListOfCeasedCompanies: 'Liste der eingestellten Unternehmen',
    ListOfErrors: 'Fehlerliste',
    Stats: {
      Companies: 'Unternehmen',
      AllCompanies: 'Alle Unternehmen',
      EnrichedCompanies: 'Angereicherte Unternehmen',
      Enriched: 'angereichert',
      NotEnriched: 'nicht angereichert',
      Prospect: 'Prospects',
      Lead: 'Leads',
      Client: 'Kunden',
      Supplier: 'Lieferanten',
      Competitor: 'Wettbewerber',
      LastUpdate: 'Lieferanten'
    },
    Enrich: 'Anreichern',
    EnrichTooltip: 'Verbessern Sie die Daten dieser Unternehmen',
    Analyze: 'Portfolio Analyse',
    AnalyzeTooltip: 'Visualisieren Sie die Portfoliodaten in Bezug auf die Hauptvariablen',
    Similarity: 'Similarity erzeugen',
    SimilarityTooltip: 'Wenden Sie den Ähnlichkeitsalgorithmus an, um neue Interessenten zu finden',
    SelectSource: 'Firmendaten mittels Template hochladen',
    UpdatePortfolio: 'Portfolio aktualisieren'
  },
  Table: {
    companyName: 'Firmenname',
    lastUpdateDate: 'Letztes Update',
    companyUnitTypeCode: 'Rechtsform',
    companyStatusCode: 'Status',
    employeeNumber: 'Anzahl Mitarbeiter',
    companyPortfolioStatus: 'Bundesland',
    sale: 'Sales',
    Sale: 'Sales',
    '[tags]': 'Tags',
    startDate: 'Startdatum',
    revenue: 'Umsatz',
    turnover: 'Umsatz',
    isRating4: 'Rating',
    Empty: 'Wir haben keine Unternehmen gefunden, die Ihrer Suche entsprechen',
    isEnriched: 'angereichert'
  },
  SelectColumns: 'Spalten auswählen',
  LoadMore: 'weitere laden',
  MapDescription: 'Die Kartenansicht zeigt die einzelnen Unternehmen unabhängig von ihrem Status im Portfolio',
  CompanyGroupActions: {
    Title: 'Gruppenaktionen',
    Actions: 'Aktionen',
    AreaManager: 'Area Manager',
    Sales: 'Sales',
    Products: 'Produkte',
    Select: 'Wählen',
    NotAssigned: 'Nicht zugeordnet',
    SelectAreaManager: 'Area Manager auswählen',
    SelectSales: 'Sales auswählen',
    SelectProducts: 'Produkt auswählen',
    ActionItems: {
      DuplicateCompany: 'Unternehmen duplizieren',
      DeleteCompany: 'Unternehmen löschen',
      MoveCompany: 'Unternehmen in ein anderes Portfolio verschieben',
      Enrich: 'Anreichern',
      CreatePortfolio: 'Portfolio erstellen',
      DownloadExcel: 'Excel herunterladen',
      AddLocalUnits: 'Lokale Einheit hinzufügen',
      ShareCompanies: 'Unternehmen teilen',
      UnshareCompanies: 'Teilen beenden'
    },
    CreatePortfolio: {
      Title: 'Portfolio erstellen',
      InputDescription: 'Geben Sie einen Namen für das zu erstellende Portfolio ein',
      InsertPortfolioName: 'Portfolio-Namen hinzufügen'
    },
    HiddenCompanyMessage: 'Die Operation wird nicht für Unternehmen durchgeführt, die zu verdeckten Portfolios gehören.'
  },
  Actions: {
    ViewCampaigns: 'Alle relativen Kampagnen anzeigen',
    LoadQuery: 'Gespeicherte Suche laden',
    LoadQueryTooltip: 'Öffnen Sie eine gespeicherte Abfrage',
    SaveQuery: 'Suche Speichern',
    SaveQueryTooltip: 'Speichern Sie diese Abfrage im Modul "Portfolio Management"',
    Download: 'Portfolio herunterladen',
    DownloadTooltip: 'Laden Sie das Portfolio im Excel-Format auf Ihr Gerät herunter',
    Analyze: 'Portfolio Analyse',
    AnalyzeTooltip: 'Visualisieren Sie die Portfoliodaten in Bezug auf die Hauptvariablen',
    Similarity: 'Similarity Erzeugen',
    SimilarityTooltip: 'Wenden Sie den Ähnlichkeitsalgorithmus an, um neue Interessenten zu finden',
    Campaign: 'Kampagne erstellen',
    CampaignTooltip: 'Weisen Sie die Unternehmen dem Vertriebsteam zu und planen Sie eine Kampagne',
    Update: 'Portfolio Aktualisieren',
    UpdateTooltip:
      'Fügen Sie diesem Portfolio neue Unternehmen hinzu oder aktualisieren Sie die benutzerdefinierten Variablen',
    LocalUnits: 'Lokale Einheit hinzufügen',
    LocalUnitsTooltip: 'Filialen, die mit der Hauptsitz verbunden sind, zu diesem Portfolio hinzufügen',
    SendToOtherModules: 'An andere Module Senden',
    SendToOtherModulesTooltip: 'Senden Sie die Unternehmen an das Sales Tool oder das Analyze market Modul',
    UnlockEsg: 'Schalten Sie den ESG-Score frei',
    UnlockEsgTooltip: 'Schalten Sie den ESG-Score für die Unternehmen im Portfolio frei',
    MaxEsgUnlockExceededTooltip:
      'Sie können den ESG-Score für maximal {{count}} Unternehmen gleichzeitig freischalten. Bitte wählen Sie weniger Unternehmen aus.'
  },
  Enriched: 'Angereichert',
  Enrich: 'Anreichern',
  Monitored: 'Verfolgen',
  Close: 'Schließen',
  Filters: 'Filter',
  UserFilters: 'Benutzerdefinierter Filter',
  Download: {
    DownloadPortfolio: 'Portfolio herunterladen',
    SelectTypeOfList: 'Wählen Sie den Listentyp',
    SelectTypeOfListDescription: 'Wählen Sie den Listentyp aus, den Sie herunterladen möchten.',
    DownloadCost: 'Die Downloadkosten sind für jede Art von Liste identisch',
    Base: 'Basis',
    BaseDescription: 'Die Liste enthält die Basic-Daten der Unternehmensdaten.',
    Complete: 'Komplett',
    CompleteDescription: 'Die Liste enthält die vollständigen Daten der Unternehmensdaten',
    ExceededMaximumCompanies: 'Maximale Anzahl herunterladbarer Unternehmen überschritten',
    Marketing: 'Marketing',
    Standard: 'Marketing',
    MarketingDescription: 'Die Liste enthält die Standard-Daten der Unternehmensdaten',
    Custom: 'Benutzerdefiniert',
    CustomDescription:
      'Sie können die Liste anpassen, um nur die benötigten Datensätze herunterzuladen und/oder zu speichern',
    CustomTracks: 'Gespeicherte Strecken',
    CustomTracksDefaultSelection: 'Kein benutzerdefinierter Track ausgewählt',
    SaveCustomTrack: 'Benutzerdefinierte Strecke speichern',
    SaveCustomTrackQuestion: 'Möchten Sie diesen Filtersatz als benutzerdefinierte Spur speichern?',
    SaveCustomTrackTitle: 'Benutzerdefinierte Strecke speichern',
    SaveCustomTrackDescription:
      'Geben Sie den Namen ein, den Sie zum Speichern Ihres benutzerdefinierten Tracks verwenden möchten, oder wählen Sie einen vorhandenen aus, um ihn zu überschreiben.',
    SaveCustomTrackName: 'Benutzerdefinierter Titelname',
    SaveCustomTrackUpdateExisting: 'Update verfügbar',
    SaveCustomTrackCreateNew: 'Erstelle neu',
    DownloadExample: 'Beispiel herunterladen',
    Download: 'Herunterladen',
    ForEveryCompanyInList: 'für jedes Unternehmen in der Liste',
    ToDownloadYouNeedToPurchase: 'Um die Liste herunterzuladen, müssen Sie {{companyName}}-Firmendaten erwerben',
    YouAreDownloading:
      'Sie laden eine Excel-Datei mit allen angeforderten {{companyName}}-Daten herunter. Es wird im Bereich "Verwendung" Ihres Kontos gespeichert.',
    CustomExport: {
      StepTitle: 'Daten auswählen',
      Description1: 'Wählen Sie die gewünschten Daten zum Download aus.',
      Description2: 'Die Downloadkosten sind für jede Art von Liste identisch',
      DataPacket: 'Data Paket',
      DataPacketSearchPlaceholder: 'Finden Sie Datenpakete oder bestimmte Felder'
    },
    Dialog1: 'Die angeforderte Datei wird verarbeitet und kann einige Minuten dauern.',
    Dialog2: 'Sie können den Fortschritt über das Benachrichtigungsmenü verfolgen.',
    SaveSuccess: 'als benutzerdefinierte Strecke gespeichert',
    SaveSuccessDescription: 'Sie finden den Track, der im Repository gespeichert ist, bezogen auf das Referenzgebiet.'
  },
  Enrichment: {
    PortfolioEnrichment: 'Die Anreicherung könnte einige Minuten dauern. Möchten Sie damit fortfahren?',
    Enrichment: 'Anreicherung',
    EnrichmentComplete: 'Anreicherung abgeschlossen',
    EnrichmentDescription:
      'Durch die Anreicherung des Unternehmens mit {{companyName}}-Daten werden diese Informationen heruntergeladen und regelmäßig aktualisiert',
    CompaniesInPortfolio: 'Firmen in diesem Portfolio',
    CompaniesEnriched: 'Unternehmen bereits angereichert',
    CompaniesNotEnriched: 'Nicht angereicherte Unternehmen',
    CreditsWillBeUsed: 'Benutzte Rings',
    CreditsRemaining: 'Verbleibende Rings',
    CompaniesSuccesfullyEnriched: 'Unternehmen angereichert'
  },
  CompanyDeletion: {
    ConfirmDelete: 'Möchten Sie das Unternehmen wirklich löschen?',
    DeleteSuccess:
      'Firma korrekt gelöscht. Möglicherweise müssen Sie einige Sekunden warten, bis die Änderungen wirksam werden.'
  },
  CompaniesMove: {
    ConfirmMove:
      'Das von Ihnen ausgewählte Unternehmen wird aus dem aktuellen Portfolio entfernt und einem anderen Portfolio hinzugefügt. Bitte wählen Sie ein Portfolio aus der untenstehenden Liste aus.',
    ConfirmMove_plural:
      'Von Ihnen ausgewählte Unternehmen werden aus dem aktuellen Portfolio entfernt und einem anderen Portfolio hinzugefügt. Bitte wählen Sie ein Portfolio aus der untenstehenden Liste aus.',
    MoveSuccess: 'Der Verschiebevorgang wird ausgeführt, dies kann einige Minuten dauern.',
    SelectPortfolio: 'Portfolio auswählen'
  },
  CompanyAssignment: {
    ConfirmOperation: 'Ausführung bestätige',
    ProductAssignment:
      'Das Produkt {{product}} wird den ausgewählten Unternehmen zugewiesen. Dies kann einige Minuten dauern. Mit der Operation fortfahren?',
    SalesAssignment:
      '{{sales}} wird den ausgewählten Unternehmen zugewiesen. Dies kann einige Minuten dauern. Mit der Operation fortfahren?',
    AreaManagerAssignment:
      'Zuordnung des Verkäufers {{manager}} zu den ausgewählten Unternehmen. Dies kann einige Minuten dauern. Mit der Operation fortfahren?',
    ProductUnassignment:
      'Nichtzuordnung zu den ausgewählten Unternehmen. Dies kann einige Minuten dauern. Fahren Sie mit der Operation fort?',
    SalesUnassignment:
      'Nichtzuordnung zu den ausgewählten Unternehmen. Dies kann einige Minuten dauern. Fahren Sie mit der Operation fort?',
    AreaManagerUnassignment:
      'Nichtzuordnung zu den ausgewählten Unternehmen. Dies kann einige Minuten dauern. Fahren Sie mit der Operation fort?'
  },
  DialogError: 'Ein Fehler ist beim Verarbeiten der Anfrage aufgetreten.',
  Credits: 'Rings',
  LoadQuery: {
    DialogTitle: 'Gespeicherte Suche laden',
    SaveBefore: {
      Question: 'Möchten Sie die Liste der Prospects speichern, bevor Sie die Suche laden?',
      Description:
        'Speichern Sie Ihre Prospect-Liste in einem Portfolio oder erstellen Sie eine neue, bevor Sie die Abfrage hochladen, um Datenverluste zu vermeiden',
      OkBtn: 'Liste speichern',
      CancelBtn: 'Speichern überspringen'
    }
  },
  DomainFilter: {
    SaveAndClose: 'Filter speichern & schließen'
  },
  ManageDuplicates: {
    Title: 'Dubletten verwalten',
    Description: 'Um ein Unternehmen zu duplizieren, müssen Sie den Status oder das Produkt ändern',
    GroupActions: 'Gruppenaktionen',
    Duplicates: '{{count}} Dublette',
    Duplicates_plural: '{{count}} Dubletten',
    ShowOK: 'Anzeigen OK',
    HideOK: 'Ausblenden OK',
    SelectColumns: 'Spalten auswählen'
  },
  GenerateSimilarity: {
    Title: 'Similarity erzeugen',
    Description: 'Wählen Sie die Art von Unternehmen aus, zu der Sie vergleichbare Unternehmen finden möchten',
    DropdownPlaceholder: 'Unternehmensart auswählen',
    GotoActionButton: 'wählen'
  },
  Campaign: {
    YouNeedToEnrich: 'Um eine Kampagne zu starten, müssen alle Unternehmen im Portfolio angereichert sein.'
  },
  CreatingPortfolio: 'Portfolio erstellen',
  UpdatingPortfolio: 'Portfolio aktualisieren',
  Share: {
    SharePortfolioTitle: 'Portfolio teilen',
    Subtitle: 'Sales Mitarbeiter auswählen',
    Description: 'Wählen Sie die Sales Mitarbeiter aus, mit denen Sie das Portfolio teilen möchten.',
    Success: 'Teilen des Portfolios erfolgreich ausgeführt',
    ShareCompany: 'Unternehmen teilen',
    DeleteSharing: 'Teilen beenden',
    SharedCompanies: 'Unternehmen geteilt',
    SharedPortfolio: 'Portfolio wird geteilt',
    SharePortfolio: 'Portfolio teilen',
    SharedByOwner: 'Geteilt von {{ownerName}}',
    NotActiveUser: 'benutzer nicht aktiv',
    ShareCompanies: {
      Title: 'Unternehmen teilen',
      Subtitle: 'Benutzer auswählen',
      Description: 'Wählen Sie die Benutzer aus, mit denen Sie die Unternehmen teilen möchten.',
      WithAreaManager: 'Unternehmen mit Area Manager teilen',
      WithSales: 'Unternehmen mit Sales teilen',
      ConfirmTitle: 'Ausführung bestätigen',
      ConfirmSubtitle: 'Das Teilen mit den selektierten Benutzern wird ausgeführt.',
      ConfirmDescription: 'Dies kann ein paar Minuten in Anspruch nehmen. Möchten Sie fortfahren?',
      Check: 'Überprüfen, ob alle Unternehmen für die Freigabe gültig sind.',
      CheckFail: 'Um fortzufahren, müssen Sie die ausgewählten Unternehmen zuweisen.'
    },
    UnshareCompanies: {
      Subtitle: 'Benutzer auswählen',
      Description: 'Wählen Sie die Benutzer aus, mit denen Sie die Unternehmen teilen möchten.',
      WithAreaManager: 'Teilen mit Area Manager beenden',
      WithSales: 'Teilen mit Sales beenden',
      ConfirmTitle: 'Ausführung bestätigen',
      ConfirmSubtitle: 'Das Teilen mit den selektierten Benutzern wird ausgeführt.',
      ConfirmDescription: 'Dies kann ein paar Minuten in Anspruch nehmen. Möchten Sie fortfahren?',
      Check: 'Überprüfen, ob alle Unternehmen für die Freigabe gültig sind.',
      CheckFail: 'Um fortzufahren, müssen Sie die ausgewählten Unternehmen zuweisen.'
    }
  },
  Exclude: {
    Title: 'Portfolio ausschließen',
    ExcludedPortfolio: 'Ausgeschlossenes Portfolio',
    Description: 'Wählen Sie Benutzer aus, die das ausgewählte Portfolio im Ausschluss erhalten',
    Success: 'Ausschlussfreigabe korrekt ausgeführt.',
    Error: 'Es tut uns leid, aber wir konnten dieses Portfolio nicht ausschließen.'
  },
  AddLocalUnits: {
    Title: 'Lokale Einheit hinzufügen',
    NoCompanySelected: 'Kein Unternehmen ausgewählt. Bitte fügen Sie mindestens einen Hauptsitz hinzu.',
    NoHeadquarter:
      'Das ausgewählte Portfolio enthält bereits lokale Einheiten. Bitte fügen Sie mindestens einen Hauptsitz hinzu.',
    NoHeadquarterSelected:
      'Die ausgewählten Unternehmen sind bereits lokale Einheiten. Bitte fügen Sie mindestens einen Hauptsitz hinzu.',
    Enrich: {
      Title: 'Um lokale Einheiten hinzuzufügen, müssen Sie Unternehmensdaten von {{companyName}} erwerben',
      Description:
        'Sie fügen lokale Einheiten zu einem Portfolio hinzu, das alle angeforderten {{companyName}}-Daten enthält. Es wird im Abschnitt „Verwenden“ Ihres Kontos gespeichert.'
    },
    ChooseBranch: {
      Title: 'Wählen Sie den Zweig aus, den Sie hinzufügen möchten.',
      Description: 'Wählen Sie den Zweigtyp aus, der hinzugefügt werden soll.',
      All: 'Alle Filialtypen',
      DeleteAll: 'Alles löschen'
    },
    ChooseStatus: {
      Title: 'Wählen Sie den Status aus, den Sie hinzufügen möchten.',
      Description: 'Wählen Sie den Ausgangsstatus der hinzuzufügenden Unternehmen aus.'
    },
    CreateTag: {
      Title: ' Tag erstellen',
      Description: 'Jedes Unternehmen wird durch ein von Ihnen erstelltes Tag definiert.',
      Label: 'Tag erstellen (optional)',
      Placeholder: 'Tag einfügen'
    }
  },
  EditPortfolioCompany: {
    Title: 'Unternehmenswerte bearbeiten',
    Subtitle: 'Werte ändern',
    Description: 'Wählen Sie die Felder aus, die Sie ändern möchten, und fügen Sie den neuen Wert in das Feld ein.',
    CompanyStatus: 'Firmen Portfolio Status',
    Amount: 'Umsatz',
    AreaManager: 'Area Manager',
    Sales: 'Sales',
    Product: 'Produkt',
    Tags: 'Tags',
    Save: 'Speichern',
    Error: 'Fehler',
    ErrorDescription: 'Daten konnten nicht geladen werden.',
    SaveSuccessTitle: 'Änderungen gespeichert',
    SaveSuccessText: 'Die Änderungen wurden korrekt gespeichert.',
    SaveErrorTitle: 'Fehler',
    R_24: 'Die Aktualisierung des Unternehmens würde zu Duplizität führen. Unternehmen mit demselben „Objekt im Portfolio“ und „Produkt“ existiert bereits im Portfolio'
  },
  SendToOtherModulesDialog: {
    Title: 'Modul auswählen',
    SubTitle: 'Wählen Sie das Modul aus, an das Sie Ihre Analyse oder Liste senden möchten',
    Targeting: 'Analyze market',
    TargetingDescription:
      'Klicken Sie hier, um die Liste der identifizierten Unternehmen an das Analyze market Modul zu senden und aktualisierte Diagramme einzusehen, die die Verteilung der Unternehmen in Bezug auf die Hauptvariablen darstellen.',
    SalesTool: 'Sales tool',
    SalesToolDescription:
      'Klicken Sie hier, um die Liste der identifizierten Unternehmen an das Sales tool Modul zu senden. Die Liste wird in einem Portfolio gespeichert und die Daten der Unternehmen werden angereichert. Innerhalb des Sales tool Moduls können Sie eine Verkaufskampagne für die ausgewählten Unternehmen starten und diese Ihrem Vertriebsteam zuweisen.',
    OkButton: 'Senden',
    DisabledTargetingTooltip: 'Diese Aktion ist nur für Portfolios mit mehr als {{count}} Unternehmen möglich'
  },
  MonitoringRemoval: {
    Title: 'Nicht mehr folgen',
    ButtonTooltip: 'Sie folgen diesem Portfolio. Klicken Sie hier, um nicht mehr zu folgen.',
    UnfollowSuccess: 'Die Stornierung der Portfolioverfolgung wurde erfolgreich durchgeführt.',
    UnfollowAndSaveSuccess:
      'Der Abbruch der Portfolioverfolgung wurde erfolgreich durchgeführt und die Abfrage {{queryName}} wurde erstellt.',
    UnfollowAndUpdateSuccess:
      'Der Abbruch der Portfolioverfolgung wurde erfolgreich durchgeführt und die Abfrage {{queryName}} wurde aktualisiert',
    UnfollowFailure:
      'Die Stornierung der Portfolioverfolgung ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie unseren Support.',
    WarningMessage:
      'Durch Auswahl von Speichern wird dieses Portfolio nicht mehr verfolgt. Beachten Sie, dass es nicht mehr möglich sein wird, dieses Portfolio in einen verfolgten Zustand zu versetzen. Möchten Sie die Abfrage speichern, die zur Erstellung dieses Portfolios verwendet wurde'
  },
  UnlockPortfolioEsgDataDialog: {
    Title: 'Schalten Sie den ESG-Score frei',
    SubTitle: 'Sind Sie sicher, dass Sie ESG-Scores freischalten möchten?',
    UnlockText:
      'Wir prüfen und entsperren alle Unternehmen, die über einen ESG-Unternehmensscore verfügen. Die Daten sind ab dem Entsperrdatum sechs Monate lang sichtbar, oder bis zum Enddatum des Abonnements, falls früher. Jede Freischaltung kostet ein ESG-Guthaben. Wenn die Zeit für ESG-Daten freigeschalteter Unternehmen abläuft, müssen Sie diese erneut freischalten. Am Ende des Vorgangs senden wir eine Benachrichtigung. Es kann einige Minuten dauern.',
    UnlockRequestedText:
      'Die Freischaltung des ESG-Unternehmensscores wurde erfolgreich angefordert. Es kann einige Minuten dauern. Sie können dieses Dialogfenster jetzt schließen.',
    EnrichmentRequiredText:
      'Der Vorgang kann leider nicht ausgeführt werden, da in der Liste nicht angereicherte Unternehmen vorhanden sind. Bereichern Sie sie, um fortzufahren, oder wählen Sie nur angereicherte Unternehmen aus.',
    OkUnlock: 'Konfirmieren',
    OkEnrich: 'Anreichern',
    ErrorUnlockingEsgData:
      'Unerwarteter Fehler beim Freischalten des ESG-Unternehmensscores. Bitte versuchen Sie es später noch einmal.'
  }
}
