import { getCompaniesCount } from '@containers/SaveList/utils'
import { TFunction } from 'i18next'
import _ from 'lodash'
import dayjs from 'dayjs'
import XRegExp from 'xregexp'
import { SaveListNewPortfolioType } from '../types/common'

export type FormError = { error: string; errorMessage?: string; data: any; translated?: boolean } | string | undefined

export type Validator<T = any> = (x: any, values: T, props: any) => FormError | FormError[]
export type ValidatorConfig = Record<string, Validator[]>

export const formValidator =
  <T extends Record<string, any>, P = any>(validators: ValidatorConfig) =>
  (values: T, props: P) =>
    _.reduce(
      validators,
      (errors, fieldValidators, field) => {
        const fieldValue = values[field]
        const firstFailing = _.find(fieldValidators, validate => validate(fieldValue, values, props))
        if (firstFailing) {
          return _.set(errors, field, firstFailing(fieldValue, values, props))
        }
        return errors
      },
      {}
    )

export const required = (value: any): FormError => {
  if (!value) {
    return 'Required'
  }
  return undefined
}

export const requiredWithoutFieldName = (value: any): FormError => {
  if (!value) {
    return 'RequiredWithoutFieldName'
  }
  return undefined
}

export const length =
  ({ min, max }: { min?: number; max?: number }) =>
  (value: string) => {
    if (_.isNumber(min) && _.isNumber(max) && (min > _.size(value) || max < _.size(value))) {
      return { error: 'Length', data: { min, max } }
    }
    if (_.isNumber(min) && min > _.size(value)) {
      return { error: 'LengthShort', data: { min } }
    }
    if (_.isNumber(max) && max < _.size(value)) {
      return { error: 'LengthLong', data: { max } }
    }
    return undefined
  }

export const emptyOrLength =
  ({ min, max }: { min?: number; max?: number }) =>
  (value: string) => {
    if (_.isEmpty(value)) {
      return undefined
    }
    return length({ min, max })(value)
  }

export const cannotStartWithSpace = (value: string) => (_.startsWith(value, ' ') ? 'CannotStartWithSpace' : undefined)

export const allowedCharacters =
  (allowedRegex: RegExp) =>
  (value: string): FormError => {
    if (!allowedRegex.test(value)) {
      return { error: 'NoSpecialCharacters', data: {} }
    }
    return undefined
  }

const chars = XRegExp('^[\\p{Common}\\p{Latin}\\s!"§$%&/()=?,;.:+*#\'@<>€_-]+$')

export const noSpecialCharacters = (value: string): FormError => {
  if (!chars.test(value)) {
    return { error: 'NoSpecialCharacters', data: {} }
  }
  return undefined
}

const charsSubset = XRegExp('^[0-9\\p{Latin}\\s_-]+$')

export const noSpecialCharactersSubset = (value: string): FormError => {
  if (!charsSubset.test(value)) {
    return { error: 'NoSpecialCharacters', data: {} }
  }
  return undefined
}

// eslint-disable-next-line no-useless-escape
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmail = (value: string): FormError => {
  if (value && !emailRegex.test(_.toLower(value))) {
    return 'NotEmail'
  }
  return undefined
}

export const areCommaSeparatedEmails = (value: string): FormError => {
  if (_.isEmpty(value) || _.isNil(value)) return undefined
  const isValid = _.every(_.split(value, ','), item => {
    return isEmail(_.trim(item)) === undefined
  })
  if (!isValid) {
    return 'NotEmail'
  }
  return undefined
}

export const onlyNumbersOrSpace =
  ({ canBeEmpty, label }: { canBeEmpty?: boolean; label?: string }) =>
  (value: string): FormError => {
    if (canBeEmpty && _.isEmpty(value)) return undefined

    const isValid = /^[0-9,. ]+$/.test(value)
    if (!isValid) return { error: 'OnlyNumbersOrSpace', data: { Label: label ?? '' } }
    return undefined
  }

export const onlyIntegers = (value: number): FormError => {
  if (!_.isInteger(value)) {
    return { error: 'OnlyIntegers', data: {} }
  }
  return undefined
}

export const disabledStartDate = (current: dayjs.Dayjs | null | undefined): boolean => {
  // Can not select days before today
  if (current) {
    return current && current <= dayjs().subtract(1, 'day').endOf('day')
  }
  return false
}

export const disabledEndDate = (
  current: dayjs.Dayjs | null | undefined,
  startDate: dayjs.Dayjs | null | undefined
): boolean => {
  // Can not select days before today
  if (current && startDate) {
    return current && current <= startDate.endOf('day')
  }
  return false
}

export function isInRange(range: { min: number; max: number }, value?: number) {
  return !_.isUndefined(value) && value >= range.min && value <= range.max
}

export const isUrl = (value: string) => {
  const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
  if (!regex.test(value)) return 'InvalidUrl'
  return undefined
}

export const isPhoneNumber = (value: string) => {
  const regex = /^\+?[0-9 ]{6,15}$/
  if (value && !regex.test(value.trim())) return 'InvalidPhone'
  return undefined
}

export const validateInput = ({
  value,
  validators,
  isRequired,
  errorHandler = _.noop
}: {
  value?: string
  validators?: ((value: string) => FormError)[]
  isRequired?: boolean
  errorHandler?: (value: any) => void
}) => {
  if (isRequired && (_.isNil(value) || _.isEmpty(value))) {
    errorHandler('Required')
    return
  }

  if (_.isNil(value) || _.isEmpty(value)) {
    errorHandler(undefined)
    return
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const validator of validators || []) {
    const possibleError = validator(value)
    if (possibleError) {
      errorHandler(possibleError)
      return
    }
  }

  errorHandler(undefined)
}

export const formatError = (error: FormError, t: TFunction, options?: Record<string, string>) => {
  if (!error) return undefined

  if (_.isString(error)) {
    return t(`error:${error}`, options ?? {})
  }

  return t(`error:${error.error}`, { ...error.data, ...options } as Record<string, string>)
}

export const getPasswordsMustMatchValidator = (pwd1?: string) => (pwd2: string) =>
  pwd1 !== pwd2 ? { error: 'PasswordDoesNotMatch', data: {} } : undefined

export const getPasswordsMustNotMatchValidator = (pwd1?: string) => (pwd2: string) =>
  pwd1 === pwd2 ? { error: 'PasswordSameAsOld', data: {} } : undefined

export type SaveListCountValidatorProps = {
  numberOfCompanies: number
  groupAction?: boolean
  isSelectAll: boolean
  selectedRows?: any[]
  deselectedRows?: any[]
  newPortfolioType: SaveListNewPortfolioType
  maxCompaniesCount: number
}

export const saveListCountValidator = (
  value: number,
  {
    numberOfCompanies,
    groupAction,
    isSelectAll,
    selectedRows,
    deselectedRows,
    newPortfolioType,
    maxCompaniesCount
  }: SaveListCountValidatorProps
) => {
  if (newPortfolioType !== SaveListNewPortfolioType.Partial) return undefined

  const companiesCount = getCompaniesCount(
    numberOfCompanies,
    groupAction ?? false,
    isSelectAll,
    selectedRows,
    deselectedRows
  )

  const maxCount = Math.min(companiesCount, maxCompaniesCount)

  if (value < 1 || value > maxCount) {
    return {
      error: 'MinMax',
      data: {
        min: 1,
        max: maxCount
      }
    }
  }

  return undefined
}

export const getOneOfRequiredValidator =
  (otherValue: string | undefined, fieldLabels: { field: string; otherField: string }) =>
  (value: string | undefined) => {
    if ((!value || _.isEmpty(value)) && (!otherValue || _.isEmpty(otherValue)))
      return { error: 'OneOfRequired', data: fieldLabels }
    return undefined
  }

export const validateNameSynchronous = (specialCharacter?: boolean) => (value: any) => {
  const requiredResult = required(value)
  if (requiredResult) return requiredResult

  const cannotStartWithSpaceResult = cannotStartWithSpace(value)
  if (cannotStartWithSpaceResult) return cannotStartWithSpaceResult

  if (specialCharacter) {
    const noSpecialCharactersResult = noSpecialCharacters(value)
    if (noSpecialCharactersResult) return noSpecialCharactersResult
  }

  const lengthResult = length({ min: 5, max: 35 })(value as string)
  if (lengthResult) return lengthResult

  return undefined
}
