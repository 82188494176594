import { Reducer } from 'redux'
import * as actions from './actions'

export type ProspectingState = Readonly<{}>

export const initState: ProspectingState = {}

export const prospectingReducer: Reducer<ProspectingState, actions.ProspectingActions> = (state = initState) => {
  return state
}
