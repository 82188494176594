import _ from 'lodash'
import { Dispatch } from 'redux'
import { CompoundExpression, Expression, FieldExpression, FieldOperator } from '@api/expressionTypes'
import { FilterQueryDto, FilterStructure, FilterStructureResponse } from '../../api/filters/types'
import { FilterReducerPath, FilterDefinition, FilterGroup, FilterOptionType, SemanticSearch } from './types'

import { Dictionary } from '../../utils/types'
import { DataTree, KeyTree, TreeKeySelection, TreeKeySelectionWithLength, TreePath } from '../../utils/tree'
import {
  isHierarchyExpression,
  isCompoundExpression,
  isFieldExpression,
  isNumericRangeExpression,
  isDateRangeExpression,
  isSemanticExpression,
  isGeoDistanceExpression,
  isGeoPolygonExpression,
  isCustomVarFieldExpression,
  isCustomVarNumericRangeExpression,
  isCustomVarDateRangeExpression,
  isCustomVarExpression
} from '../operations/guards'
import {
  CAMPAIGN_IDS_FILTER,
  COMPANY_SEARCH_GROUP_ID,
  CUSTOM_FILTERS_GROUP,
  GROUP_USER_FILTERS,
  PORTFOLIO_IDS_FILTER
} from './constants'

import { DomainFilterSelector } from './types/domainFilter'
import { reduceToSelectionByFieldId } from '../../pages/Operations/QueryBuilder/utils'
import { addNewHierarchyExpression, getDefaultRootExpression, getEmptyFieldExpression } from '../operations/utils'
import { getFieldIdFromExpression } from '../../components/ContextFilters/utils'
import { filterActions } from './actions'
import { CustomerCompanyVisibilityQuery } from '../../api/customerCompanyVisibility/types'

const getFilterStructureType = (filterStructure: FilterStructure) => {
  if (filterStructure.children && filterStructure.children.length) {
    return FilterOptionType.Container
  }
  if (filterStructure.filterHierarchy) {
    return FilterOptionType.Hierarchy
  }
  return FilterOptionType.Simple
}

export const formatFilterGroupChildren = (filterGroupChildren: FilterStructure[], parentPath: string[]) =>
  _.map(filterGroupChildren, (filter): FilterDefinition => {
    const id =
      filter.isCustomVar && !_.isNil(filter.position)
        ? `${filter.position}`
        : filter.id ?? filter.filterHierarchy ?? filter.fieldId ?? `Virtual-${filter.text}`
    return {
      id,
      name: filter.text,
      enabled: filter.enabled !== false,
      description: filter.description,
      isCustomVar: filter.isCustomVar,
      position: filter.position,
      type: getFilterStructureType(filter),
      children: formatFilterGroupChildren(filter.children ?? [], [...parentPath, id]),
      parentPath,
      isMostPopularFilter: filter.isMostPopularFilter,
      hasExactSearch: filter.hasExactSearch,
      exactSearchTooltip: filter.exactSearchTooltip
    }
  })

export const formatFilterGroupsFromResponse = (
  data: FilterStructureResponse | undefined,
  isCustomIndexesOnTop?: boolean
) => {
  if (data === undefined) return []

  let filterGroups = data.filtersGroups
  if (isCustomIndexesOnTop) {
    filterGroups = _.sortBy(filterGroups, filterGroup => {
      return filterGroup.id === CUSTOM_FILTERS_GROUP ? -1 : 1
    })
  }

  return _.map(
    filterGroups,
    (x): FilterGroup => ({
      id: x.id,
      name: x.text,
      description: x.description,
      showInfo: x.showInfoIcon,
      type: FilterOptionType.Container,
      children: formatFilterGroupChildren(x.filters, [])
    })
  )
}

export const isIdStandardFilters = (groupId: string) => groupId === 'standardFilters'

export const mapFilterDefinitionToTreeKeySelection = (
  filterDefinitionArr: FilterDefinition[],
  groupId: string
): TreeKeySelection[] => {
  if (!isIdStandardFilters(groupId)) return []
  return _.map(filterDefinitionArr, filter => ({
    key: filter.id,
    parentPath: filter.parentPath as TreePath
  }))
}

const getSortingOrderForMostPopularFilters = (groupId: string) => {
  if (isIdStandardFilters(groupId)) {
    return [
      'geo',
      'ateco',
      'businessInfo.turnover',
      'businessInfo.employee',
      'businessInfo.companiesWithoutNegativeEvents'
    ]
  }
  return []
}

export const getMostPopularFiltersFromFiltersGroups = (filtersGroup: FilterGroup[], groupId: string) => {
  if (!isIdStandardFilters(groupId)) return []
  const standardFiltersGroup = _.filter(filtersGroup, { id: groupId })
  const standardFilters = standardFiltersGroup?.[0]?.children
  const mostPopularFilters: any[] = []
  const sorting = getSortingOrderForMostPopularFilters(groupId)

  const searchForMostPopularFilters = (filters: FilterDefinition[]): any => {
    _.forEach(filters, filter => {
      if (filter.isMostPopularFilter) {
        mostPopularFilters.push(filter)
      }
      if (filter.children) {
        searchForMostPopularFilters(filter.children)
      }
    })
  }
  searchForMostPopularFilters(standardFilters)

  const mostPopularFiltersAsKeySelection: TreeKeySelection[] = mapFilterDefinitionToTreeKeySelection(
    mostPopularFilters,
    groupId
  ).sort((a, b) => sorting.indexOf(a.key) - sorting.indexOf(b.key))

  return mostPopularFiltersAsKeySelection
}

export function extractFiltersFromFilterGroups(groups?: FilterGroup[], selectedFilters?: Set<string>) {
  function processContainerChildren(filterDefinitions: FilterDefinition[]): Dictionary<FilterDefinition> {
    return _.reduce(
      filterDefinitions,
      (acc: Dictionary<FilterDefinition>, f) => {
        if (f.type !== FilterOptionType.Container) {
          if ((selectedFilters as Set<string>).has(f.id)) {
            acc[f.id] = f
            return acc
          }
        }
        return {
          ...acc,
          ...processContainerChildren(f.children)
        }
      },
      {}
    )
  }

  if (groups === undefined || selectedFilters === undefined) return {}

  return _.reduce(
    groups,
    (acc: Dictionary<FilterDefinition>, filterGroup) => ({
      ...acc,
      ...processContainerChildren(filterGroup.children)
    }),
    {}
  )
}

export function processDataTreeChildren(
  children: FilterDefinition[] | undefined,
  path: string[]
): Record<string, DataTree> | undefined {
  if (!children || !children.length) return undefined

  return _.reduce(
    children,
    (acc: Record<string, DataTree>, child) => {
      acc[child.id] = {
        isRoot: false,
        key: child.id,
        hasChildren: !_.isEmpty(child.children),
        label: child.name,
        parentPath: [...path],
        children: processDataTreeChildren(child.children, [...path, child.id]),
        childrenOrder: undefined,
        data: child,
        disabled: !child.enabled
      }
      return acc
    },
    {}
  )
}

export function getFilterDataTreeByGroupId(filterGroups: FilterGroup[] | undefined, groupId: string) {
  const groups = _.values(filterGroups)
  const group = _.find(groups, ['id', groupId])

  if (!group) return undefined

  const tree: DataTree<FilterDefinition> = {
    isRoot: true,
    parentPath: null,
    key: groupId,
    label: group.name,
    hasChildren: !_.isEmpty(group.children),
    children: processDataTreeChildren(group.children, []),
    childrenOrder: undefined
  }

  return tree
}

function processKeyChildren(node: FilterDefinition, tree: KeyTree, path: string[]) {
  _.set(tree, [...path, node.id], node.children && node.children.length ? {} : null)
  _.forEach(node.children, child => {
    processKeyChildren(child, tree, [...path, node.id])
  })
}

export function getKeyTreeForFilterSelection(filterGroups: FilterGroup[] | undefined, groupId: string) {
  if (filterGroups === undefined) return undefined

  const group = _.find(filterGroups, { id: groupId })
  const tree = {} as KeyTree

  if (group?.children?.length) {
    _.forEach(group.children, child => {
      processKeyChildren(child, tree, [])
    })
  }

  return tree
}

export const convertHierarchicalExpressionForBackend = (expression: Expression) => {
  if (
    isHierarchyExpression(expression) &&
    expression.childExpressions &&
    expression.childExpressions.length > 0 &&
    !expression.additionalData.isMultiSelection &&
    expression.additionalData.singleSelectionTargetJsonPath
  ) {
    const mergedValues = _.reduce(
      expression.childExpressions,
      (acc: string[], fieldExpression) => {
        return _.concat(acc, fieldExpression.value as string[])
      },
      []
    )

    return {
      ...expression,
      childExpressions: [
        {
          ...expression.childExpressions[0],
          fieldId: expression.additionalData.singleSelectionTargetJsonPath,
          value: mergedValues
        } as FieldExpression
      ]
    }
  }

  return expression
}

export const convertFieldTextArrayExpressionForBackend = (expression: Expression) => {
  if (isFieldExpression(expression) && _.isArray(expression.value)) {
    const values = _.compact(_.map(expression.value, value => _.trim(value)))

    return {
      ...expression,
      value: values
    }
  }

  return expression
}

export const convertCompoundExpressionBeforeUse = (expression: CompoundExpression): CompoundExpression => ({
  ...expression,
  childExpressions: _.compact(
    _.map(expression.childExpressions, e => {
      if (isCompoundExpression(e)) return convertCompoundExpressionBeforeUse(e)
      if (isFieldExpression(e) && _.isNil(e.value)) return undefined
      if (isCustomVarFieldExpression(e) && _.isNil(e.value)) return undefined
      if (isHierarchyExpression(e) && _.isEmpty(e.childExpressions)) return undefined
      return e
    })
  )
})

export const convertCompoundExpressionForBackend = (query: CompoundExpression): CompoundExpression => {
  // ListOfHierarchical when multiSelection is off should send singleSelectionFieldId instead hierarchical fieldId
  const childExpressions = _.map(query.childExpressions, childExpression => {
    if (isCompoundExpression(childExpression)) {
      return convertCompoundExpressionForBackend(childExpression)
    }
    if (isFieldExpression(childExpression) && _.isArray(childExpression.value)) {
      return convertFieldTextArrayExpressionForBackend(childExpression)
    }
    return convertHierarchicalExpressionForBackend(childExpression)
  })

  return { ...query, childExpressions }
}

export const isNonEmptyExpression = (expression: Expression) => {
  if (isHierarchyExpression(expression)) {
    if (!_.isEmpty(expression.childExpressions)) return true
  } else if (isFieldExpression(expression) || isCustomVarFieldExpression(expression)) {
    if (!_.isNil(expression.value)) return true
  } else if (
    isNumericRangeExpression(expression) ||
    isDateRangeExpression(expression) ||
    isCustomVarNumericRangeExpression(expression) ||
    isCustomVarDateRangeExpression(expression)
  ) {
    if (!_.isNil(expression.maxValue) && !_.isNil(expression.minValue)) return true
  } else if (isSemanticExpression(expression)) {
    if (!_.isEmpty(expression.synonyms) || !_.isEmpty(expression.search)) return true
  } else if (isGeoDistanceExpression(expression)) {
    if (!_.isNil(expression.distanceKm) && !_.isEmpty(expression.point)) return true
  } else if (isGeoPolygonExpression(expression)) {
    if (!_.isEmpty(expression.points)) return true
  } else if (isCompoundExpression(expression)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (isAnyFilterApplied(expression)) return true
  }
  return false
}
export const isAnyFilterApplied = (expression: CompoundExpression) => {
  return _.some(expression.childExpressions, isNonEmptyExpression)
}

export const isSemanticSearchApplied = (semanticSearch: SemanticSearch) =>
  !_.isEmpty(semanticSearch.searchText) || !_.isEmpty(semanticSearch.appliedSynonyms)

export const convertKeySelectionToFieldExpressions = (
  newValues: TreeKeySelection[],
  selectors: DomainFilterSelector[],
  isExcludeFilter: boolean | undefined
) => {
  const withParentPathLength = _.map(newValues, newValue => {
    return { ...newValue, parentPathLength: newValue.parentPath?.length } as TreeKeySelectionWithLength
  })
  const mergedNodes = reduceToSelectionByFieldId(withParentPathLength, selectors)

  return _.map(mergedNodes, node => {
    return getEmptyFieldExpression(
      node.fieldId,
      node.value,
      isExcludeFilter ? FieldOperator.IsNotOneOf : FieldOperator.IsOneOf
    )
  })
}

type SetExpressionAndSelectedFilterGroupsParams = {
  currentEntityId?: string
  saveByEntityId?: boolean
  userId: string
  reducer: FilterReducerPath
  query: FilterQueryDto
  omitUserFilters?: boolean
}

type OmittableFilterGroup = 'userFilters'

export const filterChildExpressionsByIds = (
  expression: CompoundExpression,
  filtersToOmit: string[]
): CompoundExpression => ({
  ...expression,
  childExpressions: _.reduce(
    expression.childExpressions,
    (acc, exp) => {
      if (isCompoundExpression(exp)) {
        const newCompoundExpression = filterChildExpressionsByIds(exp, filtersToOmit)
        if (!_.isEmpty(newCompoundExpression.childExpressions)) {
          return [...acc, newCompoundExpression]
        }
      }
      return _.includes(filtersToOmit, getFieldIdFromExpression(exp)) ? acc : [...acc, exp]
    },
    [] as Expression[]
  )
})

export function setExpressionAndSelectedFilterGroups(
  dispatch: Dispatch,
  setExpression: (expression: CompoundExpression) => void,
  params: SetExpressionAndSelectedFilterGroupsParams
) {
  const { currentEntityId, saveByEntityId = true, userId, reducer, query, omitUserFilters } = params

  const actions = filterActions(reducer)

  const groupsToOmit: OmittableFilterGroup[] = []
  if (omitUserFilters) {
    groupsToOmit.push(GROUP_USER_FILTERS)
  }

  _.forEach(query.filterGroups, (filterGroup, key) => {
    if (!_.includes(groupsToOmit, key))
      dispatch(
        actions.setSelectedFilterGroup({
          filters: filterGroup,
          groupId: key,
          userId,
          entityId: saveByEntityId ? currentEntityId : undefined
        })
      )
  })

  const filtersToIgnore = _.reduce(
    groupsToOmit,
    (acc: string[], curr) => [...acc, ..._.map(_.get(query.filterGroups, curr), 'key')],
    []
  )

  if (query.query) {
    const withoutEmptyValues = convertCompoundExpressionBeforeUse(query.query)
    setExpression(filterChildExpressionsByIds(withoutEmptyValues, filtersToIgnore))
  } else {
    setExpression(getDefaultRootExpression())
  }
}

export const convertSelectedFilterGroupKeysToVisibleFilters = (
  selectedFilterGroupsKeys: Record<string, TreeKeySelection[]>
) =>
  _.flatMap(selectedFilterGroupsKeys, selectedFilterGroupItems =>
    _.map(selectedFilterGroupItems, selectedFilterGroupItem => selectedFilterGroupItem.key)
  )

export const expressionWithExcludedPortfolio = (
  expression: CompoundExpression,
  excludedPortfolios: string[] | null | undefined
) => {
  if (!_.isEmpty(excludedPortfolios)) {
    const excludeFieldExpression = getEmptyFieldExpression(
      PORTFOLIO_IDS_FILTER,
      excludedPortfolios,
      FieldOperator.IsNotOneOf
    )
    return addNewHierarchyExpression(expression, PORTFOLIO_IDS_FILTER, undefined, false, [excludeFieldExpression])
  }
  return expression
}

export const hasCampaignIdsFilter = (childExpressions: Expression[] | undefined) =>
  _.some(childExpressions, childExpression => getFieldIdFromExpression(childExpression) === CAMPAIGN_IDS_FILTER)

/**
 * Removes all empty child expressions - just one level deep
 */
export const removeEmptyChildExpressions = (expression: CompoundExpression): CompoundExpression => {
  return {
    ...expression,
    childExpressions: _.filter(expression.childExpressions, isNonEmptyExpression) ?? []
  }
}

const getFiltersFromChildExpressions = (childExpressions: Expression[]) => {
  let selectedFilterIds = new Set<string>()
  _.forEach(childExpressions, childExpression => {
    if (isHierarchyExpression(childExpression) && isNonEmptyExpression(childExpression)) {
      selectedFilterIds.add(childExpression?.additionalData?.fieldId ?? '')
    } else if (
      isFieldExpression(childExpression) ||
      isNumericRangeExpression(childExpression) ||
      isDateRangeExpression(childExpression) ||
      isGeoDistanceExpression(childExpression) ||
      isGeoPolygonExpression(childExpression)
    ) {
      selectedFilterIds.add(childExpression.fieldId ?? '')
    } else if (isCustomVarExpression(childExpression)) {
      selectedFilterIds.add(childExpression.position?.toString() ?? '0')
    } else if (isCompoundExpression(childExpression) && childExpression.groupId !== COMPANY_SEARCH_GROUP_ID) {
      const compoundFilters = getFiltersFromChildExpressions(childExpression.childExpressions)
      selectedFilterIds = new Set([...selectedFilterIds, ...compoundFilters])
    }
  })

  return selectedFilterIds
}

export function getSelectedFilters(data?: FilterQueryDto | CustomerCompanyVisibilityQuery | null): Set<string> {
  if (!data) return new Set()
  const { query } = data

  const selectedFilterIds = getFiltersFromChildExpressions(query.childExpressions)

  return selectedFilterIds
}
