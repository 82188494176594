import _ from 'lodash'
import React, { EventHandler } from 'react'

export type StickyContextType = {
  subscribe: (v: any) => void
  unsubscribe: (v: any) => void
  getParent: () => HTMLDivElement | null
}

export const StickyContext = React.createContext<StickyContextType>({
  subscribe: _.noop,
  unsubscribe: _.noop,
  // eslint-disable-next-line lodash/prefer-constant
  getParent: () => null
})

export type StickyHandlerProps = {
  distanceFromTop: number
  distanceFromBottom: number
}
export type StickyHandler = (props: StickyHandlerProps) => void

const events = ['resize', 'scroll', 'touchstart', 'touchmove', 'touchend', 'pageshow', 'load']

export const StickyContainer = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  const [framePending, setFramePending] = React.useState(false)
  const [rafHandle, setRafHandle] = React.useState<number | null>(null)
  const [subscribers, setSubscribers] = React.useState<StickyHandler[]>([])

  const subscribe = React.useCallback(
    (handler: StickyHandler) => {
      setSubscribers(subscribers.concat(handler))
    },
    [subscribers]
  )

  const unsubscribe = React.useCallback(
    (handler: StickyHandler) => {
      setSubscribers(subscribers.filter(current => current !== handler))
    },
    [subscribers]
  )

  const node = React.useRef<HTMLDivElement>(null)

  const notifySubscribers = React.useCallback<EventHandler<any>>(() => {
    if (!framePending) {
      setRafHandle(
        requestAnimationFrame(() => {
          setFramePending(false)
          const { top, bottom } = node.current ? node.current.getBoundingClientRect() : { top: 0, bottom: 0 }

          subscribers.forEach(handler =>
            handler({
              distanceFromTop: top,
              distanceFromBottom: bottom
            })
          )
        })
      )
      setFramePending(true)
    }
  }, [framePending, subscribers])

  const getParent = () => node.current

  React.useEffect(() => {
    events.forEach(event => window.addEventListener(event, notifySubscribers))

    return () => {
      if (rafHandle) {
        cancelAnimationFrame(rafHandle)
        setRafHandle(null)
      }

      events.forEach(event => window.removeEventListener(event, notifySubscribers))
    }
  }, [notifySubscribers, rafHandle])

  const contextValue = React.useMemo(() => ({ subscribe, unsubscribe, getParent }), [subscribe, unsubscribe])

  return (
    <div
      ref={node}
      onScroll={notifySubscribers}
      onTouchStart={notifySubscribers}
      onTouchMove={notifySubscribers}
      onTouchEnd={notifySubscribers}
      className={className}
    >
      <StickyContext.Provider value={contextValue}>{children}</StickyContext.Provider>
    </div>
  )
}
