import React from 'react'
import { WithT } from 'i18next'
import { Text } from '../Text'
import { notificationSection } from '../../utils/notifications'
import { Notification, PushEvent } from '../../features/notifications/types'
import { Type } from '../../utils/theme'
import { TextSize } from '../Text/common'

type NotificationsProps = {
  notification: Notification | PushEvent
  type?: Type
  size?: TextSize
  bold?: boolean
  block?: boolean
  fallbackTheme?: any
} & WithT

export const NotificationSection = ({
  notification,
  type,
  bold,
  size,
  block,
  fallbackTheme,
  t
}: NotificationsProps) => {
  const text = notificationSection(t, notification)
  return (
    <Text fallbackTheme={fallbackTheme} bold={bold} lineHeight={1.71} type={type} block={block} size={size}>
      {text}
    </Text>
  )
}
