import { WithBlurOption } from '@types'
import { css } from 'styled-components/macro'

export const blurred = css<WithBlurOption>`
  ${({ blur }) =>
    blur === true &&
    `
    filter: blur(3px);
    user-select: none;
    `}
`

export const blurredRow = css`
  .blurred-row {
    ${blurred}
  }
`
