/* eslint-disable import/no-default-export */
export default {
  Username: 'Username',
  Password: 'Password',
  Login: 'Login',
  LoginErrorMessage: 'Il nome utente o la password inseriti non sono corretti',
  SignInMessage: 'Accedi a Margò',
  EnterDetails: 'Inserisci sotto i tuoi dati',
  InsertUserName: 'Inserisci il tuo username',
  InsertUserNamaInBehalf: 'Per conto di',
  InsertPassword: 'Inserisci la tua password',
  ForgotPassword: 'Hai dimenticato la password?',
  ResetPasswordDialog: {
    Title: 'Forgot Password',
    Description:
      'Contatta il servizio clienti per effettuare il recupero password scrivendo a <0>{{email}}</0> oppure chiamando il {{phone}}.',
    Email: 'customerserviceitaly@cribis.com',
    Phone: '800 821058'
  },
  ResetPasswordForm: {
    Description:
      'Inserisci la Username l’indirizzo email al quale è associato il tuo account. Ti verrà inviata una email contenente il link per reimpostare la tua password.',
    SuccessDesription:
      "Ti abbiamo inviato un'email con il link per ripristinare la password. Torna alla pagina di Accesso.",
    Email: 'Email',
    ResetPassword: 'Ripristina Password',
    ReturnToLoginpage: 'Torna alla pagina di accesso',
    InsertUserName: 'Inserisci il tuo Username',
    InsertEmail: 'Inserisci la tua mail',
    InvalidUsernameOrPassword:
      'La password non è stata ripristinata. Utente/email non validi. Si prega di riprovare, oppure di contattare il servizio clienti.',
    ResetFailed: 'La password non è stata ripristinata.'
  },
  RenewPasswordForm: {
    Title: 'Ripristina password',
    Description: 'La tua password è scaduta. Per accedere al servizio devi cambiare la tua password.',
    OldPassword: 'Vecchia password',
    NewPassword: 'Nuova password',
    ConfirmPassword: 'Conferma nuova password',
    ChangePassword: 'Cambia password',
    PasswordChangeSuccessTitle: 'Password cambiata',
    PasswordChangeSuccessText: 'La password è stata modificata con successo',
    RenewPasswordFailed: 'La vecchia password non è corretta.'
  },
  PasswordExpiration90Days: 'La password rimarrà valida 90 giorni',
  PasswordExpiration60Days: 'La password rimarrà valida 60 giorni',
  PasswordRules_ita: `La password deve essere lunga almeno 10 caratteri
  La password deve contenere almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale
  La password non può contenere più di tre caratteri consecutivi identici
  La password non può contenere il Nome Utente o parte di esso per oltre due caratteri consecutivi
  Le due password devono corrispondere
  Si raccomanda di non utilizzare parole del dizionario o parole intuibili come nomi di parenti e animali domestici, date di nascita e numeri di telefono`,
  PasswordRules_che: `La password deve essere lunga almeno 8 caratteri e massimo 25 caratteri
  La password deve contenere almeno una lettera e un numero
  Non è consentito riutilizzare una password già usata e non può contenere l'username
  La password deve contenere almeno una maiuscola
  La password non può contenere più di tre caratteri consecutivi identici`,
  Cs: {
    Title: 'Utente già loggato',
    Info: 'Il tuo utente risulta già loggato, procedendo terminerai la sessione attiva. Tutte le operazioni in background verranno mantenute.',
    Question: 'Vuoi chiudere la sessione attiva e iniziarne una nuova?',
    NewSession: 'Inizia una nuova sessione'
  },
  Sso: {
    error: 'Si è verificato un problema durante il login con il metodo "Single Sign On".',
    back: 'Registrati utilizzando username e password.'
  }
}
