import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUsercentricsSettingsId } from '../config/selectors'

export const useUsercentricsScript = () => {
  const usercentricsSettingsId = useSelector(getUsercentricsSettingsId)

  useEffect(() => {
    if (usercentricsSettingsId) {
      const script = document.createElement('script')
      script.id = 'usercentrics-cmp'
      script.src = 'https://app.usercentrics.eu/browser-ui/3.22.0/loader.js'
      script.async = true
      script.setAttribute('data-settings-id', usercentricsSettingsId)

      document.head.append(script)
    }
  }, [usercentricsSettingsId])
}
