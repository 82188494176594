/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Schůzka',
  CreateAppointmentButton: 'Vytvořit novou schůzku',
  ManageAppointmentsButton: 'Spravovat schůzku',
  EditAppointment: 'Upravit schůzku',
  SendEmailLabel: 'Odeslat podrobnosti do mého kalendáře a informovat příjemce',
  ForHeadquarterButton: 'Pro sídlo společnosti',
  ForLocalBranchButton: 'Pro tuto místní jednotku',
  AppointmentDetails: {
    Title: 'Zadejte jméno, datum a místo související s vaší novou schůzkou',
    EditTitle: 'Upravte jméno, datum a místo související s vaší schůzkou',
    MatchingTermIta: 'Daňový kód/DIČ',
    MatchingTermDeu: 'Daňový kód/DIČ',
    MatchingTermChe: 'Daňový kód/DIČ',
    MatchingTermAut: 'Daňový kód/DIČ',
    MatchingTermCheAut: 'Daňový kód/DIČ',
    MatchingTermCzsk: 'Daňový kód/DIČ',
    MatchingTermFareast: 'Daňový kód/DIČ',
    Name: 'Název schůzky',
    Description: 'Popis schůzky',
    Place: 'Místo',
    StartDate: 'Datum a čas zahájení',
    EndDate: 'Koneční datum',
    SelectColor: 'Vyberte barvu'
  },
  CreateAppointment: {
    Title: 'Vytvořit schůzku',
    ShareAppointmentStep: {
      Title: 'Vyberte příjemce',
      Text: 'Vyberte uživatele, kterým chcete upomínku přeposlat, nebo zadejte e-mailové adresy příjemců oddělené čárkami',
      Email: 'E-mailové adresy oddělené čárkami'
    },
    ConfirmationStep: {
      Title: 'Potvrdit operaci',
      Text: 'Chcete tuto schůzku odeslat do kalendáře? Výběrem ON: Margò vám pošle e-mail, který bude obsahovat všechny právě přidané informace'
    }
  },
  DeleteAppointment: {
    Title: 'Smazat schůzku',
    SubTitle: 'Opravdu chcete schůzku smazat?'
  },
  AppointmentsList: {
    EmptyText: 'Zde najdete všechny schůzky uložené ve vašem kalendáři.',
    NameColumn: 'Název',
    StartColumn: 'Začátek',
    EndColumn: 'Konec',
    ShowExpired: 'Zobrazit prošlé',
    All: 'Všechno'
  },
  Colors: {
    Green: 'Zelená',
    Yellow: 'Žlutá',
    Orange: 'Oranžová',
    Red: 'Červená',
    Violet: 'Fialová',
    Blue: 'Modrá',
    LightBlue: 'Světle modrá',
    Turquoise: 'Turquoise',
    Pink: 'Růžová',
    Black: 'Černá'
  }
}
