import { FilterQueryDto } from '../../../api/filters/types'
import { FilterReducerPath, SimilarityType } from '../../../features/filters/types'
import { Query, QueryDetail } from '../../../features/query/types'

export type LocationState = {
  query?: Partial<Query>
  queryFromTargeting?: QueryDetail | FilterQueryDto
  similarity?: SimilarityType
  shouldPreloadFilters?: boolean
  skipReloadData?: boolean
  semanticPreFilled?: string
  customIndexesOnTop?: boolean
  saveBeforeContinueFlag?: {
    displayDialog: boolean
    actionPath: string
  }
}

export const LOCAL_UNIT_INFORMATION = 'localUnitsFilterInfo'

export const REDUCER_PATH: FilterReducerPath = 'prospecting'
