import { NavigateFunction } from 'react-router-dom'
import { DownloadPortfolioType } from '../../api/portfolioDownload/types'
import { createAction } from '../../utils/redux'
import { ActionsUnion } from '../../utils/types'

export const DOWNLOAD_FILE = 'FILE/download-file'

export const DOWNLOAD_FILE_STREAM = 'FILE/download-file-stream'

// deprecated: don't extend this any further - use useDownloadFile hook instead (see useDownloadPortfolioTemplate)
export const FileActions = {
  downloadPortfolioExcelPreview: (
    portfolioId: string,
    downloadType: DownloadPortfolioType,
    dataPackets?: string[],
    jsonPaths?: string[]
  ) =>
    createAction(DOWNLOAD_FILE, {
      url: `/api/portfolio/${portfolioId}/download-example`,
      method: 'post',
      data: {
        exportType: downloadType,
        templateOnly: true,
        ...(downloadType === 'Custom'
          ? {
              dataPackets: dataPackets ?? [],
              jsonPaths: jsonPaths ?? []
            }
          : {})
      },
      defaultFilename: 'portfolio-download-example.xlsx'
    }),
  downloadCampaignExcelPreview: (
    campaignId: string,
    downloadType: DownloadPortfolioType,
    dataPackets?: string[],
    jsonPaths?: string[]
  ) =>
    createAction(DOWNLOAD_FILE, {
      url: `/api/salestool/campaign/${campaignId}/download-example`,
      method: 'post',
      data: {
        exportType: downloadType,
        templateOnly: true,
        ...(downloadType === 'Custom'
          ? {
              dataPackets: dataPackets ?? [],
              jsonPaths: jsonPaths ?? []
            }
          : {})
      },
      defaultFilename: 'campaign-download-example.xlsx'
    }),
  downloadDocument: (documentId: string, navigate: NavigateFunction) =>
    createAction(DOWNLOAD_FILE_STREAM, {
      documentId,
      navigate
    })
}

export type FileActions = ActionsUnion<typeof FileActions>
