import _ from 'lodash/fp'
import { useLocation } from 'react-router-dom'

const hasEmptyValue = _.compose(_.isEmpty, _.tail)
const parseHash = _.compose(
  _.fromPairs,
  _.remove(hasEmptyValue),
  _.map(_.split('=')),
  _.split('&'),
  _.replace('#', ''),
  _.replace('?', '')
)
const getOtk = _.get('otk')
const getSource = _.get('source')

export const useSingleSignOnParams = () => {
  const { hash, search } = useLocation()
  const otk: string | undefined = getOtk(parseHash(hash)) ?? getOtk(parseHash(search))
  const source: string | undefined = getSource(parseHash(hash)) ?? getSource(parseHash(search))
  return { otk, source, isSingleSignOn: !!otk }
}
