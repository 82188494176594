/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: 'Zum Start der Analyse bitte gewünschten Analyse-Typ auswählen',
    TypeOfAnalysis: 'Art der Analyse',
    FromTargeting: 'Beginnen Sie mit einer gespeicherten Marktanalyse',
    FromTargetingTooltip: 'Öffnen Sie eine im Modul "Analyze market" gespeicherte Abfrage',
    FromProspecting: 'Beginnen Sie mit einer gespeicherten prospect Suche',
    FromProspectingTooltip: 'Öffnen Sie eine im Prospecting-Modul gespeicherte Abfrage',
    NewResearch: 'Starten Sie eine neue Suche',
    NewResearchTooltip: 'Suche nach Prospects in gesamten Datenbank von margò',
    PortfolioQuery: 'Beginnen Sie mit einer Portfolio Management Suche',
    TargetingQuery: 'Beginnen Sie mit einer gespeicherten Marktanalyse',
    ProspectingQuery: 'Beginnen Sie mit einer gespeicherten prospect Suche',
    SelectOne: 'Abfrage auswählen',
    LoadQuery: 'Gespeicherte Suche laden',
    LoadQueryTooltip: 'Öffnen Sie eine gespeicherte Abfrage',
    SavedQuery: 'Gespeicherte Suchen',
    ChooseQuery: 'Wählen Sie die Suche aus, die Sie laden möchten',
    YouCanResumeResearch: 'Sie können eine bereits gestartete Suche fortsetzen.'
  },
  Prospecting: 'Prospecting',
  Actions: {
    LoadQuery: 'Gespeicherte Suche laden',
    LoadQueryTooltip: 'Eine gespeicherte Abfrage öffnen',
    SaveQuery: 'Suche Speichern',
    SaveQueryTooltip: 'Speichern Sie diese Abfrage im "Prospecting" Modul',
    Download: 'Excel herunterladen',
    DownloadTooltip: 'Laden Sie die angereicherte Liste der Unternehmen auf Ihr Gerät',
    SaveList: 'als Portfolio speichern',
    SaveListTooltip: 'Speichern Sie diese Unternehmensliste in einem Portfolio',
    SaveAndMonitor: 'Liste Speichern und Verfolgen',
    SaveAndMonitorTooltip:
      'Speichern Sie die Liste dieser Unternehmen in einem Portfolio, das dynamisch aktualisiert wird, wenn neue Unternehmen Suchparameter eingeben. Hinzugefügte Unternehmen werden automatisch angereichert.',
    SendToSales: 'Liste an Sales Tool senden',
    SendToSalesTooltip: 'Zuweisung der Unternehmen an das Sales Team und Planung einer Werbekampagne',
    SendToOtherModules: 'An andere Module senden',
    SendToOtherModulesTooltip: 'Senden Sie die Unternehmen an das Sales Tool oder das Analyze market Modul'
  },
  Indexes: {
    Penetration: 'Durchdringungsindex',
    PenetrationTooltip:
      'Er misst den %-Anteil der Unternehmen im Protfolio im Vergleich zu denen die von Similarity generiert sind. Ein höherer Wert zeigt eine bessere Durchdringung des potenziellen Marktes an.',
    Opportunity: 'Opportunitätsindex',
    OpportunityTooltip:
      'Er misst den %-Anteil der von Similarity generierten Unternehmen, die nicht im Portfolio enthalten sind. Ein höherer Wert deutet auf eine größere Anzahl von potenziellen Kunden hin.'
  },
  CompaniesInList: 'Firma in der Liste',
  CompaniesInList_plural: 'Firmen in der Liste',
  Search: {
    CompanySearch: 'Unternehmenssuche',
    CompanySearchPlaceholder: 'Finde Unternehmen anhand der ',
    CompanySearchFields: {
      TaxNumber: 'Steuercode',
      VatNumber: 'UID-Nr./MWST-Nr',
      ONRNumber: 'ONR',
      CompanyName: 'Firmennamen',
      CompanyBookNumber: 'Firmenbuchnummer',
      CrifNumber: 'CRIF-Nummer',
      CompanyIdentification: 'Unternehmens-Identifikationsnummer',
      LegacyChNumber: 'CH Key',
      WebSite: 'Website'
    },
    SemanticSearch: 'Semantische Suche',
    SearchPlaceholder: 'Suche nach Stichwörtern',
    All: 'Alle',
    OfficialData: 'Offizielle Daten',
    Website: 'Web & Social',
    Synonyms: 'Synonyme',
    Advanced: 'Advanced semantic search',
    AdvancedSwitch: 'Fortschrittlich',
    AdvancedTooltip:
      'Bei der advanced semantic search können Sie mehrere Wörter verwenden, die durch logische Operatoren (AND, OR, NOT) verknüpft sind. Um dies zu aktivieren, stellen Sie den Schalter auf ON. Für weitere Informationen können sie die Dokumentation herunter laden.',
    DownloadSsGuide: 'Anleitung zur semantischen Suche herunterladen'
  },
  EmptySearch: {
    History: {
      Title: 'Kein Filter',
      Subtitle: 'Hier können Sie sehen, wie sich jeder Filter auf Ihre Abfrage auswirkt'
    },
    Banner: {
      Title: 'Recherche mit bevorzugtem Filter starten',
      Subtitle:
        'Bitte stellen Sie vor Start der Recherche sicher, dass die Filter wie gewünscht eingestellt sind. Die Plattform generiert Ihnen basierend auf den gesetzten Filtern eine Liste idealer Interessenten (Prospects).'
    }
  },
  SaveBeforeContinue: {
    DialogTitle: 'Liste speichern',
    Question: 'Möchten Sie die Suche nach Interessenten speichern, bevor Sie die Seite wechseln?',
    Description: 'Speichern Sie die Suchanfragen Ihrer potenziellen Kunden, um Datenverluste zu vermeiden',
    OkBtn: 'Liste speichern',
    CancelBtn: 'Speichern überspringen'
  },
  DomainFilter: {
    SaveAndClose: 'Filter speichern & schließen'
  },
  LoadMore: 'Weitere laden',
  UnavailableForMobile: 'Die Seite, auf die Sie zuzugreifen versuchen, ist nur für Tablets und Desktops verfügbar.',
  WarningRespectingLocalUnits: {
    Title: 'Anreicherung',
    Text: 'Firmen mit dem Bürotyp "Verwaltungssitz", "Verwaltungssitz und Sitz" und "Sitz" wurden angereichert (falls ausgewählt). Um Unternehmen mit dem Office-Typ "Lokale Einheit" zu bereichern, aktivieren Sie bitte das Addon Lokale Einheiten, indem Sie sich an den Kundenservice wenden.'
  },
  SendToOtherModulesDialog: {
    Title: 'Modul auswählen',
    SubTitle: 'Wählen Sie das Modul aus, an das Sie Ihre Analyse oder Liste senden möchten',
    Targeting: 'Analyze market',
    TargetingDescription:
      'Klicken Sie hier, um die Liste der identifizierten Unternehmen an das Analyze market Modul zu senden und aktualisierte Diagramme einzusehen, die die Verteilung der Unternehmen in Bezug auf die Hauptvariablen darstellen.',
    SalesTool: 'Sales tool',
    SalesToolDescription:
      'Klicken Sie hier, um die Liste der identifizierten Unternehmen an das Sales tool Modul zu senden. Die Liste wird in einem Portfolio gespeichert und die Daten der Unternehmen werden angereichert. Innerhalb des Sales tool Moduls können Sie eine Verkaufskampagne für die ausgewählten Unternehmen starten und diese Ihrem Vertriebsteam zuweisen.',
    OkButton: 'Senden'
  }
}
