import styled from 'styled-components/macro'

export const IconWrapper = styled.span`
  path {
    fill: white;
  }
  margin-right: 16px;
  width: 24px;
  min-width: 24px;
  height: 24px;
`

export const SpinWrapper = styled.span`
  margin: 24px;
`

export const DropdownWrapper = styled.div`
  .ant-select-item {
    &.ant-select-item-option {
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        span {
          color: ${({ theme }) => theme.colors.white};
        }
        span.error {
          color: ${({ theme }) => theme.colors.watermelon};
        }
        :hover {
          background-color: #1f5c9c;
        }
      }
    }
    &.ant-select-item-option-selected {
      background-color: #1a508a;
    }
    &.ant-select-item-option-active {
      background-color: #1f5c9c;
    }
  }
`

export const HeaderSearchWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  align-items: center;
`
