import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { InfoDialog } from '../../containers/Dialog/InfoDialog/InfoDialog'
import { FORCED_LOGOUT_HASH } from '../../features/user/constants'

export const ForcedLogout = () => {
  const { hash, pathname, search } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    const fragmentParams = new URLSearchParams(hash.slice(1))
    if (fragmentParams.has(FORCED_LOGOUT_HASH)) {
      setIsModalVisible(true)
      fragmentParams.delete(FORCED_LOGOUT_HASH)
      const remainingParams = fragmentParams.toString()
      const remainingHash = remainingParams ? `#${remainingParams}` : ''
      navigate({ pathname, search, hash: remainingHash }, { replace: true })
    }
  }, [navigate, hash, pathname, search, t])

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {isModalVisible && (
        <InfoDialog
          close={handleModalClose}
          data={{
            title: t('common:ForceLogout.Title'),
            text: t('common:ForceLogout.Desc')
          }}
        />
      )}
    </>
  )
}
