/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  PM001_0: 'Společnost byla nahrána. Nahrávání portfolia {{portfolioName}} bylo dokončeno!',
  PM001_1: 'Společnosti byly nahrány. Nahrávání portfolia {{portfolioName}} bylo dokončeno!',
  PM001_2: 'Společnosti byly nahrány. Nahrávání portfolia {{portfolioName}} bylo dokončeno!',
  PM002: 'Přejděte na detail chyb při nahrávání portfolia {{portfolioName}}',
  PM003_0: 'Společnost {{portfolioName}} nebyla importována z důvodu nesouladu identifikace nebo ukončení činnosti.',
  PM003_1: 'Společnosti {{portfolioName}} nebyly importovány z důvodu nesouladu identifikace nebo ukončení činnosti.',
  PM003_2: 'Společnosti {{portfolioName}} nebyly importovány z důvodu nesouladu identifikace nebo ukončení činnosti.',
  PM004_0: '{{portfolioName}}: společnost nebyla importována z důvodu neúplných nebo nesprávných údajů.',
  PM004_1: '{{portfolioName}}: společnosti nebyly importovány z důvodu neúplných nebo nesprávných údajů.',
  PM004_2: '{{portfolioName}}: společnosti nebyly importovány z důvodu neúplných nebo nesprávných údajů.',
  PM005: 'Stažení seznamu {{exportType}} portfolia {{portfolioName}} bylo dokončeno',
  PM006: 'Stažení PDF souboru společnosti {{companyName}} bylo dokončeno',
  PM007: 'Duplikace společnosti {{numberOfCompanies}} v portfoliu {{portfolioName}} byla provedena',
  PM008: 'Vymazání {{numberOfCompanies}} společností z portfolia {{portfolioName}} bylo provedeno',
  PM009_0:
    'Přesun {{count}} společnosti z portfolia {{sourcePortfolioName}} do portfolia {{targetPortfolioName}} byl proveden.',
  PM009_1:
    'Přesun {{count}} společností z portfolia {{sourcePortfolioName}} do portfolia {{targetPortfolioName}} byl proveden.',
  PM009_2:
    'Přesun {{count}} společností z portfolia {{sourcePortfolioName}} do portfolia {{targetPortfolioName}} byl proveden.',
  PM010_0: 'Obohacení {{numberOfCompanies}} společnosti v portfoliu {{portfolioName}} bylo provedeno',
  PM010_1: 'Obohacení {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  PM010_2: 'Obohacení {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  PM011: 'Sdílení společnosti {{companyNumber}} z portfolia {{portfolioName}} s {{userName}} bylo provedeno',
  PM012: '{{grantorName}} sdílí portfolio {{portfolioName}}',
  PM013: 'Odstranění sdílení {{portfolioName}} bylo provedeno',
  PM014Part1_0:
    'Přiřazení {{productName}} {{numberOfCompanies}} společnosti v portfoliu {{portfolioName}} proběhlo správně.',
  PM014Part1_1:
    'Přiřazení {{productName}} {{numberOfCompanies}} společnostem v portfoliu {{portfolioName}} proběhlo správně.',
  PM014Part1_2:
    'Přiřazení {{productName}} {{numberOfCompanies}} společnostem v portfoliu {{portfolioName}} proběhlo správně.',
  PM014Part2_0: ' Přiřazení produktu nebylo dokončeno u {{numberOfDuplicates}} společnosti z důvodu duplikace řádku.',
  PM014Part2_1: ' Přiřazení produktu nebylo dokončeno u {{numberOfDuplicates}} společností z důvodu duplikace řádku.',
  PM014Part2_2: ' Přiřazení produktu nebylo dokončeno u {{numberOfDuplicates}} společností z důvodu duplikace řádku.',
  PM015: 'Přiřazení {{salesName}} {{numberOfCompanies}} společnostem v portfoliu {{portfolioName}} bylo provedeno',
  PM017: 'Přiřazení {{managerName}} {{numberOfCompanies}} společnostem v portfoliu {{portfolioName}} bylo provedeno',
  PM014_0: 'Zrušení přiřazení u {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  PM015_0: 'Zrušení přiřazení u {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  PM017_0: 'Zrušení přiřazení u {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  PM019_0: 'Kampaň {{campaignName}} s {{numberOfCompanies}} společností byla vytvořena',
  PM019_1: 'Kampaň {{campaignName}} s {{numberOfCompanies}} společnostmi byla vytvořena',
  PM019_2: 'Kampaň {{campaignName}} s {{numberOfCompanies}} společnostmi byla vytvořena',
  PM020: '{{numberOfCompanies}} úspěšně obohacených',
  PM021: '{{initiatorUserName}} sdílí {{numberOfCompanies}} společností',
  PM022: '{{initiatorUserName}} zastavil sdílení {{numberOfCompanies}} společností',
  PM023: 'Aktualizace sdílení byla provedena',
  PM024: 'Aktualizace sdílení byla provedena',
  PM025: '{{initiatorUserName}} sdílel {{numberOfCompanies}} společností {{numberOfSalesUsers}} uživatelům',
  PM026: '{{initiatorUserName}} zastavil sdílení {{numberOfCompanies}} společností {{numberOfSalesUsers}} uživatelům',
  PM027_0: '{{portfolioName}}: společnost nebyla importována z důvodu neúplných nebo nesprávných údajů.',
  PM027_1: '{{portfolioName}}: společnosti nebyly importovány z důvodu neúplných nebo nesprávných údajů.',
  PM027_2: '{{portfolioName}}: společnosti nebyly importovány z důvodu neúplných nebo nesprávných údajů.',
  PM028: 'Stažení šablóny pro aktualizaci dokončeno',
  PM029: 'Portfolio {{portfolioName}} již není sledovné',
  LL001: 'Adding Local Units. The portfolio has been updated with {{numberOfCompanies}} Local Units.',
  ST001_0: 'Kampaň {{campaignName}} s {{numberOfCompanies}} společností byla vytvořena.',
  ST001_1: 'Kampaň {{campaignName}} s {{numberOfCompanies}} společnostmi byla vytvořena.',
  ST001_2: 'Kampaň {{campaignName}} s {{numberOfCompanies}} společnostmi byla vytvořena.',
  ST001WithDuplicates_0: '{{numberOfDuplicates}} duplikátů bylo vynecháno.',
  ST001WithDuplicates_1: '{{numberOfDuplicates}} duplikát byl vynechán.',
  ST001WithDuplicates_2: '{{numberOfDuplicates}} duplikátu bylo vynecháno.',
  ST002_0: '{{userName}} odstranil {{numberOfCompanies}} společnost z kampaně {{campaignName}}',
  ST002_1: '{{userName}} odstranil {{numberOfCompanies}} společnosti z kampaně {{campaignName}}',
  ST002_2: '{{userName}} odstranil {{numberOfCompanies}} společností z kampaně {{campaignName}}',
  ST003_0: '{{userName}} přiřadil {{numberOfCompanies}} společnost do kampaně {{campaignName}}.',
  ST003_1: '{{userName}} přiřadil {{numberOfCompanies}} společnosti do kampaně {{campaignName}}.',
  ST003_2: '{{userName}} přiřadil {{numberOfCompanies}} společností do kampaně {{campaignName}}.',
  ST003WithDuplicates_0: '{{numberOfDuplicates}} duplikátů bylo vynecháno.',
  ST003WithDuplicates_1: '{{numberOfDuplicates}} duplikát byl vynechán.',
  ST003WithDuplicates_2: '{{numberOfDuplicates}} duplikátů bylo vynecháno.',
  ST004: 'Kampaň {{campaignName}} začne {{startDate}}',
  ST005: 'Kampaň {{campaignName}} skončí {{endDate}}',
  ST006: 'Portfolio {{portfolioName}} bylo úspěšně přidáno do kampaně {{campaignName}}.',
  ST007: 'Stažení Excelu {{campaignName}} bylo dokončeno',
  ST009_0: 'V kampani {{campaignName}} se skončilo přiřazení pro {{numberOfCompanies}} společnost.',
  ST009_1: 'V kampani {{campaignName}} se skončilo přiřazení pro {{numberOfCompanies}} společnosti.',
  ST009_2: 'V kampani {{campaignName}} se skončilo přiřazení pro {{numberOfCompanies}} společností.',
  ST010_0: '{{numberOfCompanies}} společnost byla úspěšně přidaná do kampaně {{campaignName}}.',
  ST010_1: '{{numberOfCompanies}} společnosti byly úspěšně přidány do kampaně {{campaignName}}.',
  ST010_2: '{{numberOfCompanies}} společností bylo úspěšně přidáno do kampaně {{campaignName}}.',
  ST011_0: 'Přiřazení úkolu {{taskName}} společnosti {{numberOfCompanies}} pro kampaň {{campaignName}} dokončeno.',
  ST011_1: 'Přiřazení úkolu {{taskName}} společnostem {{numberOfCompanies}} pro kampaň {{campaignName}} dokončeno.',
  ST011_2: 'Přiřazení úkolu {{taskName}} společnostem {{numberOfCompanies}} pro kampaň {{campaignName}} dokončeno.',
  ST012_0: '{{userName}} přiřadil {{numberOfCompanies}} společnost {{salesName}} do kampaně {{campaignName}}.',
  ST012_1: '{{userName}} přiřadil {{numberOfCompanies}} společnosti {{salesName}} do kampaně {{campaignName}}.',
  ST012_2: '{{userName}} přiřadil {{numberOfCompanies}} společností {{salesName}} do kampaně {{campaignName}}.',
  ST012WithDuplicates_0: '{{numberOfDuplicates}} duplikát byl vynechán.',
  ST012WithDuplicates_1: '{{numberOfDuplicates}} duplikáty byly vynechány.',
  ST012WithDuplicates_2: '{{numberOfDuplicates}} duplikátů bylo vynecháno.',
  ST013_0: 'Přiřazení k {{numberOfCompanies}} společnosti pro kampaň {{campaignName}} bylo odebráno.',
  ST013_1: 'Přiřazení k {{numberOfCompanies}} společnostem pro kampaň {{campaignName}} bylo odebráno.',
  ST013_2: 'Přiřazení k {{numberOfCompanies}} společnostem pro kampaň {{campaignName}} bylo odebráno.',
  ST014_0: '{{userName}} odstranil {{numberOfCompanies}} společnost pre {{salesName}} z kampaně {{campaignName}}.',
  ST014_1: '{{userName}} odstranil {{numberOfCompanies}} společnosti pre {{salesName}} z kampaně {{campaignName}}.',
  ST014_2: '{{userName}} odstranil {{numberOfCompanies}} společností pre {{salesName}} z kampaně {{campaignName}}.',
  PR001: '{{numberOfCompanies}} nových společností bylo přidáno do sledovaného portfolia {{portfolioName}}',
  PR002: 'Vložení a obohacení {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  PR003_0: 'Vytvoření portfolia {{portfolioName}} a vložení {{numberOfCompanies}} obohacené společnosti bylo provedeno',
  PR003_1:
    'Vytvoření portfolia {{portfolioName}} a vložení {{numberOfCompanies}} obohacených společností bylo provedeno',
  PR003_2:
    'Vytvoření portfolia {{portfolioName}} a vložení {{numberOfCompanies}} obohacených společností bylo provedeno',
  PR004:
    'Správně provedené vložení a obohacení {{numberOfCompanies}} společností ve sledovaném portfoliu {{portfolioName}}',
  PR005:
    'Vytvoření sledovaného portfolia {{portfolioName}} a vložení {{numberOfCompanies}} obohacených společností bylo provedeno',
  PR006: 'Vaše obohacené portfolio <0> {{portfolioName}} </0> bylo vytvořeno. {{exclusionWarning}}',
  PR007: 'Vložení a obohacení {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  TR001_0: 'Vytvoření portfolia {{portfolioName}} a vložení {{numberOfCompanies}} obohacené společnosti bylo provedeno',
  TR001_1:
    'Vytvoření portfolia {{portfolioName}} a vložení {{numberOfCompanies}} obohacených společností bylo provedeno',
  TR001_2:
    'Vytvoření portfolia {{portfolioName}} a vložení {{numberOfCompanies}} obohacených společností bylo provedeno',
  TR002: 'Vložení a obohacení {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  TA001: 'Stažení Excel souboru ze sekce Targeting bylo dokončeno',
  TA002: 'Stažení PDF souboru ze sekce Targeting bylo dokončeno',
  DO002: 'Soubor pro společnost {{companyName}} byl vytvořen a je připraven ke stažení.',
  DO003: '{{grantorUserName}} s vámi sdílí {{module}} výzkum {{queryName}}.',
  PM002Event1: 'Neaktivní společnosti nebyly nahrány',
  PM002Event2: 'Chyby z důvodu nesprávných údajů. Šablonu můžete později nahrát v sekci "Správa portfolia"',
  OP001:
    'Správně provedené vytvoření portfolia {{portfolioName}} a vložení obohacené společnosti {{numberOfCompanies}}',
  OP001_plural:
    'Správně provedené vytvoření portfolia {{portfolioName}} a vložení obohacené společnosti {{numberOfCompanies}}',
  OP002: 'Správně provedené vložení a obohacení společnosti {{numberOfCompanies}} do portfolia {{portfolioName}}',
  OP003: 'Vaše obohacené portfolio {{portfolioName}} bylo správně vytvořeno. {{exclusionWarning}}',
  OP004_0: 'Obohacení {{numberOfCompanies}} společnosti v portfoliu {{portfolioName}} bylo provedeno',
  OP004_1: 'Obohacení {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  OP004_2: 'Obohacení {{numberOfCompanies}} společností v portfoliu {{portfolioName}} bylo provedeno',
  MissingMessage: 'Pro typ {{type}} chybí notifikační zpráva.',
  ExcludedCompaniesWarning: 'Společnosti patřící do skrytých portfolií nebyly zpracovány.',
  SalesTool: 'Obchodní aktivity',
  Portfolio: 'Správa portfolia',
  Prospecting: 'Výběr klientů',
  Operations: 'Operace',
  Targeting: 'Analýza trhu',
  DownloadPDF: 'Stáhnout PDF',
  ShareQuery: {
    Title: 'Sdílet dotaz'
  },
  NotificationArchive: {
    Title: 'Archiv notifikací',
    Description: 'Na této stránce naleznete seznam přijatých notifikací',
    Notifications: '{{notificationsNumber}} notifikací',
    Welcome: 'Vítejte v Margo:',
    NoNotifications: 'toto je sekce notifikací a vyplní se, když začnete provádět akce.'
  },
  Table: {
    Header: {
      Date: 'Datum',
      Section: 'Oddíl',
      Message: 'Zpráva '
    }
  },
  NotificationPopup: {
    Open: 'Otevřít',
    ManageErrors: 'Správa chyb',
    DownloadList: 'Stáhnout seznam',
    DownloadFile: 'Stáhnout soubor',
    ShareQueryAction: 'Uložit sdílený dotaz'
  },
  PushEvent: {
    UploadProgress: 'Vaše portfolio se nahrává',
    UploadProgressFinished: 'Nahrávání bylo dokončeno',
    DeleteCompaniesProgress: 'Probíhá mazání vybraných společností',
    DeleteCompaniesProgressFinished: 'Mazání vybraných společností bylo dokončeno',
    EnrichCompaniesProgress: 'Obohacování vybraných společností právě probíhá',
    EnrichCompaniesProgressFinished: 'Obohacování vybraných společností bylo dokončeno',
    CreatePortfolioFromSelectionProgress: 'Probíhá vytváření portfolia z vybraných společností',
    CreatePortfolioFromSelectionProgressFinished: 'Vytvoření portfolia z vybraných společností bylo dokončeno',
    DuplicateProgress: 'Vaše portfolio se duplikuje',
    DuplicateProgressFinished: 'Duplikát byl dokončen',
    ShareCompaniesProgress: 'Společnosti se sdílejí',
    ShareCompaniesProgressFinished: 'Sdílení bylo dokončeno',
    UnshareCompaniesProgress: 'Sdílení společností se zastavuje',
    UnshareCompaniesProgressFinished: 'Sdílení společností bylo zastaveno',
    AssignSalesProgress: 'Probíhá přiřazení skupině Sales',
    AssignSalesProgressFinished: 'Přiřazení skupině Sales dokončeno',
    AssignAreaManagerProgress: 'Probíhá přiřazení skupině AreaManagers',
    AssignAreaManagerProgressFinished: 'Přiřazení skupině AreaManagers dokončeno',
    AssignProductProgress: 'Probíhá přiřazení k produktu',
    AssignProductProgressFinished: 'Přiřazení k produktu dokončeno',
    ProspectingSaveListProgress: 'Vytváření portfolia z probíhajících průzkumů',
    ProspectingSaveListProgressFinished: 'Vytváření portfolia dokončeno',
    TargetingSaveListProgress: 'Probíhá tvorba portfolia z analýzy trhu',
    TargetingSaveListProgressFinished: 'Vytváření portfolia dokončeno',
    ProspectingUpdateListProgress: 'Probíhá aktualizace portfolia z "Výběru klientů"',
    ProspectingUpdateListProgressFinished: 'Aktualizace portfolia z "Výběru klientů" byla dokončena',
    TargetingUpdateListProgress: 'Probíhá aktualizace portfolia z analýzy trhu',
    TargetingUpdateListProgressFinished: 'Aktualizace portfolia z analýzy trhu byla dokončena',
    CreateCampaignProgress: 'Probíhá vytváření kampaně',
    CreateCampaignProgressFinished: 'Vytváření kampaně bylo dokončeno',
    AssignCampaignProgress: 'Probíhá přiřazování kampaně',
    AssignCampaignProgressFinished: 'Přiřazení kampaně bylo dokončeno',
    UpdateCampaignTaskProgress: 'Probíhá aktualizace vaší kampaně',
    UpdateCampaignTaskProgressFinished: 'Vaše kampaň byla aktualizována',
    SetCompaniesTaskProgress: 'Probíhá nastavení úloh společností',
    SetCompaniesTaskProgressFinished: 'Nastavení úloh společností bylo dokončeno',
    ExportCampaignProgress: 'Probíhá export kampaně',
    ExportCampaignProgressFinished: 'Export kampaně byl dokončený',
    ExportPortfolioProgress: 'Vaše portfolio {{portfolioName}} se připravuje ke stažení',
    ExportPortfolioProgressFinished: 'Vaše stažené portfolio {{portfolioName}} je připraveno',
    PdfGenerateProgress: 'Probíhá vytváření PDF souboru pro společnost {{companyName}}',
    AddLocalUnitsToPortfolioProgress: 'Adding Local Units. Portfolio update in progress.',
    OperationsSaveListProgress: 'Vytváření portfolia z modulu Operace',
    OperationsSaveListProgressFinished: 'Vytváření portfolia dokončeno',
    OperationsUpdateListProgress: 'Probíhá aktualizace portfolia z modulu Operace',
    OperationsUpdateListProgressFinished: 'Aktualizace portfolia z modulu Operace byla dokončena'
  },
  Banner: {
    RingsWarningMessage:
      'Pozor! Počet vašich zbývajících jednotek je menší než 10%. Zkontrolujte prosím <1>svůj účet</1> nebo kontaktujte náš prodejní tým.'
  }
}
