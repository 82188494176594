import _ from 'lodash'
import { createSelector } from 'reselect'
import { Permission } from '@api/config/types'
import { FREEMIUM_ROLE_CODE } from '@features/constants'
import { Claim, UserType } from '../../authorization'
import { paramSelector } from '../../utils/selectors'
import { RootState } from '../types'

type WithUserClaim = { claim: Claim }

const getFullName = (firstName?: string, lastName?: string) => _.join([firstName, lastName], ' ')

const selectState = (state: RootState) => state.user

export const getToken = createSelector(selectState, state => state.token)

export const isLoggedIn = createSelector(getToken, token => !!token)

export const hasPortfolio = createSelector(selectState, state => state.hasPortfolio)

export const getSelectedLanguage = createSelector(selectState, state => state.selectedLanguage)

export const getSelectedCurrency = createSelector(selectState, state => state.selectedCurrency)

export const getAvailableRedirects = createSelector(selectState, state => state.availableRedirects)

export const canRedirectToCcxBalanceSheet = createSelector(getAvailableRedirects, redirects =>
  _.includes(redirects, 'CCX_BalanceSheets')
)
export const canRedirectToCcxPurchaseReport = createSelector(getAvailableRedirects, redirects =>
  _.includes(redirects, 'CCX_PurchaseReport')
)
export const canRedirectToCcxCreditCheckReport = createSelector(getAvailableRedirects, redirects =>
  _.includes(redirects, 'CCX_CreditCheckReport')
)

export const getCustCompanyVisiblityAccountEnabled = createSelector(
  selectState,
  state => state.customerCompanyVisibilityAccountEnabled
)

export const getCustCompanyConfigurationApplied = createSelector(
  selectState,
  state => state.customerCompanyConfigurationApplied
)

export const getHasPortfolioExclusions = createSelector(selectState, state => state.hasPortfolioExclusions)

// ********************************* Constraints *******************************************

export const getConstraints = createSelector(selectState, state => state.constraints)

export const getMaxPortfoliosCount = createSelector(
  getConstraints,
  constraints => constraints['portfolio.maxCount'] as number | undefined
)

export const getSimilarityCompaniesCount = createSelector(
  getConstraints,
  constraints => constraints['portfolio.similarityCompaniesCount'] as number | undefined
)

export const getMaxSemanticSearchCount = createSelector(
  getConstraints,
  constraints => constraints['semanticSearch.maxCount'] as number | undefined
)

// ********************************* Portfolio *******************************************

export const portfolioQueriesConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.queries'] as Permission | undefined
)

export const portfolioDownloadConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.download'] as Permission | undefined
)

export const portfolioAddLocalUnitsConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.addLocalUnits'] as Permission | undefined
)

export const portfolioUpdateConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.updatePortfolio'] as Permission | undefined
)

export const portfolioGenerateSimilarityConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.generateSimilarity'] as Permission | undefined
)

export const portfolioAnalyzeConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.analyze'] as Permission | undefined
)

export const portfolioCreateConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.createPortfolio'] as Permission | undefined
)

export const portfolioUploadConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.uploadPortfolio'] as Permission | undefined
)

export const portfolioStandardUploadConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.standardUploadPortfolio'] as Permission | undefined
)

export const portfolioSimplifiedUploadConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.simplifiedUploadPortfolio'] as Permission | undefined
)

export const portfolioSharePortfolioConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.sharePortfolio'] as Permission | undefined
)

export const portfolioExcludePortfolioConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.excludePortfolio'] as Permission | undefined
)

export const portfolioCreateCampaignConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.createCampaign'] as Permission | undefined
)

export const portfolioDeletePortfolioConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.deletePortfolio'] as Permission | undefined
)

export const portfolioChangeNameConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.changeName'] as Permission | undefined
)

export const portfolioAddEditNoteConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.addEditNote'] as Permission | undefined
)

export const portfolioEnrichConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.enrich'] as Permission | undefined
)

// ********************************* Portfolio companies *******************************************

export const portfolioDeleteCompanyConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.deleteCompany'] as Permission | undefined
)

export const portfolioShareCompanyConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.shareCompany'] as Permission | undefined
)

export const portfolioMoveCompanyConstraint = createSelector(
  getConstraints,
  constraints => constraints['portfolio.moveCompany'] as Permission | undefined
)

// ********************************* Targeting *******************************************

export const targetingQueriesConstraint = createSelector(
  getConstraints,
  constraints => constraints['targeting.queries'] as Permission | undefined
)

export const targetingSaveListConstraint = createSelector(
  getConstraints,
  constraints => constraints['targeting.saveList'] as Permission | undefined
)

export const targetingLoadPortfolioConstraint = createSelector(
  getConstraints,
  constraints => constraints['targeting.loadPortfolio'] as Permission | undefined
)

export const targetingDownloadConstraint = createSelector(
  getConstraints,
  constraints => constraints['targeting.download'] as Permission | undefined
)

// ********************************* Prospecting *******************************************

export const prospectingSaveListConstraint = createSelector(
  getConstraints,
  constraints => constraints['prospecting.saveList'] as Permission | undefined
)

export const prospectingSaveMonitoredConstraint = createSelector(
  getConstraints,
  constraints => constraints['prospecting.saveMonitored'] as Permission | undefined
)

export const prospectingQueriesConstraint = createSelector(
  getConstraints,
  constraints => constraints['prospecting.queries'] as Permission | undefined
)

export const prospectingDownloadConstraint = createSelector(
  getConstraints,
  constraints => constraints['prospecting.download'] as Permission | undefined
)

// ********************************* My Account *******************************************

export const myAccountAppointmentsConstraint = createSelector(
  getConstraints,
  constraints => constraints['myAccount.Appointments'] as Permission | undefined
)

export const myAccountPortfolioManagementConstraint = createSelector(
  getConstraints,
  constraints => constraints['myAccount.PortfolioManagement'] as Permission | undefined
)

export const myAccountTargetingConstraint = createSelector(
  getConstraints,
  constraints => constraints['myAccount.Targeting'] as Permission | undefined
)

export const myAccountProspectingConstraint = createSelector(
  getConstraints,
  constraints => constraints['myAccount.Prospecting'] as Permission | undefined
)

export const myAccountOperationsConstraint = createSelector(
  getConstraints,
  constraints => constraints['myAccount.Operations'] as Permission | undefined
)

export const myAccountProductsConstraint = createSelector(
  getConstraints,
  constraints => constraints['myAccount.Products'] as Permission | undefined
)

export const myAccountGoalsTasksConstraint = createSelector(
  getConstraints,
  constraints => constraints['myAccount.GoalsTasks'] as Permission | undefined
)

export const myAccountReleaseNotesConstraint = createSelector(
  getConstraints,
  constraints => constraints['myAccount.ReleaseNotes'] as Permission | undefined
)

export const myAccountConfigurationConstraint = createSelector(
  getConstraints,
  constraints => constraints['myAccount.Configuration'] as Permission | undefined
)

// ********************************* Module & SendToModule permissions *******************************************

export const moduleDashboardConstraint = createSelector(
  getConstraints,
  constraints => constraints['module.Dashboard'] as Permission | undefined
)

export const moduleSalesToolConstraint = createSelector(
  getConstraints,
  constraints => constraints['module.SalesTool'] as Permission | undefined
)

export const moduleAppointmentConstraint = createSelector(
  getConstraints,
  constraints => constraints['module.Appointment'] as Permission | undefined
)

export const sendToModuleProspectingConstraint = createSelector(
  getConstraints,
  constraints => constraints['sendToModule.Prospecting'] as Permission | undefined
)

export const sendToModuleTargetingConstraint = createSelector(
  getConstraints,
  constraints => constraints['sendToModule.Targeting'] as Permission | undefined
)

export const sendToModuleSalesToolConstraint = createSelector(
  getConstraints,
  constraints => constraints['sendToModule.SalesTool'] as Permission | undefined
)

export const sendToModulePortfolioManagementConstraint = createSelector(
  getConstraints,
  constraints => constraints['sendToModule.PortfolioManagement'] as Permission | undefined
)

export const freeSearchPermissionConstraint = createSelector(
  getConstraints,
  constraints => constraints.freeSearch as Permission | undefined
)

// ********************************* Company Report permissions *******************************************

export const reportDownloadPdfConstraint = createSelector(
  getConstraints,
  constraints => constraints['report.downloadPdf'] as Permission | undefined
)

// ********************************* Features permissions *******************************************

export const showLocalUnitsFeatureConstraint = createSelector(
  getConstraints,
  constraints => constraints['features.showLocalUnits'] as Permission | undefined
)

export const hotFiltersFeatureConstraint = createSelector(
  getConstraints,
  constraints => constraints['features.showHotFilters'] as Permission | undefined
)

export const getCurrentInstance = createSelector(selectState, state => state?.instanceCode)

// Extension Manager
export const hasSurveys = createSelector(selectState, state => state.extensionsManager.hasSurveys)
export const getAvailableItems = createSelector(selectState, state => state.extensionsManager.availableItems)

// claims

export const getUserClaims = createSelector(selectState, state => state.claims)
export const hasUserClaim = createSelector(getUserClaims, paramSelector<WithUserClaim>(), (claims, { claim }) =>
  Boolean(claims & claim)
)
export const hasProspectingClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.Prospecting))
export const hasTargetingClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.Targeting))
export const hasSalesToolClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.SalesTool))
export const hasPortfolioManagementClaim = createSelector(getUserClaims, claims =>
  Boolean(claims & Claim.PortfolioManagement)
)
export const hasOperationsClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.Operations))
export const hasPnrrClaim = createSelector(getUserClaims, claims => Boolean(claims & Claim.Pnrr))

// user

const getUser = createSelector(selectState, state => state.user)

export const getUserId = createSelector(getUser, userState => userState.id)

export const getUserFirstName = createSelector(getUser, userState => userState.firstName)

export const getUserLastName = createSelector(getUser, userState => userState.lastName)

export const getUserFullName = createSelector(getUserFirstName, getUserLastName, getFullName)

export const getUserCode = createSelector(getUser, userState => userState.userCode)

export const getUserEmail = createSelector(getUser, userState => userState.email)

export const getLanguage = createSelector(getUser, userState => userState.language)

export const getCurrency = createSelector(getUser, userState => userState.currency)

export const getUserType = createSelector(getUser, userState => userState.userType)

export const getImpersonationAllowed = createSelector(getUser, userState => userState.impersonationAllowed)

export const isReleaseNotesEditor = createSelector(getUser, userState => userState.isReleaseNotesEditor)

export const isUserTypeSales = createSelector(getUserType, userType => Boolean(userType & UserType.Sales))

export const isUserTypeSupervisor = createSelector(getUserType, userType => Boolean(userType & UserType.Supervisor))

// organization

const getOrganization = createSelector(selectState, state => state.organization)

export const getOrganizationId = createSelector(getOrganization, organizationState => organizationState.id)

export const getOrganizationDistinguishName = createSelector(
  selectState,
  state => state.user.organizationUserDistinguishName
)

export const getOrganizationName = createSelector(getOrganization, organizationState => organizationState.name)

export const getOrganizationCode = createSelector(getOrganization, organizationState => organizationState.code)

export const getOrganizationCountryCode = createSelector(
  getOrganization,
  organizationState => organizationState.countryCode
)

export const getOrganizationAddressCountryCode = createSelector(
  getOrganization,
  organizationState => organizationState.addressCountryCode
)

// originator user

const getOriginator = createSelector(selectState, state => state.originatorUser)

export const hasOriginator = createSelector(getOriginator, originatorState => !!originatorState)

export const getOriginatorUserCode = createSelector(getOriginator, originatorState => originatorState?.userCode)

// branch

const getBranch = createSelector(selectState, state => state.branch)

export const getBranchName = createSelector(getBranch, branchState => branchState.name)

// subscription

export const getSubscription = createSelector(selectState, state => state.subscription)

export const getSubscriptionId = createSelector(getSubscription, subscriptionState => subscriptionState.id)

export const hasBaseList = createSelector(getSubscription, subscriptionState => subscriptionState.hasBaseList)

export const hasMarketingList = createSelector(getSubscription, subscriptionState => subscriptionState.hasMarketingList)

export const hasCompleteList = createSelector(getSubscription, subscriptionState => subscriptionState.hasCompleteList)

export const hasAnalyticsIndexes = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.hasAnalyticsIndexes
)

export const hasPdfReport = createSelector(getSubscription, subscriptionState => subscriptionState.hasPdfReport)

export const hasLocalUnits = createSelector(getSubscription, subscriptionState => subscriptionState.hasLocalUnit)

export const hasRetailIndexes = createSelector(getSubscription, subscriptionState => subscriptionState.hasRetailIndexes)

export const hasSemanticClusters = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.hasSemanticClusters
)

export const getVisibilityCountries = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.visibilityCountries
)

export const hasExtraPeopleData = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.hasExtraPeopleData
)

export const hasFreemiumRole = createSelector(
  getSubscription,
  subscriptionState => _.find(subscriptionState.subscriptionRoles, { role: { code: FREEMIUM_ROLE_CODE } }) !== undefined
)

export const hasEsg = createSelector(getSubscription, subscriptionState => subscriptionState.hasEsg)

// subscription detail

export const hasPortfolioActions = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.details.hasPortfolioActions
)

export const hasExtensions = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.details.hasExtensions
)

export const hasEasyFinance = createSelector(
  getSubscription,
  subscriptionState => subscriptionState.details.hasEasyFinance
)

// combined

export const getUserAccountInfo = createSelector(
  getUser,
  getBranchName,
  getSubscriptionId,
  (user, branchName, subscriptionId) => ({
    ..._.pick(user, ['userCode', 'userType', 'id', 'phoneNumber', 'jobTitle']),
    officeName: branchName,
    subscriptionId,
    fullName: `${user.firstName} ${user.lastName}`
  })
)
