import * as signalR from '@microsoft/signalr'
import { Dispatch, Store } from 'redux'
import { HttpTransportType, LogLevel } from '@microsoft/signalr'
import { delayWithPromise } from './helpers'
import 'abortcontroller-polyfill'
import { createSessionOtk } from '../api/users'

async function startConnection(dispatch: any, connection: signalR.HubConnection, reconnectTimeoutCounter = 0) {
  const realtimeReconnectTimeouts = [1000, 5000, 10000, 20000, 30000]
  try {
    await connection.start()
  } catch (e: any) {
    if (reconnectTimeoutCounter <= realtimeReconnectTimeouts.length) {
      await delayWithPromise(realtimeReconnectTimeouts[reconnectTimeoutCounter])
      await startConnection(dispatch, connection, reconnectTimeoutCounter + 1)
    }
  }
}

export class SignalR {
  private static connection: signalR.HubConnection

  private static store: Store

  static setStore(store: Store) {
    SignalR.store = store
  }

  static start(baseUrl: string) {
    const { dispatch } = SignalR.store
    SignalR.stop()
    SignalR.connection = new signalR.HubConnectionBuilder()
      .configureLogging(process.env.NODE_ENV === 'development' ? LogLevel.Error : LogLevel.Information)
      .withUrl(`${baseUrl}/hubs/events`, {
        accessTokenFactory: () => createSessionOtk().then(data => data.otk),
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build()
    SignalR.connection.onclose(async err => {
      if (err) await startConnection(dispatch, SignalR.connection)
    })

    startConnection(dispatch, SignalR.connection)
  }

  static addSubscriptionHandler(
    subscriptionString: string,
    createResponseHandler: (dispatch: Dispatch) => (response: any) => void
  ) {
    const { dispatch } = SignalR.store
    SignalR.connection.on(subscriptionString, createResponseHandler(dispatch))
  }

  static stop() {
    if (!this.connection || this.connection.state !== signalR.HubConnectionState.Connected) return
    this.connection.stop()
  }
}
