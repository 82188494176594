/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  ChooseNameForCompaniesList: 'Choose the name of the companies list',
  EnterARepresentativeName: 'Enter a representative name for the portfolio you are uploading.',
  EnterARepresentativeNameCompaniesList: "Insert the name for the companies list you're saving.",
  ItWillIdentifyCompaniesList: 'The name will help you to identify it later.',
  PortfolioName: 'Portfolio name',
  InsertNameForPortfolio: 'Insert a name for your portfolio',
  UploadPortfolio: {
    StandardTitle: 'Upload Portfolio',
    UploadPortfolioOrConnect: 'Upload a portfolio or connect your company CRM to start working',
    UploadOrConnect: 'Upload a portfolio or connect your business CRM',
    UploadFromPlatform: 'Upload from platform',
    UploadAPortfolio: 'Upload a portfolio',
    SelectFromPc: 'Select from PC',
    DownloadTemplateDescription1: 'Download base track of the portfolio',
    DownloadTemplateDescription2:
      'Enter the data of your client companies as indicated in the example sheet keeping the structure of the tables unchanged',
    DownloadTemplateDescription3: 'Upload the track in the next step',
    DownloadTemplateDescription4:
      'Don’t you know the Business identification number/CRIF Id? Please contact customer support at <0>support.ch@crif.com</0> to proceed with manual matching',
    DownloadTemplate: 'Download the file',
    ChooseHowToUploadPortfolio: 'Please select your file',
    SelectPortfolioFromPc: 'Select the portfolio from your computer',
    YouCanLoad: 'You can load other portfolio whenever you want, they will be loaded separately!',
    ToEnsureTheCorrectLoading:
      'To ensure the correct loading of the companies, make sure you have used the template provided',
    ChooseHowToUploadForUpdate: 'Choose how to update your company list',
    SelectCompanyListFromPC: 'Select the company list from your PC',
    ToEnsureTheCorrectLoadingForUpdate:
      'To ensure the correct Company List loading, make sure you used the template provided by Margo. Download the basic list from the "Download portfolio" function, copy and paste values into template file, update it and upload filled template in this section.',
    SelectFile: 'Select the file',
    SelectFileFromComputer: 'Select the desired file',
    ChoosePortfolioName: 'Choose a portfolio name',
    ItWillDistinguish: 'It will help you to distinguish it from your other portfolios!',
    WrapperPage: {
      TypeOfUpload: 'Type of upload',
      TypeOfUploadDescription: 'Choose the type of upload you want to perform: standard or simplified',
      StandardUpload: 'Standard Upload',
      StandardUploadTooltip: 'Load companies on Margo via the dedicated template',
      SimplifiedUpload: 'Simplified Upload',
      SimplifiedUploadTooltip: 'Upload a list of Identification codes directly on web, in a simplified way'
    },
    Simplified: {
      Title: 'Simplified Upload Portfolio',
      FirstStepTitle: 'Paste or type Identification codes',
      AvailableCharacters: 'Available characters: {{numberOfCharacters}}/{{maximumCharacters}}',
      UploadWarning: 'WARNING: Closing this page or stepping back without saving, all changes will be lost',
      UploadPlaceholder: 'Paste or type valid {{descriptionFields}}, separated by space',
      DescriptionFields: {
        ita: 'Tax Code/VAT Code/CRIF Number',
        deu: 'ONR Number',
        czsk: 'CompanyId/TaxId/VatId',
        che: 'Business identification number/CRIF Id/ Vat Code/Company book number'
      },
      Country: {
        Select: 'Select your country',
        SK: 'Slovakia',
        CZ: 'Czech Republic',
        CHE: 'Switzerland',
        AUT: 'Austria'
      },
      Description1: 'Paste or type valid {{descriptionFields}}, separated by space',
      Description2: 'Click on "Continue" button for next steps',
      Description3:
        'Potential errors will be reported in "Error table", accessible by clicking on "Manage Error" in related notification',
      ChooseStatus: {
        Title: 'Choose the status you want to apply',
        Description1: 'Select the initial status of the companies to be uploaded.',
        Description2: 'The same company status will be applied to the whole portfolio.'
      }
    },
    ManageErrors: {
      Title: 'View and fix errors',
      Description1: 'Click on the {{descriptionFields}} to modify it, or click on the trash can icon to delete the row',
      Description2: 'Click on "Continue" button to upload the file',
      Description3: 'Find fixed companies in portfolio',
      CompanyMatchStatusTitle: 'Upload status',
      StatusDescriptionTitle: 'Status Description',
      DownloadList: 'Download list',
      CompanyMatchStatus: {
        Rejected: 'Rejected',
        RejectedDescription: 'The {{descriptionFields}} is not valid',
        Duplicate: 'Duplicate',
        DuplicateDescription: 'The {{descriptionFields}} inserted is already present in the portfolio',
        NotMatched: 'Not Matched',
        NotMatchedDescription: 'The {{descriptionFields}} is valid, but no match was found in database',
        Pending: 'Pending validation',
        PendingDescription: 'The {{descriptionFields}} has been changed. Click Continue to validate it',
        Ceased: 'Ceased',
        CeasedDescription: 'The {{descriptionFields}} inserted is related to a ceased company'
      }
    }
  },
  ExcludeCustomVariables: 'Exclude custom variables',
  ExcludeCustomVariablesConfirm:
    'Do you want to move companies without Custom Variables? If yes, the Custom variables from origin portfolio will be deleted.',
  Management: {
    Header: 'Portfolio Management',
    Upload: 'Upload Portfolio',
    UploadTooltip: 'Load companies on Margo via the dedicated template',
    UploadTooltipWithSimplified: 'Upload a list of Identification codes, using standard or simplified method',
    ShowCorporate: 'See corporate portfolio',
    SearchPortfolioTooltip: 'Search portfolio',
    SearchPortfolioPlaceholder: 'Search for name of portfolio',
    SearchHeader: 'Portfolio Management - Portfolio search results',
    PortfolioTabs: {
      AllPortfolios: 'All Portfolios',
      MyPortfolios: 'My Portfolios',
      SharedPortfolios: 'Shared Portfolios',
      ReceivedPortfolios: 'Received Portfolios'
    },
    PortfolioName: {
      ChangePortfolioName: 'Change Portfolio name',
      EnterARepresentativeName: 'Enter a representative name for the portfolio',
      InsertPortfolioName: 'Insert portfolio name',
      NameChangedCorrectly: 'Name changed correctly in'
    },
    PortfolioNote: {
      AddNote: 'Add note',
      EditNote: 'Edit note',
      AddANoteToDescribe: 'Add a note to describe the portfolio',
      AddNotePlaceholder: 'Add note',
      AddNoteFieldLabel: 'Note'
    },
    DeletePortfolio: {
      DeletePortfolio: 'Delete portfolio',
      AreYouSure: 'Are you sure you want to delete {{name}}?',
      PortfolioDeletedCorrectly: '{{name}} deleted correctly.',
      DataWillRemain: 'All the company data you downloaded will remain in your repository'
    },
    Table: {
      NumberOfCompanies: 'Number of companies',
      EnrichedCompanies: 'Enriched companies',
      CreationDate: 'Creation date',
      LastUpdateDate: 'Last update date',
      CampaignsWithThisPortfolio: 'Campaigns with this portfolio'
    },
    Menu: {
      ChangeName: 'Change name',
      DuplicatePortfolio: 'Duplicate portfolio',
      DeletePortfolio: 'Delete portfolio',
      AddNote: 'Add note',
      EditNote: 'Edit note'
    },
    Message: {
      Header: 'Click on "Upload portfolio" to upload your first portfolio!',
      Description:
        'Here you can see a list already saved with prospect suggested. Click on its name and start to explore it and manage companies inside the list.'
    },
    UploadComplete: 'Upload complete!',
    CompaniesUploadedCorrectly: 'Companies uploaded correctly',
    CeasedCompaniesNotMatched: 'Ceased companies or not matched not uploaded',
    ErrorsDueWrongData: 'Errors due to wrong data',
    Download: 'Download',
    ListOfCeasedCompanies: 'List of ceased companies',
    ListOfErrors: 'List of errors',
    Stats: {
      Companies: 'Company',
      Companies_plural: 'Companies',
      AllCompanies: 'All companies',
      EnrichedCompanies: 'Enriched company',
      EnrichedCompanies_plural: 'Enriched companies',
      Enriched: 'Enriched',
      NotEnriched: 'Not enriched',
      Prospect: 'Prospect',
      Prospect_plural: 'Prospects',
      Lead: 'Lead',
      Lead_plural: 'Leads',
      Client: 'Client',
      Client_plural: 'Clients',
      Supplier: 'Supplier',
      Supplier_plural: 'Suppliers',
      Competitor: 'Competitor',
      Competitor_plural: 'Competitors',
      LastUpdate: 'Last update'
    },
    Enrich: 'Enrich',
    EnrichTooltip: 'Enhance the data of these companies',
    Analyze: 'Analyze portfolio',
    AnalyzeTooltip: 'Visualize the portfolio data with respect to the main variables',
    Similarity: 'Generate similarity',
    SimilarityTooltip: 'Apply the similarity algorithm to find new prospects',
    SelectSource: 'Loading companies from template files',
    UpdatePortfolio: 'Update Portfolio'
  },
  Table: {
    companyName: 'Company name',
    lastUpdateDate: 'Last update',
    companyUnitTypeCode: 'Company unit type',
    companyStatusCode: 'Status',
    employeeNumber: 'Employee number',
    companyPortfolioStatus: 'State',
    sale: 'Sales',
    Sale: 'Sales',
    '[tags]': 'Tags',
    startDate: 'Start date',
    revenue: 'Revenue',
    turnover: 'Turnover',
    isRating4: 'Rating',
    Empty: 'We have not found any companies that match your search',
    isEnriched: 'Is enriched'
  },
  SelectColumns: 'Select columns',
  LoadMore: 'Load more',
  MapDescription: 'The map view shows the individual companies regardless of their status in the portfolio',
  CompanyGroupActions: {
    Title: 'Group actions',
    Actions: 'Actions',
    AreaManager: 'Area manager',
    Sales: 'Sales',
    Products: 'Products',
    Select: 'Select',
    NotAssigned: 'Not assigned',
    SelectAreaManager: 'Select area manager',
    SelectSales: 'Select sales',
    SelectProducts: 'Select product',
    ActionItems: {
      DuplicateCompany: 'Duplicate company',
      DeleteCompany: 'Delete company',
      MoveCompany: 'Move company to another portfolio',
      MoveCompany_plural: 'Move {{count}} companies to another portfolio',
      Enrich: 'Enrich',
      CreatePortfolio: 'Create Portfolio',
      DownloadExcel: 'Download Portfolio',
      AddLocalUnits: 'Add Local Units',
      ShareCompanies: 'Share companies',
      UnshareCompanies: 'Stop sharing companies'
    },
    CreatePortfolio: {
      Title: 'Create Portfolio',
      InputDescription: 'Enter a name for the portfolio you are creating',
      InsertPortfolioName: 'Insert portfolio name'
    },
    HiddenCompanyMessage: 'The operation will not be applied to companies belonging to hidden portfolios.'
  },
  Actions: {
    ViewCampaigns: 'View all the relative campaigns',
    LoadQuery: 'Load Query',
    LoadQueryTooltip: 'Open a saved query',
    SaveQuery: 'Save Query',
    SaveQueryTooltip: 'Save this query in the Portfolio Management module',
    Download: 'Download Portfolio',
    DownloadTooltip: 'Download the portfolio in Excel format on your device',
    Analyze: 'Analyze portfolio',
    AnalyzeTooltip: 'Visualize the portfolio data with respect to the main variables',
    Similarity: 'Generate Similarity',
    SimilarityTooltip: 'Apply the similarity algorithm to find new prospects',
    Campaign: 'Create campaign',
    CampaignTooltip: 'Assign the companies to the sales team and plan a commercial campaign',
    Update: 'Update Portfolio',
    UpdateTooltip: 'Add new companies to this portfolio or update the custom variables',
    LocalUnits: 'Add Local Units',
    LocalUnitsTooltip: 'Add branches connected to the headquarters in this portfolio',
    SendToOtherModules: 'Send to Other Modules',
    SendToOtherModulesTooltip: 'Send the companies to Sales tool or to Analyze market',
    UnlockEsg: 'Unlock ESG Score',
    UnlockEsgTooltip: 'Unlock ESG Score for the companies inside the portfolio',
    MaxEsgUnlockExceededTooltip:
      'You can unlock ESG Score for a maximum of {{count}} companies at a time. Please select fewer companies.'
  },
  Enriched: 'Enriched',
  Enrich: 'Enrich',
  Monitored: 'Followed',
  Close: 'Close',
  Filters: 'Filters',
  UserFilters: 'User filters',
  Download: {
    DownloadPortfolio: 'Download portfolio',
    SelectTypeOfList: 'Select type of list',
    SelectTypeOfListDescription: 'Select the type of list you want to download.',
    DownloadCost: 'Download cost will be the same for every type of list.',
    Base: 'Base',
    BaseDescription: 'The list will contain the basic set of data related to the companies.',
    Complete: 'Complete',
    CompleteDescription: 'The list will contain the complete set of data related to the companies',
    ExceededMaximumCompanies: 'Exceeded maximum number of downloadable companies',
    Marketing: 'Marketing',
    MarketingDescription: 'The list will contain the standard set of data related to the companies',
    Standard: 'Marketing',
    Custom: 'Custom',
    FLLBIR_ID: 'Full',
    LGHBIR_ID: 'Light',
    FLLBIR_PH: 'Full',
    LGHBIR_PH: 'Light',
    CustomDescription: 'You can customize the list in order to download and/or save only the set of data you need',
    CustomTracks: 'Saved tracks',
    CustomTracksDefaultSelection: 'No custom track selected',
    SaveCustomTrack: 'Save Custom Track',
    SaveCustomTrackQuestion: 'Do you want to save the data packets you chose for this Custom Track?',
    SaveCustomTrackTitle: 'Save custom track',
    SaveCustomTrackDescription:
      'Insert the name you want to use to save your custom track or select an existing track to override it.',
    SaveCustomTrackName: 'Custom track name',
    SaveCustomTrackUpdateExisting: 'Update existing',
    SaveCustomTrackCreateNew: 'Create New',
    DownloadExample: 'Download Example',
    Download: 'Download',
    ForEveryCompanyInList: 'for every company in the list',
    ToDownloadYouNeedToPurchase: 'To download the list you need to purchase {{companyName}} company data',
    YouAreDownloading:
      'You are downloading an Excel file containing all the {{companyName}} data requested. It will be saved in the "Portfolio Management" section of your account.',
    CustomExport: {
      StepTitle: 'Select data',
      Description1: 'Select data you want to include in your download',
      Description2: 'Download cost will be the same for every type of list',
      DataPacket: 'Data packet',
      DataPacketSearchPlaceholder: 'Find datapackets or specific fields'
    },
    Dialog1: 'The requested file is being processed and may take several minutes.',
    Dialog2: 'You can follow the progress from the notifications menu.',
    SaveSuccess: 'saved as Custom Track',
    SaveSuccessDescription: 'You will find the track saved in the repository related to the reference area.'
  },
  Enrichment: {
    PortfolioEnrichment: 'Companies Enrichment. This could take a few minutes. Proceed with the operation?',
    Enrichment: 'Enrichment',
    EnrichmentComplete: 'Enrichment completed',
    EnrichmentDescription:
      'By enriching the company with the {{companyName}} data, these information will be downloaded and will be updated periodically',
    CompaniesInPortfolio: 'Company in this portfolio',
    CompaniesInPortfolio_plural: 'Companies in this portfolio',
    CompaniesEnriched: 'Company already enriched',
    CompaniesEnriched_plural: 'Companies already enriched',
    CompaniesNotEnriched: 'Company not enriched',
    CompaniesNotEnriched_plural: 'Companies not enriched',
    CreditsWillBeUsed: 'Ring used',
    CreditsWillBeUsed_plural: 'Rings used',
    CreditsRemaining: 'Remaining ring',
    CreditsRemaining_plural: 'Remaining rings',
    CompaniesSuccesfullyEnriched: 'Company enriched',
    CompaniesSuccesfullyEnriched_plural: 'Companies enriched'
  },
  CompanyDeletion: {
    ConfirmDelete: 'Are you sure you want to delete the company ?',
    DeleteSuccess: 'Company correctly deleted. You may need to wait a few seconds for the changes to take effect.'
  },
  CompaniesMove: {
    ConfirmMove:
      'The company you have selected, will be removed from the current portfolio and added to another portfolio. Please, select one portfolio from the list below.',
    ConfirmMove_plural:
      'The companies you have selected, will be removed from the current portfolio and added to another portfolio. Please, select one portfolio from the list below.',
    MoveSuccess: 'Move operation is in progress, this could take a few minutes.',
    SelectPortfolio: 'Select portfolio'
  },
  CompanyAssignment: {
    ConfirmOperation: 'Confirm operation',
    ProductAssignment:
      'Assignment of the product {{product}} to the selected companies. This could take a few minutes. Proceed with the operation?',
    SalesAssignment:
      'Assignment of the sales man {{sales}} to the selected companies. This could take a few minutes. Proceed with the operation?',
    AreaManagerAssignment:
      'Assignment of the area manager {{manager}} to the selected companies. This could take a few minutes. Proceed with the operation?',
    ProductUnassignment:
      'Unassignment to the selected companies. This could take a few minutes. Proceed with the operation?',
    SalesUnassignment:
      'Unassignment to the selected companies. This could take a few minutes. Proceed with the operation?',
    AreaManagerUnassignment:
      'Unassignment to the selected companies. This could take a few minutes. Proceed with the operation?'
  },
  DialogError: 'An error occurred while processing the request.',
  Credits: 'Rings',
  LoadQuery: {
    DialogTitle: 'Load saved search',
    SaveBefore: {
      Question: 'Do you want to save the search of prospects before loading the search?',
      Description: 'Save your prospects search in a new one before uploading the query to avoid losing data',
      OkBtn: 'Save search',
      CancelBtn: 'Skip save'
    }
  },
  DomainFilter: {
    SaveAndClose: 'Save filters & close'
  },
  ManageDuplicates: {
    Title: 'Manage duplicates',
    Description: 'To duplicate a company is necessary to change its status or its product',
    GroupActions: 'Group actions',
    Duplicates: '{{count}} duplicate',
    Duplicates_plural: '{{count}} duplicates',
    ShowOK: 'Show OK',
    HideOK: 'Hide OK',
    SelectColumns: 'Select columns'
  },
  GenerateSimilarity: {
    Title: 'Generate Similarity: select a representative status',
    TitleClusterAllowed: 'Generate Similarity',
    Description: 'To start the analysis you need to select the type of status you want to process',
    DropdownPlaceholder: 'Select status',
    GotoActionButton: 'Select',
    IncludeSemanticClusters: 'Include Margo Semantic Clusters'
  },
  Campaign: {
    YouNeedToEnrich: 'To start a campaign, all companies in the portfolio need to be enriched.'
  },
  CreatingPortfolio: 'Creating portfolio',
  UpdatingPortfolio: 'Updating portfolio',
  Share: {
    SharePortfolioTitle: 'Share portfolio',
    Subtitle: 'Select the users',
    Description: 'Select the users you want to share the portfolio',
    Success: 'Updating sharing executed correctly.',
    ShareCompany: 'Share company',
    DeleteSharing: 'Delete sharing',
    SharedCompanies: 'Companies shared',
    SharedPortfolio: 'Shared portfolio',
    SharePortfolio: 'Sharing portfolio',
    SharedByOwner: 'Shared by {{ownerName}}',
    NotActiveUser: 'user not active',
    ShareCompanies: {
      Title: 'Share companies',
      Subtitle: 'Select the users',
      Description: 'Select the users you want to share the companies',
      WithAreaManager: 'Share companies with area manager',
      WithSales: 'Share companies with sales',
      ConfirmTitle: 'Confirm Operation',
      ConfirmSubtitle: 'Updating sharing with the selected user.',
      ConfirmDescription: 'This could take a few minutes. Proceed with the operation?',
      Check: 'Checking if all companies are valid for share.',
      CheckFail: 'You must assign the selected companies.'
    },
    UnshareCompanies: {
      Title: 'Stop sharing companies',
      Subtitle: 'Select the users',
      Description: 'Select the users you want to stop sharing the companies',
      WithAreaManager: 'Stop sharing companies with area manager',
      WithSales: 'Stop sharing companies with sales',
      ConfirmTitle: 'Confirm Operation',
      ConfirmSubtitle: 'Updating unsharing from the selected user',
      ConfirmDescription: 'This could take a few minutes. Proceed with the operation?',
      Check: 'Checking if all companies are valid for unshare.',
      CheckFail: 'You must assign the selected companies.'
    }
  },
  Exclude: {
    Title: 'Excluding portfolio',
    ExcludedPortfolio: 'Excluded Portfolio',
    Description: 'Select users who will receive the selected portfolio in exclusion',
    Success: 'Exclusion sharing executed correctly.',
    Error: 'We are sorry, but we could not share in exclusion this portfolio.'
  },
  AddLocalUnits: {
    Title: 'Add Local Units',
    NoCompanySelected: 'No company selected. Please add at least one Headquarter.',
    NoHeadquarter: 'The selected portfolio already contains Local Units. Please add at least one Headquarter.',
    NoHeadquarterSelected: 'The selected companies are already Local Units. Please add at least one Headquarter.',
    Enrich: {
      Title: 'To add local units you need to purchase {{companyName}} company data',
      Description:
        'You are adding local units to a portfolio containing all the {{companyName}} data requested. It will be saved in the "Use" section of your account.'
    },
    ChooseBranch: {
      Title: 'Choose the branch you want to add.',
      Description: 'Select the branch type to be added.',
      All: 'All the Branch types',
      DeleteAll: 'Delete all'
    },
    ChooseStatus: {
      Title: 'Choose the status you want to add.',
      Description: 'Select the initial status of the companies to be added.'
    },
    CreateTag: {
      Title: 'Create tag.',
      Description: 'Each company will be defined by a tag that you create.',
      Label: 'Create tag (optional)',
      Placeholder: 'Insert tag'
    }
  },
  EditPortfolioCompany: {
    Title: 'Edit company values',
    Subtitle: 'Change values',
    Description:
      'Choose what fields you want to modify and insert into the related box the new values. In case the same company is present multiple times into the portfolio, the fields Company Status and Product will not be editable.',
    CompanyStatus: 'Status in portfolio',
    Amount: 'Amount',
    AreaManager: 'Area Manager',
    Sales: 'Sales',
    Product: 'Product',
    Tags: 'Tags',
    Save: 'Save',
    Error: 'Error',
    ErrorDescription: 'Failed to load data.',
    SaveSuccessTitle: 'Changes saved',
    SaveSuccessText: 'The changes have been saved correctly.',
    SaveErrorTitle: 'Error'
  },
  SendToOtherModulesDialog: {
    Title: 'Select module',
    SubTitle: 'Select the module where you want to send your analysis or list to',
    Targeting: 'Analyze market',
    TargetingDescription:
      'Click here to send the list of identified companies to the Analyze Market module, to consult updated graphs that provide a representation of the companies’ distribution with respect to the main variables.',
    SalesTool: 'Sales tool',
    SalesToolDescription:
      'Click here to send the list of identified companies to the Sales Tool module. The list will be saved in a portfolio and the companies’ data will be enriched. Within the Sales Tool module, you can start a sales campaign on the selected companies and assign it to your sales force.',
    OkButton: 'Send',
    DisabledTargetingTooltip: 'This action is possible only for portfolios with more than {{count}} company',
    DisabledTargetingTooltip_plural: 'This action is possible only for portfolios with more than {{count}} companies'
  },
  MonitoringRemoval: {
    Title: 'Following removal',
    ButtonTooltip: 'You are following this portfolio. Click here to unfollow.',
    UnfollowSuccess: 'Cancellation of portfolio following has been executed sucessfully.',
    UnfollowAndSaveSuccess:
      'Cancellation of portfolio following has been executed sucessfully and query "{{queryName}}" has been created.',
    UnfollowAndUpdateSuccess:
      'Cancellation of portfolio following has been executed sucessfully and query "{{queryName}}" has been updated.',
    UnfollowFailure: 'Cancellation of portfolio following has failed. Please try again later or contact our support.',
    WarningMessage:
      'By selecting save, this portfolio will not be followed anymore. Note that it will not be possible to put this portfolio in a followed state anymore. Do you want to save the query that has been used to create this portfolio?'
  },
  UnlockPortfolioEsgDataDialog: {
    Title: 'Unlock ESG Score',
    SubTitle: 'Are you sure you want to unlock ESG Scores?',
    UnlockText:
      'We will check and unlock all the companies that have ESG Company Score and data will be visible for six months from the unlock date, or until subscription end date if sooner. Each unlock will cost one ESG credit. When the time expires for ESG data of unlocked companies, you will have to unlock them again. We will send a notification at the end of the operation. It may take few minutes.',
    UnlockRequestedText:
      'Unlocking ESG Company Score successfully requested. It may take few minutes. You can close this dialog now.',
    EnrichmentRequiredText:
      'Sorry, the operation cannot be performed because there are companies not enriched in the list. Enrich them to continue, or select only enriched companies.',
    OkUnlock: 'Confirm',
    OkEnrich: 'Enrich',
    ErrorUnlockingEsgData: 'Unexpected error while unlocking ESG Company Score. Please try again later.'
  }
}
