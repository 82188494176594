/* eslint-disable import/no-default-export */
export default {
  UserName: 'Užívateľské meno',
  InsertUserName: 'Vložte svoje užívateľské meno',
  Email: 'E-mail',
  InsertEmail: 'Vložte svoj e-mail',
  ResetPassword: 'Obnoviť heslo',
  Success: 'Vaše heslo bolo úspešne obnovené. Poslali sme vám nové do vašej e-mailovej schránky.',
  Propmt:
    'Zadajte užívateľské meno a e-mailovú adresu, ku ktorej je váš účet priradený. Heslo pre prístup k službe vám bude zaslané do vami uvedenej e-mailovej schránky.'
}
