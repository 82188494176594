import styled from 'styled-components/macro'
import { theme } from '@utils/theme'
import CloseIcon from '../../assets/icons/close.svg?react'

export const Close = styled(CloseIcon)`
  fill: white;
`

export const AssistantButton = styled.div<{ bottom?: string }>`
  position: fixed;
  bottom: ${({ bottom }) => bottom};
  right: 75px;
  width: 44px;
  bottom: 40px;
  height: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 46000;
  background-color: ${theme.colors.main};
  color: white;
  border-radius: 50%;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12);
  z-index: 46001;
`
