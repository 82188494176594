import _ from 'lodash'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  getPortfolioCompaniesByIdentificationCode,
  getPortfolioCompanyDuplicates,
  resolvePortfolioCompanyDuplicates
} from '../../api/portfolio'
import { GetPortfolioCompanyDuplicatesResponse, ResolvedPortfolioCompanyDuplicate } from '../../api/portfolio/types'
import { LoadingActions } from '../loading/actions'
import { withLoadingParametrized } from '../loading/sagas'
import { mapPortfolioCompanyDuplicatesResponse } from '../mappers'
import {
  FetchDuplicatesAction,
  FETCH_DUPLICATES,
  PortfolioCompanyDuplicatesActions,
  SetAction,
  SET_DUPLICATE_PRODUCT,
  SET_DUPLICATE_STATUS,
  SUBMIT,
  SubmitAction
} from './actions'
import { getPortfolioCompaniesByIdentificationCode as getPortfolioCompaniesByIdCode, getTableData } from './selectors'
import { DuplicateTableRow, PortfolioCompanyDuplicate } from './types'

export function* fetchDuplicates(action: FetchDuplicatesAction) {
  const { portfolioId } = action.payload

  const data: GetPortfolioCompanyDuplicatesResponse = yield call(getPortfolioCompanyDuplicates, portfolioId)
  const duplicates = mapPortfolioCompanyDuplicatesResponse(data)

  yield put(PortfolioCompanyDuplicatesActions.setDuplicates(portfolioId, duplicates))
}

export function* validateStatusAndProduct(action: SetAction) {
  const { portfolioId, rowId } = action.payload

  const tableData: DuplicateTableRow[] = yield select(getTableData, { portfolioId })
  const company = _.find(tableData, { id: rowId })!
  // TODO: this should be tax code, but api does not provide it, company.id is temporary fix
  const identificationCode = company.id

  yield put(PortfolioCompanyDuplicatesActions.setValidating(portfolioId, rowId, true))

  let lookup: PortfolioCompanyDuplicate[] = yield select(getPortfolioCompaniesByIdCode, {
    portfolioId,
    identificationCode
  })

  if (_.isEmpty(lookup)) {
    const data: GetPortfolioCompanyDuplicatesResponse = yield call(
      getPortfolioCompaniesByIdentificationCode,
      portfolioId,
      identificationCode
    )

    lookup = mapPortfolioCompanyDuplicatesResponse(data)

    yield put(PortfolioCompanyDuplicatesActions.setLookup(portfolioId, identificationCode, lookup))
  }

  if (!_.isNil(company.selectedStatus) && !_.isNil(company.selectedProduct)) {
    const isResolved = !_.find(lookup, { status: company.selectedStatus, product: company.selectedProduct })
    yield put(PortfolioCompanyDuplicatesActions.setResolved(portfolioId, rowId, isResolved))
  } else {
    yield put(PortfolioCompanyDuplicatesActions.setResolved(portfolioId, rowId, false))
  }

  yield put(PortfolioCompanyDuplicatesActions.setValidating(portfolioId, rowId, false))
}

export function* submit(action: SubmitAction) {
  const { portfolioId } = action.payload

  const duplicates: DuplicateTableRow[] = yield select(getTableData, { portfolioId })

  const resolvedDuplicates: ResolvedPortfolioCompanyDuplicate[] = _.map(duplicates, x => ({
    margoId: x.id,
    status: x.selectedStatus!,
    product: x.selectedProduct!
  }))

  yield call(resolvePortfolioCompanyDuplicates, portfolioId, resolvedDuplicates)

  yield put(PortfolioCompanyDuplicatesActions.close(portfolioId))
}

export function* rootSaga() {
  yield all([
    takeEvery(
      FETCH_DUPLICATES,
      withLoadingParametrized(LoadingActions.setPortfolioCompanyDuplicatesLoading, fetchDuplicates, action => ({
        portfolioId: action.payload.portfolioId
      }))
    ),
    takeLatest(SET_DUPLICATE_STATUS, validateStatusAndProduct),
    takeLatest(SET_DUPLICATE_PRODUCT, validateStatusAndProduct),
    takeLatest(
      SUBMIT,
      withLoadingParametrized(LoadingActions.setPortfolioCompanyDuplicatesLoading, submit, action => ({
        portfolioId: action.payload.portfolioId
      }))
    )
  ])
}
