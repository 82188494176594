/* eslint-disable import/no-default-export */
export default {
  LogoutPrimaryText: 'Zadejte své přihlašovací údaje pro přístup ke službě',
  LogoutSecondaryText: 'Přidejte informace o sobě a své pozici ve společnosti, jejíž jste součástí',
  PortfolioManagement: 'Správa portfolia',
  Targeting: 'Analýza trhu',
  Prospecting: 'Výběr klientů',
  SalesTool: 'Obchodní aktivity',
  API: 'API',
  Operations: 'Operace',
  SemanticSearch: 'Přejít na sémantické vyhledávání',
  FreeSearchPlaceholderWithCampaigns: 'Vyhledejte společnost, portfolio nebo kampaň podle jména',
  FreeSearchPlaceholder: 'Vyhledejte společnost nebo portfolio podle jména',
  SearchErrorTooLong: 'Maximální počet znaků: {{maxLimit}}',
  SearchErrorTooShort: 'Prosím zadej alespoň {{minLimit}} znaky',
  FeedbackArea:
    'Oblast notifikací: uvnitř najdete své poslední dokončené akce nebo můžete zkontrolovat průběh probíhajících akcí',
  Profile: 'Profil',
  Notification: {
    Title: 'Oznámení',
    ViewAll: 'Zobrazit vše'
  },
  Appointments: 'Schůzky'
}
