import React from 'react'
import _ from 'lodash'

type Props = {
  condition: boolean | (() => boolean)
  children?: React.ReactNode
  fallback?: React.ReactNode
}

export const ConditionallyVisible = ({ condition, fallback, children }: Props) => {
  const conditionResult = _.isFunction(condition) ? condition() : condition
  if (!conditionResult) {
    return _.isNil(fallback) ? null : <>{fallback}</>
  }
  return _.isNil(children) ? null : <>{children}</>
}

type Params = {
  condition: boolean | (() => boolean)
  render: () => React.ReactNode
  fallback?: () => React.ReactNode
}

export const RenderConditionally = ({ condition, render, fallback }: Params): React.ReactNode => {
  const conditionResult = _.isFunction(condition) ? condition() : condition
  if (!conditionResult) {
    return fallback ? fallback() : null
  }
  return render()
}
