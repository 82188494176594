import React from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components/macro'
import { gridTheme } from '../../utils/theme'
import { Footer } from '../App/Footer'

const LayoutWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    /* Safari does not support viewport units so use % instead of vh for correct display on iphones */
    height: 100%;
  }
`

const LeftPart = styled.div`
  height: 100%;
  display: flex;
  float: left;
  flex-direction: column;
  overflow-y: auto;
  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    width: 100%;

    #footer {
      display: block;
    }
  }
  ${media.sm`
    width: 100%;
  `}
  ${media.md`
    width: 320px;
    #footer {
      display: none;
    }
  `}
  ${media.lg`
    width: 480px;
  `}
`

const RightPart = styled.div`
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    display: none;
  }
  ${media.sm`
    display: none;
  `}
  ${media.md`
    display: flex;
    width: calc(100% - 320px);
  `}
  ${media.lg`
    width: calc(100% - 480px);
  `}
`

type LayoutProps = {
  leftContent: React.ReactNode
  rightContent: React.ReactNode
}

export const TwoColumnFullPageLayout = ({ leftContent, rightContent }: LayoutProps) => (
  <LayoutWrapper>
    <LeftPart>
      {leftContent}
      <Footer />
    </LeftPart>
    <RightPart>
      {rightContent}
      <Footer />
    </RightPart>
  </LayoutWrapper>
)
