// eslint-disable-next-line import/no-default-export
export default {
  Title: 'PNRR - Piano Nazionale di Ripresa e Resilienza',
  UnavailableForMobile: 'La pagina alla quale stai cercando di accedere è disponibile per tablet e desktop.',
  Description:
    'Con il modulo PNRR potrai visualizzare e segmentare le imprese in base agli investimenti previsti e costruire il tuo target',
  Box: {
    Title: 'Contesto Generale',
    Text: "Nel dicembre 2019, la presidente della Commissione europea Ursula von der Leyen, ha presentato lo European Green Deal che intende rendere l'Europa il primo continente a impatto climatico zero entro il 2050.\nLa pandemia, e la conseguente crisi economica hanno spinto UE a formulare una risposta coordinata a livello sia congiunturale, con la sospensione del Patto di Stabilità e ingenti pacchetti di sostegno all'economia adottati dai singoli Stati membri, sia strutturale, in particolare con il lancio a luglio 2020 del programma New Generation EU (NGEU).\nNel settembre 2020, il Comitato interministeriale per gli Affari Europei (CIAE) ha approvato una proposta di linee guida per la redazione del PNRR, che è stata sottoposta all'esame del Parlamento italiano. Il 13 e 14 ottobre 2020 le Camere si sono pronunciate con un atto di indirizzo che invitava il Governo a predisporre il Piano garantendo un ampio coinvolgimento del settore privato, degli enti locali e delle forze produttive del Paese.\nNei mesi successivi ha avuto luogo un'approfondita interlocuzione informale con la task torce della Commissione europea. Il 12 gennaio 2021 il Consiglio dei ministri ha approvato una proposta di PNRR sulla quale il Parlamento ha svolto un approfondito esame, approvando le proprie conclusioni il 31 marzo 2021.\nIl Governo ha provveduto ad una riscrittura del Piano, anche alla luce delle osservazioni del Parlamento. Nel mese di aprile 2021, il piano è stato discusso con gli enti territoriali, le forze politiche e le parti sociali."
  },
  SelectTheTarget: "Seleziona l'Investimento PNRR desiderato",
  SelectTheTargetTooltip: 'Screening e individuazione di imprese target per specifici investimenti',
  InvestmentsWizard: {
    Title: 'Analisi PNRR',
    SelectAll: 'Seleziona tutto',
    StepOne: {
      Title: "Seleziona su quale portafoglio effettuare l'analisi",
      Description:
        "Puoi effettuare l'analisi su uno o più dei tuoi portafogli oppure visualizzare l'analisi aggregata di tutte le imprese nel sistema di {{companyName}}.",
      PortfolioOption: 'Seleziona portafoglio',
      AggregateOption: 'Tutte le imprese nel sistema {{companyName}}',
      UploadedPortfolio: 'Portafoglio caricato'
    },
    StepTwo: {
      Title: 'Scegli lo stato che vuoi analizzare',
      DescriptionLine1: "Seleziona lo stato dell'impresa da analizzare.",
      DescriptionLine2: "Durante l'analisi puoi cambiare lo stato usando i filtri.",
      Status: 'Stato'
    },
    StepThree: {
      Title: "Seleziona la missione relativa all'investimento PNRR",
      DescriptionLine1:
        'Il dispositivo RRF richiede agli Stati membri di presentare un pacchetto di investimenti e riforme: il Piano Nazionale di Ripresa e Resilienza (PNRR). Questo Piano, che si articola in sei Missioni e 16 Componenti, beneficia della stretta interlocuzione avvenuta in questi mesi con il Parlamento e con la Commissione Europea, sulla base del Regolamento RRF.',
      DescriptionLine2:
        'Le sei Missioni del Piano sono: digitalizzazione, innovazione, competitività, cultura e turismo; rivoluzione verde e transizione ecologica; infrastrutture per una mobilita sostenibile; istruzione e ricerca.',
      Mission: 'Missione'
    },
    StepFour: {
      Title: "Seleziona la componente relativa all'investimento PNRR",
      Description:
        'Il Piano si articola in sedici Componenti, raggruppate in sei Missioni. Queste ultime sono articolate in linea con i sei Pilastri menzionati dal Regolamento RRF e illustrati nel precedente paragrafo, sebbene la formulazione segua una sequenza e una aggregazione lievemente differente.',
      Component: 'Componente'
    },
    StepFive: {
      Title: "Seleziona l'ambito relativo all'investimento PNRR",
      Description:
        "Sulla base di quanto sopra descritto, la Riforma della giustizia si snoda in alcuni ambiti di intervento prioritari come interventi sull'organizzazione: Ufficio del processo e potenziamento dell'amministrazione, Riforma del processo civile e Alternative Dispute Resolution (ADR), Riforma della giustizia tributaria, Riforma del processo penale, Riforma dell'Ordinamento giudiziario.",
      Scope: 'Ambito'
    },
    StepSix: {
      Title: "Seleziona l'investimento relativo al PNRR",
      Description:
        "Il PNRR italiano prevede 151 Investimenti all'interno delle diverse Componenti delle sei Missioni. Obiettivo degli Investimenti è rilanciare la produttività del Paese e la crescita dell'economia italiana, per renderla più digitale, dinamica, sostenibile e inclusiva.",
      Investment: 'Investimento'
    }
  },
  Switch: {
    Pnrr: 'PNRR',
    SubsidizedFinancing: 'Finanza Agevolata'
  },
  SubsidizedFinancing: {
    Title: 'Finanza Agevolata',
    Description:
      'Con la Finanza Agevolata potrai visualizzare tutti gli investimenti e gli appalti pubblici per cui la tua impresa di interesse è elegibile. Inoltre, potrai caricare un portafoglio per conoscere tutte le informazioni su un set di imprese.',
    CheckForSubsidizedFinancing: 'Carica lista per Finanza Agevolata',
    LatestDocuments: {
      Title: 'Ultimi Documenti',
      DocumentName: 'Nome Documento',
      PortfolioName: 'Nome Portafoglio',
      Type: 'Tipo',
      CreationDate: 'Data di creazione',
      Status: 'Stato',
      LoadMore: 'Load more',
      OpenDocument: 'Open document',
      Delete: 'Delete',
      DownloadDocument: 'Download document'
    }
  }
}
