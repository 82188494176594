import React from 'react'
import styled, { css, DefaultTheme } from 'styled-components/macro'
import { centerChildrenMixin } from '../Center'
import { RenderConditionally } from '../HideFeature'
import { Size, Spin, ExtraLargeSpin, Type } from './Spin'

export const backgroundColorByType = (t: DefaultTheme, type?: Type) => {
  switch (type) {
    case 'dark':
      return t.colors.white
    default:
      return t.colors.main
  }
}

const loadingMixin = css<{ color?: string; type?: Type }>`
  top: 0;
  left: 0;
  z-index: 9990;
  background-color: #000;
  opacity: 0.5;
  background-color: ${({ theme, color, type = 'dark' }) => color || backgroundColorByType(theme, type)};
  ${centerChildrenMixin}
`

export const Container = styled.div<{ $minHeight?: string }>`
  height: 100%;
  width: 100%;
  position: relative;
  ${({ $minHeight }) =>
    $minHeight &&
    `
    min-height: ${$minHeight};
  `}
`

export const ContainerLoadingWrapper = styled.div`
  && {
    width: 100%;
    height: 100%;
    position: absolute;
    ${loadingMixin}
  }
`

export const PageLoadingWrapper = styled.div`
  && {
    width: 100vw;
    height: 100vh;
    position: fixed;
    ${loadingMixin}
  }
`

type LoadingOverlayContainerProps = {
  loading: boolean
  children?: React.ReactNode
  overlayColor?: string
  spinnerColor?: string
  type?: Type
  size?: Size
  minHeight?: string
  className?: string
}

export const LoadingOverlayContainer = ({
  loading,
  children,
  overlayColor,
  spinnerColor,
  type = 'dark',
  size = Size.ExtraLarge,
  minHeight,
  className
}: LoadingOverlayContainerProps) => (
  <Container $minHeight={minHeight} className={className}>
    {loading && (
      <ContainerLoadingWrapper color={overlayColor} type={type}>
        <Spin color={spinnerColor} type={type} size={size} delay={150} />
      </ContainerLoadingWrapper>
    )}
    {children}
  </Container>
)

type LoadingOverlayPageProps = {
  loading: boolean
  overlayColor?: string
  spinnerColor?: string
  type?: Type
  children: React.ReactNode
}

export const LoadingOverlayPage = ({
  loading,
  children,
  overlayColor,
  spinnerColor,
  type = 'dark'
}: LoadingOverlayPageProps) => (
  <>
    {RenderConditionally({
      condition: loading,
      render() {
        return (
          <PageLoadingWrapper data-testid="loading-overlay-page" color={overlayColor} type={type}>
            <ExtraLargeSpin color={spinnerColor} delay={150} />
          </PageLoadingWrapper>
        )
      }
    })}
    {children}
  </>
)
