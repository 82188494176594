import dayjs from '@dayjs'
import { HasRingsWarningType } from './types'
import { getParsedValueFromLocalStorage, saveStringifiedToLocalStorage } from '../../features/storage/utils'
import { LocalStorageKeys } from '../../features/storage/types'

export const getHasRingsWarningFromLocalStorageForUser = (userId: string): HasRingsWarningType | undefined => {
  const hasRingsWarning = getParsedValueFromLocalStorage<Record<string, HasRingsWarningType>>(
    LocalStorageKeys.HAS_RINGS_WARNING,
    {}
  )

  return hasRingsWarning[userId]
}

export const setHasRingsWarningToLocalStorageForUser = (hasRingsWarningValue: boolean, userId: string) => {
  const hasRingsWarning = getParsedValueFromLocalStorage<Record<string, HasRingsWarningType>>(
    LocalStorageKeys.HAS_RINGS_WARNING,
    {}
  )

  hasRingsWarning[userId] = {
    expiration: dayjs().endOf('day'),
    hasRingsWarning: hasRingsWarningValue
  } as HasRingsWarningType

  saveStringifiedToLocalStorage(LocalStorageKeys.HAS_RINGS_WARNING, hasRingsWarning)
}
