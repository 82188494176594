import React from 'react'
import { TERMS_OF_USE } from '@routes'
import { useTranslation } from 'react-i18next'
import { AssistantMessageBubble, CustomerMessageBubble, MessageContainer } from './styled'
import { RatingContainer } from '../Rating/styled'
import { MessageData } from '../types'
import RatingComponent from '../Rating/Rating'
import { FeedbackMessage, SenderLabel } from '../styled'

type Props = {
  message: MessageData
  onRate: (value: number) => void
  thankYouMessageVisible: boolean
  feedbackGiven: boolean
  messageIndex?: number
}

export const ChatMessage = ({ message, onRate, feedbackGiven, thankYouMessageVisible, messageIndex }: Props) => {
  const { t } = useTranslation()
  const handleLinkClick = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    window.location.href = TERMS_OF_USE
  }

  return (
    <MessageContainer sender={message.sender}>
      {message.sender === 'Assistant' ? (
        <AssistantMessageBubble>
          {message.isInitial ? (
            <>
              {message.text}
              <a href="/terms-of-use" onClick={handleLinkClick}>
                {t('common:TermsOfUse')}
              </a>
            </>
          ) : (
            message.text
          )}
        </AssistantMessageBubble>
      ) : (
        <CustomerMessageBubble>{message.text}</CustomerMessageBubble>
      )}
      <SenderLabel sender={message.sender}>{t(`common:AssistantAi.${message.sender}`)}</SenderLabel>

      {message.sender === 'Assistant' && messageIndex !== 0 && !message.isRated && (
        <RatingContainer>
          <RatingComponent onRate={value => onRate(value)} />
        </RatingContainer>
      )}
      {message.sender === 'Assistant' && thankYouMessageVisible && (
        <FeedbackMessage feedbackGiven={feedbackGiven}>{t('common:AssistantAi.Feedback')}</FeedbackMessage>
      )}
    </MessageContainer>
  )
}
