import _ from 'lodash'
import { ArrayCompare, DataTree, KeyTree, Tree, TreeKeySelection, TreeNodeData, TreePath } from './types'

export function compareArrays(a: string[], b: string[], distinguishStartsWith = false): ArrayCompare {
  for (let i = 0; i < b.length; i += 1) {
    if (a.length - 1 < i) return ArrayCompare.DIFFERENT
    if (a[i] !== b[i]) return ArrayCompare.DIFFERENT
  }
  if (distinguishStartsWith) {
    return a.length === b.length ? ArrayCompare.EQUAL : ArrayCompare.STARTSWITH
  }
  return ArrayCompare.STARTSWITH
}

export const hasNodeKeySelection = (keySelection: TreeKeySelection[], keySelectionItem: TreeKeySelection) => {
  return !!_.find(keySelection, keySelectionItem)
}

export const pushToPath = (path: TreePath, key: string) => {
  return path ? [...path, key] : [key]
}

export const popFromPath = (path: TreePath) => {
  if (path) {
    return path.length > 0 ? _.dropRight(path) : null
  }
  return null
}

export const getNode = <T>(tree: Tree<T>, nodePath: TreePath) => {
  let node: Tree<T> | null = tree
  _.forEach(nodePath, key => {
    if (!key) return undefined
    if (_.isNil(node)) return false
    const { children } = node
    if (children && children[key]) {
      node = children[key]
    } else {
      // eslint-disable-next-line no-console
      console.warn('Cannot find data tree node for path ', nodePath)
      node = null
    }
    return undefined
  })
  return node
}

export const getKeyTreeNode = (tree: KeyTree, nodePath: TreePath) => {
  let node: KeyTree | null = tree
  _.forEach(nodePath, key => {
    if (_.isNil(node)) return false
    const currentNode = _.get(node, key)
    // eslint-disable-next-line no-console
    if (_.isUndefined(currentNode)) console.warn('Cannot find key tree node for path ', nodePath)
    node = currentNode
    return undefined
  })
  return node
}

export function searchTree<T = any>(tree: DataTree<T>, expression: (node: TreeNodeData<T>) => boolean): DataTree<T>[] {
  const initAcc: DataTree<T>[] = []
  if (expression(tree)) initAcc.push(tree)

  return _.reduce(
    tree.children,
    (acc: DataTree<T>[], child) => {
      if (expression(child)) acc.push(child)
      if (child.children) return [...acc, ...searchTree(child, expression)]
      return acc
    },
    []
  )
}

export const hasAllChildrenSelected = (
  keySelection: TreeKeySelection[],
  childrenNodes: TreeNodeData<any>[],
  parentKey?: string,
  isMultiSelection?: boolean
) => {
  if (isMultiSelection === false) {
    return _.every(childrenNodes, f => _.some(keySelection, ({ key }) => key === f.key))
  }

  return (
    _.some(keySelection, ({ key }) => key === parentKey) ||
    _.every(childrenNodes, f => _.some(keySelection, ({ key }) => key === f.key)) ||
    _.some(keySelection, ({ key }) => _.includes(childrenNodes[0]?.parentPath, key))
  )
}

export const createKeyTree = <T>(
  nodes: T[],
  keySelector: (node: T) => string,
  childrenSelector: (node: T) => T[] | undefined
): KeyTree => {
  return _.reduce(
    nodes,
    (acc: KeyTree, node) => {
      const children = childrenSelector(node)
      acc[keySelector(node)] = children ? createKeyTree(children, keySelector, childrenSelector) : null
      return acc
    },
    {} as KeyTree
  )
}

export const gatherKeySelections = <T>(
  nodes: T[],
  keySelector: (node: T) => string,
  childrenSelector: (node: T) => T[] | undefined,
  parentPath: TreePath,
  treeKeySelectionsAcc: TreeKeySelection[]
) => {
  _.forEach(nodes, node => {
    const children = childrenSelector(node)
    const key = keySelector(node)
    treeKeySelectionsAcc.push({
      key: keySelector(node),
      parentPath: parentPath ? [...parentPath] : []
    })
    if (children) {
      const path = [...(parentPath || []), key]
      gatherKeySelections(children, keySelector, childrenSelector, path, treeKeySelectionsAcc)
    }
  })
}

export const createTreeKeySelections = <T>(
  nodes: T[],
  keySelector: (node: T) => string,
  childrenSelector: (node: T) => T[] | undefined
): TreeKeySelection[] => {
  const treeKeySelections: TreeKeySelection[] = []
  gatherKeySelections(nodes, keySelector, childrenSelector, null, treeKeySelections)
  return treeKeySelections
}
