import styled, { css } from 'styled-components/macro'
import { xl } from '../../utils/helpers'
import { HEADER_HEIGHT } from '../../utils/theme'

export const GAP = 28
export const OFFSET = HEADER_HEIGHT + GAP
export const WIDE_WIDTH = '410px'
export const NARROW_WIDTH = '290px'

const MODULE_ACTIONS_HEIGHT = 160
export const DRAWER_POPUP_HEIGHT_OFFSET = HEADER_HEIGHT + MODULE_ACTIONS_HEIGHT + 2 * GAP
export const MIN_POPUP_HEIGHT = 490

export const containerWidthMixin = css<{ open: boolean }>`
  && {
    width: ${NARROW_WIDTH};
  }

  ${xl`
    && {
      width: ${WIDE_WIDTH};
    }
  `}
`

export const AnimationOverflow = styled.div<{ open: boolean }>`
  overflow-x: hidden;
  transition: width ${({ theme }) => theme.animation.duration};
  width: ${({ open }) => (open ? 'auto' : '0 !important')};
  ${containerWidthMixin}
`
