import axios, { CancelToken } from 'axios'
import {
  DocumentsRequest,
  DocumentsResponse,
  DeleteFilterQueriesAsDocumentRequest,
  DeleteDocumentsRequest,
  DeleteFilterQueriesAsDocumentResponse
} from './types'

export const deleteDocuments = (data: DeleteDocumentsRequest) =>
  axios.delete<DeleteDocumentsRequest>('/api/Documents', { data })

export const deleteFilterQueriesAsDocument = (data: DeleteFilterQueriesAsDocumentRequest) => {
  const result = axios
    .delete<DeleteFilterQueriesAsDocumentResponse>('/api/Documents/filter-queries', { data })
    .then(res => res.data)
  return result
}

export const getDownloadToken = (documentId: string) =>
  axios.get<string>('/api/DownloadToken/register-token', { params: { documentId } })

export const getDocuments = (params: DocumentsRequest, cancelToken?: CancelToken) =>
  axios.get<DocumentsResponse>('/api/documents', { params, cancelToken }).then(response => response.data)

export const getDocumentsDataStreamUrl = (documentId: string, data: string) =>
  `${axios.defaults.baseURL}/api/Documents/${documentId}/data-stream/${data}`
