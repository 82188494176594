/* eslint-disable import/no-default-export */
export default {
  LogoutPrimaryText: 'Geben Sie Ihre Anmeldeinformationen ein, um auf den Dienst zuzugreifen',
  LogoutSecondaryText: 'Fügen Sie Informationen über sich selbst und Ihre Rolle im Unternehmen hinzu',
  PortfolioManagement: 'Portfolio Management',
  Targeting: 'Analyze Market',
  Prospecting: 'Prospecting',
  SalesTool: 'Sales Tool',
  API: 'API',
  Operations: 'Operatione',
  SemanticSearch: 'Gehen Sie zur semantischen Suche',
  FreeSearchPlaceholderWithCampaigns: 'Finden Sie Unternehmen, Portfolios oder Kampagnen nach Namen',
  FreeSearchPlaceholder: 'Finden Sie Unternehmen oder Portfolios nach Namen',
  SearchErrorTooLong: 'Maximale Zeichenanzahl: {{maxLimit}}',
  SearchErrorTooShort: 'Bitte geben Sie mindestens {{minLimit}} Zeichen ein',
  FeedbackArea:
    'Benachrichtigungsbereich: Hier finden Sie Ihre letzten abgeschlossenen Aktionen oder können den Fortschritt der laufenden Aktionen überprüfen',
  Profile: 'Profil',
  Notification: {
    ViewAll: 'Alle anzeigen'
  },
  Appointments: 'Termine'
}
