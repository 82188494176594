// eslint-disable-next-line max-classes-per-file
import React, { useEffect } from 'react'
import _ from 'lodash'
import { Sticky, StickyChildArgs } from '@components/Sticky'
import { HEADER_HEIGHT } from '../../utils/theme'
import { ViewportDimensions } from '../../types'
import { FullPageDialog } from '../Dialog'
import { withViewportSize } from '../App/ViewportSizeContext'
import { FullPageDrawer } from './FullPageDrawer'
import { Drawer as DrawerBase, DrawerProps } from './Drawer'
import { RightDrawer } from './RightDrawer'
import { DrawerHolder } from './DrawerHolder'
import { GAP, OFFSET } from './common'

class Drawer extends React.PureComponent<DrawerProps> {
  render() {
    return <DrawerBase {...this.props} />
  }
}

export const DrawerContext = React.createContext<{
  height: number
  top: number
  drawerElement: HTMLDivElement | null
  isSticky: boolean
}>({ height: 0, top: 0, drawerElement: null, isSticky: false })

const POINT = 148

type CommonDrawerProps = {
  buttonOffset?: number
  open: boolean
  extra?: React.ReactNode
  drawerContent: React.ReactNode
  onToggle: (isOpen: boolean) => void
}

type DrawerContainerProps = {
  fullPageDrawerTitle: string
  children: React.ReactNode
  rightOpen?: boolean
  rightDrawerContent?: React.ReactNode
  onRightOverlayClick?: () => void
} & ViewportDimensions &
  CommonDrawerProps

type LeftDrawerProps = {
  calcHeight: () => void
  setSticky: (value: boolean) => void
  top: number
  setTop: (value: number) => void
  height: number
} & CommonDrawerProps &
  Pick<StickyChildArgs, 'style' | 'isSticky' | 'distanceFromBottom'>

const LeftDrawer = ({
  calcHeight,
  setSticky,
  top,
  setTop,
  open,
  height,
  buttonOffset,
  extra,
  drawerContent,
  onToggle,
  style,
  isSticky,
  distanceFromBottom
}: LeftDrawerProps) => {
  useEffect(() => {
    if (isSticky) {
      setTop(OFFSET)
      if (distanceFromBottom && distanceFromBottom < POINT) {
        setTop(OFFSET - (POINT - distanceFromBottom))
      }
    } else {
      setTop(0)
    }
    calcHeight()
    setSticky(isSticky)
  }, [calcHeight, distanceFromBottom, isSticky, setSticky, setTop, style.top])

  return (
    <Drawer
      style={{ ..._.omit(style, 'width'), top, transform: style.transform || 'translateZ(0px)' }}
      open={open}
      height={height}
      buttonOffset={buttonOffset || 0}
      extra={extra}
      drawerContent={drawerContent}
      onToggle={onToggle}
    />
  )
}

const DrawerContainerComponent = ({
  buttonOffset,
  open,
  onToggle,
  drawerContent,
  rightDrawerContent,
  rightOpen,
  children,
  onRightOverlayClick,
  isTablet,
  fullPageDrawerTitle,
  viewportHeight,
  extra
}: DrawerContainerProps) => {
  const [height, setHeight] = React.useState(0)
  const [top, setTop] = React.useState(0)
  const [footer, setFooter] = React.useState<HTMLElement>()
  const [sticky, setSticky] = React.useState(false)

  const placeholderRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    setFooter(document.getElementById('footer')!)
  }, [])

  const calcHeight = React.useCallback(() => {
    setTimeout(() => {
      if (!placeholderRef.current || !footer) return
      const { top: footerTop } = footer.getBoundingClientRect()

      const placeholder = placeholderRef.current?.getBoundingClientRect()
      const placeholderTop = _.max([placeholder.top, HEADER_HEIGHT + GAP])!
      const h1 = footerTop - placeholderTop - 2 * GAP
      const h2 = viewportHeight - HEADER_HEIGHT - 2 * GAP
      const currentHeight = _.min([h1, h2])!
      setHeight(currentHeight)
    }, 10)
  }, [footer, viewportHeight])

  useEffect(() => {
    calcHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  let leftDrawer = null
  if (isTablet) {
    leftDrawer = (
      <FullPageDrawer
        open={open}
        fullPageDrawerTitle={fullPageDrawerTitle}
        drawerContent={drawerContent}
        onToggle={onToggle}
        extra={extra}
      />
    )
  } else {
    leftDrawer = (
      <Sticky topOffset={OFFSET} bottomOffset={GAP}>
        {({ style, isSticky, distanceFromBottom }) => {
          return (
            <div>
              <LeftDrawer
                buttonOffset={buttonOffset}
                calcHeight={calcHeight}
                drawerContent={drawerContent}
                height={height}
                onToggle={onToggle}
                open={open}
                setSticky={setSticky}
                setTop={setTop}
                extra={extra}
                style={style}
                isSticky={isSticky}
                distanceFromBottom={distanceFromBottom}
                top={top}
              />
            </div>
          )
        }}
      </Sticky>
    )
  }

  let rightDrawer = null
  if (rightDrawerContent) {
    rightDrawer = isTablet ? (
      <FullPageDialog visible={!!rightOpen}>{rightDrawerContent}</FullPageDialog>
    ) : (
      <RightDrawer
        open={!!rightOpen}
        height={height}
        drawerContent={rightDrawerContent}
        onRightOverlayClick={onRightOverlayClick}
      />
    )
  }

  return (
    // Remove eslint disable when refactoring to functional component and use useMemo
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DrawerContext.Provider value={{ height, top, drawerElement: placeholderRef.current, isSticky: sticky }}>
      <DrawerHolder
        ref={placeholderRef}
        open={open}
        leftDrawer={leftDrawer}
        rightDrawer={rightDrawer}
        onToggle={onToggle}
      >
        {children}
      </DrawerHolder>
    </DrawerContext.Provider>
  )
}

export const DrawerContainer = withViewportSize(DrawerContainerComponent)
