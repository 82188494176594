/* eslint-disable import/no-default-export */
export default {
  UserName: "Nom d'utilisateur",
  InsertUserName: "Saisir votre nom d'utilisateur",
  Email: 'E-mail',
  InsertEmail: 'Saisir votre e-mail',
  ResetPassword: 'Réinitialiser le mot de passe',
  Success: 'Mot de passe réinitialisé avec succès. Un nouveau mot de passe a été envoyé par courrier électronique.',
  Propmt:
    "Saisir le nom d'utilisateur et l'adresse e-mail auxquels le compte est associé. Un mot de passe d'accès au service sera envoyé à l'adresse électronique indiquée."
}
