import React from 'react'
import { importLazy } from '@utils/import'
import { EnrichCompaniesDialogData } from '@containers/Dialog/EnrichCompanies/types'
import { DialogType } from '@features/dialog/types'
import { EnrichPortfolioDialogData } from '@containers/Dialog/EnrichPortfolio/types'
import { CancelMonitoringDialog } from '@pages/PortfolioManagement/CancelPortfolioMonitoringDialog/CancelMonitoringDialog'

const LoadTargetingPortfolioDialog = importLazy(
  () => import('@pages/Targeting/Dialogs/LoadPortfolio/LoadPortfolioContainer')
)
const LoadTargetingQueryDialog = importLazy(() => import('@pages/Targeting/Dialogs/LoadQuery/LoadQueryDialogContainer'))
const SaveTargetingQueryDialog = importLazy(() => import('@pages/Targeting/Dialogs/SaveQuery/SaveQueryDialogContainer'))
const AddPortfolioDialog = importLazy(
  () => import('@pages/SalesTool/AssignCampaign/Dialog/AddPortfolio/AddPortfolioDialogContainer')
)
const DuplicateCompanyDialog = importLazy(
  () => import('@pages/SalesTool/AssignCampaign/Dialog/DuplicateCompany/DuplicateCompanyDialogContainer')
)
const EditCompanyNoteDialog = importLazy(
  () => import('@pages/SalesTool/AssignCampaign/Dialog/EditCompanyNote/EditCompanyNoteDialog')
)
const DeleteCompanyDialog = importLazy(
  () => import('@pages/SalesTool/AssignCampaign/Dialog/DeleteCompany/DeleteCompanyDialog')
)
const MoveCompanyDialog = importLazy(
  () => import('@pages/SalesTool/AssignCampaign/Dialog/MoveCompany/MoveCompanyDialog')
)
const EnrichPersonDialog = importLazy(() => import('@containers/Dialog/EnrichPerson'))
const AssignCompaniesDialog = importLazy(() => import('@containers/Dialog/AssignCompanies/AssignCompaniesDialog'))
const CompanyPopupDialog = importLazy(() => import('@containers/Dialog/CompanyPopupDialog'))
const ConfirmDialog = importLazy(() => import('@containers/Dialog/ConfirmDialog'))
const CreatePortfolioDialog = importLazy(() => import('@containers/Dialog/CreatePortfolio'))
const DeleteCompaniesDialog = importLazy(() => import('@containers/Dialog/DeleteCompanies/DeleteCompaniesDialog'))
const DeleteDocumentDialog = importLazy(() => import('@containers/Dialog/DeleteDocument/DeleteDocumentDialog'))
const DownloadPortfolioDialog = importLazy(() => import('@containers/Dialog/DownloadPortfolio'))
const EnrichBeforeCampaign = importLazy(() => import('@containers/Dialog/EnrichBeforeCampaign'))
const EnrichCompaniesDialog = importLazy(() => import('@containers/Dialog/EnrichCompanies/EnrichCompaniesDialog'))
const InfoDialog = importLazy(() => import('@containers/Dialog/InfoDialog'))
const LogoutCancelDialog = importLazy(() => import('@containers/Dialog/LogoutCancelDialog'))
const MoveCompaniesDialog = importLazy(() => import('@containers/Dialog/MoveCompanies/MoveCompaniesDialog'))
const PersonPopupDialog = importLazy(() => import('@containers/Dialog/PersonPopupDialog'))
const ReleaseNotesDialog = importLazy(() => import('@containers/Dialog/ReleaseNotesDialog'))
const WarningDialog = importLazy(() => import('@containers/Dialog/WarningDialog'))
const ChangePasswordDialog = importLazy(() => import('@pages/Account/AccountTab/ChangePasswordDialog'))
const ViewAddonsDialog = importLazy(() => import('@pages/Account/AccountTab/SubscriptionSettings/ViewAddonsDialog'))
const CurrencyDialog = importLazy(() => import('@pages/Account/AccountTab/Currency/CurrencyDialog'))
const ChangeLanguageDialog = importLazy(() => import('@pages/Account/AccountTab/Language/ChangeLanguageDialog'))
const GoalDetailDialog = importLazy(() => import('@pages/Account/Goal/GoalDetailDialog'))
const ResumePortfolioQueryDialog = importLazy(() => import('@pages/Account/PortfolioManagement/ResumeQueryDialog'))
const PdfReportErrorLock = importLazy(() => import('@pages/CompanyReport/Italy/PdfReportErrorLock'))
const ContactDialog = importLazy(() => import('@pages/Contact/ContactDialog'))
const ResetPasswordDialog = importLazy(() => import('@pages/Login/ResetPasswordDialog'))
const LoadOperationQueryDialog = importLazy(() => import('@pages/Operations/LoadQuery'))
const SaveOperationQueryDialog = importLazy(() => import('@pages/Operations/SaveQuery'))
const PortfolioAddNoteDialog = importLazy(() => import('@pages/PortfolioManagement/AddNoteDialog'))
const PortfolioChangeNameDialog = importLazy(() => import('@pages/PortfolioManagement/ChangeNameDialog'))
const DeletePortfolioDialog = importLazy(() => import('@pages/PortfolioManagement/DeletePortfolioDialog'))
const GenerateSimilarityDialog = importLazy(() => import('@pages/PortfolioManagement/GenerateSimilarityDialog'))
const LoadPortfolioQueryDialog = importLazy(() => import('@pages/PortfolioManagement/LoadQueryDialog'))
const PortfolioErrorLockDialog = importLazy(() => import('@pages/PortfolioManagement/PortfolioErrorLockDialog'))
const SavePortfolioQueryDialog = importLazy(() => import('@pages/PortfolioManagement/SaveQueryDialog'))
const SendToOtherModulesDialog = importLazy(() => import('@pages/Targeting/SendToOtherModulesDialog'))
const LoadProspectingQueryDialog = importLazy(() => import('@pages/Prospecting/LoadQueryDialog'))
const SaveProspectingQueryDialog = importLazy(() => import('@pages/Prospecting/SaveQueryDialog'))
const SaveBeforeContinueDialog = importLazy(() => import('@pages/Prospecting/SaveBeforeContinueDialog'))
const SendToOtherModulesDialogProspecting = importLazy(() => import('@pages/Prospecting/SendToOtherModulesDialog'))
const SendToOtherModulesDialogPortfolio = importLazy(
  () => import('@pages/PortfolioManagement/Portfolio/SendToOtherModulesDialog')
)
const UnlockPortfolioEsgDataDialog = importLazy(
  () => import('@pages/PortfolioManagement/Portfolio/UnlockPortfolioEsgDataDialog')
)
const UnlockCompanyEsgDataDialog = importLazy(() => import('@pages/CompanyReport/Shared/UnlockCompanyEsgDataDialog'))
const ChangeEndDateDialog = importLazy(() => import('@pages/SalesTool/Campaign/Dialog/ChangeEndDateDialog'))
const ChangeNameDialog = importLazy(() => import('@pages/SalesTool/Campaign/Dialog/ChangeNameDialog'))
const ChangeNoteDialog = importLazy(() => import('@pages/SalesTool/Campaign/Dialog/ChangeNoteDialog'))
const ChangeStartDateDialog = importLazy(() => import('@pages/SalesTool/Campaign/Dialog/ChangeStartDateDialog'))
const StartCampaignDialog = importLazy(() => import('@pages/SalesTool/Campaign/Dialog/StartCampaignDialog'))
const StopCampaignDialog = importLazy(() => import('@pages/SalesTool/Campaign/Dialog/StopCampaignDialog'))
const CampaignReceivedDialog = importLazy(() => import('@pages/SalesTool/Campaign/Dialog/CampaignReceivedDialog'))
const DeleteCampaignDialog = importLazy(() => import('@pages/SalesTool/CampaignArchive/Dialog/DeleteCampaign'))
const SalestoolDialog = importLazy(() => import('@pages/SalesTool/Dialog'))
const AssignTaskDialog = importLazy(() => import('@pages/SalesTool/SaleTasks/Dialog/AssignTask'))
const LoadingDialog = importLazy(() => import('../../Dialog/LoadingDialog'))
const DeleteGoalDialog = importLazy(() => import('@containers/Dialog/DeleteGoal/DeleteGoalDialog'))
const DeleteContactDialog = importLazy(() => import('@pages/Account/Contacts/DeleteContactDialog'))
const EditContactNoteDialog = importLazy(() => import('@pages/Account/Contacts/EditContactNoteDialog'))
const EditGoalDialog = importLazy(() => import('@containers/Dialog/EditGoal/EditGoalDialog'))
const DeleteProductDialog = importLazy(() => import('@containers/Dialog/DeleteProduct/DeleteProductDialog'))
const EditProductDialog = importLazy(() => import('@containers/Dialog/EditProduct/EditProductDialog'))
const DeleteTaskDialog = importLazy(() => import('@containers/Dialog/DeleteTask/DeleteTaskDialog'))
const EditTaskDialog = importLazy(() => import('@containers/Dialog/EditTask/EditTaskDialog'))
const SemanticClusterContactFormDialog = importLazy(() => import('@containers/Dialog/SemanticClusterContactFormDialog'))
const DeleteCustomTrackDialog = importLazy(() => import('@containers/Dialog/DeleteCustomTrack/DeleteCustomTrackDialog'))
const RenameCustomTrackDialog = importLazy(() => import('@containers/Dialog/RenameCustomTrack/RenameCustomTrackDialog'))
const DeleteConfigurationDialog = importLazy(
  () => import('@containers/Dialog/DeleteConfiguration/DeleteConfigurationDialog')
)
const CreateConfigurationDialog = importLazy(
  () => import('@containers/Dialog/CreateConfiguration/CreateConfigurationDialog')
)
const DeleteAppointmentDialog = importLazy(() => import('@containers/Dialog/DeleteAppointment/DeleteAppointmentDialog'))
const EnrichPortfolioDialog = importLazy(() => import('@containers/Dialog/EnrichPortfolio/EnrichPortfolioDialog'))
const SearchInformationDialog = importLazy(() => import('@containers/Dialog/SearchInformation/SearchInformationDialog'))
const ShareQueryConfirmationDialog = importLazy(() => import('@containers/ShareQuery/ShareQueryConfirmationDialog'))

type Props = {
  dialogType?: DialogType
  dialogData: any
  isLoading: boolean
  close: () => void
}

const DialogRendererComponent = ({ dialogType, close, dialogData, isLoading }: Props) => {
  if (!dialogType) return null
  switch (dialogType) {
    case 'DownloadPortfolio':
      return <DownloadPortfolioDialog close={close} downloadProposal={dialogData} />
    case 'CompanyPopup':
    case 'ReportCompanyPopup':
      return <CompanyPopupDialog close={close} data={dialogData} />
    case 'PersonPopup':
      return <PersonPopupDialog close={close} data={dialogData} />
    case 'AddPortfolioNote':
      return <PortfolioAddNoteDialog data={dialogData} />
    case 'ChangePortfolioName':
      return <PortfolioChangeNameDialog />
    case 'CreatePortfolio':
      return <CreatePortfolioDialog close={close} data={dialogData} />
    case 'DeletePortfolio':
      return <DeletePortfolioDialog />
    case 'LoadProspectingQuery':
      return <LoadProspectingQueryDialog contextData={dialogData.contextData} />
    case 'SaveProspectingQuery':
      return <SaveProspectingQueryDialog close={close} data={dialogData} />
    case 'SaveBeforeContinue':
      return <SaveBeforeContinueDialog close={close} data={dialogData} />
    case 'Contact':
      return <ContactDialog view={dialogData.state} close={close} isLoading={isLoading} />
    case 'ResetPasswordInfo':
      return <ResetPasswordDialog close={close} />
    case 'SalestoolError':
      return <SalestoolDialog close={close} isLoading={isLoading} data={dialogData} />
    case 'StartCampaign':
      return <StartCampaignDialog data={dialogData} />
    case 'StopCampaign':
      return <StopCampaignDialog data={dialogData} />
    case 'DeleteCampaign':
      return <DeleteCampaignDialog data={dialogData} close={close} />
    case 'ChangeCampaignName':
      return <ChangeNameDialog data={dialogData} />
    case 'ChangeCampaignEndDate':
      return <ChangeEndDateDialog data={dialogData} />
    case 'ChangeCampaignStartDate':
      return <ChangeStartDateDialog data={dialogData} />
    case 'ChangeCampaignNote':
    case 'AddCampaignNote':
      return <ChangeNoteDialog data={dialogData} />
    case 'CampaignReceived':
      return <CampaignReceivedDialog data={dialogData} close={close} />
    case 'AssignTasks':
      return <AssignTaskDialog close={close} data={dialogData} />
    case 'AddPortfolio':
      return <AddPortfolioDialog close={close} isLoading={isLoading} data={dialogData} />
    case 'EditCompanyNote':
      return <EditCompanyNoteDialog data={dialogData} />
    case 'DuplicateCampaignCompany':
      return <DuplicateCompanyDialog data={dialogData} close={close} />
    case 'DeleteCampaignCompany':
      return <DeleteCompanyDialog close={close} data={dialogData} />
    case 'MoveCampaignCompany':
      return <MoveCompanyDialog close={close} data={dialogData} />
    case 'GoalDetail':
      return <GoalDetailDialog close={close} goalId={dialogData.goalId} />
    case 'EnrichBeforeCampaign':
      return <EnrichBeforeCampaign close={close} data={dialogData} />
    case 'GenerateSimilarity':
      return <GenerateSimilarityDialog close={close} portfolioId={dialogData.portfolioId} />
    case 'EnrichCompanies':
      return <EnrichCompaniesDialog close={close} data={dialogData as EnrichCompaniesDialogData} />
    case 'EnrichPortfolio':
      return <EnrichPortfolioDialog close={close} data={dialogData as EnrichPortfolioDialogData} />
    case 'DeleteCompanies':
      return <DeleteCompaniesDialog close={close} data={dialogData} />
    case 'MoveCompanies':
      return <MoveCompaniesDialog close={close} data={dialogData} />
    case 'AssignCompanies':
      return <AssignCompaniesDialog close={close} data={dialogData} />
    case 'InfoDialog':
      return <InfoDialog close={close} data={dialogData} />
    case 'WarningDialog':
      return <WarningDialog close={close} data={dialogData} />
    case 'DeleteDocument':
      return <DeleteDocumentDialog close={close} data={dialogData} />
    case 'ChangePassword':
      return <ChangePasswordDialog close={close} />
    case 'ViewAddons':
      return <ViewAddonsDialog close={close} />
    case 'SaveTargetingQuery':
      return <SaveTargetingQueryDialog close={close} data={dialogData} />
    case 'LoadTargetingQuery':
      return <LoadTargetingQueryDialog close={close} data={dialogData} />
    case 'LoadTargetingPortfolio':
      return <LoadTargetingPortfolioDialog close={close} data={dialogData} />
    case 'ChangeLanguage':
      return <ChangeLanguageDialog close={close} />
    case 'ChangeCurrency':
      return <CurrencyDialog close={close} />
    case 'LoadingDialog':
      return <LoadingDialog data={dialogData} close={close} />
    case 'LogoutCancel':
      return <LogoutCancelDialog close={close} />
    case 'PortfolioErrorLock':
      return <PortfolioErrorLockDialog close={close} />
    case 'PdfErrorLock':
      return <PdfReportErrorLock close={close} data={dialogData} />
    case 'EnrichPerson':
      return <EnrichPersonDialog close={close} data={dialogData} />
    case 'ConfirmDialog':
      return <ConfirmDialog close={close} data={dialogData} />
    case 'ReleaseNotes':
      return <ReleaseNotesDialog close={close} data={dialogData} />
    case 'SavePortfolioQuery':
      return <SavePortfolioQueryDialog close={close} data={dialogData} />
    case 'LoadPortfolioQuery':
      return <LoadPortfolioQueryDialog close={close} data={dialogData} />
    case 'SaveOperationQuery':
      return <SaveOperationQueryDialog close={close} data={dialogData} />
    case 'LoadOperationQuery':
      return <LoadOperationQueryDialog close={close} data={dialogData} />
    case 'ResumePortfolioQuery':
      return <ResumePortfolioQueryDialog close={close} data={dialogData} />
    case 'CancelPortfolioMonitoring':
      return <CancelMonitoringDialog close={close} data={dialogData} />
    case 'EditGoal':
      return <EditGoalDialog close={close} data={dialogData} />
    case 'DeleteGoal':
      return <DeleteGoalDialog close={close} data={dialogData} />
    case 'DeleteContact':
      return <DeleteContactDialog close={close} data={dialogData} />
    case 'EditContactNote':
      return <EditContactNoteDialog close={close} data={dialogData} />
    case 'EditProduct':
      return <EditProductDialog close={close} data={dialogData} />
    case 'DeleteProduct':
      return <DeleteProductDialog close={close} data={dialogData} />
    case 'EditTask':
      return <EditTaskDialog close={close} data={dialogData} />
    case 'DeleteTask':
      return <DeleteTaskDialog close={close} data={dialogData} />
    case 'SemanticClusterContactForm':
      return <SemanticClusterContactFormDialog close={close} data={dialogData} />
    case 'DeleteCustomTrack':
      return <DeleteCustomTrackDialog close={close} data={dialogData} />
    case 'RenameCustomTrack':
      return <RenameCustomTrackDialog close={close} data={dialogData} />
    case 'DeleteConfiguration':
      return <DeleteConfigurationDialog close={close} data={dialogData} />
    case 'CreateConfiguration':
      return <CreateConfigurationDialog close={close} />
    case 'DeleteAppointment':
      return <DeleteAppointmentDialog close={close} data={dialogData} />
    case 'SearchInformation':
      return <SearchInformationDialog close={close} />
    case 'SendToOtherModulesDialogTargeting':
      return <SendToOtherModulesDialog close={close} data={dialogData} />
    case 'SendToOtherModulesDialogProspecting':
      return <SendToOtherModulesDialogProspecting close={close} data={dialogData} />
    case 'SendToOtherModulesDialogPortfolio':
      return <SendToOtherModulesDialogPortfolio close={close} data={dialogData} />
    case 'UnlockPortfolioEsgDataDialog':
      return <UnlockPortfolioEsgDataDialog close={close} data={dialogData} />
    case 'UnlockCompanyEsgDataDialog':
      return <UnlockCompanyEsgDataDialog close={close} data={dialogData} />
    case 'ShareQueryConfirmationDialog':
      return <ShareQueryConfirmationDialog close={close} data={dialogData} />
    default:
      return null
  }
}

// eslint-disable-next-line import/no-default-export
export default DialogRendererComponent
