/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  wizard: {
    StartAnalysis: 'Pre začatie analýzy musíte zvoliť typ analýzy, ktorú chcete spracovať',
    TypeOfAnalysis: 'Typ analýzy',
    FromTargeting: 'Načítať dopyt na Analýzu trhu',
    FromTargetingTooltip: 'Otvorte dopyt uložený z Analýzy trhu',
    FromProspecting: 'Načítať dopyt z Výberu klientov',
    FromProspectingTooltip: 'Otvorte dopyt uložený z Výberu klientov',
    NewResearch: 'Spustiť nové vyhľadávanie',
    NewResearchTooltip: 'Hľadajte potenciálnych zákazníkov prostredníctvom všetkých spoločností v databáze Margo',
    SelectOne: 'Vybrať dopyt',
    SavedQuery: 'Uložený dopyt',
    LoadQuery: 'Načítať dopyt',
    LoadQueryTooltip: 'Otvorte uložený dopyt',
    PortfolioQuery: 'Načítajte dopyt zo správy portfólia',
    TargetingQuery: 'Načítať dopyt na Analýzu trhu',
    ProspectingQuery: 'Načítať dopyt z Výberu klientov',
    ChooseQuery: 'Vyberte, ktorý dopyt chcete načítať',
    YouCanResumeResearch: 'Môžete pokračovať vo vyhľadávaní, ktoré ste už začali.'
  },
  Prospecting: 'Výber klientov',
  Actions: {
    LoadQuery: 'Načítať Dopyt',
    LoadQueryTooltip: 'Otvorte uložený dopyt',
    SaveQuery: 'Uložiť Dopyt',
    SaveQueryTooltip: 'Uložte tento dopyt do Výberu klientov',
    Download: 'Stiahnuť Excel',
    DownloadTooltip: 'Stiahnite si obohatený zoznam spoločností do svojho zariadenia',
    SaveList: 'Uložit zoznam',
    SaveListTooltip: 'Uložte zoznam týchto spoločností do portfólia',
    SaveAndMonitor: 'Uložiť a Sledovať Zoznam',
    SaveAndMonitorTooltip:
      'Uložte zoznam týchto spoločností do portfólia, ktoré sa dynamicky aktualizuje, keď nové spoločnosti odpovedajú zvoleným parametrom. Pridané spoločnosti budú automaticky obohatené.',
    SendToSales: 'Poslať zoznam do Obchodných aktivít',
    SendToSalesTooltip: 'Priraďte spoločnosti k predajnému tímu a naplánujte komerčnú kampaň',
    SendToOtherModules: 'Poslať do iných modulov',
    SendToOtherModulesTooltip: 'Pošlite spoločnosti do modulu Obchodné aktivity alebo Analýza trhu'
  },
  Indexes: {
    Penetration: 'Index penetrácie',
    PenetrationTooltip:
      'Meria % spoločností v portfóliu v porovnaní s tými, ktoré generuje Podobnosť. Vyššia hodnota znamená lepšiu penetráciu na potenciálnom trhu.',
    Opportunity: 'Index príležitostí',
    OpportunityTooltip:
      'Meria % spoločností generovaných podobnosťou, ktoré nie sú zahrnuté v portfóliu. Vyššia hodnota znamená väčší počet potenciálnych klientov.'
  },
  CompaniesInList_0: 'Spoločnosť na zozname',
  CompaniesInList_1: 'Spoločnosti na zozname',
  CompaniesInList_2: 'Spoločností na zozname',
  Search: {
    CompanySearch: 'Vyhľadávanie spoločností',
    CompanySearchPlaceholder: 'Nájdite spoločnosti podľa',
    CompanySearchFields: {
      TaxNumber: 'IČO',
      VatNumber: 'IČ DPH',
      ONRNumber: 'ONR',
      CompanyName: 'Názvu spoločnosti',
      CrifNumber: 'Crif čísla',
      CompanyIdentification: 'IČO'
    },
    SemanticSearch: 'Sémantické vyhľadávanie',
    SearchPlaceholder: 'Vyhľadávajte podľa kľúčových slov',
    All: 'Všetko',
    OfficialData: 'Oficiálne dáta',
    Website: 'Web a sociálne siete',
    Synonyms: 'Synonymá',
    Advanced: 'Rozšírené sémantické vyhľadávanie',
    AdvancedSwitch: 'Rozšírené',
    AdvancedTooltip:
      'S pokročilým sémantickým vyhľadávaním môžete používať viaceré slová oddelené pomocou (AND, OR, NOT). Pre zapnutie funkcie prepnite tlačidlo na ON. Stiahnuť dokument pre viacej informácií.',
    DownloadSsGuide: 'Stiahnuť sprievodcu sémantickým vyhľadávaním'
  },
  EmptySearch: {
    History: {
      Title: 'Bez filtrov',
      Subtitle: 'Tu sa zobrazí ako zmena filtra ovplyvní vyhľadávanie'
    },
    Banner: {
      Title: 'Začnite vyhľadávanie použitím oblúbených filtrov',
      Subtitle:
        'Pred začiakom vyhľadávania sa uistite, že ste vybrali najvhodnejšie filtre. Platforma vygeneruje zoznam ideálnych prospektov na základe zvolených filtrov.'
    }
  },
  SaveBeforeContinue: {
    DialogTitle: 'Uložiť vyhľadávanie',
    Question: 'Chcete pred pokračovaním uložiť svoje vyhľadávanie?',
    Description: 'Pred pokračovaním uložte svoje vyhľadávanie, aby ste predišli strate údajov.',
    OkBtn: 'Uložiť vyhľadávanie',
    CancelBtn: 'Preskočiť uloženie'
  },
  DomainFilter: {
    SaveAndClose: 'Uložiť filtre & zavrieť'
  },
  LoadMore: 'Načítať viac',
  UnavailableForMobile: 'Stránka, na ktorú sa pokúšate dostať, je k dispozícii iba pre tablety a počítače.',
  SendToOtherModulesDialog: {
    Title: 'Vyberte modul',
    SubTitle: 'Vyberte modul, do ktorého chcete odoslať analýzu alebo zoznam',
    Targeting: 'Analýza trhu',
    TargetingDescription:
      'Kliknutím sem odošlete zoznam identifikovaných spoločností do modulu Analýza trhu, kde si môžete pozrieť aktualizované grafy, ktoré poskytujú reprezentáciu rozloženia spoločností vzhľadom na hlavné premenné.',
    SalesTool: 'Obchodné aktivity',
    SalesToolDescription:
      'Kliknutím sem odošlete zoznam identifikovaných spoločností do modulu Obchodné aktivity. Zoznam sa uloží do portfólia a údaje spoločností sa obohatia. V rámci modulu Obchodné aktivity môžete spustiť predajnú kampaň na vybraných spoločnostiach a priradiť ju svojim predajcom.',
    OkButton: 'Odoslať'
  }
}
