/* eslint-disable import/no-default-export */
export default {
  MainTitle: 'Názov spoločnosti',
  Actions: 'Akcie',
  DownloadPDF: 'Stiahnuť PDF',
  AddToCampaign: 'Pridaj do kampane',
  CompanyData: {
    CompanyName: 'Názov spoločnosti',
    Title: 'Náhľad',
    CompanyId: 'IČO',
    Town: 'Mesto',
    OfficeClassification: 'Typ pobočky',
    Ateco2007: 'Primárne Ateco (2007)',
    NaceCode: 'Prevažujúca činnosť (NACE)',
    LegalForm: 'Právna forma',
    LastUpdate: 'Posledná aktualizácia',
    TaxCode: 'DIČ',
    VatCode: 'IČ DPH',
    ZipCity: 'PSČ, Mesto',
    MainIndustrialSectors: 'Hlavné priemyselné odvetvia'
  },
  CompanySummary: {
    Evaluation: 'Hodnotenie',
    Profile: 'Profil',
    AffidabilityScore: 'Skóre dôveryhodnosti',
    CompanyGroup: 'Skupina spoločností',
    PublicContracts: 'Verejné obstarávania',
    LocalUnits: 'Pobočky',
    CompanyStatus: 'Status spoločnosti',
    Title: 'Prehľad spoločnosti',
    Address: 'Adresa',
    Town: 'Mesto',
    State: 'Štát',
    TaxCode: 'IČO',
    VatCode: 'IČ DPH',
    CrifNumber: 'CRIF Number',
    CCIAAREA: 'CCIAA / REA',
    TotalNumberBranches: 'Počet pobočiek',
    TotalNumberLocalUnit: 'Počet pobočiek',
    DetailedLegalForm: 'Detailná právna forma',
    Ateco2007: 'Primárny Ateco (2007)',
    SIC: 'SIC',
    NACE: 'NACE',
    StartDate: 'Dátum začiatku',
    IncorporationDate: 'Dátum založenia',
    RegistrationCamComDate: 'Dátum registrácie v ochodnom registri',
    HoldingCompanyName: 'Názov holdingovej spoločnosti',
    NationalParentCompany: 'Národná materská spoločnosť',
    TenderWon3y: 'Vyhrané verejné súťaže (posledné 3 roky)',
    TenderParticipated3y: 'Účasť na verejných súťažiach (posledné 3 roky)',
    TenderValueWon3y: 'Hodnota vyhraných verejných súťaží (posledné 3 roky)',
    TypeOffice: 'Typ pobočky',
    CountOffice: 'Počet pobočiek podľa typu',
    Turnover: 'Obrat',
    GroupTurnover: 'Skupinový obrat',
    Estimated: 'Odhadovaný',
    Profit: 'ZISK',
    Employees: 'Zamestnanci',
    Mol: 'MOL',
    DetectionDate: 'Dátum súvahy',
    Ebitda: 'EBITDA',
    Ebit: 'EBIT',
    GroupVatCode: 'IČ DPH skupiny',
    MainIndustrialSectors: 'Hlavné priemyselné odvetvia',
    SecondaryIndustrialSectors: 'Sekundárne priemyselné odvetvia',
    StartOfBusiness: 'Začiatok podnikania',
    DateOfFoundation: 'Datum založenia',
    TotalNumberOfVehicles: 'Celkový počet vozidiel',
    NumberOfPassengerCars: 'Počet osobných automobilov',
    CarpoolNumberOfLorries: 'Vozový park - počet nákladných vozidiel',
    tlcFix: 'Výdavky na TLC (telefón) (€)',
    tlcMob: 'Výdavky na TLC (mobilný telefón) (€)',
    tlcFixTrend: 'Trend výdavkov na TLC (telefón) (%)',
    tlcMobTrend: 'Trend výdavkov na TLC (mobilný telefón) (%)',
    BankInformation: 'Bankové spojenie',
    ImportExport: 'Import/Export',
    Grants: 'Granty',
    Vehicles: 'Vozidlá',
    ratingScore: 'Rating skóre',
    Entrepreneurship: 'Podnikanie',
    SoaCertification: 'Certifikácia SOA'
  },
  FinancialStatement: {
    Title: 'Finančný výkaz',
    Subtitle: 'Hlavné rozpočtové ukazovatele za posledné 3 roky',
    Text: 'Kompletné detaily, súvahové položky sú k dispozícii po stiahnutí kompletného zoznamu.',
    ProfitAndLossStatement: 'Položky z výkazu ziskov a strát (výkonnosť)',
    BalanceSheet: 'Položky zo súvahy (finančná stabilita)',
    RentabilityIndicators: 'Ukazovatele rentability',
    EconomicData: 'Ekonomické údaje',
    FinancialData: 'Finančné údaje',
    FinancialRatios: 'Rozpočtové ukazovatele',
    ProductionValue: 'Hodnota výroby',
    ProductionCosts: 'Náklady výroby',
    BalanceOfRevenues: 'Rovnováha výnosov a finančných poplatkov',
    ProfitLoss: 'Zisk / Strata',
    CashFlow: 'Cash Flow',
    TotalAssets: 'Celkové aktíva',
    FixedAssets: 'Fixné aktíva',
    TangAssets: 'Hmotný majetok',
    CurrentAssets: 'Obežný majetok',
    TotalLiabilities: 'Pasíva celkom',
    Liabilities: 'Pasíva',
    ShareholdersEquity: 'Vlastné imanie akcionárov',
    Provisions: 'Rezervy',
    DebtRatio: 'Ukazovateľ zadĺženosti',
    PFN: 'PFN',
    TotalCredits: 'Pohľadávky celkom',
    TotalDebts: 'Záväzky celkom',
    NetWorth: 'Čistá hodnota',
    EBITDA: 'EBITDA',
    ROE: 'ROE',
    ROI: 'ROI',
    ROS: 'ROS',
    TotalEmployees: 'Celkový počet zamestnancov',
    Turnover: 'Obrat',
    Profit: 'Zisk',
    RegisterCapital: 'Základný kapitál',
    BalanceSheetRatios: 'Súvahový koeficient',
    Sales: 'Predaj',
    Cost: 'Náklady',
    FinancialAssets: 'Finančné aktíva',
    BalanceSheetProfitLost: 'Zisk / strata zo súvahy (+/-)',
    Equity: 'Základné imanie',
    LiquidityRatio: 'Pomer likvidity',
    ReturnOnTotalCapital: 'Návratnosť celkového kapitálu',
    BalanceSheetRatio: 'Súvahový koeficient',
    TurnoverAndTurnoverTrend: 'Obrat a trend obratu',
    EmployeeEmployeetrend: 'Zamestnanci a trend zamestnancov',
    RequestBalance: 'Vyžiadať zostatok'
  },
  People: {
    Title: 'Ľudia',
    From: 'Od',
    Amount: 'Čiastka',
    Exponents: 'Exponenti',
    Shareholders: 'Akcionári',
    Partners: 'Pridružená spoločnosť',
    SupervisoryBoard: 'Kontrolné a štatutárne orgány',
    Owners: 'Vlastníci',
    OwnershipInterests: 'Majetkové účasti v spoločnostiach',
    Years: 'rokov',
    OfficialSourceData: 'Údaje z oficiálneho zdroja',
    UnofficialSourceData: 'Údaje z neoficiálneho zdroja',
    DateOfBirth: 'Dátum narodenia',
    TypeOfConnection: 'Typ väzby',
    GoToLinkedin: 'Prejsť na LinkedIn',
    Visualize: {
      PersonData: 'Zobraziť osobné dáta',
      CompanyData: 'Zobraziť dáta spoločnosti'
    }
  },
  ContactsAndSocial: {
    Title: 'Kontakty a sociálne siete',
    Social: 'Sociálne siete',
    RepresentativeCompany: 'Predstaviteľ spoločnosti',
    CertifiedMail: 'Certifikovaný e-mail',
    PublicEmail: 'Verejný e-mail',
    Website: 'Website URL',
    Fax: 'Fax',
    Telephone: 'Telefón',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Youtube: 'Youtube',
    Linkedin: 'LinkedIn',
    Pinterest: 'Pinterest',
    Vimeo: 'Vimeo',
    LoadMore: 'Načítať viac',
    ManagerContacts: 'Kontakty na manažérov'
  },
  Analytics: {
    Title: 'Analytika',
    Seasonalit: 'Skóre sezónnosti',
    Propensity: 'Skóre tendencie',
    AnalyticsMarketing: 'Analytika Marketing',
    AnalyticsFinance: 'Analytika Financie',
    AnalyticsMarketingMobile: 'Marketing ',
    AnalyticsFinanceMobile: 'Financie',
    SeasonalitWinter: 'Zima',
    SeasonalitSpring: 'Jar',
    SeasonalitSummer: 'Leto',
    SeasonalitAutumn: 'Jeseň',
    PropensityShortTerm: 'Krátkodobý',
    PropensityMiddTerm: 'Strednodobý',
    PropensityCarLeas: 'Prenájom automobilu',
    PropensityInstrLeas: 'Prenájom vybavenia',
    PropensityRealEstateLeas: 'Prenájom nehnuteľností',
    PropensityFactoring: 'Faktoring',
    Internazionalization: 'Skóre internacionalizácie',
    Social: 'Sociálne skóre',
    DigitalAttitude: 'Skóre digitalizácie',
    Innovation: 'Skóre inovácie',
    Supplier: 'Skóre dodávateľa',
    EstimatedTurnover: 'Odhadovaný obrat',
    AnalyticsMarketingTooltip: 'Analytika Marketing',
    AnalyticsFinanceTooltip: 'Analytika Financie',
    AnalyticsMarketingMobileTooltip: 'Marketing ',
    AnalyticsFinanceMobileTooltip: 'Financie',
    SeasonalitTooltip:
      'Ukazuje ročné obdobie, v ktorom je pravdepodobné, že sa spoločnosti zaviažu ku krátkodobému úveru. Pomáha identifikovať prospekty alebo klientov a plánovať obchodné kampane na určité obdobia.',
    PropensityTooltip:
      'Ukazuje tendenciu spoločnosti vziať si úverový produkt: krátkodobý úver, dlhodobý úver, faktoring, leasing (auto, vybavenie a stroje, nehnuteľnosti). Skóre sklonu každého produktu je zoradené v 3 úrovniach. (1 = vysoká, 2 = stredná, 3 = nízka)',
    InternazionalizationTooltip:
      'Ukazuje tendenciu spoločnosti vykonávať medzinárodný obchod.\nSkóre sa pohybuje od 1 (vysoká tendencia) do 5 (nízka tendencia)',
    SocialTooltip: 'Skóre sociálne',
    DigitalAttitudeTooltip:
      'Odhaduje digitálnu spriaznenosť spoločnosti, sklon k prijímaniu digitálnych procesov (napr. digitálny prístup) a schopnosť reagovať na digitálne marketingové kampane.\nSkóre sa pohybuje od 1 (veľký sklon) do 5 (nízky sklon) spriaznenosť',
    InnovationTooltip:
      'Odhaduje úroveň inovácie spoločnosti, ktorá koreluje s jej rastom.\nSkóre sa pohybuje od 1 (vysoká inovácia) do 5 (nízka inovácia)',
    SupplierTooltip: 'Skóre dodávateľa',
    EstimatedTurnoverTooltip:
      'Pre spoločnosti, ktoré nemajú účtovnú závierku, bol vypracovaný model odhadu, ktorý je schopný rekonštruovať najnovší obrat.',
    TelcoFixScore: 'Skóre spotreby pre telefón',
    TelcoMobScore: 'Skóre spotreby pre mobilný telefón'
  },
  Mastercard: {
    Title: 'Maloobchod',
    Business: 'Obchod',
    BusinessTooltip:
      'Ukazuje obrat spoločnosti na základe 12-mesačného trendu. Skóre sa pohybuje od 1 (vysoký) do 3 (nízky).',
    Competitiveness: 'Konkurencieschopnosť',
    CompetitivenessTooltip:
      'Ukazuje potenciál spoločnosti na základe konkurenčného prostredia, v ktorom pôsobí. Ak je vysoká, znamená to, že patrí medzi najlepšie vo svojom priemysle a geografickej oblasti. Skóre sa pohybuje od 1 (vysoká) do 3 (nízká).',
    Digitalization: 'Digitalizácia',
    DigitalizationTooltip:
      'Ukazuje úroveň rozvoja digitálnych kanálov spoločnosti. Skóre sa pohybuje od 1 (vysoká) do 3 (nízká).',
    Premium: 'Premium',
    PremiumTooltip:
      'Ukazuje vplyv zákazníkov, ktorí vynakladajú vysoké výdavky v rámci spoločnosti, v porovnaní s priemerom v danom odvetví. Ak je táto hodnota vysoká, znamená to, že táto spoločnosť priťahuje transakcie nad priemerom v porovnaní s partnerskými spoločnosťami. Skóre sa pohybuje od 1 (vysoké) do 3 (nízke).',
    CommercialPotential: 'Obchodný potenciál',
    CommercialPotentialTooltip:
      'Ukazuje obchodný potenciál geografickej oblasti v porovnaní s priemermi v odvetví. Skóre sa pohybuje od 1 (vysoký) do 3 (nízký).',
    CompetitionLevel: 'Úroveň konkurencie',
    CompetitionLevelTooltip:
      'Ukazuje koncentráciu spoločností v rovnakom odvetví v geografickej oblasti, kde spoločnosť pôsobí. Skóre sa pohybuje od 1 (vysoké) do 3 (nízke).',
    Performance: 'Výkon',
    Restart: 'Reštart'
  },
  Portfolio: {
    Title: 'Portfólio',
    NumberOfCompanies: 'Počet spoločností',
    CreationDate: 'Dátum vytvorenia',
    LastUpdateDate: 'Dátum poslednej aktualizácie',
    AddToPortfolio: 'Pridať do portfólia',
    SaveCompany: 'Uložiť spoločnosť',
    SaveOrCreate: 'Chcete pridať spoločnosť do portfólia alebo vytvoriť nové?',
    SaveCompanyDesc: 'Spoločnosť sa automaticky uloží so statusom "Prospekt" v časti "Správa portfólia"'
  },
  SalesTransaction: {
    Title: 'Spätná väzba o pridelených osobách',
    Name: 'Názov',
    Product: 'Produkt',
    Assignee: 'Pridelená osoba',
    Task: 'Úloha',
    startDate: 'Dátum začiatku',
    EndDate: 'Dátum ukončenia',
    Notes: 'Poznámky'
  },
  UnlockView: {
    Title: 'Ak chcete odomknúť túto časť, zakúpte si údaje spoločnosti {{companyName}}',
    MissingPortfoliosTitle: 'Tu nájdete všetky portfóliá, v ktorých je spoločnosť prítomná',
    MissingSalesTransactionsTitle: 'Tu nájdete všetky kampane, v ktorých je spoločnosť prítomná',
    Subtitle: 'Túto sekciu môžete odomknúť zakúpením údajov spoločnosti {{companyName}}',
    Button: 'Odomknúť údaje spoločnosti {{companyName}}',
    Tooltip: 'Prosím, uveďte informačnú správu ...'
  },
  ModalWindow: {
    Notes: 'Poznámky',
    Title: 'Môžete pridať až päť poznámok k tejto spoločnosti',
    InsertNote: 'Vložte novú poznámku (max. 250 znakov)',
    NewNote: 'Novú poznámku napíšte sem',
    Add: 'Pridať',
    AreYouSure: 'Naozaj chcete vymazať túto poznámku?',
    Yes: 'Áno',
    No: 'Nie'
  },
  CompanyProfile: 'Profil spoločnosti',
  CompanyProfileMobile: 'Spoločnosť',
  FinancialStatementSummary: 'Zhrnutie účtovnej závierky',
  FinancialStatementMobile: 'Finančný',
  Contacts: {
    Title: 'Kontakty'
  },
  News: 'Novinky',
  Close: 'Zavrieť',
  LocalUnits: {
    Title: 'Miestne jednotky',
    StartDate: 'Dátum začiatku'
  },
  Score: {
    Title: 'Vlastný index',
    Tabs: {
      Index: 'Vlastný index',
      Relevant: 'Relevantné udalosti',
      Telco: 'Analytika Telco',
      Analytics: 'Analytické nástroje'
    }
  },
  Pnrr: {
    Title: 'PNRR',
    Headers: {
      Score: 'PNRR Score Investment',
      Mission: 'Mission',
      Component: 'Component',
      Scope: 'Scope',
      Investment: 'Investment'
    }
  },
  CustomIndexes: {
    Title: 'Vlastný index',
    Tabs: {
      Index: 'Vlastný index',
      Warnings: 'Upozornenia'
    },
    warningCzSkDescription:
      'Farba varovnej informácie indikuje jej závažnosť: menej závažné negatívne informácie sú zobrazené žltou, vysoko závažné negatívne informácie červenou.',
    cribisIndexTooltip:
      'Vyjadruje stabilitu subjektu vypočítanú na základe finančných a/alebo nefinančných kritérií. Hodnotenie dosahuje hodnoty v rozsahu a1 (výnimočne stabilný subjekt) až e (veľmi nestabilný subjekt alebo subjekt v insolvencii).'
  },
  CompanyPopup: {
    Title: 'Systémová správa',
    CompanyNotFound: 'Pre vybranú spoločnosť nebolo možné obnoviť žiadne údaje'
  },
  PersonPopup: {
    Title: 'Systémová správa',
    Desc: 'Pre vybranú osobu nebolo možné obnoviť žiadne údaje'
  },
  PdfPopup: {
    Title: 'Potvrdenie operácie',
    TextEnriched:
      'Hneď ako vytvoríte súbor PDF, dostanete notifikáciu a budete si ho môcť stiahnuť a uložiť. Pokračovať v operácii?',
    TextNotEnriched:
      'Pre stiahnutie PDF je potrebné najskôr obohatiť vybranú spoločnosť. Proces môže trvať niekoľko minút. Pokračujte potvrdením.'
  },
  Appointments: {
    Title: 'Schôdzky'
  }
}
