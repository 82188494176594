import _ from 'lodash'

export const EMPTY_CLAIM = 0

export enum Claim {
  PortfolioManagement = 1 << 0,
  Targeting = 1 << 1,
  Prospecting = 1 << 2,
  SalesTool = 1 << 3,
  Operations = 1 << 5,
  Pnrr = 1 << 6
}

export type ClaimKey = keyof typeof Claim

export const claimKeys = _.keys(Claim) as ClaimKey[]

export enum UserType {
  Sales = 1 << 0,
  AreaManager = 1 << 1,
  Supervisor = 1 << 2,
  All = 0b111
}

export type UserTypeKey = keyof typeof UserType
