export type AnalyticsState = {
  visitorId?: string
}

export enum EventCategory {
  Prospecting = 'Prospecting',
  PortfolioManagement = 'PortfolioManagement',
  PortfolioDetail = 'PortfolioDetail',
  SalesTool = 'SalesTool',
  Targeting = 'Targeting',
  Operations = 'Operations',
  Search = 'Search',
  Pnrr = 'Pnrr',
  Appointments = 'Appointments',
  Unknown = 'Unknown'
}

export enum EventAction {
  Search = 'Search',
  SemanticSearch = 'SemanticSearch',
  SemanticSearchAdvanced = 'SemanticSearchAdvanced',
  GroupAction = 'GroupAction',
  Filtering = 'Filtering'
}

export enum GroupActionType {
  Enrichment = 'GroupActionEnrichment',
  DeleteCompany = 'GroupActionDeleteCompany',
  SavePortfolio = 'GroupActionSavePortfolio',
  DownloadExcel = 'GroupActionDownloadExcel',
  AddLocalUnits = 'GroupActionAddLocalUnits',
  SaveList = 'GroupActionSaveList',
  AssignAreaManager = 'GroupActionAssignAreaManager',
  AssignSales = 'GroupActionAssignSales',
  AssignProduct = 'GroupActionAssignProducts',
  MoveCompany = 'GroupActionMoveCompany'
}

export enum CallToActionType {
  CreateCampaign = 'CreateCampaign',
  ManageCampaign = 'ManageCampaign',
  SharePortfolio = 'SharePortfolio',
  ExcludePortfolio = 'ExcludePortfolio',
  CreateOffer = 'CreateOffer',
  ContactCustomerOnline = 'ContactCustomerOnline',
  DownloadExcel = 'DownloadExcel',
  DownloadPdf = 'DownloadPdf',
  DownloadFullPdf = 'DownloadFullPdf',
  AddLocalUnits = 'AddLocalUnits',
  SaveList = 'SaveList',
  SaveAndMonitorList = 'SaveAndMonitorList',
  MoveCompany = 'MoveCompany',
  DeleteCompany = 'DeleteCompany',
  GenerateSimilarity = 'GenerateSimilarity',
  GenerateSimilarityWithSemanticCluster = 'GenerateSimilarityWithSemanticCluster',
  AnalyzePortfolio = 'AnalyzePortfolio',
  SemanticClusterLabelPrefill = 'SemanticClusterLabelPrefill',
  SemanticClusterSelection = 'SemanticClusterSelection',
  StandardUpload = 'StandardUpload',
  SimplifiedUpload = 'SimplifiedUpload',
  UpdatePortfolio = 'UpdatePortfolio',

  // PNRR
  PnrrWizard = 'PnrrWizard',
  EasyFinanceSendPortfolio = 'EasyFinanceSendPortfolio',

  // Appointments
  CreateAppointment = 'CreateAppointment',
  EditAppointment = 'EditAppointment',

  // Query
  ShareQuery = 'ShareQuery'
}
