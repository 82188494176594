export enum DocumentType {
  ExportedPortfolio = 'ExportedPortfolio',
  SalesToolCampaign = 'SalesToolCampaign',
  CompanyReport = 'CompanyReport',
  MultipleDocument = 'MultipleDocument',
  TargetingExportedAnalysis = 'TargetingExportedAnalysis',
  CompanyReportPdf = 'CompanyReportPdf',
  Queries = 'Queries',
  SurveyAttachment = 'SurveyAttachment',

  TargetingQueries = 'TargetingQueries',
  ProspectingQueries = 'ProspectingQueries',
  PortfolioQueries = 'PortfolioQueries',
  MonitoredQueries = 'MonitoredQueries',
  OperationQueries = 'OperationQueries',
  CustomTracks = 'CustomTracks' // this values is used only for active tabs, not used by BE. It could be nice to create new enum "Tabs..."
}

export type Document = {
  id: string
  userId: string
  name: string
  type: DocumentType
  createdAt: string
  expirationDate: string
  extension: string
  associatedEntityId?: string
  isMonitored?: boolean
}
