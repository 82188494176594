import _ from 'lodash'
import {
  Col as BootstrapCol,
  ColProps as BootstrapColProps,
  Container,
  Row as BootstrapRow,
  RowProps as BootstrapRowProps
} from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components/macro'
import { blurred } from '@pages/CompanyReport/Shared/Freemium/components'
import { WithBlurOption } from '@types'
import { Dimension, parseDimension } from '../../utils/dimension'
import { TwoColumnFullPageLayout } from './TwoColumnFullPageLayout'

type LayoutProps = {
  modal?: boolean
  ignoreFixedWidth?: boolean
}

export const Layout = styled(Container)<LayoutProps>`
  max-width: none !important;
  width: auto;

  position: relative; /* for aligning page background image */
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1921px) {
    margin: 0 auto;
    ${({ ignoreFixedWidth }) => (ignoreFixedWidth ? '' : 'width: 1760px;')}
  }

  @media only screen and (max-width: 1920px) {
    margin: 0 80px;
  }

  @media only screen and (max-width: 1360px) {
    margin: 0 40px;
  }

  @media only screen and (max-width: 767px) {
    margin: 0 16px;
  }

  /* Override GlobalStyle #root > Layout which is has fixed margin */
  ${({ modal }) =>
    modal &&
    css`
      margin-top: 0 !important;
    `}
`

const parseGap = (gap: Dimension) => {
  if (_.isNumber(gap)) {
    return `${gap / 2}px`
  }
  const [, gapString, unit] = gap.match(/^(\d+)(.*)$/) || ['', '0', 'px']
  const pad = _.parseInt(gapString) / 2
  return `${pad}${unit}`
}

type ColProps = {
  marginTop?: Dimension
  marginBottom?: Dimension
  paddingLeft?: Dimension
} & BootstrapColProps

export const Col = styled(BootstrapCol)<ColProps & WithBlurOption>`
  ${blurred}

  ${({ marginTop }) => (_.isUndefined(marginTop) ? '' : `margin-top: ${parseDimension(marginTop)};`)}
  ${({ marginBottom }) => (_.isUndefined(marginBottom) ? '' : `margin-bottom: ${parseDimension(marginBottom)};`)}
  ${({ paddingLeft }) => (_.isUndefined(paddingLeft) ? '' : `padding-left: ${parseDimension(paddingLeft)};`)}
`

type RowProps = {
  gap?: Dimension
  padding?: string
} & BootstrapRowProps

export const Row = styled(BootstrapRow)<RowProps>`
  ${({ padding }) => (padding ? `padding: ${padding}` : '')};

  ${({ gap }) => {
    if (!gap) return ''
    const margin = parseGap(gap)
    return `
      margin-top: -${margin};
      margin-bottom: -${margin};
      > ${Col} {
        margin-top: ${margin};
        margin-bottom: ${margin};
      }
    `
  }}
`

export const CenteredRow = styled(Row)`
  text-align: center;
  justify-content: center;

  &.buttons {
    margin-top: 40px;
    margin-bottom: 40px;

    button:first-of-type {
      margin-right: 24px;
    }
  }

  & .download-text {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`

export const RightCenteredRow = styled(Row)`
  text-align: end;
  justify-content: center;
`

export { TwoColumnFullPageLayout }
