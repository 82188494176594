/* eslint-disable class-methods-use-this */
import { WebStorage } from 'redux-persist/es/types'
import _ from 'lodash'

export class FilterStorage implements WebStorage {
  localStorageKeys = ['selectedFilterGroups']

  getItem(key: string): Promise<string | null> {
    return new Promise(resolve => {
      const localFilters = JSON.parse(localStorage.getItem(key) || '{}').filters
      const sessionFilters = JSON.parse(sessionStorage.getItem(key) || '{}')

      const res = {
        ...sessionFilters,
        filters: JSON.stringify(_.merge(localFilters, sessionFilters.filters)),
        // eslint-disable-next-line no-underscore-dangle
        _persist: sessionFilters._persist
      }

      resolve(JSON.stringify(res))
    })
  }

  setItem(key: string, item: string): Promise<void> {
    return new Promise(resolve => {
      const data = JSON.parse(item)
      const filters = JSON.parse(data.filters)

      const dataToSave = _.reduce(
        _.keys(filters),
        (acc, objKey) => {
          if (_.includes(this.localStorageKeys, objKey)) {
            acc.localData.filters[objKey] = filters[objKey]
          } else {
            acc.sessionData.filters[objKey] = filters[objKey]
          }
          return acc
        },
        {
          localData: {
            filters: {}
          },
          sessionData: {
            ...data,
            filters: {},
            // eslint-disable-next-line no-underscore-dangle
            _persist: data._persist
          }
        } as any
      )

      localStorage.setItem(key, JSON.stringify(dataToSave.localData))
      sessionStorage.setItem(key, JSON.stringify(dataToSave.sessionData))
      return resolve()
    })
  }

  removeItem(key: string): Promise<void> {
    return new Promise(resolve => {
      localStorage.removeItem(key)
      sessionStorage.removeItem(key)
      return resolve()
    })
  }
}
