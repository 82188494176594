import { createAction } from '../utils/redux'
import { ActionsUnion } from '../utils/types'

export const CLEAR_STORE = 'global/CLEAR_STORE'

export const GlobalActions = {
  clearStore: () => createAction(CLEAR_STORE)
}

export type GlobalActions = ActionsUnion<typeof GlobalActions>
