import _ from 'lodash'
import { Dictionary } from '../../../utils/types'
import { SelectedFilterGroups, FilterState } from '../types'
import { TreeKeySelection } from '../../../utils/tree'

export const getResetSelected = (
  filtersState: FilterState,
  selectedFilterGroups: SelectedFilterGroups,
  userId?: string,
  entityId?: string
): Partial<FilterState> => {
  if (_.isNil(userId)) {
    return {
      selectedFilterGroups
    }
  }

  let selectedFilters: SelectedFilterGroups

  if (entityId) {
    selectedFilters = {
      ...filtersState.selectedFilterGroups,
      [userId]: _.omit(filtersState.selectedFilterGroups[userId], [entityId])
    }
  } else {
    selectedFilters = _.omit(filtersState.selectedFilterGroups, [userId])
  }

  return {
    selectedFilterGroups: selectedFilters
  }
}

type SetSelectedFilterGroupDto = {
  userId: string
  entityId?: string
  groupId: string
  filters: TreeKeySelection[]
}

export const getSetSelected = (
  { entityId, userId, filters, groupId }: SetSelectedFilterGroupDto,
  filterState: FilterState
): Partial<FilterState> => {
  if (entityId) {
    const newSelectedFilters = {
      ..._.get(filterState.selectedFilterGroups, [userId, entityId], {}),
      [groupId]: filters
    } as Dictionary<TreeKeySelection[]>

    return {
      selectedFilterGroups: {
        ...filterState.selectedFilterGroups,
        [userId]: {
          ...filterState.selectedFilterGroups[userId],
          [entityId]: newSelectedFilters
        }
      } as SelectedFilterGroups
    }
  }

  const newGroups = {
    ...filterState.selectedFilterGroups[userId],
    [groupId]: filters
  } as Dictionary<TreeKeySelection[]>

  return {
    selectedFilterGroups: {
      ...filterState.selectedFilterGroups,
      [userId]: newGroups
    } as SelectedFilterGroups
  }
}
