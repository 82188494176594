import axios from 'axios'
import { UserTypeKey } from '../../authorization'
import { LoginResponse } from '../login/types'
import { Channel } from '../types'
import {
  UsersResponse,
  ConfirmResetPasswordRequest,
  PasswordChangeRequest,
  ResetPasswordRequest,
  TokenResponse
} from './types'

export const getUsers = (userType: UserTypeKey, channel?: Channel) =>
  axios
    .get<UsersResponse>('/api/users', {
      params: {
        channel,
        userType: userType !== 'All' ? userType : undefined
      }
    })
    .then(resp => resp.data)

export const refreshToken = () => axios.post<LoginResponse>('/api/session/refresh-token')

export const confirmResetPassword = (params: ConfirmResetPasswordRequest) =>
  axios.put('/api/session/confirm-reset-password', params)

export const changePassword = (oldPassword: string, newPassword: string) =>
  axios.put('/api/users/me/password', { oldPassword, newPassword })

export const changeLanguage = (language: string) => axios.put('/api/users/me/language', { language })

export const changeCurrency = (currency: string) => axios.put('/api/users/me/currency', { currency })

export const renewPassword = (userName: string, request: PasswordChangeRequest) =>
  axios.put(`/api/users/${encodeURIComponent(userName)}/password`, request)

export const resetPassword = (request: ResetPasswordRequest) =>
  axios.put('/api/session/reset-password', undefined, { params: request })

export const createSessionOtk = () => axios.post<TokenResponse>('api/session/create-session-otk').then(res => res.data)
