import { SearchType } from '@features/filters/types/mapFilter'
import { SemanticSearchSynonyms, SemanticSearchType } from './semanticSearch/types'

// If you change the enum value,
// you need to update keys of operator translations in operations
export enum FieldOperator {
  Equals = 'Equals',
  NotEquals = 'NotEquals',
  Ge = 'GreaterOrEqual',
  Le = 'LowerOrEqual',
  Greater = 'Greater',
  Less = 'Lower',
  FromList = 'FromList',
  ExcludeList = 'ExcludeList',
  NotExists = 'NotExists',
  Exists = 'Exists',
  Between = 'Between',
  IsOneOf = 'IsOneOf',
  IsNotOneOf = 'IsNotOneOf',
  In = 'In',
  NotIn = 'NotIn'
}

export enum CompoundOperator {
  Or = 'Or',
  And = 'And'
}

// HierarchyAdditionalData are needed on FE to convert BE representation to FE
export type HierarchyAdditionalData = {
  $type: 'HierarchyAdditionalData'
  fieldId?: string
  singleSelectionTargetJsonPath?: string
  isMultiSelection?: boolean
  exactSearch?: boolean
}

export type FieldExpression = {
  $type: 'FieldExpression'
  fieldId?: string
  value?: string | number | boolean | string[] | null
  operator?: FieldOperator
}

export enum Interval {
  LeftOpenRightOpen = 'LeftOpenRightOpen',
  LeftOpenRightClosed = 'LeftOpenRightClosed',
  LeftClosedRightOpen = 'LeftClosedRightOpen',
  LeftClosedRightClosed = 'LeftClosedRightClosed'
}

export type NumericRangeExpression = {
  $type: 'NumericRangeExpression'
  fieldId?: string
  minValue?: number | null
  maxValue?: number | null
  interval: Interval
}
export type DateRangeExpression = {
  $type: 'DateRangeExpression'
  fieldId?: string
  minValue?: string
  maxValue?: string
  interval: Interval
}

// Expressions which are filters
export type FilterExpression =
  | FieldExpression
  | HierarchyExpression
  | NumericRangeExpression
  | DateRangeExpression
  | SemanticExpression
  | GeoDistanceExpression
  | GeoPolygonExpression
  | CustomVarExpression

export type Expression = FilterExpression | CompoundExpression

export type CompoundExpression = {
  $type: 'CompoundExpression'
  condition: CompoundOperator
  childExpressions: Expression[]
  groupId?: string
}

export type HierarchyExpression = {
  $type: 'HierarchyExpression'
  additionalData: HierarchyAdditionalData
  condition?: CompoundOperator
  childExpressions?: FieldExpression[]
}

type CustomVarExpressionBase = {
  fieldId?: string
  position?: number
}

export type CustomVarFieldExpression = CustomVarExpressionBase & {
  $type: 'CustomVariableExpression'
  operator?: FieldOperator
  value?: string | number | boolean | string[] | null
}

export type CustomVarNumericRangeExpression = CustomVarExpressionBase & {
  $type: 'CustomVariableNumericRangeExpression'
  minValue?: number | null
  maxValue?: number | null
  interval: Interval
}

export type CustomVarDateRangeExpression = CustomVarExpressionBase & {
  $type: 'CustomVariableDateRangeExpression'
  minValue?: string
  maxValue?: string
  interval: Interval
}

export type CustomVarExpression =
  | CustomVarFieldExpression
  | CustomVarNumericRangeExpression
  | CustomVarDateRangeExpression

export type SemanticExpression = {
  $type: 'SemanticExpression'
  search: string
  type: SemanticSearchType
  synonyms?: SemanticSearchSynonyms[]
}

export type Point = {
  $type: 'GeoPosition'
  lat: number
  lon: number
}

export type GeoDistanceAdditionalData = {
  $type: 'GeoDistanceMetadata'
  searchType: SearchType
  address?: string
  companyName?: string
}

export type GeoDistanceExpression = {
  $type: 'GeoDistanceExpression'
  fieldId: string
  distanceKm?: number
  point?: Point
  operator?: FieldOperator
  additionalData: GeoDistanceAdditionalData
}

export type GeoPolygonExpression = {
  $type: 'GeoPolygonExpression'
  fieldId: string
  points?: Point[]
  additionalData: GeoDistanceAdditionalData
}
