/* eslint-disable import/no-default-export */
export default {
  UserName: 'Benutzername',
  InsertUserName: 'Benutzername eingeben',
  Email: 'E-Mail',
  InsertEmail: 'E-Mail-Adresse eingeben',
  ResetPassword: 'Passwort zurücksetzen',
  Success: 'Ihr Passwort wurde erfolgreich geändert. Sie erhalten eine Bestätigungs-E-Mail mit einem neuen Passwort.',
  Propmt:
    'Geben Sie den Benutzernamen und die E-Mail-Adresse ein, mit der Ihr Konto verknüpft ist. Sie erhalten das Passwort für den Zugriff auf den Dienst in der von Ihnen angegebenen E-Mail-Adresse.'
}
