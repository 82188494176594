import React from 'react'
import { Input } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import { InputContainer, SendButton } from '../styled'
import RefreshIcon from '../../../assets/icons/refresh.svg?react'
import { ChatInputBarProps } from '../types'

export const ChatInputBar: React.FC<ChatInputBarProps> = ({
  currentMessage,
  onMessageChange,
  onSend,
  onReset,
  placeholder
}) => {
  return (
    <InputContainer>
      <Input placeholder={placeholder} value={currentMessage} onChange={onMessageChange} onPressEnter={onSend} />
      <SendButton type="primary" icon={<SendOutlined />} onClick={onSend} />
      <SendButton type="primary" icon={<RefreshIcon />} onClick={onReset} />
    </InputContainer>
  )
}
