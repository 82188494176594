import _ from 'lodash'
import MatomoTracker from '@jonkoops/matomo-tracker'

import { ApplicationModule } from '@types'
import { FilterReducerPath } from '../filters/types'
import { EventCategory } from './types'

export function toTrackableLocation(pathname: string) {
  return _.replace(
    pathname,
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
    '{identifier}'
  )
}

export function categoryFromFilterReducerPath(p: FilterReducerPath): EventCategory {
  switch (p) {
    case 'prospecting':
      return EventCategory.Prospecting
    case 'views.portfolioDetail':
      return EventCategory.PortfolioDetail
    case 'views.salestoolAssignments':
      return EventCategory.SalesTool
    case 'targeting':
      return EventCategory.Targeting
    default:
      return EventCategory.Unknown
  }
}

type InitOptions = {
  urlBase: string | undefined
  siteId: number | undefined
}
export class Matomo {
  static tracker: MatomoTracker

  static initialize = ({ urlBase, siteId }: InitOptions) => {
    if (!urlBase || !siteId) return
    Matomo.tracker = new MatomoTracker({
      urlBase,
      siteId
    })
  }

  static setCrossDomainLinking(trackedUrls: string[] | undefined) {
    if (trackedUrls) {
      Matomo.tracker?.pushInstruction('setDomains', trackedUrls)
      Matomo.tracker?.pushInstruction('enableCrossDomainLinking')
    }
  }

  static setVisitorId = (visitorId: string) => {
    if (visitorId) {
      Matomo.tracker?.pushInstruction('setVisitorId', visitorId)
    }
  }

  static setUserId = (userId: string) => {
    if (userId) {
      Matomo.tracker?.pushInstruction('setUserId', userId)
    }
  }
}

export const getEventCategory = (applicationModule: ApplicationModule) => {
  switch (applicationModule) {
    case ApplicationModule.PortfolioManagement:
      return EventCategory.PortfolioManagement
    case ApplicationModule.Prospecting:
      return EventCategory.Prospecting
    case ApplicationModule.Targeting:
      return EventCategory.Targeting
    case ApplicationModule.Salestool:
      return EventCategory.SalesTool
    case ApplicationModule.Operations:
      return EventCategory.Operations
    default:
      throw new Error('Event category missing for this module!')
  }
}
