/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Appointment',
  CreateAppointmentButton: 'Create new appointment',
  ManageAppointmentsButton: 'Manage appointment',
  EditAppointment: 'Edit appointment',
  SendEmailLabel: 'Send details to my calendar and notify recipients',
  ForHeadquarterButton: 'For headquarter',
  ForLocalBranchButton: 'For current branch',
  AppointmentDetails: {
    Title: 'Insert name, date and place related to your new appointment',
    EditTitle: 'Edit name, date and place related to your appointment',
    MatchingTermIta: 'Tax code/VAT code',
    MatchingTermDeu: 'Tax code/VAT code/ONR Number',
    MatchingTermChe: 'Business identification number / CRIF Id',
    MatchingTermAut: 'Vat Code / Company book number',
    MatchingTermCheAut: 'Business identification number / CRIF Id /  Vat Code / Company book number',
    MatchingTermCzsk: 'Tax code/VAT code',
    MatchingTermFareast: 'Tax code/VAT code',
    Name: 'Appointment name',
    Description: 'Appointment description',
    Place: 'Place',
    StartDate: 'Start date',
    EndDate: 'End date',
    SelectColor: 'Select a color'
  },
  CreateAppointment: {
    Title: 'Create appointment',
    ShareAppointmentStep: {
      Title: 'Select recipients',
      Text: 'Select the users to whom you want to forward the appointment or enter the email address of the recipients, separated by commas',
      Email: 'Email addresses, separated by commas'
    },
    ConfirmationStep: {
      Title: 'Confirm operation',
      Text: 'Do you want to send this appointment to your calendar? Selecting ON: Margò will send you an email which will contain all information just added'
    }
  },
  DeleteAppointment: {
    Title: 'Delete appointment',
    SubTitle: 'Are you sure you want to delete the appointment?'
  },
  AppointmentsList: {
    EmptyText: 'Here you will find all the appointments saved in your calendar.',
    NameColumn: 'Name',
    StartColumn: 'Start',
    EndColumn: 'End',
    ShowExpired: 'Show expired',
    All: 'All'
  },
  Colors: {
    Green: 'Green',
    Yellow: 'Yellow',
    Orange: 'Orange',
    Red: 'Red',
    Violet: 'Violet',
    Blue: 'Blue',
    LightBlue: 'Light blue',
    Turquoise: 'Turquoise',
    Pink: 'Pink',
    Black: 'Black'
  }
}
