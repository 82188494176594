import React, { MouseEvent } from 'react'
import _ from 'lodash'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components/macro'
import { ForUpsell } from '@utils/types'
import { isDenied, isForUpsell } from '@utils/constraints'
import { LockedFeatureTooltipContent } from '@components/Tooltip/LockedFeatureTooltipContent'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { SaveBeforeContinue } from '@pages/Prospecting/SaveBeforeContinueDialog'
import { useBranding } from '../../hooks/useBranding'
import UserIcon from '../../assets/icons/single.svg?react'
import UserSecret from '../../assets/icons/fa-user-secret.svg?react'
import CalendarIcon from '../../assets/icons/icon-s-calendar.svg?react'
import { getAvailableLanguages } from '../../features/config/selectors'
import { UserActions } from '../../features/user/actions'
import { getLanguageIconCmp } from '../LanguageIcon'
import { Stack } from '../Stack'
import { BasicTooltip } from '../Tooltip'
import { VisibleDrawer } from './types'
import { useMainMenuItems } from './hooks'
import { HorizontalDivider, getBellIconCmp } from './StyledComponents'
import { SvgComponent, SvgComponentProps } from '../../types'
import { hasOriginator, isLoggedIn, moduleAppointmentConstraint } from '../../features/user/selectors'

const MobileMenuWrapper = styled(Stack)`
  justify-content: stretch;

  ${media.sm`
    && .mobile-only {
      display: none !important;
    }
  `}
`

type MenuItemWrapperProps = {
  active?: boolean
  mobile?: boolean
  checkable?: boolean
  numberOfMenuItems?: number
}

const NUMBER_OF_MENU_ITEMS_THAT_FIT_WELL = 7

const MenuItemWrapper = styled.button.withConfig<MenuItemWrapperProps>({
  shouldForwardProp: p => !_.includes(['active', 'mobile', 'checkable', 'numberOfMenuItems'], p)
})`
  cursor: pointer;
  display: flex;
  padding: 25px 40px;
  align-items: center;
  background-color: transparent;
  border: none;
  ${({ mobile }) => (mobile ? 'width: 100%;' : 'height: 100%;')}
  ${({ theme, active, checkable }) =>
    checkable ? `fill: ${theme.colors.white};` : `fill: ${active ? theme.colors.pumpkinOrange : theme.colors.white};`}
  color: ${({ theme }) => theme.colors.white};
  position: relative;

  span {
    margin-left: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :hover {
    fill: ${({ theme }) => theme.colors.pumpkinOrange};
    color: ${({ theme }) => theme.colors.pumpkinOrange};
  }

  ${({ theme, active, checkable }) =>
    checkable
      ? `
    svg {
      border: ${active ? `1px solid ${theme.colors.white}` : 'none'};
      border-radius: 2px;
      padding: 4px;
    }

    &::after {
      position: absolute;
      right: 40px;
      content: '${active ? '✓' : ''}';
    }`
      : ''}

  ${media.md`
    padding: 18px;
    svg {
      margin: 0 0.45em;
    }
    span {
      margin: 0 0.45em;
    }
  `}

  ${media.lg`
    flex-wrap: wrap;
    justify-content: center;
  `}

  ${media.xl`
    flex-wrap: nowrap;
    justify-content: flex-start;
  `}

  ${({ numberOfMenuItems }) =>
    media.lg`${
      numberOfMenuItems && numberOfMenuItems > NUMBER_OF_MENU_ITEMS_THAT_FIT_WELL
        ? 'padding: 15px 12px;'
        : 'padding: 15px;'
    }`}

  ${({ numberOfMenuItems }) =>
    media.xl`${
      numberOfMenuItems && numberOfMenuItems > NUMBER_OF_MENU_ITEMS_THAT_FIT_WELL
        ? 'padding: 25px 28px;'
        : 'padding: 25px 30px;'
    }`}

    ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
`

const StyledLink = styled.a<{ disabled?: boolean }>`
  ${MenuItemWrapper}:focus {
    outline: 0;
  }

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
`

type MenuItemButtonProps = {
  icon: SvgComponent
  iconProps?: SvgComponentProps
  text: string
  onClick?: () => void
  className?: string
  hideText?: boolean
  tooltipPlacement?: TooltipPlacement
} & MenuItemWrapperProps &
  ForUpsell

export const MenuItemButton = ({
  icon: IconCmp,
  iconProps,
  text,
  hideText,
  disabled,
  tooltip,
  tooltipClickable,
  tooltipPlacement = 'bottom',
  ...wrapperProps
}: MenuItemButtonProps) => {
  const btn = (
    <MenuItemWrapper disabled={disabled} {...wrapperProps}>
      <IconCmp {...iconProps} />
      {!hideText && <span>{text}</span>}
    </MenuItemWrapper>
  )

  return hideText || tooltip !== undefined ? (
    <BasicTooltip
      placement={tooltipPlacement}
      title={tooltip ?? text}
      overlayClassName={tooltipClickable ? 'info-tooltip' : undefined}
    >
      {btn}
    </BasicTooltip>
  ) : (
    btn
  )
}

type MenuItemLinkProps = {
  link: string
  tooltipPlacement?: TooltipPlacement
} & MenuItemButtonProps &
  ForUpsell &
  SaveBeforeContinue

export const MenuItemLink = ({
  className,
  link,
  onClick,
  numberOfMenuItems,
  disabled,
  tooltip,
  tooltipClickable,
  tooltipPlacement = 'bottom',
  saveBeforeContinue = fn => fn,
  ...btnProps
}: MenuItemLinkProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onButtonClick = React.useCallback(
    (event: MouseEvent) => {
      event.preventDefault()

      if (onClick) onClick()

      let action = () => (event.ctrlKey ? window.open(link, '_blank') : navigate(link))
      if (pathname !== link) action = saveBeforeContinue(action)
      action()
    },
    [link, navigate, onClick, pathname, saveBeforeContinue]
  )

  return (
    <BasicTooltip
      placement={tooltipPlacement}
      title={tooltip}
      overlayClassName={tooltipClickable ? 'info-tooltip' : undefined}
    >
      <StyledLink className={className} onClick={onButtonClick} disabled={disabled} href={link}>
        <MenuItemButton {...btnProps} numberOfMenuItems={numberOfMenuItems} tooltipPlacement={tooltipPlacement} />
      </StyledLink>
    </BasicTooltip>
  )
}

type NavigationMenuProps = {
  hideLabels?: boolean
} & SaveBeforeContinue

export const NavigationMenu = React.memo(function NavigationMenu({
  hideLabels,
  saveBeforeContinue
}: NavigationMenuProps) {
  const menuItems = useMainMenuItems()
  return (
    <div className="header-menu-wrapper">
      {_.map(menuItems, item => (
        <MenuItemLink
          data-pf-id={item.link}
          key={item.text}
          {...item}
          hideText={hideLabels}
          saveBeforeContinue={saveBeforeContinue}
          numberOfMenuItems={menuItems.length}
        />
      ))}
    </div>
  )
})

type MobileMenuProps = {
  drawer: VisibleDrawer
  hasNewNotifications: boolean
  setDrawer: (drawer: VisibleDrawer) => void
  setHasNewNotifications: (hasNewNotifications: boolean) => void
}

export const MobileMenu = ({ drawer, hasNewNotifications, setDrawer, setHasNewNotifications }: MobileMenuProps) => {
  const { t, i18n } = useTranslation('header')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { allowedModules } = useBranding()
  const isImpersonited = useSelector(hasOriginator)
  const isUserLoggedIn = useSelector(isLoggedIn)
  const appointmentConstraint = useSelector(moduleAppointmentConstraint)

  const isAppointmentForUpsell = isForUpsell(appointmentConstraint)

  const menuItems = useMainMenuItems()

  const availableLanguages = useSelector(getAvailableLanguages)
  return (
    <MobileMenuWrapper orientation="vertical" padding="20px 0">
      {_.map(menuItems, item => (
        <MenuItemLink
          key={item.text}
          mobile
          onClick={() => setDrawer(VisibleDrawer.None)}
          tooltipPlacement="left"
          {...item}
        />
      ))}
      {menuItems.length > 0 && <HorizontalDivider className="mobile-only" />}
      {isUserLoggedIn && (
        <>
          {allowedModules.Appointment && !isDenied(appointmentConstraint) && (
            <MenuItemButton
              mobile
              className="mobile-only"
              onClick={() => {
                setDrawer(drawer === VisibleDrawer.Appointment ? VisibleDrawer.None : VisibleDrawer.Appointment)
              }}
              icon={CalendarIcon}
              text={t('Appointments')}
              disabled={isAppointmentForUpsell}
              tooltip={isAppointmentForUpsell ? <LockedFeatureTooltipContent /> : undefined}
              tooltipClickable={isAppointmentForUpsell}
              tooltipPlacement="left"
            />
          )}
          <MenuItemButton
            mobile
            className="mobile-only"
            onClick={() => {
              setDrawer(drawer === VisibleDrawer.Notification ? VisibleDrawer.None : VisibleDrawer.Notification)
              setHasNewNotifications(false)
            }}
            icon={getBellIconCmp({ hasNewNotifications })}
            text={t('Notification.Title')}
            tooltipPlacement="left"
          />
          <MenuItemButton
            mobile
            className="mobile-only"
            onClick={() => {
              setDrawer(drawer === VisibleDrawer.Account ? VisibleDrawer.None : VisibleDrawer.Account)
            }}
            icon={isImpersonited ? UserSecret : UserIcon}
            text={t('Profile')}
            tooltipPlacement="left"
          />
          <HorizontalDivider />
        </>
      )}
      {_.map(availableLanguages, (value, key) => (
        <MenuItemButton
          mobile
          checkable
          onClick={() => {
            dispatch(UserActions.changeLanguage(key, true, location, navigate))
            setDrawer(VisibleDrawer.None)
          }}
          icon={getLanguageIconCmp({ langKey: key })}
          iconProps={{ width: 36, height: 28 }}
          active={i18n.language === key}
          text={value.full}
          key={key}
          tooltipPlacement="left"
        />
      ))}
    </MobileMenuWrapper>
  )
}
