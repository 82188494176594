// eslint-disable-next-line import/no-default-export
export default {
  Close: 'Schließen',
  Popup: {
    TaxCode: 'Tax code',
    BirthDate: 'Geburtsdatum',
    BirthTown: 'Geburtsort',
    Enriched: 'Angereichert',
    Enrich: 'Anreichern'
  },
  NotEnriched: {
    Title: 'Kaufen Sie {{companyName}} Daten, um diesen Bereich freizuschalten',
    Message: 'Sie können diesen Bereich durch den Kauf {{companyName}} freischalten',
    Action: 'Freischalten von {{companyName}}'
  },
  IdentificationData: {
    Title: 'Daten zur Identifizierung',
    TaxCode: 'Tax code',
    BirthDate: 'Geburtsdatum',
    BirthPlace: 'Geburtsort',
    BirthCountry: 'Geburtsland',
    Sex: 'Geschlecht',
    Address: 'Adresse'
  },
  Positions: {
    Title: 'Aktive und ehemalige Positionen',
    ActivePositions: {
      Title: 'Aktive Positionen'
    },
    PastPositions: {
      Title: 'Frühere Positionen'
    },
    From: 'Ab',
    To: 'Bis'
  },
  PartnersList: {
    Title: 'Partner Liste',
    Amount: 'Menge'
  },
  Enrich: {
    Title: 'Person anreichern',
    Desc: 'Durch die Anreicherung der Person mit den {{companyName}}-Daten werden diese Informationen heruntergeladen und regelmäßig aktualisiert',
    Success: 'Die Anreicherung war erfolgreich',
    CreditsWillBeUsed: 'Benutzte Rings',
    CreditsRemaining: 'Verbleibende Rings',
    ProposalFailed: 'Es gab ein Problem bei der Überprüfung der verfügbaren Credits für diesen Vorgang.'
  }
}
