import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { Link as RouterLink, To } from 'react-router-dom'
import { Stack } from '../Stack'
import { toInt } from '../../utils/helpers'
import { ellipsisMixin } from './common'
import arrow from '../../assets/icons/icon-s-chevron-right.svg'

const ICON_SIZE: Record<string, number> = {
  '12px': 16,
  '14px': 20,
  '16px': 24,
  '24px': 28,
  '32px': 36
}

const LinkStyled = styled(RouterLink)<{ ellipsis: number }>`
  ${({ ellipsis }) => ellipsis && ellipsisMixin}
`

type BaseLinkProps = {
  to?: To
  state?: any
  ellipsis?: boolean
  children?: ReactNode
  onClick?: (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

type LinkWithoutStackProps = {
  iconSize: string
  hideIcon?: boolean
} & BaseLinkProps

type LinkProps = {
  stretch?: boolean
} & LinkWithoutStackProps

const BaseLink = ({ to, state, ellipsis, children, onClick }: BaseLinkProps) => {
  return (
    <LinkStyled
      to={to || '/'}
      state={state}
      ellipsis={toInt(ellipsis)}
      onClick={evt => {
        if (!to) evt.preventDefault()
        if (onClick) onClick(evt)
      }}
    >
      {children}
    </LinkStyled>
  )
}

export const Link = ({ to, state, stretch, ellipsis, iconSize, hideIcon, children, onClick }: LinkProps) => {
  const size = ICON_SIZE[iconSize] || 24
  return (
    <BaseLink to={to} state={state} ellipsis={ellipsis} onClick={onClick}>
      <Stack center gap={stretch ? 'auto' : undefined}>
        {children}
        {hideIcon || <img src={arrow} width={size} height={size} alt="arrow" />}
      </Stack>
    </BaseLink>
  )
}

export const LinkWithoutStack = ({
  to,
  state,
  ellipsis,
  iconSize,
  hideIcon,
  children,
  onClick
}: LinkWithoutStackProps) => {
  const size = ICON_SIZE[iconSize] || 24
  return (
    <BaseLink to={to} state={state} ellipsis={ellipsis} onClick={onClick}>
      {children}
      {hideIcon || <img src={arrow} width={size} height={size} alt="arrow" />}
    </BaseLink>
  )
}
