import _ from 'lodash'
import i18NextInstance, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en-GB'
import it from './it-IT'
import de from './de-DE'
import sk from './sk-SK'
import cs from './cs-CZ'
import fr from './fr-FR'

const debug = process.env.NODE_ENV === 'development'

const config: InitOptions = {
  resources: {
    'en-GB': en,
    'it-IT': it,
    'de-DE': de,
    'sk-SK': sk,
    'cs-CZ': cs,
    fr
  },
  debug,
  lng: 'en-GB',
  fallbackLng: 'en-GB',
  keySeparator: '.',
  interpolation: {
    escapeValue: false
  },
  saveMissing: debug,
  missingKeyHandler: (lngs: readonly string[], ns: string, key: string) => {
    const message = `Translation for key: ${ns}:${key} is missing in languages: ${_.join(lngs, ',')}`
    if (debug) {
      throw new Error(message)
    } else {
      console.warn(message) // eslint-disable-line no-console
    }
  },
  compatibilityJSON: 'v3'
}

i18NextInstance.use(initReactI18next).init(config)

export const i18n = i18NextInstance
