/* eslint-disable import/no-default-export */
export default {
  UserName: 'Uživatelské jméno',
  InsertUserName: 'Vložte uživatelské jméno',
  Email: 'E-mail',
  InsertEmail: 'Vložte e-mail',
  ResetPassword: 'Obnovit heslo',
  Success: 'Vaše heslo bylo úspěšně obnoveno. Poslali jsme vám nové do vaší e-mailové schránky.',
  Propmt:
    'Zadejte uživatelské jméno a e-mailovou adresu, ke které je váš účet přiřazen. Heslo pro přístup ke službě bude zasláno do vámi uvedené e-mailové schránky.'
}
