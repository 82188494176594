import axios, { CancelToken } from 'axios'
import {
  GetUserDashboardResponse,
  AddDashboardItemRequest,
  AddDashboardItemResponse,
  ChangeDashboardItemRequest,
  ChangeDashboardItemResponse,
  GetProspectingQueryDataRequest,
  GetQueryDataResponse,
  GetTargetingQueryDataRequest,
  GetTargetingQueryDataResponse,
  GetCampaignWidgetDataResponse
} from './types'

export const getUserDashboard = () =>
  axios.get<GetUserDashboardResponse>('/api/users/dashboards/default').then(response => response.data)

export const addDashboardItem = (data: AddDashboardItemRequest) =>
  axios.post<AddDashboardItemResponse>('/api/DashboardItems/dashboard-items', data).then(response => response.data)

export const removeDashboardItem = (itemId: string) => axios.delete(`/api/DashboardItems/dashboard-items/${itemId}`)

export const changeDashboardItem = (data: ChangeDashboardItemRequest) => {
  const { id: itemId, ...rest } = data
  return axios
    .put<ChangeDashboardItemResponse>(`/api/DashboardItems/dashboard-items/${itemId}`, rest)
    .then(response => response.data)
}

export const getQueryData = (data: GetProspectingQueryDataRequest, cancelToken?: CancelToken) =>
  axios.post<GetQueryDataResponse>('/api/dashboard/queries', data, { cancelToken })

export const getTargetingQueryData = (data: GetTargetingQueryDataRequest, cancelToken?: CancelToken) =>
  axios.post<GetTargetingQueryDataResponse>('/api/dashboard/targeting-queries', data, { cancelToken })

export const getCampaignWidgetData = (campaignId: string, cancelToken?: CancelToken) =>
  axios.post<GetCampaignWidgetDataResponse>(`/api/dashboard/campaign-preview/${campaignId}`, undefined, { cancelToken })
