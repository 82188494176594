import _ from 'lodash'
import { useRef } from 'react'

export const useDeepCompareMemoize = (value: React.DependencyList) => {
  const ref = useRef<React.DependencyList>([])

  if (!_.isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}
