import { Reducer } from 'redux'
import { ConstraintValue, ExtensionManager } from '../../api/config/types'
import { Channel } from '../../api/types'
import { Claim, EMPTY_CLAIM } from '../../authorization'
import { User, UserType } from '../sharedTypes'
import * as actions from './actions'
import { Branch, Organization, Subscription } from './types'

export type UserState = Readonly<{
  token: string | null
  claims: Claim | number
  products: string[]
  hasPortfolio: boolean
  user: User
  originatorUser?: User
  instanceCode: string
  organization: Organization
  branch: Branch
  subscription: Subscription
  selectedLanguage?: string
  selectedCurrency?: string
  availableRedirects: string[]
  constraints: Record<string, ConstraintValue>
  extensionsManager: ExtensionManager
  hasPortfolioExclusions: boolean
  customerCompanyVisibilityAccountEnabled: boolean
  customerCompanyConfigurationApplied: boolean
}>

export const initState: UserState = {
  token: null,
  claims: EMPTY_CLAIM,
  products: [],
  hasPortfolio: false,
  instanceCode: 'default',
  user: {
    id: '',
    userCode: '',
    firstName: '',
    lastName: '',
    email: '',
    authUserDistinguishName: '',
    organizationUserDistinguishName: '',
    impersonationAllowed: undefined,
    userType: UserType.Supervisor,
    language: '',
    currency: '',
    isActive: false,
    channel: Channel.Web
  },
  organization: {
    id: '',
    code: '',
    name: '',
    countryCode: '',
    authUserDistinguishName: '',
    organizationUserDistinguishName: '',
    identificationCode: ''
  },
  branch: {
    code: '',
    name: '',
    authUserDistinguishName: '',
    organizationUserDistinguishName: ''
  },
  subscription: {
    id: '',
    subscriptionStartDate: '',
    subscriptionEndDate: '',
    hasRetailIndexes: false,
    hasAnalyticsIndexes: false,
    hasRiskData: false,
    hasBaseList: true,
    hasMarketingList: false,
    hasCompleteList: false,
    hasPdfReport: false,
    hasLocalUnit: false,
    hasSemanticClusters: false,
    details: {
      hasPortfolioActions: false,
      hasExtensions: false,
      hasEasyFinance: false
    },
    visibilityCountries: [],
    hasExtraPeopleData: false,
    customIndexes: [],
    hasEsg: false,
    subscriptionRoles: []
  },
  availableRedirects: [],
  constraints: {},
  originatorUser: undefined,
  extensionsManager: {
    hasSurveys: false,
    availableItems: []
  },
  hasPortfolioExclusions: false,
  customerCompanyVisibilityAccountEnabled: false,
  customerCompanyConfigurationApplied: false
}

export const userReducer: Reducer<UserState, actions.UserActions> = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        claims: action.payload.claims,
        user: {
          ...state.user,
          id: action.payload.userId
        },
        organization: {
          ...state.organization,
          id: action.payload.organizationId
        }
      }
    case actions.SET_USER_DATA:
      return {
        ...state,
        hasPortfolio: action.payload.hasPortfolio,
        user: action.payload.user,
        originatorUser: action.payload.originatorUser,
        organization: action.payload.organization,
        instanceCode: action.payload.instanceCode,
        branch: action.payload.branch,
        subscription: action.payload.subscription,
        selectedCurrency: state.selectedCurrency ?? action.payload.user.currency,
        extensionsManager: action.payload.extensionsManager
      }
    case actions.SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case actions.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.payload
      }
    case actions.SET_HAS_PORTFOLIO:
      return {
        ...state,
        hasPortfolio: action.payload.hasPortfolio
      }
    case actions.SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload.lang
      }
    case actions.SET_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload.currency
      }
    case actions.SET_USER_LANGUAGE:
      return {
        ...state,
        user: {
          ...state.user,
          language: action.payload.lang
        }
      }
    case actions.SET_USER_CURRENCY:
      return {
        ...state,
        user: {
          ...state.user,
          currency: action.payload.currency
        }
      }
    case actions.SET_AVAILABLE_REDIRECT:
      return {
        ...state,
        availableRedirects: action.payload.availableRedirects
      }
    case actions.SET_CONSTRAINTS:
      return {
        ...state,
        constraints: action.payload.constraints
      }
    case actions.SET_HAS_PORTFOLIO_EXCLUSIONS:
      return {
        ...state,
        hasPortfolioExclusions: action.payload.hasPortfolioExclusions
      }
    case actions.SET_CUST_COMP_VISIBILITY_ENABLED:
      return {
        ...state,
        customerCompanyVisibilityAccountEnabled: action.payload.custCompanyVisibilityAccountEnabled
      }
    case actions.SET_CUST_COMP_CONFIGURATION_APPLIED:
      return {
        ...state,
        customerCompanyConfigurationApplied: action.payload.customerCompanyConfigurationApplied
      }
    default:
      return state
  }
}
