import axios, { CancelToken } from 'axios'
import {
  PaginationRequestParams,
  ReleaseNotesResponse,
  SingleReleaseNoteResponse,
  AddReleaseNoteRequest,
  UpdateReleaseNoteRequest,
  ReleaseNoteFilters
} from './types'

export const getReleaseNotes = ({
  pagination,
  cancelToken,
  filter
}: {
  pagination?: PaginationRequestParams
  cancelToken?: CancelToken
  filter?: ReleaseNoteFilters
}) => axios.get<ReleaseNotesResponse>('/api/release-notes', { params: { ...pagination, filter }, cancelToken })

export const getSingleReleaseNote = (releaseNoteId: string, cancelToken?: CancelToken) =>
  axios.get<SingleReleaseNoteResponse>(`/api/release-notes/${releaseNoteId}`, { cancelToken })

export const addReleaseNote = (data: AddReleaseNoteRequest, cancelToken?: CancelToken) =>
  axios.post<SingleReleaseNoteResponse>('/api/release-notes', data, { cancelToken })

export const updateReleaseNote = (releaseNoteId: string, data: UpdateReleaseNoteRequest, cancelToken?: CancelToken) =>
  axios.put<SingleReleaseNoteResponse>('/api/release-notes', data, { params: { releaseNoteId }, cancelToken })

export const removeReleaseNote = (releaseNoteId: string, cancelToken?: CancelToken) =>
  axios.delete<{}>('/api/release-notes', { params: { releaseNoteId }, cancelToken })

export const setLastSeenReleaseNote = (releaseNoteId: string) =>
  axios.put(`/api/release-notes/last-seen-release-note?releaseNoteId=${releaseNoteId}`)
