import { theme } from '@utils/theme'
import { Card, Button } from 'antd'
import { StyledScrollWrapper } from '@components/StyledScrollWrapper'
import styled from 'styled-components/macro'
import { Text } from '@components/Text'
import CloseIcon from '../../assets/icons/close.svg?react'

export const Close = styled(CloseIcon)`
  fill: white;
`

export const StyledCard = styled(Card)<{ bottom?: string }>`
  display: flex;
  flex-direction: column;
  bottom: ${({ bottom }) => bottom};
  height: 470px;
  width: 400px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

  .ant-card-head {
    background-color: ${theme.colors.main};
    color: white;
  }

  &:after {
    content: '';
    position: absolute;
    right: 14px;
    bottom: -13px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 5px 0 15px;
    border-color: white transparent transparent transparent;
    z-index: 2;
  }

  &:before,
  &:nth-child(n + 1)::before,
  &:nth-child(n + 2)::before {
    content: '';
    position: absolute;
    right: 14px;
    width: 0;
    height: 0;
    border-style: solid;
    z-index: -1;
  }

  &:before {
    border-width: 16px 6px 0 16px;
    border-color: rgba(0, 0, 0, 0.05) transparent transparent transparent;
    bottom: -14px;
  }

  &:nth-child(n + 1)::before {
    border-width: 17px 7px 0 17px;
    border-color: rgba(0, 0, 0, 0.075) transparent transparent transparent;
    bottom: -15px;
  }

  &:nth-child(n + 2)::before {
    border-width: 18px 8px 0 18px;
    border-color: rgba(0, 0, 0, 0.025) transparent transparent transparent;
    bottom: -16px;
  }
`

export const StyledScroll = styled(StyledScrollWrapper)`
  height: 80%;
`

export const CloseIconWrapper = styled.div`
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const Message = styled.div`
  padding: 5px;
  border-bottom: 1px solid #eee;
  margin: 0;
`

export const InputContainer = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
  padding-top: 5px;
  bottom: 0;
  gap: 5px;
`

export const FloatButtonGroup = styled.div`
  position: fixed;
  bottom: 50px;
  right: 60px;
  z-index: 46000;
  .ant-card {
    height: 480px;
  }
`

export const FloatingButton = styled.div<{ bottom?: string }>`
  position: fixed;
  bottom: ${({ bottom }) => bottom};
  right: 40px;
  width: 44px;
  height: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 46002;
  background-color: ${theme.colors.main};
  color: white;
  border-radius: 50%;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12);

  animation: slide 0.25s forwards;
  -webkit-animation: slide 0.25s forwards;

  @keyframes slide {
    0% {
      transform: translateY(calc(${({ bottom }) => bottom} - 30px));
    }
    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes slide {
    0% {
      transform: translateY(calc(${({ bottom }) => bottom} - 30px));
    }
    100% {
      transform: translateY(0);
    }
  }
`

export const SendButton = styled(Button)`
  background-color: ${theme.colors.main};
  height: 35px;
`

export const SenderLabel = styled.div<{ sender: 'Assistant' | 'Customer' }>`
  font-size: 12px;
  position: relative;
  bottom: 5px;
  text-align: ${props => (props.sender === 'Assistant' ? 'left' : 'right')};
  margin-top: 5px;
  z-index: 100;
  ${props =>
    props.sender === 'Assistant'
      ? `
  margin-left: 32px;
  color: ${theme.colors.darkGrey}
  font-weight: 500;
`
      : `
  margin-right: 32px;
  font-weight: 500;
`}
`

export const FeedbackMessage = styled.p<{ feedbackGiven?: boolean }>`
  display: ${props => (props.feedbackGiven ? '' : 'hide')};
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 1s,
    transform 1s;

  &.hide {
    opacity: 0;
    transform: translateY(-20px);
  }
`

export const StyledTitle = styled(Text)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 18px;
  color: white;
`
