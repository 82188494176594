export type TreePath = string[] | null

export type Tree<T> = T & {
  isRoot?: boolean
  parentPath: TreePath
  key: string
  children: Record<string, Tree<T>> | undefined
  childrenOrder: string[] | undefined
}

export type KeyTree = { [k: string]: KeyTree | null }

export type TreeNodeData<T = any> = {
  parentPath: TreePath
  key: string
  label: string
  hasChildren: boolean
  data?: T
  disabled?: boolean
  count?: number
}

export type Select = TreeKeySelection & { selected: boolean }

export type TreeValue = TreeNodeData[]

export enum NodeSelectionState {
  Selected,
  Intermediate,
  NotSelected
}

export type DataTree<T = any> = Tree<TreeNodeData<T>>

export type TreeKeySelection = {
  key: string
  parentPath: TreePath
}

export type TreeKeySelectionWithLength = {
  key: string
  parentPathLength: number
}

export enum SelectionType {
  MultiselectClassic, // Hierarchical || ListAsHierarchical && multiselection ON
  MultiselectMultiValue, // FilterSelection
  SelectClassic // ListAsHierarchical && multiselection OFF
}

export enum ArrayCompare {
  STARTSWITH,
  EQUAL,
  DIFFERENT
}
