export type SearchType = 'primary' | 'secondary'

export enum IconPosition {
  Right = 0,
  Left = 1
}

export enum TextAreaVerticalExpansion {
  OneLine = 0,
  TwoLines = 1,
  Full = 2
}

export type SearchProps = {
  type: SearchType
  open: boolean
  placeholder?: string
  closable?: boolean
  value: string
  className?: string
  onChange: (value: string) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  shouldTriggerOnBlur?: boolean
  onClickSearchIcon?: () => void
  tooltipSearchText?: string
  searchIconPosition?: IconPosition
  onlySearchIcon?: boolean
  disabled?: boolean
  validateSearchValue?: (searchValue: string) => void
  isSearchValid?: boolean
  maxLength?: number
}

export type SearchError = {
  id: 'TooManyWordsInFilter'
  data: {
    wordCount: number
  }
}
