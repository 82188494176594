import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import contentDisposition from 'content-disposition'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import { all, call, takeEvery } from 'redux-saga/effects'
import { getDownloadToken, getDocumentsDataStreamUrl } from '../../api/documents'
import { NOT_FOUND } from '../../routes'
import { PromisedType } from '../../types'
import { DOWNLOAD_FILE, DOWNLOAD_FILE_STREAM, FileActions } from './actions'

export const loadFile = (config: AxiosRequestConfig) => {
  // eslint-disable-next-line no-param-reassign
  config.responseType = 'blob'
  return axios(config)
}

const UNQUOTE_REGEXP = /(^")|("$)/g
const unquoted = (data: string) => (_.endsWith(data, '"') ? _.replace(data, UNQUOTE_REGEXP, '') : data)

export const saveFile = (file: File, fileName: string) =>
  new Promise<void>(resolve => {
    saveAs(file, unquoted(fileName))
    resolve()
  })

export function* handleDownloadFile(action: FileActions) {
  const { payload } = action
  const response: AxiosResponse<any> = yield call(loadFile, payload as AxiosRequestConfig)
  const fileInfo = contentDisposition.parse(response.headers['content-disposition'])
  yield call(saveFile, response.data, fileInfo.parameters.filename)
}

export function* handleDownloadFileAsStream(action: ReturnType<typeof FileActions.downloadDocument>) {
  const { payload } = action
  try {
    const response: PromisedType<typeof getDownloadToken> = yield call(getDownloadToken, payload.documentId)
    window.open(getDocumentsDataStreamUrl(payload.documentId, response.data), '_blank')
  } catch {
    payload.navigate(
      { pathname: NOT_FOUND },
      {
        replace: true,
        state: {
          textKey: '',
          titleKey: 'DocumentNotFound',
          showMenu: true,
          image: 'koImage'
        }
      }
    )
  }
}

export function* rootSaga() {
  yield all([takeEvery(DOWNLOAD_FILE, handleDownloadFile), takeEvery(DOWNLOAD_FILE_STREAM, handleDownloadFileAsStream)])
}
