// eslint-disable-next-line import/no-default-export
export default {
  title: 'Operace',
  CompaniesInTheList: 'Společností na seznamu',
  Editor: {
    AddRule: 'Přidat pravidlo',
    AddGroup: 'Přidat skupinu',
    DeleteGroup: 'Smazat skupinu',
    StartNewSearch: 'Začít nové vyhledávání'
  },
  ShowQuery: 'Zobrazit dotaz',
  NoMobileDesc: 'Stránka, na kterou se pokoušíte dostat, je k dispozici pouze pro tablety a počítače.',
  Search: 'Hledat',
  Actions: {
    LoadQuery: 'Načíst Dotaz',
    LoadQueryTooltip: 'Otevřete uložený dotaz',
    SaveQuery: 'Uložit Dotaz',
    SaveQueryTooltip: 'Uložte tento dotaz do Operations',
    Download: 'Stáhnout Excel',
    DownloadTooltip: 'Stáhněte si obohacený seznam společností do svého zařízení',
    SaveList: 'Uložit seznam',
    SaveListTooltip: 'Uložte seznam těchto společností do portfolia'
  },
  SaveQuery: {
    DialogTitle: 'Uložit vyhledávání',
    Description:
      'Zadejte název, který chcete použít k uložení výzkumu, nebo vyberte existující dotaz, který chcete přepsat.',
    InputLabel: 'Název dotazu',
    InputPlaceholder: 'Název vyhledávání',
    UpdateExisting: 'Aktualizovat stávající',
    CreateNew: 'Vytvořit nový',
    SavedQuery: 'správně uložený.',
    SavedQueryDescription: 'Dotaz najdete uložený v úložišti souvisejícím s referenční oblastí.'
  },
  filters: {
    selectGroup: 'Vyberte skupinu',
    selectFilter: 'Vyberte filtr',
    booleanTrue: 'Pravda',
    booleanFalse: 'Nepravda',
    booleanEmpty: 'Není dostupné',
    searchText: 'Hledat text',
    operatorsEquals: 'Rovná se',
    operatorsNotEquals: 'Nerovná se',
    operatorsGreater: 'Větší než',
    operatorsLower: 'Menší než',
    operatorsGreaterOrEqual: 'Větší nebo rovné',
    operatorsLowerOrEqual: 'Menší nebo rovné',
    operatorsNotExists: 'Prázdné',
    operatorsExists: 'Neprázdné',
    operatorsBetween: 'Mezi',
    operatorsIsOneOf: 'Jeden z',
    operatorsIsNotOneOf: 'Není jeden z',
    operatorsIn: 'Jeden z',
    operatorsNotIn: 'Není jeden z',
    operatorsContains: 'Obsahuje',
    operatorsNotContains: 'Neobsahuje',
    fromDate: 'Datum od',
    toDate: 'Datum do',
    minValue: 'Minimální hodnota',
    maxValue: 'Maximální hodnota'
  },
  CustomizeQuery: {
    Title: 'Přizpůsobte svůj dotaz.',
    Message: 'Kliknutím na „Přidat pravidlo“ zahájíte průzkum. Vyberte operátory, vyberte příslušné filtry a potvrďte.'
  }
}
