import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ChevronRightIcon from '../../assets/icons/icon-s-chevron-right-generic.svg?react'
import ClearIcon from '../../assets/icons/close.svg?react'
import InfoIcon from '../../assets/icons/circle-info-solid.svg?react'
import { Claim } from '../../authorization'
import { PROSPECTING } from '../../routes'
import { ClaimsWrapper } from '../ClaimsWrapper'
import { IconButton, Divider, LinkMenuButton } from './StyledComponents'
import { isSemanticSearchFeatureEnabled } from '../../features/config/selectors'
import { DialogActions } from '../../features/dialog/actions'

type Props = {
  searchText: string
  toggleSearch: () => void
}

export const ExpandedSearchBar = ({ toggleSearch, searchText }: Props) => {
  const { t } = useTranslation('header')
  const dispatch = useDispatch()

  const semanticSearchEnabled = useSelector(isSemanticSearchFeatureEnabled)
  const [semanticSearchText, setSemanticSearchText] = useState(searchText)

  useEffect(() => {
    setSemanticSearchText(searchText)
  }, [searchText])

  const openInfoDialog = () => dispatch(DialogActions.openSearchInformationDialog())

  return (
    <>
      <IconButton onClick={openInfoDialog} data-testid="open-info-dialog">
        <InfoIcon />
      </IconButton>
      <IconButton onClick={toggleSearch} data-testid="close-search">
        <ClearIcon />
      </IconButton>
      {semanticSearchEnabled && (
        <ClaimsWrapper claims={Claim.Prospecting}>
          <Divider className="pc tablet" />
          <LinkMenuButton
            className="pc tablet"
            to={{
              pathname: PROSPECTING,
              search: window.location.search
            }}
            state={{
              shouldPreloadFilters: true,
              semanticPreFilled: semanticSearchText
            }}
          >
            {t('SemanticSearch')}
            <ChevronRightIcon height={16} width={16} />
          </LinkMenuButton>
        </ClaimsWrapper>
      )}
    </>
  )
}
