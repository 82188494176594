import React from 'react'
import { notification as notificationAntd } from 'antd'
import styled from 'styled-components/macro'
import CloseIcon from '../../../assets/icons/close.svg?react'

const Wrapper = styled.div`
  display: flex;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`

const CloseButton = styled.div`
  flex: 0 0 auto;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;

  & > svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`

type Props = {
  notificationPopupKey: string
  fallbackTheme?: any
  children: React.ReactNode
}

export const NotificationEventWrapper = ({ notificationPopupKey, fallbackTheme, children }: Props) => {
  return (
    <Wrapper>
      <ContentWrapper>{children}</ContentWrapper>
      <CloseButton theme={fallbackTheme} onClick={() => notificationAntd.destroy(notificationPopupKey)}>
        <CloseIcon />
      </CloseButton>
    </Wrapper>
  )
}
