/* eslint-disable import/no-default-export */
export default {
  ExcelFileType: 'Vybratý súbor je neplatný. Načítajte súbor Excel (s príponou .xls alebo .xlsx).',
  FileNotEmpty: 'Súbor nemôže byť prázdny.',
  Forbidden: 'Zakázané',
  ExcludeCustomVariablesError: 'Štruktúra vlastných premenných v tomto portfóliu nie je kompatibilná s pôvodnou.',
  Length: '„{{label}}“ musí mať od {{min}} do {{max}} znakov.',
  LengthLong: 'Maximálna dĺžka je {{max}} znakov',
  LengthShort: 'Minimálna dĺžka je {{min}} znakov',
  MissingClaims: 'Chýbajú vám nároky: {{claims}}.',
  NoSpecialCharacters: '„{{label}}“ nemôže obsahovať špeciálne znaky ',
  NoSpecialCharactersWoLabel: 'Pole nemôže obsahovať špeciálne znaky',
  MaxTagsCount: 'Maximálny počet poskytnutých značiek: 20',
  NotEmpty: 'Pole nemôže byť prázdne.',
  CannotStartWithSpace: 'Názov nemôže začínať medzerou',
  PortfolioCreationError:
    'Upozornenie! chyba pri vytváraní portfólia. Skontrolujte vybraný súbor a skúste nahrať znova.',
  PortfolioCreationFromListError: 'Upozornenie! Chyba pri vytváraní portfólia. Vložte zoznam ID a skúste nahrať znova.',
  PortfolioImportCompaniesLessThen:
    'Šablóna prekračuje maximálny povolený počet riadkov {{maxCompaniesCount}}, skontrolujte to a skúste to znova.',
  PortfolioImportCompaniesNotEmpty: 'Nahraný súbor je neplatný. Uistite sa, že obsahuje aspoň jednu spoločnosť.',
  PortfolioImportHasCustomVariableSheet: 'V nahranom súbore chýba hárok s vlastnými premennými.',
  PortfolioImportCustomVariableNames: 'Vlastné premenné nahrávaného súboru musia mať jedinečný názov.',
  PortfolioImportValidStructure:
    'Nahraný súbor je neplatný. Uistite sa, že použitá štruktúra šablón je rovnaká ako navrhovaná štruktúra.',
  Required: 'Pole "{{label}}" je povinné',
  RequiredWithoutFieldName: 'Pole je povinné',
  ServerError: 'Chyba servera',
  MinimumLength8Characters: 'Heslo musí mať dĺžku aspoň 8 znakov',
  MustContainLetter: 'Heslo musí obsahovať aspoň jedno písmeno a jednu číslicu',
  MustContainNumber: 'Heslo musí obsahovať aspoň jedno písmeno a jednu číslicu',
  MustContainSpecialCharacter: 'Heslo musí obsahovať aspoň jedno číslo a jeden špeciálny znak',
  CannotContainUsername: 'Heslo nesmie obsahovať meno používateľa',
  CannotBeSameAsOldPassword: 'Heslo nemôže byť rovnaké ako staré heslo',
  MustContainCapitalLetter: 'Heslo musí obsahovať veľké a malé písmená',
  MustContainLowercaseLetter: 'Heslo musí obsahovať veľké a malé písmená',
  CannotContainMoreThan2EqualCharactersInRow: 'Heslo musí obsahovať maximálne 3 rovnaké znaky po sebe',
  CannotContainMoreThan3EqualCharactersInRow: 'Heslo musí obsahovať maximálne 3 rovnaké znaky po sebe',
  CannotBeTheSameAsLast6Passwords: 'Heslo nesmie byť rovnaké ako šesť posledných použitých hesiel',
  CannotBeTheSameAsLast10Passwords: '',
  UniqueName: 'Existujúci názov. Prosím, zadajte iný názov.',
  Warning: 'Upozornenie',
  ForbiddenText: 'Prepáčte, nemáte oprávnenie k prístupu na túto stránku.',
  PortfolioDownloadError: 'Upozornenie! Chyba sťahovania portfólia. Prosím skúste znova.',
  PortfolioUpdateError:
    'Upozornenie! Chyba aktualizácie portfólia. Skontrolujte vybraný súbor a skúste aktualizovať znova.',
  PortfolioLockErrorTitle: 'Potvrdiť operáciu',
  PortfolioLockError: 'Operáciu nie je možné dokončiť, pretože v portfóliu beží iná akcia.',
  PdfReportLockErrorTitle: 'Potvrdiť operáciu',
  PdfReportLockError: 'Prebieha vytváranie súboru PDF pre spoločnosť {{companyName}}, počkajte na dokončenie operácie.',
  CouldNotValidatePortfolioName: 'Chyba servera. Nepodarilo sa overiť názov portfólia.',
  MinMax: 'Hodnota musí byť medzi {{min}} a {{max}}',
  OnlyIntegers: 'Povolené sú iba celé čísla.',
  ProspectingSaveListError: 'Upozornenie! Chyba uloženia zoznamu. Skontrolujte vybrané vstupy a skúste to znova.',
  NotEmail: 'Pole „{{label}}“ nemá platný formát e-mailu.',
  MustAgreeWithPrivacyPolicy: 'Musíte súhlasiť s pravidlami ochrany osobných údajov.',
  ContactError: 'Je nám ľúto, ale vašu správu sme nemohli spracovať.',
  ResetPasswordError: 'Je nám ľúto, ale nemohli sme resetovať vaše heslo.',
  DuplicatesNotAllowed: 'Duplikáty nie sú povolené.',
  CreateGoal: 'Je nám ľúto, ale nepodarilo sa nám vytvoriť váš cieľ.',
  SharePortfolio: 'Je nám ľúto, ale nemohli sme zdieľať portfólio.',
  OnlyNumbersOrSpace: '"{{label}}" môže obsahovať iba čísla alebo medzery',
  CampaignNameAlreadyExist: 'Názov existujúcej kampane. Prosím vyberte iný názov',
  CampaignNameTooShort: 'Názov kampane musí byť medzi 5 a 35 písmenami',
  CampaignNameTooLong: 'Názov kampane musí byť medzi 5 a 35 písmenami',
  CampaignEmpty: 'Názov kampane je povinný',
  CampaignInvalidName: 'Názov kampane nemôže obsahovať špeciálne znaky',
  UniqueGoalName: 'Názov cieľa už existuje. Prosím, zadajte iný názov.',
  UniqueTaskName: 'Existujúci názov. Zadajte iný názov.',
  StartCampaignError: 'Pri zastavovaní kampane sa vyskytla chyba. Skúste to neskôr prosím.',
  StopCampaignError: 'Pri spustení kampane sa vyskytla chyba. Skúste to neskôr prosím.',
  DuplicateCompanyError: 'Spoločnosť nie je možné duplikovať: status i produkt už boli priradené.',
  DeleteCampaign: 'Pri odstraňovaní kampane sa vyskytla chyba. Skúste to neskôr prosím.',
  ChangeCampaignName: 'Pri zmene názvu kampane sa vyskytla chyba. Skúste to neskôr prosím.',
  ChangeEndDate: 'Pri zmene dátumu ukončenia sa vyskytla chyba. Skúste to neskôr prosím.',
  ChangeStartDate: 'Pri zmene dátumu začiatku sa vyskytla chyba. Skúste to neskôr prosím.',
  ChangeCampaignNote: 'Pri zmene poznámky sa vyskytla chyba. Skúste neskôr to prosím.',
  AddCompanyNote: 'Pri pridávaní poznámky sa vyskytla chyba. Skúste to neskôr prosím.',
  DeleteCompanyNote: 'Pri odstraňovaní poznámky sa vyskytla chyba. Skúste to neskôr prosím.',
  FetchCompanyNotes: 'Pri načítavaní poznámok sa vyskytla chyba. Skúste to neskôr prosím.',
  AssignTask: 'Pri prideľovaní vybratej úlohy sa vyskytla chyba. Skúste to neskôr prosím.',
  CampaignDownloadError: 'Pri sťahovaní súboru Excel sa vyskytla chyba. Skúste to neskôr prosím.',
  RenewCampaign: 'Obnovenie tejto kampane nie je možné, pretože neobsahuje platné portfólio.',
  AssignCampaignError: 'Pri ukladaní vašich úloh sa vyskytla chyba. Skúste to neskôr prosím.',
  AssignCampaignErrorCompaniesDuplicated:
    'Pri ukladaní vašich úloh sa vyskytla chyba. Existuje jedna alebo viac duplikovaných spoločností.',
  FetchCampaigns: 'Pri načítaní kampaní sa vyskytla chyba. Skúste to neskôr prosím.',
  SaveCampaign: 'Pri ukladaní kampane sa vyskytla chyba. Skúste to neskôr prosím.',
  DuringCampaignCreation:
    'Vyskytla sa chyba a nie je možné dokončiť proces vytvárania kampane. Skúste to neskôr prosím.',
  FetchCompanies: 'Pri načítaní spoločností sa vyskytla chyba.',
  EnrichCompany: 'Je nám ľúto, ale obohatenie spoločností zlyhalo.',
  NotFound: {
    Title: 'Nenájdené',
    Text: 'Ľutujeme, stránka, ktorú ste navštívili, neexistuje.',
    BackHome: 'Späť domov',
    DocumentNotFound: 'Dokument už nie je k dispozícii.',
    PortfolioNotFound: 'Portfólio už nie je k dispozícii.',
    CampaignNotFound: 'Kampaň už nie je k dispozícii.',
    GoBack: 'Späť',
    QueryNotFound: 'Dopyt nie je k dispozícii.',
    ReportLoadFailed: 'Report nie je k dispozícii alebo neexistuje.',
    Section: 'Sekcia nie je k dispozícii',
    SectionForCountry: 'Táto sekcia nie je k dispozícii pre vašu krajinu ({{country}}) ',
    EnrichmentNotPossible: 'Obohacovanie nie je možné.',
    EnrichmentNotPossibleBody:
      'Spoločnosť, ktorú hľadáte, nie je možné obohatiť, pretože môže patriť do portfólia skrytého iným používateľom vašej organizácie.',
    ReportNotAvailable: 'Správa nie je k dispozícii.',
    ReportNotAvailableBody:
      'Firma, ktorú hľadáte, nie je prístupná, pretože môže patriť do portfólia skrytého iným používateľom vo vašej organizácii.'
  },
  ErrorPage: {
    Text: 'Vyskytla sa neočakávaná chyba.'
  },
  ConfigGuardErrorPage: {
    Title: 'Ospravedlňujeme sa',
    Text: 'Konfiguráciu aplikácie sa nepodarilo načítať.',
    Reload: 'Znova načítať konfiguráciu ',
    Logout: 'Odhlásiť sa'
  },
  CreateProduct: 'Je nám ľúto, ale nemohli sme vytvoriť váš produkt.',
  UniqueProductName: 'Názov produktu už existuje. Prosím, zadajte iný názov.',
  PasswordSameAsOld: 'Heslo nemôže byť rovnaké ako staré heslo',
  PasswordCouldNotBeChanged: 'Heslo nebolo možné zmeniť. Skontrolujte prosím, či heslo spĺňa pravidlá.',
  InvalidOldPassword: 'Zadané heslo je nesprávne.',
  UsernameDoesMatch: 'Zosobnené užívateľské meno je rovnaké ako užívateľské meno',
  PasswordContainsUsername: 'Heslo nemôže obsahovať užívateľské meno ',
  PasswordCharacterAndDigit: 'Heslo musí obsahovať najmenej jedno písmeno a jednu číslicu',
  PasswordMultipleCharacters: 'Heslo nemôže obsahovať tri po sebe nasledujúce rovnaké znaky',
  PasswordDoesNotMatch: 'Heslo sa nezhoduje',
  DisallowedPassword: 'Heslo nie je povolené ',
  SelectAtLeastOneCompany: 'Musíte vybrať aspoň jednu spoločnosť.',
  TargetingLoadPortfolio: 'Chyba pri načítaní vybraného portfólia',
  TargetingLoadPortfolioDescription: 'Prosím, skúste to znova neskôr, inak vybrané portfólio nemusí byť platné.',
  TargetingLoadQuery: 'Chyba pri načítaní vybraného dopytu',
  TargetingLoadQueryDescription: 'Prosím, skúste to znova neskôr, inak vybraný dopyt nemusí byť platný.',
  TargetingDownloadAnalysisError: 'Vyskytla sa chyba a vašu analýzu nie je možné stiahnuť.',
  GenericMessage: 'Pri spracovaní vašeho dopytu sa vyskytla chyba.',
  LoginSE: 'Vyskytol sa problém s prihlásením, skúste to znova neskôr.',
  TooManyWordsInFilter:
    'Bol prekročený maximálny počet 1 000 slov, ktoré je možné zadať súčasne ({{wordCount}} / 1 000)',
  NoEmptyValues: 'Dotaz nesmie obsahovať prázdne hodnoty.',
  MinValueIsRequired: '"Min" hodnota je povinná',
  MaxValueIsRequired: '"Max" hodnota je povinná',
  MatchingTermDoesNotExist: '{{matchingTerm}} neexistuje',
  CountryRequired: 'Prosím, zvoľte krajinu',
  R_24: 'Aktualizácia spoločnosti by spôsobila duplicitu. Spoločnosť s rovnakým "Subjekt v portfóliu" a "Produkt" už v portfóliu existuje.',
  R_5: 'Maximálny počet značiek prekročil povolený limit 20.',
  DataLoadError: 'Nepodarilo sa načítať údaje. Skúste neskôr prosím.',
  SaveChangesFailed: 'Pri ukladaní zmien sa vyskytla chyba. Skúste neskôr prosím.',
  PortfolioImportCustomVariableMaxLabelLimit: 'Menovka vlastných premenných nemôže obsahovať viac ako 256 znakov.',
  FailedToLoadTermsOfUse: 'Nie je možné načítať podmienky používania, skúste to neskôr znova.'
}
