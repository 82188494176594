/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Rendez-vous',
  CreateAppointmentButton: 'Créer un nouveau rendez-vous',
  ManageAppointmentsButton: 'Gérer rendez-vous',
  EditAppointment: 'Modifier le rendez-vous',
  SendEmailLabel: 'Envoyer les détails à mon calendrier et notifier les destinataires',
  ForHeadquarterButton: 'Par siège social',
  ForLocalBranchButton: 'Pour cette unité locale',
  AppointmentDetails: {
    Title: 'Insérez le nom, la date et le lieu liés à votre nouveau rendez-vous',
    EditTitle: 'Modifiez le nom, la date et le lieu liés à votre rendez-vous',
    MatchingTermIta: 'Code fiscal/code TVA',
    MatchingTermDeu: 'Code fiscal/code TVA',
    MatchingTermChe: "ID de l'entreprise / CRIF Id",
    MatchingTermAut: 'N° de TVA  / Company book number',
    MatchingTermCheAut: "ID de l'entreprise / CRIF Id / N° de TVA  / Company book number",
    MatchingTermCzsk: 'Code fiscal/code TVA',
    MatchingTermFareast: 'Code fiscal/code TVA',
    Name: 'Nom du rendez-vous',
    Description: 'Descriptif du rendez-vous',
    Place: 'Place',
    StartDate: 'Date de début',
    EndDate: 'Date de fin',
    SelectColor: 'Sélectionnez une couleur'
  },
  CreateAppointment: {
    Title: 'Créer un rendez-vous',
    ShareAppointmentStep: {
      Title: 'Sélectionnez les destinataires',
      Text: "Sélectionnez les utilisateurs auxquels vous souhaitez transférer le rappel ou saisissez l'adresse e-mail des destinataires, séparée par des virgules",
      Email: 'Adresses e-mail, séparées par des virgules'
    },
    ConfirmationStep: {
      Title: "Confirmer l'opération",
      Text: "Voulez-vous envoyer ce rendez-vous à votre calendrier ? En sélectionnant ON : Margò vous enverra un e-mail contenant toutes les informations que vous venez d'ajouter"
    }
  },
  DeleteAppointment: {
    Title: 'Supprimez les rendez-vous',
    SubTitle: 'Voulez-vous vraiment supprimer le rendez-vous?'
  },
  AppointmentsList: {
    EmptyText: 'Vous y trouverez tous les rendez-vous enregistrés dans votre agenda.',
    NameColumn: 'Nom',
    StartColumn: 'Début',
    EndColumn: 'Fin',
    ShowExpired: 'Afficher expirés',
    All: 'Tous'
  },
  Colors: {
    Green: 'Vert',
    Yellow: 'Jaune',
    Orange: 'Orange',
    Red: 'Rouge',
    Violet: 'Violet',
    Blue: 'Bleu',
    LightBlue: 'Bleu clair',
    Turquoise: 'Turquoise',
    Pink: 'Rose',
    Black: 'Noir'
  }
}
