import _ from 'lodash'
import React from 'react'

export const EventListeners = () => {
  const copyListener = (e: ClipboardEvent) => {
    const clipboardText = window.getSelection()?.toString()
    const trimmedText = _.trimStart(clipboardText)
    if (!_.isEmpty(trimmedText)) {
      e.clipboardData?.setData('text', trimmedText)
      e.preventDefault()
    }
  }

  React.useEffect(() => {
    document.addEventListener('copy', copyListener)

    return () => document.removeEventListener('copy', copyListener)
  }, [])

  return null
}
