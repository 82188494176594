import { DialogActions } from '@features/dialog/actions'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'
import { ContactViewType } from '@pages/Contact/ContactDialog/types'
import { Text } from '../Text'

const commonStyle = css`
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`

const ClickHereTextStyled = styled(Text)`
  ${commonStyle}

  text-decoration: underline;
`

const TooltipTextStyled = styled(Text)`
  ${commonStyle}

  :hover {
    text-decoration: underline;
  }
`

export const LockedFeatureTooltipContent = () => {
  const dispatch = useDispatch()

  return (
    <TooltipTextStyled
      onClick={() => dispatch(DialogActions.showDialogWithData('Contact', { state: ContactViewType.INIT }))}
    >
      <Trans i18nKey="common:Freemium.UnlockFeature" components={[<ClickHereTextStyled key="click-here" />]} />
    </TooltipTextStyled>
  )
}
