import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getUserDashboard } from './api'
import { DocumentType } from '../../features/documents/types'
import { mapUserDashboardResponse } from '../../features/mappers'
import { cacheAllTheTime } from '../../features/queryclient'
import { getUserId } from '../../features/user/selectors'
import { getDocuments } from '../documents'

export const getDashboardKeys = {
  All: () => [{ level1: 'dashboard' }] as const,
  GetUserDashboard: () => [{ ...getDashboardKeys.All()[0], level2: 'getUserDashboard' }] as const,
  GetInfiniteDocuments: () => [{ ...getDashboardKeys.All()[0], level2: 'getInfiniteDocuments' }] as const,
  GetInfiniteDocumentsWithParams: (userId: string, top: number, filterTypes: DocumentType) =>
    [{ ...getDashboardKeys.GetInfiniteDocuments()[0], userId, top, filterTypes }] as const
}

export const useGetUserDashboard = (enabled?: boolean) => {
  return useQuery({
    queryKey: getDashboardKeys.GetUserDashboard(),
    queryFn: getUserDashboard,
    select: data => {
      return mapUserDashboardResponse(data)
    },
    enabled,
    ...cacheAllTheTime
  })
}

export const useGetInfiniteDocuments = (top: number) => {
  const userId = useSelector(getUserId)

  return useInfiniteQuery({
    queryKey: getDashboardKeys.GetInfiniteDocumentsWithParams(userId, top, DocumentType.ExportedPortfolio),
    queryFn: ({ queryKey: [queryKeys], pageParam }) =>
      getDocuments({
        'filter.userId': queryKeys.userId,
        top: queryKeys.top,
        skip: pageParam,
        'filter.types': queryKeys.filterTypes
      }),
    initialPageParam: 0,
    getNextPageParam: (lastResp, allPages) => {
      const count = _.sum(_.map(allPages, 'items.length'))
      return lastResp?.totalCount <= count ? undefined : count
    },
    ...cacheAllTheTime
  })
}
