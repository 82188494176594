import { call, put } from 'redux-saga/effects'
import { LoadingActionParametrized, LoadingActionSimple } from './actions'

export function withLoadingParametrized<TSaga extends (...args: any[]) => Generator, TParam extends object>(
  loadingAction: LoadingActionParametrized<TParam>,
  handler: TSaga,
  createParams: (...args: Parameters<TSaga>) => TParam
) {
  return function* loader(...args: Parameters<TSaga>) {
    const params: ReturnType<typeof createParams> = yield call(createParams, ...args)
    try {
      const loadAction: ReturnType<typeof loadingAction> = yield call(loadingAction, true, params)
      yield put(loadAction)
      yield call(handler, ...args)
    } finally {
      yield put(loadingAction(false, params))
    }
  }
}

export function withLoading<TSaga extends (...args: any[]) => Generator>(
  loadingAction: LoadingActionSimple,
  handler: TSaga
) {
  return function* loader(...args: Parameters<TSaga>) {
    try {
      yield put(loadingAction(true))
      yield call(handler, ...args)
    } finally {
      yield put(loadingAction(false))
    }
  }
}
