import React, { useCallback } from 'react'
import styled from 'styled-components/macro'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { media } from 'styled-bootstrap-grid'
import { useNavigate } from 'react-router-dom'
import { Notification } from '../../features/notifications/types'
import { notificationActionUrl, notificationActionCallback } from '../../utils/notifications'
import ChevronRight from '../../assets/icons/chevron-right.svg?react'
import { Type, colorByType } from '../../utils/theme'

export { ChevronRight }

export const NavigationButton = styled(ChevronRight)<{ type?: Type }>`
  flex: 0 0 auto;
  align-self: center;
  fill: ${({ theme, type }) => colorByType(theme, type)};
  cursor: pointer;
  height: 24px;
`

export const NavigationButtonContainer = styled.div`
  min-height: 100%;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: transparent;

  ${media.lg`
    background-color: transparent;
  `}
`

type NotificationsProps = {
  notification: Notification
  type?: Type
  onRead?: (notificationId: string) => void
  className?: string
}

export const NotificationAction = ({ notification, type, onRead = _.noop, className }: NotificationsProps) => {
  const route = notificationActionUrl(notification)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onClickCallback = useCallback(() => {
    if (!notification.read) onRead(notification.id)
    notificationActionCallback(notification, dispatch, navigate, route)
  }, [notification, onRead, dispatch, navigate, route])

  if (route) {
    return (
      <NavigationButtonContainer onClick={onClickCallback}>
        <NavigationButton className={className} type={type} role="button" aria-label="notification action" />
      </NavigationButtonContainer>
    )
  }
  return null
}
