/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Schôdzka',
  CreateAppointmentButton: 'Vytvoriť novú schôdzku',
  ManageAppointmentsButton: 'Riadiť schôdzku',
  EditAppointment: 'Upraviť schôdzku',
  SendEmailLabel: 'Odoslať podrobnosti do môjho kalendára a upozorniť príjemcov',
  ForHeadquarterButton: 'Pre sídlo spoločnosti',
  ForLocalBranchButton: 'Pre túto miestnu jednotku',
  AppointmentDetails: {
    Title: 'Zadajte meno, dátum a miesto súvisiace s vašou novou schôdzkou',
    EditTitle: 'Upravte meno, dátum a miesto súvisiace s vašou schôdzkou',
    MatchingTermIta: 'Daňový kód/DIČ',
    MatchingTermDeu: 'Daňový kód/DIČ',
    MatchingTermChe: 'Daňový kód/DIČ',
    MatchingTermAut: 'Daňový kód/DIČ',
    MatchingTermCheAut: 'Daňový kód/DIČ',
    MatchingTermCzsk: 'Daňový kód/DIČ',
    MatchingTermFareast: 'Daňový kód/DIČ',
    Name: 'Názov schôdzky',
    Description: 'Popis schôdzky',
    Place: 'Miesto',
    StartDate: 'Dátum a čas začiatku',
    EndDate: 'Dátum konca',
    SelectColor: 'Vyberte farbu'
  },
  CreateAppointment: {
    Title: 'Vytvorte schôdzku',
    ShareAppointmentStep: {
      Title: 'Vyberte príjemcov',
      Text: 'Vyberte používateľov, ktorým chcete pripomienku preposlať, alebo zadajte e-mailové adresy príjemcov oddelené čiarkami',
      Email: 'E-mailové adresy oddelené čiarkami'
    },
    ConfirmationStep: {
      Title: 'Potvrďte operáciu',
      Text: 'Chcete poslať túto schôdzku do svojho kalendára? Výberom možnosti ON: Margò vám pošle e-mail, ktorý bude obsahovať všetky informácie, ktoré ste práve pridali'
    }
  },
  AppointmentsList: {
    EmptyText: 'Tu nájdete všetky schôdzky uložené vo vašom kalendári.',
    NameColumn: 'Názov',
    StartColumn: 'Začiatok',
    EndColumn: 'Koniec',
    ShowExpired: 'Zobraziť exspirované',
    All: 'Všetky'
  },
  Colors: {
    Green: 'Zelená',
    Yellow: 'Žltá',
    Orange: 'Oranžová',
    Red: 'Červená',
    Violet: 'Fialová',
    Blue: 'Modrá',
    LightBlue: 'Svetlo modrá',
    Turquoise: 'Tyrkysová',
    Pink: 'Ružová',
    Black: 'Čierna'
  }
}
