export const PORTFOLIO_MANAGEMENT_MODULE = '/portfolio-management'
export const TARGETING_MODULE = '/targeting'
export const PROSPECTING_MODULE = '/prospecting'
export const SALESTOOL_MODULE = '/salestool'
export const ACCOUNT_MODULE = '/account'
export const API_MODULE = '/api'
export const OPERATIONS_MODULE = '/operations'
export const PNRR_MODULE = '/pnrr'
export const APPOINTMENT_MODULE = '/appointment'

// Global
export const DASHBOARD = '/'
export const LOGIN = '/login'
export const SEARCH = '/search'
export const FORBIDDEN = '/forbidden'
export const NOT_FOUND = '/not-found'
export const ERROR = '/error'
export const NOTIFICATIONS_ARCHIVE = '/notifications-archive'
export const CONTACT = '/contact'
export const RESET_PASS = '/reset-password'
export const REFRESH_ROUTE = '/refresh'
export const PERSON_REPORT = '/person-report/:personId'
export const PRIVACY_POLICY = '/privacy-policy'
export const COOKIES_POLICY = '/cookie-policy'
export const IMPRINT_POLICY = '/impressum'
export const TERMS_OF_USE = '/terms-of-use'

// Company report
export const COMPANY_REPORT = '/company-report/:identificationCode/:companyUnitId'

export const COMPANY_REPORT_SUMMARY_RELATIVE = '/summary'
export const COMPANY_REPORT_SUMMARY = `${COMPANY_REPORT}${COMPANY_REPORT_SUMMARY_RELATIVE}`

export const COMPANY_REPORT_SALES_TRANSACTION_RELATIVE = '/sales-transaction'
export const COMPANY_REPORT_SALES_TRANSACTION = `${COMPANY_REPORT}${COMPANY_REPORT_SALES_TRANSACTION_RELATIVE}`

export const COMPANY_REPORT_PORTFOLIO_RELATIVE = '/portfolio'
export const COMPANY_REPORT_PORTFOLIO = `${COMPANY_REPORT}${COMPANY_REPORT_PORTFOLIO_RELATIVE}`

export const COMPANY_REPORT_MYCONTACTS_RELATIVE = '/mycontacts'
export const COMPANY_REPORT_MYCONTACTS = `${COMPANY_REPORT}${COMPANY_REPORT_MYCONTACTS_RELATIVE}`

export const COMPANY_REPORT_APPOINTMENTS_RELATIVE = '/appointments'
export const COMPANY_REPORT_APPOINTMENTS = `${COMPANY_REPORT}${COMPANY_REPORT_APPOINTMENTS_RELATIVE}`

export const COMPANY_REPORT_FINANCIAL_STATEMENT_RELATIVE = '/financial-statement'
export const COMPANY_REPORT_FINANCIAL_STATEMENT = `${COMPANY_REPORT}${COMPANY_REPORT_FINANCIAL_STATEMENT_RELATIVE}`

export const COMPANY_REPORT_CONTACTS_AND_SOCIAL_RELATIVE = '/contacts-and-social'
export const COMPANY_REPORT_CONTACTS_AND_SOCIAL = `${COMPANY_REPORT}${COMPANY_REPORT_CONTACTS_AND_SOCIAL_RELATIVE}`

export const COMPANY_REPORT_CONTACTS_AND_NEWS_RELATIVE = '/contacts-and-news'
export const COMPANY_REPORT_CONTACTS_AND_NEWS = `${COMPANY_REPORT}${COMPANY_REPORT_CONTACTS_AND_NEWS_RELATIVE}`

export const COMPANY_REPORT_CONTACTS_RELATIVE = '/contacts'
export const COMPANY_REPORT_CONTACTS = `${COMPANY_REPORT}${COMPANY_REPORT_CONTACTS_RELATIVE}`

export const COMPANY_REPORT_ANALYTICS_RELATIVE = '/analytics'
export const COMPANY_REPORT_ANALYTICS = `${COMPANY_REPORT}${COMPANY_REPORT_ANALYTICS_RELATIVE}`

export const COMPANY_REPORT_MASTERCARD_RELATIVE = '/mastercard'
export const COMPANY_REPORT_MASTERCARD = `${COMPANY_REPORT}${COMPANY_REPORT_MASTERCARD_RELATIVE}`

export const COMPANY_REPORT_SCORE_RELATIVE = '/score'
export const COMPANY_REPORT_SCORE = `${COMPANY_REPORT}${COMPANY_REPORT_SCORE_RELATIVE}`

export const COMPANY_CUSTOM_INDEX_RELATIVE = '/custom-index'
export const COMPANY_CUSTOM_INDEX = `${COMPANY_REPORT}${COMPANY_CUSTOM_INDEX_RELATIVE}`

export const COMPANY_REPORT_CADASTRAL_DATA_RELATIVE = '/cadastral-data'
export const COMPANY_REPORT_CADASTRAL_DATA = `${COMPANY_REPORT}${COMPANY_REPORT_CADASTRAL_DATA_RELATIVE}`

export const COMPANY_REPORT_LOCAL_UNITS_RELATIVE = '/local-units'
export const COMPANY_REPORT_LOCAL_UNITS = `${COMPANY_REPORT}${COMPANY_REPORT_LOCAL_UNITS_RELATIVE}`

export const COMPANY_REPORT_PNRR_RELATIVE = '/pnrr'
export const COMPANY_REPORT_PNRR = `${COMPANY_REPORT}${COMPANY_REPORT_PNRR_RELATIVE}`

export const COMPANY_REPORT_ESG_RELATIVE = '/esg'
export const COMPANY_REPORT_ESG = `${COMPANY_REPORT}${COMPANY_REPORT_ESG_RELATIVE}`

// Company report People
export const COMPANY_REPORT_PEOPLE_RELATIVE = '/people'
export const COMPANY_REPORT_PEOPLE = `${COMPANY_REPORT}${COMPANY_REPORT_PEOPLE_RELATIVE}`

export const COMPANY_REPORT_PEOPLE_SHARE_RELATIVE = '/shareholders'
export const COMPANY_REPORT_PEOPLE_SHARE = `${COMPANY_REPORT_PEOPLE}${COMPANY_REPORT_PEOPLE_SHARE_RELATIVE}`

export const COMPANY_REPORT_PEOPLE_OWNERS_RELATIVE = '/owners'
export const COMPANY_REPORT_PEOPLE_OWNERS = `${COMPANY_REPORT_PEOPLE}${COMPANY_REPORT_PEOPLE_OWNERS_RELATIVE}`

export const COMPANY_REPORT_RELATED_COMPANIES_RELATIVE = '/related-companies'
export const COMPANY_REPORT_RELATED_COMPANIES = `${COMPANY_REPORT_PEOPLE}${COMPANY_REPORT_RELATED_COMPANIES_RELATIVE}`

export const COMPANY_REPORT_PEOPLE_PARTNERS_RELATIVE = '/partners'
export const COMPANY_REPORT_PEOPLE_PARTNERS = `${COMPANY_REPORT_PEOPLE}${COMPANY_REPORT_PEOPLE_PARTNERS_RELATIVE}`

export const COMPANY_REPORT_PEOPLE_COMPANIES_OWNERSHIP_RELATIVE = '/companies-ownership-interests'
export const COMPANY_REPORT_PEOPLE_COMPANIES_OWNERSHIP = `${COMPANY_REPORT_PEOPLE}${COMPANY_REPORT_PEOPLE_COMPANIES_OWNERSHIP_RELATIVE}`

// Company report Decision Makers Shareholders
export const COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS_RELATIVE = '/decision-makers-shareholders'
export const COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS = `${COMPANY_REPORT}${COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS_RELATIVE}`

export const COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS_PERSON_SHAREHOLDERS_RELATIVE = '/shareholder-persons'
export const COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS_PERSON_SHAREHOLDERS = `${COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS}${COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS_PERSON_SHAREHOLDERS_RELATIVE}`

export const COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS_COMPANY_SHAREHOLDERS_RELATIVE = '/shareholder-companies'
export const COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS_COMPANY_SHAREHOLDERS = `${COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS}${COMPANY_REPORT_DECISION_MAKERS_SHAREHOLDERS_COMPANY_SHAREHOLDERS_RELATIVE}`

// Portfolio Management
export const PORTFOLIO_MANAGEMENT = PORTFOLIO_MANAGEMENT_MODULE

export const PORTFOLIO_RELATIVE = '/portfolio/:portfolioId'
export const PORTFOLIO = `${PORTFOLIO_MANAGEMENT_MODULE}${PORTFOLIO_RELATIVE}`

export const ADD_PORTFOLIO_RELATIVE = '/add-portfolio'
export const ADD_PORTFOLIO = `${PORTFOLIO_MANAGEMENT_MODULE}${ADD_PORTFOLIO_RELATIVE}`

export const ADD_PORTFOLIO_STANDARD_RELATIVE = 'standard'
export const ADD_PORTFOLIO_STANDARD = `${PORTFOLIO_MANAGEMENT_MODULE}${ADD_PORTFOLIO_RELATIVE}/${ADD_PORTFOLIO_STANDARD_RELATIVE}`

export const ADD_PORTFOLIO_SIMPLIFIED_RELATIVE = 'simplified'
export const ADD_PORTFOLIO_SIMPLIFIED = `${PORTFOLIO_MANAGEMENT_MODULE}${ADD_PORTFOLIO_RELATIVE}/${ADD_PORTFOLIO_SIMPLIFIED_RELATIVE}`

export const MANAGE_UPLOAD_ERRORS_RELATIVE = '/manage-upload-errors'
export const MANAGE_UPLOAD_ERRORS = `${PORTFOLIO_MANAGEMENT_MODULE}${MANAGE_UPLOAD_ERRORS_RELATIVE}`

// Targeting
export const TARGETING = TARGETING_MODULE

// Prospecting
export const PROSPECTING = PROSPECTING_MODULE

// Account management
export const ACCOUNT = ACCOUNT_MODULE

export const ACCOUNT_LOGIN_AS_RELATIVE = '/login-as'
export const ACCOUNT_LOGIN_AS = `${ACCOUNT_MODULE}${ACCOUNT_LOGIN_AS_RELATIVE}`

export const ACCOUNT_QUERY_DETAIL_RELATIVE = '/query/:queryId/:queryType'
export const ACCOUNT_QUERY_DETAIL = `${ACCOUNT_MODULE}${ACCOUNT_QUERY_DETAIL_RELATIVE}`

export const ACCOUNT_QUERY_FROM_PORTFOLIO_DETAIL_RELATIVE = '/query/:queryId/:queryType/:portfolioId'
export const ACCOUNT_QUERY_FROM_PORTFOLIO_DETAIL = `${ACCOUNT_MODULE}${ACCOUNT_QUERY_FROM_PORTFOLIO_DETAIL_RELATIVE}`

export const ACCOUNT_CUSTOM_TRACK_DETAIL_RELATIVE = '/custom-track/:customTrackId/'
export const ACCOUNT_CUSTOM_TRACK_DETAIL = `${ACCOUNT_MODULE}${ACCOUNT_CUSTOM_TRACK_DETAIL_RELATIVE}`

export const ACCOUNT_CONFIGURATION_DETAIL_RELATIVE = '/configuration/:configurationId/:queryType'
export const ACCOUNT_CONFIGURATION_DETAIL = `${ACCOUNT_MODULE}${ACCOUNT_CONFIGURATION_DETAIL_RELATIVE}`

export const ACCOUNT_CONFIGURATION_EDIT_RELATIVE = `${ACCOUNT_CONFIGURATION_DETAIL_RELATIVE}/edit`
export const ACCOUNT_CONFIGURATION_EDIT = `${ACCOUNT_MODULE}${ACCOUNT_CONFIGURATION_EDIT_RELATIVE}`

export const ACCOUNT_PORTFOLIO_MANAGEMENT_RELATIVE = '/portfolio-management/'
export const ACCOUNT_PORTFOLIO_MANAGEMENT = `${ACCOUNT_MODULE}${ACCOUNT_PORTFOLIO_MANAGEMENT_RELATIVE}`

export const ACCOUNT_TARGETING_RELATIVE = '/targeting/'
export const ACCOUNT_TARGETING = `${ACCOUNT_MODULE}${ACCOUNT_TARGETING_RELATIVE}`

export const ACCOUNT_PROSPECTING_RELATIVE = '/prospecting/'
export const ACCOUNT_PROSPECTING = `${ACCOUNT_MODULE}${ACCOUNT_PROSPECTING_RELATIVE}`

export const ACCOUNT_OPERATIONS_RELATIVE = '/operations/'
export const ACCOUNT_OPERATIONS = `${ACCOUNT_MODULE}${ACCOUNT_OPERATIONS_RELATIVE}`

export const ACCOUNT_PRODUCTS_RELATIVE = '/products/'
export const ACCOUNT_PRODUCTS = `${ACCOUNT_MODULE}${ACCOUNT_PRODUCTS_RELATIVE}`

export const ACCOUNT_GOALS_TASKS_RELATIVE = '/goal-task/'
export const ACCOUNT_GOALS_TASKS = `${ACCOUNT_MODULE}${ACCOUNT_GOALS_TASKS_RELATIVE}`

export const ACCOUNT_RELEASE_NOTES_RELATIVE = '/release-notes'
export const ACCOUNT_RELEASE_NOTES = `${ACCOUNT_MODULE}${ACCOUNT_RELEASE_NOTES_RELATIVE}`

export const ACCOUNT_CONFIGURATION_RELATIVE = '/configuration'
export const ACCOUNT_CONFIGURATION = `${ACCOUNT_MODULE}${ACCOUNT_CONFIGURATION_RELATIVE}`

export const ACCOUNT_APPOINTMENTS_RELATIVE = '/appointments'
export const ACCOUNT_APPOINTMENTS = `${ACCOUNT_MODULE}${ACCOUNT_APPOINTMENTS_RELATIVE}`

export const ACCOUNT_CONTACTS_RELATIVE = '/contacts'
export const ACCOUNT_CONTACTS = `${ACCOUNT_MODULE}${ACCOUNT_CONTACTS_RELATIVE}`

// Sales tool
export const SALESTOOL = SALESTOOL_MODULE

export const ADD_CAMPAIGN_RELATIVE = '/add-campaign'
export const ADD_CAMPAIGN = `${SALESTOOL_MODULE}${ADD_CAMPAIGN_RELATIVE}`

export const SALESTOOL_CAMPAIGN_DETAIL_RELATIVE = '/campaign/:campaignId'
export const SALESTOOL_CAMPAIGN_DETAIL = `${SALESTOOL_MODULE}${SALESTOOL_CAMPAIGN_DETAIL_RELATIVE}`

export const SALESTOOL_CAMPAIGN_ASSIGN_RELATIVE = `${SALESTOOL_CAMPAIGN_DETAIL_RELATIVE}/assignments`
export const SALESTOOL_CAMPAIGN_ASSIGN = `${SALESTOOL_MODULE}${SALESTOOL_CAMPAIGN_ASSIGN_RELATIVE}`

export const SALESTOOL_CAMPAIGN_ARCHIVE_RELATIVE = '/campaign-archive'
export const SALESTOOL_CAMPAIGN_ARCHIVE = `${SALESTOOL_MODULE}${SALESTOOL_CAMPAIGN_ARCHIVE_RELATIVE}`

export const SALESTOOL_SALE_DETAIL_RELATIVE = '/sales/:salesId'
export const SALESTOOL_SALE_DETAIL = `${SALESTOOL_MODULE}${SALESTOOL_SALE_DETAIL_RELATIVE}`

export const SALESTOOL_SALE_CAMPAIGN_DETAIL_RELATIVE = `${SALESTOOL_SALE_DETAIL_RELATIVE}/campaign/:campaignId`
export const SALESTOOL_SALE_CAMPAIGN_DETAIL = `${SALESTOOL_MODULE}${SALESTOOL_SALE_CAMPAIGN_DETAIL_RELATIVE}`

// Operations
export const OPERATIONS = OPERATIONS_MODULE

// Pnrr
export const PNRR = PNRR_MODULE

export const PNRR_INVESTMENT_WIZARD_RELATIVE = '/investment'
export const PNRR_INVESTMENT_WIZARD = `${PNRR_MODULE}${PNRR_INVESTMENT_WIZARD_RELATIVE}`

// API
export const API = API_MODULE

// Appointment
export const APPOINTMENT_DETAIL_RELATIVE = '/appointment/:appointmentId'
export const APPOINTMENT_DETAIL = `${APPOINTMENT_MODULE}${APPOINTMENT_DETAIL_RELATIVE}`

export const APPOINTMENT_EDIT_RELATIVE = '/appointment/:appointmentId/edit'
export const APPOINTMENT_EDIT = `${APPOINTMENT_MODULE}${APPOINTMENT_EDIT_RELATIVE}`

export const APPOINTMENT_CREATE_RELATIVE = '/add-appointment'
export const APPOINTMENT_CREATE = `${APPOINTMENT_MODULE}${APPOINTMENT_CREATE_RELATIVE}`

// Contacts
export const CONTACTS = '/contacts'
export const ADD_CONTACT_RELATIVE = '/add-contact'
export const ADD_CONTACT = `${CONTACTS}${ADD_CONTACT_RELATIVE}`

export const getParentRoute = (route: string) => `${route}/*`
