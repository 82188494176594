import { Reducer } from 'redux'
import _ from 'lodash'
import { AggregationField, TargetingCompanyStatusFilter, COUNT_OF_COMPANIES } from '../../api/targeting/types'
import { TargetingFilter, DownloadType } from './types'
import * as actions from './actions'
import { Dictionary } from '../../utils/types'

export const initialTargetingFilters: Dictionary<TargetingFilter> = {}

export type LoadedQueryTargeting = {
  queryId: string
  queryName: string
  hasValidPortfolio: boolean
}
export type TargetingState = Readonly<{
  aggregationField: AggregationField
  targetingFilters: Dictionary<TargetingFilter>
  loadedQuery?: LoadedQueryTargeting
  download: DownloadType
  statusCompanyFilter: TargetingCompanyStatusFilter
}>

export const initState: TargetingState = {
  aggregationField: COUNT_OF_COMPANIES,
  targetingFilters: {},
  statusCompanyFilter: TargetingCompanyStatusFilter.totalCompanies,
  download: null
}

export const targetingReducer: Reducer<TargetingState, actions.TargetingActions> = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_ACTIVE_FILTERS: {
      const { fieldId } = action.payload // The single filter
      return {
        ...state,
        targetingFilters: {
          ...state.targetingFilters,
          [fieldId]: action.payload
        }
      }
    }
    case actions.UNSET_ACTIVE_FILTER:
      return {
        ...state,
        targetingFilters: _.omit(state.targetingFilters, action.payload)
      }
    case actions.SET_AGGREGATION_FIELD:
      return {
        ...state,
        aggregationField: action.payload
      }
    case actions.SET_LOADED_QUERY: {
      const { payload: query } = action
      return {
        ...state,
        loadedQuery: query
          ? { queryId: query.queryId, queryName: query.queryName, hasValidPortfolio: query.hasValidPortfolio }
          : undefined
      }
    }
    case actions.SET_DOWNLOAD:
      return {
        ...state,
        download: action.payload
      }
    case actions.SET_TARGETING_TABULAR_FILTER:
      return {
        ...state,
        statusCompanyFilter: action.payload.filter
      }
    default:
      return state
  }
}
