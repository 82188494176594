export enum LocalStorageKeys {
  MONITORED_PORTFOLIO_LAST_VIEW_DATE = 'persist:monitoredPortfolioLastViewDate',
  HAS_RINGS_WARNING = 'persist:hasRingsWarningPerUser',
  RECENT_RESEARCHES = 'persist:recentResearches',
  USER = 'persist:user',
  CONFIG = 'persist:config',
  CAMPAIGN_CREATION_DATA = 'persist:campaignCreationData',
  CAMPAIGN_RECEIVED_KEY = 'persist:campaignReceived',
  ASSISTANT_AI_DATA = 'persist:assistantAiData',
  PREVIOUS_PATH = 'persist:previousPath'
}

export enum StorageType {
  Local,
  Session
}

export enum StorageArea {
  Prospecting = 'prospecting',
  PortfolioManagement = 'portfolioManagement',
  Targeting = 'targeting'
}

export type PersisterOptions = {
  key: string
  storageType?: StorageType
}

export type PersisterOptionsWithArea = {
  area: StorageArea
  key: string
  storageType?: StorageType
}
