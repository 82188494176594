import React from 'react'
import { RatingWrapper, ButtonRow, RateButton } from './styled'
import { RatingComponentProps } from '../types'

const RatingComponent: React.FC<RatingComponentProps> = ({ onRate }) => {
  const calculateColor = (index: number): string => {
    const red = Math.round(255 - index * 25.5)
    const green = Math.round(index * 25.5)

    const pastelFactor = 0.7
    const pastelRed = Math.round(red + (255 - red) * pastelFactor)
    const pastelGreen = Math.round(green + (255 - green) * pastelFactor)

    return `rgb(${pastelRed}, ${pastelGreen}, ${255 * pastelFactor})`
  }

  return (
    <RatingWrapper>
      <ButtonRow>
        {Array.from({ length: 10 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <RateButton color={calculateColor(index)} key={index} onClick={() => onRate(index + 1)}>
            {index + 1}
          </RateButton>
        ))}
      </ButtonRow>
    </RatingWrapper>
  )
}

// eslint-disable-next-line import/no-default-export
export default RatingComponent
