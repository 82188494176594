import React from 'react'
import styled from 'styled-components/macro'
import { Title } from '../Text'
import { Box } from '../Box'
import { FullPageDialog } from '../Dialog'
import CloseIcon from '../../assets/icons/icon-s-close-normal.svg?react'
import { StyledVerticalScrollWrapper } from '../StyledScrollWrapper'

export const FullPageTitleWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  flex: 0 0 auto;
`

export const TitleStyled = styled(Title)`
  flex: 1 1 auto;
  text-align: center;
`

const DrawerContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  max-height: 100vh;

  & {
    .ant-card-head {
      flex: 0 0 auto;
    }

    .ant-card-body {
      display: flex;
      flex: 1 1 auto;
      overflow: hidden;
    }
  }
`

type Props = {
  open: boolean
  fullPageDrawerTitle: string
  drawerContent: React.ReactNode
  onToggle: (isOpen: boolean) => void
  extra: React.ReactNode
}

export const FullPageDrawer = ({ open, fullPageDrawerTitle, drawerContent, extra, onToggle }: Props) => (
  <FullPageDialog visible={open} hideBodyPadding>
    <DrawerContentWrapper
      title={
        <FullPageTitleWrapper>
          <TitleStyled level={3} ellipsis>
            {fullPageDrawerTitle}
          </TitleStyled>
          <CloseIconStyled onClick={() => onToggle(!open)} />
        </FullPageTitleWrapper>
      }
    >
      <StyledVerticalScrollWrapper
        useFlex
        customTrackProps={{
          trackWidth: 8,
          trackSpacing: 7,
          maxThumbSizePercentage: 0.2,
          thumbColor: theme => theme.colors.dustyBlue,
          trackColor: theme => theme.colors.almostWhite
        }}
      >
        {extra}
        {drawerContent}
      </StyledVerticalScrollWrapper>
    </DrawerContentWrapper>
  </FullPageDialog>
)
