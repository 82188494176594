/* eslint-disable react/destructuring-assignment */
import _ from 'lodash'
import { Reducer } from 'redux'
import * as actions from './actions'
import * as filterGroup from './case-functions/filtersGroups'
import { FilterReducerPath, FilterState, FilterStateWrapper, ReducerWithFiltering } from './types'

export const initState: FilterStateWrapper = {
  filters: {
    selectingFilters: undefined,
    selectedFilterGroups: {}
  }
}

const setFilters = (state: FilterStateWrapper, newObject: Partial<FilterState>): FilterStateWrapper => ({
  ...state,
  filters: {
    ...state.filters,
    ...newObject
  }
})

export const withFiltering =
  <T>(
    reducer: Reducer,
    reducerPath: FilterReducerPath,
    wrappedReducerInit: T
  ): Reducer<ReducerWithFiltering<T>, actions.FiltersActions> =>
  (state = { ...initState, ...wrappedReducerInit }, action) => {
    const { payload } = action
    if (
      payload &&
      (_.get(payload, 'reducer') === reducerPath || _.get(payload, 'originalAction.payload.reducer') === reducerPath)
    ) {
      switch (action.type) {
        case actions.SET_FILTER_SELECTION: {
          return setFilters(state, {
            selectingFilters: action.payload.groupId
          })
        }
        case actions.SET_SELECTED_FILTER_GROUP: {
          return setFilters(state, filterGroup.getSetSelected(action.payload, state.filters))
        }
        case actions.RESET_SELECTED_FILTER_GROUPS: {
          const { selectedFilterGroups } = initState.filters
          const { userId, entityId } = action.payload
          return setFilters(state, filterGroup.getResetSelected(state.filters, selectedFilterGroups, userId, entityId))
        }
        case actions.RESET: {
          const newState = reducer(state, action)
          return {
            ...newState,
            filters: {
              ...newState.filters
            }
          }
        }
        default:
          return reducer(state, action)
      }
    }
    return reducer(state, action)
  }
