import React from 'react'
import { Dropdown, DropDownProps } from 'antd'
import styled from 'styled-components/macro'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'
import { Omit } from '../../utils/types'
import { IconButton, IconButtonType, IconButtonSize } from './IconButton'
import DotsIcon from '../../assets/icons/icon-s-more-horizontal.svg?react'
import { SvgComponent } from '../../types/common'

const MenuButtonWrapper = styled.div`
  svg {
    vertical-align: middle;
    cursor: pointer;
  }
`

type Props = {
  type?: IconButtonType
  size?: IconButtonSize
  style?: React.CSSProperties
  customIcon?: SvgComponent
  items?: MenuItemType[]
  dataTestId?: string
} & Omit<DropDownProps, 'overlay'>

export const MenuButton = ({ type, size, style, items, dataTestId, customIcon: CustomIcon, ...rest }: Props) => (
  <MenuButtonWrapper style={style}>
    <Dropdown trigger={['click']} placement="bottomRight" {...rest} menu={{ items }}>
      <IconButton type={type} size={size} aria-label="menu-button" data-testid={dataTestId}>
        {CustomIcon ? <CustomIcon /> : <DotsIcon />}
      </IconButton>
    </Dropdown>
  </MenuButtonWrapper>
)
