// eslint-disable-next-line import/no-default-export
export default {
  Close: 'Zavrieť',
  Popup: {
    TaxCode: 'IČO',
    BirthDate: 'Dátum narodenia',
    BirthTown: 'Mesto narodenia',
    Enriched: 'Obohatený',
    Enrich: 'Obohatiť'
  },
  NotEnriched: {
    Title: 'Ak chcete odomknúť túto sekciu, zakúpte si údaje spoločnosti {{companyName}}',
    Message: 'Túto sekciu môžete odomknúť zakúpením údajov spoločnosti {{companyName}}',
    Action: 'Odomknúť údaje spoločnosti {{companyName}}'
  },
  IdentificationData: {
    Title: 'Identifikačné údaje',
    TaxCode: 'IČO',
    BirthDate: 'Dátum narodenia',
    BirthPlace: 'Miesto narodenia',
    BirthCountry: 'Krajina narodenia',
    Sex: 'Pohlavie',
    Address: 'Adresa'
  },
  Positions: {
    Title: 'Aktívne a bývalé pozície',
    ActivePositions: {
      Title: 'Aktívne pozície'
    },
    PastPositions: {
      Title: 'Minulé pozície'
    },
    From: 'Od',
    To: 'Do'
  },
  PartnersList: {
    Title: 'Zoznam partnerov',
    Amount: 'Suma'
  },
  Enrich: {
    Title: 'Obohatiť osobu',
    Desc: 'Obohatením osoby o údaje spoločnosti {{companyName}} sa tieto informácie stiahnu a budú sa pravidelne aktualizovať',
    Success: 'Obohatenie bolo úspešné',
    CreditsWillBeUsed: 'Použité jednotky',
    CreditsRemaining: 'Zostávajúce jednotky',
    ProposalFailed: 'Vyskytol sa problém s kontrolou dostupných kreditov pre túto operáciu.'
  }
}
