import { Reducer } from 'redux'
import _ from 'lodash'
import * as actions from './actions'
import { Query } from './types'
import { updateRecord } from '../../utils/helpers'

export type QueryState = Readonly<{
  queries: Record<string, Query>
}>

export const initState: QueryState = {
  queries: {}
}

export const queryReducer: Reducer<QueryState, actions.QueryActions> = (state = initState, action) => {
  switch (action.type) {
    case actions.REMOVE_QUERIES_BY_TYPE:
      return {
        ...state,
        queries: _.omitBy(state.queries, { type: action.payload.type })
      }
    case actions.SET_QUERIES:
      return {
        ...state,
        queries: updateRecord(state.queries, action.payload.queries, 'id')
      }
    case actions.SET_QUERY:
      return {
        ...state,
        queries: {
          ...state.queries,
          [action.payload.query.id]: action.payload.query
        }
      }
    default:
      return state
  }
}
