import axios from 'axios'
import {
  FilterStructureResponse,
  FilterStructureModuleRequest,
  FilterDefinitionsRequest,
  FilterResponseDto,
  KeyTreeResponse,
  FilterQueryDto,
  LocalUnitsFilterInfo,
  DomainNodesDataNotEmptyRequest,
  DomainNodeDataNotEmptyResponse
} from './types'

export const getFiltersStructure = (structureModule: FilterStructureModuleRequest, entityId?: string) =>
  axios.post<FilterStructureResponse>('/api/common/get-filters-structure', {
    structureModule,
    entityId
  })

export const getFiltersDefinitions = (data: FilterDefinitionsRequest) =>
  axios.post<FilterResponseDto>('/api/common/get-filters-definitions', data).then(r => r.data)

export const getDomainCodesTree = (domainTypes: string[], entityId?: string, module?: FilterStructureModuleRequest) =>
  axios.post<KeyTreeResponse>('/api/common/domain-codes-tree', {
    domainTypes,
    entityId,
    structureModule: module
  })

export const getLocalUnitsFilterInfo = () => axios.get<LocalUnitsFilterInfo>('/api/common/local-units-settings')

export const getPreloadedQuery = (module: FilterStructureModuleRequest) => {
  switch (module) {
    case FilterStructureModuleRequest.SalesTool:
      return axios.get<FilterQueryDto>('/api/Common/get-sales-tool-preloaded-filter-query')
    default:
      return axios.get<FilterQueryDto>('/api/Common/get-prospecting-preloaded-filter-query')
  }
}
export const getDomainNodesDataNotEmpty = (data: DomainNodesDataNotEmptyRequest) =>
  axios.post<DomainNodeDataNotEmptyResponse>('/api/common/domain-nodes-data-not-empty', data).then(resp => resp.data)
