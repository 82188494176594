/* eslint-disable import/no-default-export */
export default {
  LogoutPrimaryText: 'Zadajte svoje prihlasovacie údaje pre prístup k službe',
  LogoutSecondaryText: 'Pridajte informácie o sebe a svojej pozícii v spoločnosti, ktorej ste súčasťou',
  PortfolioManagement: 'Správa portfólia',
  Targeting: 'Analýza trhu',
  Prospecting: 'Výber klientov',
  SalesTool: 'Obchodné aktivity',
  API: 'API',
  Operations: 'Operácie',
  SemanticSearch: 'Prejsť na sémantické vyhľadávanie',
  FreeSearchPlaceholderWithCampaigns: 'Vyhľadajte spoločnosť, portfólio alebo kampaň podľa mena',
  FreeSearchPlaceholder: 'Vyhľadajte spoločnosť alebo portfólio podľa mena',
  SearchErrorTooLong: 'Maximálny počet znakov: {{maxLimit}}',
  SearchErrorTooShort: 'Prosím zadaj aspoň {{minLimit}} znaky',
  FeedbackArea:
    'Oblasť notifikácií: vo vnútri nájdete svoje posledné dokončené akcie alebo môžete skontrolovať priebeh prebiehajúcich akcií',
  Profile: 'Profil',
  Notification: {
    Title: 'Oznámenie',
    ViewAll: 'Zobraziť všetko'
  },
  Appointments: 'Schôdzky'
}
