/* eslint-disable import/no-default-export */
export default {
  ExcelFileType:
    'Die ausgewählte Datei ist ungültig. Bitte verwenden Sie eine Excel-Datei (.xls oder .xlsx Erweiterung).',
  FileNotEmpty: 'Datei darf nicht leer sein.',
  Forbidden: 'Verboten',
  ExcludeCustomVariablesError:
    'Die Struktur der benutzerdefinierten Variablen in diesem Portfolio ist nicht mit der ursprünglichen kompatibel.',
  Length: 'Der "{{label}}" muss zwischen {{min}} und {{max}} Zeichen liegen.',
  LengthLong: 'Die maximale Länge beträgt {{max}} Zeichen',
  LengthShort: 'Die Mindestlänge beträgt {{min}} Zeichen',
  MissingClaims: 'Ihnen fehlen Ansprüche: {{claims}}.',
  NoSpecialCharacters: '"{{label}}" darf keine Sonderzeichen enthalten',
  NoSpecialCharactersWoLabel: 'Das Feld darf keine Sonderzeichen enthalten',
  MaxTagsCount: 'Maximale Anzahl bereitgestellter Tags: 20',
  NotEmpty: 'Feld darf nicht leer sein.',
  CannotStartWithSpace: 'Name darf nicht mit Leerzeichen beginnen',
  PortfolioCreationError: 'Ladefehler. Überprüfen Sie die ausgewählte Datei und versuchen Sie erneut, sie hochzuladen.',
  PortfolioCreationFromListError:
    'Warnung! Fehler bei der Portfolioerstellung. Fügen Sie die IDs-Liste ein und versuchen Sie erneut, sie hochzuladen.',
  PortfolioImportCompaniesLessThen:
    'Die hochgeladene Datei überschreitet das Maximum von {{maxCompaniesCount}} Zeilen. Wir empfehlen, die Datei in kleinere Einheiten aufzuteilen, und diese einzelnen Dateien hochzuladen. Danke.',
  PortfolioImportCompaniesNotEmpty:
    'Die hochgeladene Datei ist ungültig. Stellen Sie sicher, dass mindestens eine Firma enthalten ist.',
  PortfolioImportHasCustomVariableSheet: 'Das Blatt für benutzerdefinierte Variablen fehlt in der hochgeladenen Datei.',
  PortfolioImportCustomVariableNames:
    'Die benutzerdefinierten Variablen der hochgeladenen Datei müssen eindeutige Bezeichnungsnamen enthalten.',
  PortfolioImportValidStructure:
    'Die ausgewählte Datei ist ungültig. Bitte stellen Sie sicher, dass das bereitgestellte Template verwendet wird.',
  Required: 'Das Feld "{{label}}" ist ein Pflichtfeld',
  RequiredWithoutFieldName: 'Das Feld ist ein Pflichtfeld',
  ServerError: 'Serverfehler',
  MinimumLength8Characters: 'Das Passwort muss mindestens 8 Zeichen lang sein',
  MustContainLetter: 'Das Passwort muss mindestens einen Buchstaben und eine Zahl enthalten',
  MustContainNumber: 'Das Passwort muss mindestens einen Buchstaben und eine Zahl enthalten',
  CannotContainUsername: 'Das Passwort darf nicht die Benutzer-ID enthalten',
  CannotBeSameAsOldPassword: 'Das Passwort kann nicht mit dem alten Passwort identisch sein',
  MustContainCapitalLetter: 'Das Passwort muss mindestens einen Großbuchstaben enthalten',
  CannotContainMoreThan2EqualCharactersInRow:
    'Das Passwort darf nicht mehr als 3 aufeinanderfolgende identische Zeichen enthalten',
  CannotContainMoreThan3EqualCharactersInRow:
    'Das Passwort darf nicht mehr als 3 aufeinanderfolgende identische Zeichen enthalten',
  MaximumLength25Characters: 'Die Mindestlänge des Passworts beträgt 8 Zeichen und die maximale Länge 25 Zeichen',
  CannotBeTheSameAsLast6Passwords:
    'Das Passwort darf nicht mit den letzten sechs verwendeten Passwörtern identisch sein',
  UniqueName: 'Name existiert bereits. Bitte wählen Sie einen anderen Namen.',
  Warning: 'Hinweis',
  ForbiddenText: 'Sie sind leider nicht berechtigt, auf diese Seite zuzugreifen.',
  PortfolioDownloadError: 'Warnung! Portfolio-Download-Fehler. Bitte versuche es erneut.',
  PortfolioUpdateError:
    'Warnung! Portfolio-Aktualisierungsfehler. Überprüfen Sie die ausgewählte Datei und versuchen Sie es erneut.',
  PortfolioLockErrorTitle: 'Bestätige die Operation',
  PortfolioLockError:
    'Die Operation kann nicht abgeschlossen werden, da eine andere Aktion im Portfolio ausgeführt wird.',
  CouldNotValidatePortfolioName: 'Serverfehler. Portfolio-Name konnte nicht überprüft werden.',
  MinMax: 'Der Wert muss zwischen {{min}} und {{max}} liegen',
  OnlyIntegers: 'Es sind nur ganze Zahlen erlaubt.',
  ProspectingSaveListError:
    'Warnung! Fehler beim Speichern der Liste. Bitte überprüfen Sie die Eingabe und versuchen es erneut.',
  NotEmail: 'Das Feld "{{label}}" hat kein gültiges E-Mail-Format.',
  MustAgreeWithPrivacyPolicy: 'Sie müssen den Datenschutzbestimmungen zustimmen.',
  ContactError: 'Es tut uns leid, wir konnten Ihre Nachricht nicht verarbeiten.',
  ResetPasswordError: 'Es tut uns leid, wir konnten Ihr Passwort nicht zurücksetzen.',
  DuplicatesNotAllowed: 'Duplikate sind nicht erlaubt.',
  CreateGoal: 'Es tut uns leid, wir konnten Ihre Ziel nicht erstellen.',
  OnlyNumbersOrSpace: 'Das "{{label}}" darf nur Zahlen und Leerzeichen enthalten',
  CampaignNameAlreadyExist: 'Eine gleichnamige Kampagne existiert bereits.',
  CampaignNameTooShort: 'Der Kampagnenname ist zu kurz.',
  CampaignEmpty: 'Der Kampagnenname darf nicht leer sein',
  UniqueGoalName: 'Name bereits vergeben. Bitte geben Sie einen anderen Namen für das Ziel an.',
  UniqueTaskName: 'Bestehender Name. Bitte geben Sie einen anderen Namen an.',
  StartCampaignError: 'Beim Beenden der Kampagne ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  StopCampaignError: 'Beim Starten der Kampagne ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  DuplicateCompanyError:
    'Das Unternehmen kann nicht dupliziert werden: Sowohl der Status als auch das Produkt wurden bereits zugewiesen.',
  DeleteCampaign: 'Beim Löschen der Kampagne ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  ChangeCampaignName:
    'Beim Ändern des Kampagnennamens ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  ChangeEndDate: 'Beim Ändern des Enddatums ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  ChangeStartDate: 'An error occurred while changing the start date. Please try again later.',
  ChangeCampaignNote: 'Beim Ändern der Notiz ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  AddCompanyNote: 'Beim Hinzufügen der Notiz ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  DeleteCompanyNote: 'Beim Löschen der Notiz ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  FetchCompanyNotes: 'Beim Abrufen der Notizen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  AssignTask:
    'Beim Zuweisen der ausgewählten Aufgabe ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  CampaignDownloadError:
    'Beim Herunterladen der Exceldatei ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut .',
  RenewCampaign: 'Eine Erneuerung dieser Kampagne ist nicht möglich, da sie kein gültiges Portfolio enthält.',
  AssignCampaignError:
    'Beim Speichern Ihrer Aufgaben ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  AssignCampaignErrorCompaniesDuplicated:
    'Beim Speichern Ihrer Aufgaben ist ein Fehler aufgetreten. Es sind ein oder mehrere Unternehmen doppelt vorhanden.',
  FetchCampaigns: 'Beim Abrufen der Kampagnen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  SaveCampaign: 'Beim Speichern der Kampagne ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  DuringCampaignCreation:
    'Es ist ein Fehler aufgetreten und es ist nicht möglich, die Erstellung der Kampagne abzuschließen. Bitte versuchen Sie es später erneut.',
  FetchCompanies: 'Beim Abrufen der Unternehmen ist ein Fehler aufgetreten.',
  EnrichCompany: 'Es tut uns leid, aber die Bereicherung der Unternehmen ist gescheitert.',
  NotFound: {
    Title: 'Nicht gefunden',
    Text: 'Die von Ihnen besuchte Seite existiert leider nicht.',
    BackHome: 'Zurück zur Startseite',
    DocumentNotFound: 'Dokument ist nicht mehr verfügbar.',
    PortfolioNotFound: 'Portfolio ist nicht mehr verfügbar.',
    CampaignNotFound: 'Kampagne ist nicht mehr verfügbar.',
    GoBack: 'Zurück',
    QueryNotFound: 'Abfrage ist nicht verfügbar.',
    ReportLoadFailed: 'Bericht ist nicht verfügbar oder existiert nicht.',
    Section: 'Abschnitt ist nicht verfügbar',
    SectionForCountry: 'Dieser Abschnitt ist für Ihr Land nicht verfügbar ({{country}})',
    EnrichmentNotPossible: 'Eine Anreicherung ist nicht möglich.',
    EnrichmentNotPossibleBody:
      'Das gesuchte Unternehmen ist nicht anreicherbar, da es möglicherweise zu einem Portfolio gehört, das von einem anderen Benutzer Ihrer Organisation ausgeblendet wurde.',
    ReportNotAvailable: 'Bericht nicht verfügbar.',
    ReportNotAvailableBody:
      'Auf das gesuchte Unternehmen kann nicht zugegriffen werden, da es möglicherweise zu einer Brieftasche gehört, die von einem anderen Benutzer in Ihrer Organisation ausgeblendet wurde.'
  },
  ErrorPage: {
    Text: 'Ein unerwarteter Fehler ist aufgetreten.'
  },
  ConfigGuardErrorPage: {
    Title: 'Es tut uns leid',
    Text: 'Applikation konnte nicht geladen werden.',
    Reload: 'Konfiguration neu laden',
    Logout: 'Logout'
  },
  CreateProduct: 'Es tut uns leid, wir konnten Ihr Produkt nicht erstellen.',
  UniqueProductName: 'Bestehender Produktname. Bitte geben Sie einen anderen Namen an.',
  PasswordSameAsOld: 'Das Passwort kann nicht mit dem alten Passwort identisch sein',
  InvalidOldPassword: 'Passwort ist falsch.',
  PasswordContainsUsername: 'Passwort darf keinen Benutzernamen enthalten',
  PasswordCharacterAndDigit: 'Das Passwort muss mindestens einen Buchstaben und eine Ziffer enthalten',
  PasswordMultipleCharacters: 'Das Passwort darf nicht die drei aufeinanderfolgenden gleichen Zeichen enthalten',
  PasswordDoesNotMatch: 'Passwort stimmt nicht überein',
  DisallowedPassword: 'Passwort ist nicht erlaubt',
  SelectAtLeastOneCompany: 'Sie müssen mindestens ein Unternehmen auswählen.',
  GenericMessage: 'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten.',
  LoginSE: 'Es liegt ein Problem mit der Anmeldung vor. Versuchen Sie es später erneut.',
  FailedToLoadImprintPolicy: 'Impressum kann nicht geladen werden, bitte versuchen Sie es später erneut. ',
  FailedToLoadTermsOfUse: 'Nutzungsbedingungen können nicht geladen werden, bitte versuchen Sie es später erneut.',
  NoEmptyValues: 'Die Abfrage darf keine leeren Werte enthalten.',
  MinValueIsRequired: '"Min"-Wert ist erforderlich',
  MaxValueIsRequired: '"Max"-Wert ist erforderlich',
  MatchingTermDoesNotExist: '{{matchingTerm}} existiert nicht',
  CountryRequired: 'Bitte Land auswählen',
  R_24: 'Die Aktualisierung des Unternehmens würde zu Duplizität führen. Unternehmen mit demselben „Objekt im Portfolio“ und „Produkt“ existiert bereits im Portfolio.',
  R_5: 'Die maximale Anzahl von Tags hat das zulässige Limit von 20 überschritten.',
  DataLoadError: 'Daten konnten nicht geladen werden. Bitte versuchen Sie es später erneut.',
  SaveChangesFailed: 'Beim Speichern der Änderungen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
  PortfolioImportCustomVariableMaxLabelLimit:
    'Die Bezeichnung der benutzerdefinierten Variablen darf nicht mehr als 256 Zeichen enthalten.',
  InvalidPhone: 'Ungültiges Telefonnummerformat'
}
