import React, { forwardRef, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { md, lg } from '../../utils/helpers'
import { containerWidthMixin } from './common'

const DrawerContainerStyled = styled.div`
  width: 100%;
  display: flex;
`

const DrawerPlaceholder = styled.div<{ open: boolean }>`
  position: absolute;
  height: 100%;
  ${lg`
    position: relative;
    height: auto;
  `}
  flex: 0 0 auto;
  margin-right: 20px;
  transition: width ${({ theme }) => theme.animation.duration};
  width: ${({ open }) => (open ? 'auto' : '0 !important')};
  ${containerWidthMixin}
`

const ContentWrapper = styled.div<{ open: boolean }>`
  position: relative;
  flex: 1 1 100%;
  ${md`
    margin-left: 60px;
  `}
  z-index: 1;
  overflow: hidden;
  ${({ open }) => lg`
    margin-left: ${open ? '20px' : '40px'};
    z-index: auto;
  `}
`

const ContentOverlay = styled.div<{ open: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.4;
  z-index: 1;
  display: ${({ open }) => (open ? 'block' : 'none')};
  ${lg`
    display: none;
  `}
`

type Props = {
  // ref: any
  open: boolean
  leftDrawer: ReactNode
  rightDrawer: ReactNode
  children: ReactNode
  onToggle: (isOpen: boolean) => void
}

// eslint-disable-next-line react/display-name
export const DrawerHolder = forwardRef(({ open, leftDrawer, rightDrawer, children, onToggle }: Props, ref: any) => (
  <DrawerContainerStyled>
    {leftDrawer}
    <DrawerPlaceholder ref={ref} open={open} />
    <ContentWrapper open={open}>
      {children}
      <ContentOverlay open={open} onClick={() => onToggle(false)} />
    </ContentWrapper>
    {rightDrawer}
  </DrawerContainerStyled>
))
