/* eslint-disable import/no-default-export */
export default {
  UserName: 'Username',
  InsertUserName: 'Insert your username',
  Email: 'Email',
  InsertEmail: 'Insert your email',
  ResetPassword: 'Reset Password',
  Success: 'Your password was succesfully reseted. We sent you a new one into your mail box.',
  Propmt:
    'Enter the User Name and email address to which your account is associated. You will be sent the password to access the service in mail box you indicated.'
}
