import _ from 'lodash'
import { SalestoolSelectionUnit } from '../../../api/salestool/types'
import { Dictionary } from '../../../utils/types'
import { SalestoolAssignmentsViewType } from '../../salestoolAssignmentsPage/types'
import { CampaignCompany } from '../types'
import {
  Assignment,
  AssignmentType,
  CompaniesListRow,
  AssignmentsKeys,
  AssignmentsData,
  CompanyStatusDict,
  AssignmentData,
  AssignmentItemsData,
  AssignmentsDataWithStatus
} from './types'

export const WILDCARD = '*'
export const NO_STATUS = 'other'

const getAssignmentsOnly = (assignment: Assignment) => _.omit(assignment, 'entityId')

const isAssigned = (fields: Dictionary<any>) => !!_.get(fields, AssignmentsKeys.assignee)

export const getAssignment = (
  assignments: Dictionary<Assignment>,
  ignoreWildcard: boolean, // If a company is ignored, it should not take the current wildcard assignments when missing its own
  entityId: string,
  type: AssignmentType,
  value: any
): Assignment => {
  const currentAssignment = assignments[entityId] || (!ignoreWildcard && assignments[WILDCARD]) || {}
  return {
    ...currentAssignment,
    entityId,
    [type]: value
  }
}

export const getUpdatedData = (
  currentRows: CompaniesListRow[],
  currentlySelectedRows: string[],
  isSelectAllActive: boolean,
  isSale: boolean,
  companies: CampaignCompany[]
) => {
  const companiesAssignmentStatus: CompanyStatusDict = {}
  const selectedRows: string[] = []

  const rows = _.map<CampaignCompany, CompaniesListRow>(companies, item => {
    if (
      (isSelectAllActive && !currentRows.some(row => row.entityId === item.entityId)) ||
      currentlySelectedRows.includes(item.entityId)
    ) {
      selectedRows.push(item.entityId)
    }
    companiesAssignmentStatus[item.entityId] = isSale || isAssigned(item.fields) ? 'assigned' : 'notAssigned'
    return {
      ...item,
      entityId: item.entityId,
      companyUnitId: item.companyUnitId,
      identificationCode: item.identificationCode,
      notes: item.notes,
      campaignCompanyId: item.campaignCompanyId,
      companyName: item.companyName
    }
  })
  return { selectedRows, rows, companiesAssignmentStatus }
}

export const getItemsToIgnore = (visibleCompanies: CompaniesListRow[], assignments: Dictionary<Assignment | string>) =>
  _.reduce(
    visibleCompanies,
    (acc: SalestoolSelectionUnit[], { entityId, companyUnitId }) =>
      !assignments[entityId] ? [...acc, { entityId, companyUnitId }] : acc,
    []
  )

export const formatAssignmentsForRequest = (assignments: Dictionary<Assignment>) => {
  if (_.isEmpty(assignments)) {
    return []
  }

  const wildcardAssignment = assignments[WILDCARD]

  if (!wildcardAssignment) {
    return _.values(assignments)
  }

  // Merge assignments to wildcard, if possible
  const assignmentsForRequest = _.reject(_.omit(assignments, WILDCARD), assignment => {
    const currentAssignments = getAssignmentsOnly(assignment)
    const wildcardAssignments = getAssignmentsOnly(wildcardAssignment)
    return _.isEqual(currentAssignments, wildcardAssignments)
  })

  return [...assignmentsForRequest, wildcardAssignment]
}

export const getItemsToExclude = (
  visibleCompanies: CompaniesListRow[],
  assignments: Dictionary<Assignment | string>
) => {
  const itemsToExclude = _.reduce(
    visibleCompanies,
    (acc: Dictionary<SalestoolSelectionUnit>, { entityId, companyUnitId }) =>
      !assignments[entityId] ? { ...acc, [entityId]: { entityId, companyUnitId } } : acc,
    {}
  )
  return !_.isEmpty(itemsToExclude) ? itemsToExclude : undefined
}

export const updateItemsToExclude = (itemsToExclude: Dictionary<SalestoolSelectionUnit> = {}, entityId: string) => {
  const updatedItemsToIgnore = { ...itemsToExclude }
  _.unset(updatedItemsToIgnore, entityId)
  return !_.isEmpty(updatedItemsToIgnore) ? updatedItemsToIgnore : undefined
}

export const isAllCompaniesTab = (tab: SalestoolAssignmentsViewType | undefined) =>
  tab === SalestoolAssignmentsViewType.ALL

export const updateAssignmentsDataSingle = (
  { assignments, itemsToIgnore }: AssignmentsData[string],
  entityId: string,
  type: AssignmentType,
  value: string
): AssignmentData => {
  const ignoreWildcard = !!_.get(itemsToIgnore, entityId)
  return {
    assignments: {
      ...assignments,
      [entityId]: getAssignment(assignments, ignoreWildcard, entityId, type, value)
    },
    itemsToIgnore: updateItemsToExclude(itemsToIgnore, entityId)
  }
}

const updateAssignmentsByStatus = (
  selectedItems: string[],
  currentAssignmentsByStatus: AssignmentsData,
  companiesStatusDict: CompanyStatusDict,
  type: AssignmentType,
  value: string
) =>
  _.reduce(
    selectedItems,
    (acc, entityId) => {
      const status = companiesStatusDict[entityId]
      const { assignments, itemsToIgnore } = acc[status]
      const ignoreWildcard = !!_.get(itemsToIgnore, entityId)
      return {
        ...acc,
        [status]: {
          assignments: {
            ...assignments,
            [entityId]: getAssignment(assignments, ignoreWildcard, entityId, type, value)
          },
          itemsToIgnore: updateItemsToExclude(itemsToIgnore, entityId)
        }
      }
    },
    currentAssignmentsByStatus
  )

const getWildcardAssignment = (assignments: Dictionary<Assignment>, type: AssignmentType, value: string) => ({
  ...(assignments[WILDCARD] || {}),
  entityId: WILDCARD,
  [type]: value
})

const validStatuses: SalestoolAssignmentsViewType[] = [
  SalestoolAssignmentsViewType.ASSIGNED,
  SalestoolAssignmentsViewType.NOT_ASSIGNED
]

const getWildcardAndItemsToIgnore = ({
  currentStatus,
  isSelectAllActive,
  currentAssignmentsByStatus,
  companiesStatusDict,
  visibleCompanies,
  type,
  value
}: AssignmentItemsData) => {
  const updatedAssignmentsData = { ...currentAssignmentsByStatus }
  const filterVisibleCompanies = (status: SalestoolAssignmentsViewType) =>
    _.filter(visibleCompanies, ({ entityId }) => companiesStatusDict[entityId] === status)

  if (isAllCompaniesTab(currentStatus)) {
    // Write to the appropriate key based on company status (assigned | notAssigned); ignore all status
    _.forEach(validStatuses, status => {
      const currentData = updatedAssignmentsData[status]
      const { assignments, itemsToIgnore } = currentData
      updatedAssignmentsData[status] = {
        assignments: {
          ...assignments,
          ...(isSelectAllActive && { [WILDCARD]: getWildcardAssignment(assignments, type, value) })
        },
        itemsToIgnore: isSelectAllActive
          ? getItemsToExclude(filterVisibleCompanies(status), assignments)
          : itemsToIgnore
      }
    })
  } else {
    // The key to write to is the same as the active tab
    const status = currentStatus
    const currentData = updatedAssignmentsData[status]
    const { assignments, itemsToIgnore } = currentData
    updatedAssignmentsData[status] = {
      assignments: {
        ...assignments,
        ...(isSelectAllActive && { [WILDCARD]: getWildcardAssignment(assignments, type, value) })
      },
      itemsToIgnore: isSelectAllActive ? getItemsToExclude(filterVisibleCompanies(status), assignments) : itemsToIgnore
    }
  }

  return updatedAssignmentsData
}

export const updateAssignmentsDataWithStatus = ({
  currentStatus,
  isSelectAllActive,
  selectedItems,
  visibleCompanies,
  currentAssignmentsByStatus,
  companiesStatusDict,
  type,
  value
}: AssignmentsDataWithStatus) => {
  const updatedAssignments = updateAssignmentsByStatus(
    selectedItems,
    currentAssignmentsByStatus,
    companiesStatusDict,
    type,
    value
  )

  const fullUpdatedAssignments = getWildcardAndItemsToIgnore({
    currentStatus,
    isSelectAllActive,
    currentAssignmentsByStatus: updatedAssignments,
    companiesStatusDict,
    visibleCompanies,
    type,
    value
  })

  return fullUpdatedAssignments
}

// This is not meant to be used with more than one assignment type (like sale & product together)
// Used for task table, where the only assignment type is task, and there is no filter by status
export const updateAssignmentsDataWithoutStatus = (
  isSelectAllActive: boolean,
  selectedItems: string[],
  currentAssignments: Dictionary<Assignment>,
  type: AssignmentType,
  value: string
) => {
  const assignments = _.reduce(
    selectedItems,
    (acc, entityId) => ({
      ...acc,
      [entityId]: getAssignment(currentAssignments, true, entityId, type, value)
    }),
    currentAssignments
  )

  if (isSelectAllActive) {
    assignments[WILDCARD] = getAssignment(assignments, true, WILDCARD, type, value)
  }

  return { ...currentAssignments, ...assignments }
}

export const getValueForSelect = (
  assignmentsData: AssignmentsData | undefined,
  companyId: string,
  status: string,
  assignmentType: AssignmentType
) => {
  if (!assignmentsData) return undefined

  const assignment = _.get(assignmentsData, [status, 'assignments', companyId])
  if (!assignment) {
    const wildcardAssignment = _.get(assignmentsData, [status, 'assignments', WILDCARD, assignmentType])
    const isInItemsToIgnore = !!_.get(assignmentsData, [status, 'itemsToIgnore', companyId])
    return !isInItemsToIgnore ? wildcardAssignment : undefined
  }
  return _.get(assignment, assignmentType)
}
