/* eslint-disable import/no-default-export */
export default {
  DashboardTitle: 'Your Dashboard',
  DashboardSubtitle: 'Use the boxes to see all your data at a glance!',
  ChangeBox: 'Change',
  RemoveBox: 'Delete box',
  ChooseWhatDisplay: 'Choose what to display in this box',
  PortfolioTitle: 'Portfolios',
  PortfolioSubtitle: 'Your portfolios at a glance',
  PortfolioAndCampaignTitle: 'Portfolios and campaigns',
  PortfolioAndCampaignSubtitle: 'Your portfolios and campaigns at a glance',
  AnalysisTitle: 'Portfolio queries, analysis and researches',
  AnalysisSubtitle: 'Preview your saved portfolio queries, analysis or researches',
  SuggestedProspects: 'Prospect suggested',
  CreatedOn: 'Created on {{date}}',
  EmptyWidget: {
    SelectPortfolio: {
      BoxTitlePortfolio: 'Select a portfolio',
      BoxTitlePortfolioAndCampaign: 'Select a portfolio or a campaign',
      Title: 'Portfolio',
      Description: 'Select a portfolio from "Portfolio Management"',
      SelectLabel: 'Select portfolio',
      SelectPlaceholder: 'Click to select a portfolio',
      Button: 'Select',
      Banner: {
        Message: 'If you don\'t want to select a portfolio, you can create one from "Portfolio Management"',
        Button: 'Create'
      }
    },
    SelectCampaign: {
      BoxTitle: 'Select a portfolio or a campaign',
      Title: 'Campaigns',
      Description: 'Select a campaign from "Sales Tool"',
      SelectLabel: 'Select campaign',
      SelectPlaceholder: 'Click to select a campaign',
      Button: 'Select',
      Banner: {
        Message: 'If you don\'t want to select a campaign, you can create one from "Sales Tool"',
        Button: 'Create'
      }
    },
    SelectProspectingAnalysis: {
      BoxTitle: 'Select portfolio queries, analysis and researches',
      Title: 'Research',
      Description: 'Select a saved research',
      SelectLabel: 'Select research',
      SelectPlaceholder: 'Click to select a research',
      SelectPlaceholderNoQuery: 'No query saved',
      Button: 'Select',
      Banner: {
        Message: "If you don't want to select a research, you can create a new one",
        Button: 'Create'
      }
    },
    SelectTargetingAnalysis: {
      BoxTitle: 'Select portfolio queries, analysis and researches',
      Title: 'Analysis',
      Description: 'Select a saved analysis',
      SelectLabel: 'Select analysis',
      SelectPlaceholder: 'Click to select an analysis',
      SelectPlaceholderNoQuery: 'No query saved',
      Button: 'Select',
      Banner: {
        Message: "If you don't want to select an analysis, you can create a new one",
        Button: 'Create'
      }
    },
    SelectPortfolioAnalysis: {
      BoxTitle: 'Select portfolio queries, analysis and researches',
      Title: 'Portfolio query',
      Description: 'Select a saved portfolio query',
      SelectLabel: 'Select portfolio query',
      SelectPlaceholder: 'Click to select a portfolio query',
      SelectPlaceholderNoQuery: 'No query saved',
      Button: 'Select',
      Banner: {
        Message: "If you don't want to select a portfolio query, you can create a new one",
        Button: 'Create'
      }
    },
    UploadingPortfolio: {
      BoxTitle: 'Uploading portfolio',
      Title: 'Uploading portfolio',
      Description: 'In this box you will see a preview list',
      BannerMessage: 'Change content whenever you want by clicking on the icon at the top right'
    }
  },
  PortfolioWidget: {
    NoData: 'Data does not exist.',
    NumberOfCompanies: '{{companiesCount}} company',
    NumberOfCompanies_plural: '{{companiesCount}} companies',
    Monitored: 'Followed',
    Actions: 'Actions',
    Enrich: 'Enrich',
    EnrichTooltip: 'Enhance the data of these companies',
    Analyze: 'Analyze',
    AnalyzeTooltip: 'Visualize the portfolio data with respect to the main variables',
    Similarity: 'Similarity',
    SimilarityTooltip: 'Apply the similarity algorithm to find new prospects'
  },
  ProspectingWidget: {
    NoData: 'Data does not exist.',
    NumberOfCompanies: '{{companiesCount}} Company',
    NumberOfCompanies_plural: '{{companiesCount}} Companies',
    Monitored: 'Followed',
    MoreFilters: '{{filtersCount}} more filter',
    MoreFilters_plural: '{{filtersCount}} more filters',
    ViewDetailsTooltip: 'See the filters applied to this Prospecting list'
  },
  TargetingWidget: {
    NoData: 'Data does not exist.',
    Companies: 'Company',
    Companies_plural: 'Companies'
  },
  CampaignWidget: {
    NoData: 'Data does not exist.',
    NumberOfCompanies: '{{companiesCount}} company',
    NumberOfCompanies_plural: '{{companiesCount}} companies'
  },
  AppointmentsWidget: {
    Title: 'Your appointments',
    NoAppointments: 'You have no appointments',
    MoreEvents: '{{eventsCount}} more event',
    MoreEvents_plural: '{{eventsCount}} more events',
    Today: 'Today',
    Tomorrow: 'Tomorrow'
  },
  ContactsWidget: {
    Title: 'Your Contacts',
    NameAndSurname: 'Name Surname',
    JobTitle: 'Job Title',
    Email: 'Email',
    Search: 'Search by Name, Email or Job Title',
    ContactsSaved: 'Contacts saved',
    AddContacts: 'Add Contacts',
    ShowAll: 'Show All'
  },
  LatestDocuments: {
    Title: 'Latest documents',
    NoDocuments:
      'In this box you will see all your latest downloads. Your documents will be in the repository inside your profile.',
    Info: 'The table shows the latest documents downloaded',
    Table: {
      Name: 'Name',
      Type: 'Type',
      CreationDate: 'Creation date',
      ExpirationDate: 'Expiration date',
      DownloadExcel: 'Downloaded Excel',
      Campaign: 'Campaign',
      Report: 'Report'
    }
  },
  PortfolioQueryWidget: {
    MoreFilters: '{{filtersCount}} more filter',
    MoreFilters_plural: '{{filtersCount}} more filters',
    ViewDetailsTooltip: 'See the filters applied to this Portfolio'
  }
}
