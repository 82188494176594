import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@components/Button'
import { SaveBeforeContinue } from '@pages/Prospecting/SaveBeforeContinueDialog'
import ChevronRight from '../../assets/icons/chevron-right.svg?react'
import { APPOINTMENT_CREATE, ACCOUNT_APPOINTMENTS } from '../../routes'
import { Text } from '../Text'

import { ActionWrapper, InfoWrapper, MenuDivider, StyledButton } from './StyledComponents'

export const AppointmentMenu = ({ saveBeforeContinue = fn => fn }: SaveBeforeContinue) => {
  const { t } = useTranslation('appointment')
  const navigate = useNavigate()
  const createAppointment = saveBeforeContinue(() => navigate(APPOINTMENT_CREATE))
  const manageAppointments = saveBeforeContinue(() => navigate(ACCOUNT_APPOINTMENTS))

  return (
    <div>
      <StyledButton onClick={createAppointment} aria-label={t('CreateAppointmentButton')}>
        <InfoWrapper>
          <Text block text={t('CreateAppointmentButton')} bold color="white" />
        </InfoWrapper>
        <ActionWrapper>
          <IconButton ghost>
            <ChevronRight />
          </IconButton>
        </ActionWrapper>
      </StyledButton>
      <MenuDivider />
      <StyledButton type="button" onClick={manageAppointments} aria-label={t('ManageAppointmentsButton')}>
        <InfoWrapper>
          <Text block text={t('ManageAppointmentsButton')} bold color="white" />
        </InfoWrapper>
        <ActionWrapper>
          <IconButton ghost>
            <ChevronRight />
          </IconButton>
        </ActionWrapper>
      </StyledButton>
    </div>
  )
}
