/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  PM001: 'Company uploaded correctly. Upload of portfolio {{portfolioName}} completed!',
  PM001_plural: 'Companies uploaded correctly. Upload of portfolio {{portfolioName}} completed!',
  PM002: 'Go to the detail of the upload errors of the {{portfolioName}} portfolio',
  PM003: '{{portfolioName}} company not imported due to identification mismatch or ceased activity.',
  PM003_plural: '{{portfolioName}} companies not imported due to identification mismatch or ceased activity.',
  PM004: '{{portfolioName}} companies not imported due to incomplete or wrong data.',
  PM004_plural: '{{portfolioName}} companies not imported due to incomplete or wrong data.',
  PM005: 'Download list {{exportType}} of portfolio {{portfolioName}} completed',
  PM006: 'Download PDF of Company {{companyName}} completed',
  PM007: 'Duplication of {{numberOfCompanies}} company in the portfolio {{portfolioName}} executed correctly',
  PM008: 'Deletion of {{numberOfCompanies}} companies in the portfolio {{portfolioName}} executed correctly',
  PM009:
    'Moving {{count}} company from portfolio {{sourcePortfolioName}} to portfolio {{targetPortfolioName}} successfully executed.',
  PM009_plural:
    'Moving {{count}} companies from portfolio {{sourcePortfolioName}} to portfolio {{targetPortfolioName}} successfully executed.',
  PM010: 'Enrichment of {{numberOfCompanies}} company in the portfolio {{portfolioName}} executed correctly',
  PM010_plural: 'Enrichment of {{numberOfCompanies}} companies in the portfolio {{portfolioName}} executed correctly',
  PM011: 'Sharing of {{companyNumber}} company from portfolio {{portfolioName}} with {{userName}} executed correctly',
  PM012: '{{grantorName}} has been shared the portfolio {{portfolioName}}',
  PM013: 'Remove sharing of {{portfolioName}} executed correctly',
  PM014Part1:
    '{{productName}} assignment to {{numberOfCompanies}} company in portfolio {{portfolioName}} performed correctly.',
  PM014Part1_plural:
    '{{productName}} assignment to {{numberOfCompanies}} companies in portfolio {{portfolioName}} performed correctly.',
  PM014Part2:
    ' Product assignment has not been completed for {{numberOfDuplicates}} company due to duplication of the line.',
  PM014Part2_plural:
    ' Product assignment has not been completed for {{numberOfDuplicates}} companies due to duplication of the line.',
  PM015: '{{salesName}} assigned to {{numberOfCompanies}} company in {{portfolioName}} performed correctly',
  PM017: '{{managerName}} assigned to {{numberOfCompanies}} company in {{portfolioName}} performed correctly',
  PM014_0: 'Unassignment to {{numberOfCompanies}} companies in {{portfolioName}} executed correctly',
  PM015_0: 'Unassignment to {{numberOfCompanies}} companies in {{portfolioName}} executed correctly',
  PM017_0: 'Unassignment to {{numberOfCompanies}} companies in {{portfolioName}} executed correctly',
  PM019: 'The Campaign {{campaignName}} with {{numberOfCompanies}} company has been created',
  PM019_plural: 'The Campaign {{campaignName}} with {{numberOfCompanies}} companies has been created',
  PM020: '{{numberOfCompanies}} successfully enriched',
  PM021: '{{initiatorUserName}} has been shared {{numberOfCompanies}} companies',
  PM022: '{{initiatorUserName}} stopped sharing of {{numberOfCompanies}} companies',
  PM023: 'Updating sharing executed correctly',
  PM024: 'Updating sharing executed correctly',
  PM025: '{{initiatorUserName}} has shared {{numberOfCompanies}} companies to {{numberOfSalesUsers}} users',
  PM026: '{{initiatorUserName}} stopped sharing of {{numberOfCompanies}} companies to {{numberOfSalesUsers}} users',
  PM027: '{{portfolioName}} companies not imported due to incomplete or wrong data.',
  PM027_plural: '{{portfolioName}} companies not imported due to incomplete or wrong data.',
  PM028: 'Download of update template completed',
  PM029: 'Portfolio {{portfolioName}} is not followed anymore',
  LL001: 'Adding Local Units. The portfolio has been updated with {{numberOfCompanies}} Local Units.',
  ST001: 'The Campaign {{campaignName}} with {{numberOfCompanies}} company has been created.',
  ST001_plural: 'The Campaign {{campaignName}} with {{numberOfCompanies}} companies has been created.',
  ST001WithDuplicates: '{{numberOfDuplicates}} duplicate skipped.',
  ST001WithDuplicates_plural: '{{numberOfDuplicates}} duplicates skipped.',
  ST002: '{{userName}} has removed {{numberOfCompanies}} company from campaign {{campaignName}}',
  ST002_plural: '{{userName}} has removed {{numberOfCompanies}} companies from campaign {{campaignName}}',
  ST003: '{{userName}} has assigned {{numberOfCompanies}} company for the campaign {{campaignName}}.',
  ST003_plural: '{{userName}} has assigned {{numberOfCompanies}} companies for the campaign {{campaignName}}.',
  ST003WithDuplicates: '{{numberOfDuplicates}} duplicates skipped.',
  ST003WithDuplicates_plural: '{{numberOfDuplicates}} duplicates skipped.',
  ST004: 'The campaign {{campaignName}} will start on {{startDate}}',
  ST005: 'The campaign {{campaignName}} will end on {{endDate}}',
  ST006: 'The {{portfolioName}} portfolio was successfully added to the {{campaignName}} campaign.',
  ST007: 'Download excel of campaign {{campaignName}} completed',
  ST009: 'New assignments to {{numberOfCompanies}} company for the {{campaignName}} campaign completed.',
  ST009_plural: 'New assignments to {{numberOfCompanies}} companies for the {{campaignName}} campaign completed.',
  ST010: '{{numberOfCompanies}} company was successfully added to the {{campaignName}} campaign.',
  ST010_plural: '{{numberOfCompanies}} companies were successfully added to the {{campaignName}} campaign.',
  ST011:
    'Assignment of task {{taskName}} to {{numberOfCompanies}} company for the {{campaignName}} campaign completed.',
  ST011_plural:
    'Assignment of task {{taskName}} to {{numberOfCompanies}} companies for the {{campaignName}} campaign completed.',
  ST012: '{{userName}} has assigned {{numberOfCompanies}} company to {{salesName}} for the campaign {{campaignName}}.',
  ST012_plural:
    '{{userName}} has assigned {{numberOfCompanies}} companies to {{salesName}} for the campaign {{campaignName}}.',
  ST012WithDuplicates: '{{numberOfDuplicates}} duplicates skipped.',
  ST012WithDuplicates_plural: '{{numberOfDuplicates}} duplicates skipped.',
  ST013: 'Assignment removed to {{numberOfCompanies}} company for the {{campaignName}} campaign.',
  ST013_plural: 'Assignment removed to {{numberOfCompanies}} companies for the {{campaignName}} campaign.',
  ST014: '{{userName}} has removed {{numberOfCompanies}} company to {{salesName}} for the campaign {{campaignName}}.',
  ST014_plural:
    '{{userName}} has removed {{numberOfCompanies}} companies to {{salesName}} for the campaign {{campaignName}}.',
  PR001: '{{numberOfCompanies}} new companies entered the portfolio monitored {{portfolioName}}',
  PR002: 'Insert and enrichment of {{numberOfCompanies}} companies in portfolio {{portfolioName}} executed correctly',
  PR003:
    'Creation of portfolio {{portfolioName}} and insert of {{numberOfCompanies}} enriched company executed correctly',
  PR003_plural:
    'Creation of portfolio {{portfolioName}} and insert of {{numberOfCompanies}} enriched companies executed correctly',
  PR004:
    'Insertion and enrichment of {{numberOfCompanies}} companies in followed portfolio {{portfolioName}} executed correctly',
  PR005:
    'Creation of followed portfolio {{portfolioName}} and insert of {{numberOfCompanies}} enriched companies executed correctly ',
  PR006: 'Your enriched portfolio <0>{{portfolioName}}</0> has been correctly created. {{exclusionWarning}}',
  PR007: 'Insert and enrichment of {{numberOfCompanies}} companies in portfolio {{portfolioName}} executed correctly',
  TR001:
    'Creation of portfolio {{portfolioName}} and insert of {{numberOfCompanies}} enriched company executed correctly',
  TR001_plural:
    'Creation of portfolio {{portfolioName}} and insert of {{numberOfCompanies}} enriched companies executed correctly',
  TR002: 'Insert and enrichment of {{numberOfCompanies}} companies in portfolio {{portfolioName}} executed correctly',
  TA001: 'Download excel from Targeting completed',
  TA002: 'Download PDF from Targeting completed',
  DO002: "The file for company {{companyName}} has been created and it's ready to download.",
  DO003: 'The {{module}} research {{queryName}} has been shared with you by {{grantorUserName}}',
  PM002Event1: 'Ceased companies not uploaded',
  PM002Event2: 'Errors due to wrong data. You can upload the template later in the "Portoflio management" section',
  OP001:
    'Creation of portfolio {{portfolioName}} and insert of {{numberOfCompanies}} enriched company executed correctly',
  OP001_plural:
    'Creation of portfolio {{portfolioName}} and insert of {{numberOfCompanies}} enriched companies executed correctly',
  OP002: 'Insert and enrichment of {{numberOfCompanies}} company in portfolio {{portfolioName}} executed correctly',
  OP003: 'Your enriched portfolio {{portfolioName}} has been correctly created. {{exclusionWarning}}',
  OP004: 'Enrichment of {{numberOfCompanies}} company in the portfolio {{portfolioName}} executed correctly',
  MissingMessage: 'Notification message is missing for type {{type}}.',
  ExcludedCompaniesWarning: 'Companies belonging to hidden portfolios were not processed.',
  SalesTool: 'Sales tool',
  Portfolio: 'Portfolio Management',
  Prospecting: 'Prospecting',
  Operations: 'Operations',
  Targeting: 'Analyze Market',
  DownloadPDF: 'Download PDF',
  ShareQuery: {
    Title: 'Share Query',
    Prospecting: 'Prospecting query',
    Targeting: 'Targeting query',
    PortfolioManagement: 'Portfolio management query'
  },
  EsgUnlockComplete:
    'ESG Company Scores have been unlocked for {{numberOfUnlockedCompanies}} companies with all available data inside the portfolio {{portfolioName}}. {{numberOfNotUnlockedCompanies}} have not been unlocked due to already unlocked or missing data.',
  NotificationArchive: {
    Title: 'Notification archive',
    Description: 'In this page you can find the notification list received',
    Notifications: '{{notificationsNumber}} Notifications',
    Welcome: 'Welcome in Margò:',
    NoNotifications: "this is the notification section and it's going to fill up once you start to perform actions"
  },
  Table: {
    Header: {
      Date: 'Date',
      Section: 'Section',
      Message: 'Message'
    }
  },
  NotificationPopup: {
    Open: 'Open',
    ManageErrors: 'Manage errors',
    DownloadList: 'Download list',
    DownloadFile: 'Download file',
    ShareQueryAction: 'Save shared query'
  },
  PushEvent: {
    UploadProgress: 'Your portfolio is uploading',
    UploadProgressFinished: 'Upload completed',
    DeleteCompaniesProgress: 'Deleting of selected companies is in progress',
    DeleteCompaniesProgressFinished: 'Deleting of selected companies is finished',
    MoveCompaniesProgress: 'Moving of selected companies is in progress',
    MoveCompaniesProgressFinished: 'Moving of selected companies is finished',
    EnrichCompaniesProgress: 'Enrichment of selected companies is in progress',
    EnrichCompaniesProgressFinished: 'Enrichment of selected companies is finished',
    CreatePortfolioFromSelectionProgress: 'Create portfolio from selected companies is in progress',
    CreatePortfolioFromSelectionProgressFinished: 'Create portfolio from selected companies is finished',
    DuplicateProgress: 'Your portfolio is duplicating',
    DuplicateProgressFinished: 'Duplicate complete',
    ShareCompaniesProgress: 'Companies are being shared',
    ShareCompaniesProgressFinished: 'Shared is completed',
    UnshareCompaniesProgress: 'Companies are being stopped shared',
    UnshareCompaniesProgressFinished: 'Companies were stopped shared',
    AssignSalesProgress: 'Assign to Sales in progress',
    AssignSalesProgressFinished: 'Assign to Sales completed',
    AssignAreaManagerProgress: 'Assign to AreaManagers in progress',
    AssignAreaManagerProgressFinished: 'Assign to AreaManagers completed',
    AssignProductProgress: 'Assign to Product in progress',
    AssignProductProgressFinished: 'Assign to Product completed',
    ProspectingSaveListProgress: 'Creating portfolio from prospecting in progress',
    ProspectingSaveListProgressFinished: 'Creating portfolio completed',
    TargetingSaveListProgress: 'Creating portfolio from Analyze market in progress',
    TargetingSaveListProgressFinished: 'Creating portfolio completed',
    ProspectingUpdateListProgress: 'Updating portfolio from prospecting in progress',
    ProspectingUpdateListProgressFinished: 'Updating portfolio from prospecting completed',
    TargetingUpdateListProgress: 'Updating portfolio from Analyze market in progress',
    TargetingUpdateListProgressFinished: 'Updating portfolio from Analyze market completed',
    CreateCampaignProgress: 'Creating campaign in progress',
    CreateCampaignProgressFinished: 'Creating campaign completed',
    AssignCampaignProgress: 'Assigning campaign in progress',
    AssignCampaignProgressFinished: 'Assigning campaign completed',
    UpdateCampaignTaskProgress: 'Your campaign is updating',
    UpdateCampaignTaskProgressFinished: 'Your campaign has been updated',
    SetCompaniesTaskProgress: 'Setting companies task in progress',
    SetCompaniesTaskProgressFinished: 'Setting companies task completed',
    ExportCampaignProgress: 'Export campaign in progress',
    ExportCampaignProgressFinished: 'Export campaign completed',
    ExportPortfolioProgress: 'Your portfolio {{portfolioName}} is being prepared for download',
    ExportPortfolioProgressFinished: 'Your portfolio {{portfolioName}} download is ready',
    PdfGenerateProgress: 'PDF creation for company {{companyName}} in progress',
    AddLocalUnitsToPortfolioProgress: 'Adding Local Units. Portfolio update in progress.',
    OperationsSaveListProgress: 'Creating portfolio from Operations in progress',
    OperationsSaveListProgressFinished: 'Creating portfolio completed',
    OperationsUpdateListProgress: 'Updating portfolio from Operations in progress',
    OperationsUpdateListProgressFinished: 'Updating portfolio completed',
    UnlockEsgScoresProgress: 'Unlock operation in progress',
    UnlockEsgScoresProgressFinished: 'Unlock operation is completed'
  },
  Banner: {
    RingsWarningMessage:
      'Attention! Your remaining rings credit is less than 10%. Please check <1>Your Account</1> or contact our sales team.'
  },
  Contacts: 'Contacts',
  ContactsDownloadStart: 'Your Contacts is being prepared for download',
  ContactsDownloadEnd: 'Contacts download complete'
}
