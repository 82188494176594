import dayjs from '@dayjs'
import { FormatNumberFunction } from './types'

export const TIME_FORMAT = 'HH:mm'

export const getLocalizedDateFormatPattern = (format = 'l') =>
  dayjs().locale(navigator.language).localeData().longDateFormat(format)

export const getLocalizedDatePickerFormatPatterns = () => [
  getLocalizedDateFormatPattern(),
  getLocalizedDateFormatPattern('L')
]

export const getLocalizedDateFormatWithHourAndMinutesPattern = () => `${getLocalizedDateFormatPattern()} ${TIME_FORMAT}`

export const toLocalizedDateFormat = (date: dayjs.Dayjs | Date | string | undefined) =>
  date !== undefined ? dayjs(date).locale(navigator.language).format('L') : ''

export const toLocalizedTimeFormat = (date: dayjs.Dayjs | Date | string) =>
  dayjs(date).locale(navigator.language).format('LT')

export const toNamedDateFormat = (date: dayjs.Dayjs | Date | string) => dayjs(date).format('dddd LL')

export const formatDateTime = (date: dayjs.Dayjs | Date | string) => dayjs(date).utc().toISOString()

export const formatNumberThousands = (value: number | undefined, formatter: FormatNumberFunction) => {
  if (value === undefined) return ''

  if (Math.abs(value) < 1000) return formatter(value)
  return `${formatter(Math.round(value / 100) / 10)} K`
}

// BE is not sending timezone in dateTime either it is sending dates in UTC
export const convertDateTimeFromBackendWithoutTimezone = (backendDatetime: string | Date) =>
  dayjs(backendDatetime).utc(true)
