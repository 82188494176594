import { createSelector } from 'reselect'
import { paramSelector } from '../../utils/selectors'
import { RootState } from '../types'
import * as t from './types'
import { buildLoadingKey } from './utils'

const selectState = (state: RootState) => state.loading

const isLoadingSingleFactory = (type: t.LoadingOption) => createSelector(selectState, state => !!state[type])

const isLoadingPropFactory = <TParam extends Record<string, t.LoadingParams>>(
  type: t.LoadingOption,
  prop: keyof TParam
) => createSelector(selectState, paramSelector<TParam>(), (state, param) => !!state[buildLoadingKey(type, param[prop])])

export const areNotificationsLoading = isLoadingSingleFactory('notifications')

export const isDialogLoading = isLoadingSingleFactory('dialog')

// parametrized

export const isPortfolioCompanyDuplicatesLoading = isLoadingPropFactory<t.WithPortfolioId>(
  'portfolioCompanyDuplicates',
  'portfolioId'
)

export const isPortfolioCompanyDuplicatesSubmitting = isLoadingPropFactory<t.WithPortfolioId>(
  'portfolioCompanyDuplicatesSubmit',
  'portfolioId'
)
