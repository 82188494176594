import _ from 'lodash'

export function consoleOverride() {
  const { console } = window
  if (!console) return
  function intercept(method: string) {
    const original = _.get(console, method)

    _.set(console, method, (...rest: string[]) => {
      const stack = Array.prototype.slice.apply(rest).join(' ')
      if (original.apply) {
        // normal browser
        original.apply(console, rest)
      } else {
        // IE
        original(stack)
      }
    })
  }
  const methods = ['warn', 'error']
  _.forEach(methods, (method: string) => intercept(method))
}
