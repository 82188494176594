import _ from 'lodash'
import { FieldExpression } from '@api/expressionTypes'
import { DomainFilterSelector, CodeWithSelector } from '../features/filters/types/domainFilter'
import { getKeyTreeNode, KeyTree, TreePath } from './tree'

export function codesWithSelectorsByPath(keyTree: KeyTree, selectors: DomainFilterSelector[], parentPath: TreePath) {
  const node = getKeyTreeNode(keyTree, parentPath)
  if (!parentPath || !node) {
    return null
  }
  const codes = _.keys(node)
  const filteredSelectors = _.filter(selectors, { hierarchyLevel: parentPath.length + 1 })
  const currentSelector = filteredSelectors[0]
  if (!currentSelector) return null
  const { fieldId, domainType, filterHierarchy } = currentSelector
  return _.map<string, CodeWithSelector>(codes, code => ({
    code,
    fieldId,
    domainType,
    filterHierarchy
  }))
}

export function codesWithSelectorsByFieldExpressions(
  fieldExpressions: FieldExpression[] | undefined,
  selectors: DomainFilterSelector[]
) {
  const codes = _.reduce(
    fieldExpressions,
    (result: CodeWithSelector[], fieldExpression) => {
      if (!fieldExpression.fieldId) return result
      const filteredSelectors = _.filter(selectors, { fieldId: fieldExpression.fieldId })
      const currentSelector = filteredSelectors[0]
      if (!currentSelector) return result
      const { fieldId, domainType, filterHierarchy } = currentSelector

      return _.concat(
        result,
        _.map(fieldExpression.value as string[], value => {
          return {
            code: value as string,
            fieldId,
            domainType,
            filterHierarchy
          }
        })
      )
    },
    []
  )
  return _.reject(codes, code => _.isNil(code))
}

// Temporary solution until portfolioIds will be moved to query
export function codesWithSelectorsByValueOverload(valueOverload: string[], selectors: DomainFilterSelector[]) {
  const fieldSelector = selectors[0]
  if (fieldSelector) {
    return _.map(valueOverload, value => {
      return {
        code: value,
        fieldId: fieldSelector.fieldId,
        domainType: fieldSelector.domainType,
        filterHierarchy: fieldSelector.filterHierarchy
      } as CodeWithSelector
    })
  }

  return []
}
