import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TFunction } from 'i18next'
import { isDenied } from '@utils/constraints'
import PortfolioIcon from '../../assets/icons/case.svg?react'
import TargetingIcon from '../../assets/icons/target-1.svg?react'
import ProspectingIcon from '../../assets/icons/ordered.svg?react'
import { QueryType } from '../../types'
import { queryTypes } from '../../features/query/types'
import {
  portfolioQueriesConstraint,
  prospectingQueriesConstraint,
  targetingQueriesConstraint
} from '../../features/user/selectors'

export const getIconBasedOnQueryType = (queryType: QueryType) => {
  if (queryType === QueryType.PortfolioQueries) return <PortfolioIcon />
  if (queryType === QueryType.TargetingQueries) return <TargetingIcon />
  if (queryType === QueryType.ProspectingQueries || queryType === QueryType.MonitoredQueries) return <ProspectingIcon />

  return undefined
}

export const getLabelBasedOnQueryType = (t: TFunction, queryType: QueryType) => {
  if (queryType === QueryType.PortfolioQueries) return t('header:PortfolioManagement')
  if (queryType === QueryType.TargetingQueries) return t('header:Targeting')
  if (queryType === QueryType.ProspectingQueries || queryType === QueryType.MonitoredQueries)
    return t('header:Prospecting')
  return undefined
}

export const getQueryLabelBasedOnQueryType = (t: TFunction, queryType: QueryType) => {
  if (queryType === QueryType.PortfolioQueries) return t('notification:Portfolio')
  if (queryType === QueryType.TargetingQueries) return t('notification:Targeting')
  if (queryType === QueryType.ProspectingQueries || queryType === QueryType.MonitoredQueries)
    return t('notification:Prospecting')
  return undefined
}

export const useGetAllowedQueryTypes = () => {
  const portfolioQueriesConstraintInner = useSelector(portfolioQueriesConstraint)
  const targetingQueriesConstraintInner = useSelector(targetingQueriesConstraint)
  const prospectingQueriesConstraintInner = useSelector(prospectingQueriesConstraint)

  const allowedQueryTypes = useMemo(
    () =>
      queryTypes.filter(queryType => {
        if (isDenied(portfolioQueriesConstraintInner) && queryType === QueryType.PortfolioQueries) return false
        if (isDenied(targetingQueriesConstraintInner) && queryType === QueryType.TargetingQueries) return false
        if (
          isDenied(prospectingQueriesConstraintInner) &&
          (queryType === QueryType.ProspectingQueries || queryType === QueryType.MonitoredQueries)
        )
          return false

        return true
      }),
    [portfolioQueriesConstraintInner, prospectingQueriesConstraintInner, targetingQueriesConstraintInner]
  )

  return allowedQueryTypes
}
