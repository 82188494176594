/* eslint-disable import/no-default-export */
export default {
  Username: 'Užívateľské meno',
  Password: 'Heslo',
  Login: 'Prihlásiť sa',
  LoginErrorMessage: 'Zadaný užívateľ alebo heslo je nesprávne',
  SignInMessage: 'Prihlásiť sa do Margo',
  EnterDetails: 'Zadajte svoje údaje nižšie',
  InsertUserName: 'Vložte svoje užívateľské meno',
  InsertUserNamaInBehalf: 'V mene',
  InsertPassword: 'Vložte svoje heslo',
  ForgotPassword: 'Zabudli ste heslo?',
  ResetPasswordDialog: {
    Title: 'Zabudnuté heslo',
    Description:
      'Kontaktujte naše zákaznícke centrum a požiadajte o obnovenie hesla e-mailom <0> {{email}} </0> alebo telefonicky {{phone}}.',
    Email: 'helpdesk.sk@crif.com',
    Phone: '+421 (02) 5920 7537'
  },
  ResetPasswordForm: {
    Description:
      'Zadajte svoje používateľské meno a e-mailovú adresu priradenú k vášmu účtu. Bude vám zaslaný e-mail s odkazom na vyplnenie obnovovacieho hesla.',
    SuccessDesription: 'Bol vám zaslaný e-mail s odkazom na obnovenie hesla. Prejdite na prihlasovaciu stránku.',
    Email: 'E-mail',
    ResetPassword: 'Obnoviť heslo',
    ReturnToLoginpage: 'Späť na prihlasovaciu stránku',
    InsertUserName: 'Vložte svoje užívateľské meno',
    InsertEmail: 'Vložte svoj e-mail',
    InvalidUsernameOrPassword:
      'Heslo nebolo obnovené. Používateľské meno / e-mail je neplatný. Skúste to znova alebo kontaktujte zákaznícky servis.',
    ResetFailed: 'Obnovenie hesla zlyhalo.'
  },
  RenewPasswordForm: {
    Title: 'Obnoviť heslo',
    Description: 'Platnosť vášho hesla vypršala. Pre prístup k službám Margo prosím zmeňte svoje heslo.',
    OldPassword: 'Staré heslo',
    NewPassword: 'Nové heslo',
    ConfirmPassword: 'Potvrďte nové heslo',
    ChangePassword: 'Zmeniť heslo',
    PasswordChangeSuccessTitle: 'Heslo zmenené',
    PasswordChangeSuccessText: 'Heslo bolo úspešne zmenené',
    RenewPasswordFailed: 'Staré heslo je nesprávné.'
  },
  PasswordExpiration90Days: 'Heslo bude platné po dobu 90 dní',
  PasswordRules_czsk: `Heslo musí mať dĺžku aspoň 8 znakov,
  Heslo musí obsahovať maximálne 3 rovnaké znaky po sebe
  Heslo musí obsahovať veľké a malé písmená
  Heslo musí obsahovať aspoň jedno číslo a jeden špeciálny znak
  Heslo nesmie obsahovať meno používateľa
  Heslo nesmie byť rovnaké ako šesť posledných použitých hesiel`,
  Cs: {
    Title: 'Užívateľ je už prihlásený',
    Info: 'Váš užívateľ je už prihlásený, aktívna relácia sa tým ukončí. Všetky operácie na pozadí sa zachovajú.',
    Question: 'Chcete ukončiť existujúcu reláciu a začať novú?',
    NewSession: 'Začať novú reláciu'
  },
  Sso: {
    error: 'Nastal problém s prihlásením sa pomocou metódy "Single Sign On".',
    back: 'Prihláste sa pomocou mena a hesla.'
  }
}
