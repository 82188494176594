export enum FieldType {
  Boolean = 'Boolean',
  String = 'String',
  Keyword = 'Keyword',
  ArrayKeywords = 'ArrayKeywords',
  Long = 'Long',
  Decimal = 'Decimal',
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Domain = 'Domain',
  ArrayDomains = 'ArrayDomains',
  ArrayOfIdName = 'ArrayOfIdName',

  // Todo: in notification there is this, we need to handle it
  Object = 'Object',
  // TODO: remove from utils after refactoring
  Array = 'Array'
}

export enum VisibilityType {
  Default = 'Default',
  Optional = 'Optional'
}

export type FieldConfigurationDto = {
  fieldType: FieldType
  jsonPath: string
  text: string
  sticky?: boolean
  width?: number
  visibilityType?: VisibilityType
}
