/* eslint-disable import/no-default-export */
export default {
  LogoutPrimaryText: 'Saisir vos identifiants pour accéder au service',
  LogoutSecondaryText:
    'Ajouter des informations sur vous et votre rôle au sein de la société à laquelle vous appartenez',
  PortfolioManagement: 'Gestion des portefeuilles',
  Targeting: 'Analyser marché',
  Prospecting: 'Prospection',
  SalesTool: 'Outil de vente',
  API: 'API',
  Operations: 'Opérations',
  Pnrr: 'PNRR',
  SemanticSearch: 'Aller à recherche sémantique',
  FreeSearchPlaceholderWithCampaigns: 'Rechercher une entreprise, un portefeuille ou une campagne par nom',
  FreeSearchPlaceholder: 'Rechercher une entreprise ou un portefeuille par nom',
  SearchErrorTooLong: 'Nombre maximum de caractères: {{maxLimit}}',
  SearchErrorTooShort: 'Veuillez entrer au moins {{minLimit}} caractères',
  FeedbackArea:
    "Zone de notification : elle comprend les dernières actions terminées ou permet de vérifier l'avancement de celles en cours",
  Profile: 'Profil',
  Notification: {
    Title: 'Notification',
    ViewAll: 'Afficher tout'
  },
  Appointments: 'Rendez-vous'
}
