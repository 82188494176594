// eslint-disable-next-line import/no-default-export
export default {
  LeadSection: {
    Title: 'Soubor nástrojů pro úspěch v prodeji',
    Text: 'Margo API nabízí jedinečný soubor dat o registrovaných společnostech, který podpoří váš obchodní růst a automatizaci CRM. Využívá širokou škálu dostupných datových paketů na přístup k oficiálním informacím, jako jsou údaje o zaměstnancích a obratu, adresy společností, kontakty a pokročilé analýzy. Vylepšete své CRM a klientské databáze nejnovějšími informacemi o analýze trhů, hledání nových perspektiv a obchodních příležitostí.'
  },
  MiddleSection: {
    Title: 'Margo API ve zkratce',
    ItemCompanies: 'Údaje o 6,5 milionech českých a slovenských společností aktualizovaných denně',
    ItemAnalytics: '40+ pokročilých analýz a ukazatelů',
    ItemFilters: '300+ filtrů na výběr a shlukování společností',
    ItemPackets: '130+ datových balíků na přístup k tématickým údajům společností'
  },
  BottomSection: {
    Title: 'Uvolněte potenciál svého podnikání, zefektivněte organizaci prodeje a automatizujte manuální procesy',
    AutomationTitle: 'CRM automatizace',
    AutomationText:
      'Integrujte údaje Margo do svého CRM, aby byla vaše databáze aktuální s oficiálními registry, a abyste poskytovali příslušné informace svému prodejnímu týmu.',
    MarketAnalysisTitle: 'Analýza trhu a AI',
    MarketAnalysisText:
      'Připojte své business intelligence systémy k Margo a obohaťte své údaje o klientské základně. Vylepšete své AI modely novými daty.',
    EnrichmentTitle: 'Obohacení údajů a targeting',
    EnrichmentText:
      'Vytvořte cílené podnikové klastry obohacením zákaznické základny o nejnovější dostupné informace o společnosti.',
    BetterDataTitle: 'Lepší údaje o vašich potenciálních zákaznících',
    BetterDataText:
      'Vylepšete své prodejní kampaně o údaje, které pomohou vašemu obchodnímu týmu rychleji vyhodnotit společnosti a uzavírat obchody.',
    ProspectingTitle: 'Výběr klientů',
    ProspectingText:
      'Objevte nové mezery na trhu a najděte nejlepší příležitosti pro své výrobky, importujte je do svých systémů a začněte prodej.',
    MarketingTitle: 'Marketingové analýzy',
    MarketingText:
      'Najděte společnosti, které podnikají v mezinárodním obchodě, inovují nebo mají vysokou digitalizaci. Zobrazte odhady obratu MSP společností.',
    FinanceTitle: 'Finanční analýzy',
    FinanceText:
      'Vyberte společnosti, které mají vysoký sklon předplatit si různé finanční produkty a zjistěte sezónnost jejich finančních potřeb.',
    OnboardingTitle: 'Onboarding a e-fakturace',
    OnboardingText:
      'Vytvářejte bezstarostně registrační formuláře pro své B2B podnikání a využijte údaje z Marga na zefektivnění administrativních úkolů.'
  },
  LearnMore: 'Dozvědět se více'
}
