/* eslint-disable import/no-default-export */
export default {
  BackToLogin: 'Back to login',
  SimpleUsefulPowerful: 'Simple, useful and powerful.',
  MargoWasSurprise: 'Margò was a wonderful surprise for me and my team.',
  ContactCrif: 'Contact {{companyName}} to open your account or ask for information.',
  ContactUsText: 'Contact us to open your account or ask for information.',
  Name: 'Name',
  InsertName: 'Insert your name',
  Surname: 'Surname',
  InsertSurname: 'Insert your surname',
  Email: 'Email',
  InsertEmail: 'Insert your email',
  Message: 'Message',
  InsertMessage: 'Write your text here',
  IAgreeWith: 'I agree with',
  PrivacyPolicy: 'privacy policy',
  Send: 'Send',
  Success: 'Your message has been correctly sent to {{companyName}}.',
  SuccessITA: 'Your message has been correctly sent to Customer Care.',
  ContactCRIF: 'Contact {{companyName}}',
  ContactUs: 'Contact us',
  Company: 'Company',
  InsertCompany: 'Insert your company',
  Country: 'Country',
  InsertCountry: 'Choose your Country',
  JobTitle: 'Job Title',
  InsertJobTitle: 'Insert your job title',
  Telephone: 'Telephone number',
  InsertTelephone: 'Insert your telephone number',
  CompanyName: 'Company Name',
  ContactCustomer: 'Contact the customer',
  EnterContactDetails: 'Enter the contact details',
  EnterContactDetailsSubtitle: 'Enter data of the contact person you want to contact',
  ContactName: 'Contact name',
  ContactSurname: 'Contact surname',
  ContactEmail: 'Contact email',
  ContactTelephone: 'Contact telephone number',
  ConfirmOperation: 'Confirm the operation',
  ConfirmOperationDescription: 'The operation could require few minutes. Do you want to proceed?',
  CreateOffer: 'Create offer',
  ContactCustomerOnline: 'Contact the customer online',
  ProceedQuestion: 'Do you want to proceed?',
  ErrorMessage:
    'Pay attention: you cannot create a new offer for this company, since another one is still pending. Please, proceed with its completion inside <1>Apparound</1> platform.'
}
