/* eslint-disable import/no-default-export */
export default {
  Title: 'Výsledky vyhledávání',
  SaletoolTitle: 'Nástroj prodeje - Výsledky vyhledávání kampaní',
  Search: 'Hledat',
  SearchPlaceholder: 'Hledat',
  SearchCompaniesAdditionalInfo: 'Vezměte prosím na vědomí, že pro společnosti můžete použít také',
  NoResults: 'Je nám líto, nenašli jsme žádné shody pro',
  TryDifferent:
    'Zkuste použít jiné klíčové slovo nebo použijte <0> sémantické vyhledávání </0> k vyhledání souvisejících výrazů',
  TryDifferentWithoutProsp: 'Zkusit s jiným klíčovým slovem.',
  Banner: {
    title: 'Aktivovat sekci "Výběr klientů"!',
    subtitle: 'Sémantické vyhledávání je k dispozici pouze pro uživatele, kteří si zakoupili sekci "Výběr klientů".',
    gotoAction: 'Kontaktovat {{companyName}}'
  },
  Tabs: {
    Companies: 'Společnosti',
    Portfolios: 'Portfólia',
    Campaigns: 'Kampaně'
  },
  SearchInformationDialog: {
    Title: 'Co lze vyhledat?',
    SubTitleWithCampaigns: 'Můžete vyhledávat společnosti, portfolia nebo kampaně',
    SubTitle: 'Můžete vyhledávat společnosti nebo portfolia',
    CompaniesBy: '<strong>Společnosti</strong> podle:',
    PortfoliosCampaignsHelpText: '<strong>Portfolia</strong> a <strong>kampaně</strong> podle názvu',
    PortfoliosHelpText: '<strong>Portfolia</strong> podle názvu',
    Companies: {
      CommercialDisplay: 'Komerční zobrazení'
    }
  },
  HiddenPortfolioCompany:
    'Firma, kterou hledáte, není přístupná, protože může patřit do portfolia skrytého jiným uživatelem ve vaší organizaci.',
  Company: 'Společnost',
  Portfolio: 'Portfolio',
  Campaign: 'Kampaň',
  GoTo: {
    Company: 'Report společnosti',
    Portfolio: 'Správa portfolia',
    Campaign: 'Obchodní aktivity'
  }
}
