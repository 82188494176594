import { useGetFilterQueriesAsDocument } from '@api/query'
import { Select, Option } from '@components/Select'
import { FETCH_QUERIES_TOP } from '@features/query/constants'
import { QueryType } from '@types'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  portfolioId?: string
  queryId?: string
  setQueryName: (queryName: string) => void
  setQueryId: (queryId: string) => void
  queryTypes: QueryType[]
}

export const UpdateQuerySelect = ({ portfolioId, queryId, setQueryName, setQueryId, queryTypes }: Props) => {
  const { t } = useTranslation('common')

  const { isLoading, data: items } = useGetFilterQueriesAsDocument({
    documentTypes: queryTypes,
    top: FETCH_QUERIES_TOP
  })

  return (
    <Select
      loading={isLoading}
      placeholder={t('SaveQuery.InputPlaceholder')}
      value={queryId}
      getPopupContainer={trigger => trigger.parentNode as HTMLElement}
      onChange={value => {
        const selectedQueryName = _.find(items, { id: value as string })?.name ?? ''
        setQueryName(selectedQueryName)
        setQueryId(value as string)
      }}
      showSearch
      optionFilterProp="title"
    >
      {_.map(items, i => (
        <Option key={i.id} title={i.name} disabled={portfolioId ? i.associatedEntityId !== portfolioId : false}>
          {i.name}
        </Option>
      ))}
    </Select>
  )
}
